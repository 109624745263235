import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { tableIcons } from "../../theme/TableIcons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { formattedDateTimeUntilNow, ucFirst } from "../../utils/localization.utils";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CollectorStatus, DataSourceResponseDTO, DataSourceType } from "../../clients/collector.client";
import { CloudTypeLogo } from "./CloudTypeLogo";
import { collectorType } from "../../utils/collector.utils";
import { Typography, TypographyProps } from "@material-ui/core";
import { CollectorResponseDTOPriceAdjustmentsSetEnum } from "../../open-api";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 120,
    height: 32,
  },
  failed: {
    backgroundColor: theme.palette.error.dark,
  },
  done: {
    backgroundColor: theme.palette.success.dark,
  },
}));

interface DataSourcesListProps {
  onClick: (ds?: DataSourceResponseDTO) => void;
  dataSources: DataSourceResponseDTO[];
}

export function DataSourcesList({ dataSources, onClick }: DataSourcesListProps) {
  const classes = useStyles();
  const state = getTableProps(dataSources, classes.logo);

  if (!dataSources) {
    return <CircularProgress />;
  }

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={"Data sources"}
        onRowClick={(event, rowData, togglePanel) => {
          if (rowData && rowData.type !== "SERVICE_PROVIDER") {
            onClick(rowData);
          }
        }}
        {...state}
        options={{ draggable: false, pageSize: 10, actionsColumnIndex: -1 }}
      />
    </>
  );
}

function getTableProps(
  dataSources: DataSourceResponseDTO[],
  logoClassName: string
): MaterialTableProps<DataSourceResponseDTO> {
  return {
    columns: [
      {
        title: "Type",
        field: "type",
        cellStyle: {
          textAlign: "center",
        },
        render: (val) => <CloudTypeLogo className={logoClassName} type={val.type as DataSourceType} />,
      },
      {
        render: collectorType,
      },
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Last updated",
        render: (val) => ucFirst(formattedDateTimeUntilNow(val.updatedAt)),
      },
      {
        title: "State",
        render: (val) => <CollectorStatusColumn status={val.status as CollectorStatus} />,
      },
      {
        title: "Price calculation",
        render: (val) => <PriceCalculationColumn priceAdjustmentsSet={val.priceAdjustmentsSet} />,
      },
    ],
    data: dataSources,
  };
}

interface CollectorStatusColumnProps {
  status: CollectorStatus;
}

function CollectorStatusColumn({ status }: CollectorStatusColumnProps) {
  return <>{ucFirst(status)}</>;
}

interface PriceCalculationColumnProps {
  priceAdjustmentsSet: string;
}

function PriceCalculationColumn({ priceAdjustmentsSet }: PriceCalculationColumnProps) {
  const [label, color] = getPriceCalculationLabelAndColor(priceAdjustmentsSet);
  const typographyProps = {
    color: color,
  } as TypographyProps;
  return <Typography {...typographyProps}>{label}</Typography>;
}

function getPriceCalculationLabelAndColor(priceAdjustmentsSet: string) {
  switch (priceAdjustmentsSet) {
    case CollectorResponseDTOPriceAdjustmentsSetEnum.SET:
      return ["Set", "primary"];
    case CollectorResponseDTOPriceAdjustmentsSetEnum.NOT_SET:
      return ["Not set", "initial"];
    default:
      return ["N/A", "textSecondary"];
  }
}
