import { useDataClientPromise } from "../hooks/client.hooks";
import { useServices } from "../services/context.service";
import { useCurrency } from "../hooks/config.hooks";
import { QueryFilter } from "../open-api";
import { DIMENSIONS, useNMostRecentPeriods } from "./lineitems.client";
import { useMonthlyBillingPeriod, useSelectedBillingPeriods } from "../hooks/billing-periods.hooks";
import { createContext, ReactNode, useMemo, useState } from "react";
import { StateContext, useContextOrThrow } from "../hooks/context.hooks";

export const useMultipleAggregateQuery = (
  dimensions: { arg: string; segment: string }[],
  filters: QueryFilter[] = [],
  limit?: number
) => {
  const queryService = useServices().aggregate;
  const currency = useCurrency();

  return useDataClientPromise(queryService.query, [
    dimensions.map((dim) => ({
      arg: dim.arg,
      limit: limit,
      includeOthers: false,
      segment: dim.segment,
    })),
    filters,
    currency,
  ]);
};

export const useAggregateQuery = (
  argument: string,
  segment: string,
  filters: QueryFilter[] = [],
  limit?: number,
  forceCurrency?: string
) => {
  const queryService = useServices().aggregate;
  const currency = useCurrency();

  return useDataClientPromise(
    queryService.query,
    [
      [
        {
          arg: argument,
          limit: limit,
          includeOthers: false,
          segment: segment,
        },
      ],
      filters,
      forceCurrency ?? currency,
    ],
    {
      useCacheKey: "query",
    }
  );
};

export const useMostRecentPeriodsFilter = (periods: number = 3, forceMonthly: boolean = false) => {
  const recentPeriods = useNMostRecentPeriods(periods, forceMonthly);
  const monthly = useMonthlyBillingPeriod();
  const collectorId = useSelectedBillingPeriods()?.collectorId;
  if (!forceMonthly && !monthly && collectorId) {
    return [
      {
        dimension: DIMENSIONS.BILLING_PERIOD,
        filters: recentPeriods,
      },
      {
        dimension: DIMENSIONS.DATASOURCE,
        filters: [collectorId],
      },
    ];
  } else {
    return [
      {
        dimension: DIMENSIONS.BILLING_MONTH,
        filters: recentPeriods,
      },
    ];
  }
};

export const useBillingPeriodArgument = () => {
  const monthly = useMonthlyBillingPeriod();
  return monthly ? "BILLING_MONTH" : "BILLING_PERIOD";
};

export const useMultipleMostRecentPeriodsFilter = (periods: number = 3) => {
  const recentPeriods = useNMostRecentPeriods(periods);
  const monthly = useMonthlyBillingPeriod();
  return recentPeriods.map((it) => ({
    dimension: monthly ? "BILLING_MONTH" : "BILLING_PERIOD",
    filters: [it],
  }));
};

export const QueryCacheContext = createContext<StateContext<Record<string, any>>>(undefined);
export const useQueryCache = (key: string) => {
  const cache = useContextOrThrow(QueryCacheContext)[0];
  const actualCache = cache[key];
  return useMemo(() => actualCache, [actualCache]);
};
export const useSetQueryCache = () => useContextOrThrow(QueryCacheContext)[1];

export function QueryCacheProvider({ children }: { children: ReactNode }) {
  const [queryCache, setQueryCache] = useState<Record<string, any>>({});

  return (
    <QueryCacheContext.Provider value={[queryCache, setQueryCache] as const}>{children}</QueryCacheContext.Provider>
  );
}
