import Fab from "@material-ui/core/Fab";
import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { CustomerPortalDialogCreate } from "../organizationalunit/dialogs/CustomerPortalDialogCreate";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface AddCViewProps {
  onSuccess: () => void;
}

export function AddCView({ onSuccess }: AddCViewProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);


  return (
    <>
      <Fab aria-label={"Add C-View"} className={classes.fab} color={"primary"} onClick={() => setOpen(true)}>
        <Tooltip title={"Add C-View"} placement="left">
          <AddIcon />
        </Tooltip>
      </Fab>
      {open && (
        <CustomerPortalDialogCreate
          customer={null}
          onSuccess={() => {
            if (onSuccess) {
              onSuccess();
            }
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
          open={open}
        />
      )}
    </>
  );
}
