import { AccordionItem, AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";
import { DialogCView } from "./DialogCView";
import { useDialog } from "../../../../hooks/dialog.hooks";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ChangeEvent } from "react";

interface AccordionCViewProps {
  organization: OrganizationalUnitResponseDTO;
}

export const AccordionCView = ({ organization }: AccordionCViewProps) => {
  const card: AccordionItem = {
    id: "data",
    summaryMain: "C-View",
    summarySub: "C-View based on Assigned Tags",
  };

  const [showCView, setShowCView, dialogProps] = useDialog<boolean>();

  const onChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    setShowCView(expanded);
    event.stopPropagation();
  };

  return (
    <>
      <AccordionItemList
        expanded={showCView || false}
        onChange={onChange}
        item={card}
        expandIcon={<VisibilityIcon />}
      />
      {organization && showCView && <DialogCView organization={organization} {...dialogProps} />}
    </>
  );
};
