import Grid from "@material-ui/core/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import { FormDateField } from "../../../components/form/FormDateField";
import { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DELIMITER_ID, useCsvUploadFormMethods } from "./CsvUploadForm";
import { useColumnNames, useDataRows, useFile } from "./CsvUpload";
import { DropEvent } from "react-dropzone";
import Papa, { ParseError } from "papaparse";
import { messageError } from "../../../services/message.service";
import { ControlledTextField } from "../../../components/form/FormComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropZoneRoot: {
      minHeight: 180,
    },
    gridItemPaddingRight: {
      paddingRight: theme.spacing(2),
    },
    error: {
      color: theme.palette.error.main,
    },
    gridMarginTop: {
      marginTop: theme.spacing(2),
    },
    gridMarginBottom: {
      marginBottom: theme.spacing(2),
    },
    gridFullHeight: {
      height: "100%",
    },
    hidden: {
      display: "none",
    },
    dropZoneContainer: {
      padding: theme.spacing(2),
      width: "100%",
    },
    dropZoneItem: {
      marginTop: theme.spacing(1),
      margin: "auto",
    },
    dropZoneImage: {
      width: 60,
    },
  })
);

export const CsvInvoiceInformation = () => {
  const formMethods = useCsvUploadFormMethods();
  const classes = useStyles();

  const [file, setFile] = useFile();
  const [, setColumnNames] = useColumnNames();
  const [, setDataRows] = useDataRows();

  const delimiter = formMethods.watch(DELIMITER_ID);

  useEffect(() => {
    file && handleFile(file);
    // eslint-disable-next-line
  }, [delimiter, file]);

  const handleFiles = (files: File[], event: DropEvent) => {
    setFile(files[0]);
  };

  const handleFile = async (file: File) => {
    let parsedData: any = await readCSV(file);
    setColumnNames(parsedData.data[0]);
    setDataRows(parsedData.data);
    if (!delimiter) {
      formMethods.setValue(DELIMITER_ID, parsedData.meta.delimiter);
    }
  };

  const readCSV = async (file: File) => {
    return new Promise((resolve) => {
      Papa.parse<any>(file, {
        header: false,
        delimiter: formMethods.getValues(DELIMITER_ID),
        preview: 5,
        complete: (results) => {
          resolve(results);
        },
        error(error: ParseError, file?: File) {
          console.error(error);
          messageError(`CSV parsing failed: ${error.message}`);
        },
      });
    });
  };

  const handleRemoveFile = (file: File) => {
    setFile(undefined);
    setColumnNames([]);
    setDataRows([]);
  };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <DropzoneArea
          filesLimit={1}
          onDrop={handleFiles}
          onDelete={handleRemoveFile}
          dropzoneText={"Drag and drop a .csv file here or click"}
          showFileNames={true}
          showPreviews={false}
          showPreviewsInDropzone={true}
          acceptedFiles={[".csv", "text/csv"]}
          classes={{
            root: classes.dropZoneRoot,
            textContainer: !!file ? classes.hidden : undefined,
          }}
          previewGridClasses={{
            container: classes.dropZoneContainer,
            item: classes.dropZoneItem,
            image: classes.dropZoneImage,
          }}
          previewGridProps={{
            container: {
              spacing: 2,
            },
            item: {
              xs: 6,
              spacing: 0,
            },
          }}
          useChipsForPreview={false}
        />
      </Grid>

      <Grid container spacing={2} item xs={6} direction={"row"} justify={"flex-start"}>
        <Grid item xs={12}>
          <ControlledTextField
            control={formMethods.control}
            name={"invoice.invoiceId"}
            label={"Invoice ID (required)"}
          />
        </Grid>
        <Grid
          container
          classes={{ root: classes.gridFullHeight }}
          item
          xs={12}
          direction={"row"}
          justify={"flex-start"}
        >
          <Grid classes={{ root: classes.gridItemPaddingRight }} item xs={6}>
            <FormDateField
              silent={true}
              error={formMethods.formState.errors.invoice?.startDate}
              name="invoice.startDate"
              label={"Start date of invoice"}
              formMethods={formMethods}
              rules={{
                required: "This field is required",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
              silent={true}
              error={formMethods.formState.errors.invoice?.endDate}
              name="invoice.endDate"
              label={"End date of Invoice"}
              formMethods={formMethods}
              rules={{
                required: "This field is required",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
