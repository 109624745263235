import { createContext, ReactNode, useState } from "react";
import { useContextOrThrow } from "../../../hooks/context.hooks";

export const ColorCache = createContext<Map<string, Map<string, string>> | undefined>(undefined);
export const useColorCache = () => useContextOrThrow(ColorCache);

export const palette = [
  "#70c92f",
  "#f8ca00",
  "#bd1550",
  "#e97f02",
  "#9d419c",
  "#7e4452",
  "#9ab57e",
  "#36a3a6",
  "#db7028",
  "#d34a29",
  "#c3604f",
  "#8e4377",
  "#8c7d68",
  "#68ac92",
  "#53b66b",
  "#b4ca18",
  "#e48e1b",
  "#d15135",
  "#cc3836",
  "#da5c1c",
  "#d06a35",
  "#b65669",
  "#934283",
  "#88436b",
  "#876a61",
  "#918f6f",
  "#79af8b",
  "#57a999",
  "#9dc91f",
  "#cbca10",
  "#e99d14",
  "#cc423c",
  "#c8303d",
  "#de6516",
  "#d67029",
  "#b05176",
  "#95428a",
  "#864365",
  "#85605d",
  "#939973",
  "#81b188",
  "#4fa89c",
  "#eca610",
  "#e08220",
  "#d55d30",
  "#c93940",
  "#c62a40",
  "#cf3f31",
  "#d75521",
  "#e06a12",
  "#da7321",
  "#cb6640",
  "#bb5a5e",
  "#ac4d7d",
  "#97428d",
  "#91427e",
  "#8a4370",
  "#844361",
  "#845b5b",
  "#897164",
  "#8f886c",
  "#949e75",
  "#86b186",
  "#72ae8e",
  "#5eaa96",
  "#4aa79e",
  "#49b07e",
  "#5dbc57",
  "#92c923",
  "#d6ca0c",
  "#eeac0d",
  "#c73343",
  "#c42743",
  "#e26d0f",
];

export function ColorCacheProvider(props: { children: ReactNode }) {
  const [cache] = useState(new Map());
  return <ColorCache.Provider value={cache}>{props.children}</ColorCache.Provider>;
}

export function useAttributeColorGenerator() {
  const cache = useColorCache();
  return (attributeTypeId: string, attributeValueId: string) => {
    let map = cache.get(attributeTypeId);
    if (map == null) {
      map = new Map<string, string>();
      cache.set(attributeTypeId, map);
    }
    let color = map.get(attributeValueId);
    if (color == null) {
      color = palette[map.size % palette.length];
      map.set(attributeValueId, color);
    }
    return color;
  };
}
