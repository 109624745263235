import { SetStateAction, useCallback } from "react";
import { GroupSegmentFilter } from "../model/GroupSegmentFilter";
import { Model } from "../../../model/Model";
import { DateTime } from "luxon";
import { ChartConfig } from "../model/ChartConfig";
import { useDashboards, useSelectedDashboardId, useSetDashboards } from "../NewDashPageProvider";
import { useDashboard } from "../NewDashPage";
import { DateRange } from "../model/DateRange";

/** A nullable dashboard, should be used in the DashboardTabs, as there may be no tabs, or no selected dashboard. */
export const useSelectedDashboard = () => {
  const dashboards = useDashboards();
  const dashboardId = useSelectedDashboardId();
  return dashboardId == null ? undefined : Model.get(dashboards, dashboardId);
};

export const useDateRange = () => [useDashboard().dateRange, useSetDashDateRange()] as const;
export const useSetDashDateRange = () => {
  const [dashboard, setDashboards] = [useDashboard(), useSetDashboards()];

  return useCallback(
    (dateRange: SetStateAction<DateRange>) => {
      const dateRange_ = typeof dateRange === "function" ? dateRange(dashboard.dateRange) : dateRange;
      return setDashboards((state) =>
        Model.update(state, { ...dashboard, updatedAt: DateTime.local(), dateRange: dateRange_ })
      );
    },
    [dashboard, setDashboards]
  );
};

export const useDashFilters = () => [useDashboard().filters, useSetDashFilters()] as const;
export const useSetDashFilters = () => {
  const [dashboard, setDashboards] = [useDashboard(), useSetDashboards()];
  return useCallback(
    (filters: SetStateAction<GroupSegmentFilter[]>) => {
      const filters_ = typeof filters === "function" ? filters(dashboard.filters) : filters;
      return setDashboards((state) =>
        Model.update(state, { ...dashboard, updatedAt: DateTime.local(), filters: filters_ })
      );
    },
    [dashboard, setDashboards]
  );
};

export const useDashCharts = () => [useDashboard().charts, useSetDashCharts()] as const;
export const useSetDashCharts = () => {
  const [dashboard, setDashboards] = [useDashboard(), useSetDashboards()];
  return useCallback(
    (charts: SetStateAction<ChartConfig[]>) => {
      const charts_ = typeof charts === "function" ? charts(dashboard.charts) : charts;
      return setDashboards((state) =>
        Model.update(state, { ...dashboard, updatedAt: DateTime.local(), charts: charts_ })
      );
    },
    [dashboard, setDashboards]
  );
};
