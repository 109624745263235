// From https://observablehq.com/@mbostock/localized-number-parsing
// parse a string as Number based on a given locale

export class NumberParser {
  _group: RegExp;
  _decimal: RegExp;
  _numeral: RegExp;
  _index: any;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    // @ts-ignore
    this._group = new RegExp(`[${parts.find((d) => d.type === "group").value}]`, "g");
    // @ts-ignore
    this._decimal = new RegExp(`[${parts.find((d) => d.type === "decimal").value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d: string) => index.get(d);
  }

  // Arjo: eslint did not like this, so rewrote it slightly
  parse(numberString: string) {
    const str = numberString
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index);
    return str ? +str : NaN;
  }
}
