import { createContext, useContext } from "react";
import { ConfigContext } from "../providers/config.provider";

/**
 * Can be used to override the currency used in a part of the application (such as when displaying invoices)
 */
export const CurrencyContext = createContext<string | undefined>(undefined);

export const useConfigContext = () => useContext(ConfigContext);

export const useCurrency = () => {
  const currency = useContext(CurrencyContext);
  const configCurrency = useConfigContext().config.currency;
  if (currency) {
    return currency;
  }
  return configCurrency;
};

export const useSetCurrency = () => {
  const setConfig = useConfigContext().setConfig;

  return (currency: string) => setConfig({ currency });
};

export const useHideDashboard = (component: string): [boolean, () => void] => {
  const { config, setConfig } = useConfigContext();

  const hidden = config.hideDashboard[component] ?? false;
  const setHidden = () =>
    setConfig({
      hideDashboard: {
        ...config.hideDashboard,
        [component]: true,
      },
    });

  return [hidden, setHidden];
};

export const useHiddenDashboards = (): [string[], (comp: string) => void] => {
  const { config, setConfig } = useConfigContext();

  const setVisible = (component: string) =>
    setConfig({
      hideDashboard: {
        ...config.hideDashboard,
        [component]: false,
      },
    });

  const hidden = Object.keys(config.hideDashboard).filter((key) => config.hideDashboard[key]);

  return [hidden, setVisible];
};
