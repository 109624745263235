import { useEffect, useRef, useState } from "react";
import { getRequest } from "../utils/api.utils";
import { messageError } from "../services/message.service";
import { useIsAuthenticated } from "./auth.hooks";
import { CacheNotYetCalculatedError } from "../services/context.service";
import { useQueryCache, useSetQueryCache } from "../clients/query.client";

interface DataClientConfig<T, K> {
  /**
   * Only query when user is authenticated?
   */
  authenticatedOnly?: boolean;
  /**
   * Can be set to false if some prerequisite is necessary before loading the
   * data. Has no effect if the refresh data function is called explicitly.
   */
  shouldLoad?: boolean;
  onSuccess?: (res: T) => K;
  onError?: (res: any) => K | void | undefined;
  useCacheKey?: string;
  overrideCall?: () => K;
}

const defaultConfig = {
  authenticatedOnly: false,
  shouldLoad: true,
};

export type DataClientResponse<T> = [T | null, () => void, boolean];
export type WithSignalPromise<T> = (signal?: AbortSignal) => Promise<T>;

type DataClientSignalPromise<T, P extends unknown[]> = (...args: P) => WithSignalPromise<T>;
type DataClientPromise<T, P extends unknown[]> = (...args: P) => Promise<T>;

export function useDataClientPromise<T, P extends unknown[], K = T>(
  promise: DataClientPromise<T, P> | DataClientSignalPromise<T, P>,
  args: P,
  config: DataClientConfig<T, K> = {}
): DataClientResponse<K> {
  const [res, setRes] = useState(null as K | null);
  const [refreshTimestamp, setRefreshTimestamp] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const cacheKey = JSON.stringify({
    fun: config.useCacheKey,
    args: args,
  });
  const cache = useQueryCache(cacheKey);
  const setCache = useSetQueryCache();
  const isAuthenticated = useIsAuthenticated();
  const mergedConfig = { ...defaultConfig, ...config };

  useEffect(() => {
    const ac = new AbortController();
    let isMounted = true;
    if (mergedConfig.overrideCall) {
      setRes(mergedConfig.overrideCall());
      setIsLoading(false);
    } else if (mergedConfig.useCacheKey && cache) {
      setRes(cache);
      setIsLoading(false);
    } else if (mergedConfig.shouldLoad && (!mergedConfig.authenticatedOnly || isAuthenticated)) {
      /*
      Actual fetch related code:
       */
      const keyForRequest = `${cacheKey}`;
      setIsLoading(true);

      const fun = promise(...args);
      (typeof fun === "function" ? fun(ac.signal) : fun)
        .then((json, response) => {
          const res = mergedConfig.onSuccess ? mergedConfig.onSuccess(json) : (json as unknown as K);
          // Only set result if the requested key is the actual value
          if (isMounted) {
            setRes(res);
            setIsLoading(false);
          }
          if (config.useCacheKey) {
            setCache((cache) => ({
              ...cache,
              [keyForRequest]: res,
            }));
          }
        })
        .catch((err) => {
          if (err instanceof DOMException) {
            console.log("Aborted call", err);
          } else {
            if (mergedConfig.onError) {
              const res = mergedConfig.onError(err);
              if (res) {
                setRes(res);
              }
              return res;
            }
            if (err.message) {
              messageError(err.message);
            } else {
              messageError("Error loading data..");
            }
            console.warn(err);
            if (isMounted) {
              setIsLoading(false);
            }
          }
        });
    }

    return () => {
      isMounted = false;
      if (!config.useCacheKey) {
        ac.abort();
      }
    };
    // The dependency check is skipped here, since we explicitly don't want to rerun this hook when the cache changes.
    // 'args' and 'config' are specified by the json string & shouldLoad, which encompasses pretty much everything.
    // However, it is important to take care that while changing this function, any additions should be considered for
    // adding to the dependency list.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promise, cacheKey, isAuthenticated, mergedConfig.shouldLoad, refreshTimestamp]);

  return [res, () => setRefreshTimestamp(`${new Date()}`), isLoading];
}

export function useDataClient<T extends any, K = T>(
  url: string,
  config: DataClientConfig<T, K> = {}
): [T | K | null, () => void, boolean] {
  const [res, setRes] = useState(null as K | null);
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const mergedConfig = {
    ...defaultConfig,
    ...config,
  };
  // Used for retrying cached items
  const timeoutRef = useRef<number>();

  const load = () => {
    setIsLoading(true);
    return getRequest(url)
      .then((json) => {
        setRes(mergedConfig.onSuccess ? mergedConfig.onSuccess(json) : json);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err instanceof CacheNotYetCalculatedError) {
          console.warn("Cache is not yet calculated");
          timeoutRef.current = window.setTimeout(() => load(), 2000);
        } else {
          if (mergedConfig.onError) {
            return mergedConfig.onError(err);
          }
          messageError("Error loading data..");
          console.warn(err);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (mergedConfig.shouldLoad && (!mergedConfig.authenticatedOnly || isAuthenticated)) {
      load();
    }
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isAuthenticated, mergedConfig.shouldLoad]);

  return [res, load, isLoading];
}

export const useApiCall = <T extends any[], K>(
  func: (...args: T) => Promise<K>
): [(...args: T) => Promise<K>, boolean] => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fetch = (...args: T) => {
    setIsSubmitting(true);
    return func(...args).finally(() => {
      setIsSubmitting(false);
    });
  };
  return [fetch, isSubmitting];
};
