import { Avatar, CardHeader, Grid, Typography } from "@material-ui/core";
import { useAppContext } from "../../hooks/auth.hooks";

export function AccountDetails() {
  const { user, workspace } = useAppContext();
  return (
    <>
      <CardHeader avatar={<Avatar aria-label="User">{user?.name[0].toUpperCase()}</Avatar>} title={user?.name} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>About you</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Email</Typography>
          <Typography variant={"caption"}>{user?.email}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Workspace name</Typography>
          <Typography variant={"caption"}>{workspace?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Subscription type</Typography>
          <Typography variant={"caption"}>{workspace?.subscriptionType}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Role</Typography>
          <Typography variant={"caption"}>{user?.role}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
