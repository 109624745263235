import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Controller, Path } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";
import InputLabel from "@material-ui/core/InputLabel";
import * as React from "react";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { SelectProps } from "@material-ui/core/Select";

export interface ControlledFormProps<T> {
  control: Control<T>;
  name: Path<T>;
  label?: string | ReactNode;
  /**
   * Can be used to change input after entering, and to force the cursor position to a specific position.
   * @param val the input string
   */
  transform?: (val: string) => { newVal: string; cursorPos?: number };
  disabled?: boolean;
}

export interface ControlledTextFieldProps<T> extends ControlledFormProps<T> {
  type?: React.InputHTMLAttributes<unknown>["type"];
}

export function ControlledTextField<T>({
  name,
  control,
  label,
  type,
  transform,
  multiline,
  defaultValue,
  ...rest
}: ControlledTextFieldProps<T> & TextFieldProps) {
  const ref = useRef<any>(null);
  const [cursorPos, setCursorPos] = useState<number | null>(null);

  // Used to handle cursor position from transform
  useLayoutEffect(() => {
    if (ref.current && cursorPos !== null) {
      ref.current.setSelectionRange(cursorPos, cursorPos);
    }
  }, [cursorPos]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState }) => (
        <TextField
          style={type === "hidden" ? { display: "none" } : {}}
          multiline={multiline}
          variant={"outlined"}
          type={type}
          onChange={(e) => {
            if (transform) {
              const { newVal, cursorPos } = transform(e.target.value);
              onChange(newVal);
              if (cursorPos !== undefined) {
                setCursorPos(cursorPos);
              }
            } else {
              onChange(e.target.value);
            }
          }}
          onBlur={onBlur}
          value={value ?? defaultValue}
          label={label ?? ""}
          inputProps={{
            ref,
            ...rest.inputProps,
          }}
          error={fieldState.invalid}
          helperText={fieldState.error?.message ?? " "}
          {...rest}
        />
      )}
    />
  );
}

interface SelectOptionProps {
  value: string | number;
  label: string | React.ReactNode;
}

export interface ControlledSelectSingleFieldProps<T> extends ControlledFormProps<T> {
  options: SelectOptionProps[];
}

export function ControlledSelectSingleField<T>({
  control,
  name,
  options,
  label,
  fullWidth,
  ...rest
}: ControlledSelectSingleFieldProps<T> & SelectProps) {
  const renderOptions = () =>
    options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState }) => (
        <FormControl fullWidth={fullWidth} variant="outlined" margin="dense" error={fieldState.invalid}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            label={label}
            labelId={`${name}-label}`}
            onChange={(event, child) => {
              onChange(event, child);
              onBlur();
            }}
            onClose={onBlur}
            onBlur={onBlur}
            value={value ?? ""}
            {...rest}
          >
            {renderOptions()}
          </Select>
          <FormHelperText>{fieldState.error?.message ?? " "}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

export interface ControlledSwitchProps<T> extends ControlledFormProps<T> {}

export function ControlledSwitch<T>({ control, name, label, disabled }: ControlledSwitchProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur }, fieldState }) => (
        <FormControlLabel
          control={<Switch color="primary" checked={!!value ?? false} onChange={onChange} />}
          label={label}
          disabled={disabled}
        />
      )}
    />
  );
}
