import { IconButton } from "@material-ui/core";
import { ReactElement } from "react";
import Tooltip from "@material-ui/core/Tooltip";

interface TooltipIconButtonProps {
  onClick?: (e?: any) => void;
  tooltip: string;
  ariaLabel: string;
  iconButton: ReactElement<any, any>;
  disabled?: boolean;
}

export function TooltipIconButton({ onClick, tooltip, ariaLabel, iconButton, disabled }: TooltipIconButtonProps) {
  return (
    <IconButton aria-label={ariaLabel} onClick={onClick} disabled={disabled}>
      <Tooltip title={tooltip}>{iconButton}</Tooltip>
    </IconButton>
  );
}
