import { Avatar } from "@material-ui/core";
import { GenericIcon, Icon } from "../../../theme/Icon";
import { Chips } from "../../../components/Chips";
import { PortalResponseDTO } from "../../../open-api";

interface PortalChipsProps {
  portals: PortalResponseDTO[];
}

export const PortalChips = ({ portals }: PortalChipsProps) => {
  return portals.length > 0 ? (
    <Chips<PortalResponseDTO>
      getAvatar={(option) => (
        <Avatar>
          <Icon icon={GenericIcon.SHARED} color={option.status === "ACCEPTED" ? "primary" : "inherit"} />
        </Avatar>
      )}
      chips={portals}
      getValue={(option) => option.email ?? ""}
      limit={2}
      getKey={(option) => option.id ?? ""}
    />
  ) : (
    <>Not shared</>
  );
};
