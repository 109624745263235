import { API } from "./context.service";
import { CreateCustomerPortalDTO, PortalResponseDTO } from "../open-api";

export interface PortalService {
  list(): Promise<Array<PortalResponseDTO>>;

  invite(orgUnitId: string, dto: CreateCustomerPortalDTO): Promise<void>;

  delete(id: string): Promise<void>;

  updateCanAddDataSource(id: string, canAddDataSource: boolean): Promise<void>;

  canWorkspaceAddDataSource(workspaceId: string): Promise<string>;
}

export class LivePortalService implements PortalService {
  constructor(private api: API) {}

  list = () => this.api.portal.listPortals();
  invite = (orgUnitId: string, dto: CreateCustomerPortalDTO) =>
    this.api.portal.createPortal({ customerIds: orgUnitId, createCustomerPortalDTO: dto });
  delete = (id: string) => this.api.portal.deletePortal({ portalId: id });
  updateCanAddDataSource = (id: string, canAddDataSource: boolean) =>
    this.api.portal.setCanAddDataSource({
      portalId: id,
      canAddDataSource: canAddDataSource,
    });
  canWorkspaceAddDataSource = (workspaceId: string) =>
    this.api.portal.canAddDataSource({
      workspaceId: workspaceId,
    });
}
