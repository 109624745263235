/* eslint-disable no-redeclare */
import { z } from "zod";
import { Dashboard, defaultDashboard } from "./Dashboard";
import {
  ArgumentAttribute,
  DATE_ATTRIBUTE,
  getAllGroupSegments,
  GroupSegment,
  migrateGroupSegmentId,
} from "./GroupSegment";
import { attributeEquals } from "./Attribute";
import { DISPLAY_SEGMENT, LineItemDisplay, pieChartTypes, rangeChartTypes, rawChartType } from "./ChartConfig";
import { DateTime } from "luxon";
import { isNotNull } from "../../../utils/predicate";
import { v4 } from "uuid";
import { assertNever } from "../../../utils/assert";
import { DateRange } from "./DateRange";

const StoredGroupSegment = z
  .object({
    id: z.string().transform(migrateGroupSegmentId),
    collectorId: z.string().optional(),
    idDisplay: z.string().optional(),
  })
  .default(() => ({ id: "date" }));

const StoredDisplaySegment = z.object({ value: z.string() });

const StoredChartConfig = z
  .object({
    id: z
      .string()
      .uuid()
      .default(() => v4()),
    title: z.string().optional(),
  })
  .and(
    z.union([
      z.object({
        type: z.enum(pieChartTypes),
        groupSegment: StoredGroupSegment,
        displaySegment: StoredDisplaySegment,
      }),
      z.object({
        type: z.enum(rangeChartTypes),
        groupSegment: StoredGroupSegment,
        displaySegment: StoredDisplaySegment,
        argument: StoredGroupSegment,
      }),
      z.object({ type: z.literal(rawChartType), displaySegment: StoredDisplaySegment }),
    ])
  );

export const StoredDashboard = z.object({
  version: z.literal(1),
  id: z.string().uuid(),
  title: z.string().min(1, "The dashboard title is required."),
  createdAt: z.string(),
  updatedAt: z.string(),
  savedAt: z.string(),
  dateRange: z
    .tuple([z.string(), z.string()])
    .or(DateRange)
    .transform(
      (it): DateRange => (Array.isArray(it) ? { type: "AbsoluteRange", startDate: it[0], endDate: it[1] } : it)
    ),
  filters: z.array(
    z.object({
      segment: StoredGroupSegment,
      filters: z.array(z.string()).or(z.array(z.object({ id: z.string(), label: z.string().optional() }))),
    })
  ),
  charts: z.array(StoredChartConfig),
  shared: z.boolean().optional(),
});

type StoredGroupSegment = z.infer<typeof StoredGroupSegment>;
type StoredDisplaySegment = z.infer<typeof StoredDisplaySegment>;
export type StoredDashboard = z.infer<typeof StoredDashboard>;

export function internalizeDashboards(storedDashboards: StoredDashboard[]) {
  if (storedDashboards.length === 0) {
    return [defaultDashboard];
  }
  return storedDashboards.map((it) => internalizeDashboard(it)).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
}

export function internalizeDashboard(storedDashboard: StoredDashboard): Dashboard {
  const internalizeArgument = (stored: StoredGroupSegment): ArgumentAttribute | undefined =>
    [DATE_ATTRIBUTE, ...getAllGroupSegments()].find((it) => attributeEquals(it, stored));

  const internalizeGroupSegment = (stored: StoredGroupSegment): GroupSegment | undefined => {
    if (stored.id === "billingCycle") {
      // For migration purposes, billing cycle filter is not allowed anymore
      return undefined;
    }
    return (
      getAllGroupSegments().find((it) => attributeEquals(it, stored)) || {
        type: "attribute",
        id: stored.id,
        label: stored.idDisplay ?? stored.id,
        category: "DATA_SOURCE_ATTRIBUTE",
        collectorId: stored.collectorId,
      }
    );
  };

  const internalizeDisplaySegment = (stored: StoredDisplaySegment) =>
    Object.values(DISPLAY_SEGMENT).find((it) => it.value === stored.value);

  return {
    ...storedDashboard,
    createdAt: DateTime.fromISO(storedDashboard.createdAt),
    updatedAt: DateTime.fromISO(storedDashboard.updatedAt),
    savedAt: DateTime.fromISO(storedDashboard.savedAt),
    editing: false,
    removing: false,
    saving: false,
    charts: storedDashboard.charts
      .map((chart) => {
        const displaySegment = internalizeDisplaySegment(chart.displaySegment);
        if (displaySegment == null) return null;

        if (chart.type === "raw") return { ...chart, displaySegment };
        const groupSegment = internalizeGroupSegment(chart.groupSegment);
        if (!groupSegment) {
          console.warn("Cannot find chart for: ", chart);
        }
        switch (chart.type) {
          case "line":
          case "spline":
          case "bar":
            const argument = internalizeArgument(chart.argument);
            if (groupSegment == null || argument == null) return null;
            return { ...chart, displaySegment, groupSegment, argument };
          case "donut":
          case "pie":
            if (groupSegment == null) return null;
            return { ...chart, displaySegment, groupSegment };
          default:
            return assertNever(chart);
        }
      })
      .filter(isNotNull),
    filters: storedDashboard.filters
      .map((it) => {
        const segment = internalizeGroupSegment(it.segment);
        if (segment == null) return null;
        return {
          ...it,
          filters: it.filters.map((option) => {
            if (typeof option == "string") {
              return { id: option };
            } else {
              return option;
            }
          }),
          segment,
        };
      })
      .filter(isNotNull),
    shared: storedDashboard.shared ?? false,
  };
}

export function externalizeDashboard(dashboard: Dashboard): StoredDashboard {
  const { version, id, title, createdAt, updatedAt, savedAt, charts, filters, dateRange } = dashboard;

  const externalizeGroupSegment = ({ id, collectorId, label }: GroupSegment): StoredGroupSegment => ({
    id,
    collectorId,
    idDisplay: label,
  });
  const externalizeDisplaySegment = ({ value }: LineItemDisplay): StoredDisplaySegment => ({ value });

  if (savedAt == null) throw Error("Saved at should be filled before saving");

  return {
    ...{ version, id, title, charts, filters, dateRange },
    createdAt: createdAt.toISO(),
    updatedAt: updatedAt.toISO(),
    savedAt: savedAt.toISO(),
    charts: dashboard.charts.map((chart) => {
      return chart.type === "raw"
        ? {
            ...chart,
            displaySegment: externalizeDisplaySegment(chart.displaySegment),
          }
        : {
            ...chart,
            displaySegment: externalizeDisplaySegment(chart.displaySegment),
            groupSegment: externalizeGroupSegment(chart.groupSegment),
          };
    }),
    filters: dashboard.filters.map((it) => ({ ...it, segment: externalizeGroupSegment(it.segment)! })),
  };
}
