import { FormPartial } from "../../../../utils/form.utils";
import { z } from "zod";
import Grid from "@material-ui/core/Grid";
import { ControlledTextField } from "../../../../components/form/FormComponents";
import { DropzoneArea } from "material-ui-dropzone";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DropEvent } from "react-dropzone";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(1),
  },
  dropzoneGridContainer: {
    paddingLeft: theme.spacing(4),
  },
  dropZoneRoot: {
    minHeight: 180,
    padding: theme.spacing(1),
  },
  dropZoneRootError: {
    minHeight: 180,
    padding: theme.spacing(1),
    borderColor: theme.palette.error.main,
  },
  hidden: {
    display: "none",
  },
  dropZoneContainer: {
    padding: theme.spacing(2),
    width: "100%",
  },
  dropZoneItem: {
    marginTop: theme.spacing(1),
    margin: "auto",
  },
  dropZoneImage: {
    width: 60,
  },
}));

interface GoogleCredentialsFormPartialProps extends Required<FormPartial<z.infer<typeof googleSchema>>> {}

export const googleSchema = z.object({
  dataset: z.string(),
  apiKey: z.string(),
});

export const GoogleCredentialsFormPartial = ({ control, formMethods }: GoogleCredentialsFormPartialProps) => {
  const classes = useStyles();
  const [file, setFile] = useState<File>();

  const handleFiles = (files: File[], event: DropEvent) => {
    setFile(files[0]);
    const fileReader = new FileReader();
    fileReader.readAsText(files[0]);
    fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      if (ev.target?.result) {
        formMethods.setValue("apiKey", `${ev.target.result}`);
      }
    };
  };

  const handleRemoveFile = (file: File) => {
    setFile(undefined);
    formMethods.setValue("apiKey", "");
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid xs={6}>
          <ControlledTextField
            control={control}
            name={"dataset"}
            label={"Dataset"}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid xs={6} classes={{ root: classes.dropzoneGridContainer }}>
          <DropzoneArea
            filesLimit={1}
            onDrop={handleFiles}
            onDelete={handleRemoveFile}
            dropzoneText={"Drag and drop your JSON key file here or click"}
            showFileNames={true}
            showPreviews={false}
            showPreviewsInDropzone={true}
            acceptedFiles={[".json", "application/json"]}
            classes={{
              root: formMethods.formState.errors.apiKey ? classes.dropZoneRootError : classes.dropZoneRoot,
              textContainer: !!file ? classes.hidden : undefined,
            }}
            previewGridClasses={{
              container: classes.dropZoneContainer,
              item: classes.dropZoneItem,
              image: classes.dropZoneImage,
            }}
            previewGridProps={{
              container: {
                spacing: 2,
              },
              item: {
                xs: 12,
              },
            }}
            useChipsForPreview={false}
          />
          <ControlledTextField control={control} name={"apiKey"} label={""} type="hidden" />
        </Grid>
      </Grid>
    </>
  );
};
