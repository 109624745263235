import { SearchableTagTreeAction } from "../../pages/organizationalunit/components/tags/SearchableTabTree";
import { TreeListItemProps } from "../../pages/organizationalunit/components/tags/TreeItemStyled";
import { GenericIcon } from "../../theme/Icon";
import { mapGrossType, mapOrganizationToIconType, OrganizationUnitType } from "../../mappers/organization.mapper";
import { roundToTwoDecimals } from "../localization.utils";
import {
  CollectorPriceAdjustment,
  FilterResponseValueDTO,
  OrganizationalUnitResponseDTO,
  OrganizationPriceAdjustment,
  PriceAdjustmentResponseDTO,
  TimedPriceAdjustment,
} from "../../open-api";
import { isNotNull } from "../predicate";
import { PriceAdjustments } from "../../model/PriceAdjustments";

export enum AdjustmentType {
  DISCOUNT = "discount",
  MARGIN = "margin",
  MARKUP = "markup",
}

export enum CustomerAdjustmentType {
  PROFIT = "profit",
}

export enum AdjustmentFilterType {
  SOURCE = "source",
  COST_TYPE = "costType",
  SERVICE = "service",
  DETAIL = "detail",
}

export const ADJUSTMENT = {
  TOOLTIP: {
    discount: "Set Discount",
    margin: "Set Margin",
    markup: "Set Markup",
    profit: "Set Profit",
  },
  ICON: {
    discount: GenericIcon.DISCOUNT,
    margin: GenericIcon.MARGIN,
    markup: GenericIcon.MARGIN,
  },
  ADD_ADJUSTMENT: "Add Adjustment",
  MAX_VALUE: 10 * 1000,
};

export const marginAdjustmentAvailable = (adjustment: PriceAdjustmentResponseDTO): boolean => {
  return !adjustment.adjustments.margin || adjustment.adjustments.margin.applied.inherited;
};

export const discountAdjustmentAvailable = (adjustment: PriceAdjustmentResponseDTO): boolean => {
  return !adjustment.adjustments.discount || adjustment.adjustments.discount.applied.inherited;
};

export const hasAdjustment = (adjustment: PriceAdjustmentResponseDTO): boolean => {
  return !!adjustment.adjustments.margin || !!adjustment.adjustments.markup || !!adjustment.adjustments.discount;
};

export const hasInheritedAdjustment = (adjustment: PriceAdjustments): boolean => {
  return (
    (!!adjustment.adjustments.margin && adjustment.adjustments.margin?.applied.inherited) ||
    (!!adjustment.adjustments.markup && adjustment.adjustments.markup?.applied.inherited) ||
    (!!adjustment.adjustments.discount && adjustment.adjustments.discount?.applied.inherited)
  );
};

export const hasOverWrittenAdjustment = (adjustment: PriceAdjustments): boolean => {
  return (
    (!!adjustment.adjustments.margin && adjustment.adjustments.margin?.overwritten?.length > 0) ||
    (!!adjustment.adjustments.markup && adjustment.adjustments.markup?.overwritten?.length > 0) ||
    (!!adjustment.adjustments.discount && adjustment.adjustments.discount?.overwritten?.length > 0)
  );
};

export const adjustmentSelected = (
  adjustment: PriceAdjustments,
  organization?: OrganizationalUnitResponseDTO
): boolean => {
  return (
    "organization" in adjustment &&
    adjustment.organization?.id === organization?.id &&
    ((!!adjustment.adjustments.margin && !adjustment.adjustments.margin?.applied.inherited) ||
      (!!adjustment.adjustments.markup && !adjustment.adjustments.markup?.applied.inherited) ||
      (!!adjustment.adjustments.discount && !adjustment.adjustments.discount?.applied.inherited))
  );
};

export const filterSelectedAdjustments = (
  adjustments: PriceAdjustments[],
  organization?: OrganizationalUnitResponseDTO
): PriceAdjustments[] => {
  return adjustments.filter((adjustment: PriceAdjustments) => adjustmentSelected(adjustment, organization));
};

export const adjustmentFromPriceAdjustment = (adjustment: PriceAdjustments, type: AdjustmentType): number => {
  return roundToTwoDecimals(Number(activeAdjustmentForType(adjustment, type)?.adjustmentValue ?? 0));
};

export const activeAdjustmentForType = (
  filter: PriceAdjustments,
  type: AdjustmentType
): TimedPriceAdjustment | undefined => {
  // TODO: when dates are implemented, compare start and end date to now
  return filter.adjustments[type]?.applied.adjustments[0];
};

export const nonInheritedActiveAdjustmentForType = (
  filter: PriceAdjustments,
  type: AdjustmentType
): TimedPriceAdjustment | undefined => {
  return filter.adjustments[type]?.applied.inherited ? undefined : activeAdjustmentForType(filter, type);
};

export const labelForType = (item: PriceAdjustments, filterType: string): string => {
  return getDisplayValueForType(item, filterType as AdjustmentFilterType).join(", ");
};

export const mapAssociatedPriceAdjustmentsToTreeListItems = (
  priceAdjustments: PriceAdjustmentResponseDTO[] | null,
  actions: SearchableTagTreeAction<PriceAdjustments>[],
  organization: OrganizationalUnitResponseDTO
): [TreeListItemProps<PriceAdjustments>[], string[]] => {
  if (!priceAdjustments) return [[], []];

  const adjustmentsGroupedByOrganization = priceAdjustments.reduce(
    (
      groupedByOrganization: {
        item: PriceAdjustmentResponseDTO;
        priceAdjustmentResponses: PriceAdjustmentResponseDTO[];
      }[],
      item: PriceAdjustmentResponseDTO
    ) => {
      let organization = groupedByOrganization.find((it) => it.item.organization?.id === item.organization?.id);

      if (!organization) {
        organization = {
          item: item,
          priceAdjustmentResponses: [],
        };
        groupedByOrganization.push(organization);
      }

      organization.priceAdjustmentResponses.push(item);

      return groupedByOrganization;
    },
    []
  );

  const nodeIds: string[] = [];

  return [
    adjustmentsGroupedByOrganization
      .sort((a, b) => {
        // ROOT goes first
        if (b.item.organization.unitType === OrganizationUnitType.ROOT) {
          return 1;
        }
        return a.item.organization.name.localeCompare(b.item.organization.name);
      })
      .map((it) => {
        // make tree list item and return with children
        const orgItem = createTreeItemForOrganization(it.item, organization);
        const [children, newNodeIds] = mapAllPriceAdjustmentsToTreeListItems(
          it.priceAdjustmentResponses,
          actions,
          organization
        );
        orgItem.children = children;

        nodeIds.concat(newNodeIds);
        return orgItem;
      }),
    nodeIds,
  ];
};

export const mapAllPriceAdjustmentsToTreeListItems = (
  priceAdjustments: PriceAdjustments[] | null,
  actions: SearchableTagTreeAction<PriceAdjustments>[],
  organization?: OrganizationalUnitResponseDTO
): [TreeListItemProps<PriceAdjustments>[], string[]] => {
  if (!priceAdjustments) return [[], []];

  const nodeIds: string[] = [];
  const priceAdjustmentsSorted = priceAdjustments.sort((a: PriceAdjustments, b: PriceAdjustments) =>
    customPriceAdjustmentComparator(a.key, b.key)
  );

  return [
    priceAdjustmentsSorted.reduce((resultTreeItems: TreeListItemProps<PriceAdjustments>[], item: PriceAdjustments) => {
      // TODO: deprecate in favor of function used in TreeItemStyled
      const tagSelected = adjustmentSelected(item, organization);

      /*
        Create tree structure from PriceAdjustmentResponseDTO[]

        Source
          \_ costType
              \_ Service
                  \_ Detail
         */

      const findOrCreateTreeListItemOfType = (
        parent: TreeListItemProps<PriceAdjustments> | undefined,
        item: PriceAdjustments,
        filterType: AdjustmentFilterType,
        parentTreeItemList?: TreeListItemProps<PriceAdjustments>[]
      ): TreeListItemProps<PriceAdjustments> | undefined => {
        if (parent) {
          parentTreeItemList = parent.children;
        }
        let treeListItem = parentTreeItemList?.find(
          (treeListItem) => treeListItem.nodeId === getNodeIdForItemWithType(item, filterType)
        );

        if (!treeListItem) {
          // don't create a tree list item if it's a ANY filter
          if (parent && item.filter[filterType].value === ADJUSTMENT_FILTER.ANY) {
            addAnyActionToItem(parent, actions);
            parent.selected = tagSelected;
          } else {
            treeListItem = createTreeItemForFilterType(item, filterType, organization);
            parentTreeItemList?.push(treeListItem);
            nodeIds.push(treeListItem.nodeId);
          }
        }

        return treeListItem;
      };

      // 1.
      // source item
      // source
      const sourceItem = findOrCreateTreeListItemOfType(undefined, item, AdjustmentFilterType.SOURCE, resultTreeItems);
      if (!sourceItem) {
        return resultTreeItems;
      }

      // 2.
      // costtype item
      // source -> costtype
      const costTypeItem = findOrCreateTreeListItemOfType(sourceItem, item, AdjustmentFilterType.COST_TYPE);
      if (!costTypeItem) {
        return resultTreeItems;
      }

      // 3.
      // service item
      // source -> costtype -> service
      const serviceItem = findOrCreateTreeListItemOfType(costTypeItem, item, AdjustmentFilterType.SERVICE);
      if (!serviceItem) {
        return resultTreeItems;
      }

      // 4.
      // detail item
      // source -> costtype -> service -> detail
      const detailItem = findOrCreateTreeListItemOfType(serviceItem, item, AdjustmentFilterType.DETAIL);
      if (detailItem) {
        detailItem.actions = actions;
      }

      return resultTreeItems;
    }, []),
    nodeIds,
  ];
};

/** private stuff **/

const ADJUSTMENT_FILTER_ICON = {
  source: GenericIcon.DATA_USAGE,
  costType: GenericIcon.EXTENSION,
  service: GenericIcon.CATEGORY,
  detail: GenericIcon.SALES,
};

const ADJUSTMENT_FILTER = {
  ANY: "<ANY>",
  ALL_ANY_KEY: "<ANY>|<ANY>|<ANY>|<ANY>",
};

const DISPLAY_VALUES = {
  ANY: "All line items",
  CONFIGURE_BELOW:
    "Globally applied across all line items. You can differ from this by configuring specific items below",
};

const customPriceAdjustmentComparator = (a: string, b: string) => {
  const order = { letters: 0, numbers: 1, specialChars: 2 };

  const getCategory = (char: string) => {
    if (/[a-zA-Z]/.test(char)) return "letters";
    if (/\d/.test(char)) return "numbers";
    return "specialChars";
  };

  const categoryA = getCategory(a[0]);
  const categoryB = getCategory(b[0]);

  if (order[categoryA] !== order[categoryB]) {
    return order[categoryA] - order[categoryB];
  } else {
    return a.localeCompare(b);
  }
};

const createTreeItemForFilterType = (
  item: PriceAdjustments,
  filterType: AdjustmentFilterType,
  organization?: OrganizationalUnitResponseDTO
): TreeListItemProps<PriceAdjustments> => {
  return {
    icon: ADJUSTMENT_FILTER_ICON[filterType],
    nodeId: getNodeIdForItemWithType(item, filterType),
    label: getDisplayValueForFilter(item.filter[filterType]),
    data: {
      item: item,
      filterKey: filterType,
    },
    selected: adjustmentSelected(item, organization),
    children: [],
    actions: [],
    tooltip: tooltipLabel(item, organization),
  };
};

const getDisplayValueForFilter = (filter: FilterResponseValueDTO): string => {
  return filter.displayValue === ADJUSTMENT_FILTER.ANY ? DISPLAY_VALUES.ANY : filter.displayValue;
};

const createTreeItemForOrganization = (
  item: PriceAdjustments & { organization: OrganizationalUnitResponseDTO },
  organization: OrganizationalUnitResponseDTO
): TreeListItemProps<PriceAdjustments> => {
  return {
    icon: mapOrganizationToIconType(item.organization),
    nodeId: item.organization.id,
    label: item.organization.name,
    data: {
      item: item,
    },
    children: [],
    actions: [],
    selected: item.organization.id === organization.id,
  };
};

const addAnyActionToItem = (
  parentItem: TreeListItemProps<PriceAdjustments>,
  actions: SearchableTagTreeAction<PriceAdjustments>[]
): void => {
  parentItem.actions = actions.map((action) => ({
    ...action,
    any: true,
  }));
};

const tooltipLabel = (item: PriceAdjustments, organization?: OrganizationalUnitResponseDTO): string => {
  const items: string[] = [];

  if (item.key === ADJUSTMENT_FILTER.ALL_ANY_KEY) {
    items.push(DISPLAY_VALUES.CONFIGURE_BELOW);
  }

  if (hasInheritedAdjustment(item)) {
    [AdjustmentType.MARKUP, AdjustmentType.MARGIN, AdjustmentType.DISCOUNT].map((adjustmentType) => {
      const adjustmentItem = item.adjustments[adjustmentType];
      return (
        adjustmentItem &&
        adjustmentItem.applied.inherited &&
        items.push(
          `${adjustmentItem.applied.adjustments[0].adjustmentValue}% ${mapGrossType(
            adjustmentType,
            organization
          )} inherited through \
        "${tooltipDisplayValue(adjustmentItem.applied)}"\
        \
        ${"organization" in adjustmentItem.applied ? `on ${adjustmentItem.applied.organization?.name}` : ""}`
        )
      );
    });
  }

  if (hasOverWrittenAdjustment(item)) {
    [AdjustmentType.MARKUP, AdjustmentType.MARGIN, AdjustmentType.DISCOUNT].map((adjustmentType) => {
      const overwritten: (CollectorPriceAdjustment | OrganizationPriceAdjustment)[] | undefined =
        item.adjustments[adjustmentType]?.overwritten ?? [];
      if (overwritten?.length > 0) {
        items.push(
          ...overwritten.map((it): string => {
            return `Overwrites ${
              // TODO: when we support date based adjustmentValues, get the right one here
              it.adjustments[0].adjustmentValue
            }% ${mapGrossType(adjustmentType, organization)} \
        "${tooltipDisplayValue(it)}"\
        \
        ${"organization" in it ? `on ${it.organization.name}` : ""}`;
          })
        );
      }
      return items;
    });
  }

  return items.length === 0 ? "No inherited or overwritten adjustments" : items.join("\n");
};

const tooltipDisplayValue = (item: OrganizationPriceAdjustment | CollectorPriceAdjustment): string => {
  let displayValues = [
    item.filter.source.displayValue,
    item.filter.costType.displayValue,
    item.filter.service.displayValue,
    item.filter.detail.displayValue,
  ];

  const anyIndex = displayValues.findIndex((it) => it === ADJUSTMENT_FILTER.ANY);

  if (anyIndex > -1) {
    displayValues.splice(anyIndex, displayValues.length - anyIndex, DISPLAY_VALUES.ANY);
  }

  return displayValues.join(", ");
};

const getDisplayValueForType = (item: PriceAdjustments, filterType: AdjustmentFilterType): string[] => {
  return getPropsForType("displayValue", item, filterType);
};

const getNodeIdForItemWithType = (item: PriceAdjustments, filterType: AdjustmentFilterType): string => {
  return [item.organization?.id, ...getPropsForType("value", item, filterType)].filter(isNotNull).join("_");
};

const getPropsForType = (
  prop: "value" | "displayValue",
  item: PriceAdjustments,
  filterType: AdjustmentFilterType
): string[] => {
  const filterValues: string[] = [];

  switch (filterType) {
    // @ts-ignore 'no-fallthrough'
    case AdjustmentFilterType.DETAIL:
      filterValues.push(item.filter.detail[prop]);
    // @ts-ignore 'no-fallthrough'
    case AdjustmentFilterType.SERVICE:
      filterValues.push(item.filter.service[prop]);
    // @ts-ignore 'no-fallthrough'
    case AdjustmentFilterType.COST_TYPE:
      filterValues.push(item.filter.costType[prop]);
    // @ts-ignore 'no-fallthrough'
    case AdjustmentFilterType.SOURCE:
    default:
      filterValues.push(item.filter.source[prop]);
  }

  return filterValues.reverse();
};
