import { FC, useEffect, useState } from "react";
import { CFCard } from "../../../components/layout/CFContainer";
import { DataSourceResponseDTO, postCollectorConfig } from "../../../clients/collector.client";
import { FormSelectSingle } from "../../../components/form/FormSelectSingle";
import {
  AttributeResponseDTO,
  CollectorConfigResponseDTO,
  ModuleHierarchyRequestAttributeDTO,
} from "../../../open-api";
import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Alert } from "@material-ui/lab";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface CollectorHierarchyProps {
  collector: DataSourceResponseDTO;
  onUpdate: () => void;
  collectorConfig?: CollectorConfigResponseDTO | null;
  attributes?: AttributeResponseDTO[];
}

interface HierarchyParam {
  type: string;
  param?: {
    id: string;
  };
  displayValue?: string;
}

const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    color: theme.palette.grey[100],
    fontWeight: "bold",
  },
}));

export const CollectorHierarchy: FC<CollectorHierarchyProps> = ({
  collectorConfig,
  onUpdate,
  collector,
  attributes,
}: CollectorHierarchyProps) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);
  const canEdit = !collector.canDisable || !collector.isEnabled;

  const [showConfirm, setShowConfirm] = useState(false);
  const hierarchyValues = collectorConfig?.hierarchy?.values;

  const [rootHierarchy, setRootHierarchy] = useState<HierarchyParam | undefined>(hierarchyValues?.rootHierarchy);
  const [detailHierarchy, setDetailHierarchy] = useState<HierarchyParam | undefined>(
    hierarchyValues?.detailHierarchy[0]
  );

  useEffect(() => {
    setRootHierarchy(hierarchyValues?.rootHierarchy);
    setDetailHierarchy(hierarchyValues?.detailHierarchy[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchyValues?.rootHierarchy, hierarchyValues?.detailHierarchy[0]]);

  const handleSave = (
    rootHierarchy: ModuleHierarchyRequestAttributeDTO,
    detailHierarchy?: ModuleHierarchyRequestAttributeDTO
  ) => {
    return postCollectorConfig(collector.id, {
      hierarchy: {
        rootHierarchy: rootHierarchy,
        detailHierarchy: detailHierarchy == null ? [] : [detailHierarchy],
      },
    }).then((it) => {
      if (onUpdate) {
        onUpdate();
      }
      setIsEditing(false);
    });
  };

  const showDefaultAttributes = attributes !== undefined && attributes.length === 0;

  const options = (
    showDefaultAttributes
      ? collectorConfig?.hierarchy?.options?.defaultAttributes?.map((it) => ({
          type: it.type,
          param: it.param,
          displayValue: it.displayValue,
        })) ?? []
      : attributes?.map((it) => ({
          type: it.type.type,
          param: it.type.param
            ? {
                id: it.type.param,
              }
            : undefined,
          displayValue: it.type.displayValue,
        })) ?? []
  ) // Use default attributes if there are no attributes available
    .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

  const rootHierarchyId = hierarchyId(rootHierarchy);
  const detailHierarchyId = hierarchyId(detailHierarchy);

  const costAllocationConfigRows = [
    {
      costAllocation: "Level 1",
      distributor: "Partners",
      msp: "Customers",
      enterprise: "Subsidiaries",
    },
    {
      costAllocation: "Level 2",
      distributor: "Customers",
      msp: "Applications",
      enterprise: "Departments",
    },
  ];

  return (
    <>
      <CFCard
        header={"Cost allocation configuration"}
        isLoading={!hierarchyValues}
        actions={{ edit: !isEditing ? () => setIsEditing(true) : undefined }}
        formActions={
          isEditing
            ? {
                cancel: () => setIsEditing(false),
                save: canEdit ? () => setShowConfirm(true) : undefined,
              }
            : undefined
        }
      >
        {!isEditing && (
          <>
            C-Facts lets you select 2 attributes that enable you to allocate cost to cost centers. Examples of best
            practice configurations are:
            <br />
            <TableContainer style={{ width: 500 }}>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>Cost allocation</TableCell>
                    <TableCell className={classes.tableHeadCell}>Distributor</TableCell>
                    <TableCell className={classes.tableHeadCell}>MSP</TableCell>
                    <TableCell className={classes.tableHeadCell}>Enterprise</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {costAllocationConfigRows.map((row) => (
                    <TableRow key={row.costAllocation}>
                      <TableCell component="th" scope="row">
                        {row.costAllocation}
                      </TableCell>
                      <TableCell>{row.distributor}</TableCell>
                      <TableCell>{row.msp}</TableCell>
                      <TableCell>{row.enterprise}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <br />
            <strong>{hierarchyValues?.rootHierarchy.displayValue}</strong> is used for level 1 cost allocation.
            <br />
            <br />
            {detailHierarchy ? (
              <>
                <strong>{detailHierarchy.displayValue}</strong> is used for level 2 cost allocation.
              </>
            ) : (
              <>Currently no attribute is selected to distinguish between applications and projects.</>
            )}
          </>
        )}
        {isEditing && !canEdit && (
          <>
            The collector needs to be disabled in the <strong>settings</strong> tab before the hierarchy configuration
            can be edited. After the process is finished, it will be automatically enabled again.
          </>
        )}
        {isEditing && canEdit && (
          <>
            Select up to <strong>2</strong> attributes that are used to allocate your cost data over your customers
            and/or departments. <br />
            <br />
            Note: You can only select attributes that are included in the dashboard. If an attribute is missing, please
            include it using the configuration options on this page.
            <br />
            <br />
            {showDefaultAttributes && (
              <Alert variant={"outlined"} severity={"info"}>
                There is no data collected yet, therefore you can only select default attributes right now. Not all of
                the selectable attributes necessarily exist in the available data.
              </Alert>
            )}
            <br />
            <FormSelectSingle
              md={4}
              label={"Attribute to distinguish customers and departments"}
              optionValue={"value"}
              value={hierarchyId(rootHierarchy)}
              autoWidth={true}
              optionId={"key"}
              options={options
                .map((it) => ({ key: hierarchyId(it), value: it.displayValue }))
                .filter((it) => it.key !== detailHierarchyId)}
              handleChange={(it) => {
                const value: HierarchyParam | undefined = JSON.parse(it.target.value);
                if (value == null) {
                  setRootHierarchy(undefined);
                } else {
                  setRootHierarchy(options.find((it) => it.type === value.type && it.param?.id === value.param?.id));
                }
              }}
            />
            <br />
            <FormSelectSingle
              label={"Attribute to distinguish applications and projects"}
              md={4}
              emptyOption={true}
              emptyValue={null}
              emptyLabel={"None"}
              optionValue={"value"}
              value={hierarchyId(detailHierarchy)}
              autoWidth={true}
              optionId={"key"}
              options={options
                .map((it) => ({
                  key: hierarchyId(it),
                  value: it.displayValue,
                }))
                .filter((it) => it.key !== rootHierarchyId)}
              handleChange={(it) => {
                const value: HierarchyParam | undefined = JSON.parse(it.target.value);
                if (value == null) {
                  setDetailHierarchy(undefined);
                } else {
                  setDetailHierarchy(options.find((it) => it.type === value.type && it.param?.id === value.param?.id));
                }
              }}
            />
          </>
        )}
      </CFCard>
      {showConfirm && (
        <ConfirmDialog
          open={!!showConfirm}
          title={"Are you sure?"}
          content={`Are you sure you want to change the hierarchy for your collector?\nThis will remove all existing mappings between data and organizations in C-Facts.\nThis action can take up to an hour. \nData shown in C-Facts is not guaranteed to be correct during processing`}
          onClose={() => setShowConfirm(false)}
          onConfirm={() => handleSave(rootHierarchy!, detailHierarchy)}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
    </>
  );
};

function hierarchyId(hierarchy?: HierarchyParam | null): string {
  if (hierarchy == null) return JSON.stringify(null);
  return JSON.stringify({
    type: hierarchy.type,
    param: hierarchy.param
      ? {
          id: hierarchy.param.id,
        }
      : undefined,
  });
}
