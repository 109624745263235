import { DateTime } from "luxon";

export const yearMonth = (date: DateTime) => date.toFormat("yyyy-MM");

export const parseDate = (date: string) => DateTime.fromISO(date, { zone: "utc" });

export const asDate = (date: DateTime) => date.toFormat("d MMM yyyy");

export const asDateTime = (date: DateTime) => date.toLocal().toFormat("d MMM yyyy, HH:mm");

export const asDisplayYearMonth = (date: DateTime) => date.toFormat("MMM yyyy");
