import { createBrowserHistory } from "history";
import { ThemeProvider } from "@material-ui/core/styles";
import { AppRouter } from "./AppRouter";
import { Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import { appConfig, appInit } from "../app.config";
import ErrorBoundary from "./ErrorBoundary";
import { AuthProvider } from "../providers/auth.provider";
import { ConfigProvider } from "../providers/config.provider";
import { StateProvider } from "../providers/state.provider";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { liveServices, ServicesContext } from "../services/context.service";
import { StylesProvider } from "@material-ui/core";
import { QueryCacheProvider } from "../clients/query.client";

const history = createBrowserHistory();

// TODO get from env variables
export const getEnvironment = () => {
  if (window.location.href.startsWith("https://demo.c-facts.com")) return "demo";
  if (window.location.href.startsWith("https://dev.c-facts.com")) return "dev";
  if (window.location.href.startsWith("https://app.development.c-facts.com")) return "development";
  if (window.location.href.startsWith("https://app.staging.c-facts.com")) return "staging";
  if (window.location.href.startsWith("https://controlcenter.c-facts.com")) return "production";
  return "local";
};

const dsn =
  getEnvironment() === "local"
    ? undefined
    : "https://e97dfd588b894f32963c842aaffc26c6@o406051.ingest.sentry.io/5848951";

// const dsn = "https://e97dfd588b894f32963c842aaffc26c6@o406051.ingest.sentry.io/5848951";

Sentry.init({
  dsn: dsn,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment: getEnvironment(),
  tracesSampleRate: 0.1,
});

export function App() {
  appInit();
  return (
    <StylesProvider injectFirst>
      <ServicesContext.Provider value={liveServices}>
        <ErrorBoundary>
          <QueryCacheProvider>
            <AuthProvider>
              <ConfigProvider>
                <StateProvider>
                  <ThemeProvider theme={appConfig.theme.theme}>
                    <CssBaseline />
                    <Router history={history}>
                      <AppRouter />
                    </Router>
                  </ThemeProvider>
                </StateProvider>
              </ConfigProvider>
            </AuthProvider>
          </QueryCacheProvider>
        </ErrorBoundary>
      </ServicesContext.Provider>
    </StylesProvider>
  );
}
