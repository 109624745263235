import { useRef } from "react";
import Chart, { Series, Title, Tooltip } from "devextreme-react/chart";
import { Card, CardContent } from "@material-ui/core";
import { ValueTooltip } from "../../../components/tooltips/ValueTooltip";
import { Information } from "../../../components/Information";
import { BarChartSkeleton } from "../../../components/skeletons/BarChartSkeleton";
import { OneCardGrid } from "../../../components/grid/OneCardGrid";
import Grid from "@material-ui/core/Grid";
import { MenuOptionsGraph } from "../../../components/menu/MenuOptionsGraph";
import { useAggregateQuery, useBillingPeriodArgument, useMostRecentPeriodsFilter } from "../../../clients/query.client";
import { aggregateToChart } from "../../newdash/model/Query";
import { DISPLAY_SEGMENT } from "../../newdash/model/ChartConfig";

export const TrendChart = () => {
  const chartRef = useRef<Chart>(null);
  const periods = useMostRecentPeriodsFilter(12);
  const argument = useBillingPeriodArgument();

  const [items, , loading] = useAggregateQuery(argument, "CLOUD", [...periods]);

  const [dataSource, series] = aggregateToChart(
    [DISPLAY_SEGMENT.COST, DISPLAY_SEGMENT.SALES, DISPLAY_SEGMENT.CROSS_CHARGING, DISPLAY_SEGMENT.PROFIT],
    items ? items[0] : undefined
  );

  if (loading || !items) {
    return (
      <OneCardGrid>
        <BarChartSkeleton />
      </OneCardGrid>
    );
  }
  if (items.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={10}>
            <Information
              title={"This pivot chart shows all data of all selected customers and services"}
              align={"left"}
            />
          </Grid>
          <Grid item container justify={"flex-end"} xs={2}>
            <MenuOptionsGraph png={chartRef} svg={chartRef} />
          </Grid>
        </Grid>
        <Chart
          ref={chartRef}
          dataSource={dataSource}
          commonSeriesSettings={{ argumentField: "argument", type: "line" }}
        >
          <Title text=" " />
          <Tooltip
            enabled={true}
            contentRender={(props) => (
              <ValueTooltip {...props} argumentText={props.argumentText} title={props.seriesName} value={props.value} />
            )}
          />
          {series.map((item) => (
            <Series key={item.key} valueField={item.key} name={item.label} />
          ))}
        </Chart>
      </CardContent>
    </Card>
  );
};
