import { Button, CircularProgress, IconButton, Portal, Slide, Snackbar, useTheme } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useState } from "react";
import { css } from "@emotion/css";
import { Alert } from "@material-ui/lab";
import { Dashboard } from "../model/Dashboard";
import { useOnSaveDashboard } from "../events/dashboard.events";

export interface SaveDashboardForm {
  submitting: boolean;
  status: "none" | "success" | "error";
  message?: string;
}

export function SaveDashboard({ dashboard }: { dashboard: Dashboard }) {
  const theme = useTheme();
  const [form, setForm] = useState<SaveDashboardForm>({ submitting: false, status: "none" });
  const onSaveDashboard = useOnSaveDashboard([form, setForm], dashboard);
  const showSaveButton = dashboard.savedAt == null || dashboard.updatedAt > dashboard.savedAt;

  const onClose = () => setForm((it) => ({ ...it, status: "none" }));
  const onRetry = () => {
    onClose();
    onSaveDashboard();
  };
  return (
    <>
      {showSaveButton && (
        <IconButton disabled={form.submitting} onClick={onSaveDashboard}>
          {dashboard.saving ? (
            <CircularProgress className={css({ color: theme.palette.common.white })} size={20} />
          ) : (
            <SaveIcon fontSize={"small"} />
          )}
        </IconButton>
      )}
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={form.status !== "none"}
          autoHideDuration={6000}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
          TransitionProps={{ timeout: 300 }}
          onClose={onClose}
        >
          {form.status === "error" ? (
            <Alert
              variant={"filled"}
              severity={"error"}
              onClose={onClose}
              action={
                <Button size={"small"} variant={"outlined"} onClick={onRetry}>
                  Try again
                </Button>
              }
            >
              {form.message ?? "Something went wrong"}
            </Alert>
          ) : (
            <Alert variant={"filled"} severity={"success"} onClose={onClose}>
              Saved dashboard
            </Alert>
          )}
        </Snackbar>
      </Portal>
    </>
  );
}
