import { ResetComponent } from "./ResetComponent";
import { Grid, Typography } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export function ResetPasswordComponent({ ...props }) {
  const resetCode = props.match.params.code;

  return (
    <ResetComponent resetCode={resetCode}>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <LockOpenIcon style={{ fontSize: 80 }} />
      </Grid>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <Typography variant={"h6"}>Reset password</Typography>
      </Grid>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <Typography>Please create a new password for your account.</Typography>
      </Grid>
    </ResetComponent>
  );
}
