import { FormControlLabel, Grid, GridSize, Switch } from "@material-ui/core";
import { useState } from "react";
import { ConfirmDialog } from "../dialogs/ConfirmDialog";
import { ControlledFormProps } from "./FormComponents";
import { UseFormSetValue } from "react-hook-form";

interface FormSwitchFieldProps<T> extends ControlledFormProps<T> {
  id: string;
  xs?: GridSize;
  md?: GridSize;
  setValue: UseFormSetValue<T>;
  defaultValue?: boolean;
  inputRef?: any;
  confirmationDialogTitle?: string;
  confirmationDialogContent?: string;
}

export function FormSwitchField<T>({
  xs = 12,
  md = 12,
  name,
  id,
  label,
  defaultValue = false,
  setValue,
  confirmationDialogTitle = "",
  confirmationDialogContent = "",
  control,
  ...props
}: FormSwitchFieldProps<T>) {
  const [isChecked, setIsChecked] = useState(defaultValue);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    confirmationDialogTitle ? setIsOpenDialog(true) : setIsChecked(event.target.checked);
  };

  const closeDialog = () => {
    setIsOpenDialog(false);
  };

  const switchIsChecked = () => {
    setIsChecked(!isChecked);
    // @ts-ignore
    setValue(name, !isChecked);
  };

  return (
    <>
      {confirmationDialogTitle && (
        <ConfirmDialog
          open={isOpenDialog}
          title={confirmationDialogTitle}
          content={confirmationDialogContent}
          onClose={closeDialog}
          onConfirm={() => {
            closeDialog();
            switchIsChecked();
            // for ConfirmDialog's internal state...
            return Promise.resolve();
          }}
        />
      )}
      <Grid item xs={xs} md={md}>
        <FormControlLabel
          label={label}
          control={
            <Switch checked={isChecked} onChange={handleChange} name={name} id={id} color={"primary"} {...props} />
          }
        />
      </Grid>
    </>
  );
}
