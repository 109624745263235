/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CreateCustomerPortalDTO,
    PortalResponseDTO,
} from '../models';

export interface CreateManagedPortalRequest {
    customerIds: string;
    createCustomerPortalDTO: CreateCustomerPortalDTO;
}

export interface DeleteManagedPortalRequest {
    managedPortalId: string;
}

export interface ListManagedPortalsForOrgUnitRequest {
    organizationalUnitId: string;
}

/**
 * 
 */
export class ManagedCustomerPortalControllerApi extends runtime.BaseAPI {

    /**
     */
    async createManagedPortalRaw(requestParameters: CreateManagedPortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerIds === null || requestParameters.customerIds === undefined) {
            throw new runtime.RequiredError('customerIds','Required parameter requestParameters.customerIds was null or undefined when calling createManagedPortal.');
        }

        if (requestParameters.createCustomerPortalDTO === null || requestParameters.createCustomerPortalDTO === undefined) {
            throw new runtime.RequiredError('createCustomerPortalDTO','Required parameter requestParameters.createCustomerPortalDTO was null or undefined when calling createManagedPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{customerIds}/managed-portal`.replace(`{${"customerIds"}}`, encodeURIComponent(String(requestParameters.customerIds))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createCustomerPortalDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createManagedPortal(requestParameters: CreateManagedPortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createManagedPortalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteManagedPortalRaw(requestParameters: DeleteManagedPortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.managedPortalId === null || requestParameters.managedPortalId === undefined) {
            throw new runtime.RequiredError('managedPortalId','Required parameter requestParameters.managedPortalId was null or undefined when calling deleteManagedPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{customerId}/managed-portal/{managedPortalId}`.replace(`{${"managedPortalId"}}`, encodeURIComponent(String(requestParameters.managedPortalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteManagedPortal(requestParameters: DeleteManagedPortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteManagedPortalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async listManagedPortalsForOrgUnitRaw(requestParameters: ListManagedPortalsForOrgUnitRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PortalResponseDTO>>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling listManagedPortalsForOrgUnit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managed-portals/{organizationalUnitId}`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listManagedPortalsForOrgUnit(requestParameters: ListManagedPortalsForOrgUnitRequest, initOverrides?: RequestInit): Promise<Array<PortalResponseDTO>> {
        const response = await this.listManagedPortalsForOrgUnitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listManagedPortalsForWorkspaceRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PortalResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/managed-portals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listManagedPortalsForWorkspace(initOverrides?: RequestInit): Promise<Array<PortalResponseDTO>> {
        const response = await this.listManagedPortalsForWorkspaceRaw(initOverrides);
        return await response.value();
    }

}
