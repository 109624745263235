import { StateContext } from "../hooks/context.hooks";
import { BillingPeriodFilter } from "../clients/billing-periods.client";
import { createContext, ReactNode, useState } from "react";

export const BillingCycleContext = createContext<StateContext<BillingPeriodFilter | null>>([null, () => null]);

export function SelectBillingCycleProvider({ children }: { children: ReactNode }) {
  const cache = useState<BillingPeriodFilter | null>(null);
  return <BillingCycleContext.Provider value={cache}>{children}</BillingCycleContext.Provider>;
}
