import { failFormValidation } from "../../services/message.service";
import { GridSize, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldError } from "react-hook-form";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

type FormTextFieldProps = Omit<TextFieldProps, "error"> & {
  error?: FieldError;
  silent?: boolean;
  name: string;
  variant?: string;
  initValue?: string;
  id?: string;
  xs?: GridSize;
  md?: GridSize;
};

export function FormTextField({
  xs = 12,
  md = 12,
  label,
  error,
  initValue,
  silent = false,
  variant = "outlined",
  name,
  id = name,
  ...props
}: FormTextFieldProps) {
  const classes = useStyles();

  if (error && !silent && label) {
    failFormValidation(label as string, error.message as string);
  }

  const display = props.type === "hidden" ? "none" : "";

  return (
    <Grid item xs={xs} md={md}>
      <TextField
        fullWidth={true}
        value={initValue}
        variant={variant}
        error={!!error}
        label={label}
        style={{ display: display }}
        name={name}
        id={id}
        {...props}
      />
      {error ? <Typography className={classes.error}>{error.message}</Typography> : ""}
    </Grid>
  );
}
