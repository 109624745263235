import Fab from "@material-ui/core/Fab";
import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { DataSourceDialog } from "./DataSourceDialog";
import { DataSourceResponseDTO } from "../../clients/collector.client";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export function DataSourceAdd({ onDataSourceAdded }: { onDataSourceAdded: (ds?: DataSourceResponseDTO) => void }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Fab aria-label={"Add data source"} className={classes.fab} color={"primary"} onClick={toggleOpen}>
        <Tooltip title={"Add data source"} placement="left">
          <AddIcon />
        </Tooltip>
      </Fab>

      {isOpen && (
        <DataSourceDialog
          toggle={toggleOpen}
          onSuccess={(ds?: DataSourceResponseDTO) => {
            onDataSourceAdded(ds);
            setIsOpen(false);
          }}
          open={isOpen}
        />
      )}
    </>
  );
}
