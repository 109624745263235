import Dialog from "@material-ui/core/Dialog";
import { useState } from "react";
import { DataSourceResponseDTO, DataSourceType } from "../../clients/collector.client";
import { StepperDialog, StepperDialogObj } from "../../components/dialogs/StepperDialog";
import { DataSourceDialogTiles } from "./DataSourceDialogTiles";
import { AwsDataSourceForm } from "./forms/AwsDataSourceForm";
import { AzureDataSourceForm } from "./forms/AzureDataSourceForm";
import { OracleDataSourceForm } from "./forms/OracleDataSourceForm";
import { Typography } from "@material-ui/core";
import { DataSourceCreatedForm } from "./forms/DataSourceCreatedForm";
import { messageError, messageSuccess } from "../../services/message.service";
import { UploadDataSourceForm } from "./forms/UploadDataSourceForm";
import { GoogleDataSourceForm } from "./forms/GoogleDataSourceForm";

interface DataSourceDialogProps {
  toggle?: () => void;
  onSuccess?: (ds?: DataSourceResponseDTO) => void;
  open?: boolean;
  dialogWrapper?: boolean;
  buttons?: { close: boolean; cancel: boolean };
}

export function DataSourceDialog({
  toggle,
  onSuccess,
  open = true,
  dialogWrapper = true,
  buttons = { close: true, cancel: true },
}: DataSourceDialogProps) {
  const [type, setType] = useState(null as DataSourceType | null);
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    toggle && toggle();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSuccess = (ds?: DataSourceResponseDTO) => {
    messageSuccess("Data source added");
    handleNext();
    onSuccess && onSuccess(ds);
  };

  const handleError = (e: any) => {
    messageError(
      "Could not connect with the given API Credentials, please make sure they are correct and the credentials have the appropriate permissions."
    );
  };

  const step1: StepperDialogObj = {
    stepTitle: "Select data source type",
    step: (
      <DataSourceDialogTiles
        onSetType={(type) => {
          setType(type);
          handleNext();
        }}
        onClose={handleClose}
        buttonCancel={buttons.cancel}
      />
    ),
  };

  const step2: StepperDialogObj = {
    stepTitle: "Add credentials",
    step: (
      <DatasourceForms
        type={type}
        onClose={handleClose}
        onSuccess={handleSuccess}
        onError={handleError}
        onBack={handleBack}
        buttonCancel={buttons.cancel}
      />
    ),
  };

  const step3: StepperDialogObj = {
    stepTitle: "Done",
    step: <DataSourceCreatedForm onClose={handleClose} />,
  };

  const steps = [step1, step2, step3];

  if (!dialogWrapper) {
    return (
      <StepperDialog steps={steps} dialogTitle={"Add new Data Source"} onClose={handleClose} activeStep={activeStep} />
    );
  }
  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose} disableBackdropClick>
      <StepperDialog steps={steps} dialogTitle={"Add new Data Source"} onClose={handleClose} activeStep={activeStep} />
    </Dialog>
  );
}
interface DatasourceFormsProps {
  type: DataSourceType | null;
  onClose: () => void;
  onBack: () => void;
  onSuccess: () => void;
  onError: (e: any) => void;
  buttonCancel: boolean;
}

export const DatasourceForms = ({ type, ...formProps }: DatasourceFormsProps) => {
  switch (type) {
    case DataSourceType.AWS:
      return <AwsDataSourceForm {...formProps} />;
    case DataSourceType.AZURE:
      return <AzureDataSourceForm {...formProps} />;
    case DataSourceType.ORACLE:
      return <OracleDataSourceForm {...formProps} />;
    case DataSourceType.GOOGLE:
      return <GoogleDataSourceForm {...formProps} />;
    case DataSourceType.UPLOAD:
      return <UploadDataSourceForm {...formProps} />;
    default:
      return <Typography>Unknown data source</Typography>;
  }
};
