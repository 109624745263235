import { useHistory, useLocation } from "react-router-dom";

export const useRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  return (url: string, search: Record<string, string | null> = {}) => {
    const params = new URLSearchParams(location.search);
    [...params.keys()].forEach((it) => {
      if (it !== "ft") {
        params.delete(it);
      }
    });
    Object.keys(search).forEach((it) => {
      if (typeof search[it] === "string") {
        params.set(it, search[it] as string);
      } else {
        params.delete(it);
      }
    });

    history.push({
      pathname: url,
      search: params.toString(),
    });
  };
};
