import { DIMENSIONS } from "../clients/lineitems.client";
import { GenericIcon, Icon } from "../theme/Icon";
import { DateTime } from "luxon";
import { LineItemProperty, NestedLineItemDTO, NestedLineItemDTOV2 } from "graphql-simple-bindings/lineitems";

export const mapDescription = (item: NestedLineItemDTOV2 | NestedLineItemDTO) => {
  switch (item.dimension) {
    case DIMENSIONS.TYPE:
      return mapType(item.description);
    default:
      return typeof item.description === "string" ? item.description : item.description.displayValue;
  }
};

export const mapType = (description: string | LineItemProperty) => {
  switch (typeof description === "string" ? description : description.value) {
    case "UNMAPPED":
      return "Unassigned";
    case "CUSTOMER":
      return "Customer";
    case "INTERNAL":
      return "Internal";
    case "RESELLER":
      return "Reseller";
    case "INCOMING":
      return "Service provider";
    default:
      return typeof description === "string" ? description : description.displayValue;
  }
};

export const mapIcon = (item: NestedLineItemDTOV2) => {
  switch (item.dimension) {
    case DIMENSIONS.TYPE:
      return mapTypeIcon(item.description.value);
    default:
      return null;
  }
};

export const mapTypeIcon = (description: string) => {
  switch (description) {
    case "UNMAPPED":
      return <Icon icon={GenericIcon.WARNING} />;
    case "CUSTOMER":
    case "INTERNAL":
    case "RESELLER":
      return <Icon icon={description as GenericIcon} />;
    default:
      return null;
  }
};

export const mapBillingPeriod = (date: string, short = false) => {
  if (/^\d{4}-\d{2}$/g.test(date)) {
    // yearmonth
    return DateTime.fromFormat(date, DATEFORMAT.YEARMONTH).toFormat(DATEFORMAT.YEARMONTH_FULL);
  } else {
    const split = date.split("|");
    if (split.length === 2) {
      const [from, to] = split.map((it) => DateTime.fromISO(it));
      const showFromYear = from.year !== to.year;
      if (short) {
        return `${to.toFormat(DATEFORMAT.FULL_WITH_YEAR)}`;
      } else {
        return `${from.toFormat(
          showFromYear ? DATEFORMAT.FULL_WITH_YEAR : DATEFORMAT.FULL_WITHOUT_YEAR
        )} &mdash; ${to.toFormat(DATEFORMAT.FULL_WITH_YEAR)}`;
      }
    }
  }

  return date;
};

const DATEFORMAT = {
  FULL_WITH_YEAR: "dd MMM yyyy",
  FULL_WITHOUT_YEAR: "dd MMM",
  SHORT_WITH_YEAR: "MM/dd/yy",
  SHORT_WITHOUT_YEAR: "MM/dd",
  YEARMONTH: "yyyy-MM",
  YEARMONTH_FULL: "MMMM yyyy",
};
