import { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { PipelineDetailStatus } from "./PipelineDetailStatus";
import ListItemText from "@material-ui/core/ListItemText";
import { formattedDateTimeUntilNow } from "../../../../utils/localization.utils";
import { css } from "@emotion/css";
import { PipelineRunEvent } from "../../../../open-api";

interface RunEventProps {
  event?: PipelineRunEvent;
  includeDebug: boolean;
  state?: "ERROR" | "FINISHED" | "IN_PROGRESS";
  timestamp?: string; // Datestring
}

export const RunEvent = ({ event, state, includeDebug, timestamp }: RunEventProps) => {
  const [shouldFormat, setShouldFormat] = useState(false);
  let styledDescription = (
    <span
      className={
        shouldFormat
          ? ""
          : css({
              display: "inline-block",
              whiteSpace: "nowrap",
              maxWidth: "calc(100vw - 350px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
            })
      }
    >
      {event?.timestamp}: {event?.description ?? ""}
    </span>
  );

  if (event?.description && shouldFormat) {
    try {
      styledDescription = (
        <span>
          {event?.timestamp}: <pre>{JSON.stringify(JSON.parse(event?.description), null, 2)}</pre>
        </span>
      );
    } catch (e) {
      // do nothing
    }
  }

  return (
    <ListItem onDoubleClick={() => setShouldFormat((it) => !it)}>
      <ListItemAvatar>
        <PipelineDetailStatus status={state} />
      </ListItemAvatar>

      {event ? (
        <ListItemText
          primary={includeDebug ? `[${event.type}] ${event.name}` : event.name}
          secondary={includeDebug ? styledDescription : `${formattedDateTimeUntilNow(timestamp ?? event.timestamp)}`}
        />
      ) : (
        <ListItemText primary={"Scheduled..."} />
      )}
    </ListItem>
  );
};
