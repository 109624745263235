/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    NotificationResponseDTO,
    OrgAndWorkspaceIdsDTO,
} from '../models';

export interface GetOrganizationsAndWorkspacesByAttributeRequest {
    attributeType: string;
    attributeValue: string;
    xAPIKey?: string;
}

/**
 * 
 */
export class ActionCenterControllerApi extends runtime.BaseAPI {

    /**
     */
    async getNotificationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NotificationResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/action-center/get-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getNotifications(initOverrides?: RequestInit): Promise<Array<NotificationResponseDTO>> {
        const response = await this.getNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNumberOfUnreadNotificationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/action-center/count-unread-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getNumberOfUnreadNotifications(initOverrides?: RequestInit): Promise<number> {
        const response = await this.getNumberOfUnreadNotificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationsAndWorkspacesByAttributeRaw(requestParameters: GetOrganizationsAndWorkspacesByAttributeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrgAndWorkspaceIdsDTO>> {
        if (requestParameters.attributeType === null || requestParameters.attributeType === undefined) {
            throw new runtime.RequiredError('attributeType','Required parameter requestParameters.attributeType was null or undefined when calling getOrganizationsAndWorkspacesByAttribute.');
        }

        if (requestParameters.attributeValue === null || requestParameters.attributeValue === undefined) {
            throw new runtime.RequiredError('attributeValue','Required parameter requestParameters.attributeValue was null or undefined when calling getOrganizationsAndWorkspacesByAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-key'] = String(requestParameters.xAPIKey);
        }

        const response = await this.request({
            path: `/api/action-center/unit/{attributeType}/{attributeValue}`.replace(`{${"attributeType"}}`, encodeURIComponent(String(requestParameters.attributeType))).replace(`{${"attributeValue"}}`, encodeURIComponent(String(requestParameters.attributeValue))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getOrganizationsAndWorkspacesByAttribute(requestParameters: GetOrganizationsAndWorkspacesByAttributeRequest, initOverrides?: RequestInit): Promise<OrgAndWorkspaceIdsDTO> {
        const response = await this.getOrganizationsAndWorkspacesByAttributeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async readNotificationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/action-center/read-notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async readNotifications(initOverrides?: RequestInit): Promise<number> {
        const response = await this.readNotificationsRaw(initOverrides);
        return await response.value();
    }

}
