import { useRootOrganizations } from "../../../../clients/organizationalunit.client";
import { FastFact, FastFactSkeleton } from "../FastFact";
import { DIMENSIONS } from "../../../../clients/lineitems.client";
import { FastFactLineProps } from "../FastFactLine";
import { useAggregateQuery, useMostRecentPeriodsFilter } from "../../../../clients/query.client";
import { useIsManagedCView } from "../../../../hooks/auth.hooks";
import { QuerySegment } from "../../../../open-api";

export function FastFactCustomers() {
  const [organizations, , loading] = useRootOrganizations();
  const isManagedCview = useIsManagedCView();

  const periods = useMostRecentPeriodsFilter(1);
  const [items, , loading2] = useAggregateQuery(DIMENSIONS.TYPE, DIMENSIONS.ORGANIZATION, [...periods]);

  if (!items || !organizations || loading || loading2) {
    return <FastFactSkeleton />;
  }
  if (organizations.length === 0) {
    return null;
  }

  const lines: FastFactLineProps[] = [];

  if (isManagedCview) {
    const activeOrganizations = items[0].arguments.reduce(
      (accumulator: number, currentValue: QuerySegment) => accumulator + currentValue.points.length,
      0
    );

    lines.push({
      key: "Internal organizations",
      description: "Internal organizations",
      value: `${activeOrganizations ?? 0}/${items[0].arguments.length}`,
    });
  } else {
    const customers = organizations.filter((it) => it.type === "CUSTOMER");
    const internal = organizations.filter((it) => it.type === "INTERNAL");
    const resellers = organizations.filter((it) => it.type === "RESELLER");

    if (customers.length > 0) {
      const activeCustomers = items[0].arguments.find((it) => it.argId === "CUSTOMER")?.points.length ?? 0;
      lines.push({
        key: "customers",
        description: "Customers",
        value: `${activeCustomers ?? 0}/${customers.length}`,
      });
    }

    if (internal.length > 0) {
      const activeInternal = items[0].arguments.find((it) => it.argId === "INTERNAL")?.points.length ?? 0;
      lines.push({
        key: "Internal organizations",
        description: "Internal organizations",
        value: `${activeInternal ?? 0}/${internal.length}`,
      });
    }

    if (resellers.length > 0) {
      const activeResellers = items[0].arguments.find((it) => it.argId === "RESELLER")?.points.length ?? 0;
      lines.push({
        key: "resellers",
        description: "Resellers",
        value: `${activeResellers ?? 0}/${resellers.length}`,
      });
    }
  }

  return (
    <FastFact
      title={"Organizations (active/total)"}
      info={
        "Total is the total number of organizations configured in C-facts.\n" +
        `Active is total number of configured ${
          isManagedCview ? "organizations" : "customers"
        } for which costs have been found in this month’s data.`
      }
      lines={lines}
    />
  );
}
