import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ReactNode } from "react";
import { css } from "@emotion/css";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
  },
  buttonContainer: {
    whiteSpace: "nowrap",
  },
  button: {
    position: "relative",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    width: 0,
  },
  titleText: {
    fontWeight: "bold",
  },
}));

interface DialogTitleCloseableProps {
  onClose?: () => void | null;
  children: ReactNode;
  color?: string;
}

export function DialogTitleCloseable({ children, onClose, color }: DialogTitleCloseableProps) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={css({ display: "flex", backgroundColor: color ?? theme.palette.primary.dark })}>
      <DialogTitle className={classes.title} disableTypography>
        <Typography classes={{ root: classes.titleText }} variant={"h6"} noWrap>
          {children}
        </Typography>
      </DialogTitle>
      <div className={classes.buttonContainer}>
        {onClose ? (
          <Tooltip title={`Close dialog`} placement="bottom-end">
            <IconButton aria-label="Close" className={classes.button} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
