/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    AttributeFilterDTO,
    UpdateAttributeFiltersRequestDTO,
} from '../models';

export interface ListAttributeFilterStateRequest {
    collectorId: string;
}

export interface UpdateAttributeFilterStateRequest {
    collectorId: string;
    updateAttributeFiltersRequestDTO: UpdateAttributeFiltersRequestDTO;
}

/**
 * 
 */
export class AttributesFilterControllerApi extends runtime.BaseAPI {

    /**
     */
    async listAttributeFilterStateRaw(requestParameters: ListAttributeFilterStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AttributeFilterDTO>>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling listAttributeFilterState.');
        }

        const queryParameters: any = {};

        if (requestParameters.collectorId !== undefined) {
            queryParameters['collectorId'] = requestParameters.collectorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/attributes/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listAttributeFilterState(requestParameters: ListAttributeFilterStateRequest, initOverrides?: RequestInit): Promise<Array<AttributeFilterDTO>> {
        const response = await this.listAttributeFilterStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAttributeFilterStateRaw(requestParameters: UpdateAttributeFilterStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling updateAttributeFilterState.');
        }

        if (requestParameters.updateAttributeFiltersRequestDTO === null || requestParameters.updateAttributeFiltersRequestDTO === undefined) {
            throw new runtime.RequiredError('updateAttributeFiltersRequestDTO','Required parameter requestParameters.updateAttributeFiltersRequestDTO was null or undefined when calling updateAttributeFilterState.');
        }

        const queryParameters: any = {};

        if (requestParameters.collectorId !== undefined) {
            queryParameters['collectorId'] = requestParameters.collectorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/attributes/filter`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateAttributeFiltersRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAttributeFilterState(requestParameters: UpdateAttributeFilterStateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateAttributeFilterStateRaw(requestParameters, initOverrides);
    }

}
