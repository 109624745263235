import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { RouterLink } from "../../components/navigation/RouterLink";
import { DataSourceType, useCollector, useCollectorConfig } from "../../clients/collector.client";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import { InvoiceRuns } from "./pipelineruns/InvoiceRuns";
import { TabsStyled } from "../../components/tabs/TabStyled";
import { TabPanel } from "../../components/tabs/TabPanel";
import { CsvUpload } from "./upload/CsvUpload";
import { DataSourceSettings } from "./DataSourceSettings";
import { DataSourceOverview } from "./DataSourceOverview";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CloudTypeLogo } from "./CloudTypeLogo";
import { CollectorUploadResponseDTO } from "../../open-api";
import { getIncludeAllData } from "../../utils/collector.utils";
import { OrganizationFilter } from "./organizationFilter/OrganizationFilter";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
    height: 78,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

interface DataSourceDetailPageProps {
  collectorId: string;
}

export const DataSourceDetailPage = ({ collectorId }: DataSourceDetailPageProps) => {
  const classes = useStyles();

  const useFilterAttributes = useFeatureToggle(FEATURES.filterAttributes);
  const [collector, refreshCollector, collectorIsLoading] = useCollector(collectorId);
  const [collectorConfig, refreshCollectorConfig] = useCollectorConfig(collectorId);

  // Tabs
  const [value, setValue] = useState(0);
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  if (!collector || collectorIsLoading) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={5} />
      </OneCardGrid>
    );
  }

  enum tabKeys {
    OVERVIEW,
    UPLOAD,
    RUNS,
    ACTIONS,
    FILTERS,
  }

  const tabs = [
    {
      key: tabKeys.OVERVIEW,
      label: "Overview",
    },
    ...(collector?.type === DataSourceType.UPLOAD
      ? [
          {
            key: tabKeys.UPLOAD,
            label: "Upload Invoice",
          },
        ]
      : []),
    {
      key: tabKeys.RUNS,
      label: "Invoice Runs",
    },
    {
      key: tabKeys.ACTIONS,
      label: "Actions",
    },
    ...(useFilterAttributes && !getIncludeAllData(collectorConfig)
      ? [
          {
            key: tabKeys.FILTERS,
            label: "Organization Filter",
          },
        ]
      : []),
  ];

  return (
    <Grid container>
      {collector.status === "PAUSED" && (
        <Grid item xs={12}>
          <Paper elevation={2} classes={{ root: classes.paper }}>
            <Typography align="center" color="error" variant="h5">
              Attention!
            </Typography>
            <Typography align="center" color="error">
              This data source is currently disabled, and will not receive any new updates. It can be enabled again
              under settings.
            </Typography>
          </Paper>
        </Grid>
      )}
      {collector.status === "RECALCULATING" && (
        <Grid item xs={12}>
          <Paper elevation={2} classes={{ root: classes.paper }}>
            <Typography align="center" color="primary" variant="h5">
              Attention!
            </Typography>
            <Typography align="center" color="textPrimary">
              This data source is currently paused, due to processing configuration changes. This can take up to 24
              hours. This message will disappear when finished, and the collector will automatically activate again.
            </Typography>
            <Typography align="center" color="textSecondary">
              If this message remains here for more than 24 hours, please contact C-Facts support.
            </Typography>
          </Paper>
        </Grid>
      )}

      <Grid item xs={9}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <RouterLink to={"/datasources"}>Data sources</RouterLink>
            <Typography color="textPrimary">{collector.name}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <TabsStyled handleChange={handleChange} tabValue={value} tabs={tabs} />
        </Grid>
      </Grid>
      <Grid item xs={3} direction={"row-reverse"}>
        <div>
          <CloudTypeLogo type={collector.type as DataSourceType} className={classes.logo} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={tabs.findIndex((tab) => tab.key === tabKeys.OVERVIEW)}>
          <DataSourceOverview
            collectorId={collectorId}
            collector={collector}
            collectorConfig={collectorConfig}
            onUpdateConfig={() => refreshCollectorConfig()}
          />
        </TabPanel>
        <TabPanel value={value} index={tabs.findIndex((tab) => tab.key === tabKeys.UPLOAD)}>
          <CsvUpload collector={collector as CollectorUploadResponseDTO} />
        </TabPanel>
        <TabPanel value={value} index={tabs.findIndex((tab) => tab.key === tabKeys.RUNS)}>
          <InvoiceRuns collectorId={collectorId} />
        </TabPanel>
        <TabPanel value={value} index={tabs.findIndex((tab) => tab.key === tabKeys.ACTIONS)}>
          <DataSourceSettings
            collector={collector}
            refreshCollector={refreshCollector}
            collectorConfig={collectorConfig}
            refreshCollectorConfig={refreshCollectorConfig}
          />
        </TabPanel>
        <TabPanel value={value} index={tabs.findIndex((tab) => tab.key === tabKeys.FILTERS)}>
          <OrganizationFilter collector={collector} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
