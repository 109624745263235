import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import { useApiCall } from "../../../hooks/client.hooks";
import { createOracleCollector, DataSourceResponseDTO } from "../../../clients/collector.client";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-hook-form";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { CollectorOracleInput } from "../../../open-api";
import { ControlledTextField } from "../../../components/form/FormComponents";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(1),
  },
}));

interface OracleDataSourceFormProps {
  onSuccess: (ds: DataSourceResponseDTO) => void;
  onError: (msg: string) => void;
  onBack: () => void;
  onClose: () => void;
  buttonCancel: boolean;
}

export function OracleDataSourceForm({
  onSuccess,
  onError,
  onBack,
  onClose,
  buttonCancel = true,
}: OracleDataSourceFormProps) {
  const classes = useStyles();

  const [submit, isSubmitting] = useApiCall(createOracleCollector);

  const { handleSubmit, control } = useForm<CollectorOracleInput>();

  const handleSave = (form: CollectorOracleInput) => {
    submit(form).then(onSuccess).catch(onError);
  };

  return (
    <form
      onSubmit={handleSubmit((form) => {
        handleSave(form);
      })}
    >
      <DialogContent className={classes.content}>
        <Grid container>
          <FormTitleField text="Data Source" />
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <ControlledTextField control={control} name={"name"} label="Name" fullWidth />
            </Grid>
          </Grid>
          <FormTitleField text="Credentials" />
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"credentials.user"}
              label={"User ID"}
              placeholder="ocid1.user.oc1..aa**a"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"credentials.tenancy"}
              label={"Tenancy ID"}
              placeholder="ocid1.tenancy.oc1..aa***q"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"credentials.region"}
              label={"region"}
              placeholder={"eu-frankfurt-1"}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"credentials.apiKey"}
              label={"Private key"}
              placeholder="-----BEGIN RSA PRIVATE KEY-----
MIIE***
*******
***Sgo=
-----END RSA PRIVATE KEY-----
    "
              fullWidth
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"credentials.fingerprint"}
              label={"Fingerprint"}
              placeholder="00:***:ee:ff"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Previous</Button>
        {buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
