/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CreateOrUpdateOrganizationalUnitContactRequestDTO,
    CreateOrganizationalUnitRequestDTO,
    OrganizationalUnitContactDTO,
    OrganizationalUnitResponseDTO,
    UpdateOrganizationalUnitRequestDTO,
} from '../models';

export interface Create1Request {
    createOrganizationalUnitRequestDTO: CreateOrganizationalUnitRequestDTO;
}

export interface CreateOrUpdateOrganizationContactRequest {
    organizationalUnitId: string;
    createOrUpdateOrganizationalUnitContactRequestDTO: CreateOrUpdateOrganizationalUnitContactRequestDTO;
}

export interface Delete1Request {
    organizationalUnitId: string;
}

export interface GetOrganizationRequest {
    rootId: string;
}

export interface GetOrganizationContactRequest {
    organizationalUnitId: string;
}

export interface Update1Request {
    organizationalUnitId: string;
    updateOrganizationalUnitRequestDTO: UpdateOrganizationalUnitRequestDTO;
}

/**
 * 
 */
export class OrganizationalUnitsApi extends runtime.BaseAPI {

    /**
     */
    async create1Raw(requestParameters: Create1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationalUnitResponseDTO>> {
        if (requestParameters.createOrganizationalUnitRequestDTO === null || requestParameters.createOrganizationalUnitRequestDTO === undefined) {
            throw new runtime.RequiredError('createOrganizationalUnitRequestDTO','Required parameter requestParameters.createOrganizationalUnitRequestDTO was null or undefined when calling create1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrganizationalUnitRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create1(requestParameters: Create1Request, initOverrides?: RequestInit): Promise<OrganizationalUnitResponseDTO> {
        const response = await this.create1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrUpdateOrganizationContactRaw(requestParameters: CreateOrUpdateOrganizationContactRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationalUnitContactDTO>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling createOrUpdateOrganizationContact.');
        }

        if (requestParameters.createOrUpdateOrganizationalUnitContactRequestDTO === null || requestParameters.createOrUpdateOrganizationalUnitContactRequestDTO === undefined) {
            throw new runtime.RequiredError('createOrUpdateOrganizationalUnitContactRequestDTO','Required parameter requestParameters.createOrUpdateOrganizationalUnitContactRequestDTO was null or undefined when calling createOrUpdateOrganizationContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/contact`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrUpdateOrganizationalUnitContactRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async createOrUpdateOrganizationContact(requestParameters: CreateOrUpdateOrganizationContactRequest, initOverrides?: RequestInit): Promise<OrganizationalUnitContactDTO> {
        const response = await this.createOrUpdateOrganizationContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async delete1Raw(requestParameters: Delete1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling delete1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete1(requestParameters: Delete1Request, initOverrides?: RequestInit): Promise<void> {
        await this.delete1Raw(requestParameters, initOverrides);
    }

    /**
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationalUnitResponseDTO>> {
        if (requestParameters.rootId === null || requestParameters.rootId === undefined) {
            throw new runtime.RequiredError('rootId','Required parameter requestParameters.rootId was null or undefined when calling getOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{rootId}`.replace(`{${"rootId"}}`, encodeURIComponent(String(requestParameters.rootId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationalUnitResponseDTO> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOrganizationContactRaw(requestParameters: GetOrganizationContactRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationalUnitContactDTO>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling getOrganizationContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/contact`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getOrganizationContact(requestParameters: GetOrganizationContactRequest, initOverrides?: RequestInit): Promise<OrganizationalUnitContactDTO> {
        const response = await this.getOrganizationContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRootOrganizationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OrganizationalUnitResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listRootOrganizations(initOverrides?: RequestInit): Promise<Array<OrganizationalUnitResponseDTO>> {
        const response = await this.listRootOrganizationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async update1Raw(requestParameters: Update1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationalUnitResponseDTO>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling update1.');
        }

        if (requestParameters.updateOrganizationalUnitRequestDTO === null || requestParameters.updateOrganizationalUnitRequestDTO === undefined) {
            throw new runtime.RequiredError('updateOrganizationalUnitRequestDTO','Required parameter requestParameters.updateOrganizationalUnitRequestDTO was null or undefined when calling update1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrganizationalUnitRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async update1(requestParameters: Update1Request, initOverrides?: RequestInit): Promise<OrganizationalUnitResponseDTO> {
        const response = await this.update1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
