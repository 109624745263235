export * as Model from "./Model";

interface ModelDTO {
  id: string;
}

export function get<T extends ModelDTO>(models: T[], id: T["id"]): T | undefined {
  return models.find((it) => it.id === id);
}

export function create<T extends ModelDTO>(models: T[], model: T): T[] {
  return [...models, model];
}

export function createOrUpdate<T extends ModelDTO>(models: T[], model: T): T[] {
  return get(models, model.id) ? update(models, model) : create(models, model);
}

export function update<T extends ModelDTO>(models: T[], model: T): T[] {
  return models.map((it) => (it.id === model.id ? model : it));
}

export function remove<T extends ModelDTO>(models: T[], id: T["id"]): T[] {
  return models.filter((it) => it.id !== id);
}
