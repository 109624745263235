import React, { useState } from "react";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";
import { CFCard } from "../../../components/layout/CFContainer";
import { CredentialsUnion } from "../../../services/collector-credentials.service";
import { AWSCredentialsFormPartial, awsSchema } from "../forms/credentials/AWSCredentialsFormPartial";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import {
  AWSCredentialsDTO,
  CAConnectorCredentialsDTO,
  CACredentialsDTO,
  CSPCredentialsDTO,
  GOOGLECredentialsDTO,
  INGRAMCSPCredentialsDTO,
  OCICredentialsDTO,
} from "../../../open-api";
import { Button } from "@material-ui/core";
import { messageError, messageSuccess } from "../../../services/message.service";
import {
  CACredentialsFormPartial,
  caSchema,
  CSPCredentialsFormPartial,
  cspSchema,
  INGRAMCSPCredentialsFormPartial,
  ingramCspSchema,
} from "../forms/credentials/AzureCredentialsFormPartial";
import { OCICredentialsFormPartial, ociSchema } from "../forms/credentials/OCICredentialsFormPartial";
import { GoogleCredentialsFormPartial, googleSchema } from "../forms/credentials/GoogleCredentialsFormPartial";
import { z } from "zod";
import { ControlledSwitch } from "../../../components/form/FormComponents";
import { CAConnectWizard } from "../forms/credentials/CAConnectWizard";

interface CredentialsCardProps {
  collectorId: string;
}

export const CredentialsCard = ({ collectorId, ...props }: CredentialsCardProps) => {
  const services = useServices();
  const [credentials, refreshCredentials, isLoading] = useDataClientPromise(services.collectorCredentials.get, [
    collectorId,
  ]);
  const [edit, setEdit] = useState(false);

  // TODO: user is admin
  const canEdit = !!credentials;

  const onSuccess = () => {
    refreshCredentials();
    setEdit(false);
    messageSuccess(
      "Successfully edited credentials. Please keep an eye on your data source to verify that it keeps working."
    );
  };

  return (
    <CFCard
      header={edit ? "Edit credentials" : "Credentials"}
      actions={{
        edit: canEdit && !edit ? () => setEdit(true) : undefined,
        back: canEdit && edit ? () => setEdit(false) : undefined,
      }}
      isLoading={isLoading}
    >
      {!credentials && <p>Credentials are not available for this datasource</p>}
      {credentials && !edit && <CredentialsList credentials={credentials} />}
      {credentials && edit && (
        <CredentialsEdit onSuccess={onSuccess} credentials={credentials} collectorId={collectorId} />
      )}
    </CFCard>
  );
};

interface CredentialsListProps {
  credentials: NonNullable<CredentialsUnion>;
}

const DLItem = ({ label, value }: { label: string; value?: string }) => {
  return (
    <p>
      {label}: <strong>{value ?? "-"}</strong>
    </p>
  );
};
export const CredentialsList = ({ credentials }: CredentialsListProps) => {
  switch (credentials.type) {
    case "GOOGLE":
      return (
        <>
          <DLItem label={"Dataset"} value={credentials.dataset} />
        </>
      );
    case "AWS":
      return (
        <>
          {credentials.accessType === "IAM_ROLE" ? (
            <>
              <DLItem label={"Access type"} value={"IAM Role"} />
              <DLItem label={"Role ARN"} value={credentials.roleToAssumeArn ?? ""} />
            </>
          ) : (
            <>
              <DLItem label={"Access type"} value={"IAM User"} />
              <DLItem label={"Access key"} value={credentials.accessKey ?? ""} />
            </>
          )}
          {credentials.remoteBucket && (
            <>
              <DLItem label={"Cost explorer bucket"} value={credentials.remoteBucket.bucketName} />
              <DLItem label={"Bucket region"} value={credentials.remoteBucket.region} />
            </>
          )}
        </>
      );
    case "CA":
    case "CSP":
    case "INGRAMCSP":
      return (
        <>
          <DLItem label={"AD Domain"} value={credentials.adDomain} />
          <DLItem label={"App ID"} value={credentials.appId} />
        </>
      );
    case "OCI":
      return (
        <>
          <DLItem label={"Tenancy ID"} value={credentials.tenancy} />
          <DLItem label={"User ID"} value={credentials.user} />
          <DLItem label={"Region"} value={credentials.region} />
        </>
      );
    default:
      return <></>;
  }
};

interface CredentialsEditProps<T = CredentialsUnion> {
  credentials: NonNullable<T>;
  collectorId: string;
  onSuccess: () => void;
}

export const CredentialsEdit = ({ credentials, collectorId, onSuccess }: CredentialsEditProps) => {
  switch (credentials.type) {
    case "AWS":
      return <EditAWSCredentialsForm onSuccess={onSuccess} collectorId={collectorId} credentials={credentials} />;
    case "CA":
    case "CA_CONNECTOR":
      return <EditCACredentialsForm credentials={credentials} collectorId={collectorId} onSuccess={onSuccess} />;
    case "CSP":
      return <EditCSPCredentialsForm credentials={credentials} collectorId={collectorId} onSuccess={onSuccess} />;
    case "INGRAMCSP":
      return <EditINGRAMCSPCredentialsForm credentials={credentials} collectorId={collectorId} onSuccess={onSuccess} />;
    case "GOOGLE":
      return <EditGoogleCredentialsForm credentials={credentials} collectorId={collectorId} onSuccess={onSuccess} />;
    case "OCI":
      return <EditOCICredentialsForm credentials={credentials} collectorId={collectorId} onSuccess={onSuccess} />;
  }
};

export const EditAWSCredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<AWSCredentialsDTO>) => {
  const services = useServices();
  const methods = useCFForm({
    schema: awsSchema,
    defaultValues: credentials,
  });
  const { control, handleSubmit } = methods;

  const onSubmit = (data: AWSCredentialsDTO) =>
    services.collectorCredentials
      .update(collectorId, { ...data })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  return (
    // @ts-ignore
    <form onSubmit={handleSubmit((data) => onSubmit({ type: "AWS", ...data }))}>
      <AWSCredentialsFormPartial control={control} formMethods={methods} />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export const EditCACredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<CACredentialsDTO | CAConnectorCredentialsDTO>) => {
  const services = useServices();
  const scheme = z
    .object({
      useConnector: z.boolean(),
      type: z.enum(["CA"] as const),
    })
    .merge(caSchema);

  const methods = useCFForm({
    schema: scheme,
    defaultValues: {
      ...credentials,
      useConnector: credentials.type === "CA_CONNECTOR",
      type: "CA",
    },
  });
  const { control, handleSubmit } = methods;

  const useConnector = methods.watch("useConnector");

  const onSubmit = (data: CACredentialsDTO) =>
    services.collectorCredentials
      .update(collectorId, { ...data })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  // TODO: Add support for changing indirect connections?
  // @ts-ignore
  return (
    <>
      <ControlledSwitch control={control} label={"Use Azure connector API (Recommended)"} name={"useConnector"} />
      {useConnector && <CAConnectWizard collectorId={collectorId} onSuccess={onSuccess} />}
      {!useConnector && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* @ts-ignore */}
          <CACredentialsFormPartial control={control} formMethods={methods} />
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </form>
      )}
    </>
  );
};

export const EditCSPCredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<CSPCredentialsDTO>) => {
  const services = useServices();
  const methods = useCFForm({
    schema: cspSchema,
    defaultValues: credentials,
  });
  const { control, handleSubmit } = methods;

  const onSubmit = (data: CSPCredentialsDTO) =>
    services.collectorCredentials
      .update(collectorId, { ...data })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ type: "CSP", ...data }))}>
      <CSPCredentialsFormPartial control={control} formMethods={methods} />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export const EditINGRAMCSPCredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<INGRAMCSPCredentialsDTO>) => {
  const services = useServices();
  const methods = useCFForm({
    schema: ingramCspSchema,
    defaultValues: credentials,
  });
  const { control, handleSubmit } = methods;

  const onSubmit = (data: INGRAMCSPCredentialsDTO) =>
    services.collectorCredentials
      .update(collectorId, { ...data })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ type: "INGRAMCSP", ...data }))}>
      <INGRAMCSPCredentialsFormPartial control={control} formMethods={methods} />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export const EditOCICredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<OCICredentialsDTO>) => {
  const services = useServices();
  const methods = useCFForm({
    schema: ociSchema,
    defaultValues: credentials,
  });
  const { control, handleSubmit } = methods;

  const onSubmit = (data: OCICredentialsDTO) =>
    services.collectorCredentials
      .update(collectorId, { ...data })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit((data) => onSubmit({ type: "OCI", ...data }))}>
      <OCICredentialsFormPartial formMethods={methods} control={control} />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export const EditGoogleCredentialsForm = ({
  onSuccess,
  collectorId,
  credentials,
}: CredentialsEditProps<GOOGLECredentialsDTO>) => {
  const services = useServices();
  const methods = useCFForm({
    schema: googleSchema,
    defaultValues: { ...credentials, apiKey: JSON.stringify(credentials.apiKey) },
  });
  const { control, handleSubmit } = methods;

  const onSubmit = (data: z.infer<typeof googleSchema>) =>
    services.collectorCredentials
      .update(collectorId, {
        ...data,
        type: "GOOGLE",
        apiKey: JSON.parse(data.apiKey),
      })
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  // @ts-ignore
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GoogleCredentialsFormPartial control={control} formMethods={methods} />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};
