import { adjustmentsForOrganizationLabel, mapGrossType } from "../../../mappers/organization.mapper";
import { Box, Grid, Theme, Typography } from "@material-ui/core";
import { SearchableTabTree, SearchableTagTreeAction } from "../components/tags/SearchableTabTree";
import { useEffect, useMemo, useState } from "react";
import {
  activeAdjustmentForType,
  ADJUSTMENT,
  AdjustmentFilterType,
  adjustmentFromPriceAdjustment,
  AdjustmentType,
  filterSelectedAdjustments,
  labelForType,
  mapAllPriceAdjustmentsToTreeListItems,
  nonInheritedActiveAdjustmentForType,
} from "../../../utils/functional/adjustments.utils";
import { ActionButtonType } from "../components/tags/buttons/TagActionButton";
import { ucFirst } from "../../../utils/localization.utils";
import { makeStyles } from "@material-ui/core/styles";
import { OrganizationalUnitResponseDTO } from "../../../open-api";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";
import { PriceAdjustments } from "../../../model/PriceAdjustments";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingRight: theme.spacing(2),
  },
  rowHeader: {
    minWidth: theme.spacing(15),
    margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
  },
}));

export type PriceAdjustmentsConfig =
  | {
      type: "organization";
      id: string;
      organization: OrganizationalUnitResponseDTO;
    }
  | {
      type: "collector";
      id: string;
      organization?: undefined;
    };

interface PriceAdjustmentsFormEditProps {
  config: PriceAdjustmentsConfig;
}

export const PriceAdjustmentsFormEdit = ({ config: { organization, id, type } }: PriceAdjustmentsFormEditProps) => {
  const services = useServices();

  const promise = useMemo(
    () =>
      type === "organization"
        ? (id: string) => services.priceAdjustments.getAll({ organizationalUnitId: id, includeAvailable: true })
        : (id: string) => services.collectorPriceAdjustments.getAll({ collectorId: id, includeAvailable: true }),
    [services, type]
  );

  const [possiblePriceAdjustments, refreshPriceAdjustments, isLoading] = useDataClientPromise(promise, [id]);

  const [loading, setLoading] = useState(false);
  const [priceAdjustments, setPriceAdjustments] = useState(possiblePriceAdjustments);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setPriceAdjustments(possiblePriceAdjustments);
  }, [possiblePriceAdjustments]);

  useEffect(() => {
    // Refresh price adjustments after basePrice or grossType has updated.
    if (organization?.basePrice && organization?.grossType && !loading) {
      refreshPriceAdjustments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.basePrice, organization?.grossType]);

  const classes = useStyles();

  const grossType: AdjustmentType =
    organization == null || organization.grossType?.toLowerCase() === AdjustmentType.MARKUP
      ? AdjustmentType.MARKUP
      : AdjustmentType.MARGIN;
  const adjustments: AdjustmentType[] = [grossType, AdjustmentType.DISCOUNT];

  const searchFn = (row: PriceAdjustments, searchedVal: string) => {
    return (
      row.filter[AdjustmentFilterType.SOURCE].displayValue.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.filter[AdjustmentFilterType.COST_TYPE].displayValue.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.filter[AdjustmentFilterType.SERVICE].displayValue.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.filter[AdjustmentFilterType.DETAIL].displayValue.toLowerCase().includes(searchedVal.toLowerCase())
    );
  };

  const createOrUpdateAdjustmentFn = (
    adjustmentType: AdjustmentType
  ): ((adjustment: PriceAdjustments, value?: number) => void) => {
    return (adjustment: PriceAdjustments, value?: number) => {
      value = Number(value);
      if (!loading && !isNaN(value)) {
        setLoading(true);

        // if there's no margin adjustment, or it's an inherited margin
        // we're creating a new margin
        if (!adjustment.adjustments[adjustmentType] || adjustment.adjustments[adjustmentType]?.applied.inherited) {
          (type === "organization"
            ? services.priceAdjustments.create({
                organizationalUnitId: id,
                createPriceAdjustmentRequestDTO: {
                  filter: adjustment.filter,
                  adjustmentType: adjustmentType.toUpperCase(),
                  adjustmentValue: value,
                },
              })
            : services.collectorPriceAdjustments.create({
                collectorId: id,
                createPriceAdjustmentRequestDTO: {
                  filter: adjustment.filter,
                  adjustmentType: adjustmentType.toUpperCase(),
                  adjustmentValue: value,
                },
              })
          )
            .then(refreshPriceAdjustments)
            .finally(() => setLoading(false));
        } else {
          // we're updating
          const activeTimedPriceAdjustmentId = activeAdjustmentForType(adjustment, adjustmentType)?.id;

          activeTimedPriceAdjustmentId &&
            (type === "organization"
              ? services.priceAdjustments.update({
                  organizationalUnitId: id,
                  id: activeTimedPriceAdjustmentId,
                  updatePriceAdjustmentRequestDTO: { adjustmentValue: value },
                })
              : services.collectorPriceAdjustments.update({
                  collectorId: id,
                  id: activeTimedPriceAdjustmentId,
                  updatePriceAdjustmentRequestDTO: { adjustmentValue: value },
                })
            )
              .then(refreshPriceAdjustments)
              .finally(() => setLoading(false));
        }
      }
    };
  };

  const deleteAdjustmentFn = (adjustmentType: AdjustmentType): ((adjustment: PriceAdjustments) => void) => {
    return (adjustment: PriceAdjustments) => {
      if (!loading) {
        setLoading(true);
        const activeTimedPriceAdjustmentId = activeAdjustmentForType(adjustment, adjustmentType)?.id;
        activeTimedPriceAdjustmentId &&
          (type === "organization"
            ? services.priceAdjustments.delete({ organizationalUnitId: id, id: activeTimedPriceAdjustmentId })
            : services.collectorPriceAdjustments.delete({ collectorId: id, id: activeTimedPriceAdjustmentId })
          )
            .then(refreshPriceAdjustments)
            .finally(() => setLoading(false));
      }
    };
  };

  const adjustmentAction = (adjustmentType: AdjustmentType): SearchableTagTreeAction<PriceAdjustments> => {
    return {
      name: adjustmentType,
      icon: ADJUSTMENT.ICON[adjustmentType],
      labelFn: labelForType,
      tooltip: ADJUSTMENT.TOOLTIP[mapGrossType(adjustmentType, organization)],
      actionButtonType: ActionButtonType.CHIP,
      organizationalUnitId: id,
      shouldShow: () => true,
      selected: (value: PriceAdjustments): boolean =>
        !!value.adjustments[adjustmentType] && !value.adjustments[adjustmentType]?.applied.inherited,
      chipStyle: { minWidth: "120px", margin: "0px 8px" },
      inputControls: {
        init: (adjustment: PriceAdjustments): number => {
          return adjustmentFromPriceAdjustment(adjustment, adjustmentType) ?? 0;
        },
        max: (value: PriceAdjustments): number => ADJUSTMENT.MAX_VALUE,
        allowZero: true,
      },
      onClick: createOrUpdateAdjustmentFn(adjustmentType),
      delete: {
        shouldShow: (adjustment: PriceAdjustments) => !!nonInheritedActiveAdjustmentForType(adjustment, adjustmentType),
        onDelete: deleteAdjustmentFn(adjustmentType),
      },
    };
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <SearchableTabTree<PriceAdjustments>
          mapDataItemsToTreeListItems={mapAllPriceAdjustmentsToTreeListItems}
          searchFn={searchFn}
          tabs={[
            {
              label: "All",
              onSelected: (adjustments) => adjustments,
              tooltip: `All line items that have a ${adjustmentsForOrganizationLabel(organization)}
               configured or can have a ${adjustmentsForOrganizationLabel(organization)} set on them.`,
            },
            {
              label: "Configured",
              onSelected: (projections: PriceAdjustments[]) => filterSelectedAdjustments(projections, organization),
              tooltip: `Line items that have a ${adjustmentsForOrganizationLabel(organization)} configured.`,
            },
          ]}
          dataItems={priceAdjustments || []}
          organizationalUnit={organization}
          isLoading={loading}
          actions={adjustments.map((it) => adjustmentAction(it))}
          treeHeader={
            <Box display="flex" justifyContent="flex-end" className={classes.header}>
              {adjustments.map((it) => {
                return (
                  <Box key={`box-${it}`} display={"flex"} justifyContent={"center"} className={classes.rowHeader}>
                    <Typography>{ucFirst(mapGrossType(it, organization))}</Typography>
                  </Box>
                );
              })}
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
};
