/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ExtendedLineItemDTO,
    ExtendedLineItemDTOV2,
    LineItemKeysParams,
    LineItemOptionsParams,
    LineItemQueryKeysResponseDTO,
    LineItemQueryOptionsResponseDTO,
    LineItemQueryParams,
    LineItemQueryResponseDTO,
    LineItemSegmentParams,
    NestedLineItemDTO,
    NestedLineItemDTOV2,
    NestedLineItemDTOV3,
} from '../models';

export interface GetInvoiceItemsRequest {
    invoiceId: string;
    currency?: string;
    orgId?: string;
    depId?: string;
}

export interface GetLineItemsRequest {
    dimensions?: string;
    detailed?: boolean;
    currency?: string;
    queryParams?: { [key: string]: string; };
}

export interface GetLineItemsFlatRequest {
    currency?: string;
    filterBILLINGMONTH?: string;
    filterINVOICEPERIOD?: string;
    filterINVOICE?: string;
    filterDATASOURCE?: string;
    filterUNITID?: string;
}

export interface GetLineItemsNestedRequest {
    dimensions: string;
    currency?: string;
    filterBILLINGMONTH?: string;
    filterINVOICEPERIOD?: string;
    filterINVOICE?: string;
    filterDATASOURCE?: string;
    filterUNITID?: string;
}

export interface GetLineItemsV2Request {
    dimensions?: string;
    currency?: string;
    filterDimension?: string;
}

export interface GetLineItemsV2FlatRequest {
    currency?: string;
    filterBILLINGMONTH?: string;
    filterINVOICEPERIOD?: string;
    filterINVOICE?: string;
    filterDATASOURCE?: string;
    filterUNITID?: string;
}

export interface GetLineItemsV2NestedRequest {
    dimensions: string;
    currency?: string;
    filterBILLINGMONTH?: string;
    filterINVOICEPERIOD?: string;
    filterINVOICE?: string;
    filterDATASOURCE?: string;
    filterUNITID?: string;
}

export interface GetMonthItemsRequest {
    month: string;
    currency?: string;
    orgId?: string;
    depId?: string;
    collectorId?: string;
}

export interface GetPeriodItemsRequest {
    period: string;
    currency?: string;
    orgId?: string;
    depId?: string;
    collectorId?: string;
}

export interface GetStreamRequest {
    yearMonth: string;
    currency?: string;
}

export interface GetStreamV2Request {
    yearMonth: string;
    currency?: string;
}

export interface KeysRequest {
    lineItemKeysParams: LineItemKeysParams;
}

export interface OptionsRequest {
    lineItemOptionsParams: LineItemOptionsParams;
}

export interface QueryRequest {
    lineItemQueryParams: LineItemQueryParams;
}

export interface SegmentQueryRequest {
    lineItemSegmentParams: LineItemSegmentParams;
}

export interface TopQueryRequest {
    lineItemQueryParams: LineItemQueryParams;
}

/**
 * 
 */
export class LineItemsApi extends runtime.BaseAPI {

    /**
     */
    async getInvoiceItemsRaw(requestParameters: GetInvoiceItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NestedLineItemDTOV3>>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInvoiceItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.depId !== undefined) {
            queryParameters['depId'] = requestParameters.depId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/query/invoice/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getInvoiceItems(requestParameters: GetInvoiceItemsRequest, initOverrides?: RequestInit): Promise<Array<NestedLineItemDTOV3>> {
        const response = await this.getInvoiceItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve line items.
     */
    async getLineItemsRaw(requestParameters: GetLineItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        if (requestParameters.dimensions !== undefined) {
            queryParameters['dimensions'] = requestParameters.dimensions;
        }

        if (requestParameters.detailed !== undefined) {
            queryParameters['detailed'] = requestParameters.detailed;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.queryParams !== undefined) {
            queryParameters['queryParams'] = requestParameters.queryParams;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve line items.
     */
    async getLineItems(requestParameters: GetLineItemsRequest = {}, initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.getLineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getLineItemsFlatRaw(requestParameters: GetLineItemsFlatRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExtendedLineItemDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.filterBILLINGMONTH !== undefined) {
            queryParameters['filter.BILLING_MONTH'] = requestParameters.filterBILLINGMONTH;
        }

        if (requestParameters.filterINVOICEPERIOD !== undefined) {
            queryParameters['filter.INVOICE_PERIOD'] = requestParameters.filterINVOICEPERIOD;
        }

        if (requestParameters.filterINVOICE !== undefined) {
            queryParameters['filter.INVOICE'] = requestParameters.filterINVOICE;
        }

        if (requestParameters.filterDATASOURCE !== undefined) {
            queryParameters['filter.DATASOURCE'] = requestParameters.filterDATASOURCE;
        }

        if (requestParameters.filterUNITID !== undefined) {
            queryParameters['filter.UNIT_ID'] = requestParameters.filterUNITID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/flat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getLineItemsFlat(requestParameters: GetLineItemsFlatRequest = {}, initOverrides?: RequestInit): Promise<Array<ExtendedLineItemDTO>> {
        const response = await this.getLineItemsFlatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a nested tree of lineitems based on the provided dimensions
     */
    async getLineItemsNestedRaw(requestParameters: GetLineItemsNestedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NestedLineItemDTO>>> {
        if (requestParameters.dimensions === null || requestParameters.dimensions === undefined) {
            throw new runtime.RequiredError('dimensions','Required parameter requestParameters.dimensions was null or undefined when calling getLineItemsNested.');
        }

        const queryParameters: any = {};

        if (requestParameters.dimensions !== undefined) {
            queryParameters['dimensions'] = requestParameters.dimensions;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.filterBILLINGMONTH !== undefined) {
            queryParameters['filter.BILLING_MONTH'] = requestParameters.filterBILLINGMONTH;
        }

        if (requestParameters.filterINVOICEPERIOD !== undefined) {
            queryParameters['filter.INVOICE_PERIOD'] = requestParameters.filterINVOICEPERIOD;
        }

        if (requestParameters.filterINVOICE !== undefined) {
            queryParameters['filter.INVOICE'] = requestParameters.filterINVOICE;
        }

        if (requestParameters.filterDATASOURCE !== undefined) {
            queryParameters['filter.DATASOURCE'] = requestParameters.filterDATASOURCE;
        }

        if (requestParameters.filterUNITID !== undefined) {
            queryParameters['filter.UNIT_ID'] = requestParameters.filterUNITID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/nested`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve a nested tree of lineitems based on the provided dimensions
     */
    async getLineItemsNested(requestParameters: GetLineItemsNestedRequest, initOverrides?: RequestInit): Promise<Array<NestedLineItemDTO>> {
        const response = await this.getLineItemsNestedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of line items.
     */
    async getLineItemsV2Raw(requestParameters: GetLineItemsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<object>>> {
        const queryParameters: any = {};

        if (requestParameters.dimensions !== undefined) {
            queryParameters['dimensions'] = requestParameters.dimensions;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.filterDimension !== undefined) {
            queryParameters['filter.{dimension}'] = requestParameters.filterDimension;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve a list of line items.
     */
    async getLineItemsV2(requestParameters: GetLineItemsV2Request = {}, initOverrides?: RequestInit): Promise<Array<object>> {
        const response = await this.getLineItemsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters. This endpoint also returns the ID\'s of attributes.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getLineItemsV2FlatRaw(requestParameters: GetLineItemsV2FlatRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExtendedLineItemDTOV2>>> {
        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.filterBILLINGMONTH !== undefined) {
            queryParameters['filter.BILLING_MONTH'] = requestParameters.filterBILLINGMONTH;
        }

        if (requestParameters.filterINVOICEPERIOD !== undefined) {
            queryParameters['filter.INVOICE_PERIOD'] = requestParameters.filterINVOICEPERIOD;
        }

        if (requestParameters.filterINVOICE !== undefined) {
            queryParameters['filter.INVOICE'] = requestParameters.filterINVOICE;
        }

        if (requestParameters.filterDATASOURCE !== undefined) {
            queryParameters['filter.DATASOURCE'] = requestParameters.filterDATASOURCE;
        }

        if (requestParameters.filterUNITID !== undefined) {
            queryParameters['filter.UNIT_ID'] = requestParameters.filterUNITID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/v2/flat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters. This endpoint also returns the ID\'s of attributes.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getLineItemsV2Flat(requestParameters: GetLineItemsV2FlatRequest = {}, initOverrides?: RequestInit): Promise<Array<ExtendedLineItemDTOV2>> {
        const response = await this.getLineItemsV2FlatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a nested tree of lineitems based on the provided dimensions
     */
    async getLineItemsV2NestedRaw(requestParameters: GetLineItemsV2NestedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NestedLineItemDTOV2>>> {
        if (requestParameters.dimensions === null || requestParameters.dimensions === undefined) {
            throw new runtime.RequiredError('dimensions','Required parameter requestParameters.dimensions was null or undefined when calling getLineItemsV2Nested.');
        }

        const queryParameters: any = {};

        if (requestParameters.dimensions !== undefined) {
            queryParameters['dimensions'] = requestParameters.dimensions;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.filterBILLINGMONTH !== undefined) {
            queryParameters['filter.BILLING_MONTH'] = requestParameters.filterBILLINGMONTH;
        }

        if (requestParameters.filterINVOICEPERIOD !== undefined) {
            queryParameters['filter.INVOICE_PERIOD'] = requestParameters.filterINVOICEPERIOD;
        }

        if (requestParameters.filterINVOICE !== undefined) {
            queryParameters['filter.INVOICE'] = requestParameters.filterINVOICE;
        }

        if (requestParameters.filterDATASOURCE !== undefined) {
            queryParameters['filter.DATASOURCE'] = requestParameters.filterDATASOURCE;
        }

        if (requestParameters.filterUNITID !== undefined) {
            queryParameters['filter.UNIT_ID'] = requestParameters.filterUNITID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/v2/nested`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve a nested tree of lineitems based on the provided dimensions
     */
    async getLineItemsV2Nested(requestParameters: GetLineItemsV2NestedRequest, initOverrides?: RequestInit): Promise<Array<NestedLineItemDTOV2>> {
        const response = await this.getLineItemsV2NestedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonthItemsRaw(requestParameters: GetMonthItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NestedLineItemDTOV3>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling getMonthItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.depId !== undefined) {
            queryParameters['depId'] = requestParameters.depId;
        }

        if (requestParameters.collectorId !== undefined) {
            queryParameters['collectorId'] = requestParameters.collectorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/query/month/{month}`.replace(`{${"month"}}`, encodeURIComponent(String(requestParameters.month))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getMonthItems(requestParameters: GetMonthItemsRequest, initOverrides?: RequestInit): Promise<Array<NestedLineItemDTOV3>> {
        const response = await this.getMonthItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPeriodItemsRaw(requestParameters: GetPeriodItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NestedLineItemDTOV3>>> {
        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling getPeriodItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.depId !== undefined) {
            queryParameters['depId'] = requestParameters.depId;
        }

        if (requestParameters.collectorId !== undefined) {
            queryParameters['collectorId'] = requestParameters.collectorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/query/period/{period}`.replace(`{${"period"}}`, encodeURIComponent(String(requestParameters.period))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getPeriodItems(requestParameters: GetPeriodItemsRequest, initOverrides?: RequestInit): Promise<Array<NestedLineItemDTOV3>> {
        const response = await this.getPeriodItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getStreamRaw(requestParameters: GetStreamRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.yearMonth === null || requestParameters.yearMonth === undefined) {
            throw new runtime.RequiredError('yearMonth','Required parameter requestParameters.yearMonth was null or undefined when calling getStream.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/stream/{yearMonth}`.replace(`{${"yearMonth"}}`, encodeURIComponent(String(requestParameters.yearMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Warning: Due to the large response size of this request, swagger-ui might have issues with \'Try it out\' using application/json.  Retrieves a flat list of all lineitems according to the provided filters.  Note: Requesting CSV currently does not yet include cloud specific attributes. This is coming soon.
     * Retrieve a list of line items.
     */
    async getStream(requestParameters: GetStreamRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getStreamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStreamV2Raw(requestParameters: GetStreamV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.yearMonth === null || requestParameters.yearMonth === undefined) {
            throw new runtime.RequiredError('yearMonth','Required parameter requestParameters.yearMonth was null or undefined when calling getStreamV2.');
        }

        const queryParameters: any = {};

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/v2/stream/{yearMonth}`.replace(`{${"yearMonth"}}`, encodeURIComponent(String(requestParameters.yearMonth))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getStreamV2(requestParameters: GetStreamV2Request, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getStreamV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get attributes for dimension based on current filters
     */
    async keysRaw(requestParameters: KeysRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LineItemQueryKeysResponseDTO>>> {
        if (requestParameters.lineItemKeysParams === null || requestParameters.lineItemKeysParams === undefined) {
            throw new runtime.RequiredError('lineItemKeysParams','Required parameter requestParameters.lineItemKeysParams was null or undefined when calling keys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lineitems/query/keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.lineItemKeysParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get attributes for dimension based on current filters
     */
    async keys(requestParameters: KeysRequest, initOverrides?: RequestInit): Promise<Array<LineItemQueryKeysResponseDTO>> {
        const response = await this.keysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get options for dimension based on current filters
     */
    async optionsRaw(requestParameters: OptionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LineItemQueryOptionsResponseDTO>> {
        if (requestParameters.lineItemOptionsParams === null || requestParameters.lineItemOptionsParams === undefined) {
            throw new runtime.RequiredError('lineItemOptionsParams','Required parameter requestParameters.lineItemOptionsParams was null or undefined when calling options.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lineitems/query/options`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.lineItemOptionsParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get options for dimension based on current filters
     */
    async options(requestParameters: OptionsRequest, initOverrides?: RequestInit): Promise<LineItemQueryOptionsResponseDTO> {
        const response = await this.optionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Aggregate query based on filters
     */
    async queryRaw(requestParameters: QueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LineItemQueryResponseDTO>>> {
        if (requestParameters.lineItemQueryParams === null || requestParameters.lineItemQueryParams === undefined) {
            throw new runtime.RequiredError('lineItemQueryParams','Required parameter requestParameters.lineItemQueryParams was null or undefined when calling query.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lineitems/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.lineItemQueryParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Aggregate query based on filters
     */
    async query(requestParameters: QueryRequest, initOverrides?: RequestInit): Promise<Array<LineItemQueryResponseDTO>> {
        const response = await this.queryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset mappings for line all lineitems within workspace.
     */
    async resetMappingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/lineitems/query/reset-mappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset mappings for line all lineitems within workspace.
     */
    async resetMappings(initOverrides?: RequestInit): Promise<void> {
        await this.resetMappingsRaw(initOverrides);
    }

    /**
     * Get aggregated lineitems based on multiple segments.
     */
    async segmentQueryRaw(requestParameters: SegmentQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        if (requestParameters.lineItemSegmentParams === null || requestParameters.lineItemSegmentParams === undefined) {
            throw new runtime.RequiredError('lineItemSegmentParams','Required parameter requestParameters.lineItemSegmentParams was null or undefined when calling segmentQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lineitems/query/segments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.lineItemSegmentParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get aggregated lineitems based on multiple segments.
     */
    async segmentQuery(requestParameters: SegmentQueryRequest, initOverrides?: RequestInit): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.segmentQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async topQueryRaw(requestParameters: TopQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.lineItemQueryParams === null || requestParameters.lineItemQueryParams === undefined) {
            throw new runtime.RequiredError('lineItemQueryParams','Required parameter requestParameters.lineItemQueryParams was null or undefined when calling topQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/lineitems/query/top`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.lineItemQueryParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async topQuery(requestParameters: TopQueryRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.topQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
