import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DatasourcePage } from "./DatasourcePage";

import { useRedirect } from "../hooks/router.hooks";
import { useIsGlobalAdmin } from "./admin.hooks";

interface AdminRoutesProps {}

export const AdminRoutes = ({ ...props }: AdminRoutesProps) => {
  const isAdmin = useIsGlobalAdmin();
  const redirect = useRedirect();

  useEffect(() => {
    if (isAdmin != null && !isAdmin) {
      redirect("/");
    }
  }, [isAdmin, redirect]);

  return (
    <>
      <Switch>
        <Route path="/admin/dashboard">
          <DatasourcePage />
        </Route>
        <Route path="/admin/workspace/:workspaceId"></Route>
        <Redirect exact path="/admin" to={"/admin/dashboard"} />
      </Switch>
    </>
  );
};
