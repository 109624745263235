import { DIMENSIONS } from "../clients/lineitems.client";

export const mapDimension = (dimension: keyof typeof DIMENSIONS | string) => {
  switch (dimension) {
    case "LOCATION":
      return "Location";
    case "ROOT":
      return "";
    case "CUSTOMER_TAG":
    case "ORGANIZATION_ATTRIBUTE":
      return "Level 1 cost allocation";
    case "COST_CENTER_ATTRIBUTE":
    case "APPLICATION_TAG":
      return "Level 2 cost allocation";
    case "CLOUD":
      return "Cloud";
    case "BILLING_MONTH":
      return "Billing month";
    case "COST_TYPE":
      return "Cost type";
    case "DEPARTMENT":
      return "C-Facts Cost center";
    case "INVOICE":
      return "Invoice";
    case "ORGANIZATION":
      return "C-Facts Organization";
    case "DATASOURCE":
      return "Data source";
    case "SERVICE":
      return "Description";
    case "DETAIL":
      return "Detail";
    case "TYPE":
      return "Type";
    default:
      return "Unknown";
  }
};
