import {
  CreateOrganizationAdjustmentRequest,
  DeleteOrganizationAdjustmentRequest,
  GetOrganizationAdjustmentsRequest,
  PriceAdjustmentResponseDTO,
  UpdateOrganizationAdjustmentRequest,
} from "../open-api";
import { API } from "./context.service";

export interface PriceAdjustmentsService {
  getAll(request: GetOrganizationAdjustmentsRequest): Promise<PriceAdjustmentResponseDTO[]>;
  create(request: CreateOrganizationAdjustmentRequest): Promise<void>;
  update(request: UpdateOrganizationAdjustmentRequest): Promise<void>;
  delete(request: DeleteOrganizationAdjustmentRequest): Promise<void>;
}

export class LivePriceAdjustmentsService implements PriceAdjustmentsService {
  constructor(private api: API) {}

  getAll = (request: GetOrganizationAdjustmentsRequest): Promise<PriceAdjustmentResponseDTO[]> =>
    this.api.priceAdjustments.getOrganizationAdjustments(request);

  create = (request: CreateOrganizationAdjustmentRequest): Promise<void> =>
    this.api.priceAdjustments.createOrganizationAdjustment(request);

  update = (request: UpdateOrganizationAdjustmentRequest): Promise<void> =>
    this.api.priceAdjustments.updateOrganizationAdjustment(request);

  delete = (request: DeleteOrganizationAdjustmentRequest): Promise<void> =>
    this.api.priceAdjustments.deleteOrganizationAdjustment(request);
}
