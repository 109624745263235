import { useTheme } from "@material-ui/core";
import { DashPieChart } from "./components/DashPieChart";
import { ReactNode } from "react";
import { DashAddGraph } from "./components/DashAddGraph";
import { ChartConfig } from "./model/ChartConfig";
import { useDashCharts } from "./selectors/dashboard.selector";
import { css } from "@emotion/css";
import { DashLineChart } from "./components/DashLineChart";
import { DndContext, MouseSensor, useSensor } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Model } from "../../model/Model";
import { DashRawChart } from "./components/DashRawChart";
import { ColorCacheProvider } from "./model/ColorCache";
import { useMigrationHook } from "./NewDashPageProvider";

export const DashBoardPage = () => {
  const [charts, setCharts] = useDashCharts();
  const sensor = useSensor(MouseSensor, { activationConstraint: { distance: 10 } });

  // handle migration
  // TODO: Remove in the future
  useMigrationHook();

  return (
    <ColorCacheProvider>
      <DndContext
        sensors={[sensor]}
        onDragEnd={({ active, over }) => {
          if (over == null || active.id === over.id) return;
          setCharts((charts) =>
            arrayMove(
              charts,
              charts.indexOf(Model.get(charts, active.id)!),
              charts.indexOf(Model.get(charts, over.id)!)
            )
          );
        }}
      >
        <SortableContext items={charts}>
          <div className={css({ display: "flex", flexWrap: "wrap" })}>
            {charts.map((chartConfig: ChartConfig) => (
              <SortableItem id={chartConfig.id} key={chartConfig.id}>
                <ChartComponent chartConfig={chartConfig} />
              </SortableItem>
            ))}
          </div>
          <DashAddGraph />
        </SortableContext>
      </DndContext>
    </ColorCacheProvider>
  );
};

function ChartComponent({ chartConfig }: { chartConfig: ChartConfig }) {
  switch (chartConfig.type) {
    case "raw":
      return <DashRawChart config={chartConfig} />;
    case "line":
    case "spline":
    case "bar":
      return <DashLineChart config={chartConfig} />;
    case "pie":
    case "donut":
      return <DashPieChart type={chartConfig.type} config={chartConfig} />;
  }
}

function SortableItem(props: { id: string; children: ReactNode }) {
  const theme = useTheme();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      className={css({ transform: CSS.Translate.toString(transform), margin: theme.spacing(2), transition })}
      {...attributes}
      {...listeners}
    >
      {props.children}
    </div>
  );
}
