import { countries } from "countries-list";

export interface FormCountry {
  id: string;
  name: string;
}

export const getCountries = () => {
  return Object.entries(countries).map(([key, value]) => {
    return { id: key, name: value.name };
  });
};

export const getCountryName = (countryId: string) => {
  return getCountries().find((c) => c.id === countryId)?.name || null;
};
