import { Dashboard } from "../model/Dashboard";
import { useOnRemoveDashboard } from "../events/dashboard.events";
import { CircularProgress, ListItemIcon, ListItemText, MenuItem, useTheme } from "@material-ui/core";
import { css } from "@emotion/css";
import DeleteIcon from "@material-ui/icons/Delete";

export function DashRemoveDashboard(props: { onRemove: () => void; dashboard: Dashboard }) {
  const onRemoveDashboard = useOnRemoveDashboard();
  const theme = useTheme();

  return (
    <MenuItem
      onClick={async () => {
        await onRemoveDashboard(props.dashboard);
        props.onRemove();
      }}
    >
      <ListItemIcon>
        {props.dashboard.removing ? (
          <CircularProgress className={css({ color: theme.palette.common.white })} size={20} />
        ) : (
          <DeleteIcon fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>Delete dashboard</ListItemText>
    </MenuItem>
  );
}
