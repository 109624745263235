import { useServices } from "../../services/context.service";
import React from "react";
import { messageError, messageSuccess } from "../../services/message.service";
import { CFCard } from "../../components/layout/CFContainer";
import { z } from "zod";
import { ControlledSwitch, ControlledTextField } from "../../components/form/FormComponents";
import { FormPartial } from "../../utils/form.utils";
import { useCFForm } from "../../components/form/FormComponents.hooks";
import { Button } from "@material-ui/core";
import { useDataClientPromise } from "../../hooks/client.hooks";
import Grid from "@material-ui/core/Grid";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { FormSkeleton } from "../../components/skeletons/FormSkeleton";
import { AzureSsoConfigPresentation, AzureSsoConfigRequestPresentation } from "../../services/azure-sso.service";

export const AzureSSOConfig = () => {
  const services = useServices();
  const [azureSSOCredentials, refreshCredentials, isLoading] = useDataClientPromise(services.azureSSO.getConfig, []);

  if (isLoading) {
    return <AzureSSOConfigFormSkeleton />;
  }
  return <AzureSSOConfigForm azureSSOCredentials={azureSSOCredentials} refreshCredentials={refreshCredentials} />;
};

const AzureSSOConfigFormSkeleton = () => {
  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <OneCardGrid>
          <FormSkeleton></FormSkeleton>
        </OneCardGrid>
      </Grid>
    </>
  );
};

interface AzureSSOConfigFormPartialProps extends Required<FormPartial<z.infer<typeof azureSSOConfigSchema>>> {}

const azureSSOConfigSchema = z.object({
  ssoEnabled: z.boolean(),
  ssoForced: z.boolean(),
  adDomain: z.string(),
  domains: z.string(),
  applicationId: z.string().uuid(),
  groupId: z.string().uuid(),
});

const AzureSSOConfigFormPartial = ({ control, formMethods }: AzureSSOConfigFormPartialProps) => {
  const { watch } = formMethods;
  const isEnabledSSO = watch("ssoEnabled");

  return (
    <>
      <ControlledSwitch control={control} name={"ssoEnabled"} label={"Enable SSO"} />
      <ControlledSwitch control={control} name={"ssoForced"} label={"Force SSO"} disabled={!isEnabledSSO} />
      <CFCard header={"Credentials"}>
        <ControlledTextField
          control={control}
          name={"adDomain"}
          label={"AD Domain"}
          fullWidth
          disabled={!isEnabledSSO}
        />
        <ControlledTextField
          control={control}
          name={"domains"}
          label={"Domain"}
          fullWidth
          disabled={!isEnabledSSO}
          multiline
        />
        <ControlledTextField
          control={control}
          name={"applicationId"}
          label={"Application ID"}
          fullWidth
          disabled={!isEnabledSSO}
        />
        <ControlledTextField
          control={control}
          name={"groupId"}
          label={"Security group ID"}
          fullWidth
          disabled={!isEnabledSSO}
        />
      </CFCard>
    </>
  );
};

const AzureSSOConfigForm = ({
  azureSSOCredentials,
  refreshCredentials,
}: {
  refreshCredentials: () => void;
  azureSSOCredentials: AzureSsoConfigPresentation | null;
}) => {
  const methods = useCFForm({
    schema: azureSSOConfigSchema,
    defaultValues: {
      adDomain: azureSSOCredentials?.adDomain,
      domains: azureSSOCredentials?.domains,
      applicationId: azureSSOCredentials?.applicationId,
      groupId: azureSSOCredentials?.groupId,
      ssoEnabled: azureSSOCredentials?.ssoEnabled,
      ssoForced: azureSSOCredentials?.ssoForced,
    },
  });
  const { control, handleSubmit, reset } = methods;

  const services = useServices();

  const onSubmit = (data: AzureSsoConfigRequestPresentation) => {
    services.azureSSO
      .putConfig({ ...data })
      .then(() => onSuccess("Successfully configured credentials for Azure SSO."))
      .catch((e) => messageError(e.message));
  };

  const onDelete = () => {
    if (azureSSOCredentials?.id) {
      services.azureSSO
        .deleteConfig(azureSSOCredentials?.id)
        .then(() => {
          onSuccess("Successfully deleted Azure SSO credentials.");
        })
        .catch((e) => {
          messageError(e.message);
        });
    }
    reset();
  };

  const onSuccess = (message: string) => {
    refreshCredentials();
    messageSuccess(message);
  };

  return (
    <CFCard>
      <form onSubmit={handleSubmit((data) => onSubmit({ ...data }))}>
        <AzureSSOConfigFormPartial control={control} formMethods={methods} />
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
        <Button variant="contained" color="primary" onClick={onDelete} style={{ marginLeft: 10 }}>
          Delete credentials
        </Button>
      </form>
    </CFCard>
  );
};
