import { createTheme } from "@material-ui/core/styles";
import { lime, lightBlue } from "@material-ui/core/colors";
import { materialUiLocales } from "../utils/localization.utils";

//TODO: use theme spacing.
export const themeMain = createTheme(
  {
    palette: {
      primary: lime,
      secondary: lightBlue,
      type: "dark",
    },
    // shape: {
    //   borderRadius: 0,
    // },
    // props: {
    //   MuiTextField: {
    //     variant: "outlined",
    //     size: "small",
    //   },
    //   MuiFormControl: {
    //     variant: "outlined",
    //     size: "small",
    //   },
    //   MuiSwitch: {
    //     color: "primary",
    //   },
    // },
    overrides: {
      // MuiPaper: {
      //   root: {},
      // },
      MuiAccordion: {
        root: {
          marginBottom: 20,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "#424242",
          color: lime["500"],
        },
      },
      // MuiButton: {
      //   root: {
      //     marginBottom: 12,
      //   },
      // },
      // MuiTextField: {
      //   root: {
      //     marginTop: 0,
      //     marginBottom: 16,
      //     marginRight: 16,
      //     minWidth: 240,
      //   },
      // },
      // MuiFormControl: {
      //   root: {
      //     marginRight: 16,
      //     marginTop: 0,
      //     marginBottom: 16,
      //     minWidth: 240,
      //   },
      // },
      // MuiFormControlLabel: {
      //   label: {
      //     // marginTop: 12,
      //   },
      // },
      // MuiSwitch: {
      //   root: {
      //     marginBottom: 16,
      //   },
      // },
      MuiTypography: {
        // root: {
        //   marginBottom: 8,
        // },
        // body1: {
        //   fontSize: 16,
        // },
        h1: {
          marginBottom: 16,
        },
        h2: {
          marginBottom: 16,
        },
        h3: {
          marginBottom: 16,
        },
        h4: {
          marginBottom: 16,
        },
        h5: {
          marginBottom: 16,
        },
        h6: {},
      },
    },
  },
  materialUiLocales
);
