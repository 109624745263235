import { useDataClient, useDataClientPromise } from "../hooks/client.hooks";
import { AttributeHierarchyResponseDTO } from "../open-api";
import { useServices } from "../services/context.service";

export const ATTRIBUTE_DISPLAY_NAMES = {
  DATA_SOURCE: "data source",
  ORGANIZATION: "organization",
  RESOURCE_GROUP: "resource group",
  METRICS: "available metrics in charts and graphs",
};

export const useAttributes = () => {
  const { attributes } = useServices();
  return useDataClientPromise(attributes.getAll, []);
};

export const useAttributesHierarchy = () => useDataClient<AttributeHierarchyResponseDTO[]>(`/api/attributes/hierarchy`);
