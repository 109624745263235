import { Dialog } from "@material-ui/core";
import { OrganizationalUnitFormEdit } from "../forms/OrganizationalUnitFormEdit";
import { DialogProps } from "../../../hooks/dialog.hooks";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

interface EditOrganizationalUnitDialogProps extends DialogProps {
  organizationalUnit: OrganizationalUnitResponseDTO;
  onSuccess?: () => void;
  onError?: () => void;
  display: "GENERIC";
}

export const OrganizationalUnitDialogEdit = ({
  organizationalUnit,
  open,
  onClose,
  onSuccess,
  onError,
  display,
}: EditOrganizationalUnitDialogProps) => {
  return (
    <>
      <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
        <OrganizationalUnitFormEdit
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          organizationalUnit={organizationalUnit}
          display={display}
        />
      </Dialog>
    </>
  );
};
