import { CFactsIcon } from "../theme/Icon";
import { AdjustmentType, CustomerAdjustmentType } from "../utils/functional/adjustments.utils";
import { BudgetType, InternalBudgetType } from "../utils/functional/budgetalerts.util";
import { OrganizationalUnitResponseDTO } from "../open-api";

export enum OrganizationUnitType {
  ROOT = "ROOT",
  INTERNAL = "INTERNAL",
  CUSTOMER = "CUSTOMER",
  RESELLER = "RESELLER",
}

export const mapOrganizationToIconType = (organization: OrganizationalUnitResponseDTO): CFactsIcon => {
  if (organization.unitType === OrganizationUnitType.ROOT) {
    return organization.type as CFactsIcon;
  } else {
    return organization.unitType as CFactsIcon;
  }
};

export const mapOrganizationToText = (partialOrganization: { type: string; unitType: string }): string => {
  switch (partialOrganization.type) {
    case "INTERNAL":
      switch (partialOrganization.unitType) {
        case OrganizationUnitType.ROOT:
          return "My organization";
        case "PROJECT":
          return "Project";
        case "APPLICATION":
          return "Application";
        case "DEPARTMENT":
          return "Department";
      }
      break;
    case "CUSTOMER":
      switch (partialOrganization.unitType) {
        case OrganizationUnitType.ROOT:
          return "Customer";
        case "PROJECT":
          return "Project";
        case "APPLICATION":
          return "Application";
        case "DEPARTMENT":
          return "Department";
      }
      break;
    case "RESELLER":
      switch (partialOrganization.unitType) {
        case OrganizationUnitType.ROOT:
          return "Reseller";
        case "PROJECT":
          return "Project";
        case "APPLICATION":
          return "Application";
        case "DEPARTMENT":
          return "Department";
      }
      break;
  }
  return partialOrganization.unitType;
};

export const mapGrossType = (
  type: AdjustmentType | CustomerAdjustmentType,
  organization?: OrganizationalUnitResponseDTO
): AdjustmentType | CustomerAdjustmentType => {
  return organization != null && organization.type === OrganizationUnitType.CUSTOMER && type === AdjustmentType.MARKUP
    ? CustomerAdjustmentType.PROFIT
    : type;
};

export const mapBudgetType = (
  type: BudgetType,
  organization: OrganizationalUnitResponseDTO
): BudgetType | InternalBudgetType => {
  return organization.type === OrganizationUnitType.INTERNAL && type === BudgetType.SALES
    ? InternalBudgetType.CROSS_CHARGING
    : type;
};

export const profitLabel = (organization: OrganizationalUnitResponseDTO): string => {
  return organization.grossType?.toLowerCase() === AdjustmentType.MARGIN ? "margin" : "markup";
};

export const mapOrganizationToGrossType = (organization?: OrganizationalUnitResponseDTO) => {
  if (organization?.grossType) {
    const grossType =
      organization.grossType?.toLowerCase() === AdjustmentType.MARKUP ? AdjustmentType.MARKUP : AdjustmentType.MARGIN;
    return mapGrossType(grossType, organization);
  }

  const markup = mapGrossType(AdjustmentType.MARKUP, organization);
  return `${markup}${organization?.type === OrganizationUnitType.CUSTOMER ? " or margin" : ""}`;
};

export const adjustmentsForOrganizationLabel = (organization?: OrganizationalUnitResponseDTO) => {
  return `${mapOrganizationToGrossType(organization)} or discount`;
};
