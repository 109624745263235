import { PivotGridDataSourceField, PivotGridDataSourceOptions } from "devextreme/ui/pivot_grid/data_source";
import { PivotChartDTO } from "../../mappers/PivotChartMapper";

type Year = number;
type Quarter = 1 | 2 | 3 | 4;
type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type DateFilter = [Year, Quarter, Month];

export interface CFactsPivotGridDataSourceField extends PivotGridDataSourceField {
  dataField: keyof PivotChartDTO | string;
}

export interface CFactsPivotGridDataSourceOptions extends PivotGridDataSourceOptions {
  fields: CFactsPivotGridDataSourceField[];
}

const currentDate = new Date();

const monthToQuarterZeroIdx = (month: number) => Math.abs((month % 3) - month) / 3;

export const previous12Months = Array.from(Array(12).keys()).map((i) => {
  const date = new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 1 + i);
  const monthZeroIdx = date.getMonth();
  return [date.getFullYear(), monthToQuarterZeroIdx(monthZeroIdx) + 1, monthZeroIdx + 1] as DateFilter;
});
