import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { newPasswordRequest } from "../../clients/user.client";
import { messageError, messageSuccess } from "../../services/message.service";
import { useCFForm } from "../../components/form/FormComponents.hooks";
import { z } from "zod";
import { ControlledTextField } from "../../components/form/FormComponents";
import { FormTitleField } from "../../components/form/FormTitleField";

export function ResetPassword() {
  const { control, handleSubmit } = useCFForm({
    schema: z
      .object({
        oldPassword: z.string(),
        password: z
          .string()
          .regex(
            /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,255})/,
            "Password requirements: At least 8 characters, a capital and a number."
          ),
        repeatPassword: z.string(),
      })
      .refine((data) => data.password === data.repeatPassword, {
        message: "Passwords don't match",
        path: ["repeatPassword"],
      }),
  });

  const handleReset = (oldPassword: string, newPassword: string) => {
    newPasswordRequest(oldPassword, newPassword)
      .then(() => messageSuccess(`Password successfully changed. Please sign in using your new password.`))
      .catch((msg) => messageError(`Failed to reset: ${msg}`));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(({ oldPassword, password }) => {
          handleReset(oldPassword, password);
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTitleField text="Change your password" />
            <Typography>New password will be active on your next login.</Typography>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              fullWidth
              control={control}
              name={"oldPassword"}
              label={"Current password"}
              type="password"
            />
            <ControlledTextField fullWidth control={control} name={"password"} label={"New password"} type="password" />
            <ControlledTextField
              fullWidth
              control={control}
              name={"repeatPassword"}
              label={"Confirm new password"}
              type="password"
            />
            <Button type="submit" fullWidth variant="contained" color="primary" autoFocus>
              Reset password
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
