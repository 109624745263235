import { NestedLineItemDTO } from "graphql-simple-bindings/lineitems";
import { useAggregateQuery, useBillingPeriodArgument, useMostRecentPeriodsFilter } from "../../clients/query.client";
import { QueryFilter } from "../../open-api";

export const UNKNOWN_CUSTOMER = "Organization unassigned";

export const past = (date: Date, monthsAgo: number) => new Date(date.getFullYear(), date.getMonth() - monthsAgo);

export const getYearMonth = (date: Date) => {
  const monthOneIdx = date.getMonth() + 1;
  return `${date.getFullYear()}-${monthOneIdx < 10 ? "0" + monthOneIdx.toString() : monthOneIdx}`;
};

export const getCurrentMonth = () => getYearMonth(new Date());

export const sortOnChild =
  (compareFn: (a?: NestedLineItemDTO, b?: NestedLineItemDTO) => number, findFn: (a: NestedLineItemDTO) => boolean) =>
  (data: NestedLineItemDTO[]) =>
    data.sort((a, b) => compareFn(a.childLineItems.find(findFn), b.childLineItems.find(findFn)));

export const useLandingData = (segment: string, filter: QueryFilter) => {
  const periodFilter = useMostRecentPeriodsFilter(3);
  const arg = useBillingPeriodArgument();
  const [items, , loading] = useAggregateQuery(arg, segment, [...periodFilter, filter], 5);
  return [items, loading] as const;
};
