import Grid from "@material-ui/core/Grid";
import { TrendChart } from "./TrendChart/TrendChart";
import { FastFacts } from "./FastFacts/FastFacts";
import { useNMostRecentPeriods } from "../../clients/lineitems.client";
import { EmptyContainer } from "../../components/EmptyContainer";
import { TopLists } from "./TopLists/TopLists";
import { useHiddenDashboards } from "../../hooks/config.hooks";
import { FormSelectSingle } from "../../components/form/FormSelectSingle";
import { Invitations } from "./invitations/Invitations";
import { GenericIcon } from "../../theme/Icon";
import { useCollectors } from "../../clients/collector.client";
import { CFPageTitle } from "../../components/layout/CFContainer";
import { BillingPeriodSelect } from "../../app/config/BillingPeriodSelect";
import { BillingPeriod } from "../../components/BillingPeriod";
import { useSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";
import { useAggregateQuery, useMostRecentPeriodsFilter } from "../../clients/query.client";
import { useIsManagedCView } from "../../hooks/auth.hooks";

export function DashboardPage() {
  // Only used to render empty container, might be a bit unnecessary
  const [dataSources, , sourcesLoading] = useCollectors();
  const periodFilter = useMostRecentPeriodsFilter(1);
  const isManagedCView = useIsManagedCView();
  const segment = isManagedCView ? "ORGANIZATION" : "TYPE";
  const [items, , loading] = useAggregateQuery("CLOUD", segment, periodFilter);

  const billingPeriod = useNMostRecentPeriods()[0];
  const selectedBillingCycle = useSelectedBillingPeriods();

  let emptyMessage: string = "";
  let emptyTitle: string = "";

  // First check if we have line items
  if (!loading && !sourcesLoading && (!items || items.length === 0)) {
    // No line items, no data sources
    if (!dataSources || dataSources.length === 0) {
      emptyTitle = "No billing data available";
      emptyMessage =
        "After you have configured your first data source, this dashboard will fill with widgets providing valuable insights in your cloud costs and sales";
    } else {
      emptyTitle = "No billing data available yet for this month";
    }
  }
  const [hidden, setVisible] = useHiddenDashboards();

  return (
    <Grid container spacing={1}>
      <CFPageTitle
        header={
          <>
            {selectedBillingCycle?.collectorName && <>{selectedBillingCycle?.collectorName}:&nbsp;</>}
            <BillingPeriod full billingPeriod={billingPeriod} />
          </>
        }
      >
        <BillingPeriodSelect />
      </CFPageTitle>
      <Grid container item xs={12}>
        <Invitations />
      </Grid>
      <Grid container item xs={12}>
        <FastFacts items={items} loading={loading} />
      </Grid>
      <Grid item xs={12}>
        <TrendChart />
      </Grid>
      <Grid item xs={12}>
        <TopLists />
      </Grid>
      {hidden.length > 0 && (
        <Grid item xs={12} direction="column" justify="center" alignItems="center">
          <FormSelectSingle
            value={"Show hidden widgets"}
            handleChange={(e: any) => setVisible(e.target.value)}
            options={["Show hidden widgets", ...hidden].map((it) => ({
              id: it,
            }))}
            optionId={"id"}
            optionValue={(e) => e.id}
            id="items"
          />
        </Grid>
      )}
      {!loading && !sourcesLoading && emptyTitle && (
        <EmptyContainer title={emptyTitle} text={emptyMessage} icon={GenericIcon.BILLING} />
      )}
    </Grid>
  );
}
