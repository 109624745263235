import {
  ExtendedLineItemDTO,
  ExtendedLineItemDTOV2,
  NestedLineItemDTO,
  NestedLineItemDTOV2,
} from "../../simple-bindings/TypeScript/lineitems";
import { DIMENSIONS, getLineItemQueryParamString } from "../clients/lineitems.client";
import { defaultNestedLineItems, randomLineItems } from "../pages/newdash/model/LineItem.mock";
import { getRequest } from "../utils/api.utils";
import { extendable, Extendable } from "./test.service";
import { API, CacheNotYetCalculatedError } from "./context.service";
import { delay } from "../utils/debounce.utils";

export interface LineItemFilter extends Partial<Record<keyof typeof DIMENSIONS, string[] | undefined>> {}

export interface LineItemService {
  getLineItems(
    apiLvl: 1 | 2,
    dimensions?: string[],
    currency?: string,
    filter?: LineItemFilter
  ): Promise<(ExtendedLineItemDTO | NestedLineItemDTO | ExtendedLineItemDTOV2 | NestedLineItemDTOV2)[]>;

  getNestedLineItems(dimensions: string[], currency?: string, filter?: LineItemFilter): Promise<NestedLineItemDTO[]>;

  getExtendedLineItems(currency?: string, filter?: LineItemFilter): Promise<ExtendedLineItemDTO[]>;

  getLineItemsAsStream(month: string, currency?: string): Promise<ExtendedLineItemDTO[]>;
}

export class LiveLineItemService implements LineItemService {
  constructor(private api: API) {}

  getLineItems = (
    apiLvl: 1 | 2,
    dimensions?: string[],
    currency?: string,
    filter?: LineItemFilter
  ): Promise<(ExtendedLineItemDTO | NestedLineItemDTO | ExtendedLineItemDTOV2 | NestedLineItemDTOV2)[]> =>
    getRequest(
      `/api/lineitems${apiLvl === 2 ? "/v2" : ""}${getLineItemQueryParamString(dimensions, currency, filter)}`
    );

  getNestedLineItems = (
    dimensions: string[],
    currency?: string,
    filter?: LineItemFilter
  ): Promise<NestedLineItemDTO[]> => this.getLineItems(1, dimensions, currency, filter) as Promise<NestedLineItemDTO[]>;

  getExtendedLineItems = (currency?: string, filter?: LineItemFilter): Promise<ExtendedLineItemDTO[]> =>
    this.getLineItems(1, [], currency, filter) as Promise<ExtendedLineItemDTO[]>;

  getLineItemsAsStream = async (month: string, currency?: string) => {
    let res;
    while (!res) {
      try {
        res = await getRequest(`api/lineitems/stream/${month}${currency ? `?currency=${currency}` : ""}`);
      } catch (err) {
        if (err instanceof CacheNotYetCalculatedError) {
          console.log("Cache not found, retrying");
          await delay(5000);
        } else {
          throw err;
        }
      }
    }
    return res;
  };
}

export class TestLineItemService implements LineItemService {
  constructor(props?: Extendable<LineItemService>) {
    extendable(this, props);
  }

  getLineItems = (apiLvl: 1 | 2, dimensions?: string[]) => {
    if (apiLvl === 1) {
      return dimensions?.length === 0 ? this.getExtendedLineItems() : this.getNestedLineItems();
    }
    throw new Error("TestLineItemService not implemented for v2.");
  };
  getExtendedLineItems = async () => randomLineItems(10000);
  getNestedLineItems = async (): Promise<NestedLineItemDTO[]> => defaultNestedLineItems;
  getLineItemsAsStream = async (month: string, currency?: string) => randomLineItems(100);
}
