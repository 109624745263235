import Fab from "@material-ui/core/Fab";
import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { UserCreateDialog } from "./dialogs/UserCreateDialog";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface AddWorkspaceUserProps {
  onSuccess: () => void;
}

export function AddWorkspaceUser({ onSuccess }: AddWorkspaceUserProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab aria-label={"Add user"} className={classes.fab} color={"primary"} onClick={() => setOpen(true)}>
        <Tooltip title={"Add user"} placement="left">
          <AddIcon />
        </Tooltip>
      </Fab>
      {open && (
        <UserCreateDialog
          onClose={() => {
            setOpen(false);
            onSuccess();
          }}
          open={open}
        />
      )}
    </>
  );
}
