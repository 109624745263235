import React, { useCallback, useEffect, useMemo, useState } from "react";
import { associateBy } from "../../../utils/functional/reduce.utils";
import { groupBy } from "../../../utils/functional/array.utils";
import {
  BatchProjectionCollectorAttributesDTO,
  BatchProjectionOrganizationResponseDTO,
  BatchProjectionResponseDTO,
} from "../../../open-api";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";
import { BatchProcessingRow, RowDatasource, SetBatchRows } from "./AutomaticCustomerCreation.interface";
import { OrganizationalUnitType } from "../../../clients/organizationalunit.client";
import { Chip, IconButton, Popover, Typography, useTheme } from "@material-ui/core";
import { Column } from "@material-table/core";
import MergeIcon from "@material-ui/icons/CallMerge";
import { css } from "@emotion/css";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import Tooltip from "@material-ui/core/Tooltip";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import { mapOrgTypeToDisplay } from "./AutomaticCustomerCreation.utils";
import { EditIconButton } from "../../../components/buttons/EditIconButton";
import { EditDatasourceConfigForm } from "./components/EditDatasourceConfigForm";
import { useContextOrThrow } from "../../../hooks/context.hooks";
import { BatchCreationConfigContext, useBatchCreationConfig } from "./AutomaticCustomerCreation.provider";
import Grid from "@material-ui/core/Grid";

type BatchCustomerDataHook = [
  BatchProcessingRow[],
  SetBatchRows,
  BatchProjectionCollectorAttributesDTO[],
  boolean,
  () => void
];

/**
 * Fetches batch info, and maps it to the row structure used by the table.
 * @param type
 * @param includeAll
 */
export const useBatchCustomerData = (type: OrganizationalUnitType, includeAll: boolean): BatchCustomerDataHook => {
  const services = useServices();
  const formConfig = useBatchCreationConfig();

  const [batchInfo, refreshBatchInfo, loading] = useDataClientPromise(services.projections.getBatchOptions, [
    formConfig,
  ]);
  const [rows, setRows] = useState<BatchProcessingRow[]>([]);

  useEffect(() => {
    // Handle getting batchInfo from backend
    if (!batchInfo) {
      return;
    }
    processBatchInfoToRows(batchInfo, type, includeAll, setRows);
  }, [batchInfo, type, setRows, includeAll]);

  return [rows, setRows, batchInfo?.collectors ?? [], loading, refreshBatchInfo];
};

/**
 * Creates the (memoized) function that renders the columns of the table.
 * @param type
 * @param collectors
 * @param setRows
 * @param editConfig
 */
export function useColumnsForBatchCustomerCreation(
  type: OrganizationalUnitType,
  collectors: BatchProjectionCollectorAttributesDTO[],
  setRows: SetBatchRows,
  editConfig: boolean
) {
  const theme = useTheme();
  const typeLabel = mapOrgTypeToDisplay(type);
  const [editDatasource, setEditDatasource] = useState<string | undefined>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setFormConfig] = useContextOrThrow(BatchCreationConfigContext);

  const handleChange = useCallback(
    (collectorId: string, rootTag?: string) => {
      setFormConfig((existing) => {
        if (existing.find((it) => it.collectorId === collectorId)) {
          return existing.map((it) => (it.collectorId === collectorId ? { collectorId, rootFilter: rootTag } : it));
        } else {
          return [...existing, { collectorId, rootFilter: rootTag }];
        }
      });
      setEditDatasource(undefined);
    },
    [setFormConfig, setEditDatasource]
  );

  const columns: Column<BatchProcessingRow>[] = useMemo(() => {
    return [
      {
        title: "Type",
        render: (row) => {
          return row.organization.type === "existing" ? (
            <MergeIcon
              className={css({ marginTop: "4px" })}
              color={row.tableData?.checked ? "secondary" : "disabled"}
            />
          ) : (
            <AddCircleRoundedIcon
              className={css({ marginTop: "4px" })}
              color={row.tableData?.checked ? "primary" : "disabled"}
            />
          );
        },
        width: 1,
        editable: "never",
      },
      {
        title: `C-Facts ${typeLabel}`,
        field: "organization.name",
        editable: (col, row) => {
          return !!row.tableData?.checked && row.organization.type === "new";
        },
        render: (row) => (
          <span
            className={css({
              color:
                !row.tableData?.checked && row.organization.type === "new"
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            })}
          >
            {row.organization.name}
          </span>
        ),
      },
      ...collectors.map((it, idx) => ({
        title: (
          <Grid container justifyContent="space-between" className={css({ height: "100%" })}>
            <Grid item xs={12}>
              <Typography>
                <span
                  className={css({
                    fontStyle: "italic",
                    fontSize: "0.9rem",
                  })}
                >
                  {editConfig && (
                    <EditIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                        setEditDatasource(it.collector.id);
                      }}
                      disabled={!it.detailAttribute}
                      tooltip={`Configure which attributes to use for datasource ${it.collector.type}: ${it.collector.name}`}
                    />
                  )}
                  {it.collector.type}: {it.collector.name}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <span
                  className={css({
                    fontSize: "0.8rem",
                    fontWeight: it.rootFilter ? undefined : "bold",
                  })}
                >
                  {it.rootAttribute.displayValue} {it.rootFilter && <strong>= {it.rootFilter.display}</strong>}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <span
                  className={css({
                    fontSize: "0.8rem",
                    fontWeight: it.rootFilter ? "bold" : undefined,
                  })}
                >
                  {it.rootFilter && it.detailAttribute
                    ? it.detailAttribute.displayValue
                    : it.detailAttribute
                    ? "*"
                    : "-"}
                </span>
              </Typography>
            </Grid>
            <Popover
              open={editDatasource === it.collector.id}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              onClose={() => {
                setAnchorEl(null);
                setEditDatasource(undefined);
              }}
            >
              <EditDatasourceConfigForm
                batchAttributes={it}
                onClose={() => setEditDatasource(undefined)}
                onSave={handleChange}
              />
            </Popover>
          </Grid>
        ),
        field: `datasources.${it.collector.id}.newValues[0].displayValue`,
        editable: "never" as const,
        render: (rowData: BatchProcessingRow) => {
          return (
            <>
              {rowData.datasources[it.collector.id].newValues.map((value) => (
                <Tooltip title={value.externalId}>
                  <Chip
                    size="small"
                    color={rowData.tableData?.checked ? "primary" : undefined}
                    label={value.displayValue}
                  />
                </Tooltip>
              ))}
              {rowData.datasources[it.collector.id].existingValues.map((value) => (
                <Tooltip title={value.externalId}>
                  <Chip size="small" variant="outlined" label={value.displayValue} />
                </Tooltip>
              ))}
            </>
          );
        },
      })),
      {
        title: "",
        align: "right",
        customFilterAndSearch: (filter, rowData) => {
          return rowData.state === "selected-for-merge" || rowData.state === "selected-for-join";
        },
        render: (rowData: BatchProcessingRow) => {
          switch (rowData.state) {
            case "selected-for-merge":
              return (
                <>
                  <Tooltip title={`Cancel merging`}>
                    <IconButton
                      size={"small"}
                      onClick={() => setRows((rows) => rows.map((it) => ({ ...it, state: "default" })))}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              );
            case "merge-able":
              return (
                <Tooltip title={`Add row to organization selected for merging`}>
                  <IconButton
                    size={"small"}
                    onClick={() => {
                      setRows((rows) =>
                        rows.map((it) =>
                          it.key === rowData.key
                            ? ({
                                ...it,
                                state: "selected-for-join",
                              } as BatchProcessingRow)
                            : it
                        )
                      );
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              );
            case "selected-for-join":
              return (
                <Tooltip title={`Remove option from merge`}>
                  <IconButton
                    size={"small"}
                    onClick={() => {
                      setRows((rows) =>
                        rows.map((it) =>
                          it.key === rowData.key
                            ? {
                                ...it,
                                state: "merge-able",
                              }
                            : it
                        )
                      );
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            case "merge-unable":
              return (
                <Tooltip title={`Cannot merge existing organizations right now. Please delete organization manually`}>
                  <IconButton size={"small"} disabled>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              );
            case "default":
              return (
                <Tooltip title={`Merge rows into this ${typeLabel}`}>
                  <IconButton
                    size={"small"}
                    onClick={() => {
                      setRows((rows) =>
                        rows.map((it) => {
                          if (it.key === rowData.key) {
                            return {
                              ...it,
                              state: "selected-for-merge" as const,
                            };
                          } else {
                            return {
                              ...it,
                              state:
                                rowData.organization.type === "existing" && it.organization.type === "existing"
                                  ? ("merge-unable" as const)
                                  : ("merge-able" as const),
                            };
                          }
                        })
                      );
                    }}
                  >
                    <MergeIcon />
                  </IconButton>
                </Tooltip>
              );
          }
        },
      },
    ];
  }, [collectors, theme, setRows, typeLabel, editConfig, editDatasource, anchorEl, handleChange]);
  return columns;
}

/**
 * Helper function that handles the API data and maps it to the rows.
 * @param batchInfo
 * @param type
 * @param includeAll Determines whether or not 'empty' organizations should be included in the rows
 * @param setRows
 */
function processBatchInfoToRows(
  batchInfo: BatchProjectionResponseDTO,
  type: OrganizationalUnitType,
  includeAll: boolean,
  setRows: SetBatchRows
) {
  const { relevantOrganizations, rows } = getProjectionRows(batchInfo, type);
  if (includeAll) {
    const orgRows = getIncludeAllOrgRows(relevantOrganizations, batchInfo);
    setRows([...rows, ...orgRows]);
  } else {
    setRows(rows);
  }
}

function getProjectionRows(batchInfo: BatchProjectionResponseDTO, type: string) {
  const relevantProjections = batchInfo.collectors.flatMap((it) =>
    it.attributes.map((attr) => ({
      ...attr,
      collectorId: it.collector.id,
    }))
  );
  const relevantOrganizations = associateBy(
    batchInfo.organizations.map((it) => ({ ...it, included: false })).filter((it) => it.type === type),
    (it) => makeKey(it.name)
  );

  const projectionsPerKey = groupBy(relevantProjections, (it) => makeKey(it.displayValue));
  const rootsPerCollector: Record<string, string | undefined> = batchInfo.collectors.reduce(
    (agg, coll) => ({ ...agg, [coll.collector.id]: coll.rootFilter?.id }),
    {}
  );
  const rows: BatchProcessingRow[] = [...projectionsPerKey.keys()]
    .map((key) => {
      // For each key:
      // Check if organization exists
      const organization: BatchProjectionOrganizationResponseDTO | undefined = relevantOrganizations[key];
      if (organization) {
        // Set included so if 'includeAll === true', we can efficiently get all 'irrelevant' organizations.
        relevantOrganizations[key].included = true;
      }

      // Get for each datasource relevant attributes
      const projections = projectionsPerKey.get(key) ?? [];

      const datasources = prepareAttributesRecord(batchInfo.collectors);

      // Add proposed matches to row
      projections.forEach((it) => {
        datasources[it.collectorId].newValues.push({
          rootId: rootsPerCollector[it.collectorId] ?? it.id,
          detailId: rootsPerCollector[it.collectorId] ? it.id : undefined,
          externalId: it.externalId,
          displayValue: it.displayValue,
        });
      });

      // Add existing projections to row
      organization?.attributeValues.forEach((it) => {
        datasources[it.collectorId].existingValues.push(
          ...it.values.map((attr) => ({
            rootId: rootsPerCollector[it.collectorId] ?? attr.id,
            detailId: rootsPerCollector[it.collectorId] ? attr.id : undefined,
            externalId: attr.externalId,
            displayValue: attr.displayValue,
          }))
        );
      });

      const row: BatchProcessingRow = {
        key,
        organization: organization
          ? {
              type: "existing",
              id: organization.id,
              name: organization.name,
            }
          : {
              type: "new",
              name: projections.length > 0 ? projections[0].displayValue : "",
            },
        datasources,
        selected: false,
        state: "default",
      };

      return row;
    })
    .sort((a, b) =>
      b.organization.type === a.organization.type
        ? a.key.localeCompare(b.key)
        : a.organization.type === "existing"
        ? -1
        : 1
    );
  return { relevantOrganizations, rows };
}

/**
 * Creates rows for each organization that is not matched to an unmapped attribute.
 *
 * @param relevantOrganizations
 * @param batchInfo
 */
function getIncludeAllOrgRows(
  relevantOrganizations: Record<string, BatchProjectionOrganizationResponseDTO & { included: boolean }>,
  batchInfo: BatchProjectionResponseDTO
): BatchProcessingRow[] {
  const rootsPerCollector: Record<string, string | undefined> = batchInfo.collectors.reduce(
    (agg, coll) => ({ ...agg, [coll.collector.id]: coll.rootFilter?.id }),
    {}
  );

  return Object.keys(relevantOrganizations)
    .map((key) => relevantOrganizations[key])
    .filter((it) => !it.included)
    .map((org) => {
      const datasources = prepareAttributesRecord(batchInfo.collectors);

      org?.attributeValues.forEach((it) => {
        datasources[it.collectorId].existingValues.push(
          ...it.values.map((attr) => ({
            rootId: rootsPerCollector[it.collectorId] ?? attr.id,
            detailId: rootsPerCollector[it.collectorId] ? attr.id : undefined,
            externalId: attr.externalId,
            displayValue: attr.displayValue,
          }))
        );
      });

      const row: BatchProcessingRow = {
        key: makeKey(org.name),
        organization: {
          type: "existing",
          id: org.id,
          name: org.name,
        },
        datasources,
        selected: false,
        state: "default",
      };
      return row;
    });
}

const makeKey = (string: string) => string.toLowerCase().trim();

const prepareAttributesRecord = (collectors: BatchProjectionCollectorAttributesDTO[]) => {
  const datasources: RowDatasource = {};
  collectors.forEach(
    (it) =>
      (datasources[it.collector.id] = {
        newValues: [],
        existingValues: [],
      })
  );
  return datasources;
};
