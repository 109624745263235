import { z } from "zod";
import { FormPartial } from "../../../../utils/form.utils";
import { ControlledTextField } from "../../../../components/form/FormComponents";
import { useEffect } from "react";

interface CACredentialsFormPartialProps extends Required<FormPartial<z.infer<typeof caSchema>>> {}

export const caSchema = z.object({
  adDomain: z.string(),
  appId: z.string(),
  appKey: z.string(),
});

export const CACredentialsFormPartial = ({ control, formMethods }: CACredentialsFormPartialProps) => {
  const { setValue } = formMethods;
  useEffect(() => {
    setValue("appKey", "");
  }, [setValue]);

  return (
    <>
      <ControlledTextField
        control={control}
        name={"adDomain"}
        label={"AD Domain"}
        fullWidth
        inputProps={{ readOnly: true }}
      />
      <ControlledTextField control={control} name={"appId"} label={"Application ID"} fullWidth />
      <ControlledTextField control={control} name={"appKey"} label={"Application key"} fullWidth />
    </>
  );
};

interface CSPCredentialsFormPartialProps extends Required<FormPartial<z.infer<typeof cspSchema>>> {}

export const cspSchema = z.object({
  adDomain: z.string(),
  appId: z.string(),
  appKey: z.string(),
  refreshToken: z.string(),
});

export const CSPCredentialsFormPartial = ({ control, formMethods }: CSPCredentialsFormPartialProps) => {
  const { setValue } = formMethods;
  useEffect(() => {
    setValue("appKey", "");
    setValue("refreshToken", "");
  }, [setValue]);

  return (
    <>
      <ControlledTextField
        control={control}
        name={"adDomain"}
        label={"AD Domain"}
        fullWidth
        inputProps={{ readOnly: true }}
      />
      <ControlledTextField control={control} name={"appId"} label={"Application ID"} fullWidth />
      <ControlledTextField control={control} name={"appKey"} label={"Application key"} fullWidth />
      <ControlledTextField control={control} name={"refreshToken"} label={"Refresh token"} fullWidth />
    </>
  );
};

interface INGRAMCSPCredentialsFormPartialProps extends Required<FormPartial<z.infer<typeof ingramCspSchema>>> {}

export const ingramCspSchema = z.object({
  adDomain: z.string(),
  appId: z.string(),
  appKey: z.string(),
  refreshToken: z.string(),
  ingramMarketplace: z.string(),
  ingramApiKey: z.string(),
  ingramUsername: z.string(),
  ingramPassword: z.string(),
});

export const INGRAMCSPCredentialsFormPartial = ({ control, formMethods }: INGRAMCSPCredentialsFormPartialProps) => {
  const { setValue } = formMethods;
  useEffect(() => {
    setValue("appKey", "");
    setValue("refreshToken", "");
    setValue("ingramApiKey", "");
    setValue("ingramPassword", "");
  }, [setValue]);

  return (
    <>
      <ControlledTextField
        control={control}
        name={"adDomain"}
        label={"AD Domain"}
        fullWidth
        inputProps={{ readOnly: true }}
      />
      <ControlledTextField control={control} name={"appId"} label={"Application ID"} fullWidth />
      <ControlledTextField control={control} name={"appKey"} label={"Application key"} fullWidth />
      <ControlledTextField control={control} name={"refreshToken"} label={"Refresh token"} fullWidth />
      <ControlledTextField control={control} name={"ingramMarketplace"} label={"Ingram marketplace"} fullWidth />
      <ControlledTextField control={control} name={"ingramApiKey"} label={"Ingram api key"} fullWidth />
      <ControlledTextField control={control} name={"ingramUsername"} label={"Ingram username"} fullWidth />
      <ControlledTextField control={control} name={"ingramPassword"} label={"Ingram password"} fullWidth />
    </>
  );
};
