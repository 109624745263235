import DialogContent from "@material-ui/core/DialogContent";
import { Tile, Tiles } from "./Tiles";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
}));

interface TilesChooserProps {
  tileList: Tile[];
  onClose?: () => void;
  buttonCancel?: boolean;
}

export function TilesChooser({ tileList, onClose, buttonCancel = true }: TilesChooserProps) {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <Tiles tileList={tileList} />
      </DialogContent>
      <DialogActions>{buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}</DialogActions>
    </>
  );
}
