import { AttributeResponseDTO } from "../../../open-api";

export const attributesMock: AttributeResponseDTO[] = [
  {
    type: {
      id: "AZURE_TAG|environment",
      type: "AZURE_TAG",
      param: "environment",
      displayValue: "Azure Resource tag <environment:*>",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {},
  },
  {
    type: {
      id: "AZURE_CUSTOMER|null",
      type: "AZURE_CUSTOMER",
      displayValue: "Azure Tenant",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "efb84af4-9b2e-4b6c-b0b3-1f7c94081332",
      collectorName: "Azure demo collector",
    },
    values: {
      "1463ffdf-731f-41ca-b811-1b188a3c2236": {
        id: "1463ffdf-731f-41ca-b811-1b188a3c2236",
        externalId: "0e5546a1-b010-435b-98aa-a2e72de7ac8f",
        displayValue: "PB OIL asia",
      },
      "154c0b6d-308f-45b7-b1fe-7e8fc1a5bef1": {
        id: "154c0b6d-308f-45b7-b1fe-7e8fc1a5bef1",
        externalId: "82902460-8b8b-4cc9-9aa8-a336ec271fdf",
        displayValue: "BR&R",
      },
      "1cb48af6-93ec-483a-98af-9d449226b577": {
        id: "1cb48af6-93ec-483a-98af-9d449226b577",
        externalId: "9fad43b3-7d65-4a7e-bbcd-6980becea170",
        displayValue: "PB OIL",
      },
      "1e72cb62-d9b7-40fb-9345-dfffd0a02e2b": {
        id: "1e72cb62-d9b7-40fb-9345-dfffd0a02e2b",
        externalId: "957b6e98-b5d4-430a-ab75-59f3293c0e5c",
        displayValue: "Mississippi Web Services",
      },
      "29148292-3630-46f7-9bda-e9cfe878fc50": {
        id: "29148292-3630-46f7-9bda-e9cfe878fc50",
        externalId: "37f8a52b-c0aa-4de4-bd84-a6d39e562b9a",
        displayValue: "BR&R tmp",
      },
      "2ac33e7f-d2b4-4148-8d4c-4bfb9ff97dc9": {
        id: "2ac33e7f-d2b4-4148-8d4c-4bfb9ff97dc9",
        externalId: "c73572f3-b4e0-4c3a-a7c9-9a64c5a4eaed",
        displayValue: "BR&R temp",
      },
      "2ba18b7c-1505-4fd9-a1d1-6357c144e3b9": {
        id: "2ba18b7c-1505-4fd9-a1d1-6357c144e3b9",
        externalId: "aead99f5-caad-4c3f-a83f-e03dbf931b7e",
        displayValue: "Macrotough test",
      },
      "2f431cd5-ea69-4464-b407-1e8c2a576c36": {
        id: "2f431cd5-ea69-4464-b407-1e8c2a576c36",
        externalId: "799054c7-b327-4ec1-ab0d-b00861255b59",
        displayValue: "Clam",
      },
      "33606c2f-419d-41a8-9101-483d229fa4f2": {
        id: "33606c2f-419d-41a8-9101-483d229fa4f2",
        externalId: "e3cdcdfe-72fa-4713-8a5b-10c7a6ed26b9",
        displayValue: "Macrotough US",
      },
      "3d4ffd1e-3e39-4d84-aba6-c4e373b6a747": {
        id: "3d4ffd1e-3e39-4d84-aba6-c4e373b6a747",
        externalId: "d337dd85-4631-47cf-bc62-dc3c81bf4861",
        displayValue: "Froogle Search ltd",
      },
      "4c5f7606-4462-46fb-8dc5-5a910ba05259": {
        id: "4c5f7606-4462-46fb-8dc5-5a910ba05259",
        externalId: "31cfb098-1c28-4f0f-a2f5-f7ed030d60ea",
        displayValue: "Cloud Allstars",
      },
      "59d2ae3b-71ec-4481-8296-eb6f25ab2de5": {
        id: "59d2ae3b-71ec-4481-8296-eb6f25ab2de5",
        externalId: "21802ba3-caf4-456e-8609-0581f0cb2e5b",
        displayValue: "PB OIL US",
      },
      "5ca034dd-dadb-4586-8700-671aa3b24289": {
        id: "5ca034dd-dadb-4586-8700-671aa3b24289",
        externalId: "a78758bd-0d3a-49d3-b528-1c2d8a067253",
        displayValue: "Macrotough",
      },
      "77203a7b-3e8f-4d0e-9f22-deadd07de634": {
        id: "77203a7b-3e8f-4d0e-9f22-deadd07de634",
        externalId: "55ac2ae1-c4d2-4af2-8bd4-2858b1ced42a",
        displayValue: "RD Clam",
      },
      "807dd3d7-a531-4645-a612-881dce249ab7": {
        id: "807dd3d7-a531-4645-a612-881dce249ab7",
        externalId: "96e94bbe-3cc7-4ab2-9aa5-55f457075b14",
        displayValue: "Pear hardware dev",
      },
      "98cee9d2-e336-4e6b-98ba-0647cbd3a023": {
        id: "98cee9d2-e336-4e6b-98ba-0647cbd3a023",
        externalId: "2a2536e0-e03c-483f-b6bc-d83b53072fd5",
        displayValue: "PB OIL intl",
      },
      "a3ff8200-5df0-4382-b573-243d94208e95": {
        id: "a3ff8200-5df0-4382-b573-243d94208e95",
        externalId: "fa4c9793-f944-48ed-b68f-57c9eb252fdf",
        displayValue: "Froogle Search",
      },
      "b6c606b7-9ed3-4231-84d4-e70cb8ea669e": {
        id: "b6c606b7-9ed3-4231-84d4-e70cb8ea669e",
        externalId: "f1942e9b-5589-4ebf-b2b2-fea1be12356c",
        displayValue: "Peoplecar",
      },
      "bca731bf-74ae-4dee-8b88-8a6cbeb51297": {
        id: "bca731bf-74ae-4dee-8b88-8a6cbeb51297",
        externalId: "bba83daa-3c8f-47f9-8271-93675d393902",
        displayValue: "Cloud Allstars test",
      },
      "c166d452-49d4-4e0b-9192-0e2432a7a234": {
        id: "c166d452-49d4-4e0b-9192-0e2432a7a234",
        externalId: "54d76856-bcda-46f9-a3eb-77ed5ec2cfb1",
        displayValue: "BR&R intl",
      },
      "c41e0f9a-24c4-4a8f-ac82-5bf1d86805f0": {
        id: "c41e0f9a-24c4-4a8f-ac82-5bf1d86805f0",
        externalId: "597ff2cc-8bb7-4dea-840a-7cdb2db4f4ef",
        displayValue: "Pear hardware",
      },
      "c62b1987-c4a3-484c-ba5b-8d05e69f1368": {
        id: "c62b1987-c4a3-484c-ba5b-8d05e69f1368",
        externalId: "fad86197-2167-455f-81e1-d86d9c734966",
        displayValue: "Peoplecar secret",
      },
    },
  },
  {
    type: {
      id: "AZURE_MANAGEMENT_GROUP|null",
      type: "AZURE_MANAGEMENT_GROUP",

      displayValue: "Azure Management group",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "efb84af4-9b2e-4b6c-b0b3-1f7c94081332",
      collectorName: "Azure demo collector",
    },
    values: {
      "0636d7b7-5c56-4cb0-a5e0-464be364d32d": {
        id: "0636d7b7-5c56-4cb0-a5e0-464be364d32d",
        externalId: "be622377-0d5f-453b-860b-c1ee4ebcbcc5",
        displayValue: "mg-macrotough",
      },
      "0692b99a-60d9-47c0-9842-253a5db1ce96": {
        id: "0692b99a-60d9-47c0-9842-253a5db1ce96",
        externalId: "5281e628-69c9-42ff-97b9-415c2d5468d6",
        displayValue: "mg-peoplecar-secret",
      },
      "09f0f12b-0799-49f2-81e3-88d0976193b1": {
        id: "09f0f12b-0799-49f2-81e3-88d0976193b1",
        externalId: "5c72813c-f2ba-4803-9b53-6f09146d2c0f",
        displayValue: "mg-macrotough-us",
      },
      "138cb3b9-026d-43c0-b691-a5f9708fdb74": {
        id: "138cb3b9-026d-43c0-b691-a5f9708fdb74",
        externalId: "f9e5cc23-ecd6-4b3b-bf02-9b49ddc9c122",
        displayValue: "mg-pb-oil-intl",
      },
      "242bdcd7-bf44-4450-98ee-639b78454bfd": {
        id: "242bdcd7-bf44-4450-98ee-639b78454bfd",
        externalId: "7a36ac9b-f576-41ff-8ce2-fe5c92c59d07",
        displayValue: "mg-rd-clam",
      },
      "289553cc-367c-4eb6-9821-9357cb0bcb24": {
        id: "289553cc-367c-4eb6-9821-9357cb0bcb24",
        externalId: "15476e52-fc4a-432a-a56f-ce9d4e6317d4",
        displayValue: "mg-macrotough-test",
      },
      "2b81e77e-2e6e-421e-848f-c805c80789ae": {
        id: "2b81e77e-2e6e-421e-848f-c805c80789ae",
        externalId: "7b470905-fad1-4f95-9393-c120608a6add",
        displayValue: "mg-cloud-allstars-test",
      },
      "32a1242b-66a3-4309-ac8f-6ac6a5ccb276": {
        id: "32a1242b-66a3-4309-ac8f-6ac6a5ccb276",
        externalId: "f6397677-aa6c-47b0-af6a-b4ce6867e185",
        displayValue: "mg-froogle-search-ltd",
      },
      "39b402cf-bf98-49ff-8189-9c934e9f22f6": {
        id: "39b402cf-bf98-49ff-8189-9c934e9f22f6",
        externalId: "b29a82fe-6ab0-47ce-8974-f9b9103a816f",
        displayValue: "mg-pear-hardware-dev",
      },
      "4d02c90b-04c0-49f5-bc05-84d3e5d4c879": {
        id: "4d02c90b-04c0-49f5-bc05-84d3e5d4c879",
        externalId: "7c5587b2-f6ed-4514-a23f-83575d83c9df",
        displayValue: "mg-pb-oil",
      },
      "51115db5-1efe-4cb3-8892-c3a36409aaa4": {
        id: "51115db5-1efe-4cb3-8892-c3a36409aaa4",
        externalId: "c0eb389c-1c9a-4aea-a7b6-e082c36dfc1c",
        displayValue: "mg-br-r-temp",
      },
      "5b1c1d15-a1df-4d45-81c0-28a4b7d4ab18": {
        id: "5b1c1d15-a1df-4d45-81c0-28a4b7d4ab18",
        externalId: "ed719326-fbc0-4f82-b16e-609435059ce3",
        displayValue: "mg-cloud-allstars",
      },
      "65f22efb-1b24-4291-a89a-c8d3816ccbaf": {
        id: "65f22efb-1b24-4291-a89a-c8d3816ccbaf",
        externalId: "4452bc79-b46f-498e-8ffa-dd9a98924ee9",
        displayValue: "mg-pb-oil-us",
      },
      "78b38865-9385-4b10-afa1-55e3765d5d54": {
        id: "78b38865-9385-4b10-afa1-55e3765d5d54",
        externalId: "0291996c-c23a-4fcc-931d-4a51691e58e2",
        displayValue: "mg-br-r-tmp",
      },
      "79df086f-59bc-469d-85db-663ddd678bf2": {
        id: "79df086f-59bc-469d-85db-663ddd678bf2",
        externalId: "fd416cca-d0cb-47ca-abf6-179014be790e",
        displayValue: "mg-peoplecar",
      },
      "a39c02f6-535b-4c28-a3fa-16a4a04952e3": {
        id: "a39c02f6-535b-4c28-a3fa-16a4a04952e3",
        externalId: "5cd3b38b-04a5-41c5-9b9f-a5145e0c4c43",
        displayValue: "mg-br-r-intl",
      },
      "a4a6e906-56fb-4e67-804d-71ce4abe6c0d": {
        id: "a4a6e906-56fb-4e67-804d-71ce4abe6c0d",
        externalId: "39b4e145-d231-49cd-b712-ffaf9f04dac1",
        displayValue: "mg-mississippi-web-services",
      },
      "a8c81742-1216-4224-9841-8277991afe04": {
        id: "a8c81742-1216-4224-9841-8277991afe04",
        externalId: "c86fc5bf-bf70-4f7c-a6a0-d886c081604c",
        displayValue: "mg-pb-oil-asia",
      },
      "dd3c1ee2-26bc-4f68-959f-64b794df7f6f": {
        id: "dd3c1ee2-26bc-4f68-959f-64b794df7f6f",
        externalId: "a27717a2-96a7-4314-a104-dbab1ebf310d",
        displayValue: "mg-froogle-search",
      },
      "ec886523-0965-43af-8873-d7bf73a60010": {
        id: "ec886523-0965-43af-8873-d7bf73a60010",
        externalId: "b3574cad-f4da-4e50-bc3e-213032bee2a3",
        displayValue: "mg-pear-hardware",
      },
      "ee469cbd-a064-4b0c-ab9f-b7160458b4e4": {
        id: "ee469cbd-a064-4b0c-ab9f-b7160458b4e4",
        externalId: "8ae987e1-f80a-4c3c-82c4-f13a3fefb383",
        displayValue: "mg-clam",
      },
      "fee2eaee-09f5-433e-bbe5-aceefffcd1d2": {
        id: "fee2eaee-09f5-433e-bbe5-aceefffcd1d2",
        externalId: "f7e47bc7-1790-4bc7-9a35-be728ebe14ee",
        displayValue: "mg-br-r",
      },
    },
  },
  {
    type: {
      id: "AZURE_RESOURCE_GROUP|null",
      type: "AZURE_RESOURCE_GROUP",

      displayValue: "Azure Resource group",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {
      "0d5193d6-0e56-4ab1-ad2e-40970c5d627b": {
        id: "0d5193d6-0e56-4ab1-ad2e-40970c5d627b",
        externalId: "e9027c5f-3277-4b96-b75b-7476f72c594e",
        displayValue: "rg-potentialbi-demo",
      },
      "13bb7e2b-25b0-43bd-b53b-55632f1c132c": {
        id: "13bb7e2b-25b0-43bd-b53b-55632f1c132c",
        externalId: "e56b937a-6972-478d-9639-5229c6ff9847",
        displayValue: "rg-correct-demo",
      },
      "384485c8-a404-4155-b495-8ebabaac34e7": {
        id: "384485c8-a404-4155-b495-8ebabaac34e7",
        externalId: "9271ead4-b4d6-4bd7-8d74-867acebe61ba",
        displayValue: "rg-application-server-demo",
      },
      "469b04b7-a361-45d5-a9c7-b94240c2a1a0": {
        id: "469b04b7-a361-45d5-a9c7-b94240c2a1a0",
        externalId: "bbe73a96-ade5-464a-b974-a0faa06d4c34",
        displayValue: "rg-correct-production",
      },
      "5157ffb0-471a-4d5c-a836-0d9d7486a573": {
        id: "5157ffb0-471a-4d5c-a836-0d9d7486a573",
        externalId: "bd65625d-9721-4860-911c-156fb20bba90",
        displayValue: "rg-webapp-production",
      },
      "79070f1f-715a-4f01-a04a-a3ee1180e5c6": {
        id: "79070f1f-715a-4f01-a04a-a3ee1180e5c6",
        externalId: "8bbfd412-2be6-4db2-8fda-72386093c24e",
        displayValue: "rg-correct-development",
      },
      "8b1a56c6-66ea-43b7-bdeb-c520546f4bfe": {
        id: "8b1a56c6-66ea-43b7-bdeb-c520546f4bfe",
        externalId: "9d63407e-a710-447b-bf0f-178d73725620",
        displayValue: "rg-application-server-staging",
      },
      "8fa0d0cd-368b-4c7d-a42a-8cb6a8c73a7b": {
        id: "8fa0d0cd-368b-4c7d-a42a-8cb6a8c73a7b",
        externalId: "e1c33e69-5a28-4d5a-980d-7fdfd8e3d1a1",
        displayValue: "rg-correct-staging",
      },
      "a3417127-04cb-4c4a-9e43-0e0844858bd2": {
        id: "a3417127-04cb-4c4a-9e43-0e0844858bd2",
        externalId: "d7b0c307-f90b-4499-8136-16e0b279581a",
        displayValue: "rg-potentialbi-development",
      },
      "a8de7857-de54-414e-a890-100f718358c3": {
        id: "a8de7857-de54-414e-a890-100f718358c3",
        externalId: "6e26fd53-b424-49cb-9a4e-21f286e663c3",
        displayValue: "rg-potentialbi-staging",
      },
      "acc1eb0c-3705-4366-a5f5-e10dfd265de8": {
        id: "acc1eb0c-3705-4366-a5f5-e10dfd265de8",
        externalId: "8015dc6d-74b0-4c5e-aacf-379268e516c3",
        displayValue: "rg-webapp-development",
      },
      "b37cd2cf-bd99-4746-8f23-f42952c07a31": {
        id: "b37cd2cf-bd99-4746-8f23-f42952c07a31",
        externalId: "d99beada-480b-46bc-ae2b-0cf032e09b4d",
        displayValue: "rg-potentialbi-production",
      },
      "c66d5ff8-28f0-4ad6-869e-4bcadfb8fe14": {
        id: "c66d5ff8-28f0-4ad6-869e-4bcadfb8fe14",
        externalId: "5d68ea28-34e8-4ae3-9bbd-61ec6400f415",
        displayValue: "rg-webapp-demo",
      },
      "cc49ce02-6577-4940-999c-d9ee340eedf9": {
        id: "cc49ce02-6577-4940-999c-d9ee340eedf9",
        externalId: "53cd7fb3-cd26-448d-86aa-afcfc736f20a",
        displayValue: "rg-application-server-development",
      },
      "dad921f9-3d9a-4583-b112-981958a3aa71": {
        id: "dad921f9-3d9a-4583-b112-981958a3aa71",
        externalId: "4f71f270-d444-4a85-9e9e-ec0d88986e9a",
        displayValue: "rg-application-server-production",
      },
      "dd83d0ee-f770-496f-a1f6-5f1edc567514": {
        id: "dd83d0ee-f770-496f-a1f6-5f1edc567514",
        externalId: "bc13de6f-0006-4a90-b075-84293623b516",
        displayValue: "rg-webapp-staging",
      },
    },
  },
  {
    type: {
      id: "AZURE_LOCATION|null",
      type: "AZURE_LOCATION",

      displayValue: "Azure Resource location",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {
      "06f699ef-2007-4a54-aec9-1003293c4093": {
        id: "06f699ef-2007-4a54-aec9-1003293c4093",
        externalId: "2389f4db-018c-4139-aaf2-9a981f543be8",
        displayValue: "west-us",
      },
      "0a1cdbad-1624-42b5-bc48-e4a1e4a7653c": {
        id: "0a1cdbad-1624-42b5-bc48-e4a1e4a7653c",
        externalId: "8d0af907-bae8-4e62-b55a-b88194b1b5f3",
        displayValue: "central-europe",
      },
      "a1ffaed6-ba96-48b9-91bf-2e3c530b764e": {
        id: "a1ffaed6-ba96-48b9-91bf-2e3c530b764e",
        externalId: "e9c1e0ec-e4d5-400c-9c82-8610954e259f",
        displayValue: "east-us",
      },
      "e50585a8-f8e4-42fb-8a34-4488c1551ba6": {
        id: "e50585a8-f8e4-42fb-8a34-4488c1551ba6",
        externalId: "d66a6cf1-1b3e-4526-8ebf-2c7a06028680",
        displayValue: "west-europe",
      },
      "f2dc2915-2be7-40e8-9b99-11bc45914ee0": {
        id: "f2dc2915-2be7-40e8-9b99-11bc45914ee0",
        externalId: "e9142232-a621-4d32-a39f-fec5fc926fa4",
        displayValue: "central-us",
      },
    },
  },
  {
    type: {
      id: "AZURE_RESOURCE_GROUP|null",
      type: "AZURE_RESOURCE_GROUP",

      displayValue: "Azure Resource group",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "efb84af4-9b2e-4b6c-b0b3-1f7c94081332",
      collectorName: "Azure demo collector",
    },
    values: {
      "08b4b606-8145-4e6d-8f57-4386e8b78028": {
        id: "08b4b606-8145-4e6d-8f57-4386e8b78028",
        externalId: "4812b29d-58e4-4ed7-983f-a32ead2be5bc",
        displayValue: "rg-webapp-production",
      },
      "3ed70c31-c2db-4bcc-bb10-dc2652bebb82": {
        id: "3ed70c31-c2db-4bcc-bb10-dc2652bebb82",
        externalId: "67f08fed-7c6e-44d0-8237-2063ede741d1",
        displayValue: "rg-correct-staging",
      },
      "4b7bd1f2-cb6e-4caa-9a6d-4d2be465599e": {
        id: "4b7bd1f2-cb6e-4caa-9a6d-4d2be465599e",
        externalId: "3b3c3c4b-453a-4a84-884e-2d565307e50c",
        displayValue: "rg-application-server-development",
      },
      "5b3750ec-6e4e-47de-9564-9701ad41e7cf": {
        id: "5b3750ec-6e4e-47de-9564-9701ad41e7cf",
        externalId: "721bc42f-bb20-467f-a79b-48e19b6cdd75",
        displayValue: "rg-webapp-development",
      },
      "75e14ccc-8e1f-4a8d-aee9-7a29b143ac63": {
        id: "75e14ccc-8e1f-4a8d-aee9-7a29b143ac63",
        externalId: "8ca31cf5-38ef-4835-bf78-9b07089ee7a3",
        displayValue: "rg-webapp-staging",
      },
      "91c8a8a2-3bad-4ba1-8e2f-babecaf7eba5": {
        id: "91c8a8a2-3bad-4ba1-8e2f-babecaf7eba5",
        externalId: "e55e6579-9753-4ff4-97cc-8512ed3478f3",
        displayValue: "rg-correct-production",
      },
      "946bfa58-0d2f-44e6-8d6b-e88179458d9e": {
        id: "946bfa58-0d2f-44e6-8d6b-e88179458d9e",
        externalId: "bd39e032-7e17-4fae-ac82-7bbc08f03818",
        displayValue: "rg-application-server-demo",
      },
      "9b64688f-1a10-4281-807c-f527683c7fe0": {
        id: "9b64688f-1a10-4281-807c-f527683c7fe0",
        externalId: "cfe597ff-776d-44ca-8921-2394410d11b0",
        displayValue: "rg-potentialbi-production",
      },
      "a10ed091-ff66-4b8b-b749-fbf4ac1869a5": {
        id: "a10ed091-ff66-4b8b-b749-fbf4ac1869a5",
        externalId: "11e7bbc6-cf4e-4815-bc45-49cbe50dab7d",
        displayValue: "rg-potentialbi-demo",
      },
      "b2780049-f02e-441f-bda2-e4dcabefc8a5": {
        id: "b2780049-f02e-441f-bda2-e4dcabefc8a5",
        externalId: "5faab5e5-2f81-45a4-a2d1-9ea07924a868",
        displayValue: "rg-potentialbi-development",
      },
      "cb97bb3a-7422-42e8-bce2-a939dd58410e": {
        id: "cb97bb3a-7422-42e8-bce2-a939dd58410e",
        externalId: "2ae2b829-6929-4946-9e6c-a66da6e6abe3",
        displayValue: "rg-correct-demo",
      },
      "cf1d695f-fe82-4f74-b418-c3435768ce7f": {
        id: "cf1d695f-fe82-4f74-b418-c3435768ce7f",
        externalId: "1d325c3c-e6a8-44ea-b050-3992677bd72c",
        displayValue: "rg-correct-development",
      },
      "d6e89143-fd92-4202-9905-d73e34ecabdc": {
        id: "d6e89143-fd92-4202-9905-d73e34ecabdc",
        externalId: "dc712942-6ed4-4772-adf1-c843bf8ea259",
        displayValue: "rg-potentialbi-staging",
      },
      "e3045853-c67d-4f06-8569-0c75f3915837": {
        id: "e3045853-c67d-4f06-8569-0c75f3915837",
        externalId: "66bb09b5-3337-4187-b331-844a35e7ef12",
        displayValue: "rg-webapp-demo",
      },
      "e495a723-482c-4976-88eb-023b1b69d5b1": {
        id: "e495a723-482c-4976-88eb-023b1b69d5b1",
        externalId: "b34647e9-a027-4eeb-bb3c-435a4060d4a8",
        displayValue: "rg-application-server-staging",
      },
      "f2f483a8-76ac-40b3-a575-b9400d685248": {
        id: "f2f483a8-76ac-40b3-a575-b9400d685248",
        externalId: "8bcfa3c0-eb52-46fd-a8ba-b70895f5e3f5",
        displayValue: "rg-application-server-production",
      },
    },
  },
  {
    type: {
      id: "AZURE_TAG|customer",
      type: "AZURE_TAG",
      param: "customer",
      displayValue: "Azure Resource tag <customer:*>",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {},
  },
  {
    type: {
      id: "AZURE_TAG|application",
      type: "AZURE_TAG",
      param: "application",
      displayValue: "Azure Resource tag <application:*>",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {},
  },
  {
    type: {
      id: "AZURE_SUBSCRIPTION|null",
      type: "AZURE_SUBSCRIPTION",

      displayValue: "Azure Subscription",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {
      "035390f7-569c-40a3-9ed3-8b99e896c407": {
        id: "035390f7-569c-40a3-9ed3-8b99e896c407",
        externalId: "ed17b1cb-cb3b-478c-8213-8e5a4da38adb",
        displayValue: "peoplecar-secret-sub",
      },
      "1397f292-92f8-474a-828a-20b31983859b": {
        id: "1397f292-92f8-474a-828a-20b31983859b",
        externalId: "860d3680-356e-47af-9b58-0ada82c31b77",
        displayValue: "cloud-allstars-sub",
      },
      "196e73f2-8789-447e-b651-8a2e3568e6da": {
        id: "196e73f2-8789-447e-b651-8a2e3568e6da",
        externalId: "502d7048-5e0f-4b53-ae9a-f9c389c4de60",
        displayValue: "br-r-temp-sub",
      },
      "198e5c28-cd20-4c07-9d77-5f0de66561e5": {
        id: "198e5c28-cd20-4c07-9d77-5f0de66561e5",
        externalId: "0fb2b1e0-0762-4028-b95d-e4cc13950b4a",
        displayValue: "macrotough-test-sub",
      },
      "1f052b12-0335-45a6-9996-8ddaaa87a0b4": {
        id: "1f052b12-0335-45a6-9996-8ddaaa87a0b4",
        externalId: "2ebb91ba-9c1b-4c47-91a6-66d6c57293a9",
        displayValue: "br-r-tmp-sub",
      },
      "23840c6e-e8a4-425a-af99-8cef5da28ee4": {
        id: "23840c6e-e8a4-425a-af99-8cef5da28ee4",
        externalId: "508d1ad4-ce49-49ab-a95c-5e1ff7813232",
        displayValue: "peoplecar-sub",
      },
      "24cb5898-a5a2-46bd-8a58-48f8ea55fcb3": {
        id: "24cb5898-a5a2-46bd-8a58-48f8ea55fcb3",
        externalId: "c8de34bc-6a40-481b-8638-dc824380cc11",
        displayValue: "cloud-allstars-test-sub",
      },
      "31d59017-8fca-4a72-82e0-d50212ab854a": {
        id: "31d59017-8fca-4a72-82e0-d50212ab854a",
        externalId: "9425d60a-8600-42e7-ab9b-f4402e56ebb1",
        displayValue: "pb-oil-sub",
      },
      "39155570-4b64-470a-98ef-d97a50f02015": {
        id: "39155570-4b64-470a-98ef-d97a50f02015",
        externalId: "24fc9d94-6812-4c93-b433-d5850772d19c",
        displayValue: "macrotough-sub",
      },
      "415173f8-1944-4a55-9052-adb511aa6816": {
        id: "415173f8-1944-4a55-9052-adb511aa6816",
        externalId: "459be135-d66d-4a46-8556-e8a79db732b1",
        displayValue: "rd-clam-sub",
      },
      "453f6c6c-dde1-4e1b-ac29-997116b5edfe": {
        id: "453f6c6c-dde1-4e1b-ac29-997116b5edfe",
        externalId: "9350fb42-2a06-40dc-903a-ede53cebb7fa",
        displayValue: "mississippi-web-services-sub",
      },
      "53b3f8ab-a9e4-47c3-b2a3-b7043dbe5c19": {
        id: "53b3f8ab-a9e4-47c3-b2a3-b7043dbe5c19",
        externalId: "83897e92-1c29-4d86-83de-3ef88f3f5f07",
        displayValue: "clam-sub",
      },
      "53fab410-343e-47ff-88ce-6c68b2cad89b": {
        id: "53fab410-343e-47ff-88ce-6c68b2cad89b",
        externalId: "02103c0e-c44f-4239-b0d4-5e6482fe092a",
        displayValue: "pb-oil-us-sub",
      },
      "55139d54-9938-4390-82fa-5583be4035f6": {
        id: "55139d54-9938-4390-82fa-5583be4035f6",
        externalId: "37194488-bd95-4166-9099-6bfa1eadb7b8",
        displayValue: "pear-hardware-sub",
      },
      "59bad3f5-beaf-4191-8c4f-a4babf673d59": {
        id: "59bad3f5-beaf-4191-8c4f-a4babf673d59",
        externalId: "8dfe9eb2-5701-40f2-b651-9cef8241b4e5",
        displayValue: "froogle-search-sub",
      },
      "5dc4ccc6-038a-416e-b229-0c1b6f297bb1": {
        id: "5dc4ccc6-038a-416e-b229-0c1b6f297bb1",
        externalId: "e136f7bd-9dc4-432b-b5ad-79a37b370ff1",
        displayValue: "pb-oil-intl-sub",
      },
      "835ccd01-e0bb-4cd0-941b-72f3380fc47e": {
        id: "835ccd01-e0bb-4cd0-941b-72f3380fc47e",
        externalId: "52b600e3-fbbe-4282-a49c-e8ff361fc277",
        displayValue: "pear-hardware-dev-sub",
      },
      "97c8017c-f083-40dd-a913-10cc41283258": {
        id: "97c8017c-f083-40dd-a913-10cc41283258",
        externalId: "27f2dc56-b9e8-4df2-8fe0-5bb5ac06fd8d",
        displayValue: "br-r-sub",
      },
      "b465260f-998f-4155-9909-c57948b5fec9": {
        id: "b465260f-998f-4155-9909-c57948b5fec9",
        externalId: "ec321245-3339-4bb2-8d0e-2ae57ab8f090",
        displayValue: "macrotough-us-sub",
      },
      "ceb5d8e1-43c6-4c4d-9db1-6c1926e50dc6": {
        id: "ceb5d8e1-43c6-4c4d-9db1-6c1926e50dc6",
        externalId: "91d42cf3-93ad-4699-9fde-5f1e9f6934d2",
        displayValue: "br-r-intl-sub",
      },
      "db271842-c734-4986-9e69-58c716993929": {
        id: "db271842-c734-4986-9e69-58c716993929",
        externalId: "42baa8f2-d368-464f-9f88-afe6c11594d6",
        displayValue: "froogle-search-ltd-sub",
      },
      "e7542d9b-f352-419e-94ca-8a1729077a47": {
        id: "e7542d9b-f352-419e-94ca-8a1729077a47",
        externalId: "112b6642-5cc7-4b12-9b77-ca65da9e7c1b",
        displayValue: "pb-oil-asia-sub",
      },
    },
  },
  {
    type: {
      id: "AZURE_MANAGEMENT_GROUP|null",
      type: "AZURE_MANAGEMENT_GROUP",

      displayValue: "Azure Management group",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "52b026ff-de71-43aa-baeb-2890c235abbc",
      collectorName: "azure 2",
    },
    values: {
      "0cd4dc6f-3fe2-4f44-904e-b595a6b19d13": {
        id: "0cd4dc6f-3fe2-4f44-904e-b595a6b19d13",
        externalId: "5ca6a13d-a0e0-4a68-8fbc-36755da9608e",
        displayValue: "mg-macrotough",
      },
      "1d839af5-6370-4c1e-b157-e82ebf2556a7": {
        id: "1d839af5-6370-4c1e-b157-e82ebf2556a7",
        externalId: "380edf89-d9d8-4dfd-a73d-f1ceac5cb85f",
        displayValue: "mg-peoplecar-secret",
      },
      "1ea9a86f-140b-45f3-93c4-1518926998f2": {
        id: "1ea9a86f-140b-45f3-93c4-1518926998f2",
        externalId: "d0c77b09-f7ad-466b-837d-c80ea5526d06",
        displayValue: "mg-peoplecar",
      },
      "2b4f1797-4c4f-4ee5-8870-35290568a664": {
        id: "2b4f1797-4c4f-4ee5-8870-35290568a664",
        externalId: "1943bc59-94be-4b86-a719-c4e3922ed4c5",
        displayValue: "mg-pb-oil-intl",
      },
      "2c8a6c67-133c-4146-ad27-e30f6e7a4405": {
        id: "2c8a6c67-133c-4146-ad27-e30f6e7a4405",
        externalId: "99d2fa35-665c-44d4-a002-965636e79c18",
        displayValue: "mg-pb-oil-asia",
      },
      "38761bf0-e694-49ce-b0bc-064689d77c1e": {
        id: "38761bf0-e694-49ce-b0bc-064689d77c1e",
        externalId: "66841178-11ac-4463-8ca0-d1ea26a4950c",
        displayValue: "mg-br-r",
      },
      "3fdc33f4-ad2c-4b70-953d-822b3c01eadc": {
        id: "3fdc33f4-ad2c-4b70-953d-822b3c01eadc",
        externalId: "0ce058b4-e1ea-43c5-bac7-0799b439c553",
        displayValue: "mg-br-r-tmp",
      },
      "423cb07b-d2ee-402b-953f-90f8e8399a05": {
        id: "423cb07b-d2ee-402b-953f-90f8e8399a05",
        externalId: "9f2477ef-8132-4f54-8f7f-35b06c7daa26",
        displayValue: "mg-mississippi-web-services",
      },
      "515cd47a-220c-4b46-b08f-d93e0f1aec6c": {
        id: "515cd47a-220c-4b46-b08f-d93e0f1aec6c",
        externalId: "96433903-7151-4a27-b7e5-19f34b1fa069",
        displayValue: "mg-br-r-temp",
      },
      "572082b4-fcd4-465b-9397-20bf805882a5": {
        id: "572082b4-fcd4-465b-9397-20bf805882a5",
        externalId: "01fc2592-fe49-4efd-ae21-7976acc2ad7c",
        displayValue: "mg-froogle-search-ltd",
      },
      "5b2c156c-b35d-4ade-bb29-c1ce5e976d95": {
        id: "5b2c156c-b35d-4ade-bb29-c1ce5e976d95",
        externalId: "3c0b8d88-530c-47d5-8a4a-c70834f23694",
        displayValue: "mg-br-r-intl",
      },
      "66130180-0d4b-48b6-b68d-885b33d1e036": {
        id: "66130180-0d4b-48b6-b68d-885b33d1e036",
        externalId: "ee341a3a-ced2-4faa-b4ed-550d36abca97",
        displayValue: "mg-pear-hardware",
      },
      "6b31af34-e69b-41db-a7ba-a18098b10e1b": {
        id: "6b31af34-e69b-41db-a7ba-a18098b10e1b",
        externalId: "117ce3ee-459c-4a4e-856f-a4312afb6f07",
        displayValue: "mg-macrotough-us",
      },
      "76d8547f-9332-4ec6-81c1-cc201e78dca1": {
        id: "76d8547f-9332-4ec6-81c1-cc201e78dca1",
        externalId: "d75bcd3e-e10b-494a-8887-b6c6a901e0d0",
        displayValue: "mg-macrotough-test",
      },
      "9e588aa1-a50f-41c1-b266-136eab3e1c61": {
        id: "9e588aa1-a50f-41c1-b266-136eab3e1c61",
        externalId: "cdf062e4-7795-44d3-ac21-a60ed8a92e71",
        displayValue: "mg-pear-hardware-dev",
      },
      "b4365662-8472-4bee-9173-7c7830735d82": {
        id: "b4365662-8472-4bee-9173-7c7830735d82",
        externalId: "9519aff9-7e1c-4ae6-b4f0-dce4d8a79df3",
        displayValue: "mg-cloud-allstars-test",
      },
      "b753b6e2-9fe8-42b9-91db-8d0bacb20cd9": {
        id: "b753b6e2-9fe8-42b9-91db-8d0bacb20cd9",
        externalId: "06f2b586-65aa-427b-83be-6a5791812a07",
        displayValue: "mg-froogle-search",
      },
      "bb19f891-7b44-43e7-af1b-e5d1bfab6836": {
        id: "bb19f891-7b44-43e7-af1b-e5d1bfab6836",
        externalId: "3422b185-8395-4f4f-a979-9c1ac63b0036",
        displayValue: "mg-pb-oil-us",
      },
      "c1bf336c-0c62-4ebd-956b-515afe9163cc": {
        id: "c1bf336c-0c62-4ebd-956b-515afe9163cc",
        externalId: "b1f74123-2528-4cf9-8332-ce3250a5f5ba",
        displayValue: "mg-rd-clam",
      },
      "d04e1d1f-2b28-40f0-9b0b-0409b61de45d": {
        id: "d04e1d1f-2b28-40f0-9b0b-0409b61de45d",
        externalId: "019fecd2-098d-4172-bd23-d66aa0473886",
        displayValue: "mg-pb-oil",
      },
      "d71c407f-ebee-4e8f-a201-d24cd2c1bb5b": {
        id: "d71c407f-ebee-4e8f-a201-d24cd2c1bb5b",
        externalId: "4d0a4b83-d8d1-48c0-8b11-48093c377981",
        displayValue: "mg-cloud-allstars",
      },
      "d8ccb4b7-52a7-47dc-a484-10d14810fb6e": {
        id: "d8ccb4b7-52a7-47dc-a484-10d14810fb6e",
        externalId: "8f042fbb-1697-4b3c-973c-f20b97449934",
        displayValue: "mg-clam",
      },
    },
  },
  {
    type: {
      id: "AZURE_SUBSCRIPTION|null",
      type: "AZURE_SUBSCRIPTION",

      displayValue: "Azure Subscription",
      category: "DATA_SOURCE_ATTRIBUTE",
      collectorId: "efb84af4-9b2e-4b6c-b0b3-1f7c94081332",
      collectorName: "Azure demo collector",
    },
    values: {
      "14c5b8ec-2bc6-48d9-970f-dd11680b4a57": {
        id: "14c5b8ec-2bc6-48d9-970f-dd11680b4a57",
        externalId: "2c222356-532d-4151-b603-66fe0d090bbc",
        displayValue: "pear-hardware-dev-sub",
      },
      "161fe5ad-fa92-43ab-8633-563ede94575c": {
        id: "161fe5ad-fa92-43ab-8633-563ede94575c",
        externalId: "d07d3b38-9e9d-406d-bfbe-ced454bd340c",
        displayValue: "br-r-temp-sub",
      },
      "1d190783-0658-4271-90a8-657f12688e36": {
        id: "1d190783-0658-4271-90a8-657f12688e36",
        externalId: "e22b2063-eb32-47f2-bff4-ab97424a2397",
        displayValue: "cloud-allstars-sub",
      },
      "1ed09088-67f0-4a07-8179-1714f41d97b0": {
        id: "1ed09088-67f0-4a07-8179-1714f41d97b0",
        externalId: "b9286b7c-4465-48fb-af05-67bf069d582c",
        displayValue: "peoplecar-sub",
      },
      "335250b9-5871-4e45-9596-ea728b7c8710": {
        id: "335250b9-5871-4e45-9596-ea728b7c8710",
        externalId: "6e6b5a3c-37f1-4ff9-b11b-61b94d87b6e8",
        displayValue: "froogle-search-sub",
      },
      "5417e0da-79a5-4d1d-9bae-2f8a0810efee": {
        id: "5417e0da-79a5-4d1d-9bae-2f8a0810efee",
        externalId: "4ca24193-828c-4e1d-8f75-82ce7f536086",
        displayValue: "br-r-intl-sub",
      },
      "5657631d-16f3-4173-87e4-cf9530c00b00": {
        id: "5657631d-16f3-4173-87e4-cf9530c00b00",
        externalId: "f5005b0e-5f89-477c-b51c-cc79be66b8d1",
        displayValue: "br-r-sub",
      },
      "762da104-3e80-443c-a4b2-7bb11c7569b9": {
        id: "762da104-3e80-443c-a4b2-7bb11c7569b9",
        externalId: "6512ff25-4e91-47da-83eb-362ddc4224ca",
        displayValue: "pb-oil-intl-sub",
      },
      "779f79fc-4e0e-43cd-a082-7ad6cfb0bbfc": {
        id: "779f79fc-4e0e-43cd-a082-7ad6cfb0bbfc",
        externalId: "15ae8fc8-198e-4680-8e5c-413b3773faa9",
        displayValue: "rd-clam-sub",
      },
      "834d951a-f103-4813-bf49-9d1608c10a18": {
        id: "834d951a-f103-4813-bf49-9d1608c10a18",
        externalId: "3e3539b5-f306-48b8-9026-ca3125b51d79",
        displayValue: "macrotough-test-sub",
      },
      "8967ea7e-ad71-426a-81db-ccb262b7d1b8": {
        id: "8967ea7e-ad71-426a-81db-ccb262b7d1b8",
        externalId: "35abe12d-5edc-4ae8-b179-77567de28017",
        displayValue: "pb-oil-sub",
      },
      "8f268cca-a45a-48ee-a611-3f05b2b2f769": {
        id: "8f268cca-a45a-48ee-a611-3f05b2b2f769",
        externalId: "89f2446e-c27e-47ea-bc0c-0a5a8ea88805",
        displayValue: "pear-hardware-sub",
      },
      "9b73d4f0-bdaf-4eda-90df-ecfba8f70711": {
        id: "9b73d4f0-bdaf-4eda-90df-ecfba8f70711",
        externalId: "e111be5d-a977-43e5-8f6c-782b9284159d",
        displayValue: "pb-oil-asia-sub",
      },
      "a5e6084c-ede1-4e56-bf31-df5bbd09127d": {
        id: "a5e6084c-ede1-4e56-bf31-df5bbd09127d",
        externalId: "138119d8-60ff-41fe-b6ca-595837035034",
        displayValue: "cloud-allstars-test-sub",
      },
      "c0f8441d-25b9-4de5-afe8-23b5280273e3": {
        id: "c0f8441d-25b9-4de5-afe8-23b5280273e3",
        externalId: "c728edc6-3c21-4b48-9991-e926e3c3f6ef",
        displayValue: "br-r-tmp-sub",
      },
      "c562d2e5-ef6a-49e5-8326-0ed549efed64": {
        id: "c562d2e5-ef6a-49e5-8326-0ed549efed64",
        externalId: "2c6fbd26-feee-4be0-8c9d-a064c3ae24ec",
        displayValue: "peoplecar-secret-sub",
      },
      "d53c6fd1-191c-4096-8fc4-2de4f842f42c": {
        id: "d53c6fd1-191c-4096-8fc4-2de4f842f42c",
        externalId: "0e669875-3261-4cc5-a0c7-40d22d84334e",
        displayValue: "macrotough-us-sub",
      },
      "dcf0af2b-22b7-4d2a-8f02-8f56571f66ae": {
        id: "dcf0af2b-22b7-4d2a-8f02-8f56571f66ae",
        externalId: "262ac0c3-470f-4356-84ab-bf6a1d68fe0c",
        displayValue: "pb-oil-us-sub",
      },
      "e9adb27c-76cb-4c3d-8d5a-85dd4c6d7eea": {
        id: "e9adb27c-76cb-4c3d-8d5a-85dd4c6d7eea",
        externalId: "dda8b36c-6a32-4682-83c3-2caf6741044f",
        displayValue: "froogle-search-ltd-sub",
      },
      "ec04be87-2bdb-4c66-8e20-d8f0c422d35c": {
        id: "ec04be87-2bdb-4c66-8e20-d8f0c422d35c",
        externalId: "1dcce8aa-84f4-45cb-960c-ac14709165e7",
        displayValue: "clam-sub",
      },
      "f9cabec4-16f4-461a-9e09-ba2dd4b57f00": {
        id: "f9cabec4-16f4-461a-9e09-ba2dd4b57f00",
        externalId: "1d9f960c-1d54-4554-ab3a-dbe0f53767e7",
        displayValue: "macrotough-sub",
      },
      "fef9537d-8b72-411d-b6f3-2cd6358b36d8": {
        id: "fef9537d-8b72-411d-b6f3-2cd6358b36d8",
        externalId: "2360fb4a-abc2-4b22-a57f-b450e246e780",
        displayValue: "mississippi-web-services-sub",
      },
    },
  },
];
