import { createBudgetForOrganization, updateBudget } from "../../clients/budgetalerts.client";
import { CURRENCIES } from "./money.utils";
import { CreateBudgetRequestDTO, OrganizationalUnitResponseDTO } from "../../open-api";

export const BUDGET_DEFAULT_CURRENCY = CURRENCIES[0].id;

export enum BudgetType {
  COST = "COST",
  SALES = "SALES",
}

export enum InternalBudgetType {
  CROSS_CHARGING = "CROSS-CHARGING",
}

export const createOrUpdateBudget = (
  organization: OrganizationalUnitResponseDTO,
  budget: CreateBudgetRequestDTO,
  budgetId?: string
) => {
  if (!!budgetId) {
    return updateBudget(organization.id, budgetId, budget);
  }

  return createBudgetForOrganization(organization.id, budget);
};
