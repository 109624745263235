import { PipelineRunStatus, useCollector, usePipelineRuns } from "../../../clients/collector.client";
import { PipelineDetail } from "./PipelineDetail";
import { PipelineStatus } from "./components/PipelineStatus";
import { formattedDateTimeUntilNow } from "../../../utils/localization.utils";
import { CircularProgress, useTheme } from "@material-ui/core";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import { DataTableSkeleton } from "../../../components/skeletons/DataTableChartSkeleton";
import { tableIcons } from "../../../theme/TableIcons";
import { CFCard } from "../../../components/layout/CFContainer";
import { PipelineRun } from "../../../open-api";

interface InvoiceRunsProps {
  collectorId: string;
}

export function InvoiceRuns({ collectorId }: InvoiceRunsProps) {
  const [collector, , collectorIsLoading] = useCollector(collectorId);
  const [pipelineRuns, , pipelineRunsIsLoading] = usePipelineRuns(collectorId);
  const theme = useTheme();

  if (!collector || collectorIsLoading || !pipelineRuns) {
    return (
      <CFCard>
        <DataTableSkeleton rowCount={5} />
      </CFCard>
    );
  }

  const tableProps = getTableProps(pipelineRuns);

  return (
    <CFCard header={"Invoice runs"}>
      <MaterialTable
        icons={tableIcons}
        isLoading={collectorIsLoading || pipelineRunsIsLoading}
        {...tableProps}
        onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
        options={{
          showTitle: false,
          toolbar: false,
          draggable: false,
          pageSize: 5,
          actionsColumnIndex: -1,
          search: false,
        }}
        style={{
          marginLeft: theme.spacing(-2),
          marginRight: theme.spacing(-2),
          marginBottom: theme.spacing(-4),
        }}
        detailPanel={({ rowData }) => <PipelineDetail collectorId={collector.id} pipelineId={rowData.id} />}
      />
    </CFCard>
  );
}

const getTableProps = (pipelineRuns: PipelineRun[]): MaterialTableProps<PipelineRun> => ({
  columns: [
    {
      title: "Run ID",
      field: "id",
      render: (val) => `${val.id.slice(0, 8)}...`,
    },
    {
      title: "Started",
      render: (val) => <span>{formattedDateTimeUntilNow(val.startDate)}</span>,
      customSort: (a, b) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf(),
    },
    {
      title: "Last active:",
      render: (val) => <span>{formattedDateTimeUntilNow(val.endDate) || <CircularProgress size={12} />}</span>,
      customSort: (a, b) =>
        !a.endDate ? -1 : !b.endDate ? 1 : new Date(b.endDate).valueOf() - new Date(a.endDate).valueOf(),
    },
    {
      title: "Status",
      render: (val) => <PipelineStatus status={val.status as PipelineRunStatus} />,
      sorting: false,
    },
  ],
  data: pipelineRuns,
});
