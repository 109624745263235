import { themeLight } from "./theme-light";
import { themeMain } from "./theme-main";
import { makeStyles as makeStylesMui } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.dark.css";
import { Theme } from "@material-ui/core";
import { Styles } from "@material-ui/styles/withStyles";

type CFactsThemeName = "light" | "main";

export function makeStyles<T extends {}, C extends string = string>(style: Styles<Theme, T, C>) {
  return makeStylesMui<Theme, T>(style);
}

export function getTheme(theme: CFactsThemeName) {
  if (theme === "light") {
    return { theme: themeLight };
  } else {
    return { theme: themeMain };
  }
}
