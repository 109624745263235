import { TooltipIconButton } from "./TooltipIconButton";
import DeleteIcon from "@material-ui/icons/Delete";

interface DeleteIconButtonProps {
  onClick?: () => void;
  tooltip: string;
}

export function DeleteIconButton({ onClick, tooltip }: DeleteIconButtonProps) {
  return <TooltipIconButton onClick={onClick} tooltip={tooltip} ariaLabel={"delete"} iconButton={<DeleteIcon />} />;
}
