// T should be the subtype of TestService
export class TestService<T> {
  // Allow any individual methods to be overwritten.
  constructor(self: T, readonly props?: Partial<T> | ((parent: T) => Partial<T>)) {
    if (props != null) {
      if (typeof props === "function") {
        Object.assign(this, props({ ...self }));
      } else {
        Object.assign(this, props);
      }
    }
  }
}

export type Extendable<T> = Partial<T> | ((parent: T) => Partial<T>);
export function extendable<T>(self: T, props?: Extendable<T>) {
  if (props != null) {
    if (typeof props === "function") {
      Object.assign(self, props({ ...self }));
    } else {
      Object.assign(self, props);
    }
  }
}
