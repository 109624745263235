import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { BudgetResponseDTO, OrganizationalUnitResponseDTO } from "../../../../open-api";
import { useBudgetsForOrganization } from "../../../../clients/budgetalerts.client";
import { BudgetType } from "../../../../utils/functional/budgetalerts.util";
import { AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { BudgetAlertInlineEditBudget } from "../../forms/partials/BudgetAlertInlineEditBudget";
import { BudgetAlertsList } from "../../forms/partials/BudgetAlertsList";
import { formattedCurrency, ucFirst, roundToTwoDecimals } from "../../../../utils/localization.utils";
import { DateTime } from "luxon";
import { mapBudgetType } from "../../../../mappers/organization.mapper";
import { useAggregateQuery, useMostRecentPeriodsFilter } from "../../../../clients/query.client";
import { GROUP_SEGMENT } from "../../../newdash/model/GroupSegment";

interface AccordionBudgetProps {
  organization: OrganizationalUnitResponseDTO;
  budgetType: BudgetType;
}

export const AccordionBudget = ({ organization, budgetType }: AccordionBudgetProps) => {
  const [budgets, refreshBudgets, isLoading] = useBudgetsForOrganization(organization.id);

  const [budget, setBudget] = useState<BudgetResponseDTO>();
  const [editFormId, setEditFormId] = useState<string>();

  const periodFilter = useMostRecentPeriodsFilter(1, true);
  const [lineItems] = useAggregateQuery(
    GROUP_SEGMENT.TOTAL.id,
    GROUP_SEGMENT.TOTAL.id,
    [
      ...periodFilter,
      {
        dimension:
          organization.unitType === "ROOT" ? GROUP_SEGMENT.CF_ORGANIZATION.id : GROUP_SEGMENT.CF_COST_CENTER.id,
        filters: [organization.id],
      },
    ],
    undefined,
    budget?.currency
  );

  useEffect(() => {
    setBudget(budgets ? budgets.find((it) => it.budgetType === budgetType) : undefined);
  }, [budgets, budget, isLoading, budgetType]);

  const lineItem = lineItems && lineItems.length > 0 ? lineItems[0].arguments[0]?.points[0] ?? null : null;

  const spending =
    budget?.budgetType === BudgetType.COST
      ? lineItem?.value.cost
      : lineItem?.value.sales ?? lineItem?.value.crossCharging;

  const setEditForm = (id: string, editing: boolean): void => {
    editing ? setEditFormId(id) : setEditFormId(undefined);
  };

  const isFormEdit = (id: string): boolean => {
    return id === editFormId;
  };

  const summary: string =
    budget && spending
      ? `Budget: ${formattedCurrency(budget.value, budget.currency, 0)}, reached: ${formattedCurrency(
          spending,
          budget.currency,
          0
        )} (${roundToTwoDecimals((spending / budget.value) * 100)}%) over ${DateTime.local().toFormat("MMMM y")} so far`
      : `Configure ${mapBudgetType(budgetType, organization).toLowerCase()} budget and alerting`;

  const item = {
    id: `organization-budget-alert-${budgetType.toLowerCase()}`,
    summaryMain: `${ucFirst(mapBudgetType(budgetType, organization))} budget alert`,
    summarySub: summary,
    details: (
      <Box display={"flex"} flexDirection={"column"} flexGrow={1} paddingLeft={1} paddingRight={1}>
        <Box paddingTop={1}>
          <BudgetAlertInlineEditBudget
            budgetType={budgetType as BudgetType}
            organization={organization}
            setEditFn={setEditForm}
            isEditFn={isFormEdit}
            refreshBudgets={refreshBudgets}
            budget={budget}
            spending={spending}
          />
        </Box>

        <Box paddingTop={4}>
          <BudgetAlertsList
            budget={budget}
            spending={spending}
            setEditFn={setEditForm}
            isEditFn={isFormEdit}
            refreshBudgets={refreshBudgets}
            organization={organization}
          />
        </Box>
      </Box>
    ),
  };

  return <AccordionItemList item={item} />;
};
