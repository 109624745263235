import { createContext, FC, useEffect } from "react";
import { useMergedState } from "../hooks/state.hooks";
import { useAuthenticatedId } from "../hooks/auth.hooks";
import { BillingPeriodFilter } from "../clients/billing-periods.client";
import { deleteLocalStorageValue, getLocalStorageValue, setLocalStorageValue } from "../utils/localStorage.utils";

export interface ConfigContextProps {
  config: Config;
  setConfig: (config: Partial<Config>) => void;
}

export interface Config {
  currency: string;
  hideDashboard: Record<string, boolean>;
  monthlyBillingPeriod: boolean;
  version: number;
  selectedBillingPeriods: BillingPeriodFilter | null;
}

export const ConfigContext = createContext<ConfigContextProps>(null!);

const defaultConfig = {
  currency: "EUR",
  hideDashboard: {},
  // DEPRECATED:
  monthlyBillingPeriod: true,
  version: 2,

  selectedBillingPeriods: null,
};

export const ConfigProvider: FC = ({ children }) => {
  const [configState, setConfigState] = useMergedState<Config>(defaultConfig);
  const id = useAuthenticatedId();

  // On initial render, get value from localstorage
  useEffect(() => {
    if (id) {
      const storage = getLocalStorageValue("c-facts-config", id);
      if (storage) {
        let config: Config;
        try {
          config = storage;
          if (config) {
            // reset the config if it's not the version 2 model
            if ("version" in config && config.version === 2) {
              setConfigState(config);
            } else {
              setConfigState(defaultConfig);
            }
          }
        } catch (e) {
          console.warn(`${storage} is not a valid config string`);
          deleteLocalStorageValue("c-facts-config", id);
        }
      }
    }
    // This line is very important, otherwise eslint will add setConfigState as dependency (or cry about it) which will
    // break this effect.
    // See https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
    // for more information
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Store changes to state in localstorage
  useEffect(() => {
    if (id) {
      setLocalStorageValue("c-facts-config", configState, id);
    }
  }, [id, configState]);

  return (
    <ConfigContext.Provider value={{ config: configState, setConfig: setConfigState }}>
      {children}
    </ConfigContext.Provider>
  );
};
