import { AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@material-ui/core";
import { TreeView } from "./TreeView";
import { CFactsIcon } from "../../../../theme/Icon";
import {
  ADJUSTMENT,
  adjustmentFromPriceAdjustment,
  AdjustmentType,
  labelForType,
  mapAssociatedPriceAdjustmentsToTreeListItems,
} from "../../../../utils/functional/adjustments.utils";
import { usePriceAdjustmentsForOrganization } from "../../../../clients/priceadjustments.client";
import { SearchableTagTreeAction } from "../../components/tags/SearchableTabTree";
import { ucFirst } from "../../../../utils/localization.utils";
import { mapGrossType } from "../../../../mappers/organization.mapper";
import { ActionButtonType } from "../../components/tags/buttons/TagActionButton";
import { css } from "@emotion/css";
import { FullScreenDialog } from "../../../../components/dialogs/FullScreenDialog";
import { PriceAdjustmentsFormEdit } from "../../forms/PriceAdjustmentsFormEdit";
import { isCustomer } from "../model/organization";
import { GrossTypeForm } from "./GrossTypeForm";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";
import { PriceAdjustments } from "../../../../model/PriceAdjustments";

interface AccordionGrossTypeProps {
  organization: OrganizationalUnitResponseDTO;
  onChange: () => void;
}

export function AccordionGrossType({ organization, onChange }: AccordionGrossTypeProps) {
  const theme = useTheme();
  const [priceAdjustments, refreshPriceAdjustments, isLoading] = usePriceAdjustmentsForOrganization(organization.id);
  const [editing, setEditing] = useState(false);

  const [treeItems, nodeIds] = mapAssociatedPriceAdjustmentsToTreeListItems(
    priceAdjustments,
    [
      organization.grossType?.toLowerCase() === AdjustmentType.MARKUP ? AdjustmentType.MARKUP : AdjustmentType.MARGIN,
      AdjustmentType.DISCOUNT,
    ].map((it) => adjustmentAction(organization, it)),
    organization
  );

  const item = {
    id: "organization-price-adjustment",
    summaryMain: isCustomer(organization) ? "Sales" : "Cross-charging",
    summarySub: `Configure ${formatCalculationGoal(organization)} calculation`,
    action: {
      icon: "EDIT" as CFactsIcon,
      tooltip: `Configure ${formatCalculationGoal(organization)} calculation`,
      onClick: () => setEditing(true),
    },
    details: (
      <Box flexGrow={1}>
        <TreeView nodeIds={nodeIds} isLoading={isLoading} items={treeItems} />

        <Typography classes={{ root: css({ marginTop: theme.spacing(2) }) }} color={"textSecondary"}>
          {organization.grossType == null || organization.basePrice == null ? (
            <>No {formatCalculationGoal(organization)} calculation configured</>
          ) : (
            <>
              This organization uses <strong>{formatBasePrice(organization.basePrice)}</strong> with{" "}
              <strong>{formatGrossType(organization)}</strong> as method to calculate the{" "}
              {formatCalculationGoal(organization)}.
            </>
          )}
        </Typography>
      </Box>
    ),
  };

  return (
    <>
      <AccordionItemList item={item} />

      <FullScreenDialog
        title={`Configure ${formatCalculationGoal(organization)} calculation`}
        open={editing}
        onClose={() => {
          // Refresh price adjustments after changing them, so they are updated on the accordion.
          refreshPriceAdjustments();
          setEditing(false);
        }}
        maxWidth={"1200px"}
      >
        <Grid>
          <Grid>
            <Box margin={"0 16px"}>
              <GrossTypeForm organization={organization} onChange={onChange} />
            </Box>
          </Grid>
          {organization.grossType != null && (
            <PriceAdjustmentsFormEdit config={{ type: "organization", id: organization.id, organization }} />
          )}
        </Grid>
      </FullScreenDialog>
    </>
  );
}

export function formatCalculationGoal(organization: OrganizationalUnitResponseDTO) {
  return isCustomer(organization) ? "sales price" : "cross-charging";
}

export function formatBasePrice(basePrice: NonNullable<OrganizationalUnitResponseDTO["basePrice"]>) {
  switch (basePrice) {
    case "RETAIL":
      return "retail price";
    case "INVOICED":
      return "invoiced cost";
    case "DISCOUNT":
      return "cost after discount";
  }
}

export function formatGrossType(organization: Pick<OrganizationalUnitResponseDTO, "grossType" | "type">) {
  if (!isCustomer(organization)) {
    return "markup";
  } else {
    switch (organization.grossType) {
      case "MARGIN":
        return "margin";
      case "MARKUP":
      default:
        return "profit";
    }
  }
}

const adjustmentAction = (
  organization: OrganizationalUnitResponseDTO,
  adjustmentType: AdjustmentType
): SearchableTagTreeAction<PriceAdjustments> => {
  return {
    name: adjustmentType,
    icon: ADJUSTMENT.ICON[adjustmentType],
    labelFn: labelForType,
    tooltip: ucFirst(mapGrossType(adjustmentType, organization)),
    actionButtonType: ActionButtonType.CHIP_NO_CLICK,
    organizationalUnitId: organization.id,
    inputControls: {
      init: (adjustment: PriceAdjustments): number => {
        return adjustmentFromPriceAdjustment(adjustment, adjustmentType) ?? 0;
      },
      max: (value: PriceAdjustments) => ADJUSTMENT.MAX_VALUE,
    },
    shouldShow: () => true,
    selected: (value: PriceAdjustments): boolean => {
      const adjustment = value.adjustments?.[adjustmentType]?.applied;
      const adjustmentOrganization =
        adjustment != null && "organization" in adjustment ? adjustment.organization : undefined;
      return (
        !!value.adjustments[adjustmentType] &&
        adjustmentOrganization?.id === organization.id &&
        !value.adjustments[adjustmentType]?.applied.inherited
      );
    },
    chipStyle: { minWidth: "120px", margin: "0px 8px" },
  };
};
