import { StoredDashboard } from "../pages/newdash/model/StoredDashboard";
import { Config } from "../providers/config.provider";

type ConnectorStatusType = "PENDING" | "VERIFYING" | "VERIFIED" | "FAILED";

type LocalStorageFixedTypes = {
  redirectRoute?: string;
  redirectSearch?: string;
  "connector.status"?: ConnectorStatusType;
  "connector.adDomain"?: string;
  "c-facts-dashboards-v2"?: Array<StoredDashboard>;
};

type LocalStoragePrefixTypes = {
  "c-facts-config"?: Config;
};

type LocalStorageKeyTypes = LocalStorageFixedTypes & LocalStoragePrefixTypes;

export function getLocalStorageValue<K extends keyof LocalStorageKeyTypes>(
  key: K,
  suffix?: string
): LocalStorageKeyTypes[K] | null {
  const value = localStorage.getItem(suffix ? `${key}-${suffix}` : key);
  return value ? JSON.parse(value) : null;
}

export function setLocalStorageValue<K extends keyof LocalStorageKeyTypes>(
  key: K,
  value: LocalStorageKeyTypes[K],
  suffix?: string
) {
  localStorage.setItem(suffix ? `${key}-${suffix}` : key, JSON.stringify(value));
}

export function deleteLocalStorageValue<K extends keyof LocalStorageKeyTypes>(key: K, suffix?: string) {
  localStorage.removeItem(suffix ? `${key}-${suffix}` : key);
}
