import { useRef, useState } from "react";
import { PieChart } from "devextreme-react";
import { makeStyles } from "@material-ui/core/styles";
import { IPieChartOptions } from "devextreme-react/pie-chart";
import { useCurrency } from "../../../hooks/config.hooks";
import { absoluteCenter } from "../../../utils/styles";
import { Alert } from "@material-ui/lab";
import { LineItemDisplay, PieChartConfig } from "../model/ChartConfig";
import { escapeHtml } from "../../../utils/dom";
import { attributeId } from "../model/Attribute";
import { css } from "@emotion/css";
import { Box, CircularProgress } from "@material-ui/core";
import { useChartTitles } from "../selectors/ChartConfig";
import { useChartFilterToggle } from "../events/GroupSegmentFilter.events";
import { useAttributeColorGenerator } from "../model/ColorCache";
import { useInvoicePeriods } from "../../../clients/billing-periods.client";
import { ChartMenu } from "./ChartMenu";
import { useAggregatedLineItemsForDashboard } from "../model/Query";
import { useDashboardShouldLoad } from "../NewDashPageProvider";

const SERIES_TOP_COUNT = 10;

const useStyles = makeStyles(() => ({
  pieChartWrapper: {
    marginTop: 36,
    position: "relative",
  },
  center: absoluteCenter,
}));

interface DashPieChartProps extends IPieChartOptions {
  config: PieChartConfig;
}

export const DashPieChart = ({ config, type = "donut" }: DashPieChartProps) => {
  const ref = useRef<PieChart>(null);
  const classes = useStyles();
  const format = useChartFormat(config.displaySegment);
  const { title, subTitle } = useChartTitles(config);
  const [topCount, setTopCount] = useState<number>(SERIES_TOP_COUNT);
  const [dirty, setDirty] = useState(false);
  const filterToggle = useChartFilterToggle();
  const colorGenerator = useAttributeColorGenerator();
  const billingPeriods = useInvoicePeriods();

  const shouldLoad = useDashboardShouldLoad();

  const [items, , loading] = useAggregatedLineItemsForDashboard(
    config.groupSegment,
    undefined,
    topCount,
    true,
    config.displaySegment.key
  );

  const displayMap: Record<string, string> = {};
  // TODO: Make sure all possible values are in the lineitem?
  const pieChartDataItems =
    items && items.length > 0 && items[0].arguments.length > 0
      ? items[0].arguments[0].points.map((point) => {
          displayMap[point.key] = point.keyDisplay || point.key;
          return {
            key: point.key,
            value: point.value[config.displaySegment.value] || 0,
          };
        })
      : [];

  const addTopCount = (count: number) => {
    setTopCount(topCount + count);
  };

  const toggleVisibility = (item: any) => {
    item.isVisible() ? item.hide() : item.show();
  };

  const legendClickHandler = (e: any) => {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0]; // TODO: uhmmm!? magic!?
    toggleVisibility(item);
    setDirty(true);
  };

  const pointClickHandler = (item: any) => {
    const value: string = item.target.argument;
    if (value !== "<other>") {
      filterToggle.onFilterToggled({
        groupSegment: config.groupSegment,
        value: {
          id: value,
          label: argumentDisplayValue(value),
        },
      });
    } else {
      addTopCount(5);
    }
    setDirty(true);
  };

  return (
    <Box className={css({ display: "flex", position: "relative" })}>
      <Box className={css({ marginTop: 36, position: "relative" })}>
        {loading && (
          <div className={css(absoluteCenter)}>
            <CircularProgress size={45} />
          </div>
        )}
        {pieChartDataItems.length === 0 && !loading && (
          <div className={classes.center}>
            <Alert style={{ width: "320px" }} variant="outlined" severity="warning">
              No data available to display this graph.
            </Alert>
          </div>
        )}
        <PieChart
          ref={ref}
          id="pie"
          type={type}
          disabled={loading || !shouldLoad}
          dataSource={pieChartDataItems}
          legend={{ customizeText: (e) => argumentDisplayValue(e.pointName) }}
          palette={"Bright"}
          title={{
            text: escapeHtml(title),
            font: { color: "white" },
            subtitle: { text: escapeHtml(subTitle) },
          }}
          size={{ width: 500, height: 400 }}
          onPointClick={pointClickHandler}
          onLegendClick={legendClickHandler}
          tooltip={{
            enabled: true,
            format: format({ short: false }),
            customizeTooltip: (arg) => ({
              text: `${argumentDisplayValue(arg.argumentText)} - ${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
            }),
          }}
          customizePoint={(e) => ({ color: colorGenerator(attributeId(config.groupSegment), e.argument) })}
          series={{
            argumentField: "key",
            valueField: "value",
            label: {
              visible: true,
              format: format({ short: true }),
              connector: { visible: true },
            },
          }}
        />
      </Box>
      <Box className={css({ position: "absolute", top: 0, right: 0 })}>
        <ChartMenu
          chartRef={ref}
          chartConfig={config}
          onReset={() => {
            filterToggle.onReset({ groupSegment: config.groupSegment });
            setTopCount(SERIES_TOP_COUNT);
          }}
          dirty={[dirty, setDirty]}
        />
      </Box>
    </Box>
  );

  function argumentDisplayValue(arg: string): string {
    if (displayMap[arg]) {
      return escapeHtml(displayMap[arg]);
    }
    const segment = config.groupSegment;

    if (arg === "others" || arg === "Unassigned") {
      return arg;
    }
    if (segment.type === "billingCycle") {
      const period = billingPeriods.find((it) => it.id === arg);
      return `${period?.collectorName}, ${period?.anchorDateDisplay}`;
    }
    return arg;
  }
};

export function useChartFormat(displaySegment: LineItemDisplay) {
  const currency = useCurrency();
  return ({ short }: { short: boolean }) =>
    displaySegment.value !== "quantity"
      ? ({ type: "currency", currency } as const)
      : (value: number | Date) =>
          value < 1000 || !short
            ? new Intl.NumberFormat("en-US").format(Number(value))
            : `${Math.round(Number(value) / 1000)}K`;
}
