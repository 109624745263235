import { Icon, IconProps } from "../../theme/Icon";
import { IconButton, Tooltip } from "@material-ui/core";
import { CFactsAction } from "./CFactsAction";

interface ActionIconButtonProps extends IconProps {
  action: CFactsAction;
  buttonSize?: "small" | "medium";
  className?: string;
}

export const ActionIconButton = ({ action, buttonSize, className, ...iconProps }: ActionIconButtonProps) => {
  return (
    <Tooltip title={action.tooltip}>
      <IconButton
        edge="end"
        aria-label={action.tooltip}
        onClick={action.onClick}
        disabled={action.disabled}
        size={buttonSize}
        className={className}
      >
        <Icon {...iconProps} icon={action.icon} />
      </IconButton>
    </Tooltip>
  );
};
