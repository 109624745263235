import { CollectorConfigResponseDTO, CollectorResponseDTO } from "../open-api";

export function collectorType(val: CollectorResponseDTO) {
  if (val.type === "AWS") {
    return "AWS";
  }
  if (val.type === "AZURE") {
    // @ts-ignore
    return "Azure " + (val.credentials?.accountType || "");
  }
  if (val.type === "ORACLE") {
    return "Oracle cloud";
  }
  if (val.type === "GOOGLE") {
    return "Google cloud";
  }
}

export function getIncludeAllData(val: CollectorConfigResponseDTO | null) {
  return val?.generic?.values?.includeAllData ?? true;
}
