import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { useApiCall } from "../../../hooks/client.hooks";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import { EditWorkspaceUserRequestDTO, UserDTO } from "../../../open-api";
import { useServices } from "../../../services/context.service";
import { UserEditForm } from "../forms/UserEditForm";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { WorkspaceRole } from "../../../hooks/auth.hooks";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

interface userEditDialogFormProps {
  user: UserDTO;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export function UserEditDialogForm({ user, onClose, onSuccess, onError }: userEditDialogFormProps) {
  const { users } = useServices();
  const classes = useStyles();

  const [edit, isSubmitting] = useApiCall(users.edit);

  const { handleSubmit, control } = useCFForm({
    schema: z.object({
      name: z.string(),
      role: z.enum(["ADMIN", "READ"]),
    }),
    defaultValues: { name: user.name, role: user.role as "ADMIN" | "READ" | undefined },
  });

  const editUser = (userValues: EditWorkspaceUserRequestDTO) => {
    edit({
      userId: user.id,
      editWorkspaceUserRequestDTO: userValues,
    })
      .then((e) => {
        messageSuccess("User successfully edited");
        onSuccess && onSuccess();
      })
      .catch((e) => {
        messageError(e.message);
        onError && onError();
      });
  };

  const close = () => {
    onClose();
  };

  return (
    <form
      onSubmit={handleSubmit((form) => {
        editUser(form);
      })}
    >
      <DialogContent className={classes.content}>
        <UserEditForm control={control} canEditRole={user.role !== WorkspaceRole.MANAGED_C_VIEW} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
