import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { FormTitleField } from "../../components/form/FormTitleField";
import * as React from "react";
import { useEffect, useState } from "react";
import { useShowDialog } from "../../hooks/dialog.hooks";
import { EnableMFADialog } from "./components/EnableMFADialog";
import { useAppContext, useAuthParams } from "../../hooks/auth.hooks";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { useServices } from "../../services/context.service";
import { messageError, messageSuccess } from "../../services/message.service";
import { useDataClientPromise } from "../../hooks/client.hooks";

export function MFASettings() {
  const mfaService = useServices().mfa;
  const { mfaEnabled } = useAuthParams();
  const refreshBootstrap = useAppContext().refresh;
  const [enabled, setEnabled] = useState(mfaEnabled);
  const [showDialog, setShowDialog, dialogProps] = useShowDialog();
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [isMFAForced] = useDataClientPromise(mfaService.isMFAForced, []);
  const [toggleDisabled, setToggleDisabled] = useState(false);

  const handleChange = (e: any, checked: boolean) => {
    if (!mfaEnabled && checked) {
      setShowDialog(true);
    }
    if (mfaEnabled && !checked) {
      setShowConfirmDisable(true);
    }
  };

  useEffect(() => {
    setEnabled(mfaEnabled);
  }, [mfaEnabled, setEnabled]);

  useEffect(() => {
    setToggleDisabled(isMFAForced === "true" ?? false);
  }, [isMFAForced]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTitleField text="Use 2-Factor authentication" />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch color="primary" checked={enabled} onChange={handleChange} />}
            disabled={toggleDisabled}
            label={"Use MFA for extra security"}
          />
        </Grid>
      </Grid>
      {showDialog && <EnableMFADialog onSuccess={refreshBootstrap} {...dialogProps} />}
      {showConfirmDisable && (
        <ConfirmDialog
          open={showConfirmDisable}
          title={"Disable MFA"}
          content={
            <>
              <strong>Are you sure you want to disable MFA?</strong>
              <br />
              <br />
              Disabling MFA results in reduced security. We recommend against doing this.
            </>
          }
          confirmationText={"I am sure"}
          onClose={() => setShowConfirmDisable(false)}
          onConfirm={() =>
            mfaService
              .disableMFA()
              .then(() => {
                messageSuccess("MFA Successfully disabled");
                refreshBootstrap();
                setShowConfirmDisable(false);
              })
              .catch(() => {
                messageError("Something went wrong while disabling MFA");
              })
          }
        />
      )}
    </>
  );
}
