import { AttributeTypeDTODisplayStateEnum } from "../../../../open-api";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import { SetAttributeStatus } from "./AttributeList";

interface AttributeFilterButtonsProps {
  type: AttributeTypeDTODisplayStateEnum;
  setStatus?: SetAttributeStatus;
  id?: string;
  setAllStatus?: any;
  canDelete?: boolean;
}

export const AttributeFilterButtons = ({
  canDelete,
  type,
  setStatus,
  id,
  setAllStatus,
}: AttributeFilterButtonsProps) => {
  return (
    <>
      {canDelete && (
        <ActionIconButton
          buttonSize={"small"}
          action={{
            icon: GenericIcon.DELETE,
            tooltip: "Delete historic data",
            disabled: type === AttributeTypeDTODisplayStateEnum.DELETE,
            onClick: () => {
              id
                ? setStatus?.(id, AttributeTypeDTODisplayStateEnum.DELETE)
                : setAllStatus(AttributeTypeDTODisplayStateEnum.DELETE);
            },
          }}
        />
      )}
      {type !== AttributeTypeDTODisplayStateEnum.NEW && (
        <ActionIconButton
          buttonSize="small"
          action={{
            icon: GenericIcon.UNDECIDED,
            tooltip: "Set as undecided",
            onClick: () => {
              id
                ? setStatus?.(id, AttributeTypeDTODisplayStateEnum.NEW)
                : setAllStatus(AttributeTypeDTODisplayStateEnum.NEW);
            },
          }}
        />
      )}
      {type !== AttributeTypeDTODisplayStateEnum.INCLUDE && (
        <ActionIconButton
          buttonSize="small"
          action={{
            icon: GenericIcon.UNHIDE,
            tooltip: "Include in data collection",
            onClick: () => {
              id
                ? setStatus?.(id, AttributeTypeDTODisplayStateEnum.INCLUDE)
                : setAllStatus?.(AttributeTypeDTODisplayStateEnum.INCLUDE);
            },
          }}
        />
      )}
      {type !== AttributeTypeDTODisplayStateEnum.HIDE && type !== AttributeTypeDTODisplayStateEnum.DELETE && (
        <ActionIconButton
          buttonSize="small"
          action={{
            icon: GenericIcon.HIDE,
            tooltip: "Exclude from data collection",
            onClick: () => {
              id
                ? setStatus?.(id, AttributeTypeDTODisplayStateEnum.HIDE)
                : setAllStatus?.(AttributeTypeDTODisplayStateEnum.HIDE);
            },
          }}
        />
      )}
    </>
  );
};
