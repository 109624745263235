import { useState } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { tableIcons } from "../../theme/TableIcons";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { messageError, messageSuccess } from "../../services/message.service";
import { formattedDateTime, ucFirst } from "../../utils/localization.utils";
import { UserEditDialog } from "./dialogs/UserEditDialog";
import { useServices } from "../../services/context.service";
import { useApiCall, useDataClientPromise } from "../../hooks/client.hooks";
import { AddWorkspaceUser } from "./AddWorkspaceUser";
import { Link } from "@material-ui/core";
import {UserDTO} from "../../open-api";
import UpdateIcon from "@material-ui/icons/Update";

interface UserListProps {}

export function UserList(props: UserListProps) {
  const { users, mfa } = useServices();

  const [allUsers, refreshUsers, isLoading] = useDataClientPromise(users.getAll, []);
  const [deleteUser] = useApiCall(users.delete);

  const [selectedForDelete, setSelectedForDelete] = useState(null as UserDTO | null);
  const [selectedForEdit, setSelectedForEdit] = useState(null as UserDTO | null);
  const [selectedForReset, setSelectedForReset] = useState(null as UserDTO | null);

  const handleClickReset = (ds: UserDTO) => setSelectedForReset(ds);
  const handleClickDelete = (ds: UserDTO) => setSelectedForDelete(ds);
  const handleClickEdit = (ds: UserDTO) => setSelectedForEdit(ds);

  const onCloseDelete = () => {
    setSelectedForDelete(null);
  };

  const onCloseReset= () => {
    setSelectedForReset(null);
  };

  const handleDelete = (ds: UserDTO) =>
    deleteUser(ds.id)
      .then((_) => {
        messageSuccess("User deleted");
        refreshUsers();
      })
      .catch((e) => messageError(e.message));

  const handleReset = (ds: UserDTO) =>
      mfa.resetMFA(ds.email)
      .then((_) => {
        messageSuccess("Password and TOTP token reset");
      })
      .catch((e) => messageError(e.message));

  if (!allUsers || isLoading) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );
  }

  const columns: Column<UserDTO>[] = [
    {
      title: "Name",
      field: "name",
      width: 200,
    },
    {
      title: "Email",
      field: "email",
      render: (row) => <Link href={`mailto:${row.email}`}>{row.email}</Link>,
    },
    {
      title: "Role",
      field: "role",
      width: 200,
      render: (row) => ucFirst(row.role),
    },
    {
      title: "Last login",
      field: "lastActive",
      render: (row) => ucFirst(formattedDateTime(row.lastActive, "Never")),
    },
  ];

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={"Users"}
        columns={columns}
        data={allUsers}
        options={{ draggable: false, pageSize: 10, actionsColumnIndex: -1 }}
        actions={[
          (rowData) => ({
            icon: () => <EditIcon />,
            tooltip: "Edit user",
            onClick: (event, rowData) => handleClickEdit(Array.isArray(rowData) ? rowData[0] : rowData || null),
          }),
          (rowData) => ({
            icon: () => <DeleteOutlineIcon />,
            tooltip: "Delete user",
            onClick: (event, rowData) => handleClickDelete(Array.isArray(rowData) ? rowData[0] : rowData || null),
          }),
          (rowData) => ({
            icon: () => <UpdateIcon />,
            tooltip: rowData.mfaEnabled ? "Reset the password and TOTP." : "TOTP is not set for this user.",
            onClick: (event, rowData) => handleClickReset(Array.isArray(rowData) ? rowData[0] : rowData || null),
            disabled: !rowData.mfaEnabled
          })
        ]}
      />
      {selectedForDelete && (
        <ConfirmDialog
          open={!!selectedForDelete}
          title={"Delete user"}
          content={`Are you sure you want to delete user: ${selectedForDelete.name}?`}
          onClose={() => onCloseDelete()}
          onConfirm={() => handleDelete(selectedForDelete)}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
      {selectedForEdit && (
        <UserEditDialog
          user={selectedForEdit}
          onClose={() => {
            setSelectedForEdit(null);
          }}
          onSuccess={() => refreshUsers()}
          open={!!selectedForEdit}
        />
      )}
      {selectedForReset && (
          <ConfirmDialog
              open={!!selectedForReset}
              title={"Reset the password and the TOTP"}
              content={`You are about to reset the password and the TOTP token for user: ${selectedForReset.name}.
                    The user will be notified with an email to reset the password and token. Do you wish to proceed?`}
              onClose={() => onCloseReset()}
              onConfirm={() => handleReset(selectedForReset)}
          >
            <DeleteOutlineIcon style={{ fontSize: 80 }} />
          </ConfirmDialog>
      )}
      <AddWorkspaceUser onSuccess={() => refreshUsers()} />
    </>
  );
}
