import { appConfig } from "../app.config";
import { failDebounce, messageError } from "../services/message.service";
import { saveAs } from "file-saver";
import { CacheNotYetCalculatedError } from "../services/context.service";
import { setLocalStorageValue } from "./localStorage.utils";

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

const api = async (
  method: RequestMethod,
  uri: string,
  body: any = {},
  headers: Record<string, string> | null = null,
  signal?: AbortSignal
): Promise<any> => {
  const requestInit: RequestInit = {
    method,
    headers: headers || {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    signal,
  };

  if (["PUT", "POST"].includes(method) && body) {
    if ((requestInit.headers as Record<string, string>)["Content-Type"] === "application/json") {
      requestInit.body = JSON.stringify(body);
    } else {
      requestInit.body = body;
    }
  }

  let res = await fetch(`${appConfig.apiBaseUri}${encodeURI(uri)}`, requestInit);

  if (res.status === 400) {
    // validation/user submitted error
    const { status, message } = await res.json();
    throw Error(message ?? status);
  } else if (res.status === 409) {
    // response should be cached, but isn't yet, so we have to try again in a couple of seconds:
    throw new CacheNotYetCalculatedError();
    // await delay(5000);
    // return api(method, uri, body, headers);
  } else if (res.status === 401) {
    // Unauthenticated
    setLocalStorageValue("redirectRoute", window.location.pathname);
    setLocalStorageValue("redirectSearch", window.location.search);

    window.location.href = "/login?state=expired";
  } else if (!res.ok && res.status !== 400) {
    // Some other error
    failDebounce(res.statusText);
    throw Error(res.statusText);
  } else if (res.headers.get("content-type") === "application/json") {
    // Success
    try {
      return await res.json();
    } catch (e) {
      return res.body;
    }
  }
};

export const getRequest = async (uri: string) => await api("GET", uri);

export const postRequest = async (uri: string, body: any, headers?: any, signal?: AbortSignal) =>
  await api("POST", uri, body, headers, signal);

export const deleteRequest = async (uri: string) => await api("DELETE", uri);

export const putRequest = async (uri: string, body: any) => await api("PUT", uri, body);

export const downloadRequest = async (uri: string, fileType: string, filename?: string) => {
  const requestInit: RequestInit = {
    method: "GET",
    headers: {
      Accept: fileType,
    },
  };

  let res = await fetch(`${appConfig.apiBaseUri}${uri}`, requestInit);

  if (!res.ok) {
    if (res.status === 401) window.location.href = "/login";
    messageError(res.statusText);
    throw Error(res.statusText);
  }
  filename = filename ?? getFilename(res.headers.get("content-disposition") || "");
  const blob = await res.blob();
  saveAs(blob, filename);
};

const getFilename = (header: string) => {
  const pattern = /filename=(["']?)(.+)\1/i;

  if (pattern.test(header)) {
    // @ts-ignore
    return header.match(pattern)[2];
  }

  return "download.csv";
};
export const csvRequest = async (uri: string) => {
  return downloadRequest(uri, "text/csv");
};

export const xlsRequest = async (uri: string) => {
  return downloadRequest(uri, "application/vnd.ms-excel");
};
