import { Button, Grid, GridProps, List, Paper, Typography, useTheme } from "@material-ui/core";
import { css } from "@emotion/css";
import { EditIconButton } from "../buttons/EditIconButton";
import { SubmitHandler } from "react-hook-form";
import { TooltipIconButton } from "../buttons/TooltipIconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { FC, ReactNode } from "react";
import { GeneralPageSkeleton } from "../skeletons/GeneralPageSkeleton";

interface CFContainerProps {
  header?: ReactNode;
  actions?: {
    edit?: () => void;
    back?: () => void;
  };
  isLoading?: boolean;
  formActions?: {
    save?: () => void;
    cancel?: () => void;
    handleSubmit?: SubmitHandler<any>;
  };
}

export const CFContainer: FC<CFContainerProps & GridProps> = ({ children, ...props }) => {
  return (
    <Grid container xs={12} spacing={2} {...props}>
      {children}
    </Grid>
  );
};

export const CFCardContent: FC<{
  cancel?: () => void;
  save?: () => void;
  handleSubmit?: SubmitHandler<any>;
}> = ({ children, cancel, save, handleSubmit }) => {
  const theme = useTheme();
  return (
    <>
      {children}
      <Grid container spacing={0}>
        <Grid
          item
          justify="flex-end"
          className={css({
            marginTop: theme.spacing(2),
            display: "flex",
            flexGrow: 1,
          })}
        >
          {cancel && <Button onClick={cancel}>Cancel</Button>}
          {save && (
            <Button color="primary" variant="contained" onClick={save}>
              save
            </Button>
          )}
          {handleSubmit && (
            <Button type="submit" color="primary" variant="contained">
              save
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const CFCard: FC<CFContainerProps & GridProps> = ({
  isLoading,
  header,
  actions,
  formActions,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Grid xs={12} item {...props} className={css({ marginBottom: "12px" })}>
      <Paper className={css({ padding: theme.spacing(2) })}>
        <Grid container spacing={0}>
          <Grid
            item
            className={css({
              flexGrow: 1,
            })}
          >
            {header && <Typography variant={"h5"}>{header}</Typography>}
          </Grid>
          <Grid
            className={css({
              marginTop: theme.spacing(-1),
              marginRight: theme.spacing(-1),
            })}
            item
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            {actions?.edit && <EditIconButton onClick={actions.edit} tooltip={"Edit"} />}
            {actions?.back && (
              <TooltipIconButton
                onClick={actions.back}
                tooltip={"Back"}
                ariaLabel={"back"}
                iconButton={<ArrowBackIcon />}
              />
            )}
          </Grid>
        </Grid>
        {isLoading && <GeneralPageSkeleton />}
        {!isLoading &&
          (formActions?.handleSubmit ? (
            <form onSubmit={formActions.handleSubmit}>
              <CFCardContent
                cancel={formActions?.cancel}
                save={formActions?.save}
                handleSubmit={formActions?.handleSubmit}
              >
                {children}
              </CFCardContent>
            </form>
          ) : (
            <CFCardContent
              cancel={formActions?.cancel}
              save={formActions?.save}
              handleSubmit={formActions?.handleSubmit}
            >
              {children}
            </CFCardContent>
          ))}
      </Paper>
    </Grid>
  );
};

export const CFPageTitle: FC<CFContainerProps & GridProps> = ({ children, header, ...props }) => {
  return (
    <Grid xs={12} item {...props}>
      <Grid container spacing={0}>
        <Grid item className={css({ display: "flex", alignItems: "center" })}>
          <Typography className={css({ marginBottom: "0px" })} variant={"h5"}>
            {header}
          </Typography>
        </Grid>
        <Grid item justifyContent="flex-end" className={css({ flexGrow: 1, display: "flex" })}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CFList: FC<{
  maxHeight?: string;
  height?: string;
  minHeight?: string;
}> = ({ minHeight, height, maxHeight, children }) => {
  return (
    <List
      dense
      className={css({
        overflow: "auto",
        margin: "-16px",
        height,
        minHeight,
        maxHeight,
      })}
    >
      {children}
    </List>
  );
};

export const CFNestedCard: FC<
  {
    header?: string | React.ReactNode;
  } & GridProps
> = ({ header, children, ...props }) => {
  const theme = useTheme();

  return (
    <CFBox
      className={css({
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
      })}
      {...props}
    >
      {header && <Typography variant={"h6"}>{header}</Typography>}
      <Paper
        elevation={2}
        className={css({
          padding: theme.spacing(2),
          // backgroundColor: theme.palette.grey["700"],
        })}
      >
        {children}
      </Paper>
    </CFBox>
  );
};

export const CFBox: FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid item {...props}>
      {children}
    </Grid>
  );
};
