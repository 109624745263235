import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useRedirect } from "../../hooks/router.hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: "none",
  },
  padding: {
    padding: theme.spacing(2),
  },
  popper: {
    marginTop: theme.spacing(0),
    fontSize: theme.typography.pxToRem(14),
    maxWidth: 500,
  },
  styledTab: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    minWidth: theme.spacing(4),
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export interface TabProps {
  label: string;
  className?: string;
  tooltip?: string;
  key?: number;
  url?: string;
}

interface TabsStyledProps {
  handleChange?: (value: number) => void;
  tabValue?: number;
  tabs: TabProps[];
}

export function TabsStyled({ handleChange, tabValue, tabs }: TabsStyledProps) {
  const classes = useStyles();
  const redirect = useRedirect();
  const location = useLocation();

  const onChange = (event: any, value: any) => {
    const url = tabs[value].url;
    if (url) {
      redirect(url);
    }
    if (handleChange) {
      handleChange(value);
    }
  };

  const urlIndex = tabs.findIndex((it) => it.url && location.pathname.startsWith(it.url));

  const tabIndex = urlIndex !== -1 ? urlIndex : tabValue;

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={onChange}
        classes={{
          root: classes.root,
        }}
        TabIndicatorProps={{ className: classes.indicator, children: <span /> }}
      >
        {tabs.map((tab) => (
          <Tooltip arrow classes={{ tooltip: classes.popper }} title={tab.tooltip || ""} key={`tooltip-${tab.label}`}>
            <Tab
              disableRipple
              classes={{
                root: classes.styledTab,
              }}
              className={tab.label !== "Inactive" ? tab.className : undefined}
              key={`tab-${tab.label}`}
              label={tab.label}
            />
          </Tooltip>
        ))}
      </Tabs>
    </>
  );
}
