import { Card, Grid, Typography } from "@material-ui/core";
import { acceptInvitation, useInvitations } from "../../../clients/invitations.client";
import CardContent from "@material-ui/core/CardContent";
import { GenericIcon, Icon } from "../../../theme/Icon";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { useCurrency, useSetCurrency } from "../../../hooks/config.hooks";

export const Invitations = () => {
  const [invitations, refresh] = useInvitations();

  const currency = useCurrency();
  const setCurrency = useSetCurrency();

  if (!invitations || invitations.length === 0) {
    return null;
  }
  return (
    <>
      {invitations.map((it) => (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container justify="center" alignItems="center" direction="column">
                <Icon icon={GenericIcon.SHARED} size={"large"} />
                <Typography variant="h4">New invitation</Typography>
                <Typography>
                  You have been invited by <strong>{it.inviteBy}</strong> to view cloud data for{" "}
                  <strong>{it.inviteFor}</strong>. Click get started to get insight into your consumption!
                </Typography>
                <br />
                <ButtonConfirm
                  onClick={() =>
                    acceptInvitation(it.id).then(() => {
                      refresh();
                      // Hacky way to reload line item data, not sure if this works
                      setCurrency(currency);
                    })
                  }
                >
                  Get started
                </ButtonConfirm>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};
