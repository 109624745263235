import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "unset",
    marginLeft: -theme.spacing(5),
  },
  logo: {
    width: "100%",
    maxHeight: "100%",
  },
  tile: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tileDisabled: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.action.disabled,
  },
  centered: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
}));

export interface Tile {
  contents: ReactNode;
  title?: String;
  onClick?: () => void;
  disabled?: boolean;
}
interface TilesProps {
  tileList: Tile[];
}

export const Tiles = ({ tileList }: TilesProps) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={5} className={classes.root}>
      {tileList.map((tile, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Box width="1" height={"1"}>
            <Card
              variant="outlined"
              className={tile.disabled ? classes.tileDisabled : classes.tile}
              onClick={tile.onClick}
            >
              <CardContent className={classes.centered}>
                {tile.contents}
                {tile.title ? <Typography align={"center"}>{tile.title}</Typography> : ""}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
