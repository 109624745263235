import Grid from "@material-ui/core/Grid";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { Typography } from "@material-ui/core";
import { CsvConfigField } from "./CsvConfigField";
import { CsvConfigPreview } from "./CsvConfigPreview";
import { useColumnNames, useDataRows, useUploadConfig } from "./CsvUpload";
import { HAS_HEADER_ID, useCsvUploadFormMethods } from "./CsvUploadForm";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { UploadCsvFieldRequestDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItemPaddingRight: {
      paddingRight: theme.spacing(2),
    },
    error: {
      color: theme.palette.error.main,
    },
    gridMarginTop: {
      marginTop: theme.spacing(2),
    },
    gridMarginBottom: {
      marginBottom: theme.spacing(2),
    },
    hidden: {
      display: "none",
    },
  })
);

export const configFieldDefaultId = (key: string): string => {
  return `invoice.config.fields.${key}.default`;
};

export const configFieldIndexId = (key: string): string => {
  return `invoice.config.fields.${key}.index`;
};

export const CsvConfigFieldMapper = () => {
  const formMethods = useCsvUploadFormMethods();
  const csvUploadConfig = useUploadConfig();
  const [dataRows] = useDataRows();
  const [columnNames] = useColumnNames();

  const classes = useStyles();
  const configFields = csvUploadConfig ? csvUploadConfig.fields : [];

  const hasHeader = formMethods.watch(HAS_HEADER_ID);
  const csvFields = formMethods.watch()?.invoice.config?.fields;

  const fieldsThatHaveBeenMappedToIndex: UploadCsvFieldRequestDTO[] = csvFields
    ? Object.entries(csvFields)
        .map(([key, value]) => {
          return { ...value, key: key } as UploadCsvFieldRequestDTO;
        })
        .filter((index) => {
          return typeof index.index === "number";
        })
    : [];

  const getMappedField = (field: string): UploadCsvFieldRequestDTO | undefined => {
    return fieldsThatHaveBeenMappedToIndex.find((it) => it.key === field);
  };

  const fieldsThatHaveBeenMappedToIndexEntries: number[] = fieldsThatHaveBeenMappedToIndex.map(
    (value: UploadCsvFieldRequestDTO) => value.index as number
  );

  const getMappedColumnValues = (field: string, count: number): string[] => {
    const mappedField = getMappedField(field);
    const offSet: number = hasHeader ? 1 : 0;
    return mappedField && typeof mappedField.index === "number"
      ? dataRows.slice(offSet, count + offSet).map((i) => i[mappedField.index as number])
      : [];
  };

  const fieldSelectOptionsFn = (value: number) => {
    return columnNames
      .map((it: any, idx: number) => {
        return { idx: idx, label: it };
      })
      .filter((itt) => !fieldsThatHaveBeenMappedToIndexEntries.includes(itt.idx) || itt.idx === value);
  };

  return (
    <Grid container item spacing={2} classes={{ root: classes.gridMarginTop }}>
      <Grid item xs={12}>
        <FormTitleField text={"Map columns to fields (required)"} />
      </Grid>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={2}>
          <Typography variant={"h6"} color={"textSecondary"}>
            Field name
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"h6"} color={"textSecondary"}>
            Column to map to field
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={"h6"} color={"textSecondary"}>
            Values read from CSV
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h6"} color={"textSecondary"}>
            Default
          </Typography>
        </Grid>
      </Grid>

      {configFields.map((field) => {
        return (
          <CsvConfigField
            field={field}
            configFieldDefaultIdFn={configFieldDefaultId}
            configFieldIndexIdFn={configFieldIndexId}
            formMethods={formMethods}
            hasValue={!!formMethods.getValues(configFieldIndexId(field.key))}
            hasDefaultValue={!!formMethods.getValues(configFieldDefaultId(field.key))}
            getMappedColumnValues={getMappedColumnValues}
            fieldSelectOptionsFn={fieldSelectOptionsFn}
          />
        );
      })}

      <Grid item xs={12} classes={{ root: classes.gridMarginTop }}>
        <FormTitleField text={"Preview"} />
      </Grid>

      <CsvConfigPreview getMappedFieldFn={(field: string) => getMappedField(field)} />
    </Grid>
  );
};
