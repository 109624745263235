import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { SubmitHandler } from "react-hook-form";
import { updateOrganizationalUnit } from "../../../clients/organizationalunit.client";
import { useApiCall } from "../../../hooks/client.hooks";
import { Button, DialogActions, Grid } from "@material-ui/core";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import { OrganizationalUnitResponseDTO, UpdateOrganizationalUnitRequestDTO } from "../../../open-api";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { ControlledTextField } from "../../../components/form/FormComponents";
import { mapOrganizationToText } from "../../../mappers/organization.mapper";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

interface CustomerDialogEditProps {
  organizationalUnit: OrganizationalUnitResponseDTO;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  display: "GENERIC";
}

export interface CustomerDialogFormValues {
  name: string;
  reference?: string;
}

export function OrganizationalUnitFormEdit({
  organizationalUnit,
  onClose,
  onSuccess,
  onError,
  display,
}: CustomerDialogEditProps) {
  const classes = useStyles();

  const [update] = useApiCall(updateOrganizationalUnit);

  const { control, handleSubmit } = useCFForm({
    schema: z.object({
      name: z.string().max(255),
      reference: z.string().max(255).optional(),
    }),
    defaultValues: {
      name: organizationalUnit.name || "",
      reference: organizationalUnit.reference || "",
    },
  });

  const handleClose = () => {
    onClose();
  };

  const handleSubmitCustomer: SubmitHandler<CustomerDialogFormValues> = ({ name, reference }) => {
    const customerValues: UpdateOrganizationalUnitRequestDTO = {
      name,
      reference,
    };

    return update(organizationalUnit.id, customerValues)
      .then((_) => {
        messageSuccess("Customer data saved");
        onSuccess && onSuccess();
      })
      .catch((e) => {
        messageError(e.message);
        onError && onError();
      });
  };
  const name = mapOrganizationToText(organizationalUnit);

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitCustomer)}>
        <DialogTitleCloseable onClose={handleClose}>
          Edit: {organizationalUnit && organizationalUnit.name}
        </DialogTitleCloseable>
        <DialogContent className={classes.content}>
          {display === "GENERIC" && (
            <Grid container spacing={2}>
              <FormTitleField text={`${name} details`} />
              <Grid item xs={12}>
                <ControlledTextField control={control} name={"name"} label={`${name} name`} />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField control={control} name={"reference"} label={`${name} reference`} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonConfirm>Save</ButtonConfirm>
        </DialogActions>
      </form>
    </>
  );
}
