import { Checkbox, ListItem, ListItemText } from "@material-ui/core";
import { css } from "@emotion/css";
import { SetAttributeStatus } from "./AttributeList";
import { AttributeFilterButtons } from "./AttributeFilterButtons";
import { AttributeData } from "./AttributeFilterSection";

interface AttributeItemProps {
  attribute: AttributeData;
  setStatus?: SetAttributeStatus;
  canEdit: boolean;
  canDelete?: boolean;
  setIsSelected: (id: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  withCheckbox: boolean;
}

export const AttributeItem = ({
  canDelete = false,
  attribute,
  setStatus,
  canEdit,
  setIsSelected,
  withCheckbox,
}: AttributeItemProps) => {
  return (
    <ListItem dense divider>
      {canEdit && withCheckbox && (
        <Checkbox
          color="primary"
          defaultChecked={attribute.isSelected}
          onChange={(event) => setIsSelected(attribute.id, event)}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        />
      )}
      <ListItemText
        className={css({
          overflow: "ellipsis",
        })}
      >{`${attribute.displayParam}`}</ListItemText>
      {canEdit && (
        <AttributeFilterButtons canDelete={canDelete} type={attribute.state} setStatus={setStatus} id={attribute.id} />
      )}
    </ListItem>
  );
};
