import { ListItem, Paper } from "@material-ui/core";
import { ReactNode } from "react";

interface PaperListItemProps {
  paperClassName?: string;
  listItemClassName?: string;
  children: ReactNode;
}

export const PaperListItem = ({ paperClassName, listItemClassName, children }: PaperListItemProps) => {
  return (
    <Paper className={paperClassName} elevation={2} variant={"elevation"}>
      <ListItem className={listItemClassName}>{children}</ListItem>
    </Paper>
  );
};
