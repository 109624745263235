import { ReactNode } from "react";
import { useAppStateContext } from "../hooks/state.hooks";
import { groupBy } from "../utils/functional/reduce.utils";
import { distinctBy } from "../utils/functional/array.utils";

export interface BillingPeriodFilter {
  id: string;
  collectorName: string;
  collectorId: string;
  datasourceName: string;
  periods: { id: string; from: string; to: string; cost: number }[];
  anchorDate: string;
  anchorDateDisplay: string;
  anchorDateDisplayElement: ReactNode;
}

export const useInvoicePeriods = (): BillingPeriodFilter[] => {
  const invoices = useAppStateContext().invoices;
  if (invoices) {
    // Get invoices
    // -> group by collector
    // -> group by anchor dates
    // -> sort on time, filter distinct periods, return display values
    const groupedByCollector = groupBy(invoices, (it) => it.datasource.id);

    return Object.keys(groupedByCollector).flatMap((collectorId) => {
      const collectorName = groupedByCollector[collectorId][0].datasource.name;
      const datasourceName = groupedByCollector[collectorId][0].origin;
      const invoicesForCollector = groupedByCollector[collectorId];

      const groupedByAnchorDate = groupBy(invoicesForCollector, (it) => it.billingPeriod.anchorDate);

      return Object.keys(groupedByAnchorDate).map((anchorDate) => {
        const invoicesForAnchorDate = groupedByAnchorDate[anchorDate]
          .slice() // immutable for kasper ;)
          .sort(
            (a, b) =>
              a.billingPeriod.from.localeCompare(b.billingPeriod.from) ||
              a.billingPeriod.to.localeCompare(b.billingPeriod.to)
          );
        const anchorDateDisplay = invoicesForAnchorDate[0].billingPeriod.anchorDateDisplay;

        return {
          id: `${collectorId}|${anchorDateDisplay}`,
          collectorId: collectorId,
          collectorName: collectorName,
          datasourceName: datasourceName,
          periods: distinctBy(
            invoicesForAnchorDate.map((it) => ({
              from: it.billingPeriod.from,
              to: it.billingPeriod.to,
              id: `${it.billingPeriod.from}|${it.billingPeriod.to}`,
              cost: it.cost, // TODO: Maybe remove? distinctBy does not sum this if there are multiple billing periods
            })),
            (a, b) => a.id === b.id
          ),
          anchorDateDisplay: anchorDateDisplay,

          anchorDateDisplayElement: (
            <>
              {collectorName} ({anchorDateDisplay.substring(0, 2)} &mdash; {anchorDateDisplay.substring(6)})
            </>
          ),
          anchorDate: anchorDate,
        };
      });
    });
  }

  return [];
};
