import { Dispatch, SetStateAction, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { css } from "@emotion/css";
import { SearchRounded } from "@material-ui/icons";
import { ListboxComponent } from "../../components/form/VirtualizedAutocomplete";
import { useDataClientPromise } from "../../hooks/client.hooks";
import { DIMENSIONS } from "../../clients/lineitems.client";
import { useServices } from "../../services/context.service";
import { useBillingPeriodArgument } from "../../clients/query.client";

export type OrganizationSelectFilterProps = {
  billingPeriod: string;
  setFilter: Dispatch<SetStateAction<FilterOption | null>>;
  selectedFilter: FilterOption | null;
};

export type FilterOption = {
  key: string;
  label: string;
  type?: string;
};

export const OrganizationSelectFilter = ({
  billingPeriod,
  setFilter,
  selectedFilter,
}: OrganizationSelectFilterProps) => {
  const [value, setValue] = useState<FilterOption | null>(selectedFilter);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const queryService = useServices().aggregate;

  const billingPeriodArgument = useBillingPeriodArgument();

  const [filterOptions] = useDataClientPromise(
    queryService.options,
    [
      DIMENSIONS.ORGANIZATION,
      [
        {
          dimension: billingPeriodArgument,
          filters: [billingPeriod],
        },
      ],
    ],
    {
      useCacheKey: "options",
      shouldLoad: open,
      onSuccess: (res) => {
        // should not be able to filter by Unassigned
        let options = res.options.filter((it) => it.id !== "<null>");
        return options
          .map((it) => ({ key: it.id, label: it.display || it.id, type: it.type || "" }))
          .sort((a, b) => a.type.localeCompare(b.type) || a.label.localeCompare(b.label));
      },
    }
  );

  // TODO: MOve to FormAutocompleteFilter

  return (
    <div>
      <Autocomplete
        value={value}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event: any, newValue: FilterOption | null) => {
          setValue(newValue);
          setFilter(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        ListboxComponent={ListboxComponent() as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        id="organization-select-filter"
        options={filterOptions ?? []}
        groupBy={(option) => option.type || ""}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={"Filter"}
              placeholder={"Search"}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                ...{ flex: 1 },
                value: inputValue,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: <SearchRounded className={css({ margin: "0 4px" })}></SearchRounded>,
              }}
              style={{ width: 250 }}
            />
          );
        }}
        renderOption={(props, option) => [props, option] as React.ReactNode}
        // TODO: Post React 18 update - validate this conversion, look like a hidden bug
        renderGroup={(params) => params as unknown as React.ReactNode}
      />
    </div>
  );
};
