import Fab from "@material-ui/core/Fab";
import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { OrganizationalUnitDialogCreate } from "./dialogs/OrganizationalUnitDialogCreate";
import { OrganizationalUnitResponseDTO } from "../../open-api";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface AddOrganizationalUnitProps {
  onSuccess: (res: OrganizationalUnitResponseDTO) => void;
}

export function AddOrganizationalUnit({ onSuccess }: AddOrganizationalUnitProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab aria-label={"Add customer"} className={classes.fab} color={"primary"} onClick={() => setOpen(true)}>
        <Tooltip title={"Add organizational unit"} placement="left">
          <AddIcon />
        </Tooltip>
      </Fab>
      {open && (
        <OrganizationalUnitDialogCreate
          toggle={() => {
            setOpen(!open);
          }}
          onSuccess={onSuccess}
          open={open}
        />
      )}
    </>
  );
}
