import { useContext } from "react";
import { AppContext } from "../providers/auth.provider";

export enum WorkspaceRole {
  ADMIN = "ADMIN",
  READ = "READ",
  MANAGED_C_VIEW = "MANAGED_C_VIEW",
}

export enum SubscriptionType {
  ACTIVE = "ACTIVE",
  FREE = "FREE",
}

export const useAppContext = () => useContext(AppContext);

export const useIsAuthenticated = (): boolean => useAppContext()?.authenticated ?? false;

export const useSubscriptionType = (): SubscriptionType =>
  (useAppContext()?.workspace?.subscriptionType as SubscriptionType) || SubscriptionType.FREE;

export const useEnabledFeatures = (): string[] => useAppContext()?.workspace?.enabledFeatures?.split(",") ?? [];

export const useWorkspaceRole = (): WorkspaceRole => useAppContext()?.user?.role as WorkspaceRole;

export const useAuthenticatedId = (): string | null => useAppContext()?.user?.id ?? null;

export const useCanEdit = (): boolean => useWorkspaceRole() === WorkspaceRole.ADMIN;

export const useCanEditAdminAndCView = (): boolean => {
  const workspaceRole = useWorkspaceRole();
  return workspaceRole === WorkspaceRole.ADMIN || workspaceRole === WorkspaceRole.MANAGED_C_VIEW;
};

export const useAuthParams = (): AuthParams => {
  const subscriptionType = useSubscriptionType();
  const role = useWorkspaceRole();
  const isAuthenticated = useIsAuthenticated();
  const id = useAuthenticatedId();
  const mfaEnabled = useAppContext()?.user?.mfaEnabled ?? false;
  return {
    subscriptionType,
    role,
    isAuthenticated,
    id,
    mfaEnabled,
  };
};

export const useIsManagedCView = (): boolean => {
  return useAuthParams().role === WorkspaceRole.MANAGED_C_VIEW;
};

export interface AuthParams {
  isAuthenticated: boolean;
  mfaEnabled: boolean;
  subscriptionType: SubscriptionType;
  role: WorkspaceRole;
  id: string | null;
}
