import { Button, ButtonGroup, FormGroup, lighten, Switch, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { css } from "@emotion/css";
import { CFCard } from "../../../components/layout/CFContainer";
import { OrganizationalUnitType } from "../../../clients/organizationalunit.client";
import MaterialTable from "@material-table/core";
import { tableIcons } from "../../../theme/TableIcons";
import { InformationLink } from "../../../components/Information";
import { grey } from "@material-ui/core/colors";
import { Prompt } from "react-router-dom";
import { FormTitleField } from "../../../components/form/FormTitleField";
import Grid from "@material-ui/core/Grid";
import { BatchProcessingRow } from "./AutomaticCustomerCreation.interface";
import { useBatchCustomerData, useColumnsForBatchCustomerCreation } from "./AutomaticCustomerCreation.hooks";
import { MergeCustomerVerification } from "./components/MergeCustomerVerification";
import { CreateCustomerVerification } from "./components/CreateCustomerVerification";
import { mapOrgTypeToDisplay } from "./AutomaticCustomerCreation.utils";
import { EditIconButton } from "../../../components/buttons/EditIconButton";
import { TooltipIconButton } from "../../../components/buttons/TooltipIconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import { useContextOrThrow } from "../../../hooks/context.hooks";
import {
  BatchCreationTypeContext,
  useCancelBatchCreationConfig,
  useSaveBatchCreationConfig,
} from "./AutomaticCustomerCreation.provider";

export interface AutomaticCustomerCreationProps {
  onSuccess?: () => void;
  onManualCreate?: () => void;
}

/**
 * Sorts rows alphabetic, and then, if merging is active, it puts all the
 * 'selected-for-join' rows directly below the 'selected-for-merge' row.
 * @param rows
 */
const prepareRows = (rows: BatchProcessingRow[]) => {
  const selectedForJoin = rows.filter((it) => it.state === "selected-for-join");
  const other = rows.filter((it) => it.state !== "selected-for-join");
  const idx = other.findIndex((it) => it.state === "selected-for-merge");
  return idx !== -1 ? [...other.slice(0, idx + 1), ...selectedForJoin, ...other.slice(idx + 1)] : other;
};

export function AutomaticCustomerCreation({ onSuccess, onManualCreate }: AutomaticCustomerCreationProps) {
  const theme = useTheme();
  const [type, setType] = useContextOrThrow(BatchCreationTypeContext);
  const saveConfig = useSaveBatchCreationConfig();
  const cancelConfig = useCancelBatchCreationConfig();

  const [editConfig, setEditConfig] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [rows, setRows, collectors, loading, refreshBatchInfo] = useBatchCustomerData(type, includeAll);

  const columns = useColumnsForBatchCustomerCreation(type, collectors, setRows, editConfig);

  const typeLabel = mapOrgTypeToDisplay(type);
  const selected = rows.filter((it) => it.tableData?.checked);
  const selectedForMerging = rows.filter((it) => it.state === "selected-for-merge" || it.state === "selected-for-join");

  // useMemo is used so that this function is not recreated after every render.
  const rowStyle = useMemo(
    () => (rowData: BatchProcessingRow) => ({
      verticalAlign: "middle",
      backgroundColor:
        rowData.state === "selected-for-merge"
          ? theme.palette.secondary.main
          : rowData.state === "selected-for-join"
          ? lighten(theme.palette.secondary.main, 0.4)
          : rowData.tableData?.checked
          ? lighten(theme.palette.background.paper, 0.2)
          : undefined,
    }),
    [theme]
  );

  useEffect(() => {
    // Reset selection and merging after changing
    if (type) {
      setRows((rows) =>
        rows.map((it) => ({
          ...it,
          tableData: { ...it.tableData, checked: false },
          state: "default",
        }))
      );
    }
  }, [type, setRows]);

  // Enable/disable confirmation prompt on navigating away using the browser.
  useEffect(() => {
    if (selected.length > 0 || selectedForMerging.length > 0 || editConfig) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [selected, selectedForMerging, editConfig]);

  return (
    <div>
      <Prompt
        when={selected.length > 0 || selectedForMerging.length > 0}
        message={`You have marked ${
          selectedForMerging.length || selected.length
        } ${typeLabel}s for creation/merging. If you leave now this selection will be lost`}
      />
      <Prompt
        when={editConfig}
        message={`You are currently editing the configuration for associating data with ${typeLabel}s. If you leave now your changes will not be saved.`}
      />
      <CFCard
        header={
          <>
            Convert from cloud{" "}
            <InformationLink url={"https://c-facts.atlassian.net/wiki/spaces/KB/pages/771686469/Organizations"} />
          </>
        }
      >
        <FormTitleField text={"What to create"} />
        <FormGroup row>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="type"
              name="type"
              value={type}
              onChange={(event) => {
                if (selected.length > 0 || selectedForMerging.length > 0) {
                  if (
                    window.confirm(
                      `You have marked ${
                        selectedForMerging.length || selected.length
                      } ${typeLabel}s for creation/merging. If you leave now this selection will be lost`
                    )
                  ) {
                    setType(event.target.value as OrganizationalUnitType);
                  }
                } else if (editConfig) {
                  if (
                    window.confirm(
                      `You are currently editing the configuration for associating data with ${typeLabel}s. If you leave now your changes will not be saved.`
                    )
                  ) {
                    setType(event.target.value as OrganizationalUnitType);
                  }
                } else {
                  setType(event.target.value as OrganizationalUnitType);
                }
              }}
            >
              <FormControlLabel value="CUSTOMER" control={<Radio color={"primary"} />} label="Customer" />
              <FormControlLabel value="RESELLER" control={<Radio color={"primary"} />} label="Reseller" />
              <FormControlLabel value="INTERNAL" control={<Radio color={"primary"} />} label="Internal organizations" />
            </RadioGroup>
          </FormControl>
          <Button variant={"outlined"} color="primary" size={"small"} onClick={onManualCreate}>
            Create manual
          </Button>
        </FormGroup>
        <MaterialTable
          style={{
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
          }}
          icons={tableIcons}
          title={
            <Typography variant={"h6"}>
              Create {typeLabel}s{" "}
              {editConfig ? (
                <>
                  <TooltipIconButton
                    onClick={() => {
                      setEditConfig(false);
                      cancelConfig();
                    }}
                    ariaLabel="cancel"
                    tooltip="Cancel editing"
                    iconButton={<CancelIcon />}
                  />
                  <TooltipIconButton
                    onClick={() => {
                      saveConfig().then(() => setEditConfig(false));
                    }}
                    ariaLabel="save"
                    tooltip="Save changes"
                    iconButton={<SaveIcon />}
                  />
                </>
              ) : (
                <EditIconButton
                  onClick={() => setEditConfig(true)}
                  tooltip={"Edit datasource configuration for creation of customers"}
                />
              )}
            </Typography>
          }
          columns={columns}
          isLoading={loading}
          data={prepareRows(rows)}
          options={{
            idSynonym: "key",
            draggable: false,
            selection: true,
            actionsColumnIndex: -1,
            sorting: false,
            padding: "dense",
            overflowY: "scroll",
            maxBodyHeight: 500,
            headerStyle: {
              zIndex: 10,
              backgroundColor: grey["700"],
              position: "sticky",
              top: 0,
              width: "20%",
              paddingTop: "8px",
              paddingBottom: "8px",
            },
            rowStyle: rowStyle,
            emptyRowsWhenPaging: false,
            pageSize: 50,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          cellEditable={{
            isCellEditable: (rowData, columnDef) => {
              return typeof columnDef.editable === "function" ? columnDef.editable(columnDef, rowData) : false;
            },

            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              if (newValue.length === 0) {
                return Promise.reject();
              }
              setRows((prev) =>
                prev.map((it) => {
                  if (it.key === rowData.key) {
                    it.organization.name = newValue;
                  }
                  return it;
                })
              );
              return Promise.resolve();
            },
          }}
          onSelectionChange={(rows, row) => {
            if (!row) {
              if (rows.length === 0) {
                setRows((rows) =>
                  rows.map((it) => ({
                    ...it,
                    tableData: {
                      ...it.tableData,
                      checked: false,
                    },
                  }))
                );
              } else {
                setRows(rows);
              }
              setRows((rows) => rows);
            } else {
              setRows((rows) => rows.map((it) => (it.key === row.key ? row : it)));
            }
          }}
        />
        <Grid container direction={"row"} className={css({ paddingTop: theme.spacing(2) })}>
          <Grid item>
            <FormGroup row>
              <ButtonGroup>
                <Button
                  variant={"outlined"}
                  onClick={() =>
                    setRows((rows) =>
                      rows.map((it) => ({
                        ...it,
                        tableData: { ...it.tableData, checked: it.organization.type === "existing" },
                      }))
                    )
                  }
                >
                  Select existing
                </Button>
                <Button
                  variant={"outlined"}
                  onClick={() =>
                    setRows((rows) =>
                      rows.map((it) => ({
                        ...it,
                        tableData: { ...it.tableData, checked: it.organization.type === "new" },
                      }))
                    )
                  }
                >
                  Select new
                </Button>
              </ButtonGroup>
              <FormControlLabel
                labelPlacement={"start"}
                label={"Include all organizations"}
                control={
                  <Switch
                    checked={includeAll}
                    onChange={(e) => {
                      if (selected.length > 0 || selectedForMerging.length > 0) {
                        if (
                          window.confirm(
                            `You have marked ${
                              selectedForMerging.length || selected.length
                            } ${typeLabel}s for creation/merging. If you change this now your selection will be lost`
                          )
                        ) {
                          setIncludeAll(e.target.checked);
                        }
                      } else {
                        setIncludeAll(e.target.checked);
                      }
                    }}
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </CFCard>
      <div className={css({ height: "50px" })} />
      <MergeCustomerVerification
        selectedForMerging={selectedForMerging}
        typeLabel={typeLabel}
        setRows={setRows}
        collectors={collectors}
      />
      {/*Only show create when there is no merging active*/}
      {selectedForMerging.length === 0 && (
        <CreateCustomerVerification
          selected={selected}
          type={type}
          setRows={setRows}
          collectors={collectors}
          onSuccess={() => {
            refreshBatchInfo();
            if (onSuccess) {
              onSuccess();
            }
          }}
        />
      )}
    </div>
  );
}
