import { createContext, FC, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BootstrapRequestDTO } from "../open-api";

import { LoadProgress } from "../components/LoadProgress";
import { useBootstrap } from "../clients/bootstrap.client";

interface AppContextInterface extends BootstrapRequestDTO {
  refresh: () => void;
}

export const AppContext = createContext<AppContextInterface>(null!);

export const AuthProvider: FC = ({ children }) => {
  const [bootstrap, refresh] = useBootstrap();

  useEffect(() => {
    if (bootstrap?.user) {
      Sentry.setUser({
        email: bootstrap.user.email,
        username: bootstrap.user.name,
      });
      Sentry.setTag("workspace.name", bootstrap.workspace?.name);
      Sentry.setTag("workspace.id", bootstrap.workspace?.id);
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
      Sentry.setTag("workspace.name", null);
      Sentry.setTag("workspace.id", null);
    }
  }, [bootstrap]);

  if (bootstrap) {
    return <AppContext.Provider value={{ ...bootstrap, refresh }}>{children}</AppContext.Provider>;
  }
  return <LoadProgress />;
};
