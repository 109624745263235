import { FastFact } from "../FastFact";
import { Money } from "../../../../components/Money";
import { CFTotal } from "../../../newdash/model/Query";

interface FastFactTotalProps {
  items: CFTotal[]
}

export const FastFactTotal = ({ items }: FastFactTotalProps) => {

  const lines = items.length > 0 ? items.map((item) => ({
    key: item.id,
    description: item.display,
    value: <Money money={item.value} digits={0} />,
  })) : [{
    key: "empty",
    description: "No line items for this billing period",
    value: ""
  }]
  return (
    <>
      <FastFact
        title={"Total cloud cost"}
        lines={lines}
      />
    </>
  );
};
