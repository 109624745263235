import { failFormValidation } from "../../services/message.service";
import { GridSize, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldError } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { FormSelectSingle } from "./FormSelectSingle";
import { getCurrencySymbol } from "../../utils/localization.utils";
import { CURRENCIES } from "../../utils/functional/money.utils";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

type FormCurrencySelectProps = {
  error?: FieldError;
  label?: string;
  silent?: boolean;
  name: string;
  variant?: "standard" | "outlined" | "filled";
  initValue?: string;
  id?: string;
  xs?: GridSize;
  md?: GridSize;
  onChange: (event: any) => void;
};

export function FormCurrencySelect({
  xs = 12,
  md = 12,
  label,
  error,
  initValue,
  silent = false,
  variant = "outlined",
  name,
  id = name,
  onChange,
}: FormCurrencySelectProps) {
  const classes = useStyles();

  if (error && !silent && label) {
    failFormValidation(label as string, error.message as string);
  }

  return (
    <Grid item xs={xs} md={md}>
      <FormSelectSingle
        error={!!error}
        classesSelect={!!error ? { root: classes.error } : {}}
        fullWidth={true}
        emptyOption={true}
        optionValue={(e) => getCurrencySymbol(e.id)}
        value={initValue}
        label={label}
        handleChange={onChange}
        options={CURRENCIES}
        optionId={"id"}
        variant={variant}
        id={id}
      />
      {!!error ? <Typography className={classes.error}>{error.message}</Typography> : ""}
    </Grid>
  );
}
