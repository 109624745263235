import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";

interface FormSkeletonProps {
  rowCount?: number;
}
export const FormSkeleton: FC<FormSkeletonProps> = ({ rowCount = 2 }) => {
  return (
    <Grid container spacing={1}>
      {[...Array(rowCount)].map((item, key) => (
        <Grid container item key={key}>
          <Grid item xs={4}>
            <Skeleton variant="rect" height={40} />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12}>
            <Skeleton variant="text" height={60} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
