/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CreateCustomerPortalDTO,
    PortalResponseDTO,
} from '../models';

export interface CanAddDataSourceRequest {
    workspaceId: string;
}

export interface CreatePortalRequest {
    customerIds: string;
    createCustomerPortalDTO: CreateCustomerPortalDTO;
}

export interface DeletePortalRequest {
    portalId: string;
}

export interface SetCanAddDataSourceRequest {
    portalId: string;
    canAddDataSource: boolean;
}

/**
 * 
 */
export class CustomerPortalControllerApi extends runtime.BaseAPI {

    /**
     */
    async canAddDataSourceRaw(requestParameters: CanAddDataSourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling canAddDataSource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/workspace/{workspaceId}/data-source-functionality`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async canAddDataSource(requestParameters: CanAddDataSourceRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.canAddDataSourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPortalRaw(requestParameters: CreatePortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerIds === null || requestParameters.customerIds === undefined) {
            throw new runtime.RequiredError('customerIds','Required parameter requestParameters.customerIds was null or undefined when calling createPortal.');
        }

        if (requestParameters.createCustomerPortalDTO === null || requestParameters.createCustomerPortalDTO === undefined) {
            throw new runtime.RequiredError('createCustomerPortalDTO','Required parameter requestParameters.createCustomerPortalDTO was null or undefined when calling createPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{customerIds}/portal`.replace(`{${"customerIds"}}`, encodeURIComponent(String(requestParameters.customerIds))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createCustomerPortalDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPortal(requestParameters: CreatePortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createPortalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePortalRaw(requestParameters: DeletePortalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portalId === null || requestParameters.portalId === undefined) {
            throw new runtime.RequiredError('portalId','Required parameter requestParameters.portalId was null or undefined when calling deletePortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{customerId}/portal/{portalId}`.replace(`{${"portalId"}}`, encodeURIComponent(String(requestParameters.portalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePortal(requestParameters: DeletePortalRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePortalRaw(requestParameters, initOverrides);
    }

    /**
     */
    async listPortalsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PortalResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async listPortals(initOverrides?: RequestInit): Promise<Array<PortalResponseDTO>> {
        const response = await this.listPortalsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setCanAddDataSourceRaw(requestParameters: SetCanAddDataSourceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.portalId === null || requestParameters.portalId === undefined) {
            throw new runtime.RequiredError('portalId','Required parameter requestParameters.portalId was null or undefined when calling setCanAddDataSource.');
        }

        if (requestParameters.canAddDataSource === null || requestParameters.canAddDataSource === undefined) {
            throw new runtime.RequiredError('canAddDataSource','Required parameter requestParameters.canAddDataSource was null or undefined when calling setCanAddDataSource.');
        }

        const queryParameters: any = {};

        if (requestParameters.canAddDataSource !== undefined) {
            queryParameters['canAddDataSource'] = requestParameters.canAddDataSource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/portal/{portalId}/data-source-functionality`.replace(`{${"portalId"}}`, encodeURIComponent(String(requestParameters.portalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setCanAddDataSource(requestParameters: SetCanAddDataSourceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.setCanAddDataSourceRaw(requestParameters, initOverrides);
    }

}
