import Select, { SelectClassKey, SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress, FormControl, Grid, GridSize, ListSubheader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/styles/withStyles/withStyles";
import { FormControlClassKey } from "@material-ui/core/FormControl/FormControl";
import { Arr } from "../../utils/functional/array.utils";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loading: {
    color: theme.palette.text.disabled,
  },
  alignEnd: {
    justifyContent: "flex-end",
  },
}));

export interface FormSelectSingleProps<T extends any> extends SelectProps {
  xs?: GridSize;
  md?: GridSize;
  noGrid?: boolean;
  options: T[] | null;
  optionId?: keyof T;
  group?: {
    id: (t: T) => string | undefined;
    label: (t: T) => string | undefined;
  };
  optionValue?: ((e: T) => string | ReactNode) | keyof T;
  handleChange: (e: any) => void;
  emptyOption?: boolean;
  emptyLabel?: string;
  emptyValue?: any;
  isLoading?: boolean;
  fullWidth?: boolean;
  variant?: "standard" | "outlined" | "filled";
  classesSelect?: Partial<ClassNameMap<SelectClassKey>>;
  classesFormControl?: Partial<ClassNameMap<FormControlClassKey>>;
  alignEnd?: boolean;
}

export function FormSelectSingle<T extends Record<string, any>>({
  xs = 12,
  md = 12,
  noGrid = false,
  options,
  handleChange,
  value,
  id,
  optionId = "key",
  optionValue = (e) => e.label,
  group,
  label,
  emptyOption = false,
  emptyLabel = "None",
  emptyValue = "",
  isLoading = false,
  fullWidth = true,
  variant = "outlined",
  classesSelect,
  classesFormControl,
  error = false,
  multiple = false,
  disabled = false,
  endAdornment,
  alignEnd = false,
  ...rest
}: FormSelectSingleProps<T>) {
  const classes = useStyles();
  const groups = Arr.distinctJson(
    group != null
      ? options?.map((it) => ({ id: group.id(it), label: group.label(it) })).filter((it) => it.id != null) ?? []
      : []
  );

  const selectMenuItem = (item: T) => (
    <MenuItem
      value={item[optionId]}
      key={`${id}-${item[optionId]}`}
      className={`${isLoading ? classes.loading : ""} ${alignEnd ? classes.alignEnd : ""}`}
    >
      {typeof optionValue === "function" ? optionValue(item) : item[optionValue]}
    </MenuItem>
  );

  const Component = () => (
    <FormControl error={error} fullWidth={fullWidth} variant={variant} classes={classesFormControl}>
      {isLoading && <CircularProgress className={classes.progress} size={24} />}
      {label ? <InputLabel id={`${id}-label`}>{label}</InputLabel> : ""}
      <Select
        className={isLoading ? classes.loading : undefined}
        disableUnderline
        endAdornment={endAdornment}
        error={error}
        labelId={`${id}-label`}
        label={label}
        id={id}
        value={value}
        onChange={handleChange}
        classes={classesSelect}
        multiple={multiple}
        disabled={disabled}
        {...rest}
      >
        {isLoading && <CircularProgress className={classes.progress} size={24} />}
        {emptyOption && (
          <MenuItem value={emptyValue}>
            <em>{emptyLabel}</em>
          </MenuItem>
        )}
        {groups.length > 0
          ? groups.flatMap((g) => [
              <ListSubheader key={g.id}>{g.label}</ListSubheader>,
              ...(options?.filter((item) => group?.id?.(item) === g.id).map(selectMenuItem) ?? []),
            ])
          : options?.map(selectMenuItem)}
      </Select>
    </FormControl>
  );
  return noGrid ? (
    <Component />
  ) : (
    <Grid item xs={xs} md={md}>
      <Component />
    </Grid>
  );
}
