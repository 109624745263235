import { CircularProgress, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { DataSourceResponseDTO } from "../../../clients/collector.client";
import { useRefresh } from "../../../hooks/refresh.hooks";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DataSourceIcon, Icon } from "../../../theme/Icon";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 120,
    height: 60,
  },
}));

interface StepWaitingForProcessorProps {
  datasource: DataSourceResponseDTO | null;
  refresh: () => void;
}

export function StepWaitingForProcessor({ datasource, refresh }: StepWaitingForProcessorProps) {
  const classes = useStyles();
  const shouldNotRefresh = datasource?.status === "FINISHED" || datasource?.status === "FAILED";
  const [refreshTimer] = useRefresh(!shouldNotRefresh, refresh);

  if (!datasource) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>No Data Source found, go back to step 1 and add a datasource</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>Waiting for processor to complete (Refresh in {refreshTimer} seconds)...</Typography>
      </Grid>
      <Grid container item xs={12} justify={"center"}>
        <Icon icon={datasource.type as DataSourceIcon} className={classes.logo} />
      </Grid>
      <Grid container item xs={12} justify={"center"}>
        {shouldNotRefresh ? (
          <CheckCircleIcon style={{ fontSize: 120 }} />
        ) : (
          <CircularProgress style={{ fontSize: 120 }} />
        )}
      </Grid>
      <Grid container item xs={12} justify={"center"}>
        Current status is: {datasource.status}
      </Grid>
    </Grid>
  );
}
