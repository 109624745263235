import { Grid, Typography } from "@material-ui/core";
import { useIsAuthenticated } from "../../hooks/auth.hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { csvRequest } from "../../utils/api.utils";
import { messageError } from "../../services/message.service";

interface DownloadPageProps {
  id: string;
}

export function DownloadPage({ id }: DownloadPageProps) {
  const authenticated = useIsAuthenticated();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filename = params.get("filename");
  useEffect(() => {
    if (authenticated) {
      csvRequest(`/api/downloads/${id}?filename=${filename}`).catch((err) => {
        messageError("File not found, make sure you are authenticated with the correct account");
      });
    }
  }, [id, filename, authenticated]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Preparing your download...</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>It should start automatically when it is ready</Typography>
      </Grid>
    </Grid>
  );
}
