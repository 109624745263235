import {
  CollectorPriceAdjustmentResponseDTO,
  CreateCollectorAdjustmentRequest,
  DeleteCollectorAdjustmentRequest,
  GetCollectorAdjustmentsRequest,
  UpdateCollectorAdjustmentRequest,
} from "../open-api";
import { API } from "./context.service";

export interface CollectorPriceAdjustmentsService {
  getAll(request: GetCollectorAdjustmentsRequest): Promise<CollectorPriceAdjustmentResponseDTO[]>;
  create(request: CreateCollectorAdjustmentRequest): Promise<void>;
  update(request: UpdateCollectorAdjustmentRequest): Promise<void>;
  delete(request: DeleteCollectorAdjustmentRequest): Promise<void>;
}

export class LiveCollectorPriceAdjustmentsService implements CollectorPriceAdjustmentsService {
  constructor(private api: API) {}

  getAll = (request: GetCollectorAdjustmentsRequest): Promise<CollectorPriceAdjustmentResponseDTO[]> =>
    this.api.collectorPriceAdjustments.getCollectorAdjustments(request);

  create = async (request: CreateCollectorAdjustmentRequest): Promise<void> => {
    await this.api.collectorPriceAdjustments.createCollectorAdjustment(request);
  };

  update = (request: UpdateCollectorAdjustmentRequest): Promise<void> =>
    this.api.collectorPriceAdjustments.updateCollectorAdjustment(request);

  delete = (request: DeleteCollectorAdjustmentRequest): Promise<void> =>
    this.api.collectorPriceAdjustments.deleteCollectorAdjustment(request);
}
