import { API } from "./context.service";
import { ProcessEventQueueDTO } from "../open-api";

export interface ProcessQueueService {
  list(): Promise<ProcessEventQueueDTO[]>;
}

export class LiveProcessQueueService implements ProcessQueueService {
  constructor(private api: API) {}

  list = () => this.api.processQueue.listEvents();
}
