import makeStyles from "@material-ui/core/styles/makeStyles";
import EventIcon from "@material-ui/icons/Event";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  failed: {
    backgroundColor: theme.palette.error.dark,
  },
  notice: {
    backgroundColor: theme.palette.info.dark,
  },
  done: {
    backgroundColor: theme.palette.success.dark,
  },
}));

interface PipelineDetailStatusProps {
  status: "ERROR" | "IN_PROGRESS" | "FINISHED" | undefined;
}

export function PipelineDetailStatus({ status }: PipelineDetailStatusProps) {
  const classes = useStyles();
  switch (status) {
    case "FINISHED":
      return (
        <Avatar className={classes.done}>
          <EventAvailableIcon />
        </Avatar>
      );
    case "ERROR":
      return (
        <Avatar className={classes.failed}>
          <EventBusyIcon />
        </Avatar>
      );
    case "IN_PROGRESS":
      return (
        <Avatar className={classes.notice}>
          <EventIcon />
        </Avatar>
      );
    default:
      return (
        <Avatar>
          <HelpOutlineIcon />
        </Avatar>
      );
  }
}
