import { useState } from "react";
import { InvoiceFilter } from "./InvoiceFilter";
import Typography from "@material-ui/core/Typography";
import { Invoice } from "./Invoice";
import { useInvoices } from "../../clients/invoices.client";
import { associateBy } from "../../utils/functional/reduce.utils";
import { CFCard, CFContainer } from "../../components/layout/CFContainer";
import { useSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";
import { InvoiceResponseDTO } from "../../open-api";

export const getInvoicesPageTitle = () => {
  return "Cloud Purchasing";
};

export function InvoicesPage() {
  const [invoices, , loading] = useInvoices();
  const [selected, setSelected] = useState(null as InvoiceResponseDTO | null);

  const selectedPeriods = useSelectedBillingPeriods();

  const selectedMap = selectedPeriods ? associateBy(selectedPeriods.periods, (it) => `${it.from}|${it.to}`) : {};

  const filteredInvoices =
    selectedPeriods && invoices
      ? invoices.filter(
          (it) =>
            it.datasource.id === selectedPeriods.collectorId &&
            selectedMap[`${it.billingPeriod.from}|${it.billingPeriod.to}`]
        )
      : invoices;

  // Handle when changing to invoice period that doesn't exist
  if (selected && selectedPeriods && filteredInvoices?.findIndex((it) => it.id === selected.id) === -1) {
    setSelected(null);
  }

  return (
    <CFContainer>
      <CFCard>
        <InvoiceFilter
          selected={selected}
          setSelected={(invoice) => setSelected(invoice)}
          loading={loading}
          allInvoices={filteredInvoices ?? []}
        />
      </CFCard>
      <CFCard>
        {selected ? <Invoice invoice={selected} /> : <Typography>Select an invoice in the dropdown field</Typography>}
      </CFCard>
    </CFContainer>
  );
}
