import TreeViewStyled from "./TreeViewStyled";
import { ChangeEvent, CSSProperties, ReactNode, useEffect, useState } from "react";
import SearchBar from "material-ui-search-bar";
import { Box, Theme } from "@material-ui/core";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import { TabProps, TabsStyled } from "../../../../components/tabs/TabStyled";
import { makeStyles } from "@material-ui/core/styles";
import { TreeListItemProps } from "./TreeItemStyled";
import { ActionButtonType } from "./buttons/TagActionButton";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";

export interface InputControls<T> {
  min?: (it: T) => number;
  max: (it: T) => number;
  init: (it: T) => number;
  allowZero?: boolean;
}

export interface SearchableTagTreeAction<T> {
  name: string;
  icon?: GenericIcon;
  actionButtonType: ActionButtonType;
  tooltip: string;
  organizationalUnitId: string;
  shouldShow: (dataItem: T) => boolean;
  onClick?: (data: T, value?: number) => void;
  inputControls?: InputControls<T>;
  chipStyle?: CSSProperties;
  labelFn?: (item: T, key: string) => string;
  selected?: (data: T) => boolean;
  delete?: {
    shouldShow: (data: T) => boolean;
    onDelete: (data: T) => void;
  };
}

export interface SearchableTabTreeTab<T> extends TabProps {
  onSelected: (projections: T[]) => T[];
}

interface SearchableTabTreeProps<T> {
  tabs: SearchableTabTreeTab<T>[];
  isLoading: boolean;
  dataItems: T[];
  organizationalUnit?: OrganizationalUnitResponseDTO;
  actions: SearchableTagTreeAction<T>[];
  mapDataItemsToTreeListItems: (
    dataItems: T[] | null,
    actions: SearchableTagTreeAction<T>[],
    organizationalUnit: OrganizationalUnitResponseDTO
  ) => [TreeListItemProps<T>[], string[]];
  searchFn: (row: T, searchedVal: string) => boolean;
  treeHeader?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.default,
    transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    "& button": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  searchContainer: {
    margin: `auto 0px auto ${theme.spacing(1)}px`,
    width: `calc(100% - ${theme.spacing(4)}px)`,
    "& input": {
      padding: "7px 0",
    },
  },
  searchIconButton: {
    marginRight: -theme.spacing(4),
  },
  tree: {
    paddingLeft: theme.spacing(2),
  },
}));

export const SearchableTabTree = <T extends any>({
  tabs,
  isLoading,
  dataItems,
  actions,
  organizationalUnit,
  mapDataItemsToTreeListItems,
  searchFn,
  treeHeader,
}: SearchableTabTreeProps<T>) => {
  const [searchData, setSearchData] = useState(null as T[] | null);
  const [searched, setSearched] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  // trigger searchData and tree update when data is changed
  useEffect(() => {
    setSearchData(tabs[selectedTab].onSelected(dataItems));
  }, [dataItems, tabs, selectedTab]);

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
    setCollapsed(nodeIds.length === 0);
  };

  const requestSearch = (searchedVal: string) => {
    const filteredData: T[] = tabs[selectedTab].onSelected(dataItems).filter((row: T) => searchFn(row, searchedVal));
    setSearchData(filteredData);
  };

  const [treeListData, nodeIds] = mapDataItemsToTreeListItems(searchData, actions, organizationalUnit!);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const collapseAll = () => {
    collapsed ? setExpandedNodes(nodeIds) : setExpandedNodes([]);
    setCollapsed(!collapsed);
  };

  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center">
        <ActionIconButton
          action={{
            icon: collapsed ? GenericIcon.UNFOLD_MORE : GenericIcon.UNFOLD_LESS,
            tooltip: collapsed ? "Expand All" : "Collapse All",
            onClick: (event) => {
              event.stopPropagation();
              collapseAll();
            },
          }}
          buttonSize={"medium"}
        />
        <TabsStyled handleChange={setSelectedTab} tabValue={selectedTab} tabs={tabs} />
        <Box flexGrow={1}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            cancelOnEscape={true}
            classes={{
              root: classes.root,
              searchContainer: classes.searchContainer,
              searchIconButton: classes.searchIconButton,
            }}
          />
        </Box>
      </Box>
      <Box className={classes.tree}>
        {treeHeader}
        <TreeViewStyled
          isLoading={isLoading}
          items={treeListData}
          expanded={expandedNodes}
          handleToggle={handleToggle}
          className={tabs[selectedTab].className}
          headerHeight={treeHeader ? 22 : 0}
        />
      </Box>
    </>
  );
};
