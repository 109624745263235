import { SvgIconProps } from "@material-ui/core";

import { DataSourceType } from "../clients/collector.client";
import BusinessIcon from "@material-ui/icons/Business";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import AppsIcon from "@material-ui/icons/Apps";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GroupIcon from "@material-ui/icons/Group";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import BackupIcon from "@material-ui/icons/Backup";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import { AddChartIcon, AwsIcon, AzureIcon, CSVIcon, GoogleIcon, OracleIcon } from "./CloudIcons";
import FavoriteIcon from "@material-ui/icons/Favorite";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Category,
  DataUsage,
  ErrorOutline,
  ExpandLess,
  Extension,
  NavigateNext,
  UnfoldLess,
  UnfoldMore,
  ViewColumn,
  Help,
} from "@material-ui/icons";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EuroIcon from "@material-ui/icons/Euro";
import ContactsIcon from "@material-ui/icons/Contacts";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import InfoIcon from "@material-ui/icons/Info";
import LabelOffIcon from "@material-ui/icons/LabelOff";
import AddBoxIcon from "@material-ui/icons/AddBox";
import QueueIcon from "@material-ui/icons/Queue";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import PieChartIcon from "@material-ui/icons/PieChart";
import ErrorIcon from "@material-ui/icons/Error";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import { DiscountIcon, MarginIcon } from "./GenericIcons";
import SwapCallsIcon from "@material-ui/icons/SwapCalls";

export type DataSourceIcon = DataSourceType;

export enum GenericIcon {
  ADD = "ADD",
  ADD_BOX = "ADD_BOX",
  QUEUE = "QUEUE",
  EDIT = "EDIT",
  DELETE = "DELETE",
  DELETE_SWEEP = "DELETE_SWEEP",
  WARNING = "WARNING",
  HIDE = "HIDE",
  UNHIDE = "UNHIDE",
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  SHARED = "SHARED",
  SUCCESS = "SUCCESS",
  CONTACT = "CONTACT",
  BILLING = "BILLING",
  SALES = "SALES",
  PROFIT = "PROFIT",
  UPLOAD = "UPLOAD",
  LIST_COLLAPSE = "LIST_COLLAPSE",
  LIST_EXPAND = "LIST_EXPAND",
  LIST_END = "LIST_END",
  EXPAND_LESS = "EXPAND_LESS",
  DATASOURCE = "DATASOURCE",
  DASHBOARD = "DASHBOARD",
  ANALYSIS = "ANALYSIS",
  INVOICES = "INVOICES",
  ORGANIZATIONAL_UNIT = "ORGANIZATIONAL_UNIT",
  USER_MANAGEMENT = "USER_MANAGEMENT",
  INFO = "INFO",
  TRENDING_UP = "TRENDING_UP",
  TRENDING_DOWN = "TRENDING_DOWN",
  TRENDING_FLAT = "TRENDING_FLAT",
  UNFOLD_MORE = "UNFOLD_MORE",
  UNFOLD_LESS = "UNFOLD_LESS",
  LABEL_OFF = "LABEL_OFF",
  LINEAR_SCALE = "LINEAR_SCALE",
  // Organizational Unit
  CUSTOMER = "CUSTOMER",
  INTERNAL = "INTERNAL",
  RESELLER = "RESELLER",
  DEPARTMENT = "DEPARTMENT",
  APPLICATION = "APPLICATION",
  PROJECT = "PROJECT",
  PIE_CHART = "PIE_CHART",
  MARGIN = "MARGIN",
  DISCOUNT = "DISCOUNT",
  DATA_USAGE = "DATA_USAGE",
  CATEGORY = "CATEGORY",
  EXTENSION = "EXTENSION",
  ERROR = "ERROR",
  ERROR_OUTLINE = "ERROR_OUTLINE",
  PLAY_ARROW = "PLAY_ARROW",
  PAUSE = "PAUSE",
  ADD_CHART = "ADD_CHART",
  UNDECIDED = "UNDECIDED",
}

export type CFactsIcon = GenericIcon | DataSourceIcon;

export type CFactsIconColor = SvgIconProps["color"];

interface CFactsIconProps {
  size?: "small" | "large" | "inherit" | "default" | number;
  icon?: CFactsIcon;
}

export type IconProps = CFactsIconProps & SvgIconProps;

export const Icon = (props: IconProps) => {
  const { size, icon, ...iconProps } = props;

  if (typeof size === "number") {
    iconProps["style"] = { ...iconProps.style, fontSize: size };
  } else if (size) {
    iconProps["fontSize"] = size;
  }

  switch (icon) {
    // Organizational unit
    case GenericIcon.CUSTOMER:
      return <BusinessIcon {...iconProps} />;
    case GenericIcon.DEPARTMENT:
      return <SubdirectoryArrowRightIcon {...iconProps} />;
    case GenericIcon.APPLICATION:
      return <AppsIcon {...iconProps} />;
    case GenericIcon.PROJECT:
      return <AssignmentIcon {...iconProps} />;
    case GenericIcon.BILLING:
      return <AccountBalanceWalletIcon {...iconProps} />;
    case GenericIcon.INTERNAL:
      return <GroupIcon {...iconProps} />;
    case GenericIcon.RESELLER:
      return <SwapCallsIcon {...iconProps} />;

    // Cloud icons
    case DataSourceType.SERVICE_PROVIDER:
      return <ScreenShareIcon {...iconProps} />;
    case DataSourceType.AWS:
      return <AwsIcon {...iconProps} />;
    case DataSourceType.AZURE:
      return <AzureIcon {...iconProps} />;
    case DataSourceType.ORACLE:
      return <OracleIcon {...iconProps} />;
    case DataSourceType.GOOGLE:
      return <GoogleIcon {...iconProps} />;
    case DataSourceType.UPLOAD:
      return <CSVIcon {...iconProps} />;

    // Other SVG icons
    case GenericIcon.ADD_CHART:
      return <AddChartIcon {...iconProps} />;
    // Generic Icons
    case GenericIcon.ADD:
      return <AddIcon {...iconProps} />;
    case GenericIcon.ADD_BOX:
      return <AddBoxIcon {...iconProps} />;
    case GenericIcon.QUEUE:
      return <QueueIcon {...iconProps} />;
    case GenericIcon.EDIT:
      return <EditIcon {...iconProps} />;
    case GenericIcon.DELETE:
      return <DeleteIcon {...iconProps} />;
    case GenericIcon.DELETE_SWEEP:
      return <DeleteSweepIcon {...iconProps} />;
    case GenericIcon.WARNING:
      return <WarningIcon {...iconProps} />;
    case GenericIcon.HIDE:
      return <VisibilityOffIcon {...iconProps} />;
    case GenericIcon.UNHIDE:
      return <VisibilityIcon {...iconProps} />;
    case GenericIcon.ACCEPTED:
      return <CheckIcon {...iconProps} />;
    case GenericIcon.PENDING:
      return <HourglassEmptyIcon {...iconProps} />;
    case GenericIcon.SHARED:
      return <AccountBoxIcon {...iconProps} />;
    case GenericIcon.SALES:
      return <LoyaltyIcon {...iconProps} />;
    case GenericIcon.PROFIT:
      return <MonetizationOnIcon {...iconProps} />;
    case GenericIcon.UPLOAD:
      return <BackupIcon {...iconProps} />;
    case GenericIcon.SUCCESS:
      return <FavoriteIcon {...iconProps} />;
    case GenericIcon.CONTACT:
      return <ContactMailIcon {...iconProps} />;
    case GenericIcon.TRENDING_UP:
      return <TrendingUpIcon {...iconProps} />;
    case GenericIcon.TRENDING_DOWN:
      return <TrendingDownIcon {...iconProps} />;
    case GenericIcon.TRENDING_FLAT:
      return <TrendingFlatIcon {...iconProps} />;
    case GenericIcon.LIST_COLLAPSE:
      return <ExpandMoreIcon {...iconProps} />;
    case GenericIcon.LIST_EXPAND:
      return <NavigateNext {...iconProps} />;
    case GenericIcon.LIST_END:
      return <CheckBoxOutlineBlankIcon {...iconProps} />;
    case GenericIcon.DATASOURCE:
      return <CloudDownloadIcon {...iconProps} />;
    case GenericIcon.DASHBOARD:
      return <DashboardIcon {...iconProps} />;
    case GenericIcon.ANALYSIS:
      return <ViewColumn {...iconProps} />;
    case GenericIcon.INVOICES:
      return <EuroIcon {...iconProps} />;
    case GenericIcon.ORGANIZATIONAL_UNIT:
      return <ContactsIcon {...iconProps} />;
    case GenericIcon.USER_MANAGEMENT:
      return <FolderSharedIcon {...iconProps} />;
    case GenericIcon.INFO:
      return <InfoIcon {...iconProps} />;
    case GenericIcon.UNFOLD_LESS:
      return <UnfoldLess {...iconProps} />;
    case GenericIcon.UNFOLD_MORE:
      return <UnfoldMore {...iconProps} />;
    case GenericIcon.EXPAND_LESS:
      return <ExpandLess {...iconProps} />;
    case GenericIcon.LABEL_OFF:
      return <LabelOffIcon {...iconProps} />;
    case GenericIcon.LINEAR_SCALE:
      return <LinearScaleIcon {...iconProps} />;
    case GenericIcon.PIE_CHART:
      return <PieChartIcon {...iconProps} />;
    case GenericIcon.MARGIN:
      return <MarginIcon {...iconProps} />;
    case GenericIcon.DISCOUNT:
      return <DiscountIcon {...iconProps} />;
    case GenericIcon.DATA_USAGE:
      return <DataUsage {...iconProps} />;
    case GenericIcon.CATEGORY:
      return <Category {...iconProps} />;
    case GenericIcon.EXTENSION:
      return <Extension {...iconProps} />;
    case GenericIcon.ERROR:
      return <ErrorIcon {...iconProps} />;
    case GenericIcon.ERROR_OUTLINE:
      return <ErrorOutline {...iconProps} />;
    case GenericIcon.PLAY_ARROW:
      return <PlayArrow {...iconProps} />;
    case GenericIcon.PAUSE:
      return <Pause {...iconProps} />;
    case GenericIcon.UNDECIDED:
      return <Help {...iconProps} />;
    default:
      return <BrokenImageIcon {...iconProps} />;
  }
};
