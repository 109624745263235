import { API } from "./context.service";
import {
  LineItemQueryKeysResponseDTO,
  LineItemQueryOptionsResponseDTO,
  LineItemQueryResponseDTO,
  LineItemSegmentParamsValuesEnum,
  QueryDimension,
  QueryFilter,
} from "../open-api";
import { WithSignalPromise } from "../hooks/client.hooks";

export interface AggregateQueryService {
  query(
    dimensions: QueryDimension[],
    filters: QueryFilter[],
    currency?: string
  ): WithSignalPromise<LineItemQueryResponseDTO[]>;

  segments(
    segments: string[],
    values: string[],
    filters: QueryFilter[],
    currency?: string
  ): Promise<Record<string, any>[]>;

  options(dimension: string, filters: QueryFilter[]): Promise<LineItemQueryOptionsResponseDTO>;

  keys(filters: QueryFilter[]): Promise<Array<LineItemQueryKeysResponseDTO>>;
}

export class LiveQueryService implements AggregateQueryService {
  constructor(private api: API) {}

  query = (dimensions: QueryDimension[], filters: QueryFilter[], currency?: string) => {
    return (signal?: AbortSignal) =>
      this.api.lineItems.query(
        {
          lineItemQueryParams: {
            currency,
            dimensions: dimensions,
            filters: filters.filter((it) => (it.filters?.length ?? 0) > 0),
          },
        },
        {
          signal,
        }
      );
  };

  segments = (
    segments: string[],
    values: LineItemSegmentParamsValuesEnum[],
    filters: QueryFilter[],
    currency?: string
  ): Promise<Record<string, any>[]> => {
    return this.api.lineItems.segmentQuery({
      lineItemSegmentParams: {
        segments,
        values,
        currency,
        filters: filters.filter((it) => (it.filters?.length ?? 0) > 0),
      },
    });
  };

  options = (dimension: string, filters: QueryFilter[]) => {
    return this.api.lineItems.options({
      lineItemOptionsParams: {
        segment: dimension,
        filters: filters.filter((it) => (it.filters?.length ?? 0) > 0),
      },
    });
  };

  keys = (filters: QueryFilter[]): Promise<LineItemQueryKeysResponseDTO[]> => {
    return this.api.lineItems.keys({
      lineItemKeysParams: {
        filters: filters.filter((it) => (it.filters?.length ?? 0) > 0),
      },
    });
  };
}
