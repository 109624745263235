import { GROUP_SEGMENT, GroupSegment } from "./GroupSegment";
import { Arr } from "../../../utils/functional/array.utils";
import { attributeEquals } from "./Attribute";

export interface SegmentFilterOption {
  id: string;
  label?: string;
}

export interface GroupSegmentFilter {
  segment: GroupSegment;
  filters: SegmentFilterOption[];
}

export const getDefaultGroupSegmentFilters = (): GroupSegmentFilter[] =>
  [GROUP_SEGMENT.CLOUD, GROUP_SEGMENT.DATASOURCE].map((it) => ({
    segment: { ...it },
    filters: [],
  }));

export const addGroupSegmentFilter = (filters: GroupSegmentFilter[], filter: GroupSegmentFilter) => [
  ...filters,
  filter,
];

export const updateGroupSegmentFilter = (filters: GroupSegmentFilter[], filter: GroupSegmentFilter) =>
  filters.map((it) => (attributeEquals(it.segment, filter.segment) ? filter : it));

export const deleteGroupSegmentFilter = (segmentFilter: GroupSegmentFilter[], segment: GroupSegment) =>
  segmentFilter.filter((it) => !attributeEquals(it.segment, segment));

export function onMultipleFiltersSelected(
  filters: GroupSegmentFilter[],
  filter: GroupSegmentFilter,
  values: SegmentFilterOption[]
): GroupSegmentFilter[] {
  return updateGroupSegmentFilter(filters, { ...filter, filters: values });
}

export function onFilterToggled(
  segmentFilters: GroupSegmentFilter[],
  groupSegment: GroupSegment,
  segmentValue: SegmentFilterOption
): GroupSegmentFilter[] {
  const segmentToUpdate = segmentFilters.find((it) => attributeEquals(it.segment, groupSegment));
  if (segmentToUpdate == null) {
    return addGroupSegmentFilter(segmentFilters, { segment: groupSegment, filters: [segmentValue] });
  }
  const newFilters = (() => {
    if (Arr.equals(segmentToUpdate.filters, [segmentValue])) {
      return segmentToUpdate.filters.filter((it) => it !== segmentValue);
    }
    return [segmentValue];
  })();
  return updateGroupSegmentFilter(segmentFilters, { segment: groupSegment, filters: newFilters });
}
