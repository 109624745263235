import { SearchableTagTreeAction } from "../SearchableTabTree";
import { Chip, PropTypes } from "@material-ui/core";
import { PositiveNumberInputPopper } from "../../../../../components/dialogs/PositiveNumberInputPopper";
import { Icon } from "../../../../../theme/Icon";
import { formattedPercentage } from "../../../../../utils/localization.utils";
import { CSSProperties } from "react";

interface ChipActionButtonProps<T> {
  action: SearchableTagTreeAction<T>;
  data: {
    item: T;
    filterKey?: string;
  };
  label: string;
  color?: Exclude<PropTypes.Color, "inherit">;
  chipStyle?: CSSProperties;
}

export const ChipActionButton = <T extends any>({
  action,
  data,
  label,
  color,
  chipStyle,
}: ChipActionButtonProps<T>) => {
  const tooltip = action.tooltip;
  const onConfirm = (value: any) => action.onClick && action.onClick(data.item, value);
  const onDelete = action.delete?.shouldShow(data.item)
    ? (event: any) => action.delete?.onDelete(data.item)
    : undefined;

  return (
    <PositiveNumberInputPopper
      title={tooltip}
      onSave={onConfirm}
      maxValue={action.inputControls?.max(data.item)}
      initialValue={action.inputControls?.init(data.item)}
      allowZero={action.inputControls?.allowZero}
      label={`Set ${action.name} percentage for "${
        action.labelFn ? action.labelFn(data.item, data.filterKey ?? "") : label
      }": `}
      onDelete={onDelete}
    >
      <Chip
        style={chipStyle}
        variant="outlined"
        size="small"
        label={action.inputControls && formattedPercentage(action.inputControls?.init(data.item), 0, 2)}
        clickable
        color={color ?? "primary"}
        icon={action.icon && <Icon icon={action.icon} />}
      />
    </PositiveNumberInputPopper>
  );
};
