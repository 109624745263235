import { Toolbar, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DenseListItem, DenseListItemProps } from "./DenseListItem";
import { ActionIconButton } from "../buttons/ActionIconButton";
import { CFactsAction } from "../buttons/CFactsAction";
import { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    paddingLeft: theme.spacing(1),
    flex: "1 1 100%",
  },
}));

interface DenseListProps {
  items: DenseListItemProps[];
  header?: ReactNode;
  action?: CFactsAction | false;
  empty?: string;
}

export const DenseList = ({ items, header, action, empty }: DenseListProps) => {
  const classes = useStyles();

  return (
    <>
      {header && (
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            {header}
          </Typography>
          {action && <ActionIconButton action={action} />}
        </Toolbar>
      )}
      <List dense>
        {items.length === 0 && empty && (
          <Typography align={"center"} display="block" color="textSecondary">
            {empty}
          </Typography>
        )}
        {items.length > 0 && items.map((item, key) => <DenseListItem item={item} key={key} />)}
      </List>
    </>
  );
};
