import { API } from "./context.service";
import { AzureSsoConfigResponseDTO } from "../open-api";

export interface SsoService {
  ssoLogin(email: string): Promise<AzureSsoConfigResponseDTO>;

  ssoAuthenticate(name: string, inputEmail: string, authenticatedEmail: string, groups: string[]): Promise<void>;
}

export class LiveSsoService implements SsoService {
  constructor(private api: API) {}

  ssoLogin = (email: string): Promise<AzureSsoConfigResponseDTO> => {
    return this.api.sso.getSsoConfig({
      azureAdRequestDTO: {
        email: email,
      },
    });
  };

  ssoAuthenticate = (name: string, inputEmail: string, authenticatedEmail: string, groups: string[]): Promise<void> => {
    return this.api.sso.ssoAuthenticate({
      azureAdAuthenticationResultDTO: {
        name: name,
        inputEmail: inputEmail,
        authenticatedEmail: authenticatedEmail,
        groups: groups,
      },
    });
  };
}
