import { ReactComponent as ErrorImg } from "../assets/img/404-error.svg";
import * as Sentry from "@sentry/browser";

export function NoMatchPage({ ...props }) {
  const mainColor = "#cddc39";

  Sentry.addBreadcrumb({
    category: "404",
    message: "Page not found",
    level: Sentry.Severity.Warning,
  });

  Sentry.captureMessage("404 Page not found", Sentry.Severity.Warning);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <ErrorImg title="404 not found" style={{ maxWidth: 200, maxHeight: 200, marginTop: 20 }} />
      <h1>Page Not found</h1>
      <a href={"/dashboard"} style={{ color: mainColor }}>
        Take me back
      </a>
    </div>
  );
}
