/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    CreatePriceAdjustmentRequestDTO,
    PriceAdjustmentResponseDTO,
    UpdatePriceAdjustmentRequestDTO,
} from '../models';

export interface CreateOrganizationAdjustmentRequest {
    organizationalUnitId: string;
    createPriceAdjustmentRequestDTO: CreatePriceAdjustmentRequestDTO;
}

export interface DeleteOrganizationAdjustmentRequest {
    id: string;
    organizationalUnitId: string;
}

export interface GetOrganizationAdjustmentsRequest {
    organizationalUnitId: string;
    includeAvailable?: boolean;
}

export interface UpdateOrganizationAdjustmentRequest {
    id: string;
    organizationalUnitId: string;
    updatePriceAdjustmentRequestDTO: UpdatePriceAdjustmentRequestDTO;
}

/**
 * 
 */
export class OrganizationPriceAdjustmentsApi extends runtime.BaseAPI {

    /**
     * Create a price adjustment for an organization
     */
    async createOrganizationAdjustmentRaw(requestParameters: CreateOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling createOrganizationAdjustment.');
        }

        if (requestParameters.createPriceAdjustmentRequestDTO === null || requestParameters.createPriceAdjustmentRequestDTO === undefined) {
            throw new runtime.RequiredError('createPriceAdjustmentRequestDTO','Required parameter requestParameters.createPriceAdjustmentRequestDTO was null or undefined when calling createOrganizationAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/price-adjustments`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createPriceAdjustmentRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a price adjustment for an organization
     */
    async createOrganizationAdjustment(requestParameters: CreateOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createOrganizationAdjustmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a price adjustment for an organization
     */
    async deleteOrganizationAdjustmentRaw(requestParameters: DeleteOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrganizationAdjustment.');
        }

        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling deleteOrganizationAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/price-adjustments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a price adjustment for an organization
     */
    async deleteOrganizationAdjustment(requestParameters: DeleteOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteOrganizationAdjustmentRaw(requestParameters, initOverrides);
    }

    /**
     * View price adjustments for organization
     */
    async getOrganizationAdjustmentsRaw(requestParameters: GetOrganizationAdjustmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PriceAdjustmentResponseDTO>>> {
        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling getOrganizationAdjustments.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeAvailable !== undefined) {
            queryParameters['includeAvailable'] = requestParameters.includeAvailable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/price-adjustments`.replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * View price adjustments for organization
     */
    async getOrganizationAdjustments(requestParameters: GetOrganizationAdjustmentsRequest, initOverrides?: RequestInit): Promise<Array<PriceAdjustmentResponseDTO>> {
        const response = await this.getOrganizationAdjustmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a price adjustment for an organization
     */
    async updateOrganizationAdjustmentRaw(requestParameters: UpdateOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOrganizationAdjustment.');
        }

        if (requestParameters.organizationalUnitId === null || requestParameters.organizationalUnitId === undefined) {
            throw new runtime.RequiredError('organizationalUnitId','Required parameter requestParameters.organizationalUnitId was null or undefined when calling updateOrganizationAdjustment.');
        }

        if (requestParameters.updatePriceAdjustmentRequestDTO === null || requestParameters.updatePriceAdjustmentRequestDTO === undefined) {
            throw new runtime.RequiredError('updatePriceAdjustmentRequestDTO','Required parameter requestParameters.updatePriceAdjustmentRequestDTO was null or undefined when calling updateOrganizationAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/organizational-unit/{organizationalUnitId}/price-adjustments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"organizationalUnitId"}}`, encodeURIComponent(String(requestParameters.organizationalUnitId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updatePriceAdjustmentRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a price adjustment for an organization
     */
    async updateOrganizationAdjustment(requestParameters: UpdateOrganizationAdjustmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateOrganizationAdjustmentRaw(requestParameters, initOverrides);
    }

}
