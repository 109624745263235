import { DIMENSIONS } from "../../../../clients/lineitems.client";
import { useLandingData } from "../../dashboard.utils";
import { aggregateToChart } from "../../../newdash/model/Query";
import { LineItemDisplay } from "../../../newdash/model/ChartConfig";
import Chart, { Series, Title, Tooltip } from "devextreme-react/chart";
import { ValueTooltip } from "../../../../components/tooltips/ValueTooltip";
import { FC, useRef } from "react";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Information } from "../../../../components/Information";
import { MenuOptionsGraph } from "../../../../components/menu/MenuOptionsGraph";
import { QueryFilter } from "../../../../open-api";
import { OneCardGrid } from "../../../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../../../components/skeletons/DataTableChartSkeleton";

interface OrganizationTopListProps {
  filter: QueryFilter;
  dimension: keyof typeof DIMENSIONS;
  segments: LineItemDisplay[];
  plural: string;
}

export const LandingPageBarChart = ({ filter, dimension, segments, plural }: OrganizationTopListProps) => {
  const chartRef = useRef<Chart>(null);
  const [items, isLoading] = useLandingData(dimension, filter);

  if (isLoading) {
    return <TopListSkeleton />;
  }
  if (!items || items.length === 0 || items[0].arguments.length === 0) {
    return null;
  }

  const [dataSource, series] = aggregateToChart(segments, items ? items[0] : undefined);
  return (
    <Grid item xs={12} lg={6}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Information title={`This chart shows the top 5 ${plural} for the past 3 months`} align={"left"} />
            </Grid>
            <Grid item container justify={"flex-end"} xs={2}>
              <MenuOptionsGraph png={chartRef} svg={chartRef} />
            </Grid>
          </Grid>
          <Chart
            ref={chartRef}
            dataSource={dataSource}
            commonSeriesSettings={{ argumentField: "argument", type: "bar" }}
          >
            <Title text={`Top 5 ${plural} for the past 3 months`} />
            <Tooltip
              enabled={true}
              contentRender={(props) => (
                <ValueTooltip
                  {...props}
                  argumentText={props.argumentText}
                  title={props.seriesName}
                  value={props.value}
                />
              )}
            />
            {series.map((item) => (
              <Series key={item.key} valueField={item.key} name={item.label} />
            ))}
          </Chart>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const TopListSkeleton: FC = () => {
  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <OneCardGrid>
          <DataTableSkeleton />
        </OneCardGrid>
      </Grid>
    </>
  );
};
