import { OrganizationalUnitType } from "../../../clients/organizationalunit.client";

export const mapOrgTypeToDisplay = (type: OrganizationalUnitType, count: number = 1) => {
  const res = type === "INTERNAL" ? "Organization" : type === "RESELLER" ? "Reseller" : "Customer";

  if (count !== 1) {
    return `${res}s`;
  } else {
    return res;
  }
};
