import { useSelectedDashboard } from "../selectors/dashboard.selector";
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { css } from "@emotion/css";
import TitleIcon from "@material-ui/icons/Title";
import PieChartIcon from "@material-ui/icons/PieChart";
import {
  useOnAddTab,
  useOnDiscardAll,
  useOnEditTitle,
  useOnSaveAll,
  useOnSaveAs,
  useShareDashboard,
} from "../events/dashboard.events";
import { DashRemoveDashboard } from "./DashRemoveDashboard";
import SaveIcon from "@material-ui/icons/Save";
import ShareIcon from "@material-ui/icons/Share";
import { ClearAll } from "@material-ui/icons";
import { MouseEvent, useState } from "react";

export function DashMenu() {
  const dashboard = useSelectedDashboard();
  const onAddTab = useOnAddTab();
  const onDiscardAll = useOnDiscardAll();
  const onSaveAll = useOnSaveAll();
  const onSaveAs = useOnSaveAs();
  const onEditTitle = useOnEditTitle();
  const [canShare, onShare] = useShareDashboard();
  const enableShare = dashboard && !(dashboard.savedAt == null || dashboard.updatedAt > dashboard.savedAt);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <MenuIcon color="primary" />
      </Button>
      <Menu
        classes={{
          paper: css({
            borderRadius: "0 4px 4px 4px",
            "& .MuiListItem-root": { paddingRight: "32px" },
          }),
        }}
        MenuListProps={{ dense: true }}
        anchorEl={anchorEl}
        open={open}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            onAddTab();
            handleClose();
          }}
        >
          <ListItemIcon>
            <PieChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>New dashboard</ListItemText>
          {/*<Typography variant="body2">⌘C</Typography>*/}
        </MenuItem>

        {/* Menu doesn't allow fragments as child, therefore the array. */}
        {dashboard != null && [
          <Divider key={0} />,
          <MenuItem
            key={1}
            onClick={() => {
              onEditTitle(dashboard);
              handleClose();
            }}
          >
            <ListItemIcon>
              <TitleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Update title</ListItemText>
            {/*<Typography variant="body2">⌘C</Typography>*/}
          </MenuItem>,
          <MenuItem
            key={2}
            onClick={() => {
              onSaveAs(dashboard);
              handleClose();
            }}
          >
            <ListItemIcon>
              <SaveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Save As...</ListItemText>
          </MenuItem>,
          canShare && (
            <MenuItem
              key={2}
              disabled={!enableShare}
              onClick={() => {
                onShare(dashboard);
                handleClose();
              }}
            >
              <ListItemIcon>
                <ShareIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Make {dashboard.shared ? "private" : "public"}</ListItemText>
            </MenuItem>
          ),

          <DashRemoveDashboard key={3} dashboard={dashboard} onRemove={handleClose} />,
        ]}

        <Divider />

        <MenuItem onClick={() => onSaveAll().then(handleClose)}>
          <ListItemIcon>
            <SaveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Save all</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => onDiscardAll().then(handleClose)}>
          <ListItemIcon>
            <ClearAll fontSize="small" />
          </ListItemIcon>
          <ListItemText>Discard all changes</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
