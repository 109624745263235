import { API } from "./context.service";
import { CreateCustomerPortalDTO, PortalResponseDTO } from "../open-api";

export interface ManagedPortalService {
  listManaged(): Promise<Array<PortalResponseDTO>>;

  listManagedForOrgUnit(id: string): Promise<Array<PortalResponseDTO>>;

  invite(orgUnitId: string, dto: CreateCustomerPortalDTO): Promise<void>;

  delete(id: string): Promise<void>;
}

export class LiveManagedPortalService implements ManagedPortalService {
  constructor(private api: API) {}

  listManaged = () => this.api.managedPortal.listManagedPortalsForWorkspace();
  listManagedForOrgUnit = (id: string) =>
    this.api.managedPortal.listManagedPortalsForOrgUnit({ organizationalUnitId: id });
  invite = (orgUnitId: string, dto: CreateCustomerPortalDTO) =>
    this.api.managedPortal.createManagedPortal({ customerIds: orgUnitId, createCustomerPortalDTO: dto });
  delete = (id: string) => this.api.managedPortal.deleteManagedPortal({ managedPortalId: id });
}
