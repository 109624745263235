import { useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import { Money } from "../Money";
import { ValueKey } from "../../clients/lineitems.client";
import { NestedLineItemDTOV3 } from "../../open-api";

export const SHOW_MORE_STEP = 50;

const useStyles = makeStyles((theme) => ({
  tableRow: {
    backgroundColor: "#383838",
    "&:hover": {
      backgroundColor: "#404040",
    },
  },
  customerTableRow: {
    backgroundColor: "#484848",
  },
  expandable: {
    cursor: "pointer",
  },
  tag: {
    fontStyle: "italic",
  },
  customer: {
    fontWeight: "bold",
  },
}));

interface LineItemProps {
  /**
   * LineItem to render
   */
  lineItem: NestedLineItemDTOV3;
  /**
   * Determines whether or not this item should be expanded when first rendered?
   */
  expandOnFirstRender?: boolean;
  /**
   * Maximum depth of the childLineItems tree (0 means no childLineItems)
   */
  maxDepth: number;
  /**
   * Depth used internally to manage the indentation
   */
  child?: number;

  valueColumns?: ValueKey[];
}

export function BillingLineItem({
  lineItem,
  expandOnFirstRender = false,
  child = 0,
  maxDepth,
  valueColumns = [],
}: LineItemProps): any {
  const classes = useStyles();
  const [open, setOpen] = useState(expandOnFirstRender);
  const [showChildCount, setShowChildCount] = useState(SHOW_MORE_STEP);
  const items = lineItem.children
    .filter((it) => it.total.cost || it.total.list || it.total.sales || it.total.crossCharging)
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <TableRow
        key={lineItem.id}
        className={classNames(
          classes.tableRow,
          child === 0 && classes.customerTableRow,
          lineItem.children.length > 0 && classes.expandable
        )}
        onClick={() => setOpen((open) => !open)}
      >
        {child > 0 && <TableCell colSpan={child} />}
        <TableCell colSpan={maxDepth - child + 1}>
          <span className={classNames(child === 0 && classes.customer, child === 1 && classes.tag)}>
            {child < maxDepth && (open ? "-" : "+")} {lineItem.label}
          </span>
        </TableCell>
        <TableCell align="right">{lineItem.children.length === 0 ? lineItem.qty : null}</TableCell>
        {valueColumns.map((key) => (
          <TableCell align="right">{(!open || child === maxDepth) && <Money money={lineItem.total[key]} />}</TableCell>
        ))}
      </TableRow>
      {open &&
        items
          .slice(0, showChildCount)
          .map((item) => (
            <BillingLineItem
              key={item.id}
              lineItem={item}
              child={child + 1}
              expandOnFirstRender={lineItem.children.length === 1}
              maxDepth={maxDepth}
              valueColumns={valueColumns}
            />
          ))}
      {open && lineItem.children.length > showChildCount && (
        <ShowMore
          onClick={() => setShowChildCount((count) => count + SHOW_MORE_STEP)}
          depth={child}
          maxDepth={maxDepth}
          valueColumns={valueColumns}
          remainingItems={items.slice(showChildCount)}
        />
      )}
      {open && lineItem.children.length > 1 && (
        <SubTotal lineItem={lineItem} depth={child} maxDepth={maxDepth} valueKeys={valueColumns} />
      )}
      {child === 0 && open && <Total lineItem={lineItem} maxDepth={maxDepth} valueKeys={valueColumns} />}
    </>
  );
}

function SubTotal({
  lineItem,
  maxDepth,
  depth,
  valueKeys,
}: {
  maxDepth: number;
  depth: number;
  lineItem: NestedLineItemDTOV3;
  valueKeys: ValueKey[];
}) {
  return (
    <TableRow>
      <TableCell colSpan={depth + 1} />
      <TableCell>
        <strong>SubTotal</strong>
      </TableCell>
      {maxDepth > 1 && <TableCell colSpan={maxDepth - depth} />}
      {valueKeys.map((it) => (
        <TableCell align="right">
          <strong>
            <Money money={lineItem.total[it]} />
          </strong>
        </TableCell>
      ))}
    </TableRow>
  );
}

function Total({
  lineItem,
  maxDepth,
  valueKeys,
}: {
  maxDepth: number;
  lineItem: NestedLineItemDTOV3;
  valueKeys: ValueKey[];
}) {
  return (
    <TableRow>
      <TableCell>
        <strong>Total:</strong>
      </TableCell>
      {maxDepth > 0 && <TableCell colSpan={maxDepth + 1} />}
      {valueKeys.map((it) => (
        <TableCell align="right">
          <strong>
            <Money money={lineItem.total[it]} />
          </strong>
        </TableCell>
      ))}
    </TableRow>
  );
}

export function ShowMore({
  onClick,
  depth,
  maxDepth,
  remainingItems,
  valueColumns,
}: {
  onClick: () => void;
  depth: number;
  maxDepth: number;
  remainingItems: NestedLineItemDTOV3[];
  valueColumns: ValueKey[];
}) {
  const classes = useStyles();

  return (
    <TableRow onClick={onClick} className={classNames(classes.expandable, classes.tableRow)}>
      <TableCell colSpan={depth + 1} />
      <TableCell>
        <strong>
          + Show {Math.min(SHOW_MORE_STEP, remainingItems.length)} more (of {remainingItems.length})
        </strong>
      </TableCell>
      {maxDepth > 1 && <TableCell colSpan={maxDepth - depth} />}
      {valueColumns.map((it) => (
        <TableCell align="right">
          <strong>
            <Money money={remainingItems.reduce((agg, curr) => agg + curr.total[it]!, 0)} />
          </strong>
        </TableCell>
      ))}
    </TableRow>
  );
}
