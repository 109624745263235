import { Box, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Information } from "../../../components/Information";
import { CFactsIcon, Icon } from "../../../theme/Icon";

interface FastFactTitleProps {
  icon?: CFactsIcon;
  title: string;
  info?: string;
}

const useStyles = makeStyles((theme) => ({
  logo: {
    flexGrow: 1,
    maxHeight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
}));

export function FastFactTitle({ icon, title, info }: FastFactTitleProps) {
  const classes = useStyles();

  return (
    <Box display={"flex"} justifyContent={"center"}>
      {icon ? (
        <Icon icon={icon} className={classes.logo} />
      ) : (
        <Typography className={classes.title}>{title}</Typography>
      )}
      {info ? <Information title={info} /> : ""}
    </Box>
  );
}
