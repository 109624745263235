import {
  AttributeResponseDTO,
  AttributeTypeDTOCategoryEnum,
  AttributeValueDTO,
  LineItemQueryKeysResponseDTO,
} from "../../../open-api";
import { GroupSegmentFilter } from "./GroupSegmentFilter";
import { attributeEquals, attributeId } from "./Attribute";

export type CFactsKey = "TYPE" | "ORGANIZATION" | "COST_CENTER" | "COST_TYPE" | "INVOICE";

export type BillingHierarchyKey =
  | "CLOUD"
  | "DATASOURCE"
  | "SERVICE"
  | "DETAIL"
  | "ORGANIZATION_ATTRIBUTE"
  | "COST_CENTER_ATTRIBUTE";

export type BillingCycleAttribute = {
  type: "billingCycle";
  label: string;
  id: "billingCycle";
  category: "CF_ATTRIBUTE";
  collectorId?: undefined;
};

export type BillingPeriodAttribute = {
  type: "billingPeriod";
  label: string;
  id: "BILLING_PERIOD";
  category: "CF_ATTRIBUTE";
  collectorId?: undefined;
};

export type GroupSegment =
  | BillingCycleAttribute
  | BillingPeriodAttribute
  | {
      type: "attribute";
      id: string;
      label: string;
      collectorId?: string;
      categoryLabel?: string;
      category: AttributeTypeDTOCategoryEnum;
    };

export type ArgumentAttribute = GroupSegment;

export function queryKeysToSegments(res: LineItemQueryKeysResponseDTO[]): GroupSegment[] {
  return res.flatMap((group) => {
    return group.attributes.map((attr) => {
      return {
        type: "attribute" as const,
        id: attr.id!,
        label: attr.display!,
        collectorId: group.category.collectorId,
        categoryLabel: group.category.display,
        category: group.category.category,
      };
    });
  });
}

export function segmentsToOptions(segments: GroupSegment[]) {
  return segments
    .filter((it) => it.id !== "BILLING_CYCLE")
    .map((segment) => ({
      key: attributeId(segment),
      label: segment.label,
      group: {
        id: segment.collectorId ?? segment.category,
        label: (segment.type === "attribute" ? segment.categoryLabel : segment.collectorId) || segment.category,
      },
    }));
}

export function getAllGroupSegments(): GroupSegment[] {
  return [...Object.values(GROUP_SEGMENT)];
}

export function getNewGroupSegmentOptions(filters: GroupSegmentFilter[]): GroupSegment[] {
  return getAllGroupSegments().filter((groupSegment) =>
    filters.every((filter) => !attributeEquals(filter.segment, groupSegment))
  );
}

export function getFirstNewGroupSegment(filters: GroupSegmentFilter[]): GroupSegment | undefined {
  return getNewGroupSegmentOptions(filters)[0];
}

export function getAttributeValue(
  segment: GroupSegment,
  attributes: AttributeResponseDTO[],
  attributeValueId: string
): AttributeValueDTO {
  return attributes
    .filter((it) => attributeEquals(it.type, segment))
    .flatMap((it) => Object.values(it.values ?? []))
    .find((it) => it.id === attributeValueId)!;
}

export function migrateGroupSegmentId(oldId: string): string {
  switch (oldId) {
    case "date":
      return "BILLING_MONTH";
    case "type":
      return "TYPE";
    case "organization":
      return "ORGANIZATION";
    case "department":
      return "COST_CENTER";
    case "costType":
      return "COST_TYPE";
    case "invoice":
      return "INVOICE";
    case "cloud":
      return "CLOUD";
    case "datasource":
      return "DATASOURCE";
    case "customerTag":
      return "ORGANIZATION_ATTRIBUTE";
    case "applicationTag":
      return "COST_CENTER_ATTRIBUTE";
    case "service":
      return "SERVICE";
    case "detail":
      return "DETAIL";
    default:
      return oldId;
  }
}

export const DATE_ATTRIBUTE = {
  type: "attribute",
  id: "BILLING_MONTH",
  label: "Date",
  category: "CF_ATTRIBUTE",
} as const;

export const GROUP_SEGMENT = {
  TOTAL: { id: "TOTAL", label: "Total", type: "attribute", category: "BILLING_HIERARCHY_ATTRIBUTE" },
  BILLING_CYCLE: { id: "billingCycle", label: "Billing Cycle", type: "billingCycle", category: "CF_ATTRIBUTE" },
  // CF_ATTRIBUTES
  BILLING_PERIOD: { id: "BILLING_PERIOD", label: "Billing period", type: "attribute", category: "CF_ATTRIBUTE" },
  CF_TYPE: { id: "TYPE", label: "Type (Customer/Internal)", type: "attribute", category: "CF_ATTRIBUTE" },
  CF_ORGANIZATION: {
    id: "ORGANIZATION",
    label: "C-Facts Organization",
    type: "attribute",
    category: "CF_ATTRIBUTE",
  },
  CF_COST_CENTER: {
    id: "COST_CENTER",
    label: "C-Facts Cost center",
    type: "attribute",
    category: "CF_ATTRIBUTE",
  },
  COST_TYPE: { id: "COST_TYPE", label: "Cost type", type: "attribute", category: "CF_ATTRIBUTE" },
  INVOICE: { id: "INVOICE", label: "Invoice", type: "attribute", category: "CF_ATTRIBUTE" },

  // Billing hierarchy
  CLOUD: { id: "CLOUD", label: "Cloud", type: "attribute", category: "BILLING_HIERARCHY_ATTRIBUTE" },
  DATASOURCE: {
    id: "DATASOURCE",
    label: "Data source",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  ORGANIZATION: {
    id: "ORGANIZATION_TAG",
    label: "Organization",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  ORGANIZATION_HIERARCHY: {
    id: "ORGANIZATION_ATTRIBUTE",
    label: "Level 1 cost allocation",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  RESOURCE_GROUP: {
    id: "COST_CENTER_TAG",
    label: "Resource group",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  RESOURCE_GROUP_HIERARCHY: {
    id: "COST_CENTER_ATTRIBUTE",
    label: "Level 2 cost allocation",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  SERVICE: {
    id: "SERVICE",
    label: "Service",
    type: "attribute",
    category: "BILLING_HIERARCHY_ATTRIBUTE",
  },
  DETAIL: { id: "DETAIL", label: "Detail", type: "attribute", category: "BILLING_HIERARCHY_ATTRIBUTE" },
} as const;
