import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useApiCall } from "../../../hooks/client.hooks";
import { UserCreateForm } from "../forms/UserCreateForm";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import { CreateWorkspaceUserRequestDTO } from "../../../open-api";
import { useServices } from "../../../services/context.service";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});
interface UserCreateDialogFormProps {
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export function UserCreateDialogForm({ onClose, onSuccess, onError }: UserCreateDialogFormProps) {
  const { users } = useServices();
  const classes = useStyles();

  const [create, isSubmitting] = useApiCall(users.create);

  const { handleSubmit, control } = useForm<CreateWorkspaceUserRequestDTO>({
    defaultValues: { name: "", email: "", role: "READ" },
  });

  const createUser = (userValues: CreateWorkspaceUserRequestDTO) => {
    create({ createWorkspaceUserRequestDTO: userValues })
      .then((e) => {
        messageSuccess("User invited");
        onSuccess && onSuccess();
      })
      .catch((e) => {
        messageError(`Failed to invite user: ${e.message}`);
        onError && onError();
      });
  };

  const close = () => {
    onClose();
  };

  return (
    <form
      onSubmit={handleSubmit((form) => {
        createUser(form);
      })}
    >
      <DialogContent className={classes.content}>
        <UserCreateForm control={control} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
