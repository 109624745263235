import Grid from "@material-ui/core/Grid";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { ucFirst } from "../../../utils/localization.utils";
import { CsvConfigPreviewField } from "./CsvConfigPreviewField";
import { HAS_HEADER_ID, useCsvUploadFormMethods } from "./CsvUploadForm";
import { useDataRows, useUploadConfig } from "./CsvUpload";
import { UploadCsvFieldRequestDTO } from "../../../open-api";

interface CsvConfigPreviewProps {
  getMappedFieldFn: (field: string) => UploadCsvFieldRequestDTO | undefined;
}

export const CsvConfigPreview = ({ getMappedFieldFn }: CsvConfigPreviewProps) => {
  const formMethods = useCsvUploadFormMethods();
  const csvUploadConfig = useUploadConfig();
  const [dataRows] = useDataRows();
  const configFields = csvUploadConfig ? csvUploadConfig.fields : [];
  const hasHeader = formMethods.watch(HAS_HEADER_ID);

  return (
    <Grid container item spacing={2} style={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {configFields.map((field) => {
                return (
                  <TableCell style={{ width: 140 }} key={`table-cell-${field.key}`}>
                    <Typography>{ucFirst(field.name)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.slice(hasHeader ? 1 : 0).map((row, idx) => {
              return (
                <TableRow key={`table-row-${idx}`}>
                  {configFields.map((field) => {
                    const mapped = getMappedFieldFn(field.key);
                    const fieldValue = mapped ? row[mapped.index as number] : "";

                    return (
                      <TableCell key={`table-row-${idx}-${field.key}`}>
                        <CsvConfigPreviewField field={field} fieldValue={fieldValue} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
