import { FC } from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const BarChartSkeleton: FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Skeleton variant="rect" height={200} />
      </Grid>
      <Grid item xs={1}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Grid>
    </Grid>
  );
};
