import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Grid } from "@material-ui/core";
import {
  createOrganizationalUnit,
  OrganizationalUnitType,
  OrganizationalUnitUnitType,
} from "../../../clients/organizationalunit.client";
import { useApiCall } from "../../../hooks/client.hooks";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { CreateOrganizationalUnitRequestDTO, OrganizationalUnitResponseDTO } from "../../../open-api";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { ControlledTextField } from "../../../components/form/FormComponents";
import { mapOrganizationToText } from "../../../mappers/organization.mapper";
import { FormTitleField } from "../../../components/form/FormTitleField";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

interface OrganizationalUnitCreateDialogProps {
  onClose: () => void;
  onSuccess?: (customer: OrganizationalUnitResponseDTO) => void;
  onError?: (e: any) => void;
  parentId?: string;
  type: OrganizationalUnitType;
  unitType: OrganizationalUnitUnitType;
}

export function OrganizationalUnitFormCreate({
  onClose,
  onSuccess,
  onError,
  parentId,
  type,
  unitType,
}: OrganizationalUnitCreateDialogProps) {
  const classes = useStyles();
  const [create, isLoading] = useApiCall(createOrganizationalUnit);

  const { control, handleSubmit } = useCFForm({
    schema: z.object({
      name: z.string().max(255),
      parentId: z.string().optional(),
      reference: z.string().max(255).optional(),
      type: z.string(),
      unitType: z.string(),
    }),
    defaultValues: {
      name: "",
      parentId: parentId,
      reference: "",
      type,
      unitType,
    },
  });

  const createUnit = (customerValues: CreateOrganizationalUnitRequestDTO) => {
    create(customerValues)
      .then((e) => {
        onSuccess && onSuccess(e);
      })
      .catch((e) => {
        onError && onError(e);
      });
  };

  const close = () => {
    onClose();
  };

  const name = mapOrganizationToText({ type, unitType });

  return (
    <form onSubmit={handleSubmit(createUnit)}>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <FormTitleField text={`${name} details`} />
          <Grid item xs={12}>
            <ControlledTextField control={control} name={"name"} label={`${name} name`} />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField control={control} name={"reference"} label={`${name} reference`} />
          </Grid>
          <ControlledTextField type="hidden" control={control} name="type" />
          <ControlledTextField type="hidden" control={control} name="unitType" />
          {parentId && <ControlledTextField type="hidden" control={control} name={"parentId"} />}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonConfirm isSubmitting={isLoading}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
