import { Button, Grid, Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { StepComplete } from "./StepComplete";
import { ReactNode, useState } from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    backgroundColor: "unset",
    width: "100%",
  },
}));

export interface StepperTutorialObj {
  stepTitle: string;
  step: ReactNode;
  stepCompleted?: () => boolean;
  stepCompletedDescription?: ReactNode;
}

interface StepperTutorialProps {
  steps: StepperTutorialObj[];
  onNext?: () => {};
  onBack?: () => {};
  canClickNext: boolean;
}

export function StepperTutorial({ steps, onNext, onBack, canClickNext }: StepperTutorialProps) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    onNext && onNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    onBack && onBack();
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepContent}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel>{step.stepTitle}</StepLabel>
          <StepContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TutorialStep step={step} />
              </Grid>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={canClickNext}
                  onClick={handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Finish" : `Next`}
                </Button>
              </div>
            </Grid>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

const TutorialStep = ({ step }: { step: StepperTutorialObj }) => {
  if (step.stepCompleted && step.stepCompleted()) {
    return <StepComplete>{step.stepCompletedDescription ? step.stepCompletedDescription : ""}</StepComplete>;
  }
  return <>{step.step}</>;
};
