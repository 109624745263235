import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import { ucFirst } from "../../../utils/localization.utils";
import { Information } from "../../../components/Information";
import { Controller, UseFormReturn } from "react-hook-form";
import { FormSelectSingle } from "../../../components/form/FormSelectSingle";
import { CsvDefaultField } from "./CsvDefaultField";
import { InvoicesCSVCollectorRequest } from "../../../clients/collector.client";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { UploadCsvFieldResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);

interface CsvConfigFieldProps {
  field: UploadCsvFieldResponseDTO;
  configFieldDefaultIdFn: (key: string) => string;
  configFieldIndexIdFn: (key: string) => string;
  formMethods: UseFormReturn<InvoicesCSVCollectorRequest & { [key: string]: any }>;
  hasValue: boolean;
  hasDefaultValue: boolean;
  fieldSelectOptionsFn: (value: number) => any[];
  getMappedColumnValues: (field: string, count: number) => string[];
}

export const CsvConfigField = ({
  field,
  configFieldDefaultIdFn,
  configFieldIndexIdFn,
  formMethods,
  hasValue,
  hasDefaultValue,
  fieldSelectOptionsFn,
  getMappedColumnValues,
}: CsvConfigFieldProps) => {
  const classes = useStyles();

  const error =
    formMethods?.formState.errors?.invoice?.config?.fields &&
    // @ts-ignore - because the type of fields does not correspond with what useForm gives us and so we can't do key as keyof
    formMethods?.formState.errors?.invoice?.config?.fields[field.key] &&
    // @ts-ignore - because the type of fields does not correspond with what useForm gives us and so we can't do key as keyof
    formMethods?.formState.errors?.invoice?.config?.fields[field.key].index;
  return (
    <Grid container item spacing={2} key={`select-header-${field.key}`}>
      <Grid item xs={2}>
        <Box display={"flex"}>
          <Typography style={{ paddingRight: 8 }}>{`${ucFirst(field.name)}${
            field.isRequired ? " (required)" : ""
          }`}</Typography>
          <Information title={field.description} align={"left"} />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Controller
          render={({ field: { onChange, value, name } }) => (
            <FormSelectSingle
              error={!!error}
              emptyOption={true}
              optionValue={"label"}
              emptyValue={undefined}
              optionId={"idx"}
              value={value}
              label={ucFirst(field.name)}
              handleChange={onChange}
              options={fieldSelectOptionsFn(value as number)}
              id={name}
            />
          )}
          name={configFieldIndexIdFn(field.key)}
          control={formMethods.control}
          rules={
            field.isRequired && !hasDefaultValue
              ? {
                  required: "A column is required if no default is provided",
                }
              : {}
          }
        />
        {error ? <Typography className={classes.error}>{error.message}</Typography> : ""}
      </Grid>
      <Grid item xs={2}>
        {getMappedColumnValues(field.key, 2).map((it, idx) => (
          <Grid item key={`${configFieldIndexIdFn(field.key)}.${idx}`}>
            {it}
          </Grid>
        ))}
      </Grid>
      {field.allowDefault && (
        <Grid item xs={4}>
          <CsvDefaultField
            type={field.type}
            key={field.key}
            id={configFieldDefaultIdFn(field.key)}
            hasValue={hasValue}
            required={field.isRequired}
          />
        </Grid>
      )}
    </Grid>
  );
};
