import Grid from "@material-ui/core/Grid";
import { useRootOrganizations } from "../../clients/organizationalunit.client";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import { OrganizationalUnitPageTabbed } from "./OrganizationalUnitTabbed";

export function OrganizationalUnitPage() {
  const [organizationalUnits, refreshOrganizationalUnits] = useRootOrganizations();

  if (!organizationalUnits) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {
          <OrganizationalUnitPageTabbed
            organizationalUnits={organizationalUnits}
            refresh={refreshOrganizationalUnits}
          />
        }
      </Grid>
    </Grid>
  );
}
