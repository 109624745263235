import Typography from "@material-ui/core/Typography";
import { PipelineRunStatus } from "../../../../clients/collector.client";
import { Alert } from "@material-ui/lab";
import { css } from "@emotion/css";

const classes = css({
  paddingTop: 0,
  paddingBottom: 0,
});

interface PipelineStatusProps {
  status: PipelineRunStatus;
}

export function PipelineStatus({ status }: PipelineStatusProps) {
  switch (status) {
    case PipelineRunStatus.FINISHED:
      return (
        <Alert variant="outlined" className={classes} severity={"success"}>
          Finished
        </Alert>
      );
    case "ERROR":
      return (
        <Alert variant="filled" severity={"error"} className={classes}>
          Error
        </Alert>
      );
    case "IN_PROGRESS":
      return (
        <Alert className={classes} variant="outlined" severity={"info"}>
          In progress
        </Alert>
      );
    case PipelineRunStatus.NOTICE:
    case PipelineRunStatus.PAUSED:
      return (
        <Alert variant="filled" className={classes}>
          {status}
        </Alert>
      );
    case PipelineRunStatus.SCHEDULED:
    case PipelineRunStatus.COLLECTING:
    case PipelineRunStatus.WAITING:
    case PipelineRunStatus.PROCESSING:
    case PipelineRunStatus.TIMEOUT:
      return (
        <Alert className={classes} variant="filled" severity={"info"}>
          {status}
        </Alert>
      );
    case null:
    case undefined:
      return (
        <Alert className={classes} variant="outlined" severity={"warning"}>
          Inactive
        </Alert>
      );
    default:
      return <Typography>unknown status: {status}</Typography>;
  }
}
