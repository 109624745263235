import { createTheme } from "@material-ui/core/styles";
import { lime, lightBlue } from "@material-ui/core/colors";

export const themeLight = createTheme({
  palette: {
    primary: lime,
    secondary: lightBlue,
    type: "light",
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#424242",
        color: lime["500"],
      },
    },
    MuiTypography: {
      h1: {
        marginBottom: 16,
      },
      h2: {
        marginBottom: 16,
      },
      h3: {
        marginBottom: 16,
      },
      h4: {
        marginBottom: 16,
      },
      h5: {
        marginBottom: 16,
      },
      h6: {},
    },
  },
});
