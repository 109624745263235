import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CA_CONSENT } from "../../../../clients/collector.client";
import { MessagePage } from "../../../../app/MessagePage";
import { Link, Typography } from "@material-ui/core";
import { getLocalStorageValue, setLocalStorageValue } from "../../../../utils/localStorage.utils";

interface CARedirectHandlerProps {}

export const CARedirectHandler = ({ ...props }: CARedirectHandlerProps) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const admin_consent = params.get("admin_consent");
  const tenant = params.get("tenant");
  const message = params.get("message") ?? "";
  const error = params.get("error_description") ?? "";

  const [success, setSuccess] = useState<{
    title: string;
    msg?: string;
    error?: string;
    autoClose: boolean;
  }>({ title: "", autoClose: true });
  useEffect(() => {
    // Set values to localstorage
    const status = getLocalStorageValue("connector.status");
    if (status !== CA_CONSENT.STATUS.PENDING && status !== CA_CONSENT.STATUS.VERIFYING) {
      setSuccess({
        title: "Oops",
        msg: "It appears there is no active session to connect these credentials to. Please close this window and try again.",
        autoClose: false,
      });
    } else if (admin_consent !== "True") {
      setSuccess({
        title: "Something went wrong",
        msg: message,
        error: error,
        autoClose: false,
      });
    } else if (status === CA_CONSENT.STATUS.PENDING && tenant) {
      setLocalStorageValue("connector.status", "VERIFYING");
      setLocalStorageValue("connector.adDomain", tenant);
      setSuccess({
        title: "Successfully connected to the C-Facts application",
        autoClose: true,
      });
      setTimeout(window.close, 3000);
    } else if (status === CA_CONSENT.STATUS.VERIFYING) {
      setLocalStorageValue("connector.status", "VERIFIED");
      setSuccess({
        title: "Successfully given consent",
        autoClose: true,
      });
      setTimeout(window.close, 3000);
    } else {
      setSuccess({
        title: "Something unexpected happened",
        msg: message,
        error: error,
        autoClose: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessagePage>
      <Typography variant="h4">{success.title}</Typography>
      <Typography variant="h6">{success.msg}</Typography>
      <pre>{success.error}</pre>
      {success.autoClose && <Typography>This window will close automatically in a few seconds</Typography>}
      <Link onClick={() => window.close()}>Click here to close this window to go back immediately</Link>
    </MessagePage>
  );
};
