import {API} from "./context.service";
import {NotificationResponseDTO} from "../open-api";
import {messageError} from "./message.service";


export interface ActionCenterService {

  getNotifications(): Promise<Array<NotificationResponseDTO> | void>;

}

export class LiveActionCenterService implements ActionCenterService {
  constructor(private api: API) {}

  getNotifications = (): Promise<Array<NotificationResponseDTO> | void> => {
    return this.api.actionCenter.getNotifications()
    .then((value) => {
      this.api.actionCenter.readNotifications()
      .catch((e) => {
        messageError(e.message)
      });
      return value
       .sort((a, b) => String(a.read).localeCompare(String(b.read)) ||
           ( new Date(b.createdAt || "").valueOf() - new Date(a.createdAt|| "").valueOf()));
    })
    .catch((e) => {
      messageError(e.message)
    });
  }

}
