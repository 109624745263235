import { z } from "zod";
import { BatchCreationConfig } from "../AutomaticCustomerCreation.provider";

export const StoredBatchConfig = z.object({
  version: z.literal(1).default(1),
  collectors: z
    .array(
      z.object({
        collectorId: z.string().uuid(),
        rootFilter: z.string().optional(),
      })
    )
    .default([]),
});

export type StoredBatchConfig = z.infer<typeof StoredBatchConfig>;

export const internalizeBatchConfig = (config: StoredBatchConfig): BatchCreationConfig[] => config.collectors;

export const externalizeBatchConfig = (config: BatchCreationConfig[]): StoredBatchConfig => ({
  version: 1,
  collectors: config,
});
