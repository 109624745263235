import { formattedCurrency } from "../utils/localization.utils";
import { Tooltip, Typography } from "@material-ui/core";
import { useCurrency } from "../hooks/config.hooks";
import { ConvertedMoneyDTO } from "graphql-simple-bindings/money";
import Skeleton from "@material-ui/lab/Skeleton";
import { css } from "@emotion/css";

interface MoneyProps {
  money?: ConvertedMoneyDTO | number;
  digits?: number;
  currency?: string;
  isLoading?: boolean;
}

export const Money = ({ money, digits, currency, isLoading }: MoneyProps) => {
  const appCurrency = useCurrency();

  if (isLoading) {
    return <Skeleton className={css({ display: "inline-block" })} variant={"text"} width={80} component="span" />;
  }

  if (!money) {
    return null;
  }
  if (typeof money === "number") {
    return <span>{formattedCurrency(money, currency ?? appCurrency, digits)}</span>;
  }
  const isConverted = money.original.length > 1 || money.original[0].currency !== money.requested.currency;

  return (
    <Tooltip
      style={{ cursor: "default" }}
      title={money.original
        .filter((it) => it.amount !== 0)
        .map((it) => (
          <Typography align={"right"} key={it.currency}>
            {formattedCurrency(it.amount, it.currency, digits)}
          </Typography>
        ))}
    >
      <span style={{ fontStyle: isConverted ? "italic" : "inherit" }}>
        {formattedCurrency(money.requested.amount, money.requested.currency, digits)}
      </span>
    </Tooltip>
  );
};
