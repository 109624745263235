import { z } from "zod";
import { CREATE_AWS_CONFIG, useAwsCollectorParameters } from "../../../../clients/collector.client";
import { FormPartial } from "../../../../utils/form.utils";
import {
  ControlledSelectSingleField,
  ControlledSwitch,
  ControlledTextField,
} from "../../../../components/form/FormComponents";
import { FormTitleField } from "../../../../components/form/FormTitleField";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";

interface AWSCredentialsFormProps extends Required<FormPartial<z.infer<typeof awsSchema>>> {}

export const awsSchema = z
  .object({
    accessType: z
      .enum([CREATE_AWS_CONFIG.TYPE_KEYS.IAM_ROLE, CREATE_AWS_CONFIG.TYPE_KEYS.IAM_USER] as const)
      .default(CREATE_AWS_CONFIG.TYPE_KEYS.IAM_ROLE),
    enableCostReport: z.boolean(),
    remoteBucket: z
      .object({
        bucketName: z.string(),
        region: z.string(),
      })
      .optional(),
    roleToAssumeExternalId: z.string().optional(),
    roleToAssumeArn: z.string().optional(),
    accessKey: z.string().optional(),
    secretKey: z.string().optional(),
    cfactsaccountId: z.string().optional(),
  })
  .refine((data) => data.accessType === "IAM_USER" || data.roleToAssumeArn, {
    message: "This is required",
    path: ["roleToAssumeArn"],
  })
  .refine((data) => data.accessType === "IAM_USER" || data.roleToAssumeExternalId, {
    message: "This is required",
    path: ["roleToAssumeExternalId"],
  })
  .refine((data) => data.accessType === "IAM_ROLE" || data.accessKey, {
    message: "This is required",
    path: ["accessKey"],
  })
  .refine((data) => data.accessType === "IAM_ROLE" || data.secretKey, {
    message: "This is required",
    path: ["secretKey"],
  })
  .transform(({ roleToAssumeExternalId, roleToAssumeArn, accessKey, secretKey, ...rest }) => {
    if (rest.accessType === "IAM_USER") {
      return { accessKey, secretKey, ...rest };
    } else {
      return { roleToAssumeExternalId, roleToAssumeArn, ...rest };
    }
  })
  .transform(({ remoteBucket, ...rest }) => ({
    remoteBucket: rest.enableCostReport ? remoteBucket : undefined,
    ...rest,
  }));

export const AWSCredentialsFormPartial = ({ control, formMethods }: AWSCredentialsFormProps) => {
  const [parameters] = useAwsCollectorParameters();
  const { setValue } = formMethods;
  // Prepare parameters
  useEffect(() => {
    if (parameters) {
      setValue("cfactsaccountId", parameters.accountId);
    }
  }, [parameters, setValue]);

  // Prepare cost reporting switch and secret values
  const bucket = formMethods.watch("remoteBucket");
  useEffect(() => {
    if (bucket) {
      setValue("enableCostReport", true);
    }
  }, [setValue, bucket]);

  useEffect(() => {
    setValue("secretKey", "");
  }, [setValue]);

  const enableCostReport = formMethods.watch("enableCostReport");

  const selectedType = formMethods.watch("accessType");
  return (
    <Grid container spacing={2}>
      <FormTitleField text="Choose your access type" />
      <ControlledSelectSingleField
        options={CREATE_AWS_CONFIG.TYPE_OPTIONS}
        control={control}
        name={"accessType"}
        label={"Access type"}
      />
      <FormTitleField text="AWS Cost & Usage Report" />
      <Grid item xs={12}>
        <ControlledSwitch
          control={control}
          name={"enableCostReport"}
          label={"Enable AWS Cost & Usage Report processing"}
        />
      </Grid>
      {enableCostReport ? (
        <Grid item xs={12}>
          <ControlledTextField control={control} name={"remoteBucket.bucketName"} label={"Bucket name"} fullWidth />
          <ControlledTextField control={control} name={"remoteBucket.region"} label={"Bucket region"} fullWidth />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="warning">
            Without access to AWS Cost & Usage Reporting, C-facts is limited in the amount of data it can collect and
            process, such as being unable to associate more than 1 tag to each resource.
          </Alert>
        </Grid>
      )}
      {selectedType === CREATE_AWS_CONFIG.TYPE_KEYS.IAM_ROLE && (
        <>
          <FormTitleField text="Create an AWS role using the following information" />
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"cfactsaccountId"}
              label={"Account ID"}
              fullWidth
              inputProps={{ readOnly: true }}
            />
            <ControlledTextField
              control={control}
              name={"roleToAssumeExternalId"}
              label={"Role ID"}
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <FormTitleField text="Created role ARN" />
          <Grid item xs={12}>
            <ControlledTextField control={control} name={"roleToAssumeArn"} label={"Role ARN"} fullWidth />
          </Grid>
        </>
      )}
      {selectedType === CREATE_AWS_CONFIG.TYPE_KEYS.IAM_USER && (
        <>
          <FormTitleField text="Credentials" />
          <Grid item xs={12}>
            <ControlledTextField control={control} name={"accessKey"} label={"Access key"} fullWidth />
            <ControlledTextField control={control} name={"secretKey"} label={"Secret key"} fullWidth />
          </Grid>
        </>
      )}
    </Grid>
  );
};
