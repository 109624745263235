import { Context, Dispatch, SetStateAction, useContext } from "react";

export type DataClientContext<T> = [T | null, () => void, boolean] | undefined;
export type StateContext<T> = readonly [T, Dispatch<SetStateAction<T>>] | undefined;

export function useContextOrThrow<T>(ContextComponent: Context<T | undefined>): T {
  const context = useContext(ContextComponent);
  if (context == null) {
    throw new Error(`You forget to wrap your component in a <${ContextComponent.displayName}.Provider>`);
  }
  return context;
}
