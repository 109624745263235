import {
  BatchProjectionResponseDTO,
  CreateBatchProjectionRequestDTO,
  ListOptionsForBatchProjectionsRequestDTO,
  ProjectionResponseDTO,
} from "../open-api";
import { API } from "./context.service";
import { externalizeBatchConfig, StoredBatchConfig } from "../pages/organizationalunit/batch/model/StoredBatchConfig";

export interface ProjectionsService {
  getAll(): Promise<ProjectionResponseDTO[]>;

  getBatchOptions(dto: ListOptionsForBatchProjectionsRequestDTO[]): Promise<BatchProjectionResponseDTO>;

  batchUpdate(request: CreateBatchProjectionRequestDTO[]): Promise<void>;

  getConfig(type: string): Promise<StoredBatchConfig | null>;

  saveConfig(type: string, config: StoredBatchConfig): Promise<void>;
}

export class LiveProjectionsService implements ProjectionsService {
  constructor(private api: API) {}

  getAll = () => this.api.projections.getPossibleProjections();

  getBatchOptions = (dto: ListOptionsForBatchProjectionsRequestDTO[]) =>
    this.api.batchProjections.listOptionsForBatchProjections({ listOptionsForBatchProjectionsRequestDTO: dto });

  batchUpdate = (request: CreateBatchProjectionRequestDTO[]) => {
    return this.api.batchProjections.batchProjections({
      createBatchProjectionRequestDTO: request,
    });
  };
  getConfig = async (type: string) => {
    try {
      const res = await this.api.config.findForParameter({
        type: "BATCH_CONFIG",
        param: type,
      });

      if (res) {
        return StoredBatchConfig.parse(res.config);
      } else {
        return externalizeBatchConfig([]);
      }
    } catch (e) {
      return externalizeBatchConfig([]);
    }
  };

  saveConfig = async (type: string, config: StoredBatchConfig) => {
    await this.api.config.updateForParameter({
      type: "BATCH_CONFIG",
      param: type,
      createConfigRequestDTO: {
        config: config,
        shared: true,
      },
    });
  };
}

export class TestProjectionsService implements ProjectionsService {
  getAll = async () => {
    const createProjection = (dataSource: string, customer: string) => ({
      key: `${dataSource}|${customer}|<ANY>`,
      filter: {
        account: { value: dataSource, displayValue: dataSource },
        customerTag: { value: customer, displayValue: customer },
        applicationTag: { value: "<ANY>", displayValue: "<ANY>" },
      },
      mappedTo: [],
      active: true,
    });

    return [
      createProjection("Azure <<name>> customer", "Nike inc"),
      createProjection("Azure <<name>> customer", "Nike Bv"),
      createProjection("Azure <<name>> customer", "Puma"),
      createProjection("Azure <<name>> customer", "My Runners"),
      createProjection("Azure <<name>> customer", "Nike"),
      createProjection("Aws <<name>> customer", "Puma"),
      createProjection("Aws <<name>> customer", "Nike"),
      createProjection("Aws <<name>> customer", "Offbrand"),
      createProjection("Aws <<name>> customer", "Converse"),
    ];
  };

  getBatchOptions = async (): Promise<BatchProjectionResponseDTO> => ({
    collectors: [],
    organizations: [],
  });

  batchUpdate = async (request: CreateBatchProjectionRequestDTO[]) => {};

  getConfig = (type: string) => null as any;
  saveConfig = (type: string, config: StoredBatchConfig) => Promise.resolve();
}
