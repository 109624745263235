import { Accordion, Typography } from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ActionIconButton } from "../buttons/ActionIconButton";
import { CFactsAction } from "../buttons/CFactsAction";
import { ChangeEvent, ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  headingFlex: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "28%",
    flexShrink: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexGrow: 1,
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  root: {
    marginBottom: 0,
  },
  accordionDetails: {
    paddingTop: 0,
    marginBottom: 0,
  },
  summary: {
    marginBottom: theme.spacing(1),
  },
}));

export interface AccordionItem {
  summaryMain: string;
  id: string;
  summarySub?: string;
  details?: ReactNode;
  action?: CFactsAction | false;
}

export interface AccordionItemProps {
  item: AccordionItem;
  onChange?: (event: ChangeEvent<{}>, expanded: boolean) => void;
  expanded?: boolean;
  expandIcon?: ReactNode;
}

export const AccordionItemList = ({ item, onChange, expanded, expandIcon }: AccordionItemProps) => {
  const classes = useStyles();

  return (
    <Accordion expanded={expanded} onChange={onChange} className={classes.root}>
      <AccordionSummary
        aria-controls={item.id}
        id={item.id}
        expandIcon={expandIcon || <ExpandMoreIcon />}
        classes={{ root: classes.summary }}
      >
        <Typography className={item.summarySub ? classes.headingFlex : classes.heading}>{item.summaryMain}</Typography>
        {item.summarySub ? <Typography className={classes.secondaryHeading}>{item.summarySub}</Typography> : ""}
        {item.action && (
          <ActionIconButton
            className={classes.button}
            action={{
              ...item.action,
              onClick: (e) => {
                e.stopPropagation();
                if (item.action) {
                  // Weird typescript quirk
                  item.action.onClick(e);
                }
              },
            }}
            size={15}
          />
        )}
      </AccordionSummary>
      {item.details ? (
        <AccordionDetails classes={{ root: classes.accordionDetails }}>{item.details}</AccordionDetails>
      ) : (
        ""
      )}
    </Accordion>
  );
};
