import { CollectorStats } from "./CollectorStats";
import { DataSourceResponseDTO } from "../../clients/collector.client";
import { CollectorAttributes } from "./config/CollectorAttributes";
import { CollectorAws } from "./config/CollectorAws";
import { CollectorAzureCredentials, CollectorConfigResponseDTO } from "../../open-api";
import { CollectorPriceAdjustments } from "./config/CollectorPriceAdjustments";

interface DataSourceOverviewProps {
  collectorId: string;
  collector: DataSourceResponseDTO;
  collectorConfig: CollectorConfigResponseDTO | null;
  onUpdateConfig: () => void;
}

export function DataSourceOverview({
  collector,
  collectorId,
  collectorConfig,
  onUpdateConfig,
}: DataSourceOverviewProps) {
  return (
    <>
      <CollectorStats collector={collector} collectorId={collectorId} collectorConfig={collectorConfig} />
      {collector.type === "AZURE" && (collector.credentials as CollectorAzureCredentials).accountType === "CA" && (
        <CollectorPriceAdjustments collectorId={collectorId} />
      )}
      <CollectorAttributes collector={collector} collectorConfig={collectorConfig} onUpdate={onUpdateConfig} />
      {collectorConfig?.aws && (
        <CollectorAws collector={collector} collectorConfig={collectorConfig} onUpdate={onUpdateConfig} />
      )}
    </>
  );
}
