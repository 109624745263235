import { Grid } from "@material-ui/core";
import { FastFactCustomers } from "./types/FastFactCustomers";
import { FastFactCloudRevenue } from "./types/FastFactCloudRevenue";
import { FastFactTotal } from "./types/FastFactTotal";
import { segmentPerArgument, segmentTotal } from "../../newdash/model/Query";
import { DISPLAY_SEGMENT } from "../../newdash/model/ChartConfig";
import { LineItemQueryResponseDTO } from "../../../open-api";
import { FastFactSkeleton } from "./FastFact";

interface FastFactsProps {
  items: LineItemQueryResponseDTO[] | null;
  loading: boolean;
}

export function FastFacts({ items, loading }: FastFactsProps) {
  if (loading) {
    return (
      <Grid container spacing={1}>
        <FastFactSkeleton />
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <FastFactTotal items={segmentTotal(DISPLAY_SEGMENT.COST, items)} />
      <FastFactCustomers />
      <FastFactCloudRevenue items={segmentPerArgument(DISPLAY_SEGMENT.COST, items)} />
    </Grid>
  );
}
