import { chartSeriesObject } from "devextreme/viz/chart";
import { css } from "@emotion/css";
import { absoluteCenter } from "../../../utils/styles";
import { Alert } from "@material-ui/lab";
import { Chart } from "devextreme-react";
import { Series } from "devextreme-react/chart";
import { useRef, useState } from "react";
import { useChartFormat } from "./DashPieChart";
import { cleanDisplaySegmentFromString, RangeChartConfig } from "../model/ChartConfig";
import { event } from "devextreme/events";
import { escapeHtml, findParent } from "../../../utils/dom";
import { useInvoicePeriods } from "../../../clients/billing-periods.client";
import { Box, CircularProgress } from "@material-ui/core";
import { ChartMenu } from "./ChartMenu";
import { useArgumentTitle, useChartTitles } from "../selectors/ChartConfig";
import { useChartFilterToggle } from "../events/GroupSegmentFilter.events";
import { attributeId } from "../model/Attribute";
import { assertNever } from "../../../utils/assert";
import { useAttributeColorGenerator } from "../model/ColorCache";
import { aggregateToChart, useAggregatedLineItemsForDashboard } from "../model/Query";
import { useDateRange } from "../selectors/dashboard.selector";
import { toBillingMonths } from "../model/DateRange";
import { useDashboardShouldLoad } from "../NewDashPageProvider";

interface LineChartProps {
  config: RangeChartConfig;
}

export function DashLineChart({ config }: LineChartProps) {
  const { groupSegment, displaySegment, type, argument } = config;
  const ref = useRef<Chart>(null);
  const format = useChartFormat(displaySegment);
  const billingPeriods = useInvoicePeriods();
  const { title, subTitle } = useChartTitles(config);
  const [dirty, setDirty] = useState(false);
  const filterToggle = useChartFilterToggle();
  const argumentTitle = useArgumentTitle(config);
  const colorGenerator = useAttributeColorGenerator();
  const [range] = useDateRange();
  // TODO: Add support for invoice periods?
  const billingMonths = toBillingMonths(range);

  const [items, , loading] = useAggregatedLineItemsForDashboard(
    groupSegment,
    argument,
    20,
    false,
    config.displaySegment.key
  );

  const shouldLoad = useDashboardShouldLoad();

  const isLine = argument.id === "date" || argument.id === "BILLING_MONTH";

  const [dataSource, series] = aggregateToChart(
    [config.displaySegment],
    items ? items[0] : undefined,
    isLine ? billingMonths : undefined
  );

  const legendClickHandler = (event: { target?: chartSeriesObject }) => {
    const series = event.target;
    if (series == null) return;

    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
    setDirty(true);
  };

  const handleSeriesClick = (event: { target?: chartSeriesObject; event?: event }) => {
    // The series handler also fires when there is a legend click. This hackery prevents that.
    const target = event.event != null && "target" in event.event ? event.event.target : null;
    if (!(target instanceof Element)) return;
    if (findParent(target, (it) => it.classList.contains("dxc-series")) == null) return;

    const series = event.target;
    if (series == null) return;
    const seriesKey: string = (series as any).getValueFields()[0];
    const cleanedKey = cleanDisplaySegmentFromString(seriesKey);

    series.show();
    filterToggle.onFilterToggled({ groupSegment: groupSegment, value: { id: cleanedKey, label: series.name } });
    setDirty(true);
  };

  // If there is one key, it is the billingMonth key, so it has no values.
  const emptyDatasource = !loading && items && items[0] && items[0].arguments.length === 0;

  return (
    <Box className={css({ display: "flex", position: "relative" })}>
      <Box className={css({ marginTop: 36, position: "relative" })}>
        {loading && (
          <div className={css(absoluteCenter)}>
            <CircularProgress size={45} />
          </div>
        )}
        {emptyDatasource && (
          <div className={css(absoluteCenter)}>
            <Alert style={{ width: "320px" }} variant="outlined" severity="warning">
              No data available to display this graph.
            </Alert>
          </div>
        )}
        <Chart
          ref={ref}
          disabled={loading || !shouldLoad}
          size={{ width: 1000, height: 400 }}
          palette="Bright"
          dataSource={dataSource}
          title={{
            text: escapeHtml(title),
            font: { color: "white" },
            subtitle: { text: escapeHtml(subTitle) },
          }}
          legend={{ verticalAlignment: "top", horizontalAlignment: "right" }}
          zoomAndPan={{ argumentAxis: "both", valueAxis: "both" }}
          onLegendClick={legendClickHandler}
          tooltip={{
            enabled: true,
            format: format({ short: false }),
            customizeTooltip: (arg) => ({ text: `${arg.seriesName} - ${arg.valueText}` }),
          }}
          onSeriesClick={handleSeriesClick}
          commonSeriesSettings={{ argumentField: "argument", type }}
          commonAxisSettings={{ grid: { visible: true } }}
          argumentAxis={{
            title: { text: argumentTitle },
            label: {
              customizeText: ({ value, valueText }) => {
                if (typeof value !== "string") return "";
                // if (argument.type === "attribute") return value;
                // if (argument.type === "attribute") {
                //   return (
                //     getAttributeValue(argument, [], value as string)?.displayValue ?? escapeHtml(emptyAttribute.label)
                //   );
                // }
                if (argument.type === "billingCycle")
                  return billingPeriods.find((it) => it.id === value)?.anchorDateDisplay ?? "Bla";
                if (argument.type === "attribute" || argument.type === "billingPeriod") {
                  const attr = items && items[0] && items[0].arguments.find((arg) => arg.argId === value)?.argDisplay;
                  return escapeHtml(attr || valueText || value);
                }

                return assertNever(argument);
              },
            },
          }}
          margin={{ bottom: 20 }}
        >
          {series.map((item) => (
            <Series
              key={item.key}
              valueField={item.key}
              name={item.label}
              color={colorGenerator(attributeId(config.groupSegment), item.key)}
            />
          ))}
        </Chart>
      </Box>
      <Box className={css({ position: "absolute", top: 0, right: 0 })}>
        <ChartMenu
          chartRef={ref}
          chartConfig={config}
          onReset={() => {
            filterToggle.onReset({ groupSegment: config.groupSegment });
          }}
          dirty={[dirty, setDirty]}
        />
      </Box>
    </Box>
  );
}
