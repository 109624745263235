import { MouseEvent, useState } from "react";
import { addGroupSegmentFilter } from "../model/GroupSegmentFilter";
import { GroupSegment } from "../model/GroupSegment";
import { Button, CircularProgress, ListSubheader, Menu, MenuItem, useTheme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useDashFilters } from "../selectors/dashboard.selector";
import { css } from "@emotion/css";
import { useServices } from "../../../services/context.service";
import { QueryResponseAttribute } from "../../../open-api";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useRangeAsFilter } from "../model/Query";

export function AddFilter({ onSegmentAdded }: { onSegmentAdded: (segment: GroupSegment) => void }) {
  const theme = useTheme();
  const queryService = useServices().aggregate;
  const [filters, setFilters] = useDashFilters();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rangeFilter = useRangeAsFilter();

  const noEmptyFilters = filters
    .filter((it) => it.filters.length > 0)
    .map((it) => ({ dimension: it.segment.id, filters: it.filters.map((it) => it.id) }));

  const [loadedOptions, , loading] = useDataClientPromise(queryService.keys, [[rangeFilter, ...noEmptyFilters]], {
    useCacheKey: "keys",
    shouldLoad: anchorEl != null,
  });
  const options = loadedOptions ?? [];

  return (
    <>
      <Button
        size={"large"}
        startIcon={<AddIcon />}
        color="primary"
        className={css({ height: 56, marginRight: theme.spacing(2), marginBottom: theme.spacing(2) })}
        variant={"outlined"}
        aria-controls="add-segment-filter"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        Add filter
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {loading && (
          <CircularProgress
            className={css({
              position: "sticky",
              top: "50%",
              left: "50%",
              marginTop: -12,
              marginLeft: -12,
            })}
            size={24}
          />
        )}
        {options.length === 0 && <MenuItem disabled={true}>{loading ? "Loading.." : "No options"}</MenuItem>}
        {options.flatMap((it) => [
          <ListSubheader key={it.category.collectorId || it.category.category}>
            {it.category.display || it.category.collectorId}
          </ListSubheader>,
          ...it.attributes
            // Remove billing month filter since it is already above the dashboard.
            .filter((attr) => attr.id !== "BILLING_MONTH" && attr.id !== "BILLING_CYCLE") // TODO: Change billing cycle to somewhere else on the page
            // Also filter every attribute that is already added
            .filter((attr) =>
              filters.every(
                (filter) => !(filter.segment.id === attr.id && filter.segment.collectorId === it.category.collectorId)
              )
            )
            .map((attr) => (
              <MenuItem
                value={attr.display}
                key={`${it.category.collectorId || it.category.category}|${attr.id}`}
                onClick={() => handleSegmentSelected(attr, it.category.display, it.category.collectorId)}
                className={css({ color: loading ? theme.palette.text.disabled : undefined })}
              >
                {attr.display}
              </MenuItem>
            )),
        ])}
      </Menu>
    </>
  );

  function handleSegmentSelected(attr: QueryResponseAttribute, categoryLabel?: string, collectorId?: string) {
    setAnchorEl(null);

    // const newGroupSegment: QueryResponseAttribute = options.find(it => it.key === key)!
    // TODO: Make sure it is an actual datasource here by checking for UUID regex?
    const newGroupSegment: GroupSegment = {
      type: "attribute",
      id: attr.id!,
      label: attr.display!,
      collectorId: collectorId,
      categoryLabel: categoryLabel,
      category: collectorId ? "DATA_SOURCE_ATTRIBUTE" : "CF_ATTRIBUTE",
    };
    onSegmentAdded(newGroupSegment);
    setFilters(addGroupSegmentFilter(filters, { segment: newGroupSegment, filters: [] }));
  }

  function handleOpen(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
}
