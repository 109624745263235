import { RefObject, useEffect, useState } from "react";
import Chart from "devextreme-react/chart";
import { PieChart } from "devextreme-react";
import { ChartConfig } from "../model/ChartConfig";
import { useDashCharts } from "../selectors/dashboard.selector";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Portal,
  TextField,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import TitleIcon from "@material-ui/icons/Title";
import { Image, Print } from "@material-ui/icons";
import { css } from "@emotion/css";
import { Model } from "../../../model/Model";
import { useChartTitles } from "../selectors/ChartConfig";
import { UseState } from "../../../hooks/state.hooks";

export function ChartMenu({
  chartRef,
  chartConfig,
  onReset,
  dirty: [dirty, setDirty],
}: {
  chartRef: RefObject<Chart | PieChart>;
  chartConfig: ChartConfig;
  onReset?: () => void;
  dirty: UseState<boolean>;
}) {
  const [charts, setCharts] = useDashCharts();
  const { title: defaultTitle } = useChartTitles(chartConfig);
  const [title, setTitle] = useState(defaultTitle);
  const [editingTitle, setEditingTitle] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const instance = chartRef?.current?.instance;

  useEffect(() => {
    if (editingTitle) setTitle(defaultTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingTitle]);

  return (
    <div>
      {dirty && (
        <IconButton>
          <RefreshIcon
            onClick={async () => {
              onReset?.();
              instance?.refresh();
              setDirty(false);
              onClose();
            }}
          />
        </IconButton>
      )}
      <IconButton aria-controls="long-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={onClose}
        MenuListProps={{ dense: true }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            onRemoveGraph(chartConfig);
            onClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setEditingTitle(true);
            onClose();
          }}
        >
          <ListItemIcon>
            <TitleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit title</ListItemText>
        </MenuItem>

        {chartConfig.type !== "raw" && [
          <Divider />,

          <MenuItem
            onClick={() => {
              instance?.print();
              onClose();
            }}
          >
            <ListItemIcon>
              <Print fontSize="small" />
            </ListItemIcon>
            <ListItemText>Print</ListItemText>
          </MenuItem>,
          <Divider />,
          <MenuItem
            onClick={() => {
              instance?.exportTo("chart", "svg");
              onClose();
            }}
          >
            <ListItemIcon>
              <Image fontSize="small" />
            </ListItemIcon>
            <ListItemText>Export to svg</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => {
              instance?.exportTo("chart", "png");
              onClose();
            }}
          >
            <ListItemIcon>
              <Image fontSize="small" />
            </ListItemIcon>
            <ListItemText>Export to png</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => {
              instance?.exportTo("chart", "jpeg");
              onClose();
            }}
          >
            <ListItemIcon>
              <Image fontSize="small" />
            </ListItemIcon>
            <ListItemText>Export to jpeg</ListItemText>
          </MenuItem>,
        ]}
      </Menu>
      <Portal>
        <Dialog open={editingTitle} onClose={() => setEditingTitle(false)}>
          <DialogContent>
            <TextField
              className={css({ width: "400px" })}
              autoFocus
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              label="Chart title"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditingTitle(false)}>Cancel</Button>
            <Button onClick={onTitleReset}>Reset</Button>
            <Button variant={"contained"} color={"primary"} onClick={onSave}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </div>
  );

  function onTitleReset() {
    setCharts((charts) => Model.update(charts, { ...chartConfig, title: undefined }));
    setEditingTitle(false);
  }

  function onSave() {
    setCharts((charts) => Model.update(charts, { ...chartConfig, title: title }));
    setEditingTitle(false);
  }

  function onRemoveGraph(chart: ChartConfig) {
    setCharts(charts.filter((it) => it !== chart));
  }

  function onClose() {
    setAnchorEl(null);
  }
}
