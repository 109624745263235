import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { messageError, messageSuccess } from "../../../services/message.service";
import { ControlledTextField } from "../../../components/form/FormComponents";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { RouterLink } from "../../../components/navigation/RouterLink";
import { useServices } from "../../../services/context.service";
import { loginRequest } from "../config/authConfigDefault";
import { PublicClientApplication } from "@azure/msal-browser";

export function SSOComponent() {
  const ssoService = useServices().sso;

  const { handleSubmit, control } = useCFForm({
    schema: z.object({
      email: z.string().email(),
    }),
  });

  const queryParams = new URLSearchParams(window.location.search);
  const predefinedValue = queryParams.get("email");

  const handleSso = async (email: string) => {
    try {
      const azureSsoConfig = await ssoService.ssoLogin(email);

      const msalConfigForUser = {
        auth: {
          clientId: azureSsoConfig.applicationId,
          authority: `https://login.microsoftonline.com/${azureSsoConfig.adDomain}/`,
          redirectUri: `${window.location.protocol}//${window.location.host}/login/`,
        },
      };
      const msalInstance = new PublicClientApplication(msalConfigForUser);
      const authResult = await msalInstance.loginPopup(loginRequest);

      const name = authResult.account?.idTokenClaims?.name || "";
      const authResultGroups: any = authResult.account?.idTokenClaims?.groups;
      const username = authResult.account?.username || "";

      await ssoService.ssoAuthenticate(name, email, username, authResultGroups);

      messageSuccess("Successfully logged in via Azure AD SSO");
      window.location.href = "/login";
    } catch (error: any) {
      messageError(`Failed to authenticate via Azure SSO: ${error.message}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(({ email }) => handleSso(email))}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <LockOpenIcon style={{ fontSize: 80 }} />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name={"email"}
            label={"Azure AD E-mail"}
            fullWidth
            defaultValue={predefinedValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" color="primary" autoFocus>
            Sign in with SSO
          </Button>
        </Grid>
        <Grid item xs={12}>
          <RouterLink to={"/login"}>Sign in with C-Facts account instead</RouterLink>
        </Grid>
      </Grid>
    </form>
  );
}
