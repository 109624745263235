import { useDashboards, useSetDashboards, useSetSelectedDashboardId } from "../NewDashPageProvider";
import { useCallback } from "react";
import { Model } from "../../../model/Model";
import { createDashboard, Dashboard } from "../model/Dashboard";
import { UseState } from "../../../hooks/state.hooks";
import { useServices } from "../../../services/context.service";
import { DateTime } from "luxon";
import { SaveDashboardForm } from "../components/SaveDashboard";
import * as uuid from "uuid";
import { externalizeDashboard, internalizeDashboard, internalizeDashboards } from "../model/StoredDashboard";
import { ZodError } from "zod";
import { useCanEdit } from "../../../hooks/auth.hooks";

export function useOnAddTab() {
  const setDashboards = useSetDashboards();
  const setSelectedDashboardId = useSetSelectedDashboardId();
  return useCallback(() => {
    const newDashboard = { ...createDashboard(), editing: true };
    setDashboards((state) => Model.create(state, newDashboard));
    setSelectedDashboardId(newDashboard.id);
  }, [setDashboards, setSelectedDashboardId]);
}

export function useOnRemoveDashboard() {
  const services = useServices();
  const [, setDashboards] = [useDashboards(), useSetDashboards()];

  return useCallback(
    async (dashboard: Dashboard) => {
      if (dashboard.savedAt == null) {
        return setDashboards((state) => Model.remove(state, dashboard.id));
      }
      setDashboards((state) => Model.update(state, { ...dashboard, removing: true }));
      try {
        await services.dashboard.delete(dashboard.id);
        setDashboards((state) => Model.remove(state, dashboard.id));
      } catch {
        setDashboards((state) => Model.update(state, { ...dashboard, removing: false }));
      }
    },
    [services.dashboard, setDashboards]
  );
}

export function useOnSaveDashboard([, setForm]: UseState<SaveDashboardForm>, board: Dashboard) {
  const { dashboard } = useServices();
  const setDashboards = useSetDashboards();

  return useCallback(async () => {
    setForm((it) => ({ ...it, submitting: true }));
    try {
      const oldDashboard = { ...board, saving: true };
      setDashboards((state) => Model.update(state, oldDashboard));

      const savedDashboard = { ...board, savedAt: DateTime.local(), saving: false };
      await dashboard.update(externalizeDashboard(savedDashboard));
      setDashboards((state) => Model.update(state, savedDashboard));
      setForm((state) => ({ ...state, submitting: false, status: "success" }));
    } catch (e) {
      setDashboards((state) => Model.update(state, { ...board, saving: false }));
      if (e instanceof ZodError) {
        const message = e.errors[0].message;
        setForm((state) => ({ ...state, submitting: false, status: "error", message }));
      } else {
        setForm((state) => ({ ...state, submitting: false, status: "error" }));
        // rethrow for logging
        throw e;
      }
    }
  }, [dashboard, setDashboards, board, setForm]);
}

export function useOnDiscardAll() {
  const services = useServices();
  const setDashboards = useSetDashboards();

  return async () => {
    const dashboards = await services.dashboard.getAll();
    setDashboards(internalizeDashboards(dashboards));
  };
}

export function useOnSaveAll() {
  const services = useServices();
  const dashboards = useDashboards();
  const setDashboards = useSetDashboards();

  return async () => {
    const oldDashboards = dashboards.map((board) => ({ ...board, saving: true }));
    const newDashboards = dashboards.map((board) => ({ ...board, saving: false, savedAt: DateTime.local() }));

    setDashboards(oldDashboards);
    await Promise.all(newDashboards.map((it) => services.dashboard.update(externalizeDashboard(it))));
    setDashboards(newDashboards);
  };
}

export function useOnSaveAs() {
  const setDashboards = useSetDashboards();
  const setSelectedDashboardId = useSetSelectedDashboardId();
  const services = useServices();

  return async (dashboard: Dashboard) => {
    const newDashboard = { ...dashboard, id: uuid.v4(), editing: true, title: "" };
    setDashboards((state) => Model.create(state, newDashboard));
    setSelectedDashboardId(newDashboard.id);

    // discard changes of old dashboard
    const oldDashboard = Model.get(await services.dashboard.getAll(), dashboard.id);
    if (oldDashboard != null) {
      setDashboards((state) => Model.update(state, internalizeDashboard(oldDashboard)));
    }
  };
}

export function useShareDashboard(): [boolean, (dashboard: Dashboard) => Promise<void>] {
  const dashService = useServices().dashboard;
  const setDashboards = useSetDashboards();
  const isAdmin = useCanEdit();

  const flipShare = (dashboard: Dashboard) =>
    dashboard.shared
      ? dashService
          .unshare(dashboard.id)
          .then((it) => setDashboards((state) => Model.update(state, { ...dashboard, shared: false })))
      : dashService
          .share(dashboard.id)
          .then((it) => setDashboards((state) => Model.update(state, { ...dashboard, shared: true })));

  return [isAdmin, flipShare];
}

export function useOnEditTitle() {
  const setDashboards = useSetDashboards();

  return (dashboard: Dashboard) => {
    setDashboards((state) => Model.update(state, { ...dashboard, editing: true }));
  };
}
