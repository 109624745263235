import { Button, ButtonProps } from "@material-ui/core";
import { MouseEvent, ReactNode } from "react";

interface ButtonConfirmProps extends ButtonProps {
  isSubmitting?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
}

export function ButtonConfirm({
  isSubmitting = false,
  autoFocus = true,
  onClick,
  children,
  ...props
}: ButtonConfirmProps) {
  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      autoFocus={autoFocus}
      disabled={isSubmitting}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
