import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Control } from "react-hook-form/dist/types";
import { ControlledSelectSingleField, ControlledTextField } from "../../../components/form/FormComponents";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

interface UserCreateFormProps {
  control: Control<{ email: string; name: string; role: "ADMIN" | "READ" }>;
}

export function UserCreateForm({ control }: UserCreateFormProps) {
  const classes = useStyles();
  const options = [
    {
      value: "ADMIN",
      label: "Admin",
    },
    {
      value: "READ",
      label: "Read",
    },
  ];
  return (
    <Grid container>
      <Grid item xs={12} className={classes.container}>
        <ControlledTextField control={control} name="email" label="E-mail" />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <ControlledTextField control={control} name="name" label={"Name"} />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <ControlledSelectSingleField options={options} control={control} name="role" label={"Role"} />
      </Grid>
    </Grid>
  );
}
