import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import { useApiCall } from "../../../hooks/client.hooks";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-hook-form";
import { FormTextField } from "../../../components/form/FormTextField";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { createUploadCollector, DataSourceResponseDTO } from "../../../clients/collector.client";
import { CreateCollectorUploadRequestDTO } from "../../../open-api";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(1),
  },
}));

interface UploadDataSourceFormProps {
  onSuccess: (ds: DataSourceResponseDTO) => void;
  onError: (msg: string) => void;
  onBack: () => void;
  onClose: () => void;
  buttonCancel: boolean;
}

export function UploadDataSourceForm({
  onSuccess,
  onError,
  onBack,
  onClose,
  buttonCancel = true,
}: UploadDataSourceFormProps) {
  const classes = useStyles();

  const [submit, isSubmitting] = useApiCall(createUploadCollector);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<CreateCollectorUploadRequestDTO>();

  const handleSave = (form: CreateCollectorUploadRequestDTO) => {
    submit(form).then(onSuccess).catch(onError);
  };

  return (
    <form
      onSubmit={handleSubmit((form) => {
        handleSave(form);
      })}
    >
      <DialogContent className={classes.content}>
        <Grid container>
          <FormTitleField text="Data Source" />
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <FormTextField
                error={errors.name}
                silent
                label="Name"
                id="name"
                name="name"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormTitleField text="Configuration" />
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                error={errors.accountId}
                silent
                label="Unique identifier for this data source"
                placeholder="csv_provider_x"
                id="accountId"
                name="accountId"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                error={errors.source}
                silent
                label="Cloud source"
                placeholder="AWS, AZURE, ORACLE"
                id="source"
                name="source"
                inputRef={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Previous</Button>
        {buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
