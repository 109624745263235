import React from "react";
import {ZodString} from "zod";
import {Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ControlledTextField} from "../../../components/form/FormComponents";
import {transformMFA} from "../../../utils/form.utils";
import {GetMFASecretDTO} from "../../../open-api";
import {Control} from "react-hook-form/dist/types";
import {DialogTitleCloseable} from "../../../components/DialogTitleCloseable";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";


export class MFAToggleComponent extends React.Component<{
  open: boolean, onClose: () => void,
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>, mfaProps: GetMFASecretDTO | null,
  control: Control<{ code: ZodString["_output"]; secret: ZodString["_output"] }, any>
}> {
  render() {
    return (
        <>
          <form onSubmit={this.props.onSubmit}>
            <DialogTitleCloseable onClose={this.props.onClose}>Confirm enabling MFA</DialogTitleCloseable>
            <DialogContent>
              {this.props.mfaProps && (
                  <Grid container spacing={1}>
                    <Grid container item xs={12} justifyContent={"center"}>
                      <img src={this.props.mfaProps.qrUrl} alt={`MFA Secret: ${this.props.mfaProps.secret}`}/>
                    </Grid>
                    <Grid container item xs={12} justifyContent={"center"}>
                      <Typography variant={"subtitle1"} align={"center"}>
                        {this.props.mfaProps.secret}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Scan the QR Code above with your favorite authenticator app, and enter the resulting code to verify
                        it.
                      </Typography>
                    </Grid>
                    <ControlledTextField type={"hidden"} control={this.props.control} name={"secret"}/>
                    <Grid container item xs={12} justifyContent={"center"}>
                      <ControlledTextField
                          transform={transformMFA}
                          control={this.props.control}
                          name={"code"}
                          placeholder="______"
                          label={"One time token"}
                          autoFocus
                      />
                    </Grid>
                  </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onClose}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Enable 2FA
              </Button>
            </DialogActions>
          </form>
        </>)
  }
}
