import { ExtendedLineItemDTO, NestedLineItemDTO } from "graphql-simple-bindings/lineitems";
import { range } from "../../../utils/functional/range.utils";
import { yearMonth } from "../../../utils/functional/date.utils";
import { DateTime } from "luxon";
import { multi, pick } from "../../../utils/functional/generator.utils";
import { Random, RandomG } from "../../../utils/random.utils";
import { attributesMock } from "./Attribute.mock";

export const defaultLineItem: ExtendedLineItemDTO = {
  type: "UNMAPPED",
  organization: "Unassigned",
  organizationRef: "-",
  department: "Unassigned",
  departmentRef: "-",
  customerTag: "Cloud Allstars test",
  applicationTag: "Webapp",
  location: "west-europe",
  costType: "Usage",
  service: "Storage",
  detail: "Files | LRS Data Stored | 1 GB/Month",
  cloud: "AZURE",
  invoice: "DEMO_AZURE_2020-11",
  datasource: "Azure demo",
  accountId: "AZURE_asdfas",
  billingMonth: "2020-11",
  invoicePeriod: "2020-10-16|2020-11-15",
  currency: "GBP",
  quantity: 1,
  attributes: {},
  costs: {
    cost: {
      requested: { amount: 7.4518, currency: "EUR" },
      original: [{ amount: 6.3652, currency: "GBP" }],
    },
  },
} as ExtendedLineItemDTO;

export const createLineItemMocks = (): ExtendedLineItemDTO[] =>
  multi(function* () {
    return {
      ...defaultLineItem,
      customerTag: yield* pick(() => ["BR&R", "Clam"]),
      applicationTag: yield* pick(() => ["<UNTAGGED>", "Application server"]),
      service: yield* pick(() => ["10Mbps Load Balancer Bandwidth"]),
      cloud: yield* pick(() => ["AZURE", "ORACLE"]),
      datasource: yield* pick(() => ["Azure demo", "CA Connect"]),
      billingMonth: yield* pick(() => range(7).map((i) => yearMonth(DateTime.local(2021, 7).minus({ month: i })))),
    };
  });

export function* randomLineItem(): RandomG<ExtendedLineItemDTO> {
  const customerTags = ["BR&R", "BR&R intl", "BR&R temp", "BR&R tmp", "Clam", "Cloud Allstars"];
  const customerTagWithPrice = customerTags.map(function* (it, index) {
    return [it, (yield* Random.inRange(1, 10)) * (index + 1)] as const;
  });
  const [customerTag, price] = yield* Random.pick(yield* Random.all(customerTagWithPrice));

  const attribute = yield* Random.pick(attributesMock);

  return {
    ...defaultLineItem,
    cloud: yield* Random.pick(["AWS", "AZURE", "GOOGLE", "ORACLE"]),
    customerTag,
    applicationTag: yield* Random.pick(["<UNTAGGED>", "Application server", "Correct", "PotentialBI", "Webapp"]),
    service: yield* Random.pick(["10Mbps Load Balancer Bandwidth", "Api calls"]),
    billingMonth: yield* Random.pick(range(7).map((i) => yearMonth(DateTime.local(2021, 7).minus({ month: i })))),
    costs: {
      cost: {
        requested: { amount: (yield* Random.inRange(1, 100)) * price, currency: "EUR" },
        original: [{ amount: (yield* Random.inRange(1, 100)) * price, currency: "GBP" }],
      },
      sales: {
        requested: { amount: (yield* Random.inRange(1, 100)) * price, currency: "EUR" },
        original: [{ amount: (yield* Random.inRange(1, 100)) * price, currency: "GBP" }],
      },
    },
    attributes: {
      [attribute.type.id]: yield* Random.pick(Object.keys(attribute.values)),
    },
    collectorId: attribute.type.collectorId!,
  };
}

export function randomLineItems(length: number, seed: number = 0): ExtendedLineItemDTO[] {
  const randomLineItems = Random.all(range(length).map(() => randomLineItem()));
  return Random.evaluate(randomLineItems, seed);
}

// prettier-ignore
export const defaultNestedLineItems: NestedLineItemDTO[] = [
  {
    id: "2021-06",
    dimension: "BILLING_MONTH",
    description: "2021-06",
    childLineItems: [
      {
        id: "2021-06_Azure demo",
        dimension: "DATASOURCE",
        description: "Azure demo",
        childLineItems: [],
        totalQuantity: 16624,
        total: {
          cost: {
            requested: { amount: 101287.9099, currency: "EUR" },
            original: [
              { amount: 0, currency: "EUR" },
              { amount: 86808.4999, currency: "GBP" },
            ],
          },
        },
      },
      {
        id: "2021-06_asdf",
        dimension: "DATASOURCE",
        description: "asdf",
        childLineItems: [],
        totalQuantity: 46348,
        total: {
          cost: {
            requested: { amount: 240768.6907, currency: "EUR" },
            original: [{ amount: 240768.6907, currency: "EUR" }],
          },
        },
      },
      {
        id: "2021-06_CA Connect",
        dimension: "DATASOURCE",
        description: "CA Connect",
        childLineItems: [],
        totalQuantity: 24622,
        total: {
          cost: {
            requested: { amount: 149112.5124, currency: "EUR" },
            original: [
              { amount: 0, currency: "EUR" },
              { amount: 127796.432, currency: "GBP" },
            ],
          },
        },
      },
    ],
    totalQuantity: 87594,
    total: {
      cost: {
        requested: { amount: 491169.113, currency: "EUR" },
        original: [
          { amount: 240768.6907, currency: "EUR" },
          { amount: 214604.9319, currency: "GBP" },
        ],
      },
    },
  },
  {
    id: "2021-07",
    dimension: "BILLING_MONTH",
    description: "2021-07",
    childLineItems: [
      {
        id: "2021-07_Azure demo",
        dimension: "DATASOURCE",
        description: "Azure demo",
        childLineItems: [],
        totalQuantity: 8635,
        total: {
          cost: {
            requested: { amount: 52555.1576, currency: "EUR" },
            original: [
              { amount: 0, currency: "EUR" },
              { amount: 45042.2401, currency: "GBP" },
            ],
          },
        },
      },
      {
        id: "2021-07_asdf",
        dimension: "DATASOURCE",
        description: "asdf",
        childLineItems: [],
        totalQuantity: 23951,
        total: {
          cost: {
            requested: { amount: 124259.458, currency: "EUR" },
            original: [{ amount: 124259.458, currency: "EUR" }],
          },
        },
      },
      {
        id: "2021-07_CA Connect",
        dimension: "DATASOURCE",
        description: "CA Connect",
        childLineItems: [],
        totalQuantity: 26369,
        total: {
          cost: {
            requested: { amount: 159388.3011, currency: "EUR" },
            original: [
              { amount: 0, currency: "EUR" },
              { amount: 136603.2662, currency: "GBP" },
            ],
          },
        },
      },
    ],
    totalQuantity: 58955,
    total: {
      cost: {
        requested: { amount: 336202.9167, currency: "EUR" },
        original: [
          { amount: 124259.458, currency: "EUR" },
          { amount: 181645.5063, currency: "GBP" },
        ],
      },
    },
  },
  {
    id: "2021-08",
    dimension: "BILLING_MONTH",
    description: "2021-08",
    childLineItems: [
      {
        id: "2021-08_CA Connect",
        dimension: "DATASOURCE",
        description: "CA Connect",
        childLineItems: [],
        totalQuantity: 13611,
        total: {
          cost: {
            requested: { amount: 82200.6787, currency: "EUR" },
            original: [
              { amount: 0, currency: "EUR" },
              { amount: 70449.8449, currency: "GBP" },
            ],
          },
        },
      },
    ],
    totalQuantity: 13611,
    total: {
      cost: {
        requested: { amount: 82200.6787, currency: "EUR" },
        original: [
          { amount: 0, currency: "EUR" },
          { amount: 70449.8449, currency: "GBP" },
        ],
      },
    },
  },
] as unknown as NestedLineItemDTO[];
