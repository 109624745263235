import { Box, Card, CardHeader, Typography } from "@material-ui/core";
import { Icon } from "../../../theme/Icon";
import { mapOrganizationToIconType } from "../../../mappers/organization.mapper";
import { FormSkeleton } from "../../../components/skeletons/FormSkeleton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { EditIconButton } from "../../../components/buttons/EditIconButton";
import { DeleteIconButton } from "../../../components/buttons/DeleteIconButton";
import { ConditionalRender } from "../../../components/ConditionalRender";
import { WorkspaceRole } from "../../../hooks/auth.hooks";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(0),
  },
  organizationLogo: {
    fontSize: 60,
  },
  cViewButton: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
}));

interface OrganizationCardProps {
  organization: OrganizationalUnitResponseDTO | null;
  root: OrganizationalUnitResponseDTO | null;
  handleEdit: (organization: OrganizationalUnitResponseDTO) => void;
  handleDelete: (organization: OrganizationalUnitResponseDTO) => void;
}

export const OrganizationCard = ({ organization, root, handleEdit, handleDelete }: OrganizationCardProps) => {
  const classes = useStyles();

  if (!organization || !root) return <FormSkeleton />;

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Icon icon={mapOrganizationToIconType(organization)} className={classes.organizationLogo} />}
        title={`${organization.name}`}
        subheader={
          <Box display={"flex"} alignItems={"flex-end"}>
            <Typography>Reference: {organization.reference}</Typography>
          </Box>
        }
        action={
          <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
            <EditIconButton onClick={() => handleEdit(organization)} tooltip="Edit Organizational unit" />
            <DeleteIconButton onClick={() => handleDelete(organization)} tooltip="Delete organizational unit" />
          </ConditionalRender>
        }
      />
    </Card>
  );
};
