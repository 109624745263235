import { useDashFilters } from "../selectors/dashboard.selector";
import { useMemo, useState } from "react";
import { GroupSegment } from "../model/GroupSegment";
import { attributeEquals } from "../model/Attribute";
import { addGroupSegmentFilter, SegmentFilterOption, updateGroupSegmentFilter } from "../model/GroupSegmentFilter";
import { Arr } from "../../../utils/functional/array.utils";
import { useSetDashboardShouldLoad } from "../NewDashPageProvider";

export function useChartFilterToggle() {
  const [filters, setFilters] = useDashFilters();
  const [zoomOutFilters, setZoomOutFilters] = useState<SegmentFilterOption[] | undefined>();
  const setDashboardShouldLoad = useSetDashboardShouldLoad();

  return useMemo(
    () => ({
      onFilterToggled: ({ groupSegment, value }: { groupSegment: GroupSegment; value: SegmentFilterOption }) => {
        const segmentToUpdate = filters.find((it) => attributeEquals(it.segment, groupSegment));

        setDashboardShouldLoad(true);
        // User tries to "zoom out"
        if (
          segmentToUpdate != null &&
          Arr.equals(
            segmentToUpdate.filters.map((it) => it.id),
            [value.id]
          ) &&
          zoomOutFilters != null
        ) {
          return setFilters(updateGroupSegmentFilter(filters, { segment: groupSegment, filters: zoomOutFilters }));
        }

        // Save the state that was before "zooming in". Empty array is equal to "no value selected".
        setZoomOutFilters(segmentToUpdate?.filters ?? []);
        if (segmentToUpdate == null) {
          return setFilters(addGroupSegmentFilter(filters, { segment: groupSegment, filters: [value] }));
        }
        return setFilters(updateGroupSegmentFilter(filters, { segment: groupSegment, filters: [value] }));
      },

      onReset: ({ groupSegment }: { groupSegment: GroupSegment }) => {
        if (zoomOutFilters != null) {
          setFilters(updateGroupSegmentFilter(filters, { segment: groupSegment, filters: zoomOutFilters }));
          setZoomOutFilters(undefined);
        }
      },
    }),
    [filters, setFilters, zoomOutFilters, setZoomOutFilters, setDashboardShouldLoad]
  );
}
