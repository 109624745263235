import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});
export function DataSourceCreatedForm({ onClose }: { onClose: () => void }) {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <CloudDoneIcon style={{ fontSize: 80 }} />
          </Grid>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <Typography>
              Datasource added successfully! The process will be started automatically, close this window to view the
              status.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Done
        </Button>
      </DialogActions>
    </>
  );
}
