import React, { useEffect } from "react";
import { useServices } from "../../../services/context.service";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { messageError, messageSuccess } from "../../../services/message.service";
import {DialogProps} from "../../../hooks/dialog.hooks";
import {MFAToggleComponent} from "../../account/components/MFAToggleComponent";

interface ResetComponentProps extends DialogProps {
  onSuccess: () => any;
}

export function ForceMFAComponent({ onClose, onSuccess}: ResetComponentProps) {
  const mfaService = useServices().mfa;
  const [mfaProps] = useDataClientPromise(mfaService.requestSecret, []);
  const { handleSubmit, control, setValue, } = useCFForm({
    schema: z.object({
      secret: z.string(),
      code: z.string().regex(/[0-9]{6}/, "Should be a numeric value of 6 digits"),
    }),
  });

  useEffect(() => {
    setValue("secret", mfaProps?.secret ?? "");
  }, [mfaProps, setValue]);

  const onSave = async (secret: string, code: string) => {
    try {
      await mfaService.enableMFA(secret, code)
      messageSuccess(
          "Successfully enabled MFA. Please make sure you keep your authenticator device, because you need it to sign in next time"
      )
      await mfaService.verifyMFA(code)
    }catch(e: any) {
        messageError("Invalid code");
    }
  };

  return (
      <MFAToggleComponent onClose={onClose} onSubmit={handleSubmit(({secret, code}) => onSave(secret, code))}
                          mfaProps={mfaProps} control={control} open/>
  );
}
