import { useState } from "react";
import { adminSchedule, useAdminDatasources } from "./admin.client";
import { CFCard, CFContainer, CFList, CFNestedCard, CFPageTitle } from "../components/layout/CFContainer";
import AppLayout from "../app/AppLayout";
import { Grid, ListItem, ListItemText, Typography } from "@material-ui/core";
import { PipelineStatus } from "../pages/datasources/pipelineruns/components/PipelineStatus";
import { formattedDateTimeUntilNow, ucFirst } from "../utils/localization.utils";
import { useRedirect } from "../hooks/router.hooks";
import { ActionIconButton } from "../components/buttons/ActionIconButton";
import { GenericIcon } from "../theme/Icon";
import { Route } from "react-router-dom";
import { PipelineDetail } from "../pages/datasources/pipelineruns/PipelineDetail";
import { css } from "@emotion/css";
import { adminAssumeRole } from "../clients/admin/assume.client";

interface DatasourcePageProps {}

export const DatasourcePage = ({ ...props }: DatasourcePageProps) => {
  const [adminData, refresh] = useAdminDatasources();

  const redirect = useRedirect();

  const [showDetail, setShowDetail] = useState(false);

  if (!adminData) {
    return <p>loading..</p>;
  }

  return (
    <AppLayout title="Admin">
      <CFContainer>
        <CFPageTitle header={"C-facts admin page"} />
        <Route
          path={"/admin/dashboard/pipeline/:pipelineId"}
          component={(props: any) => (
            <CFCard
              header={"View pipeline"}
              actions={{
                back: () => redirect("/admin/dashboard"),
                edit: () => setShowDetail((curr) => !curr),
              }}
            >
              <PipelineDetail
                collectorId={"asdf"}
                pipelineId={props.match.params.pipelineId}
                includeDebug={showDetail}
              />
            </CFCard>
          )}
        />
        <Route exact path={"/admin/dashboard"}>
          <CFCard header={"Datasource status"}>
            {adminData.map((it: any) => (
              <CFNestedCard
                header={
                  <span>
                    Customer: <strong>{it.workspace.name}</strong>
                  </span>
                }
              >
                <Typography className={css({ marginBottom: "12px" })} variant={"h6"}>
                  Datasources:
                </Typography>
                <CFList>
                  {it.datasources.map((datasource: any) => (
                    <ListItem>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={7}>
                          <ListItemText>
                            {datasource.collector.type}: {datasource.collector.name}
                          </ListItemText>
                        </Grid>
                        <Grid item xs={2}>
                          <span>{datasource.collector.status}</span>
                        </Grid>
                        <Grid item xs={2}>
                          <ListItemText>
                            Last active:{" "}
                            {formattedDateTimeUntilNow(datasource.recentPipeline?.lastActive, "More than 2 days ago!")}
                          </ListItemText>
                        </Grid>
                        <Grid item xs={1} alignItems={"flex-end"}>
                          <PipelineStatus status={datasource.recentPipeline?.status} />
                        </Grid>
                      </Grid>
                      <ActionIconButton
                        buttonSize={"small"}
                        action={{
                          icon: GenericIcon.UNHIDE,
                          onClick: () => redirect(`/admin/dashboard/pipeline/${datasource.recentPipeline.id}`),
                          tooltip: "View pipeline",
                          disabled: !datasource.recentPipeline,
                        }}
                      />
                      <ActionIconButton
                        buttonSize={"small"}
                        action={{
                          icon: GenericIcon.ADD,
                          onClick: () => adminSchedule(datasource.collector.id).then(() => refresh()),
                          tooltip: "Schedule pipeline",
                        }}
                      />
                    </ListItem>
                  ))}
                </CFList>
                <Typography variant="h6" className={css({ marginBottom: "12px", marginTop: "24px" })}>
                  Users:{" "}
                </Typography>
                <CFList>
                  {it.users.map((user: any) => (
                    <ListItem>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={9}>
                          {user.name} ({user.email})
                        </Grid>
                        <Grid item xs={2}>
                          {formattedDateTimeUntilNow(user.lastActive)}
                        </Grid>
                        <Grid item xs={1}>
                          {ucFirst(user.role)}
                        </Grid>
                      </Grid>
                      <ActionIconButton
                        buttonSize={"small"}
                        action={{
                          icon: GenericIcon.UNHIDE,
                          onClick: () => adminAssumeRole(user.id, it.workspace.id),
                          tooltip: "Assume role (if user allows it)",
                          disabled: !user.allowAdminAssume,
                        }}
                      />
                    </ListItem>
                  ))}
                </CFList>
              </CFNestedCard>
            ))}
          </CFCard>
        </Route>
      </CFContainer>
    </AppLayout>
  );
};
