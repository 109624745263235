import { ChangeEvent, useEffect, useState } from "react";
import { AppBar, Box, Button, IconButton, InputBase, useTheme } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  useDashboards,
  useSetDashboardMigrationError,
  useSetDashboards,
  useSetDashboardShouldLoad,
  useSetSelectedDashboardId,
} from "./NewDashPageProvider";
import { css } from "@emotion/css";
import { SaveDashboard } from "./components/SaveDashboard";
import { useSelectedDashboard } from "./selectors/dashboard.selector";
import { DashMenu } from "./components/DashMenu";
import { Dashboard } from "./model/Dashboard";
import CheckIcon from "@material-ui/icons/Check";
import PeopleIcon from "@material-ui/icons/People";
import { Model } from "../../model/Model";
import { DateTime } from "luxon";
import { useOnAddTab } from "./events/dashboard.events";
import AddIcon from "@material-ui/icons/Add";
import { Prompt } from "react-router-dom";

const tabStyle = css({
  display: "flex",
  flexDirection: "row",
  fontWeight: "normal",
  fontSize: 16,
  letterSpacing: 0.5,
  opacity: 0.7,
  "&.Mui-selected": { opacity: 1 },
});

export function DashboardTabs() {
  const [dashboards, setDashboards] = [useDashboards(), useSetDashboards()];
  const [dashboard, setDashboard] = [useSelectedDashboard(), useSetSelectedDashboardId()];
  const setMigrationError = useSetDashboardMigrationError();
  const theme = useTheme();
  const setDashboardShouldLoad = useSetDashboardShouldLoad();
  const unsavedChanges = dashboards.some(
    (it) => (it.savedAt == null || it.updatedAt > it.savedAt) && !it.createdAt.equals(it.updatedAt)
  );

  const onTitleDoubleClicked = (dashboard: Dashboard) =>
    setDashboards((state) => Model.update(state, { ...dashboard, editing: true }));

  useEffect(() => {
    if (unsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [unsavedChanges]);

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message={"You have unsaved changes that will be discarded, are you sure you want to leave?"}
      />
      <AppBar elevation={0} position={"static"} className={css({ marginBottom: theme.spacing(3) })}>
        <Tabs
          classes={{
            indicator: css({
              height: 3,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
              backgroundColor: theme.palette.primary.main,
            }),
          }}
          value={dashboard?.id}
          onChange={(event, value) => {
            setDashboard(value);
            setDashboardShouldLoad(true);
            setMigrationError(false);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <DashMenu />
          {dashboards.map((it) => {
            return (
              <Tab
                value={it.id}
                disableRipple={true}
                key={it.id}
                component={"div"}
                classes={{ wrapper: tabStyle }}
                onDoubleClick={() => onTitleDoubleClicked(it)}
                className={css({ textTransform: "initial" })}
                label={
                  it.editing ? (
                    <EditTitle dashboard={it} />
                  ) : (
                    <>
                      <Box mr={0} ml={-1}>
                        {it.shared ? (
                          <IconButton disabled={true}>
                            <PeopleIcon className={css({ color: theme.palette.primary.main })} fontSize={"small"} />
                          </IconButton>
                        ) : null}
                      </Box>
                      <Box flex={1}>{it.title}</Box>
                      <Box mr={-1} ml={1}>
                        <SaveDashboard dashboard={it} />
                      </Box>
                    </>
                  )
                }
              />
            );
          })}
          <DashAddTab />
        </Tabs>
      </AppBar>
    </>
  );
}

export function EditTitle({ dashboard }: { dashboard: Dashboard }) {
  const [title, setTitle] = useState(dashboard.title);
  const setDashboards = useSetDashboards();

  const onTitleSubmitted = () =>
    setDashboards((state) => Model.update(state, { ...dashboard, updatedAt: DateTime.local(), title, editing: false }));

  const onTitleChanged = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.currentTarget.value);

  return (
    <>
      <Box flex={1}>
        <InputBase
          value={title}
          placeholder={"Title"}
          onChange={onTitleChanged}
          onKeyDown={(e) => e.key === "Enter" && onTitleSubmitted()}
        />
      </Box>
      <Box mr={-1} ml={1}>
        <IconButton color="primary" onClick={onTitleSubmitted}>
          <CheckIcon fontSize={"small"} />
        </IconButton>
      </Box>
    </>
  );
}

function DashAddTab() {
  const onAddTab = useOnAddTab();
  const theme = useTheme();

  return (
    <Button onClick={onAddTab} className={css({ minHeight: theme.spacing(6) })}>
      <AddIcon color="primary" />
    </Button>
  );
}
