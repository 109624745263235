import { Redirect } from "react-router-dom";
import { useCollectors } from "../../clients/collector.client";
import { FormSkeleton } from "../../components/skeletons/FormSkeleton";
import { useIsManagedCView, useSubscriptionType } from "../../hooks/auth.hooks";
import { allStepsComplete } from "../information/stepper/OnboardingStepper";

export function WelcomePage() {
  const [dataSources] = useCollectors();

  const subscriptionType = useSubscriptionType();
  const isManagedCViewUser = useIsManagedCView();

  if (subscriptionType !== "ACTIVE" || isManagedCViewUser) {
    return <Redirect to="/dashboard" />;
  }

  if (!dataSources) {
    return <FormSkeleton />;
  }

  if (allStepsComplete(dataSources)) {
    const distinctTypes = new Set(dataSources.map((it) => it.type));
    if (distinctTypes.size > 1 || !distinctTypes.has("SERVICE_PROVIDER")) {
      return <Redirect to="/dashboard" />;
    }
  }

  return <Redirect to="/information" />;
}
