import { useEffect, useRef } from "react";
import Chart, {
  AdaptiveLayout,
  ArgumentAxis,
  CommonSeriesSettings,
  Series,
  Size,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { ValueTooltip } from "../../components/tooltips/ValueTooltip";
import { Information } from "../../components/Information";
import { BarChartSkeleton } from "../../components/skeletons/BarChartSkeleton";
import Grid from "@material-ui/core/Grid";
import { MenuOptionsGraph } from "../../components/menu/MenuOptionsGraph";
import { BillingPeriod } from "../../components/BillingPeriod";
import { useAggregateQuery } from "../../clients/query.client";
import { aggregateToChart } from "../newdash/model/Query";
import { DISPLAY_SEGMENT } from "../newdash/model/ChartConfig";
import { useMonthlyBillingPeriod, useSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";
import { useIsManagedCView } from "../../hooks/auth.hooks";

interface BillingMonthsOverviewProps {
  selected: string | null;
  setSelected: (s: string | null) => void;
}

export function BillingMonthsOverview({ selected, setSelected }: BillingMonthsOverviewProps) {
  const monthly = useMonthlyBillingPeriod();
  const periods = useSelectedBillingPeriods();
  const [items, , loading] = useAggregateQuery(
    monthly ? "BILLING_MONTH" : "BILLING_PERIOD",
    "TOTAL",
    monthly
      ? []
      : [
          {
            dimension: "BILLING_PERIOD",
            filters: periods?.periods.map((it) => it.id) ?? [],
          },
        ]
  );
  const chartRef = useRef<Chart>(null);

  const displaySegment = useIsManagedCView()
    ? [DISPLAY_SEGMENT.COST]
    : [DISPLAY_SEGMENT.COST, DISPLAY_SEGMENT.SALES, DISPLAY_SEGMENT.CROSS_CHARGING];

  const [dataSource, series] = aggregateToChart(displaySegment, items?.[0]);

  useEffect(() => {
    if (dataSource && dataSource.findIndex((it) => it.argument === selected) === -1) {
      setSelected(null);
    }
  }, [selected, setSelected, dataSource]);

  if (loading) {
    return <BarChartSkeleton />;
  }

  const onSelect = (res: any) => {
    console.log(res);
    res.target.select();
    setSelected(res.target.data.argument);
  };

  const onSelectArgument = (res: any) => {
    setSelected(res.argument);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <Information
            title={"This shows the total cost and sales based on the selected billing cycle"}
            align={"left"}
          />
        </Grid>
        <Grid item alignContent={"flex-end"} xs={2}>
          <MenuOptionsGraph png={chartRef} svg={chartRef} />
        </Grid>
      </Grid>
      <Chart ref={chartRef} dataSource={dataSource} onPointClick={onSelect} onArgumentAxisClick={onSelectArgument}>
        <Title text={" "} />
        <Size height={200} />
        <Tooltip
          enabled={true}
          contentRender={(props) => {
            return (
              <div>
                <ValueTooltip
                  title={props.seriesName}
                  value={props.value}
                  argumentText={<BillingPeriod full billingPeriod={props.argumentText} />}
                />
              </div>
            );
          }}
        />
        <CommonSeriesSettings
          type="bar"
          argumentField={"argument"}
          selectionMode={"allArgumentPoints"}
          hoverMode={"allArgumentPoints"}
        />
        <AdaptiveLayout width={450} />
        {series.map((item) => (
          <Series key={item.key} valueField={item.key} name={item.label} />
        ))}
        <ValueAxis position={"right"}>
          <Title text={"Amount"} />
        </ValueAxis>
        <ArgumentAxis position={"below"} label={{ format: (props: any) => console.log(props) }}>
          <Title text={"Month"} />
        </ArgumentAxis>
      </Chart>
    </>
  );
}
