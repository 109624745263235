import { AttributeData } from "../pages/datasources/organizationFilter/components/AttributeFilterSection";
import { AttributeFilterValueDTO, AttributeResponseDTO, AttributeTypeDTODisplayStateEnum } from "../open-api";

export const groupByState = (attributeParams: Array<AttributeData>) => {
  const undecided = attributeParams.filter((it) => it.state === "NEW");
  const selected = attributeParams.filter((it) => it.state === "INCLUDE");
  const ignored = attributeParams.filter((it) => it.state === "HIDE" || it.state === "DELETE");

  return [undecided, selected, ignored];
};

export const mapAttributes = (
  attributes: Array<AttributeFilterValueDTO | AttributeResponseDTO>,
  changedStates: Record<string, AttributeTypeDTODisplayStateEnum>
): Array<AttributeData> => {
  return attributes
    .map((it) => {
      if ("type" in it) {
        // AttributeResponseDTO
        return {
          id: it.type.uuid!,
          param: it.type.param!,
          displayParam: it.type.paramDisplayValue!,
          state: changedStates[it.type.uuid!] ?? it.type.displayState,
        };
      }
      // AttributeFilterValueDTO
      return {
        id: it.id!,
        displayParam: it.display!,
        state: changedStates[it.id!] ?? it.state,
        isSelected: false,
      };
    })
    .sort((a, b) => a.displayParam.localeCompare(b.displayParam));
};
