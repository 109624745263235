import { useAppContext } from "../hooks/auth.hooks";

const ADMIN_WORKSPACE_ID = "00000000-0000-0000-0000-000000000000";

export const useIsGlobalAdmin = (): boolean | null => {
  const workspaceId = useAppContext()?.workspace?.id;
  if (!workspaceId) {
    return null;
  }
  return workspaceId === ADMIN_WORKSPACE_ID;
};
