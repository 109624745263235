import { Typography } from "@material-ui/core";
import { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { StepWaitingForProcessor } from "./StepWaitingForProcessor";
import { DataSourceResponseDTO, useCollectors } from "../../../clients/collector.client";
import { FormSkeleton } from "../../../components/skeletons/FormSkeleton";
import { StepperTutorial } from "../../../components/tutorial/StepperTutorial";
import { StepDatasource } from "./StepDatasource";
import Tooltip from "@material-ui/core/Tooltip";
import { DataSourceDialog } from "../../datasources/DataSourceDialog";

export function OnboardingStepper() {
  const [dataSources, refreshDataSources] = useCollectors();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (!dataSources) {
    return <FormSkeleton />;
  }

  const handleNext = () => {
    refreshDataSources();
  };

  const handleBack = () => {
    refreshDataSources();
  };

  const stepsTitle = ["Add a data source", "Wait for the processor"];

  const steps = [
    <StepDatasource onSuccess={refreshDataSources} />,
    <StepWaitingForProcessor datasource={dataSources && dataSources[0]} refresh={refreshDataSources} />,
  ];

  const stepsCompletedDescription = [
    <Typography>
      Want to add another data source? Go to the data source page and click on the{" "}
      <Tooltip title={"Add data source"}>
        <AddCircleIcon style={{ cursor: "hand" }} onClick={toggleOpen} color={"primary"} fontSize={"small"} />
      </Tooltip>{" "}
      icon.
      {isOpen && <DataSourceDialog toggle={toggleOpen} open={isOpen} />}
    </Typography>,
    <Typography>Proceed to the next step.</Typography>,
  ];

  const stepsCompleted = [
    () => {
      return dataSourceAddedComplete(dataSources);
    },
    () => {
      return firstDataSourceFoundCurrentStatusRunComplete(dataSources);
    },
  ];

  const stepsArray = [...Array(stepsTitle.length)].map((step, index) => {
    return {
      stepTitle: stepsTitle[index],
      step: steps[index],
      stepCompleted: stepsCompleted[index],
      stepCompletedDescription: stepsCompletedDescription[index],
    };
  });

  return (
    <StepperTutorial
      steps={stepsArray}
      onBack={() => handleBack}
      canClickNext={!dataSourceAddedComplete(dataSources)}
      onNext={() => handleNext}
    />
  );
}

export function allStepsComplete(dataSources: DataSourceResponseDTO[]) {
  return dataSourceAddedComplete(dataSources);
}

function dataSourceAddedComplete(dataSources: DataSourceResponseDTO[]) {
  return dataSources && dataSources?.length > 0;
}
function firstDataSourceFoundCurrentStatusRunComplete(dataSources: DataSourceResponseDTO[]) {
  return dataSources && dataSources[0] && dataSources[0].status === "FINISHED";
}
