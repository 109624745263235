import Dialog from "@material-ui/core/Dialog";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { UserCreateDialogForm } from "./UserCreateDialogForm";

interface UserCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export function UserCreateDialog({ open, onClose }: UserCreateDialogProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog maxWidth={"md"} open={open} onClose={handleClose} disableBackdropClick>
      <DialogTitleCloseable onClose={handleClose}>Invite user to workspace</DialogTitleCloseable>
      <UserCreateDialogForm onClose={handleClose} onSuccess={handleClose} onError={handleClose} />
    </Dialog>
  );
}
