import Dialog from "@material-ui/core/Dialog";
import { DialogTitleCloseable } from "../DialogTitleCloseable";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, Grid, Typography } from "@material-ui/core";
import { FunctionComponent, ReactNode, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ButtonConfirm } from "../form/ButtonConfirm";

interface DeleteDialogProps {
  open: boolean;
  title: string;
  content: string | ReactNode;
  onClose: () => void;
  onConfirm: () => Promise<any>;
  confirmationText?: string;
}

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

export const ConfirmDialog: FunctionComponent<DeleteDialogProps> = ({
  open,
  title = "",
  content = "",
  onClose,
  onConfirm,
  children,
  confirmationText = "yes",
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  return (
    <Dialog fullWidth maxWidth={"xs"} onClose={onClose} open={open} disableBackdropClick>
      <DialogTitleCloseable onClose={onClose}>{title}</DialogTitleCloseable>

      <DialogContent className={classes.content}>
        <Grid alignItems="center" container spacing={1}>
          {children && (
            <Grid item xs={3}>
              {children}
            </Grid>
          )}
          <Grid item xs={children ? 9 : 12}>
            <Typography>{content}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonConfirm
          disabled={loading}
          onClick={() => {
            setLoading(true);
            onConfirm().finally(() => {
              setLoading(false);
              onClose();
            });
          }}
        >
          {confirmationText}
        </ButtonConfirm>
      </DialogActions>
    </Dialog>
  );
};
