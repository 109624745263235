import { ReactChild, ReactNode, useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AppMenu from "./AppMenu";
import { useIsMobile } from "../hooks/device.hooks";
import { ReactComponent as Logo } from "../assets/img/C-Facts-logo.svg";
import "../assets/css/custom-chart.css";
import { UserMenu } from "./UserMenu";
import { messagesDismiss } from "../services/message.service";
import { Alert } from "@material-ui/lab";
import { css } from "@emotion/css";
import { Button } from "@material-ui/core";
import { useServices } from "../services/context.service";
import { useDataClientPromise } from "../hooks/client.hooks";
import { useRefresh } from "../hooks/refresh.hooks";
import { useAppContext } from "../hooks/auth.hooks";
import { adminAssumeRoleLogout } from "../clients/admin/assume.client";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootMobile: {
    display: "flex",
    flexDirection: "column",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: `0 ${theme.spacing(1)}px`,
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftMobile: {
    marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  darwerPaperMobile: {
    width: "unset",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
    marginTop: theme.spacing(8),
    height: `calc(100vh - ${theme.spacing(8)}px)`,
  },
  contentMobile: {
    flexGrow: 1,
    overflow: "auto",
    marginTop: theme.spacing(8),
  },
  contentMobileOpen: {
    marginTop: "unset",
  },
  container: {
    maxWidth: "unset",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  hide: {
    display: "none",
  },
  logo: {
    height: theme.spacing(6),
  },
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  containerLogo: {
    flexGrow: 1,
  },
  supportButtonLink: {
    all: "unset",
  },
  supportButton: {
    width: 50,
    height: 50,
    backgroundColor: "#CDDC39",
    position: "fixed",
    left: 10,
    bottom: 20,
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "50px",
    cursor: "pointer",
    zIndex: 1300,
  },
}));

interface AppLayoutProps {
  title: ReactNode;
  children: ReactChild;
}

export default function AppLayout({ children, title = "" }: AppLayoutProps) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  messagesDismiss();

  const drawer = useRef();
  const handleDrawerToggle = () => {
    window.dispatchEvent(new Event("resize"));
    setIsOpen(!isOpen);
  };

  const mobile = useIsMobile();

  return (
    <div className={mobile ? classes.rootMobile : classes.root}>
      <AppBar
        position="absolute"
        className={mobile ? clsx(classes.appBar) : clsx(classes.appBar, isOpen && classes.appBarShift)}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={mobile ? clsx(classes.menuButton) : clsx(classes.menuButton, isOpen && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title}
          </Typography>

          <div className={classes.containerLogo}>
            <Logo className={classes.logo} title="c-facts logo" />
          </div>

          <UserMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        ref={drawer}
        variant="permanent"
        classes={{
          paper: mobile
            ? clsx(classes.drawerPaper, classes.darwerPaperMobile, !isOpen && classes.hide)
            : clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
        }}
        open={isOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <AppMenu />
      </Drawer>
      <main className={mobile ? clsx(isOpen && classes.contentMobileOpen, classes.contentMobile) : classes.content}>
        <AdminAssumeBar />
        <ProcessingInfoBar />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
      <a
        href="https://c-facts.atlassian.net/servicedesk/customer/portals"
        target="_blanc"
        className={classes.supportButtonLink}
      >
        <div className={classes.supportButton}>?</div>
      </a>
    </div>
  );
}

const ProcessingInfoBar = () => {
  const service = useServices().processQueue;
  const [items, refresh] = useDataClientPromise(service.list, []);
  useRefresh(true, refresh, 10);
  if (!items || items.length === 0) {
    return null;
  }

  const activeAmount = items.filter((it) => it.isActive).length;
  const queuedAmount = items.filter((it) => !it.isActive).length;

  return (
    <Container className={css({ maxWidth: "100%", marginTop: "12px" })}>
      <Alert
        variant="outlined"
        severity="info"
        // TODO: Implement notify me functionality
        // action={<Button color={"inherit"} size="small">Notify me when finished</Button>}
        // icon={<CircularProgress size="22px" />}
      >
        C-Facts is processing data in the background. Data you see in the application might not yet be up to date or
        accurate —{" "}
        <strong>
          {activeAmount} item{activeAmount !== 1 ? "s" : ""}
        </strong>{" "}
        currently processing —{" "}
        <strong>
          {queuedAmount} item{queuedAmount !== 1 ? "s" : ""}
        </strong>{" "}
        queued
      </Alert>
    </Container>
  );
};

const AdminAssumeBar = () => {
  const res = useAppContext();
  if (!res || !res.isAssumed) {
    return null;
  }

  return (
    <Container className={css({ maxWidth: "100%", marginTop: "12px" })}>
      <Alert
        variant="outlined"
        severity="warning"
        action={
          <Button color={"inherit"} size={"small"} onClick={() => adminAssumeRoleLogout()}>
            Return to admin account
          </Button>
        }
      >
        You are currently acting as user:{" "}
        <strong>
          {res?.user?.name} ({res?.user?.email})
        </strong>
      </Alert>
    </Container>
  );
};
