import { DataSourceResponseDTO } from "../../../clients/collector.client";
import { CFCard } from "../../../components/layout/CFContainer";
import { AttributeFilterValueDTO, AttributeTypeDTODisplayStateEnum } from "../../../open-api";
import { useApiCall, useDataClientPromise } from "../../../hooks/client.hooks";
import { useServices } from "../../../services/context.service";
import { useEffect, useState } from "react";
import { AttributeFilterSection } from "./components/AttributeFilterSection";

interface OrganizationFilterProps {
  collector: DataSourceResponseDTO;
}

const organizationFilterHeaderComponent = () => (
  <>
    <p>Select for which resellers and tenants you want C-Facts to collect and process the data.</p>
    <p>
      <strong>Selection rules:</strong>
      <br />
      Including a reseller automatically includes all tenants associated with that reseller.
      <br />
      Enabling the 'Include undefined' toggle includes all data with no associated reseller (direct customers).
      <br />
      Changes made in this screen will be visible <strong>after</strong> the next collect & process run is finished.
      {/*<br />*/}
      {/*The state of a Tenant can be selected when a Reseller has the Undecided state.*/}
    </p>
  </>
);

export const OrganizationFilter = ({ collector }: OrganizationFilterProps) => {
  const { attributesFilter } = useServices();
  const [attributes, refreshAttributes] = useDataClientPromise(attributesFilter.getForCollector, [collector.id]);
  const [saveAttributes] = useApiCall(attributesFilter.updateDisplayState);
  const [resellers, setResellers] = useState<Array<AttributeFilterValueDTO>>([]);
  const [resellersTitle, setResellersTitle] = useState("");
  const [includeUndefined, setIncludeUndefined] = useState<boolean | null>(null);

  // TODO tenants will be introduced later
  const [tenants, setTenants] = useState<Array<AttributeFilterValueDTO>>([]);
  const [tenantsTitle, setTenantsTitle] = useState("");

  useEffect(() => {
    setResellers(attributes?.[0]?.values ?? []);
    setResellersTitle(attributes?.[0]?.attributeDisplay ?? "Resellers");
    if (attributes?.[0]?.includeUndefined) {
      setIncludeUndefined(attributes?.[0]?.includeUndefined);
    }
    setTenants(attributes?.[1]?.values ?? []);
    setTenantsTitle(attributes?.[1]?.attributeDisplay ?? "Tenants");
  }, [attributes]);

  const handleSave = (changedMap: Record<string, AttributeTypeDTODisplayStateEnum>) =>
    saveAttributes({
      collectorId: collector.id,
      updateAttributeFiltersRequestDTO: {
        newStates: changedMap,
        includeUndefinedFor: includeUndefined && attributes?.[0].attributeType ? [attributes?.[0].attributeType] : [],
      },
    }).then(refreshAttributes);

  const newResellers = resellers
    .filter((it) => it.state === "NEW" && it.id)
    .reduce((agg, it) => ({ ...agg, [it.id!]: true }), {} as Record<string, boolean>);

  // Only show items that depend on values that are currently in NEW
  const filteredTenants = tenants.filter((it) => {
    if (it.dependsOn && it.dependsOn.length > 0) {
      return it.dependsOn?.some((id) => newResellers[id]);
    } else {
      return true;
    }
  });

  return (
    <>
      <CFCard>{organizationFilterHeaderComponent()}</CFCard>
      <AttributeFilterSection
        attributes={resellers}
        onSave={handleSave}
        title={resellersTitle}
        withCheckboxes
        handleUndefined={{
          selected: !!includeUndefined,
          onChange: setIncludeUndefined,
        }}
        canDelete={true}
      />
      <AttributeFilterSection
        attributes={filteredTenants}
        onSave={handleSave}
        title={tenantsTitle}
        withCheckboxes
        canDelete={true}
      />
    </>
  );
};
