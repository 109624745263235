import { Link, useLocation } from "react-router-dom";
import MaterialLink from "@material-ui/core/Link";
import { FC } from "react";

interface RouterLinkProps {
  to: string;
}

export const RouterLink: FC<RouterLinkProps> = ({ to, children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  Object.keys(params).forEach((it) => {
    if (it !== "ft") {
      params.delete(it);
    }
  });
  return (
    <MaterialLink component={Link} to={{ pathname: to, search: params.toString() }}>
      {children}
    </MaterialLink>
  );
};
