import { DialogTitleCloseable } from "../DialogTitleCloseable";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { ReactNode } from "react";

export interface StepperDialogObj {
  stepTitle: string;
  step: ReactNode;
}

interface StepperDialogProps {
  onClose: () => void;
  activeStep: number;
  steps: StepperDialogObj[];
  dialogTitle: string;
  buttonClose?: boolean;
}

export function StepperDialog({ onClose, activeStep, steps, dialogTitle, buttonClose = true }: StepperDialogProps) {
  return (
    <>
      <DialogTitleCloseable onClose={buttonClose ? onClose : undefined}>{dialogTitle}</DialogTitleCloseable>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, key) => (
          <Step key={key}>
            <StepLabel>{step.stepTitle}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <DialogStep step={steps[activeStep]} />
    </>
  );
}

const DialogStep = ({ step }: { step: StepperDialogObj }) => {
  return <>{step.step}</>;
};
