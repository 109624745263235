import { IconProps } from "../../theme/Icon";
import { Box, Typography } from "@material-ui/core";
import { CFactsAction } from "./CFactsAction";
import { ActionIconButton } from "./ActionIconButton";

interface ActionIconLabelButtonProps extends IconProps {
  action: CFactsAction;
  buttonSize?: "small" | "medium";
  className?: string;
}

export const ActionIconLabelButton = ({ action, buttonSize, className, ...iconProps }: ActionIconLabelButtonProps) => {
  return (
    <Box display={"flex"}>
      <ActionIconButton buttonSize={buttonSize} className={className} action={action} {...iconProps} />
      {!!action.label && (
        <Typography style={{ display: "inline-flex", paddingLeft: 16, paddingTop: 12 }}>{action.label}</Typography>
      )}
    </Box>
  );
};
