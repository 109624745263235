import { RawChartConfig } from "../model/ChartConfig";
import { useRef, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { css } from "@emotion/css";
import { absoluteCenter } from "../../../utils/styles";
import { Alert } from "@material-ui/lab";
import { PieChart } from "devextreme-react";
import { escapeHtml } from "../../../utils/dom";
import { useCurrency } from "../../../hooks/config.hooks";
import { ChartMenu } from "./ChartMenu";
import { useChartTitles } from "../selectors/ChartConfig";
import { useAggregatedLineItemsForDashboard } from "../model/Query";
import { GROUP_SEGMENT } from "../model/GroupSegment";

export interface DashRawChartProps {
  config: RawChartConfig;
}

export function DashRawChart({ config }: DashRawChartProps) {
  const ref = useRef<PieChart>(null);
  const { title, subTitle } = useChartTitles(config);
  const [dirty, setDirty] = useState(false);
  const currency = useCurrency();

  const [items, , loading] = useAggregatedLineItemsForDashboard(GROUP_SEGMENT.TOTAL, GROUP_SEGMENT.TOTAL);

  const sum = items?.[0].arguments[0].points[0].value[config.displaySegment.value] ?? 0;

  return (
    <Box className={css({ display: "flex", position: "relative" })}>
      <Box className={css({ marginTop: 36, position: "relative" })}>
        {loading && (
          <div className={css(absoluteCenter)}>
            <CircularProgress size={45} />
          </div>
        )}
        <div className={css(absoluteCenter)}>
          {sum > 0 ? (
            <Typography variant="h4" gutterBottom>
              {config.displaySegment.value !== "quantity"
                ? new Intl.NumberFormat("en-US", { style: "currency", currency }).format(Math.round(sum))
                : sum < 1000
                ? new Intl.NumberFormat("en-US").format(Number(sum))
                : `${Math.round(Number(sum) / 1000)}K`}
            </Typography>
          ) : (
            <Alert style={{ width: "320px" }} variant="outlined" severity="warning">
              No data available to display this graph.
            </Alert>
          )}
        </div>
        <PieChart
          ref={ref}
          disabled={loading}
          size={{ width: 500, height: 400 }}
          palette="Bright"
          dataSource={[]}
          margin={{ bottom: 20 }}
          title={{
            text: escapeHtml(title),
            font: { color: "white" },
            subtitle: { text: escapeHtml(subTitle) },
          }}
        />
      </Box>
      <Box className={css({ position: "absolute", top: 0, right: 0 })}>
        <ChartMenu chartRef={ref} chartConfig={config} dirty={[dirty, setDirty]} />
      </Box>
    </Box>
  );
}
