import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import { useApiCall } from "../../../hooks/client.hooks";
import { createGoogleCollector, DataSourceResponseDTO } from "../../../clients/collector.client";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-hook-form";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { DropzoneArea } from "material-ui-dropzone";
import { DropEvent } from "react-dropzone";
import { ControlledTextField } from "../../../components/form/FormComponents";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(1),
  },
  dropzoneGridContainer: {
    paddingLeft: theme.spacing(4),
  },
  dropZoneRoot: {
    minHeight: 180,
    padding: theme.spacing(1),
  },
  dropZoneRootError: {
    minHeight: 180,
    padding: theme.spacing(1),
    borderColor: theme.palette.error.main,
  },
  hidden: {
    display: "none",
  },
  dropZoneContainer: {
    padding: theme.spacing(2),
    width: "100%",
  },
  dropZoneItem: {
    marginTop: theme.spacing(1),
    margin: "auto",
  },
  dropZoneImage: {
    width: 60,
  },
}));

interface GoogleDataSourceFormProps {
  onSuccess: (ds: DataSourceResponseDTO) => void;
  onError: (msg: string) => void;
  onBack: () => void;
  onClose: () => void;
  buttonCancel: boolean;
}

export interface GoogleCollectorForm {
  name: string;
  credentials: {
    dataset: string;
    apiKey: string;
  };
}

export function GoogleDataSourceForm({
  onSuccess,
  onError,
  onBack,
  onClose,
  buttonCancel = true,
}: GoogleDataSourceFormProps) {
  const classes = useStyles();
  const [submit, isSubmitting] = useApiCall(createGoogleCollector);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<GoogleCollectorForm>({ mode: "onTouched" });
  const [file, setFile] = useState<File>();

  const handleSave = ({ credentials, ...form }: GoogleCollectorForm) => {
    submit({
      ...form,
      credentials: { ...credentials, apiKey: JSON.parse(credentials.apiKey) },
    })
      .then(onSuccess)
      .catch(onError);
  };

  const handleFiles = (files: File[], event: DropEvent) => {
    setFile(files[0]);
    const fileReader = new FileReader();
    fileReader.readAsText(files[0]);
    fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      // @ts-ignore
      setValue("credentials.apiKey", ev.target?.result);
    };
  };

  const handleRemoveFile = (file: File) => {
    setFile(undefined);
    setValue("credentials.apiKey", "");
  };

  return (
    <form
      onSubmit={handleSubmit((form) => {
        handleSave(form);
      })}
    >
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid xs={7} container>
            <FormTitleField text="Data Source" />
            <ControlledTextField label="Name" control={control} name="name" fullWidth />
            <FormTitleField text="Credentials" />
            <ControlledTextField control={control} name={"credentials.dataset"} label={"Dataset"} fullWidth />
          </Grid>
          <Grid xs={5} container classes={{ root: classes.dropzoneGridContainer }}>
            <DropzoneArea
              filesLimit={1}
              onDrop={handleFiles}
              onDelete={handleRemoveFile}
              dropzoneText={"Drag and drop your JSON key file here or click"}
              showFileNames={true}
              showPreviews={false}
              showPreviewsInDropzone={true}
              acceptedFiles={[".json", "application/json"]}
              classes={{
                root: errors.credentials?.apiKey ? classes.dropZoneRootError : classes.dropZoneRoot,
                textContainer: !!file ? classes.hidden : undefined,
              }}
              previewGridClasses={{
                container: classes.dropZoneContainer,
                item: classes.dropZoneItem,
                image: classes.dropZoneImage,
              }}
              previewGridProps={{
                container: {
                  spacing: 2,
                },
                item: {
                  xs: 12,
                },
              }}
              useChipsForPreview={false}
            />
            <ControlledTextField control={control} type={"hidden"} name="credentials.apiKey" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Previous</Button>
        {buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
