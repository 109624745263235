import {
  Box,
  Button,
  capitalize,
  DialogActions,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { isCustomer, isRoot } from "../model/organization";
import { css } from "@emotion/css";
import { AdjustmentType } from "../../../../utils/functional/adjustments.utils";
import { Alert } from "@material-ui/lab";
import { FormSelectSingle } from "../../../../components/form/FormSelectSingle";
import { ButtonConfirm } from "../../../../components/form/ButtonConfirm";
import { updateOrganizationalUnit } from "../../../../clients/organizationalunit.client";
import { messageError, messageSuccess } from "../../../../services/message.service";
import { formatBasePrice, formatCalculationGoal, formatGrossType } from "./AccordionGrossType";
import EditIcon from "@material-ui/icons/Edit";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";
import { GrossTypeText } from "../../../../texts/gross-type.text";
import { OrganizationUnitType } from "../../../../mappers/organization.mapper";

export const GrossTypeForm = ({
  organization,
  onChange,
}: {
  organization: OrganizationalUnitResponseDTO;
  onChange: () => void;
}) => {
  const theme = useTheme();
  const [showForm, setShowForm] = useState(organization.grossType == null);
  const [basePrice, setBasePrice] = useState<OrganizationalUnitResponseDTO["basePrice"] | undefined>(
    organization.basePrice
  );
  const [grossType, setGrossType] = useState<string | undefined>(
    organization.grossType ?? (isCustomer(organization) ? undefined : "MARKUP")
  );

  if (!showForm && organization.basePrice != null) {
    return (
      <Box marginTop={2} display={"flex"} alignItems={"center"}>
        <Box flex={1}>
          <Typography color={"textSecondary"}>
            This organization uses <strong>{formatBasePrice(organization.basePrice)}</strong> with{" "}
            <strong>{formatGrossType(organization)}</strong> as method to calculate the{" "}
            {formatCalculationGoal(organization)}.
          </Typography>
        </Box>
        <Tooltip title={!isRoot(organization) ? "This can only be edited on the root of this organization." : "Edit"}>
          <div>
            <IconButton onClick={() => setShowForm(true)} disabled={!isRoot(organization)}>
              <EditIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Box>
    );
  }

  const inputSelect = css({ width: 250, maxWidth: 250, marginRight: theme.spacing(2) });

  return (
    <>
      <Grid>
        <Box margin={theme.spacing(2, 0)}>
          <Typography color={"textSecondary"}>
            Select base price and calculation method to calculate the {formatCalculationGoal(organization)}. This is
            applied to the entire organization.
          </Typography>
        </Box>
      </Grid>
      <Grid>
        <Box margin={theme.spacing(2, 0)}>
          {((organization.grossType != null && organization.grossType !== grossType) ||
            organization.basePrice !== basePrice) && (
            <Alert variant="outlined" severity="warning">
              Changing the calculation method deletes all price calculations set for this customer.
            </Alert>
          )}
        </Box>
      </Grid>
      <Grid>
        <Box marginTop={3} flexDirection={"row"} display={"flex"}>
          <FormSelectSingle
            value={basePrice ?? ""}
            label={"Base price"}
            handleChange={(e) => setBasePrice(e.target.value)}
            options={[
              {
                key: "RETAIL",
                label: GrossTypeText.retailPrice.title + (isCustomer(organization) ? " (default)" : ""),
              },
              {
                key: "INVOICED",
                label: GrossTypeText.invoicePrice.title + (isCustomer(organization) ? "" : " (default)"),
              },
              { key: "DISCOUNT", label: GrossTypeText.priceAfterDiscount.title },
            ]}
            id={"price-type"}
            fullWidth={false}
            noGrid={true}
            classesFormControl={{ root: inputSelect }}
          />

          <Tooltip
            title={isCustomer(organization) ? "" : "For internal organizations, only 'Markup' is supported"}
            placement={"bottom"}
          >
            <div>
              <FormSelectSingle
                value={grossType ?? ""}
                label={"Calculation method"}
                handleChange={(e) => setGrossType(e.target.value)}
                options={[
                  {
                    key: AdjustmentType.MARKUP.toUpperCase(),
                    label: capitalize(formatGrossType({ type: organization.type, grossType: "MARKUP" })) + " %",
                  },
                  ...(isCustomer(organization)
                    ? [{ key: AdjustmentType.MARGIN.toUpperCase(), label: capitalize(AdjustmentType.MARGIN) + " %" }]
                    : []),
                ]}
                id={"grossType-select"}
                fullWidth={false}
                noGrid={true}
                disabled={!isCustomer(organization)}
                classesFormControl={{ root: inputSelect }}
              />
            </div>
          </Tooltip>
        </Box>
      </Grid>

      <Grid>
        <Box margin={theme.spacing(2, 0)}>
          {(() => {
            switch (basePrice) {
              case "RETAIL":
                return <InfoBox {...GrossTypeText.retailPrice} />;
              case "INVOICED":
                return <InfoBox {...GrossTypeText.invoicePrice} />;
              case "DISCOUNT":
                return <InfoBox {...GrossTypeText.priceAfterDiscount} />;
            }
          })()}
        </Box>
        <Box margin={theme.spacing(2, 0)}>
          {(() => {
            switch (grossType) {
              case AdjustmentType.MARKUP.toUpperCase():
                return (
                  <InfoBox
                    {...(organization.type === OrganizationUnitType.CUSTOMER
                      ? GrossTypeText.profit
                      : GrossTypeText.markup)}
                  />
                );
              case AdjustmentType.MARGIN.toUpperCase():
                return <InfoBox {...GrossTypeText.margin} />;
            }
          })()}
        </Box>
      </Grid>

      <DialogActions>
        <Button onClick={() => setShowForm(false)}>Cancel</Button>
        <ButtonConfirm onClick={onSave}>Save</ButtonConfirm>
      </DialogActions>
    </>
  );

  function onSave() {
    updateOrganizationalUnit(organization.id, {
      ...organization,
      grossType: grossType,
      basePrice: basePrice,
    })
      .then((_) => {
        messageSuccess("Data saved");
        setShowForm(false);
        onChange();
      })
      .catch((e) => {
        messageError(e.message);
      });
  }
};

function InfoBox(props: { title: string; description: string }) {
  return (
    <Alert variant="outlined" severity="info">
      <strong>{props.title}</strong>: {capitalize(props.description)}.
    </Alert>
  );
}
