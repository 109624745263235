import { useState } from "react";
import { Typography } from "@material-ui/core";
import { BillingMonthsOverview } from "./BillingMonthsOverview";
import { BillingMonthSpecification } from "./BillingMonthSpecification";
import { CFCard, CFContainer, CFPageTitle } from "../../components/layout/CFContainer";
import { BillingPeriodSelect } from "../../app/config/BillingPeriodSelect";
import { useSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";

export const getBillingPageTitle = () => "Billing Information";

export function BillingOverviewPage() {
  const [billingPeriod, setBillingPeriod] = useState(null as string | null);
  const selected = useSelectedBillingPeriods();

  if (billingPeriod && selected && !selected?.periods.find((it) => it.id === billingPeriod)) {
    // Reset billing period after changing the billing cycle
    setBillingPeriod(null);
  }

  return (
    <CFContainer>
      <CFPageTitle header={getBillingPageTitle()}>
        <BillingPeriodSelect />
      </CFPageTitle>
      <CFCard>
        <BillingMonthsOverview selected={billingPeriod} setSelected={setBillingPeriod} />
      </CFCard>
      <CFCard>
        {billingPeriod ? (
          <BillingMonthSpecification selectedYearMonth={billingPeriod} />
        ) : (
          <Typography>Select a month in the chart above to view the specification</Typography>
        )}
      </CFCard>
    </CFContainer>
  );
}
