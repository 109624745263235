import { makeStyles } from "@material-ui/core/styles";
import { useOrganizationContact } from "../../../../clients/organizationalunit.client";
import { TilesChooser } from "../../../../components/dialogs/TilesChooser";
import { OrganizationalUnitContactDTO, OrganizationalUnitResponseDTO } from "../../../../open-api";
import { AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { OrganizationalUnitContactDialogCreate } from "../../dialogs/OrganizationalUnitContactDialogCreate";
import { OrganizationalUnitContactCard } from "../../components/OrganizationContactCard";
import { useDialog } from "../../../../hooks/dialog.hooks";
import { OrganizationalUnitContactDialogEdit } from "../../dialogs/OrganizationalUnitContactDialogEdit";
import { GenericIcon, Icon } from "../../../../theme/Icon";
import { useCanEdit } from "../../../../hooks/auth.hooks";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 120,
    height: 120,
  },
}));

interface OrganizationContactProps {
  organization: OrganizationalUnitResponseDTO;
}

export const AccordionContact = ({ organization }: OrganizationContactProps) => {
  const classes = useStyles();

  const [contact, refreshContact] = useOrganizationContact(organization.id);

  const [editOrganizationContact, setEditOrganizationContact, editOrganizationContactDialogProps] =
    useDialog<OrganizationalUnitContactDTO>();

  const [createOrganizationContact, setCreateOrganizationContact, createOrganizationContactDialogProps] =
    useDialog<OrganizationalUnitResponseDTO>();

  const content = contact ? (
    <>
      <OrganizationalUnitContactCard onEdit={setEditOrganizationContact} organizationContact={contact} />
      {editOrganizationContact && (
        <OrganizationalUnitContactDialogEdit
          {...editOrganizationContactDialogProps}
          organizationalUnitContact={editOrganizationContact}
          organizationalUnit={organization}
          onSuccess={() => {
            refreshContact();
            editOrganizationContactDialogProps.onClose();
          }}
        />
      )}
    </>
  ) : (
    <>
      <TilesChooser
        tileList={[
          {
            contents: <Icon icon={GenericIcon.CONTACT} className={classes.logo} />,
            title: "Add Contact",
            onClick: () => setCreateOrganizationContact(organization),
          },
        ]}
        buttonCancel={false}
      />
      {createOrganizationContact && (
        <OrganizationalUnitContactDialogCreate
          {...createOrganizationContactDialogProps}
          organizationalUnit={createOrganizationContact}
          onSuccess={() => {
            refreshContact();
            editOrganizationContactDialogProps.onClose();
          }}
        />
      )}
    </>
  );

  return (
    <AccordionItemList
      item={{
        id: "organization-actions",
        summaryMain: "Contact",
        summarySub: "Details for this organization",
        details: content,
        action:
          useCanEdit() &&
          (contact
            ? {
                icon: GenericIcon.EDIT,
                tooltip: "Edit contact",
                onClick: () => setEditOrganizationContact(contact),
              }
            : {
                icon: GenericIcon.ADD,
                tooltip: "Add contact",
                onClick: () => setCreateOrganizationContact(organization),
              }),
      }}
    />
  );
};
