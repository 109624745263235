import { InvoiceResponseDTO } from "../open-api";
import { useDataClient } from "../hooks/client.hooks";
import { deleteRequest, getRequest } from "../utils/api.utils";

export const useInvoices = () => {
  return useDataClient<InvoiceResponseDTO[]>(`/api/invoices`, {
    onSuccess: (res) =>
      res.sort((a, b) => new Date(b.billingPeriod.to).valueOf() - new Date(a.billingPeriod.to).valueOf()),
    authenticatedOnly: true,
  });
};

export const deleteInvoicesForCollectorId = (collectorId: string) => deleteRequest(`/api/invoices/${collectorId}`);

export const prepareDownload = (invoiceId: string, useRate: boolean, orgId?: string) => {
  const params = new URLSearchParams();
  if (useRate) {
    params.set("useRate", "true");
  }
  if (orgId) {
    params.set("organizationalUnitId", orgId);
  }
  return getRequest(`/api/lineitems/raw/${invoiceId}?${params.toString()}`);
};
