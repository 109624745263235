import { AttributeTypeDTO } from "../../../open-api";

export function attributeEquals(
  self: Pick<AttributeTypeDTO, "id" | "collectorId">,
  other: Pick<AttributeTypeDTO, "id" | "collectorId">
) {
  if (self.collectorId == null) return self.id === other.id;
  return self.id === other.id && self.collectorId === other.collectorId;
}

export function attributeId(self?: Pick<AttributeTypeDTO, "id" | "collectorId">) {
  if (self == null) return JSON.stringify(null);
  return JSON.stringify({ id: self.id, collectorId: self.collectorId });
}

export function parseAttributeId(json: string): Pick<AttributeTypeDTO, "id" | "collectorId"> {
  return JSON.parse(json) ?? null;
}

export const emptyAttribute = { key: "null", label: "<undefined>" };
