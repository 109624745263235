import { CSVType, ISO_8601_DATE_FORMAT, NUMBER_FORMAT_KEYS } from "../../../clients/collector.client";
import { DateTime } from "luxon";
import { Typography } from "@material-ui/core";
import { LOCALE, NL_NUMBER_FORMAT_REGEX, US_NUMBER_FORMAT_REGEX } from "../../../utils/localization.utils";
import { NumberParser } from "../../../utils/functional/number.utils";
import { DATE_FORMAT_ID, NUMBER_FORMAT_ID, useCsvUploadFormMethods } from "./CsvUploadForm";
import { configFieldDefaultId } from "./CsvConfigFieldMapper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { UploadCsvFieldResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);

interface CsvConfigPreviewFieldProps {
  field: UploadCsvFieldResponseDTO;
  fieldValue: string;
}

export const CsvConfigPreviewField = ({ field, fieldValue }: CsvConfigPreviewFieldProps) => {
  const classes = useStyles();
  const formMethods = useCsvUploadFormMethods();
  const dateFormat = formMethods.watch(DATE_FORMAT_ID);
  const numberFormat = formMethods.watch(NUMBER_FORMAT_ID);

  // first see if we have a field value from the CSV
  // otherwise grab the default value if it's set
  // otherwise we got nothin' and return
  fieldValue = fieldValue || formMethods.getValues(configFieldDefaultId(field.key)) || "";
  if (fieldValue === "") {
    return <></>;
  }

  // if it's the date, we have to convert the DateTime object back to a string first
  if (field.type === CSVType.DATE && typeof fieldValue === "object") {
    fieldValue = (fieldValue as unknown as DateTime).toFormat((dateFormat as string) || ISO_8601_DATE_FORMAT);
  }

  if (field.type === CSVType.DATE && !!dateFormat) {
    // parse by date
    const parsedDate = DateTime.fromFormat(fieldValue, dateFormat as string);
    if (!parsedDate.isValid) {
      return <Typography className={classes.error}>Invalid Date Format: {fieldValue}</Typography>;
    }
    return <>{parsedDate.toFormat(dateFormat as string)}</>;
  }

  if (field.type === CSVType.NUMBER && !!numberFormat) {
    let numberValue: number;
    let parsedNumber: string;

    if (typeof fieldValue === "string" && numberFormat !== NUMBER_FORMAT_KEYS.DEFAULT) {
      const locale: string = numberFormat === NUMBER_FORMAT_KEYS.EU ? LOCALE.NL : LOCALE.US;
      const regex = new RegExp(locale === LOCALE.US ? US_NUMBER_FORMAT_REGEX : NL_NUMBER_FORMAT_REGEX);
      numberValue = regex.test(fieldValue) ? new NumberParser(locale).parse(fieldValue) : Number.NaN;
      parsedNumber = Intl.NumberFormat(locale).format(numberValue);
    } else {
      numberValue = Number(fieldValue);
      parsedNumber = numberValue.toString();
    }

    if (parsedNumber === "NaN") {
      return <Typography className={classes.error}>Invalid Number Format: {fieldValue}</Typography>;
    }
    return <>{parsedNumber}</>;
  }

  return <>{fieldValue}</>;
};
