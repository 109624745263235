import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { SubmitHandler } from "react-hook-form";
import { useApiCall } from "../../../hooks/client.hooks";
import { Button, DialogActions, Grid } from "@material-ui/core";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import { DataSourceResponseDTO, updateCollector, useCollectors } from "../../../clients/collector.client";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { UpdateCollectorRequestDTO } from "../../../open-api";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { ControlledTextField } from "../../../components/form/FormComponents";

interface DataSourceFormEditProps {
  dataSource: DataSourceResponseDTO;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export interface UpdateDatasourceFormValues {
  name: string;
}

export function DataSourceFormEdit({ dataSource, onClose, onSuccess, onError }: DataSourceFormEditProps) {
  const [update] = useApiCall(updateCollector);
  const [dataSources] = useCollectors();

  const defaultValues: UpdateDatasourceFormValues = {
    name: dataSource.name || "",
  };

  const isDataSourceNameUnique = (name: string) => {
    return !dataSources?.find((dataSource) => dataSource.name.toLowerCase() === name.toLowerCase());
  };

  const { handleSubmit, control } = useCFForm({
    schema: z.object({
      name: z.string().refine((val) => isDataSourceNameUnique(val), {
        message: "Data source name must be unique",
      }),
    }),
    defaultValues,
  });

  const handleClose = () => {
    onClose();
  };

  const handle: SubmitHandler<UpdateCollectorRequestDTO> = (values) => {
    // remove multiple whitespaces from data source name
    const name = values?.name?.trim().replace(/\s\s+/g, " ");
    return update(dataSource.id, { name })
      .then((_) => {
        messageSuccess("Data source information updated");
        onSuccess && onSuccess();
      })
      .catch((e) => {
        messageError(e.message);
        onError && onError();
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handle)}>
        <DialogTitleCloseable onClose={handleClose}>
          Edit data source: "{dataSource && dataSource.name}"
        </DialogTitleCloseable>
        <DialogContent>
          <Grid item xs={12}>
            <FormTitleField text={"Collector information:"} />
            <ControlledTextField control={control} name={"name"} label={"Data source name"} fullWidth />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonConfirm>Save</ButtonConfirm>
        </DialogActions>
      </form>
    </>
  );
}
