import { Box } from "@material-ui/core";
import { CFContainer } from "../layout/CFContainer";
import { ReactNode } from "react";

interface TabPanelProps {
  children: ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <CFContainer>{children}</CFContainer>}
    </Box>
  );
}
