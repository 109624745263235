import { API } from "./context.service";
import { AzureSsoConfigRequestDTO, AzureSsoConfigResponseDTO } from "../open-api";

export interface AzureSSOService {
  getConfig(): Promise<AzureSsoConfigPresentation | null>;

  putConfig(azureSsoConfigRequestPresentation: AzureSsoConfigRequestPresentation): Promise<AzureSsoConfigResponseDTO>;

  deleteConfig(id: string): Promise<void>;
}

export interface AzureSsoConfigPresentation {
  id?: string;
  adDomain: string;
  applicationId: string;
  groupId: string;
  workspaceId: string;
  ssoEnabled: boolean;
  ssoForced: boolean;
  domains: string;
}

export interface AzureSsoConfigRequestPresentation {
  adDomain: string;
  applicationId: string;
  groupId: string;
  ssoEnabled: boolean;
  ssoForced: boolean;
  domains: string;
}

// These mappings are being done in the FE client because we expect a very long list of entries (~100),
// so the data does not get lost in the communication in case of very long strings.
function mapToPresentation(config: AzureSsoConfigResponseDTO): AzureSsoConfigPresentation {
  return {
    ...config,
    domains: config.domains.toString(),
  };
}

function mapToRequest(config: AzureSsoConfigRequestPresentation): AzureSsoConfigRequestDTO {
  return {
    ...config,
    domains: config.domains.split(","),
  };
}

export class LiveAzureSSOService implements AzureSSOService {
  constructor(private api: API) {}

  getConfig = async (): Promise<AzureSsoConfigPresentation | null> => {
    try {
      const config = await this.api.azureSSO.getConfig();
      return mapToPresentation(config);
      // @ts-ignore
    } catch (e: Error) {
      if (e.message?.includes("SSO config not set for current workspace")) {
        return Promise.resolve(null);
      } else {
        return Promise.reject(e);
      }
    }
  };

  putConfig = (azureSsoConfigPresentation: AzureSsoConfigPresentation): Promise<AzureSsoConfigResponseDTO> => {
    const azureSsoConfigRequestDTO = mapToRequest(azureSsoConfigPresentation);

    return this.api.azureSSO.putConfig({
      azureSsoConfigRequestDTO,
    });
  };

  deleteConfig = (id: string) => {
    return this.api.azureSSO.deleteConfig({
      configId: id,
    });
  };
}
