import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import logo from "../../assets/img/C-Facts-logo.png";
import { Route, Switch, useLocation } from "react-router-dom";
import { SignInComponent } from "./components/SignInComponent";
import { NoMatchPage } from "../../app/NoMatchPage";
import { WelcomeComponent } from "./components/WelcomeComponent";
import { ResetPasswordComponent } from "./components/ResetPasswordComponent";
import { ForgotComponent } from "./components/ForgotComponent";
import { messageError, messageInfo, messageSuccess } from "../../services/message.service";
import { useRedirect } from "../../hooks/router.hooks";
import { useIsAuthenticated } from "../../hooks/auth.hooks";
import { deleteLocalStorageValue, getLocalStorageValue } from "../../utils/localStorage.utils";
import { MFAComponent } from "./components/MFAComponent";
import { ForceMFAComponent } from "./components/ForceMFAComponent";
import { SSOComponent } from "./components/SSOComponent";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(4, 0),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    margin: theme.spacing(1),
    width: 350,
  },
  spinner: {
    height: 500,
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const location = useLocation();
  const redirect = useRedirect();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // Handle success messages
    const state = new URLSearchParams(location.search).get("state");
    if (state != null) {
      switch (state) {
        case "expired":
          messageInfo("You have been signed out due to inactivity, please sign in again.");
          break;
        case "invalid-token":
          messageError("Invalid token provided");
          break;
        case "error":
          messageError("Invalid username or password");
          break;
        case "logout":
          messageSuccess("Successfully logged out");
          break;
        case "welcome":
          messageSuccess("Your password has been set! Login with your email and newly created password");
          break;
        case "forgot":
          messageSuccess("An email has been send with instructions to reset your password");
          break;
      }
      redirect(location.pathname, { state: null });
    }
  }, [location, redirect]);

  useEffect(() => {
    // handle redirect if signed in.
    if (isAuthenticated) {
      const redirectRoute = getLocalStorageValue("redirectRoute");
      const redirectSearch = getLocalStorageValue("redirectSearch");
      if (redirectRoute) {
        deleteLocalStorageValue("redirectRoute");
        deleteLocalStorageValue("redirectSearch");
        if (redirectSearch) {
          redirect(redirectRoute, Object.fromEntries(new URLSearchParams(redirectSearch)));
        } else {
          redirect(redirectRoute);
        }
        //if authenticated user opens the welcome link for a new user,
        //do not open the dashboard through the /welcome page,
        //but rather, let the user log out and set the new password for the new user.
      } else if (!window.location.pathname.includes("/login/welcome/")) {
        redirect("/welcome");
      }
    }
  }, [isAuthenticated, redirect]);

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.logo}>
            <img src={logo} alt="c-facts logo" className={classes.logo} />
          </div>

          <Switch>
            <Route path="/login" exact component={SignInComponent} />
            <Route path="/login/mfa" exact component={MFAComponent} />
            <Route path="/login/forgot" exact component={ForgotComponent} />
            <Route path="/login/sso" exact component={SSOComponent} />
            <Route path="/login/reset/:code" exact component={ResetPasswordComponent} />
            <Route path="/login/welcome/:code" exact component={WelcomeComponent} />
            <Route path="/login/mfa/forced" exact component={ForceMFAComponent} />
            <Route component={NoMatchPage} />
          </Switch>
        </CardContent>
      </Card>
    </Container>
  );
}
