import { createContext, useEffect, useMemo, useState } from "react";
import { NewDashPage } from "./NewDashPage";
import { StateContext, useContextOrThrow } from "../../hooks/context.hooks";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import { Dashboard } from "./model/Dashboard";
import { useGetAllDashboards } from "../../clients/dashboard.client";
import { internalizeDashboards } from "./model/StoredDashboard";
import { useDashFilters } from "./selectors/dashboard.selector";
import { useServices } from "../../services/context.service";
import { onMultipleFiltersSelected } from "./model/GroupSegmentFilter";
import { filterUndefined } from "../../utils/functional/array.utils";

export function NewDashPageProvider() {
  const [storedDashboards] = useGetAllDashboards();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [selectedId, setSelectedId] = useState<Dashboard["id"] | undefined>();
  const [shouldLoad, setShouldLoad] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (storedDashboards != null) {
      const internalized = internalizeDashboards(storedDashboards);
      setDashboards(internalized);
      setSelectedId((it) => it ?? internalized[0].id);
    }
  }, [storedDashboards]);

  // ! are safe, because of check below
  const memoizedDashboards = useMemo(() => [dashboards!, setDashboards] as const, [dashboards, setDashboards]);
  const memoizedSelectedId = useMemo(() => [selectedId, setSelectedId] as const, [selectedId, setSelectedId]);
  const memoizedShouldLoad = useMemo(() => [shouldLoad, setShouldLoad] as const, [shouldLoad, setShouldLoad]);

  if (dashboards == null)
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );

  return (
    <DashboardsContext.Provider value={memoizedDashboards}>
      <SelectedDashboardIdContext.Provider value={memoizedSelectedId}>
        <DashboardShouldLoadContext.Provider value={memoizedShouldLoad}>
          <DashboardFilterMigrationContext.Provider value={[hasError, setHasError] as const}>
            <NewDashPage />
          </DashboardFilterMigrationContext.Provider>
        </DashboardShouldLoadContext.Provider>
      </SelectedDashboardIdContext.Provider>
    </DashboardsContext.Provider>
  );
}

export const DashboardsContext = createContext<StateContext<Dashboard[]>>(undefined);
export const useDashboards = () => useContextOrThrow(DashboardsContext)[0];
export const useSetDashboards = () => useContextOrThrow(DashboardsContext)[1];

export const SelectedDashboardIdContext = createContext<StateContext<Dashboard["id"] | undefined>>(undefined);
export const useSelectedDashboardId = () => useContextOrThrow(SelectedDashboardIdContext)[0];
export const useSetSelectedDashboardId = () => useContextOrThrow(SelectedDashboardIdContext)[1];

export const DashboardShouldLoadContext = createContext<StateContext<boolean>>(undefined);
export const useDashboardShouldLoad = () => useContextOrThrow(DashboardShouldLoadContext)[0];
export const useSetDashboardShouldLoad = () => useContextOrThrow(DashboardShouldLoadContext)[1];

export const DashboardFilterMigrationContext = createContext<StateContext<boolean>>(undefined);
export const useDashboardMigrationError = () => useContextOrThrow(DashboardFilterMigrationContext)[0];
export const useSetDashboardMigrationError = () => useContextOrThrow(DashboardFilterMigrationContext)[1];

export const useMigrationHook = () => {
  const [filters, setFilters] = useDashFilters();
  const queryService = useServices().aggregate;
  const hasError = useDashboardMigrationError();
  useEffect(() => {
    if (hasError) {
      console.log("An error has occurred, trying to migrate the issue");
      filters
        // Only check filters with actual values in them
        .filter((filter) => filter.filters.length > 0)
        .forEach((filter) => {
          queryService.options(filter.segment.id, []).then((res) => {
            const newFilters = filter.filters
              .map((it) => res.options.find((option) => option.display === it.id))
              // Filter options that aren't found
              .filter(filterUndefined);
            setFilters(
              onMultipleFiltersSelected(
                filters,
                filter,
                newFilters.map((it) => ({ id: it.id, label: it.display }))
              )
            );
          });
        });

      // Loop through all filters that are configured
      // Get results from options call
      // Find actual id's corresponding to the display values
      // Replace filter
      // Store dashboard
    }
  }, [hasError, filters, setFilters, queryService]);
};
