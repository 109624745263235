import {
  DataSourceResponseDTO,
  deleteDataSource,
  scheduleCollector,
  updateCollector,
} from "../../clients/collector.client";
import { useState } from "react";
import { useApiCall } from "../../hooks/client.hooks";
import { messageError, messageSuccess } from "../../services/message.service";
import { useRedirect } from "../../hooks/router.hooks";
import { GenericIcon } from "../../theme/Icon";
import { useWorkspaceRole, WorkspaceRole } from "../../hooks/auth.hooks";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";
import { deleteInvoicesForCollectorId } from "../../clients/invoices.client";
import { CFactsAction } from "../../components/buttons/CFactsAction";
import { ActionIconLabelButton } from "../../components/buttons/ActionIconLabelButton";
import { DataSourceDialogEdit } from "./DataSourceDialogEdit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { CFCard } from "../../components/layout/CFContainer";
import { CollectorConfigResponseDTO } from "../../open-api";
import { CredentialsCard } from "./credentials/CredentialsCard";
import { ToggleFilterCard } from "./config/ToggleFilterCard";

interface DataSourceSettingsProps {
  collector: DataSourceResponseDTO;
  refreshCollector: () => void;
  collectorConfig: CollectorConfigResponseDTO | null;
  refreshCollectorConfig: () => void;
}

export function DataSourceSettings({
  collector,
  refreshCollector,
  collectorConfig,
  refreshCollectorConfig,
}: DataSourceSettingsProps) {
  const role = useWorkspaceRole();

  // Delete
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [handleDeleteDataSource, isDeleting] = useApiCall(deleteDataSource);

  // Edit
  const [showEdit, setShowEdit] = useState(false);

  // Enable/disable
  const [handleEditDataSource, isEditing] = useApiCall(updateCollector);
  const handleEnableDisable = (enable: boolean) =>
    handleEditDataSource(collector.id, { isEnabled: enable }).then((it) => {
      refreshCollector();
      messageSuccess(`Collector has been ${enable ? "enabled" : "disabled"}`);
    });

  // Parts for admin feature
  const adminFeature = useFeatureToggle(FEATURES.admin);
  const [onSchedule, isScheduling] = useApiCall(scheduleCollector);
  const [onDelete, isDeletingInvoices] = useApiCall(deleteInvoicesForCollectorId);

  const redirect = useRedirect();

  const actions: CFactsAction[] = [];
  const dangerActions: CFactsAction[] = [];

  if (role === WorkspaceRole.ADMIN && collector.owned) {
    actions.push({
      icon: GenericIcon.EDIT,
      tooltip: "Edit data source name",
      label: "Edit data source name",
      onClick: () => setShowEdit(true),
    });

    // Handle enable/disable button
    if (collector.canDisable && collector.isEnabled) {
      actions.push({
        icon: GenericIcon.PAUSE,
        tooltip: "Pause data source. Disable the collector and pause collecting invoice data",
        label: "Pause data source. This will disable the collector and pause collecting invoice data",
        disabled: isEditing,
        onClick: () => handleEnableDisable(false),
      });
    } else if (collector.canDisable) {
      actions.push({
        icon: GenericIcon.PLAY_ARROW,
        tooltip: "Enable data source",
        label: "Enable data source",
        disabled: isEditing,
        onClick: () => handleEnableDisable(true),
      });
    }

    // Handle delete button
    if (collector.canDisable && collector.isEnabled) {
      dangerActions.push({
        icon: GenericIcon.DELETE,
        tooltip: "This data source can only be deleted if it is paused",
        label: "This data source can only be deleted if it is paused",
        disabled: true,
        onClick: () => setShowConfirmDelete(true),
      });
    } else {
      dangerActions.push({
        icon: GenericIcon.DELETE,
        tooltip: "Delete this data source",
        label: "Delete this data source",
        disabled: isDeleting,
        onClick: () => setShowConfirmDelete(true),
      });
    }
  }

  if (adminFeature && "accountId" in collector) {
    actions.push({
      icon: GenericIcon.ADD,
      tooltip: "Force collector to run",
      label: "Force collector to run",
      disabled: isScheduling,
      onClick: () =>
        onSchedule(collector.id).then(() => messageSuccess("Collector forced to run, check invoice runs tab")),
    });
    dangerActions.push({
      icon: GenericIcon.DELETE,
      tooltip: "Delete invoices corresponding to this collector",
      label: "Delete invoices corresponding to this collector",
      disabled: isDeletingInvoices,
      onClick: () =>
        onDelete(collector.id).then(() => messageSuccess("Deleted all invoices corresponding to this data source")),
    });
  }

  return (
    <>
      <CFCard header={"Actions"}>
        {actions.map((action) => {
          return <ActionIconLabelButton action={action} />;
        })}
        {dangerActions.map((action) => {
          return <ActionIconLabelButton action={action} />;
        })}
      </CFCard>
      <CredentialsCard collectorId={collector.id} />
      <ToggleFilterCard collector={collector} collectorConfig={collectorConfig} onSuccess={refreshCollectorConfig} />
      {showEdit && (
        <DataSourceDialogEdit
          dataSource={collector}
          open={showEdit}
          onClose={() => setShowEdit(false)}
          onSuccess={() => {
            refreshCollector();
            setShowEdit(false);
          }}
        />
      )}

      {showConfirmDelete && (
        <ConfirmDialog
          open={showConfirmDelete}
          title={"Delete data source"}
          content={`Are you sure you want to delete data source: ${collector.name}? This will also delete ALL invoices associated with this datasource!`}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={() => {
            return handleDeleteDataSource(collector.id)
              .then(() => {
                messageSuccess("Data source deleted");
                redirect("/datasources");
              })
              .catch((e) => {
                messageError(e.message);
              });
          }}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
    </>
  );
}
