import { makeStyles } from "@material-ui/core/styles";
import { ChangeEvent, Children, useState } from "react";
import { Box, Button, Grid, Paper, Popover, TextField, Tooltip, Typography } from "@material-ui/core";
import { ButtonConfirm } from "../form/ButtonConfirm";
import DialogActions from "@material-ui/core/DialogActions";
import { TooltipProps } from "@material-ui/core/Tooltip/Tooltip";
import { TAG_DIALOG_FULLSCREEN_Z_INDEX } from "./FullScreenDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import { roundToTwoDecimals } from "../../utils/localization.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    minWidth: theme.spacing(72),
  },
  popper: {
    zIndex: TAG_DIALOG_FULLSCREEN_Z_INDEX + 10,
  },
  sliderRoot: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    width: "82%",
  },
  input: {
    marginTop: 0,
    marginBottom: 0,
    minWidth: 136,
    height: 48,
    marginLeft: theme.spacing(4),
  },
  error: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  dialogActions: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
}));

interface PositiveNumberInputPopperProps extends TooltipProps {
  onSave: (value: any) => void;
  onDelete?: (value: any) => void;
  maxValue?: number;
  initialValue?: number;
  allowZero?: boolean;
  label: string;
}

export const PositiveNumberInputPopper = ({
  title,
  onSave,
  onDelete,
  maxValue = 100,
  initialValue = maxValue,
  allowZero = false,
  label,
  ...props
}: PositiveNumberInputPopperProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [value, setValue] = useState<number | string>(roundToTwoDecimals(initialValue));
  const [error, setError] = useState<string>();

  const roundAndSetValue = (value: number) => {
    setValue(roundToTwoDecimals(value));
  };

  const clearError = () => setError(undefined);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "") {
      clearError();
      return setValue("");
    }

    if (Number(value) > maxValue) {
      setError(`Exceeds max value ${maxValue}`);
      return;
    }

    clearError();
    roundAndSetValue(Number(value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
      return;
    }

    clearError();
    setValue(roundToTwoDecimals(Number(value > maxValue ? maxValue : value)).toString());
  };

  const hidePopper = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSave = (e: any) => {
    if (!allowZero && Number(value) === 0) {
      setError("Value can't be 0");
      return;
    }

    onSave(value);
    hidePopper(e);
  };

  const handleDelete = (e: any) => {
    if (!onDelete) {
      return;
    }
    onDelete(value);
    hidePopper(e);
  };

  const togglePopper = (e: any) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  return (
    <Box>
      <Tooltip title={title} onClick={togglePopper}>
        {Children.only(props.children)}
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        onClose={hidePopper}
      >
        <Paper variant={"outlined"} onClick={(event) => event.stopPropagation()}>
          <Grid direction={"column"} container classes={{ root: classes.root }}>
            <Grid container item xs={12} justify={"flex-start"}>
              <Typography id="range-slider" gutterBottom>
                {label}
              </Typography>

              <TextField
                className={classes.input}
                margin="dense"
                value={value}
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  type: "number",
                  min: 0,
                  max: maxValue,
                  step: 0.01,
                }}
                error={!!error}
                helperText={error}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogActions classes={{ root: classes.dialogActions }}>
                {onDelete ? (
                  <Box display={"flex"} flexGrow={1} justifyContent={"flex-start"}>
                    <Button
                      variant="contained"
                      className={classes.error}
                      startIcon={<DeleteIcon />}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Box>
                ) : (
                  ""
                )}

                <Button onClick={hidePopper}>Cancel</Button>

                <ButtonConfirm onClick={handleSave}>Save</ButtonConfirm>
              </DialogActions>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    </Box>
  );
};
