import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ButtonConfirm } from "../../../../components/form/ButtonConfirm";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  formattedCurrency,
  ucFirst,
  getCurrencySymbol,
  roundToTwoDecimals,
} from "../../../../utils/localization.utils";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ConfirmDialog } from "../../../../components/dialogs/ConfirmDialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  BUDGET_DEFAULT_CURRENCY,
  BudgetType,
  createOrUpdateBudget,
} from "../../../../utils/functional/budgetalerts.util";
import { messageError, messageSuccess } from "../../../../services/message.service";
import { deleteBudget } from "../../../../clients/budgetalerts.client";
import { mapBudgetType } from "../../../../mappers/organization.mapper";
import { useCanEditAdminAndCView } from "../../../../hooks/auth.hooks";
import { CURRENCIES } from "../../../../utils/functional/money.utils";
import { DateTime } from "luxon";
import { PaperListItem } from "./PaperListItem";
import { BudgetResponseDTO, CreateBudgetRequestDTO, OrganizationalUnitResponseDTO } from "../../../../open-api";

interface BudgetAlertInlineEditBudgetProps {
  setEditFn?: (id: string, editing: boolean) => void;
  isEditFn?: (id: string) => boolean;
  budget: BudgetResponseDTO | undefined;
  spending?: number;
  organization: OrganizationalUnitResponseDTO;
  budgetType: BudgetType;
  refreshBudgets: () => void;
}

const formKey = "budget-alert-inline-budget-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    budgetBox: {
      marginRight: theme.spacing(4),
    },
    budgetDisplay: {
      fontSize: 18,
    },
    budgetInputField: {
      width: 100,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    budgetType: {
      display: "inline-flex",
    },
    confirmButton: {
      marginLeft: theme.spacing(3),
      marginTop: 12,
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
      marginTop: 12,
    },
    formSelect: {
      minWidth: 24,
    },
    formSelectType: {
      minWidth: 80,
    },
    deleteIcon: {
      fontSize: 40,
    },
    listItemSpending: {
      background: "rgba(255, 255, 255, 0.1)",
      marginBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    listItemSpendingTextMoney: {
      fontWeight: "bold",
      fontSize: 17,
    },
    listItemSpendingText: {
      marginLeft: theme.spacing(1),
    },
  })
);

export function BudgetAlertInlineEditBudget({
  setEditFn,
  isEditFn,
  budget,
  spending,
  refreshBudgets,
  organization,
  budgetType,
}: BudgetAlertInlineEditBudgetProps) {
  const [editState, setEditState] = useState<boolean>(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>();
  const canEdit = useCanEditAdminAndCView();

  const {
    handleSubmit,
    control,
    setValue,

    formState: { errors },
  } = useForm<CreateBudgetRequestDTO>({
    mode: "onTouched",
  });

  useEffect(() => {
    setValue("value", budget?.value ?? 0);
    setValue("currency", budget?.currency ?? "EUR");
  }, [budget, setValue]);

  const classes = useStyles();
  const displayBudgetType = mapBudgetType(budgetType, organization).toLowerCase();

  const onSave = (form: CreateBudgetRequestDTO) => {
    createOrUpdateBudget(organization, form || budget, budget?.id)
      .then(() => {
        refreshBudgets();
        messageSuccess("Budget Saved");
      })
      .catch((e) => {
        messageError(e.message);
      });
  };

  const onDelete = async () => {
    return (
      budget?.id &&
      deleteBudget(organization.id, budget?.id)
        .then(() => {
          refreshBudgets();
          messageSuccess("Budget deleted");
        })
        .catch((e) => {
          messageError(e.message);
        })
    );
  };

  const setEdit = (state: boolean) => {
    if (!canEdit) {
      return;
    }
    if (!!setEditFn) {
      return setEditFn(formKey, state);
    }
    setEditState(state);
  };

  const isEdit = (key: string): boolean => {
    return !!isEditFn ? isEditFn(key) : editState;
  };

  return isEdit(formKey) ? (
    <form
      key={formKey}
      onSubmit={handleSubmit((form) => {
        setEdit(false);
        onSave({ ...form, budgetType: budgetType });
      })}
    >
      <Controller
        render={({ field: { onChange, value, name } }) => (
          <FormControl error={!!errors.currency}>
            <InputLabel id="currency-select-label">Currency</InputLabel>
            <Select
              classes={{ root: classes.formSelect }}
              labelId="currency-select-label"
              id={name}
              variant={"standard"}
              value={value}
              onChange={onChange}
            >
              {CURRENCIES.map((item) => {
                return (
                  <MenuItem value={item.id} key={`${value}-${item.id}`}>
                    {getCurrencySymbol(item.id)}
                  </MenuItem>
                );
              })}
            </Select>
            {!!errors.currency && <FormHelperText>{errors.currency.message}</FormHelperText>}
          </FormControl>
        )}
        name={"currency"}
        rules={{ required: "This field is required" }}
        control={control}
        defaultValue={budget?.currency || BUDGET_DEFAULT_CURRENCY}
      />

      <Controller
        render={({ field: { onChange, onBlur, value, name } }) => (
          <TextField
            classes={{ root: classes.budgetInputField }}
            label={"Value"}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.value}
            helperText={errors.value?.message}
            variant={"standard"}
          />
        )}
        name={"value"}
        control={control}
        rules={{
          required: "A valid amount is required",
          pattern: {
            value: /^[1-9][0-9]*$/,
            message: "Invalid amount",
          },
        }}
        defaultValue={budget?.value}
      />

      <Box display={"inline-flex"}>
        <Typography classes={{ root: classes.budgetType }}>of {`${displayBudgetType}`}</Typography>
      </Box>

      <Box display={"inline-flex"}>
        <ButtonConfirm classes={{ root: classes.confirmButton }}>Save</ButtonConfirm>
        <Button classes={{ root: classes.cancelButton }} onClick={() => setEdit(false)}>
          Cancel
        </Button>
      </Box>
    </form>
  ) : (
    <>
      <Box>
        <Box flexGrow={0} className={classes.budgetBox} display={"inline-flex"} onClick={() => setEdit(true)}>
          <Typography color={"textPrimary"} classes={{ root: classes.budgetDisplay }}>
            {budget
              ? `${formattedCurrency(budget.value, budget.currency, 0)} of ${displayBudgetType}`
              : `No ${ucFirst(displayBudgetType)} Budget Configured`}
          </Typography>
        </Box>

        {canEdit && (
          <ActionIconButton
            buttonSize={"medium"}
            size={24}
            action={{
              icon: GenericIcon.EDIT,
              tooltip: "Edit",
              onClick: (e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true);
              },
            }}
          />
        )}

        {canEdit && !!onDelete && !!budget && (
          <ActionIconButton
            buttonSize={"medium"}
            size={24}
            action={{
              icon: GenericIcon.DELETE,
              tooltip: "Delete",
              onClick: (e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setShowConfirmDelete(true);
              },
            }}
          />
        )}

        {showConfirmDelete && (
          <ConfirmDialog
            open={showConfirmDelete}
            title={`Delete ${displayBudgetType} budget`}
            content={`Are you sure you want to delete the ${displayBudgetType} budget? This will remove all alerts and associated notifications as well. Cannot be undone.`}
            onClose={() => setShowConfirmDelete(false)}
            onConfirm={onDelete}
          >
            <DeleteOutlineIcon className={classes.deleteIcon} />
          </ConfirmDialog>
        )}
      </Box>
      {budget && spending && (
        <PaperListItem key={`alert-spending-${budget?.budgetType}-paper`} paperClassName={classes.listItemSpending}>
          <Typography classes={{ root: classes.listItemSpendingTextMoney }}>
            {roundToTwoDecimals((spending / budget.value) * 100)}% ({formattedCurrency(spending, budget.currency)})
          </Typography>
          <Typography classes={{ root: classes.listItemSpendingText }}>
            over {DateTime.local().toFormat("MMMM y")} so far
          </Typography>
        </PaperListItem>
      )}
    </>
  );
}
