import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from "../hooks/auth.hooks";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Notifications } from "@material-ui/icons";
import { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import { PopoverProps } from "@material-ui/core/Popover";
import { ListItemLink } from "../components/menu/ListItemLink";
import { CurrencySelect } from "../components/CurrencySelect";
import { getEnvironment } from "./App";
import { getRequest } from "../utils/api.utils";
import { toast } from "react-toastify";
import { useFeatureToggle } from "../hooks/ft.hooks";
import { Avatar, Badge } from "@material-ui/core";
import { WorkspaceDTO } from "../open-api";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  user: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  circle: {
    backgroundColor: "#e14138",
    color: theme.palette.common.white,
  },
  header: {
    "& span": {
      fontWeight: theme.typography.fontWeightLight,
    },
  },
}));

const REFRESH_UNREAD_NOTIFICATIONS_INTERVAL = 20000; //20sec

export function UserMenu() {
  const classes = useStyles();
  const { user, workspace } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = "primary-search-account-menu";

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState(0);
  useEffect(() => {
    async function countNumberOfUnreadNotifications() {
      return getRequest(`/api/action-center/count-unread-notifications`).then((value) => value);
    }

    countNumberOfUnreadNotifications().then((res) => setNumberOfUnreadNotifications(res));
    const interval = setInterval(() => {
      countNumberOfUnreadNotifications().then((res) => setNumberOfUnreadNotifications(res));
    }, REFRESH_UNREAD_NOTIFICATIONS_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  const getUnreadNotificationBadgeContent = () =>
    numberOfUnreadNotifications < 10 ? numberOfUnreadNotifications : "9+";

  function renderUnreadNotificationBadge(workspace: WorkspaceDTO | undefined) {
    return workspace ? (
      <IconButton
        edge="end"
        aria-label="notifications"
        aria-haspopup="true"
        color="inherit"
        onClick={openNotifications}
      >
        <Badge badgeContent={getUnreadNotificationBadgeContent()} color="error" overlap="circular">
          <Notifications color="action" />
        </Badge>
      </IconButton>
    ) : null;
  }

  const openNotifications = () => {
    window.location.href = "/account/notifications";
  };

  function getUserInitials() {
    return user?.name?.[0]
      ?.toUpperCase()
      .concat(
        user?.name.split(" ").length > 1 ? user?.name.split(" ")[user?.name.split(" ").length - 1][0].toUpperCase() : ""
      );
  }

  return (
    <>
      <CurrencySelect />
      {renderUnreadNotificationBadge(workspace)}
      <Typography onClick={handleProfileMenuOpen} className={classes.user}>
        <IconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit">
          <Avatar aria-label="User" className={classes.circle}>
            {getUserInitials()}
          </Avatar>
        </IconButton>
      </Typography>
      <DropDownUserMenu onMenuClose={handleMenuClose} anchorEl={anchorEl} menuId={menuId} username={user?.name} />
    </>
  );
}

interface DropDownUserMenuProps {
  onMenuClose: () => void;
  anchorEl: PopoverProps["anchorEl"];
  menuId: string;
  username?: string;
}

function DropDownUserMenu({ onMenuClose, menuId, anchorEl, username }: DropDownUserMenuProps) {
  const useResetDemoLink = username && ["local", "dev", "demo"].includes(getEnvironment());
  const useSwaggerLink = useFeatureToggle("swagger");
  const [isResetting, setIsResetting] = useState(false);
  const { user, workspace } = useAppContext();
  const classes = useStyles();

  const handleReset = () => {
    setIsResetting(true);
    getRequest(`/api/auth/demo/reset?customerName=${username}`).then((it) => {
      setIsResetting(false);
      toast.success(
        "Successfully refreshed demo data. The page will reload in a few seconds. If not, please reload the page manually to see all changes"
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    });
  };

  const handleLogout = () => {
    window.location.href = "/api/logout";
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
      >
        <List component="nav">
          <ListItem title={`${user?.name}`}>
            <ListItemText primary={`${user?.name}`} className={classes.header} />
          </ListItem>
          <ListItem title={`(${workspace?.name})`}>
            <ListItemText primary={`${workspace?.name}`} className={classes.header} />
          </ListItem>
          <Divider />
          <ListItemLink to={"/account"} primary={"My Account"} onClick={onMenuClose} />
          {<ListItemLink to={"/account/notifications"} primary={"Notifications"} onClick={onMenuClose} />}
          {useResetDemoLink && (
            <ListItemLink isLoading={isResetting} primary={"Reset demo data"} onClick={handleReset} />
          )}
          {useSwaggerLink && <ListItemLink to={"/account/api-docs"} onClick={onMenuClose} primary={"API Docs"} />}
          <ListItemLink
            primary={"Logout"}
            onClick={() => {
              handleLogout();
              onMenuClose();
            }}
          />
        </List>
      </Menu>
    </>
  );
}
