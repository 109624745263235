import { makeStyles } from "@material-ui/core/styles";
import { SetTypeAndUnitType } from "../OrganizationalUnitDialogCreate";
import { TilesChooser } from "../../../../components/dialogs/TilesChooser";
import { OrganizationalUnitType } from "../../../../clients/organizationalunit.client";
import { Tile } from "../../../../components/dialogs/Tiles";
import { GenericIcon, Icon } from "../../../../theme/Icon";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "100%",
    maxHeight: "100%",
    fontSize: 120,
  },
  logoDisabled: {
    width: "100%",
    maxHeight: "100%",
    fontSize: 120,
    color: theme.palette.common.black,
  },
}));

interface OrganizationalUnitDialogTilesProps {
  setTypeAndUnitType: SetTypeAndUnitType;
  onClose: () => void;
  parent?: OrganizationalUnitResponseDTO;
  buttonCancel?: boolean;
}

export const OrganizationalUnitCreateStepSelect = ({
  setTypeAndUnitType,
  onClose,
  parent,
  buttonCancel,
}: OrganizationalUnitDialogTilesProps) => {
  const classes = useStyles();

  const tileList: Tile[] = [];

  if (!parent) {
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.CUSTOMER} className={classes.logo} />
        </>
      ),
      title: "Customer",
      onClick: () => setTypeAndUnitType("CUSTOMER", "ROOT"),
    });
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.INTERNAL} className={classes.logo} />
        </>
      ),
      title: "My organization",
      onClick: () => setTypeAndUnitType("INTERNAL", "ROOT"),
    });
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.RESELLER} className={classes.logo} />
        </>
      ),
      title: "Reseller",
      onClick: () => setTypeAndUnitType("RESELLER", "ROOT"),
    });
  } else {
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.DEPARTMENT} className={classes.logo} />
        </>
      ),
      title: "Department",
      onClick: () => setTypeAndUnitType(parent.type as OrganizationalUnitType, "DEPARTMENT"),
    });
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.PROJECT} className={classes.logo} />
        </>
      ),
      title: "Project",
      onClick: () => setTypeAndUnitType(parent.type as OrganizationalUnitType, "PROJECT"),
    });
    tileList.push({
      contents: (
        <>
          <Icon icon={GenericIcon.APPLICATION} className={classes.logo} />
        </>
      ),
      title: "Application",
      onClick: () => setTypeAndUnitType(parent.type as OrganizationalUnitType, "APPLICATION"),
    });
  }

  return <TilesChooser tileList={tileList} onClose={onClose} buttonCancel={buttonCancel} />;
};
