import * as Sentry from "@sentry/react";

export function assertNever(value: never, throws = true): never {
  const error = new Error(
    `Unsound Typescript detected. The value (${JSON.stringify(value)}) was asserted to not be possible.`
  );
  if (throws) {
    throw error;
  } else {
    Sentry.captureException(error);
    return value;
  }
}
