import { Grid, Typography } from "@material-ui/core";
import { OneCardGrid } from "../../../components/grid/OneCardGrid";
import { DataSourceDialog } from "../../datasources/DataSourceDialog";

export function StepDatasource({ onSuccess }: { onSuccess: () => void }) {
  const handleSuccess = () => {
    onSuccess();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Look up your API key from your cloud supplier and add your data source to C-facts.</Typography>
      </Grid>
      <OneCardGrid>
        <DataSourceDialog onSuccess={handleSuccess} buttons={{ close: false, cancel: false }} dialogWrapper={false} />
      </OneCardGrid>
    </Grid>
  );
}
