import { failFormValidation } from "../../services/message.service";
import { TextFieldProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, FieldError, UseFormReturn } from "react-hook-form";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

type FormTextFieldProps = Omit<TextFieldProps, "error"> & {
  error?: FieldError;
  silent?: boolean;
  value?: DateTime;
  name: string;
  id?: string;
  formMethods: UseFormReturn<any>;
  rules?: RegisterOptions;
};

export function FormDateField({
  label,
  error,
  defaultValue = null,
  name,
  id = name,
  silent = false,
  formMethods,
  rules = {},
}: FormTextFieldProps) {
  const classes = useStyles();

  if (error && !silent && label) {
    failFormValidation(label as string, error.message as string);
  }

  const { control } = formMethods;

  return (
    <>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, value, name, onBlur } }) => (
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              fullWidth={true}
              error={!!error}
              inputVariant={"outlined"}
              onFocus={undefined}
              onBlur={onBlur}
              name={name}
              id={id}
              label={label}
              format="yyyy-MM-dd"
              mask={"____-__-__"}
              value={value}
              onChange={onChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        )}
        defaultValue={defaultValue}
      />
      {error ? <Typography className={classes.error}>{error.message}</Typography> : ""}
    </>
  );
}
