import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Typography } from "@material-ui/core";
import { useAppContext } from "../../hooks/auth.hooks";
import { OnboardingStepper } from "./stepper/OnboardingStepper";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", justifyContent: "center" },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    backgroundColor: "unset",
    width: "100%",
  },
}));

export function InformationPage() {
  const classes = useStyles();
  const { user } = useAppContext();

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>
            Hi <strong>{user?.name}</strong>! Welcome to your C-Facts Control Center
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Thank you for choosing C-Facts. If this is your first time here please take the following steps to get
            started.
          </Typography>
        </Grid>
        <OnboardingStepper />
      </Grid>
    </div>
  );
}
