export type GroupByFunction<T> = (data: T) => string;
type SumByFunction<T> = (data: T) => number;
type AccumulateFunction<T, A> = (prev: A, current: T) => A;

export const genericGroupBy = <T, A>(arr: T[], groupBy: GroupByFunction<T>, accumulate: AccumulateFunction<T, A>) =>
  arr.reduce(
    (accumulator: Record<string, A>, current: T): Record<string, A> => ({
      ...accumulator,
      [groupBy(current)]: accumulate(accumulator[groupBy(current)], current),
    }),
    {}
  );

export const groupBy = <T>(arr: T[], groupBy: GroupByFunction<T>) =>
  genericGroupBy<T, T[]>(arr, groupBy, accumulateList);
export const groupByAndSum = <T>(arr: T[], groupBy: GroupByFunction<T>, sumBy: SumByFunction<T>) =>
  genericGroupBy<T, number>(arr, groupBy, accumulateSum(sumBy));

export const accumulateList = <T>(prev: T[] | undefined, current: T) => [...(prev || []), current];
export const accumulateSum =
  <T>(sumBy: SumByFunction<T>) =>
  (prev: number | undefined, current: T) =>
    (prev || 0) + sumBy(current);

export const countOccurrence = <T>(arr: T[], val: T) => {
  return arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
};

export const countOccurrenceFn = <T>(arr: T[], evaluate: (val: T) => boolean) => {
  return arr.reduce((a, v) => (evaluate(v) ? a + 1 : a), 0);
};

export const associateBy = <T>(arr: T[], get: (val: T) => string): Record<string, T> => {
  return arr.reduce((a, v) => ({ ...a, [get(v)]: v }), {});
};
