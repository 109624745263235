import { ChartConfig, RangeChartConfig } from "../model/ChartConfig";

export function useChartTitles(chart: ChartConfig) {
  // TODO fix this?
  // chart.groupSegment == null ? undefined : attributes.find((it) => attributeEquals(it.type, chart.groupSegment));

  const title = (() => {
    if (chart.type === "raw") return `Total ${chart.displaySegment.label}`;
    const segmentLabel = chart.groupSegment?.label;
    return `${chart.displaySegment.label} per ${segmentLabel}`;
  })();

  const collectorName = "";

  return {
    title: chart.title != null ? chart.title : title,
    subTitle: `${title}${collectorName}`,
  };
}

export function useArgumentTitle(chart: RangeChartConfig) {
  const title = chart.argument?.label;
  const collectorName = "";
  return `${title}${collectorName}`;
}
