import { postRequest } from "../../utils/api.utils";

export const enableAllowAdminAssume = () => postRequest("/api/allow-assume/enable", {});

export const disableAllowAdminAssume = () => postRequest("/api/allow-assume/disable", {});

export const adminAssumeRole = (id: string, workspaceId: string) =>
  postRequest("/api/admin/assume", {
    id,
    workspaceId,
  }).then((res) => {
    window.location.reload();
  });

export const adminAssumeRoleLogout = () =>
  postRequest("/api/admin/assume/logout", {}).then((res) => {
    window.location.replace("/admin");
  });
