/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    AzureAdAuthenticationResultDTO,
    AzureAdRequestDTO,
    AzureSsoConfigResponseDTO,
} from '../models';

export interface GetSsoConfigRequest {
    azureAdRequestDTO: AzureAdRequestDTO;
}

export interface SsoAuthenticateRequest {
    azureAdAuthenticationResultDTO: AzureAdAuthenticationResultDTO;
}

/**
 * 
 */
export class AzureSsoControllerApi extends runtime.BaseAPI {

    /**
     */
    async getSsoConfigRaw(requestParameters: GetSsoConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AzureSsoConfigResponseDTO>> {
        if (requestParameters.azureAdRequestDTO === null || requestParameters.azureAdRequestDTO === undefined) {
            throw new runtime.RequiredError('azureAdRequestDTO','Required parameter requestParameters.azureAdRequestDTO was null or undefined when calling getSsoConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sso/authenticate/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.azureAdRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getSsoConfig(requestParameters: GetSsoConfigRequest, initOverrides?: RequestInit): Promise<AzureSsoConfigResponseDTO> {
        const response = await this.getSsoConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ssoAuthenticateRaw(requestParameters: SsoAuthenticateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.azureAdAuthenticationResultDTO === null || requestParameters.azureAdAuthenticationResultDTO === undefined) {
            throw new runtime.RequiredError('azureAdAuthenticationResultDTO','Required parameter requestParameters.azureAdAuthenticationResultDTO was null or undefined when calling ssoAuthenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/sso/authenticate/assume`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.azureAdAuthenticationResultDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ssoAuthenticate(requestParameters: SsoAuthenticateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.ssoAuthenticateRaw(requestParameters, initOverrides);
    }

}
