import { Paper } from "@material-ui/core";
import { CsvUploadForm } from "./CsvUploadForm";
import { createContext, useState } from "react";
import { useCollectorUpload } from "../../../clients/collector.client";
import { StateContext, useContextOrThrow } from "../../../hooks/context.hooks";
import { OneCardGrid } from "../../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../../components/skeletons/DataTableChartSkeleton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CollectorUploadResponseDTO, UploadCsvConfigResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  })
);

interface CSVUploadProps {
  collector: CollectorUploadResponseDTO;
}

export const CsvUpload = ({ collector }: CSVUploadProps) => {
  const classes = useStyles();

  const [uploadConfig, , uploadConfigIsLoading] = useCollectorUpload(collector.id);
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [dataRows, setDataRows] = useState<string[]>([]);
  const [file, setFile] = useState<File>();

  if (!uploadConfig || uploadConfigIsLoading) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );
  }

  return (
    <Paper elevation={2} classes={{ root: classes.paperRoot }}>
      <UploadConfigContext.Provider value={uploadConfig}>
        <ColumnNamesContext.Provider value={[columnNames, setColumnNames]}>
          <DataRowsContext.Provider value={[dataRows, setDataRows]}>
            <FileContext.Provider value={[file, setFile]}>
              <CsvUploadForm collectorId={collector.id} secret={collector.credentials.apiKey!} />
            </FileContext.Provider>
          </DataRowsContext.Provider>
        </ColumnNamesContext.Provider>
      </UploadConfigContext.Provider>
    </Paper>
  );
};

export const UploadConfigContext = createContext<UploadCsvConfigResponseDTO | undefined>(undefined);
export const useUploadConfig = () => useContextOrThrow(UploadConfigContext);

export const ColumnNamesContext = createContext<StateContext<string[]>>(undefined);
export const useColumnNames = () => useContextOrThrow(ColumnNamesContext);

export const DataRowsContext = createContext<StateContext<string[]>>(undefined);
export const useDataRows = () => useContextOrThrow(DataRowsContext);

export const FileContext = createContext<StateContext<File | undefined>>(undefined);
export const useFile = () => useContextOrThrow(FileContext);
