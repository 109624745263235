import { createContext } from "react";
import { useContextOrThrow } from "../hooks/context.hooks";
import { LineItemService, LiveLineItemService, TestLineItemService } from "./lineitem.service";
import { AttributeService, LiveAttributeService, TestAttributeService } from "./attribute.service";
import { DashboardService, LiveDashboardService, LocalstorageDashboardService } from "./dashboard.service";
import {
  AttributesControllerApi,
  AttributesFilterControllerApi,
  AzureSsoConfigControllerApi,
  BatchProjectionControllerApi,
  CollectorCredentialsControllerApi,
  ConfigControllerApi,
  Configuration,
  CustomerPortalControllerApi,
  DataSourcePriceAdjustmentsApi,
  LineItemsApi,
  ManagedCustomerPortalControllerApi,
  MfaControllerApi,
  OrganizationalUnitsApi,
  OrganizationPriceAdjustmentsApi,
  ProcessEventQueueControllerApi,
  ProjectionControllerApi,
  AzureSsoControllerApi,
  UserControllerApi,
  ActionCenterControllerApi,
} from "../open-api";
import {
  CollectorPriceAdjustmentsService,
  LiveCollectorPriceAdjustmentsService,
} from "./collector-price-adjustments.service";
import { LivePriceAdjustmentsService, PriceAdjustmentsService } from "./price-adjustments.service";
import {
  LiveOrganizationalUnitsService,
  OrganizationalUnitsService,
  TestOrganizationalUnitsService,
} from "./organizational-unit.service";
import { LiveProjectionsService, ProjectionsService, TestProjectionsService } from "./projections.service";
import { LiveUserService, UserService } from "./user.service";
import { LivePortalService, PortalService } from "./portal.service";
import { AggregateQueryService, LiveQueryService } from "./aggregate-query.service";
import { LiveProcessQueueService, ProcessQueueService } from "./process-queue.service";
import {
  AttributesFilterService,
  LiveAttributesFilterService,
  TestAttributesFilterService,
} from "./attributes-filter.service";
import { CollectorCredentialsService, LiveCollectorCredentialsService } from "./collector-credentials.service";
import { setLocalStorageValue } from "../utils/localStorage.utils";
import { LiveManagedPortalService, ManagedPortalService } from "./managed-portal.service";
import { LiveMfaService, MfaService } from "./mfa.service";
import { LiveAzureSSOService, AzureSSOService } from "./azure-sso.service";
import { LiveSsoService, SsoService } from "./sso.service";
import { ActionCenterService, LiveActionCenterService } from "./action-center-service";

export interface Services {
  aggregate: AggregateQueryService;
  lineItems: LineItemService;
  attributes: AttributeService;
  attributesFilter: AttributesFilterService;
  dashboard: DashboardService;
  priceAdjustments: PriceAdjustmentsService;
  collectorPriceAdjustments: CollectorPriceAdjustmentsService;
  organizationalUnits: OrganizationalUnitsService;
  processQueue: ProcessQueueService;
  projections: ProjectionsService;
  users: UserService;
  portals: PortalService;
  managedPortals: ManagedPortalService;
  collectorCredentials: CollectorCredentialsService;
  mfa: MfaService;
  azureSSO: AzureSSOService;
  sso: SsoService;
  actionCenter: ActionCenterService;
  // this context can be big, as it will never change on runtime, and cause rerenders
}

export const liveServices = createLiveServices(createLiveLayer());

export function createLiveServices({ api }: LiveLayer): Services {
  return {
    aggregate: new LiveQueryService(api),
    lineItems: new LiveLineItemService(api),
    attributes: new LiveAttributeService(api),
    attributesFilter: new LiveAttributesFilterService(api),
    dashboard: new LiveDashboardService(api),
    collectorPriceAdjustments: new LiveCollectorPriceAdjustmentsService(api),
    priceAdjustments: new LivePriceAdjustmentsService(api),
    users: new LiveUserService(api),
    processQueue: new LiveProcessQueueService(api),
    portals: new LivePortalService(api),
    managedPortals: new LiveManagedPortalService(api),
    organizationalUnits: new LiveOrganizationalUnitsService(api),
    projections: new LiveProjectionsService(api),
    collectorCredentials: new LiveCollectorCredentialsService(api),
    mfa: new LiveMfaService(api),
    azureSSO: new LiveAzureSSOService(api),
    sso: new LiveSsoService(api),
    actionCenter: new LiveActionCenterService(api),
  };
}

export type LiveLayer = ReturnType<typeof createLiveLayer>;
export type API = LiveLayer["api"];

export function createLiveLayer() {
  const apiConfig = new Configuration({
    basePath: "",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    middleware: [
      {
        pre: async ({ url, init }) => {
          return { url, init };
        },
        post: async ({ response, fetch, init, url }) => {
          if (response.status === 409) {
            // cached value error
            throw new CacheNotYetCalculatedError();
            // await delay(5000);
            // return fetch(url, init);
          } else if (response.status === 400) {
            // validation/user submitted error
            const { status, message } = await response.json();
            throw Error(message ?? status);
          } else if (response.status === 401) {
            setLocalStorageValue("redirectRoute", window.location.pathname);
            window.location.href = "/login?state=expired";
            return;
          }
          return response;
        },
      },
    ],
  });

  return {
    api: {
      attributes: new AttributesControllerApi(apiConfig),
      attributesFilter: new AttributesFilterControllerApi(apiConfig),
      lineItems: new LineItemsApi(apiConfig),
      config: new ConfigControllerApi(apiConfig),
      priceAdjustments: new OrganizationPriceAdjustmentsApi(apiConfig),
      projections: new ProjectionControllerApi(apiConfig),
      processQueue: new ProcessEventQueueControllerApi(apiConfig),
      batchProjections: new BatchProjectionControllerApi(apiConfig),
      organizationalUnits: new OrganizationalUnitsApi(apiConfig),
      collectorPriceAdjustments: new DataSourcePriceAdjustmentsApi(apiConfig),
      user: new UserControllerApi(apiConfig),
      portal: new CustomerPortalControllerApi(apiConfig),
      managedPortal: new ManagedCustomerPortalControllerApi(apiConfig),
      collectorCredentials: new CollectorCredentialsControllerApi(apiConfig),
      mfa: new MfaControllerApi(apiConfig),
      azureSSO: new AzureSsoConfigControllerApi(apiConfig),
      sso: new AzureSsoControllerApi(apiConfig),
      actionCenter: new ActionCenterControllerApi(apiConfig),
    },
  };
}

export const defaultTestServices: Services = {
  aggregate: null!,
  lineItems: new TestLineItemService(),
  attributes: new TestAttributeService(),
  attributesFilter: new TestAttributesFilterService(),
  dashboard: new LocalstorageDashboardService(),
  collectorPriceAdjustments: null!,
  priceAdjustments: null!,
  organizationalUnits: new TestOrganizationalUnitsService(),
  projections: new TestProjectionsService(),
  processQueue: null!,
  users: null!,
  portals: null!,
  managedPortals: null!,
  collectorCredentials: null!,
  mfa: null!,
  azureSSO: null!,
  sso: null!,
  actionCenter: null!,
};

export class CacheNotYetCalculatedError extends Error {
  constructor() {
    super("Cache is not yet calculated");
    this.name = "CacheNotYetCalculatedError";
  }
}

export const createTestServices = (services?: Partial<Services>) => ({
  ...defaultTestServices,
  ...services,
});

export const ServicesContext = createContext<Services | undefined>(undefined);

export const useServices = () => {
  return useContextOrThrow(ServicesContext);
};
