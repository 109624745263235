import { useCallback, useEffect, useState } from "react";
import { DashRangeFilter } from "./DashRangeFilter";
import { DashSelectFilter } from "./DashSelectFilter";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { AddFilter } from "./DashAddFilter";
import { attributeId } from "../model/Attribute";
import { OneCardGrid } from "../../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../../components/skeletons/DataTableChartSkeleton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { GroupSegmentFilter } from "../model/GroupSegmentFilter";
import { absoluteCenter } from "../../../utils/styles";
import { useDashFilters, useDateRange } from "../selectors/dashboard.selector";
import { css } from "@emotion/css";
import {
  useDashboardShouldLoad,
  useSelectedDashboardId,
  useSetDashboards,
  useSetDashboardShouldLoad,
} from "../NewDashPageProvider";
import { Model } from "../../../model/Model";
import { DateTime } from "luxon";
import { DateRange, toAbsoluteRange } from "../model/DateRange";
import { useMonthlyOverview } from "../../../clients/dashboard.client";
import Grid from "@material-ui/core/Grid";
import { useFeatureToggle } from "../../../hooks/ft.hooks";

const MAX_AUTO_REFRESH = 3 * 10 ** 6;

export const DashFilter = () => {
  const dashboardId = useSelectedDashboardId();
  const [filters, setFilters] = useDashFilters();
  const [dateRange] = useDateRange();
  const setDashboards = useSetDashboards();
  const setDashboardShouldLoad = useSetDashboardShouldLoad();
  const dashboardShouldLoad = useDashboardShouldLoad();
  const [rangeLineItems, , loading] = useMonthlyOverview();
  const [openedSegment, setOpenedSegment] = useState<string | undefined>(undefined);
  const useActiveReloading = useFeatureToggle("activeReload");
  const useForceActiveReloading = useFeatureToggle("forceActiveReload");

  const [prevFilters, setPrevFilters] = useState<GroupSegmentFilter[]>(filters);

  // TODO: @Kasper, why is this a memoized function (and almost a copy of the useDateRange() set function but slightly different?
  const setDateRange = useCallback(
    (dateRange: DateRange) => {
      return setDashboards((state) => {
        const dashboard = dashboardId == null ? undefined : Model.get(state, dashboardId);
        if (dashboard == null) return state;
        return Model.update(state, { ...dashboard, updatedAt: DateTime.local(), dateRange });
      });
    },
    [dashboardId, setDashboards]
  );

  const useManualRefresh =
    (rangeLineItems && useActiveReloading && rangeLineItems.reduce((agg, it) => agg + it.cost, 0) > MAX_AUTO_REFRESH) ||
    useForceActiveReloading;

  // // Make sure dashboard loads when changing dashboards
  // useEffect(() => {
  //   setPrevFilters(filters)
  //   setDashboardShouldLoad(true);
  // }, [dashboardId, setDashboardShouldLoad, filters, setPrevFilters]);

  useEffect(() => {
    if (dashboardShouldLoad) {
      setPrevFilters(filters);
    }
  }, [dashboardShouldLoad, filters, prevFilters]);

  // Set previous filters whenever a dashboard should load, or disable loading whenever the filters have changed
  // and active reloading is disabled.
  useEffect(() => {
    if (dashboardShouldLoad) {
      if (filters && prevFilters && useManualRefresh && filters !== prevFilters) {
        setDashboardShouldLoad(false);
      }
    }
  }, [filters, prevFilters, dashboardShouldLoad, useManualRefresh, setDashboardShouldLoad]);

  const range = toAbsoluteRange(dateRange);

  const handleOpenFilter = (attributeId: string) => {
    setOpenedSegment(attributeId);
    setDashboardShouldLoad(false);
  };

  const handleCloseFilter = () => {
    setOpenedSegment(undefined);
    if (useManualRefresh) {
      if (prevFilters === filters) {
        setDashboardShouldLoad(true);
      }
    } else {
      setDashboardShouldLoad(true);
    }
  };

  return (
    <>
      {!rangeLineItems ? (
        <OneCardGrid>
          <DataTableSkeleton rowCount={2} />
        </OneCardGrid>
      ) : (
        <div style={{ position: "relative" }}>
          {loading && (
            <div className={css(absoluteCenter)}>
              <CircularProgress size={45} />
            </div>
          )}
          <DashRangeFilter
            key={dashboardId}
            costs={rangeLineItems}
            initRangeStart={range.startDate}
            initRangeEnd={range.endDate}
            setDateRange={setDateRange}
          />
        </div>
      )}

      <Typography style={{ marginTop: 16 }} variant={"h5"} color={"textSecondary"}>
        Filter per segment
      </Typography>
      <div className={css({ display: "flex", flexDirection: "row", flexWrap: "wrap" })}>
        {filters.map((filter) => (
          <DashSelectFilter
            open={openedSegment === attributeId(filter.segment)}
            onOpen={() => handleOpenFilter(attributeId(filter.segment))}
            onClose={() => handleCloseFilter()}
            filter={filter}
            key={`${dashboardId}|${attributeId(filter.segment)}`}
          />
        ))}
        <AddFilter onSegmentAdded={(segment) => handleOpenFilter(attributeId(segment))} />
      </div>
      {useManualRefresh && prevFilters !== filters && (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              startIcon={<RefreshIcon />}
              color="primary"
              variant={"outlined"}
              onClick={() => {
                setDashboardShouldLoad(true);
                setFilters(prevFilters);
              }}
            >
              Undo changes
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<RefreshIcon />}
              color="primary"
              variant={"contained"}
              onClick={() => {
                setDashboardShouldLoad(true);
                setPrevFilters(filters);
              }}
            >
              Apply configured filters to charts
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
