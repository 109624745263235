import { SearchableTagTreeAction } from "../SearchableTabTree";
import { ActionIconButton } from "../../../../../components/buttons/ActionIconButton";
import { Box, Chip, PropTypes, Tooltip } from "@material-ui/core";
import { PositiveNumberInputPopper } from "../../../../../components/dialogs/PositiveNumberInputPopper";
import { GenericIcon, Icon } from "../../../../../theme/Icon";
import { ChipActionButton } from "./ChipActionButton";
import { formattedPercentage } from "../../../../../utils/localization.utils";
import { CSSProperties } from "react";

export enum ActionButtonType {
  ICON = "ICON",
  CHIP = "CHIP",
  CHIP_NO_CLICK = "CHIP_NO_CLICK",
  ICON_AND_CHIP = "ICON_AND_CHIP",
}

interface ActionButtonProps<T> {
  action: SearchableTagTreeAction<T>;
  data: {
    item: T;
    filterKey?: string;
  };
  label: string;
  color?: Exclude<PropTypes.Color, "inherit">;
  chipStyle?: CSSProperties;
}

export const TagActionButton = <T extends any>({ action, data, label, color, chipStyle }: ActionButtonProps<T>) => {
  const icon = action.icon;
  const tooltip = action.tooltip;
  const onConfirm = (value: any) => action.onClick && action.onClick(data.item, value);

  let content;

  switch (action.actionButtonType) {
    case ActionButtonType.CHIP_NO_CLICK:
      content = (
        <Tooltip title={tooltip}>
          <Chip
            style={chipStyle}
            variant="outlined"
            size="small"
            label={action.inputControls && formattedPercentage(Number(action.inputControls?.init(data.item)), 0, 2)}
            color={color ?? "primary"}
            icon={action.icon && <Icon icon={action.icon} />}
          />
        </Tooltip>
      );
      break;
    case ActionButtonType.CHIP:
      content = <ChipActionButton action={action} data={data} label={label} color={color} chipStyle={chipStyle} />;
      break;

    case ActionButtonType.ICON_AND_CHIP:
      const initialValue = action.inputControls?.init(data.item);

      content = (
        <PositiveNumberInputPopper
          title={tooltip}
          onSave={onConfirm}
          maxValue={action.inputControls?.max(data.item)}
          initialValue={action.inputControls?.init(data.item)}
          label={`Set ${action.name} percentage for "${
            action.labelFn ? action.labelFn(data.item, data.filterKey ?? "") : label
          }": `}
        >
          <Box>
            {!!initialValue && Number(initialValue) < 100 ? (
              <Chip
                variant="outlined"
                size="small"
                label={formattedPercentage(Number(initialValue), 0, 2)}
                clickable
                color={"primary"}
                disabled={true}
              />
            ) : (
              ""
            )}

            <ActionIconButton
              action={{
                icon: icon ?? GenericIcon.QUEUE,
                tooltip: "",
                onClick: () => action.onClick && action.onClick(data.item),
              }}
              buttonSize={"small"}
            />
          </Box>
        </PositiveNumberInputPopper>
      );
      break;

    case ActionButtonType.ICON:
    default:
      content = (
        <ActionIconButton
          action={{
            icon: icon ?? GenericIcon.QUEUE,
            tooltip: tooltip,
            onClick: () => action.onClick && action.onClick(data.item),
          }}
          buttonSize={"small"}
        />
      );
  }

  return content;
};
