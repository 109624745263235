import { useCurrency, useSetCurrency } from "../hooks/config.hooks";
import { FormSelectSingle } from "./form/FormSelectSingle";
import { getCurrencySymbol } from "../utils/localization.utils";
import { CURRENCIES } from "../utils/functional/money.utils";

export const CurrencySelect = () => {
  const selected = useCurrency();
  const setCurrency = useSetCurrency();

  return (
    <FormSelectSingle
      noGrid
      value={selected}
      alignEnd={true}
      handleChange={(e: any) => setCurrency(e.target.value)}
      options={CURRENCIES}
      optionId={"id"}
      optionValue={(e) => getCurrencySymbol(e.id)}
      id="currencies"
      label={""}
      variant={"standard"}
      fullWidth={false}
    />
  );
};
