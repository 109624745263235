import { useEffect, useState } from "react";
import { z } from "zod";
import { CA_CONSENT } from "../../../../clients/collector.client";
import { ControlledTextField } from "../../../../components/form/FormComponents";
import { FormTitleField } from "../../../../components/form/FormTitleField";
import { Button, Grid, Link, Typography } from "@material-ui/core";
import { useServices } from "../../../../services/context.service";
import { useCFForm } from "../../../../components/form/FormComponents.hooks";
import { messageError } from "../../../../services/message.service";

interface CAConnectWizardProps {
  collectorId: string;
  onSuccess: () => void;
}
export const caConnectSchema = z.object({
  adDomain: z.string(),
  type: z.enum(["CA_CONNECTOR"] as const),
});

export const CAConnectWizard = ({ collectorId, onSuccess }: CAConnectWizardProps) => {
  const services = useServices();

  const { control, setValue, handleSubmit } = useCFForm({
    schema: caConnectSchema,
    defaultValues: {
      type: "CA_CONNECTOR",
    },
  });

  const [status, setStatus] = useState<string | null>(CA_CONSENT.STATUS.PENDING);
  const [adDomain, setAdDomain] = useState<string | null>(null);

  // Setup and clean up hook for CA connect
  useEffect(() => {
    localStorage.setItem(CA_CONSENT.STORAGE_STATUS, CA_CONSENT.STATUS.PENDING);
    localStorage.removeItem(CA_CONSENT.STORAGE_DOMAIN);
    const handler = () => {
      if (status !== localStorage.getItem(CA_CONSENT.STORAGE_STATUS)) {
        setStatus(localStorage.getItem(CA_CONSENT.STORAGE_STATUS) ?? null);
      }
      if (adDomain !== localStorage.getItem(CA_CONSENT.STORAGE_DOMAIN)) {
        setAdDomain(localStorage.getItem(CA_CONSENT.STORAGE_DOMAIN) ?? null);
        // @ts-ignore
        setValue("adDomain", localStorage.getItem(CA_CONSENT.STORAGE_DOMAIN));
      }
    };
    window.addEventListener("storage", handler);
    return () => {
      localStorage.removeItem(CA_CONSENT.STORAGE_STATUS);
      localStorage.removeItem(CA_CONSENT.STORAGE_DOMAIN);
      window.removeEventListener("storage", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: z.infer<typeof caConnectSchema>) =>
    services.collectorCredentials
      .update(collectorId, data)
      .then(onSuccess)
      .catch((e) => messageError(e.message));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormTitleField text="Step 1" />
      {adDomain && (
        <ControlledTextField
          control={control}
          name={"adDomain"}
          label={"AD Domain"}
          fullWidth
          inputProps={{ readOnly: true }}
        />
      )}

      {status === CA_CONSENT.STATUS.PENDING && (
        <Link target="blank" href={CA_CONSENT.url1()}>
          Click here to give access to the application
        </Link>
      )}
      {adDomain && <Typography color="textPrimary">Successfully received AD Domain, Continue with step 2</Typography>}
      {adDomain && <FormTitleField text="Step 2" />}
      {status === CA_CONSENT.STATUS.VERIFYING && adDomain && (
        <Link target="blank" href={CA_CONSENT.url2(adDomain)}>
          Click here to give consent
        </Link>
      )}
      {status === CA_CONSENT.STATUS.VERIFIED && (
        <>
          <Typography color="textPrimary" display="initial">
            Successfully given consent, Continue with step 3
          </Typography>
          <FormTitleField text="Step 3" />
          <Typography display="initial">Add read rights to the subscription(s)</Typography>
          <Typography>
            <Link target="blank" href={CA_CONSENT.READ_RIGHTS_FAQ_URL}>
              Click here to read instructions on how to add read rights
            </Link>
          </Typography>
          <Typography>Click save to continue</Typography>
        </>
      )}
      <ControlledTextField control={control} name={"adDomain"} label={""} type="hidden" />
      <Grid xs={12}>
        <Button color="primary" variant="contained" disabled={status !== CA_CONSENT.STATUS.VERIFIED} type="submit">
          Save
        </Button>
      </Grid>
    </form>
  );
};
