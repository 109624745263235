import { TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { BudgetAlertInlineEditForm } from "./BudgetAlertInlineEditForm";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CreateEmailNotificationRequestDTO } from "../../../../open-api";

interface BudgetAlertInlineEditEmailNotificationProps {
  alertId: string;
  setEditFn: (id: string, editing: boolean) => void;
  isEditFn: (id: string) => boolean;
  onSave: (form: CreateEmailNotificationRequestDTO) => void;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailTextField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 280,
    },
  })
);

export function BudgetAlertInlineEditEmailNotification({
  alertId,
  isEditFn,
  setEditFn,
  onSave,
  onDelete,
}: BudgetAlertInlineEditEmailNotificationProps) {
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm<CreateEmailNotificationRequestDTO>({
    mode: "onTouched",
    defaultValues: { recipient: "" },
  });

  const classes = useStyles();

  return (
    <BudgetAlertInlineEditForm<CreateEmailNotificationRequestDTO>
      key={`budget-alert-inline-edit-form-${alertId}`}
      isEditFn={isEditFn}
      setEditFn={setEditFn}
      content={<Typography color={"textSecondary"}>Add an e-mail address</Typography>}
      formKey={`budget-alert-inline-email-notification-form-${alertId}`}
      handleSubmit={handleSubmit}
      onSave={onSave}
      onDelete={onDelete}
    >
      <Controller
        render={({ field: { onChange, onBlur, value, name } }) => (
          <TextField
            name={name}
            label={"E-mail"}
            margin="dense"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors.recipient}
            helperText={errors.recipient?.message}
            variant={"outlined"}
            classes={{ root: classes.emailTextField }}
          />
        )}
        name={"recipient"}
        control={control}
        rules={{
          required: "A valid e-mail is required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Invalid e-mail address",
          },
        }}
      />
    </BudgetAlertInlineEditForm>
  );
}
