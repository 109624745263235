import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { DialogProps } from "../../../hooks/dialog.hooks";
import { useServices } from "../../../services/context.service";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { messageError, messageSuccess } from "../../../services/message.service";
import { MFAToggleComponent } from "./MFAToggleComponent";

interface EnableMFADialogProps extends DialogProps {
  onSuccess: () => any;
}

export function EnableMFADialog({ open, onClose, onSuccess }: EnableMFADialogProps) {
  const mfaService = useServices().mfa;
  const [, setIsLoading] = useState<boolean>(false);
  const [mfaProps] = useDataClientPromise(mfaService.requestSecret, []);
  const { handleSubmit, control, setValue } = useCFForm({
    schema: z.object({
      secret: z.string(),
      code: z.string().regex(/[0-9]{6}/, "Should be a numeric value of 6 digits"),
    }),
  });

  useEffect(() => {
    setValue("secret", mfaProps?.secret ?? "");
  }, [mfaProps, setValue]);

  const onSave = (secret: string, code: string) => {
    setIsLoading(true);
    mfaService
      .enableMFA(secret, code)
      .then((it) => {
        setIsLoading(false);
        onSuccess();
        messageSuccess(
          "Successfully enabled MFA. Please make sure you keep your authenticator device, because you need it to sign in next time"
        );
        onClose();
      })
      .catch((it) => {
        setIsLoading(false);
        messageError("Invalid code");
      });
  };

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose}>
      <MFAToggleComponent
        open={open}
        onClose={onClose}
        onSubmit={handleSubmit(({ secret, code }) => onSave(secret, code))}
        mfaProps={mfaProps}
        control={control}
      />
    </Dialog>
  );
}
