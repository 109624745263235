import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";

export const TreeSkeleton: FC = () => {
  const one = (
    <>
      <Grid item xs={10}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={2} />
    </>
  );

  const two = (
    <>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Skeleton variant="text" height={60} />
      </Grid>
      <Grid item xs={1} />
    </>
  );

  const three = (
    <>
      <Grid item xs={2} />
      <Grid item xs={10}>
        <Skeleton variant="text" height={60} />
      </Grid>
    </>
  );

  return (
    <Grid container spacing={1}>
      <Grid container item>
        {one}
        {two}
        {three}
        {one}
        {two}
        {two}
      </Grid>
    </Grid>
  );
};
