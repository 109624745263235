import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilterOption } from "../../pages/billing/OrganizationSelectFilter";
import { ListboxComponent } from "./VirtualizedAutocomplete";
import TextField from "@material-ui/core/TextField";
import { SearchRounded } from "@material-ui/icons";
import { css } from "@emotion/css";
import { useState } from "react";

interface FormAutocompleteFilterProps {
  filterState: ReturnType<typeof useFilterState>;
  filterOptions?: FilterOption[] | null;
  disabled?: boolean;
}

export const useFilterState = () => useState<FilterOption | null>(null);

export function FormAutocompleteFilter({ filterState, filterOptions, disabled }: FormAutocompleteFilterProps) {
  const [value, setValue] = filterState;
  const [inputValue, setInputValue] = useState("");
  const [, setOpen] = useState(false);

  return (
    <Autocomplete
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event: any, newValue: FilterOption | null) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
      ListboxComponent={ListboxComponent() as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      id="organization-select-filter"
      options={filterOptions ?? []}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={"Filter"}
            placeholder={"Search"}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              ...{ flex: 1 },
              value: inputValue,
            }}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchRounded className={css({ margin: "0 4px" })}></SearchRounded>,
            }}
            fullWidth
          />
        );
      }}
    />
  );
}
