import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import { forgotPassword } from "../../../clients/user.client";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { messageError } from "../../../services/message.service";
import { useRedirect } from "../../../hooks/router.hooks";
import { ControlledTextField } from "../../../components/form/FormComponents";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { RouterLink } from "../../../components/navigation/RouterLink";

interface ResetComponentProps {}

export function ForgotComponent({ ...props }: ResetComponentProps) {
  const redirect = useRedirect();

  const {
    handleSubmit,
    control,
  } = useCFForm({
    schema: z.object({
      email: z.string().email()
    })
  });

  const handleReset = (email: string) => {
    forgotPassword(email)
      .then(() => redirect("/login", { state: "forgot" }))
      .catch((msg) => messageError(`Failed to reset: ${msg}`));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(({ email }) => {
          handleReset(email);
        })}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
            <LockOpenIcon style={{ fontSize: 80 }} />
          </Grid>
          <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
            <Typography variant={"h6"}>Forgot password</Typography>
          </Grid>
          <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
            <Typography>
              Type in your email and click "Forgot password", an email will be sent with instructions.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField control={control} name={"email"} label={"E-mail"} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" autoFocus>
              Forgot password
            </Button>
          </Grid>
          <Grid item xs={12}>
            <RouterLink to={"/login"}>
              Sign in instead
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
