import { CFCard } from "../../../components/layout/CFContainer";
import { DataSourceResponseDTO, postCollectorConfig } from "../../../clients/collector.client";
import { useFeatureToggle } from "../../../hooks/ft.hooks";
import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import { CollectorConfigResponseDTO } from "../../../open-api";
import { getIncludeAllData } from "../../../utils/collector.utils";
import { css } from "@emotion/css";
import WarningIcon from "@material-ui/icons/Warning";
import { useState } from "react";
import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";

interface ToggleFilterCardProps {
  collector: DataSourceResponseDTO;
  collectorConfig: CollectorConfigResponseDTO | null;
  onSuccess?: () => void;
}

export const ToggleFilterCard = ({ collector, collectorConfig, onSuccess }: ToggleFilterCardProps) => {
  const useFilter = useFeatureToggle("filterAttributes");
  const theme = useTheme();
  const [showConfirm, setShowConfirm] = useState(false);

  if (!useFilter || collectorConfig?.generic?.options?.canToggleIncludeAll !== true) {
    return null;
  }

  const isIncludeAll = getIncludeAllData(collectorConfig);

  return (
    <CFCard header={"Filtered collector settings"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            This collector is currently configured as: <strong> {isIncludeAll ? "include all" : "filtered"}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={"outlined"}
            className={css({ borderColor: theme.palette.error.main, color: theme.palette.error.main })}
            startIcon={<WarningIcon />}
            onClick={() => setShowConfirm(true)}
          >
            Switch to {isIncludeAll ? "filtered" : "include all"}
          </Button>
        </Grid>
        <ConfirmDialog
          open={showConfirm}
          title={`Confirm datasource change`}
          content={
            <Typography>
              Are you sure you want to change the type of this datasource?
              <br />
              <br />
              From: <strong>{isIncludeAll ? "include all" : "filtered"}</strong>
              <br />
              To: <strong>{isIncludeAll ? "filtered" : "include all"}</strong>
            </Typography>
          }
          onClose={() => setShowConfirm(false)}
          onConfirm={() => {
            return postCollectorConfig(collector.id, {
              generic: {
                includeAllData: !isIncludeAll,
              },
            }).then(() => {
              if (onSuccess) {
                onSuccess();
              }
              setShowConfirm(false);
            });
          }}
        />
      </Grid>
    </CFCard>
  );
};
