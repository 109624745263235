import { ResetComponent } from "./ResetComponent";
import { Grid, Typography } from "@material-ui/core";
import CakeIcon from "@material-ui/icons/Cake";
import { getRequest } from "../../../utils/api.utils";
import { Button } from "@material-ui/core";
import { useIsAuthenticated } from "../../../hooks/auth.hooks";

export function WelcomeComponent({ ...props }) {
  const resetCode = props.match.params.code;
  const isAuthenticated = useIsAuthenticated();

  const logoutAndRedirectToChangePasswordScreen = () => {
    //catch should not be used here!
    //added it due to the fact that 404 is returned for login?state=logout GET call
    getRequest("/api/logout")
      .then(() => {
        window.location.replace(window.location.pathname);
      })
      .catch(() => {
        window.location.replace(window.location.pathname);
      });
  };

  if (isAuthenticated) {
    return (
      <Grid>
        <Typography>
          You are already authenticated with a separate account.
          <br />
          <Button
            variant="contained"
            size={"small"}
            color={"primary"}
            onClick={logoutAndRedirectToChangePasswordScreen}
          >
            Click here
          </Button>{" "}
          to logout and set a password.
        </Typography>
      </Grid>
    );
  }

  return (
    <ResetComponent resetCode={resetCode} buttonText={"create password"}>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <CakeIcon style={{ fontSize: 80 }} />
      </Grid>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <Typography variant={"h6"}>Welcome to c-facts!</Typography>
      </Grid>
      <Grid item xs={12} justify={"center"} style={{ display: "flex" }}>
        <Typography>Please create a password for your newly created account.</Typography>
      </Grid>
    </ResetComponent>
  );
}
