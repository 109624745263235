import { CircularProgress, LinkProps } from "@material-ui/core";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { forwardRef } from "react";

interface ListItemLinkProps {
  icon?: any;
  primary: string;
  to?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export function ListItemLink({ icon, primary, to, onClick, isLoading = false }: ListItemLinkProps) {
  const renderLink = to
    ? forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <Link to={to} ref={ref} {...props} />)
    : "li";

  return (
    <>
      <ListItem
        disabled={isLoading}
        button
        component={renderLink}
        title={primary}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} /> {isLoading && <CircularProgress size={"1.4rem"} />}
      </ListItem>
    </>
  );
}
