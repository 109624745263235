import { RefObject, useState } from "react";
import { csvRequest } from "../../utils/api.utils";
import { xlsRequest } from "../../utils/api.utils";
import { exportImageFromRef } from "../../utils/dx.utils";
import { MenuOptions, MenuOptionsItem } from "./MenuOptions";

interface MenuOptionsGraphButtons {
  csv?: string;
  reconCsv?: string | (() => void);
  xls?: string;
  csvLabel?: string;
  xlsLabel?: string;
  csvFilename?: string;
  png?: RefObject<any>;
  svg?: RefObject<any>;
  pdf?: string;
  additionalItems?: MenuOptionsItem[];
}

export function MenuOptionsGraph({
  csv,
  reconCsv,
  xls,
  png,
  svg,
  csvLabel,
  xlsLabel,
  additionalItems,
}: MenuOptionsGraphButtons) {
  const [loading, setLoading] = useState(false);
  let menuList: MenuOptionsItem[] = [];

  const handle = (func: () => Promise<any> | any) => {
    setLoading(true);
    return func().finally(() => {
      setLoading(false);
    });
  };
  csv &&
    menuList.push({
      text: csvLabel ?? "CSV file",
      onClick: () => handle(() => csvRequest(csv)),
    });

  xls &&
    menuList.push({
      text: xlsLabel ?? "XLS file",
      onClick: () => handle(() => xlsRequest(xls)),
    });

  reconCsv &&
    menuList.push({
      text: "Reconciliation line items - Export CSV file",
      onClick: () => handle(() => (typeof reconCsv === "function" ? reconCsv() : csvRequest(reconCsv))),
    });

  png &&
    menuList.push({
      text: "PNG export",
      onClick: () => exportImageFromRef(png.current.instance, "png"),
    });

  svg &&
    menuList.push({
      text: "SVG export",
      onClick: () => exportImageFromRef(svg.current.instance, "png"),
    });
  if (additionalItems) {
    menuList.push(
      ...additionalItems.map((it) => ({
        text: it.text,
        onClick: () => handle(() => (it.onClick ? it.onClick() : undefined)),
      }))
    );
  }

  return <MenuOptions menuItems={menuList} loading={loading} />;
}
