import { ReactNode } from "react";
import { Grid, Typography } from "@material-ui/core";

export interface FastFactLineProps {
  key: string;
  description: ReactNode;
  value: ReactNode;
}

export function FastFactLine({ description, value }: FastFactLineProps) {
  return (
    <Grid container justify="space-between">
      <Typography display={"inline"} align={"left"}>
        {description}
      </Typography>
      <Typography display={"inline"} align={"right"}>
        {value}
      </Typography>
    </Grid>
  );
}
