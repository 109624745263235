import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { updateOrganizationalUnitContact } from "../../../clients/organizationalunit.client";
import { useApiCall } from "../../../hooks/client.hooks";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { contactSchema, OrganizationalUnitFormPartialContact } from "./partials/OrganizationalUnitFormPartialContact";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import {
  CreateOrUpdateOrganizationalUnitContactRequestDTO,
  OrganizationalUnitContactDTO,
  OrganizationalUnitResponseDTO,
} from "../../../open-api";
import { useCFForm } from "../../../components/form/FormComponents.hooks";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

interface OrganizationalUnitContactFormEditProps {
  onClose: () => void;
  onSuccess?: (customer: OrganizationalUnitResponseDTO) => void;
  onError?: (e: any) => void;
  organizationalUnitContact: OrganizationalUnitContactDTO;
  organizationalUnit: OrganizationalUnitResponseDTO;
}

export function OrganizationalUnitContactFormEdit({
  onClose,
  onSuccess,
  onError,
  organizationalUnitContact,
  organizationalUnit,
}: OrganizationalUnitContactFormEditProps) {
  const classes = useStyles();
  const [update, isLoading] = useApiCall(updateOrganizationalUnitContact);

  const defaultValues: CreateOrUpdateOrganizationalUnitContactRequestDTO = organizationalUnitContact;

  const { handleSubmit, control } = useCFForm({
    schema: contactSchema,
    defaultValues: defaultValues,
  });

  const editUnit = (customerValues: CreateOrUpdateOrganizationalUnitContactRequestDTO) => {
    update(organizationalUnit.id, customerValues)
      .then((e) => {
        onSuccess && onSuccess(e);
      })
      .catch((e) => {
        onError && onError(e);
      });
  };

  const close = () => {
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(editUnit)}>
      <DialogTitleCloseable onClose={close}>Edit contact details</DialogTitleCloseable>
      <DialogContent className={classes.content}>
        <OrganizationalUnitFormPartialContact control={control} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonConfirm isSubmitting={isLoading}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
