import { FastFactLine, FastFactLineProps } from "./FastFactLine";
import { Card, CardContent } from "@material-ui/core";
import { FastFactTitle } from "./FastFactTitle";
import { Skeleton } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { CFactsIcon } from "../../../theme/Icon";

interface FastFactProps {
  icon?: CFactsIcon;
  title: string;
  info?: string;
  lines: FastFactLineProps[];
}

export function FastFact({ icon, title, info, lines }: FastFactProps) {
  return (
    <Grid item lg={2} xs={4}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <FastFactTitle icon={icon} title={title} info={info} />
          {lines
            .sort((a, b) => a.description?.toString()?.localeCompare(b?.description?.toString() ?? "") ?? 0)
            .map((line) => (
              <FastFactLine {...line} />
            ))}
        </CardContent>
      </Card>
    </Grid>
  );
}

export const FastFactSkeleton = () => (
  <Grid item lg={2} xs={4}>
    <Card>
      <CardContent>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </CardContent>
    </Card>
  </Grid>
);
