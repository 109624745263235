import { Collapse, List, ListItem, Typography } from "@material-ui/core";
import { BudgetAlertInlineEditBudgetAlert } from "./BudgetAlertInlineEditBudgetAlert";
import {
  BudgetAlertEmailNotificationResponseDTO,
  BudgetAlertResponseDTO,
  BudgetResponseDTO,
  CreateBudgetAlertRequestDTO,
  CreateEmailNotificationRequestDTO,
  OrganizationalUnitResponseDTO,
} from "../../../../open-api";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import {
  createEmailNotificationForBudgetAlert,
  deleteBudgetAlert,
  deleteEmailNotificationForBudgetAlert,
  updateBudgetAlert,
} from "../../../../clients/budgetalerts.client";
import { messageError, messageSuccess } from "../../../../services/message.service";
import { useCanEditAdminAndCView } from "../../../../hooks/auth.hooks";
import { BudgetAlertInlineEditEmailNotification } from "./BudgetAlertInlineEditEmailNotification";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PaperListItem } from "./PaperListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: "rgba(255,255,255,0.18)",
    },
    nestedListItem: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 46,
    },
    addEmailListItem: {
      marginBottom: 12,
    },
    recipient: {
      flexGrow: 1,
    },
  })
);

interface BudgetAlertListItemProps {
  alert: BudgetAlertResponseDTO;
  budget: BudgetResponseDTO;
  toggleAlertExpanded: (alertId: string) => void;
  isAlertExpanded: (alertId: string) => boolean;
  setAlertOpen: (alertId: string) => void;
  setEditFn: (id: string, editing: boolean) => void;
  isEditFn: (id: string) => boolean;
  organization: OrganizationalUnitResponseDTO;
  refreshBudgets: () => void;
}

export const BudgetAlertListItem = ({
  alert,
  budget,
  setEditFn,
  isEditFn,
  organization,
  refreshBudgets,
  toggleAlertExpanded,
  isAlertExpanded,
  setAlertOpen,
}: BudgetAlertListItemProps) => {
  const canEdit = useCanEditAdminAndCView();
  const classes = useStyles();

  const onSaveBudgetAlert = (form: CreateBudgetAlertRequestDTO, alert: BudgetAlertResponseDTO) => {
    budget?.id &&
      updateBudgetAlert(organization.id, budget.id, alert.id, form)
        .then(() => {
          refreshBudgets();
          messageSuccess("Alert Saved");
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  const onDeleteBudgetAlert = (alert: BudgetAlertResponseDTO) => {
    budget?.id &&
      deleteBudgetAlert(organization.id, budget.id, alert.id)
        .then(() => {
          refreshBudgets();
          messageSuccess("Alert Deleted");
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  const onSaveEmailNotificationForAlert = (form: CreateEmailNotificationRequestDTO, alert: BudgetAlertResponseDTO) => {
    budget?.id &&
      createEmailNotificationForBudgetAlert(organization.id, budget.id, alert.id, form)
        .then(() => {
          refreshBudgets();
          messageSuccess("Notification Saved");
          setAlertOpen(alert.id);
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  const onDeleteEmailNotificationForAlert = (
    alert: BudgetAlertResponseDTO,
    notification: BudgetAlertEmailNotificationResponseDTO
  ) => {
    budget?.id &&
      deleteEmailNotificationForBudgetAlert(organization.id, budget.id, alert.id, notification.id)
        .then(() => {
          refreshBudgets();
          messageSuccess("Notification Deleted");
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  return (
    <>
      <ListItem key={`alert-${alert.id}`}>
        <BudgetAlertInlineEditBudgetAlert
          budget={budget}
          alert={alert}
          setEditFn={setEditFn}
          isEditFn={isEditFn}
          onSave={(form: CreateBudgetAlertRequestDTO) => onSaveBudgetAlert(form, alert)}
          onDelete={() => onDeleteBudgetAlert(alert)}
          onRowClick={(e: any) => {
            toggleAlertExpanded(alert.id);
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <ActionIconButton
          buttonSize={"medium"}
          size={22}
          action={{
            icon: isAlertExpanded(alert.id) ? GenericIcon.EXPAND_LESS : GenericIcon.LIST_COLLAPSE,
            tooltip: "Show e-mails",
            onClick: () => toggleAlertExpanded(alert.id),
          }}
        />
      </ListItem>

      <Collapse in={isAlertExpanded(alert.id)} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
          {alert.notifications?.email
            ?.sort((a, b) => a.recipient.localeCompare(b.recipient))
            .map((notification: BudgetAlertEmailNotificationResponseDTO) => {
              return (
                <PaperListItem
                  key={`budget-alert-paper-notification-email-${notification.id}`}
                  paperClassName={classes.nested}
                  listItemClassName={classes.nestedListItem}
                >
                  <Typography classes={{ root: classes.recipient }}>{notification.recipient}</Typography>
                  {canEdit && (
                    <ActionIconButton
                      buttonSize={"medium"}
                      size={22}
                      action={{
                        icon: GenericIcon.DELETE,
                        tooltip: "Delete notification",
                        onClick: () => onDeleteEmailNotificationForAlert(alert, notification),
                      }}
                    />
                  )}
                </PaperListItem>
              );
            })}

          {canEdit && (
            <PaperListItem
              key={`budget-alert-paper-add-notification-email-${alert.id}`}
              paperClassName={classes.nested}
              listItemClassName={[classes.nestedListItem, classes.addEmailListItem].join(" ")}
            >
              <BudgetAlertInlineEditEmailNotification
                key={`budget-alert-add-notification-email-inline-form-${alert.id}`}
                alertId={alert.id}
                setEditFn={setEditFn}
                isEditFn={isEditFn}
                onSave={(form: CreateEmailNotificationRequestDTO) => onSaveEmailNotificationForAlert(form, alert)}
              />
            </PaperListItem>
          )}
        </List>
      </Collapse>
    </>
  );
};
