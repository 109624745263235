import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import { ListItemLink } from "./ListItemLink";
import { Button } from "@material-ui/core";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    // margin: theme.spacing(1)
    // color: "#dedede",
    // backgroundColor: "#2e2e2e",
    // border: "1px solid #4d4d4d",
    // minWidth: "unset",
    // height: theme.spacing(5),
    // width: theme.spacing(5),
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    // paddingBottom: 6,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // "&:hover": {
    //   backgroundColor: "#555555",
    //   border: "1px solid #8d8d8d",
    // },
  },
}));

export interface MenuOptionsItem {
  text: string;
  onClick?: () => void;
}

interface MenuOptionsProps {
  menuItems: MenuOptionsItem[];
  loading: boolean;
}

export function MenuOptions({ menuItems, loading }: MenuOptionsProps) {
  const classes = useStyles();
  const menuId = "table-settings-menu";
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {loading && (
        <>
          <CircularProgress size={16} />
          <Button className={classes.menuIcon} disabled>
            Preparing download, this may take a while
          </Button>
        </>
      )}
      <IconButton
        // variant="contained"
        disabled={loading}
        aria-label="Settings menu datatable"
        aria-controls={menuId}
        aria-haspopup="true"
        className={classes.menuIcon}
        onClick={handleMenuOpen}
      >
        <MenuIcon />
        {/*{loading && <span>Preparing download, this may take a while..</span>}*/}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <List component="nav">
          {menuItems.map((item) => (
            <ListItemLink
              key={item.text}
              primary={item.text}
              onClick={() => {
                handleMenuClose();
                item.onClick && item.onClick();
              }}
            />
          ))}
        </List>
      </Menu>
    </div>
  );
}
