import { CreateUserRequest, EditUserRequest, UserDTO } from "../open-api";
import { API } from "./context.service";

export interface UserService {
  getAll(): Promise<UserDTO[]>;

  create(params: CreateUserRequest): Promise<UserDTO>;

  edit(params: EditUserRequest): Promise<void>;

  delete(id: string): Promise<void>;
}

export class LiveUserService implements UserService {
  constructor(private api: API) {}

  getAll = (): Promise<UserDTO[]> => {
    return this.api.user.getUsers();
  };

  create = (params: CreateUserRequest): Promise<UserDTO> => {
    return this.api.user.createUser(params);
  };

  edit = (params: EditUserRequest): Promise<void> => {
    return this.api.user.editUser(params);
  };

  delete = (id: string): Promise<void> => {
    return this.api.user.deleteUser({ userId: id });
  };
}
