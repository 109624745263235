import { TextFieldProps, useTheme } from "@material-ui/core";
import { Controller, FieldError } from "react-hook-form";
import { FormSelectSingle } from "./FormSelectSingle";
import { Control } from "react-hook-form/dist/types";
import { FormCountry, getCountries } from "../../utils/functional/country.utils";
import { css } from "@emotion/css";

type FormSelectCountryProps = Omit<TextFieldProps, "error"> & {
  error?: FieldError;
  silent?: boolean;
  label?: string;
  id?: string;
  name: string;
  control: Control<any>;
};

export function FormSelectCountry({ label, name, id = name, control }: FormSelectCountryProps) {
  const theme = useTheme();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormSelectSingle<FormCountry>
          className={css({ marginBottom: theme.spacing(2) })}
          value={value}
          handleChange={(e: any) => onChange(e.target.value)}
          options={getCountries()}
          optionValue={(e) => e.name}
          id={id}
          label={label}
          optionId={"id"}
          fullWidth
        />
      )}
    />
  );
}
