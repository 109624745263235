import { ChangeEvent, ReactNode, useState } from "react";
import TreeViewStyled from "../../components/tags/TreeViewStyled";
import { TreeListItemProps } from "../../components/tags/TreeItemStyled";

interface TreeViewProps<T> {
  isLoading: boolean;
  treeHeader?: ReactNode;
  items: TreeListItemProps<T>[];
  nodeIds: string[];
  className?: string;
}

export const TreeView = <T extends any>({ isLoading, items, nodeIds, className, treeHeader }: TreeViewProps<T>) => {
  const [, setCollapsed] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
    setCollapsed(nodeIds.length === 0);
  };

  return (
    <TreeViewStyled
      isLoading={isLoading}
      items={items}
      expanded={expandedNodes}
      handleToggle={handleToggle}
      className={className}
      headerHeight={treeHeader ? 22 : 0}
    />
  );
};
