import Grid from "@material-ui/core/Grid";
import { Controller } from "react-hook-form";
import { FormSelectSingle } from "../../../components/form/FormSelectSingle";
import { CSV_UPLOAD_CONFIG } from "../../../clients/collector.client";
import {
  DATE_FORMAT_ID,
  DELIMITER_ID,
  HAS_HEADER_ID,
  NUMBER_FORMAT_ID,
  useCsvUploadFormMethods,
} from "./CsvUploadForm";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridRoot: {
      padding: theme.spacing(1),
    },
  })
);

export const CsvConfiguration = () => {
  const classes = useStyles();
  const formMethods = useCsvUploadFormMethods();

  return (
    <Grid container spacing={2} classes={{ root: classes.gridRoot }}>
      <Grid container item spacing={2}>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <FormSelectSingle
                optionValue={(e) => e.label}
                value={value}
                label="Format of numbers"
                handleChange={onChange}
                options={CSV_UPLOAD_CONFIG.NUMBER_FORMAT_OPTIONS}
                optionId={"key"}
                id={name}
              />
            )}
            name={NUMBER_FORMAT_ID}
            defaultValue={"DEFAULT"}
            rules={{ required: "This field is required" }}
            control={formMethods.control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <FormSelectSingle
                optionValue={(e) => e.label}
                value={value}
                label="Format of dates"
                handleChange={onChange}
                options={CSV_UPLOAD_CONFIG.DATE_FORMAT_OPTIONS}
                optionId={"key"}
                id={name}
              />
            )}
            name={DATE_FORMAT_ID}
            defaultValue={CSV_UPLOAD_CONFIG.DATE_FORMAT_OPTIONS[0].key}
            control={formMethods.control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <FormSelectSingle
                emptyOption={true}
                emptyLabel={"Auto detect"}
                optionValue={(e) => e.label}
                value={value}
                label="Delimiter"
                handleChange={onChange}
                options={CSV_UPLOAD_CONFIG.DELIMITER_OPTIONS}
                optionId={"key"}
                id={name}
              />
            )}
            name={DELIMITER_ID}
            control={formMethods.control}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <FormSelectSingle
                optionValue={(e) => e.label}
                value={value}
                label="Header row"
                handleChange={onChange}
                options={[
                  {
                    key: true,
                    label: "Yes",
                  },
                  {
                    key: false,
                    label: "No",
                  },
                ]}
                optionId={"key"}
                id={name}
              />
            )}
            name={HAS_HEADER_ID}
            control={formMethods.control}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
