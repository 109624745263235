import { pipe } from "./fp";

export * as Iterable from "./iterable.utils";

/**
 * Not every iterable can repeatedly being iterated.
 * By default, a generator, can only be run once.
 * Which sucks if you want to use a generator as an iterable.
 * @param iterator
 */
export function repeatable<T>(iterator: () => Iterator<T>): Iterable<T> {
  return { [Symbol.iterator]: () => iterator() };
}

export function naturals(start = 0): Iterable<number> {
  return repeatable(function* () {
    while (true) yield start++;
  });
}

export function takeWhile<T>(self: Iterable<T>, predicate: (t: T) => boolean): Iterable<T> {
  return repeatable(function* () {
    for (const item of self) {
      if (!predicate(item)) break;
      yield item;
    }
  });
}

export const range = (start: number, end: number) => pipe(naturals(start), (_) => takeWhile(_, (n) => n < end));

export function map<T, R>(self: Iterable<T>, transform: (value: T) => R): Iterable<R> {
  return repeatable(function* () {
    for (const item of self) yield transform(item);
  });
}
