import { Button, Dialog, Typography, useTheme } from "@material-ui/core";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { useState } from "react";
import { DialogProps } from "../../../hooks/dialog.hooks";
import { BatchVerificationRow } from "./BatchCreationVerificationDialog";
import { BatchProjectionCollectorAttributesDTO } from "../../../open-api";
import { BatchProcessingRow } from "./AutomaticCustomerCreation.interface";

interface BatchMergeVerificationDialogProps extends DialogProps {
  selected: BatchProcessingRow[] | null;
  onVerify: () => Promise<void>;
  typeToMerge: string;
  datasources: BatchProjectionCollectorAttributesDTO[];
}

export function BatchMergeVerificationDialog({
  open,
  onClose,
  selected,
  onVerify,
  typeToMerge,
  datasources,
}: BatchMergeVerificationDialogProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();

  const onSave = () => {
    if (selected) {
      setIsLoading(true);
      onVerify().finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  };
  if (!selected) return null;

  const toMerge = selected.find((it) => it.state === "selected-for-merge");

  const toJoin = selected.filter((it) => it.state === "selected-for-join");

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
      <DialogTitleCloseable color={theme.palette.secondary.main} onClose={onClose}>
        Verify merging of {typeToMerge}s
      </DialogTitleCloseable>
      <DialogContent>
        {toMerge && (
          <>
            <Typography variant={"h6"}>Merge into:</Typography>
            <BatchVerificationRow rows={[toMerge]} typeLabel={typeToMerge} datasources={datasources} />
          </>
        )}
        {toJoin.length > 0 && (
          <>
            <Typography variant={"h6"}>The following:</Typography>
            <BatchVerificationRow rows={toJoin} typeLabel={typeToMerge} datasources={datasources} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonConfirm color="secondary" onClick={onSave} isSubmitting={isLoading}>
          Merge
        </ButtonConfirm>
      </DialogActions>
    </Dialog>
  );
}
