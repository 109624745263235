import { TooltipIconButton } from "./TooltipIconButton";
import EditIcon from "@material-ui/icons/Edit";

interface EditIconButtonProps {
  onClick?: (e?: any) => void;
  tooltip: string;
  disabled?: boolean;
}

export function EditIconButton({ onClick, tooltip, disabled }: EditIconButtonProps) {
  return (
    <TooltipIconButton
      onClick={onClick}
      tooltip={tooltip}
      disabled={disabled}
      ariaLabel={"edit"}
      iconButton={<EditIcon />}
    />
  );
}
