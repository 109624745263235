/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ProjectionRequestDTO,
    ProjectionResponseDTO,
} from '../models';

export interface GetPossibleProjectionsRequest {
    organizationalUnitId?: string;
}

export interface SaveProjectionsRequest {
    projectionRequestDTO: ProjectionRequestDTO;
}

/**
 * 
 */
export class ProjectionControllerApi extends runtime.BaseAPI {

    /**
     */
    async getPossibleProjectionsRaw(requestParameters: GetPossibleProjectionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProjectionResponseDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.organizationalUnitId !== undefined) {
            queryParameters['organizationalUnitId'] = requestParameters.organizationalUnitId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/projections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getPossibleProjections(requestParameters: GetPossibleProjectionsRequest = {}, initOverrides?: RequestInit): Promise<Array<ProjectionResponseDTO>> {
        const response = await this.getPossibleProjectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveProjectionsRaw(requestParameters: SaveProjectionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectionRequestDTO === null || requestParameters.projectionRequestDTO === undefined) {
            throw new runtime.RequiredError('projectionRequestDTO','Required parameter requestParameters.projectionRequestDTO was null or undefined when calling saveProjections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/projections`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.projectionRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveProjections(requestParameters: SaveProjectionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.saveProjectionsRaw(requestParameters, initOverrides);
    }

}
