import MaterialTable, { Column } from "@material-table/core";
import { tableIcons } from "../../theme/TableIcons";
import { useDataClientPromise } from "../../hooks/client.hooks";
import { NotificationResponseDTO } from "../../open-api";
import { useServices } from "../../services/context.service";
import React from "react";
import { formattedDateTime } from "../../utils/localization.utils";
import { Interweave } from "interweave";
import { makeStyles } from "@material-ui/core/styles";

interface NotificationsPageProps {}

const useStyles = makeStyles((theme) => ({
  link: {
    "& > a": {
      color: "#cddc39",
    },
  },
  unread: {
    fontWeight: 900,
  },
}));

export function NotificationsPage(props: NotificationsPageProps) {
  const actionCenterService = useServices().actionCenter;

  const [notifications] = useDataClientPromise(actionCenterService.getNotifications, []);

  const classes = useStyles();

  const columns: Column<NotificationResponseDTO>[] = [
    {
      title: "Received At",
      field: "createdAt",
      width: 250,
      render: (row) => {
        return <p className={!row.read ? classes.unread : ""}>{formattedDateTime(row.createdAt, "Never")}</p>;
      },
    },
    {
      title: "Stream",
      field: "topic",
      width: 250,
      render: (row) => {
        return <p className={!row.read ? classes.unread : ""}>{row.topic}</p>;
      },
    },
    {
      title: "Notification",
      field: "text",
      width: 1000,
      render: (row) => {
        return (
          <p className={!row.read ? classes.unread : ""}>
            <Interweave className={classes.link} content={row.text} />
          </p>
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={"Notifications"}
        columns={columns}
        data={notifications || []}
        options={{ draggable: false, pageSize: 20, actionsColumnIndex: -1 }}
        actions={[]}
      />
    </>
  );
}
