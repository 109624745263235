import { deleteRequest, postRequest, putRequest } from "../utils/api.utils";
import { useDataClient } from "../hooks/client.hooks";
import {
  BudgetResponseDTO,
  CreateBudgetAlertRequestDTO,
  CreateBudgetRequestDTO,
  CreateEmailNotificationRequestDTO,
} from "../open-api";

export const useBudgetsForOrganization = (organizationalUnitId: string) =>
  useDataClient<BudgetResponseDTO[]>(`/api/organizational-units/${organizationalUnitId}/budgets`);

export const createBudgetForOrganization = (organizationalUnitId: string, budget: CreateBudgetRequestDTO) =>
  postRequest(`/api/organizational-units/${organizationalUnitId}/budgets`, budget);

export const updateBudget = (organizationalUnitId: string, budgetId: string, budget: CreateBudgetRequestDTO) =>
  putRequest(`/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}`, budget);

export const deleteBudget = (organizationalUnitId: string, budgetId: string) =>
  deleteRequest(`/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}`);

export const createBudgetAlert = (organizationalUnitId: string, budgetId: string, alert: CreateBudgetAlertRequestDTO) =>
  postRequest(`/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}/alerts`, alert);

export const updateBudgetAlert = (
  organizationalUnitId: string,
  budgetId: string,
  alertId: string,
  alert: CreateBudgetAlertRequestDTO
) => putRequest(`/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}/alerts/${alertId}`, alert);

export const deleteBudgetAlert = (organizationalUnitId: string, budgetId: string, alertId: string) =>
  deleteRequest(`/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}/alerts/${alertId}`);

export const createEmailNotificationForBudgetAlert = (
  organizationalUnitId: string,
  budgetId: string,
  alertId: string,
  emailNotification: CreateEmailNotificationRequestDTO
) =>
  postRequest(
    `/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}/alerts/${alertId}/notifications/email`,
    emailNotification
  );

export const deleteEmailNotificationForBudgetAlert = (
  organizationalUnitId: string,
  budgetId: string,
  alertId: string,
  emailNotificationId: string
) =>
  deleteRequest(
    `/api/organizational-units/${organizationalUnitId}/budgets/${budgetId}/alerts/${alertId}/notifications/email/${emailNotificationId}`
  );
