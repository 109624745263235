import { Grid, Typography } from "@material-ui/core";
import { CFactsIcon, Icon } from "../theme/Icon";

interface EmptyContainerProps {
  title: string;
  text?: string;
  icon?: CFactsIcon;
}

export const EmptyContainer = ({ icon, title, text }: EmptyContainerProps) => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {icon && <Icon icon={icon} size={300} />}
      <Typography variant="h4">{title}</Typography>
      {text && <Typography>{text}</Typography>}
    </Grid>
  );
};
