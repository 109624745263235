import { Tile } from "../../components/dialogs/Tiles";
import { makeStyles } from "@material-ui/core/styles";
import { DataSourceType } from "../../clients/collector.client";
import { TilesChooser } from "../../components/dialogs/TilesChooser";
import { Icon } from "../../theme/Icon";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: 200,
    maxHeight: 80,
    width: 200,
    height: 80,
  },
}));

interface DataSourceTilesProps {
  onSetType: (type: DataSourceType) => void;
  onClose: () => void;
  buttonCancel?: boolean;
}

export const DataSourceDialogTiles = ({ onSetType, onClose, buttonCancel = true }: DataSourceTilesProps) => {
  const classes = useStyles();

  const upload = useFeatureToggle(FEATURES.upload);

  let tileList: Tile[] = [];

  tileList.push({
    contents: <Icon icon={DataSourceType.AWS} className={classes.logo} />,
    onClick: () => onSetType(DataSourceType.AWS),
  });

  tileList.push({
    contents: <Icon icon={DataSourceType.AZURE} className={classes.logo} />,
    onClick: () => onSetType(DataSourceType.AZURE),
  });

  tileList.push({
    contents: <Icon icon={DataSourceType.ORACLE} className={classes.logo} />,
    onClick: () => onSetType(DataSourceType.ORACLE),
  });

  tileList.push({
    contents: <Icon icon={DataSourceType.GOOGLE} className={classes.logo} />,
    onClick: () => onSetType(DataSourceType.GOOGLE),
  });

  // TODO: Remove when feature toggle is permanently on
  if (upload) {
    tileList.push({
      contents: <Icon icon={DataSourceType.UPLOAD} className={classes.logo} />,
      // contents: <Typography>Upload CSV</Typography>,
      onClick: () => onSetType(DataSourceType.UPLOAD),
    });
  }

  return <TilesChooser tileList={tileList} onClose={onClose} buttonCancel={buttonCancel} />;
};
