import { BillingPeriodFilter } from "../clients/billing-periods.client";
import { useContext } from "react";
import { BillingCycleContext } from "../providers/billing-cycle.provider";

/**
 * Returns whether or not to use monthly billing periods
 */
export const useMonthlyBillingPeriod = () => !useSelectedBillingPeriods();
export const useSelectedBillingPeriods = () => {
  const context = useContext(BillingCycleContext);

  return context ? context[0] : null;
};
export const useSetSelectedBillingPeriods = () => {
  const context = useContext(BillingCycleContext);

  return (selectedBillingPeriods: BillingPeriodFilter | null) => (context ? context[1](selectedBillingPeriods) : null);
};
