import { Box, List, Paper, Typography } from "@material-ui/core";
import { BudgetAlertInlineEditBudgetAlert } from "./BudgetAlertInlineEditBudgetAlert";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCanEditAdminAndCView } from "../../../../hooks/auth.hooks";
import { createBudgetAlert } from "../../../../clients/budgetalerts.client";
import { messageError, messageSuccess } from "../../../../services/message.service";
import {
  BudgetAlertResponseDTO,
  BudgetResponseDTO,
  CreateBudgetAlertRequestDTO,
  OrganizationalUnitResponseDTO,
} from "../../../../open-api";
import { roundToTwoDecimals } from "../../../../utils/localization.utils";
import { BudgetAlertListItem } from "./BudgetAlertListItem";
import { PaperListItem } from "./PaperListItem";

interface BudgetAlertsListProps {
  budget?: BudgetResponseDTO;
  spending?: number;
  setEditFn: (id: string, editing: boolean) => void;
  isEditFn: (id: string) => boolean;
  organization: OrganizationalUnitResponseDTO;
  refreshBudgets: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      minWidth: 420,
    },
    alerts: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    expandAll: {
      marginRight: 0,
    },
    listItem: {
      background: theme.palette.background.default,
      marginBottom: theme.spacing(1),
    },
    green: {
      color: theme.palette.success.main,
    },
    red: {
      color: theme.palette.error.main,
    },
  })
);

export interface OpenAlerts {
  [id: string]: boolean;
}

export const BudgetAlertsList = ({
  budget,
  spending,
  setEditFn,
  isEditFn,
  organization,
  refreshBudgets,
}: BudgetAlertsListProps) => {
  const classes = useStyles();
  const canEdit = useCanEditAdminAndCView();

  const [listItems, setListItems] = useState<AlertListItem[]>([]);

  interface AlertListItem {
    alert: BudgetAlertResponseDTO;
    value: number;
    thresholdPassed?: boolean;
  }

  const [expandedAlerts, setExpandedAlerts] = useState<OpenAlerts>({} as OpenAlerts);

  useEffect(() => {
    const newAlertIds = {} as OpenAlerts;
    budget?.alerts?.map((alert) => (newAlertIds[alert.id] = expandedAlerts[alert.id]));
    setExpandedAlerts(newAlertIds);

    if (budget) {
      const currentSpendingPercentage = roundToTwoDecimals(((spending ?? 0) / budget.value) * 100);

      const alertItems: AlertListItem[] = [];

      const map: AlertListItem[] = budget.alerts
        ? budget.alerts.map((it) => {
            return {
              alert: it,
              value: it.thresholdValue,
              thresholdPassed: it.thresholdValue < currentSpendingPercentage,
            };
          })
        : [];

      setListItems(alertItems.concat(map));
    }
    // eslint-disable-next-line
  }, [budget, spending]);

  const isAlertExpanded = (alertId: string): boolean => {
    return expandedAlerts[alertId];
  };

  const toggleAlertExpanded = (alertId: string) => {
    const newAlertIds = Object.assign({}, expandedAlerts);
    newAlertIds[alertId] = !newAlertIds[alertId];
    setExpandedAlerts(newAlertIds);
  };

  const setAlertOpen = (alertId: string) => {
    const newAlertIds = Object.assign({}, expandedAlerts);
    newAlertIds[alertId] = true;
    setExpandedAlerts(newAlertIds);
  };

  const allAlertsCollapsed = (): boolean => {
    return !!Object.keys(expandedAlerts).find((idx) => expandedAlerts[idx]);
  };

  const toggleAllAlertsExpanded = () => {
    const newState: boolean = !allAlertsCollapsed();
    const newAlertIds = Object.assign({}, expandedAlerts);
    Object.keys(newAlertIds).map((idx) => (newAlertIds[idx] = newState));
    setExpandedAlerts(newAlertIds);
  };

  const onCreateBudgetAlert = (form: CreateBudgetAlertRequestDTO) => {
    budget?.id &&
      createBudgetAlert(organization.id, budget.id, form)
        .then((alert: BudgetAlertResponseDTO) => {
          refreshBudgets();
          messageSuccess("Alert saved");
          toggleAlertExpanded(alert.id);
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  return (
    <>
      <Box display={"flex"}>
        <Typography style={{ flexGrow: 1 }} variant={"h6"}>
          Alerts
        </Typography>
        <ActionIconButton
          className={classes.expandAll}
          size={28}
          action={{
            icon: allAlertsCollapsed() ? GenericIcon.UNFOLD_LESS : GenericIcon.UNFOLD_MORE,
            tooltip: allAlertsCollapsed() ? "Collapse all e-mails" : "Expand all e-mails",
            onClick: (event) => {
              event.stopPropagation();
              toggleAllAlertsExpanded();
            },
          }}
          buttonSize={"medium"}
        />
      </Box>

      {!budget ? (
        <Typography color={"textSecondary"}>First configure a budget</Typography>
      ) : (
        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root} disablePadding>
          {listItems
            .sort((a, b) => a.value - b.value)
            .map((listItem: AlertListItem) => {
              const alert = listItem.alert;
              return (
                <Paper
                  key={`budget-alert-list-paper-${alert.id}`}
                  className={[classes.listItem, listItem.thresholdPassed ? classes.red : classes.green].join(" ")}
                  elevation={2}
                  variant={"elevation"}
                >
                  <BudgetAlertListItem
                    alert={alert}
                    budget={budget}
                    isAlertExpanded={isAlertExpanded}
                    setAlertOpen={setAlertOpen}
                    setEditFn={setEditFn}
                    isEditFn={isEditFn}
                    toggleAlertExpanded={toggleAlertExpanded}
                    organization={organization}
                    refreshBudgets={refreshBudgets}
                  />
                </Paper>
              );
            })}

          {canEdit && (
            <PaperListItem key={`budget-add-alert-${budget?.budgetType}`} paperClassName={classes.listItem}>
              <BudgetAlertInlineEditBudgetAlert
                budget={budget}
                setEditFn={setEditFn}
                isEditFn={isEditFn}
                onSave={onCreateBudgetAlert}
              />
            </PaperListItem>
          )}
        </List>
      )}
    </>
  );
};
