import Grid from "@material-ui/core/Grid";
import { FormPartial } from "../../../../utils/form.utils";
import { FormTitleField } from "../../../../components/form/FormTitleField";
import { FormSelectCountry } from "../../../../components/form/FormSelectCountry";
import { ControlledTextField } from "../../../../components/form/FormComponents";
import { z } from "zod";

interface OrganizationalUnitFormPartialContactProps extends FormPartial<z.infer<typeof contactSchema>> {}

export const contactSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  replyTo: z
    .object({
      name: z.string().optional(),
      email: z.string().email().optional(),
    })
    .optional(),
  country: z.string().optional(),
  administrativeArea: z.string().optional(),
  postalCode: z.string().optional(),
  locality: z.string().optional(),
  thoroughfare: z.string().optional(),
  premise: z.string().optional(),
  chamberOfCommerce: z.string().optional(),
});

export function OrganizationalUnitFormPartialContact({ control }: OrganizationalUnitFormPartialContactProps) {
  return (
    <Grid container>
      <FormTitleField text={"Contact person"} />
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"firstName"} label="First name" />
      </Grid>
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"lastName"} label={"Last name"} />
      </Grid>
      <FormTitleField text={"Reply to (used for future internal emailing)"} />
      <Grid item xs={12}>
        <ControlledTextField fullWidth control={control} name={"replyTo.name"} label={"Name"} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField fullWidth control={control} name={"replyTo.email"} label={"E-mail"} />
      </Grid>
      <FormTitleField text={"Address details"} />
      <Grid item xs={12}>
        <FormSelectCountry label={`Country`} id="country" name="country" control={control} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          fullWidth
          control={control}
          name={"administrativeArea"}
          label={`State / Province / Region`}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"postalCode"} label={"Postal code"} />
      </Grid>
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"locality"} label={"City"} />
      </Grid>
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"thoroughfare"} label={"Address"} />
      </Grid>
      <Grid item xs={6}>
        <ControlledTextField fullWidth control={control} name={"premise"} label={"Apartment, Suite, Box number"} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          fullWidth
          control={control}
          name={"chamberOfCommerce"}
          label={"Chamber of Commerce reference"}
        />
      </Grid>
    </Grid>
  );
}
