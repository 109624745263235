import { Component, ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";
import { ErrorPage } from "./ErrorPage";

class ErrorBoundary extends Component<{}, { eventId?: any; hasError?: any }> {
  constructor(props: any) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra("info", errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return <ErrorPage errorId={this.state.eventId} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
