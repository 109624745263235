import { Button, ListItem, Typography } from "@material-ui/core";
import { useAppContext } from "../../hooks/auth.hooks";
import { disableAllowAdminAssume, enableAllowAdminAssume } from "../../clients/admin/assume.client";
import { css } from "@emotion/css";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";

export const SupportDetails = () => {
  const { user, refresh } = useAppContext();

  return (
    <>
      <Typography variant={"h6"} className={css({ marginBottom: "12px" })}>
        C-Facts admin access
      </Typography>
      {user?.allowAdminAssume && (
        <Alert
          className={css({ marginBottom: "12px" })}
          variant="outlined"
          severity="warning"
          title={"Admin has access"}
        >
          You have enabled access so that a C-Facts support employee can view your portal the way you see it.
        </Alert>
      )}
      <Typography>
        If something is wrong with your environment, you can give C-Facts support access to your environment to review
        configurations. After the issue is resolved, the access can be revoked again.
      </Typography>

      <ListItem>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            Current status: <strong>{user?.allowAdminAssume ? "Enabled" : "Disabled"}</strong>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            {user?.allowAdminAssume ? (
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => disableAllowAdminAssume().then(refresh)}
              >
                Disable access
              </Button>
            ) : (
              <Button variant="outlined" fullWidth onClick={() => enableAllowAdminAssume().then(refresh)}>
                Enable access
              </Button>
            )}
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};
/*
CollectorIds:
58e06965-ab1a-4e70-a405-988b812687da
22733cb5-c35d-4d1e-8b29-d49f05904e76
0b4deaa8-f54c-4e26-9d4b-cad39d7e46af
 */
