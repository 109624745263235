import { debounce } from "../utils/debounce.utils";
import { toast } from "react-toastify";
import { appConfig } from "../app.config";

export const failDebounce = (message: string) =>
  debounce(() => {
    messageError(message);
  }, appConfig.waitForChart);

export const failFormValidation = (field: string, message: string) => {
  toast.error(`${field}: ${message}`, { toastId: field + message });
};

export const messageSuccess = (message: string) => {
  toast.success(message);
};

export const messageInfo = (message: string) => {
  toast.info(message);
};

export const messageError = (message: string) => {
  toast.error(message);
};

export const messagesDismiss = () => {
  toast.dismiss();
};
