import Dialog from "@material-ui/core/Dialog";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { CustomerPortalFormCreate } from "../forms/CustomerPortalFormCreate";
import { OrganizationalUnitResponseDTO } from "../../../open-api";
import {useRootOrganizations} from "../../../clients/organizationalunit.client";

interface CustomerPortalCreateDialogProps {
  open: boolean;
  onClose: () => void;
  customer: OrganizationalUnitResponseDTO | null;
  onSuccess?: () => void;
}

export function CustomerPortalDialogCreate({
  open,
  onClose,
  customer,
  onSuccess,
}: CustomerPortalCreateDialogProps) {
  const [organizationalUnits] = useRootOrganizations();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog maxWidth={"md"} open={open} onClose={handleClose} disableBackdropClick>
      <DialogTitleCloseable onClose={handleClose}>Share C-View</DialogTitleCloseable>
      <CustomerPortalFormCreate
        onClose={handleClose}
        onSuccess={() => {
          if (onSuccess) {
            onSuccess();
          }
          handleClose();
        }}
        onError={() => {
          handleClose();
        }}
        customer={customer}
        organizationalUnits={organizationalUnits || []}
      />
    </Dialog>
  );
}
