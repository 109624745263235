import { BatchProcessingRow, SetBatchRows } from "../AutomaticCustomerCreation.interface";
import { BatchProjectionCollectorAttributesDTO } from "../../../../open-api";
import Fab from "@material-ui/core/Fab";
import { css } from "@emotion/css";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { BatchCreationVerificationDialog } from "../BatchCreationVerificationDialog";
import React from "react";
import { useTheme } from "@material-ui/core";
import { useServices } from "../../../../services/context.service";
import { useDialog } from "../../../../hooks/dialog.hooks";
import { OrganizationalUnitType } from "../../../../clients/organizationalunit.client";
import { mapOrgTypeToDisplay } from "../AutomaticCustomerCreation.utils";

interface CreateCustomerVerificationProps {
  selected: BatchProcessingRow[];
  type: OrganizationalUnitType;
  setRows: SetBatchRows;
  collectors: BatchProjectionCollectorAttributesDTO[];
  onSuccess: () => void;
}

export const CreateCustomerVerification = ({
  selected,
  type,
  collectors,
  onSuccess,
}: CreateCustomerVerificationProps) => {
  const theme = useTheme();
  const services = useServices();
  const [dialog, setDialog, dialogProps] = useDialog<BatchProcessingRow[]>();

  if (selected.length === 0) {
    return null;
  }

  const typeLabel = mapOrgTypeToDisplay(type);

  const onSave = (selected: BatchProcessingRow[]) => {
    return services.projections
      .batchUpdate(
        selected.map((row) => ({
          attributeValues: Object.keys(row.datasources)
            .flatMap((ds) => row.datasources[ds].newValues.map((it) => ({ ...it, collectorId: ds })))
            .map((attr) => {
              return {
                collectorId: attr.collectorId,
                rootAttributeValue: attr.rootId,
                detailAttributeValue: attr.detailId,
              };
            }),
          organization:
            row.organization.type === "new"
              ? {
                  type: "new" as const,
                  name: row.organization.name,
                  unitType: type,
                }
              : {
                  type: "existing" as const,
                  id: row.organization.id,
                  name: row.organization.name,
                  unitType: type,
                },
        }))
      )
      .then((it) => {
        onSuccess();
      });
  };

  return (
    <>
      <Fab
        aria-label={`Create/merge ${typeLabel}s`}
        className={css({
          position: "absolute",
          bottom: theme.spacing(2),
          right: theme.spacing(4),
        })}
        variant={"extended"}
        color={"primary"}
        onClick={() => setDialog(selected)}
      >
        <Tooltip title={"Add organizational unit"} placement="left">
          <>
            <AddIcon />{" "}
            {selected.length > 0 && (
              <>
                Create {selected.length} {typeLabel}
                {selected.length > 1 && "s"}
              </>
            )}
          </>
        </Tooltip>
      </Fab>
      <BatchCreationVerificationDialog
        typeToCreate={typeLabel}
        selected={dialog}
        datasources={collectors}
        {...dialogProps}
        onVerify={onSave}
      />
    </>
  );
};
