import { ArgumentAttribute, GroupSegment } from "./GroupSegment";
import { CostMap } from "graphql-simple-bindings/lineitems";
import { QueryDimensionOrderByEnum } from "../../../open-api";

export const rawChartType = "raw" as const;
export type RawChartType = typeof rawChartType;

export const rangeChartTypes = ["line", "spline", "bar"] as const;
export type RangeChartTypes = typeof rangeChartTypes[number];

export const pieChartTypes = ["pie", "donut"] as const;
export type PieChartTypes = typeof pieChartTypes[number];

interface BaseChartConfig {
  id: string;
  title?: string;
  type: string;
}

export interface RawChartConfig extends BaseChartConfig {
  type: RawChartType;
  displaySegment: LineItemDisplay;
  groupSegment?: undefined;
}

export interface RangeChartConfig extends BaseChartConfig {
  type: RangeChartTypes;
  groupSegment: GroupSegment;
  displaySegment: LineItemDisplay;
  argument: ArgumentAttribute;
}

export interface PieChartConfig extends BaseChartConfig {
  type: PieChartTypes;
  groupSegment: GroupSegment;
  displaySegment: LineItemDisplay;
}

export type ChartConfig = RawChartConfig | RangeChartConfig | PieChartConfig;

export interface LineItemDisplay {
  value: keyof CostMap | "quantity";
  label: string;
  format: "currency" | "thousands";
  key?: QueryDimensionOrderByEnum;
}

export function cleanDisplaySegmentFromString(key: string) {
  let newKey = key;
  Object.values(DISPLAY_SEGMENT).forEach((it) => {
    newKey = newKey.replace(it.value, "");
  });
  return newKey;
}

export const DISPLAY_SEGMENT = {
  COST: {
    value: "cost" as const,
    label: "Cost",
    format: "currency" as const,
    key: "COST" as const,
  },
  SALES: {
    value: "sales" as const,
    label: "Sales",
    format: "currency" as const,
    key: "SALES" as const,
  },
  PROFIT: {
    value: "profit" as const,
    label: "Profit",
    format: "currency" as const,
  },
  LIST: {
    value: "list" as const,
    label: "Retail price",
    format: "currency" as const,
    key: "RETAIL" as const,
  },
  DISCOUNT: {
    value: "discount" as const,
    label: "Cost after discount",
    format: "currency" as const,
    key: "DISCOUNT" as const,
  },
  CROSS_CHARGING: {
    value: "crossCharging" as const,
    label: "Cross-charging",
    format: "currency" as const,
    key: "CROSS_CHARGING" as const,
  },
  QUANTITY: {
    value: "quantity" as const,
    label: "Quantity",
    format: "thousands" as const,
    key: "QUANTITY" as const,
  },
};
