import { Grid, Typography, useTheme } from "@material-ui/core";
import HorizontalDL from "../../../components/grid/HorizontalDL";
import {
  formattedCurrency,
  formattedDate,
  formattedMonth,
  formattedPercentage,
} from "../../../utils/localization.utils";
import { DataSourceIcon, Icon } from "../../../theme/Icon";
import { useCurrency } from "../../../hooks/config.hooks";
import { MenuOptionsGraph } from "../../../components/menu/MenuOptionsGraph";
import { InvoiceResponseDTO } from "../../../open-api";
import { FC, useState } from "react";
import { InformationLink } from "../../../components/Information";
import { css } from "@emotion/css";
import { Alert } from "@material-ui/lab";
import { useFeatureToggle } from "../../../hooks/ft.hooks";
import { DownloadReconDialog } from "./DownloadReconDialog";
import { useAggregateQuery } from "../../../clients/query.client";
import { csvRequest, xlsRequest } from "../../../utils/api.utils";

interface InvoiceHeaderProps {
  invoice: InvoiceResponseDTO;
}

export const InvoiceHeader: FC<InvoiceHeaderProps> = ({ invoice }) => {
  const theme = useTheme();
  const useRecon = useFeatureToggle("recon");
  const currency = useCurrency();
  const [showRecon, setShowRecon] = useState(false);
  const [monthly] = useAggregateQuery(
    "BILLING_MONTH",
    "TOTAL",
    [
      {
        dimension: "INVOICE",
        filters: [invoice.id],
      },
    ],
    undefined,
    currency
  );
  const renderReconHamburgerItems = useRecon && invoice.reconAvailable;

  const difference = invoice.invoicedCost ? (invoice.cost / invoice.invoicedCost - 1) * 100 : undefined;

  let exportUrl = `/api/lineitems?currency=${currency}&filter.INVOICE=${invoice.id}`;
  const summaryUrl = `/api/lineitems/query/invoice/${invoice.id}`;

  function renderReconPopup() {
    return () => {
      setShowRecon(true);
      return Promise.resolve();
    };
  }

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid container item xs={8}>
          <Typography variant="h5">
            <Icon icon={invoice.origin as DataSourceIcon} className={css({ height: "1.5rem", width: "5rem" })} />
            <strong className={css({ paddingLeft: theme.spacing(2) })}>{invoice.name}</strong> |{" "}
            <small>{formattedDate(invoice.date)}</small>
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent={"flex-end"}>
          <MenuOptionsGraph
            csv={exportUrl}
            xls={exportUrl}
            reconCsv={renderReconHamburgerItems ? renderReconPopup() : undefined}
            additionalItems={[
              {
                text: `Line item summary - Export CSV file`,
                onClick: () => csvRequest(summaryUrl),
              },
              {
                text: `Line item summary - Export XLS file`,
                onClick: () => xlsRequest(summaryUrl),
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={8}>
          <HorizontalDL
            colSize={4}
            title={
              <span>
                Costs{" "}
                <InformationLink
                  className={css({ marginTop: "4px" })}
                  url={"https://c-facts.atlassian.net/servicedesk/customer/portal/3/article/771457139"}
                />
              </span>
            }
            items={[
              {
                key: "Invoiced cost",
                value: invoice.invoicedCost ? formattedCurrency(invoice.invoicedCost, invoice.currency) : "N/A",
              },
              {
                key: "Sum of lineitems",
                value: (
                  <span>
                    {formattedCurrency(invoice.cost, invoice.currency)}
                    {difference !== undefined ? (
                      <span>
                        {difference > 0 ? " (+" : " ("}
                        {formattedPercentage(difference, 1, 6)})
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                ),
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <HorizontalDL
            colSize={4}
            title="Dates"
            items={[
              { key: "Invoice date", value: formattedDate(invoice.date) },
              { key: "From", value: formattedDate(invoice.billingPeriod.from) },
              {
                key: "To",
                value: formattedDate(invoice.billingPeriod.to),
                align: "right",
              },
            ]}
          />
          <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <HorizontalDL
                className={css({ paddingTop: theme.spacing(2) })}
                colSize={12}
                title={""}
                items={[
                  {
                    key: "Cost per month",
                    value: (
                      <Grid container>
                        {monthly &&
                          monthly.length &&
                          monthly[0].arguments
                            .sort((a, b) => a.argId.localeCompare(b.argId))
                            .map((it) => (
                              <>
                                <Grid item xs={6}>
                                  <Typography>{formattedMonth(it.argDisplay)}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography align="right">
                                    <strong>{formattedCurrency(it.points[0].value.cost, invoice.currency)}</strong>
                                  </Typography>
                                </Grid>
                              </>
                            ))}
                      </Grid>
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!invoice.datasource.isIncludeAll && (
        <Grid item xs={12}>
          <Alert severity={"info"} variant={"outlined"}>
            This invoice is collected by a <strong>filtered</strong> data source. This means that the values shown below
            don't necessarily add up to the invoiced cost.
          </Alert>
        </Grid>
      )}
      {showRecon && <DownloadReconDialog open={showRecon} onClose={() => setShowRecon(false)} invoice={invoice} />}
    </Grid>
  );
};
