import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogActions from "@material-ui/core/DialogActions";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { createCustomerPortal, createManagedCustomerPortal } from "../../../clients/organizationalunit.client";
import { useApiCall } from "../../../hooks/client.hooks";
import { CreateCustomerPortalDTO, OrganizationalUnitResponseDTO } from "../../../open-api";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import Grid from "@material-ui/core/Grid";
import { ControlledSwitch, ControlledTextField } from "../../../components/form/FormComponents";
import React, { useState } from "react";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { boolean, z } from "zod";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    width: 500,
  },
  container: {
    padding: theme.spacing(1),
  },
}));

interface CustomerPortalDialogFormProps {
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  customer: OrganizationalUnitResponseDTO | null;
  organizationalUnits: OrganizationalUnitResponseDTO[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface MultipleSelectCheckmarksProps {
  organizationalUnits?: OrganizationalUnitResponseDTO[];
  selectedOrganizations: string[];
  setSelectedOrganizations: any;
}

function MultipleSelectCheckmarks({
  organizationalUnits,
  selectedOrganizations,
  setSelectedOrganizations,
}: MultipleSelectCheckmarksProps) {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setSelectedOrganizations(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="multiple-checkbox-label" style={{ marginLeft: "15px" }}>
        Organization
      </InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={selectedOrganizations}
        onChange={handleChange}
        input={<OutlinedInput label="Organizational unit" />}
        renderValue={(selected: any) =>
          organizationalUnits
            ?.filter((org) => selected.includes(org.id))
            .map((org) => org.name)
            .join(", ")
        }
        MenuProps={MenuProps}
      >
        {organizationalUnits?.map((organization) => (
          <MenuItem key={organization.name} value={organization.id}>
            <Checkbox checked={selectedOrganizations.indexOf(organization.id) > -1} />
            <ListItemText primary={organization.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function CustomerPortalFormCreate({
  onClose,
  onSuccess,
  onError,
  customer,
  organizationalUnits,
}: CustomerPortalDialogFormProps) {
  const classes = useStyles();
  const [create] = useApiCall(createCustomerPortal);
  const [createManagedPortal] = useApiCall(createManagedCustomerPortal);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);

  const { control, handleSubmit, watch } = useCFForm({
    schema: z.object({
      email: z.string().email("Invalid email address"),
      name: z.string().regex(/(.|\s)*\S(.|\s)*/, "Name can not be empty string"),
      managed: boolean().default(false),
      canAddDataSource: boolean().default(false),
    }),
  });
  const watchManaged = watch("managed", false);

  const createPortal = (organization: string, customerValues: CreateCustomerPortalDTO & { managed?: boolean }) => {
    const createPortalPromise = customerValues.managed
      ? createManagedPortal(organization, customerValues)
      : create(organization, customerValues);
    return createPortalPromise
      .then((e) => {
        messageSuccess("Share data for customer");
        onSuccess && onSuccess();
      })
      .catch((e) => {
        messageError(`Failed to share data for customer: ${e.message}`);
        onError && onError();
      });
  };

  const generatePortals = async (customerValues: CreateCustomerPortalDTO) => {
    if (customer) {
      createPortal(customer.id, customerValues);
    } else {
      createPortal(selectedOrganizations.toString(), customerValues);
    }
  };

  const close = () => {
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(generatePortals)}>
      <DialogContent className={classes.content}>
        <Grid container>
          <Grid item xs={12} className={classes.container}>
            <ControlledTextField control={control} name={"name"} label={"Name"} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <ControlledTextField control={control} name={"email"} label={"E-mail"} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <ControlledSwitch name="managed" label="Managed C-View" control={control} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <ControlledSwitch
              name="canAddDataSource"
              label="Can add data sources"
              control={control}
              disabled={watchManaged}
            />
          </Grid>
          {!customer && (
            <Grid item xs={12} className={classes.container}>
              <MultipleSelectCheckmarks
                organizationalUnits={organizationalUnits}
                selectedOrganizations={selectedOrganizations}
                setSelectedOrganizations={setSelectedOrganizations}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonConfirm>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
