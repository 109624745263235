import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";

interface IDataTableSkeleton {
  rowCount?: number;
}
export const DataTableSkeleton: FC<IDataTableSkeleton> = ({ rowCount = 5 }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Skeleton variant="rect" height={50} />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={2}>
        <Skeleton variant="rect" height={50} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="text" height={30 * rowCount} />
      </Grid>
    </Grid>
  );
};
