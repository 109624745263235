import { AttributeFilterValueDTOStateEnum, AttributeTypeDTODisplayStateEnum } from "../../../../open-api";
import { useResetCache } from "../../../../hooks/virtualized.hooks";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { CFNestedCard } from "../../../../components/layout/CFContainer";
import { css } from "@emotion/css";
import { Checkbox, ListItem, ListItemText } from "@material-ui/core";
import { useState } from "react";
import { AttributeItem } from "./AttributeItem";
import { AttributeFilterButtons } from "./AttributeFilterButtons";
import { AttributeData } from "./AttributeFilterSection";

interface AttributeListProps {
  title: string;
  attributes: AttributeData[];
  setStatus?: SetAttributeStatus;
  search: string;
  type: AttributeTypeDTODisplayStateEnum;
  canEdit: boolean;
  canDelete?: boolean;
  withCheckboxes?: boolean;
}

export type SetAttributeStatus = (attributeId: string, status: AttributeTypeDTODisplayStateEnum) => void;

const filterBySearch = (attributes: Array<AttributeData>, search: string) =>
  attributes.filter((it) => it.displayParam!.toLowerCase().search(search.toLowerCase()) !== -1);

export const AttributeList = ({
  title,
  attributes,
  setStatus,
  search,
  type,
  canEdit,
  canDelete = false,
  withCheckboxes = false,
}: AttributeListProps) => {
  const filteredAttributes = search ? filterBySearch(attributes, search) : attributes;
  const fullTitle = `${title} (${
    filteredAttributes.length < attributes.length ? `${filteredAttributes.length} of ` : ""
  }${attributes.length})`;
  const gridRef = useResetCache(filteredAttributes.length);

  const [allSelected, setAllSelected] = useState(false);

  const handleAllSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    filteredAttributes.map((item) => (item.isSelected = checked));
    setAllSelected(checked);
  };

  const setAllStatus = (status: AttributeFilterValueDTOStateEnum) => {
    filteredAttributes.forEach((attribute) => {
      if (attribute.isSelected) {
        setStatus?.(attribute.id, status);
        attribute.isSelected = false;
      }
    });
    setAllSelected(false);
  };

  const setIsSelected = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const index = filteredAttributes.findIndex((attribute) => attribute.id === id);
    filteredAttributes[index].isSelected = event.target.checked;
  };

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const it = filteredAttributes[index];
    return (
      <div style={style}>
        <AttributeItem
          key={it.id}
          setStatus={setStatus}
          attribute={it}
          canEdit={canEdit}
          canDelete={canDelete && (it.state === "HIDE" || it.state === "DELETE")}
          setIsSelected={setIsSelected}
          withCheckbox={withCheckboxes}
        />
      </div>
    );
  };

  return (
    <>
      <CFNestedCard xs={4} header={fullTitle}>
        <VariableSizeList
          className={css({
            overflow: "hidden",
            margin: "-16px",
          })}
          itemSize={(idx) => Math.ceil(filteredAttributes[idx].displayParam.length / 70) * 32}
          height={200}
          itemCount={filteredAttributes.length}
          ref={gridRef}
          width={"calc(100% + 32px)"}
        >
          {renderRow}
        </VariableSizeList>
        {canEdit && withCheckboxes && (
          <ListItem
            className={css({ overflow: "hidden", marginTop: "20px", width: "100%", paddingLeft: 0, paddingRight: 0 })}
          >
            <Checkbox color="primary" checked={allSelected} onChange={handleAllSelected} />
            <ListItemText
              className={css({
                overflow: "ellipsis",
                padding: 0,
              })}
            >
              {"With selected: "}
            </ListItemText>
            <AttributeFilterButtons
              canDelete={canDelete}
              type={type}
              setStatus={setStatus}
              setAllStatus={setAllStatus}
            />
          </ListItem>
        )}
      </CFNestedCard>
    </>
  );
};
