import { CFCard, CFContainer } from "../../components/layout/CFContainer";
import { Link, Typography } from "@material-ui/core";

export const ApiDocs = () => {
  // TODO get from env variables
  const subdomainRegex = /(https:\/\/).*?\./;
  const currentUrl = window.location.origin;
  const apiUrl = currentUrl.replace(subdomainRegex, "$1api.");

  return (
    <CFContainer>
      <CFCard>
        <Typography>
          In order to use API Docs you need to login first:{" "}
          <Link href={`${apiUrl}/login`} target={"_blank"} rel="noopener noreferrer">
            Login
          </Link>
        </Typography>
        <Typography>
          After successful login API Docs are accessible via following link:{" "}
          <Link href={`${apiUrl}/api/swagger-ui/index.html`} target={"_blank"} rel="noopener noreferrer">
            API Docs
          </Link>
        </Typography>
      </CFCard>
    </CFContainer>
  );
};
