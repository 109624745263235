import { RangeSelector } from "devextreme-react";
import { ChartSeries } from "devextreme/viz/common";
import { DateRange, toDateRange } from "../model/DateRange";
import { MonthlyCostDTO } from "../../../clients/dashboard.client";
import { Arr } from "../../../utils/functional/array.utils";

interface DashRangeFilterProps {
  initRangeStart: string;
  initRangeEnd: string;
  setDateRange: (dateRange: DateRange) => void;
  costs: MonthlyCostDTO[];
}

// TODO: Move to query instead of billing range
export const DashRangeFilter = ({ initRangeStart, initRangeEnd, setDateRange, costs }: DashRangeFilterProps) => {
  const { data, series } = transformToSeries(costs, "billingMonth", "collectorId", "cost");
  return (
    <RangeSelector
      id="range-selector"
      title="Select a date range"
      dataSource={data}
      defaultValue={[initRangeStart, initRangeEnd]}
      size={{ height: 200 }}
      margin={{ left: 10 }}
      value={[initRangeStart, initRangeEnd]}
      scale={{
        minorTickCount: 1,
      }}
      chart={{
        commonSeriesSettings: {},
        series: series as ChartSeries[],
        palette: "Bright",
      }}
      behavior={{
        callValueChanged: "onMovingComplete",
        animationEnabled: false,
      }}
      onValueChanged={({ value }) => {
        if (value == null) return;
        const [startDate, endDate] = value;
        if (!(typeof startDate === "string" && typeof endDate === "string")) return;
        setDateRange(toDateRange({ startDate, endDate }));
      }}
    />
  );
};
//   (prevProps, nextProps) => {
//     // TODO: this works, but it's dirty
//     return true;
//   },
// )
// ;

type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: any;
};

export function transformToSeries<T extends Record<string, any>>(
  rangeLineItems: T[],
  axis: KeyOfType<T, string>,
  field: KeyOfType<T, string>,
  value: KeyOfType<T, number>
) {
  const dataSourceOptions = Arr.distinct(rangeLineItems.map((it) => it[field]));

  const grouping = Arr.groupBy(rangeLineItems, (it) => it[axis]);

  const axisOptions = [...grouping.keys()].sort((a, b) => a.localeCompare(b));

  const data = axisOptions.map((axisItem) => ({
    billingMonth: axisItem,
    ...Object.fromEntries(
      dataSourceOptions.map((key) => {
        const match = grouping.get(axisItem)?.find((it) => it[field] === key) ?? null;
        return [key, match == null ? 0 : match[value]];
      })
    ),
  }));
  const series = dataSourceOptions.map((it) => ({
    valueField: it,
    argumentField: axis,
    type: "spline",
  }));
  return {
    data,
    series,
  };
}
