import { useDataClient } from "../hooks/client.hooks";
import { ProjectionRequestDTO, ProjectionResponseDTO } from "../open-api";
import { putRequest } from "../utils/api.utils";

export const useProjectionsForOrganization = (id: string) =>
  useDataClient<ProjectionResponseDTO[]>(`/api/projections?organizationalUnitId=${id}`, {
    onSuccess: (res) => res.sort((a, b) => a.key.localeCompare(b.key)),
  });

export const usePossibleProjections = () =>
  useDataClient<ProjectionResponseDTO[]>(`/api/projections`, {
    onSuccess: (res) => res.sort((a, b) => a.key.localeCompare(b.key)),
  });

export const putProjection = (projection: ProjectionRequestDTO) => putRequest(`/api/projections`, projection);
