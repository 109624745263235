import { Dialog } from "@material-ui/core";
import { DialogProps } from "../../hooks/dialog.hooks";
import { DataSourceFormEdit } from "./forms/DataSourceFormEdit";
import { DataSourceResponseDTO } from "../../clients/collector.client";

interface DataSourceDialogEditProps extends DialogProps {
  dataSource: DataSourceResponseDTO;
  onSuccess?: () => void;
  onError?: () => void;
}

export const DataSourceDialogEdit = ({ dataSource, open, onClose, onSuccess, onError }: DataSourceDialogEditProps) => {
  return (
    <>
      <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
        <DataSourceFormEdit onSuccess={onSuccess} onError={onError} onClose={onClose} dataSource={dataSource} />
      </Dialog>
    </>
  );
};
