/* eslint-disable no-redeclare */
import { Model } from "../model/Model";
import { z } from "zod";
import { StoredDashboard } from "../pages/newdash/model/StoredDashboard";
import { API } from "./context.service";
import { getLocalStorageValue, setLocalStorageValue } from "../utils/localStorage.utils";

export interface DashboardService {
  getAll(): Promise<StoredDashboard[]>;
  update(dashboard: StoredDashboard): Promise<void>;
  delete(id: StoredDashboard["id"]): Promise<void>;
  share(id: StoredDashboard["id"]): Promise<void>;
  unshare(id: StoredDashboard["id"]): Promise<void>;
}

export class LiveDashboardService implements DashboardService {
  constructor(private api: API) {}

  getAll = async (): Promise<StoredDashboard[]> => {
    const dashboards = await this.api.config.list({ type: "dashboard" });
    return z.array(StoredDashboard).parse(dashboards.map((it) => ({ ...it.config, shared: it.shared })));
  };

  update = async (dashboard: StoredDashboard): Promise<void> => {
    const parsed = StoredDashboard.parse(dashboard);
    await this.api.config.put({ id: dashboard.id, type: "dashboard", createConfigRequestDTO: { config: parsed } });
  };

  delete = (id: StoredDashboard["id"]): Promise<void> => {
    return this.api.config.delete2({ type: "dashboard", id });
  };

  share = (id: StoredDashboard["id"]): Promise<void> => {
    return this.api.config.shareConfig({ type: "dashboard", id });
  };
  unshare = (id: StoredDashboard["id"]): Promise<void> => {
    return this.api.config.unshareConfig({ type: "dashboard", id });
  };
}

export class LocalstorageDashboardService implements DashboardService {
  getAll = async (): Promise<StoredDashboard[]> => {
    const stored = getLocalStorageValue("c-facts-dashboards-v2");
    if (stored == null) return [];
    const parsed = z.array(StoredDashboard).safeParse(stored);
    if (!parsed.success) {
      setLocalStorageValue("c-facts-dashboards-v2", []);
      return [];
    }
    return parsed.data;
  };

  create = async (authId: string, dashboard: StoredDashboard): Promise<void> => {
    await this.revive((state) => Model.create(state, dashboard));
  };

  update = async (dashboard: StoredDashboard): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const parsed = StoredDashboard.parse(dashboard);
    await this.revive((state) => {
      const stored = Model.get(state, parsed.id);
      if (stored != null) {
        return Model.update(state, parsed);
      } else {
        return Model.create(state, parsed);
      }
    });
  };

  delete = async (id: StoredDashboard["id"]): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await this.revive((state) => Model.remove(state, id));
  };

  share = async (id: StoredDashboard["id"]) => Promise.resolve();
  unshare = async (id: StoredDashboard["id"]) => Promise.resolve();

  private revive = async (reviver: (state: StoredDashboard[]) => StoredDashboard[]) => {
    const stored = await this.getAll();
    setLocalStorageValue("c-facts-dashboards-v2", reviver(stored));
  };
}
