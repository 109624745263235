import Dialog from "@material-ui/core/Dialog";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { UserDTO } from "../../../open-api";
import { UserEditDialogForm } from "./UserEditDialogForm";

interface UserEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  user: UserDTO;
}

export function UserEditDialog({ user, open, onClose, onSuccess }: UserEditDialogProps) {
  return (
    <Dialog maxWidth={"md"} open={open} onClose={onClose} disableBackdropClick>
      <DialogTitleCloseable onClose={onClose}>Edit user {user.email}</DialogTitleCloseable>
      <UserEditDialogForm
        user={user}
        onClose={onClose}
        onSuccess={() => {
          onClose();
          onSuccess();
        }}
      />
    </Dialog>
  );
}
