import { createContext, useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm, UseFormReturn } from "react-hook-form";
import { useApiCall } from "../../../hooks/client.hooks";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { messageError, messageSuccess } from "../../../services/message.service";
import Grid from "@material-ui/core/Grid";
import { FormTextField } from "../../../components/form/FormTextField";
import {
  CSVType,
  csvUpload,
  InvoicesCSVCollectorRequest,
  ISO_8601_DATE_FORMAT,
} from "../../../clients/collector.client";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import { GenericIcon, Icon } from "../../../theme/Icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { CsvConfiguration } from "./CsvConfiguration";
import { CsvConfigFieldMapper } from "./CsvConfigFieldMapper";
import { CsvInvoiceInformation } from "./CsvInvoiceInformation";
import { useColumnNames, useFile, useUploadConfig } from "./CsvUpload";
import { useContextOrThrow } from "../../../hooks/context.hooks";
import { UploadCsvFieldRequestDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridMarginTop: {
      marginTop: theme.spacing(2),
    },
    gridMarginBottom: {
      marginBottom: theme.spacing(2),
    },
    fullWidth: { width: "100%" },
  })
);

interface CsvUploadFormProps {
  collectorId: string;
  secret: string;
}

export function CsvUploadForm({ collectorId, secret }: CsvUploadFormProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [columnNames] = useColumnNames();
  const [file] = useFile();
  const csvUploadConfig = useUploadConfig();
  const [upload] = useApiCall(csvUpload);

  const formMethods = useForm<InvoicesCSVCollectorRequest>({
    defaultValues: {
      collectorId: collectorId,
      secret: secret,
      invoice: { config: csvUploadConfig },
    },
    mode: "onTouched",
  });

  const { register, handleSubmit, formState } = formMethods;
  const configFields = csvUploadConfig ? csvUploadConfig.fields : [];

  const onSubmitError = (errors: any, e: any) => {
    console.error(`Uploading CSV Failed: ${e.message}`, errors, e);
  };

  const uploadCsv = (values: InvoicesCSVCollectorRequest) => {
    // TODO: add a required form hook for file dropzone area
    if (!file) {
      return;
    }

    // map the objects to an array of field configs
    values.invoice.config.fields = Object.entries(values.invoice.config.fields).map(([key, value]) => {
      const fieldRequest = { ...value, key: key } as UploadCsvFieldRequestDTO;

      // format the date if a default is given for a date type field
      if (
        !!fieldRequest.default &&
        configFields.find((field) => field.key === fieldRequest.key)?.type === CSVType.DATE
      ) {
        fieldRequest.default = (fieldRequest.default as unknown as DateTime).toFormat(ISO_8601_DATE_FORMAT);
      }

      return fieldRequest;
    });

    // format the dates
    // @ts-ignore because startDate thinks it's a Date, but it's a string
    values.invoice.startDate = (values.invoice.startDate as unknown as DateTime).toFormat(ISO_8601_DATE_FORMAT);
    // @ts-ignore because startDate thinks it's a Date, but it's a string
    values.invoice.endDate = (values.invoice.endDate as unknown as DateTime).toFormat(ISO_8601_DATE_FORMAT);

    file &&
      upload({
        ...values,
        collectorId: collectorId,
        file: file,
      })
        .then((e) => {
          messageSuccess(
            "Successfully uploaded a CSV invoice. It can take a short while until it is processed. See Invoice Runs."
          );
        })
        .catch((e) => {
          messageError(e.message);
        });
  };

  return (
    <CsvUploadFormMethodContext.Provider value={formMethods}>
      <form onSubmit={handleSubmit(uploadCsv, onSubmitError)}>
        <Grid container spacing={2} classes={{ root: classes.gridMarginBottom }}>
          <FormTitleField text={"Invoice information"} />
          <CsvInvoiceInformation />

          <Grid container item spacing={2} xs={12} classes={{ root: classes.gridMarginTop }}>
            <IconButton onClick={() => setOpen(!open)}>
              <Icon icon={open ? GenericIcon.LIST_COLLAPSE : GenericIcon.LIST_EXPAND} />
            </IconButton>
            <Typography variant="h6">Configuration (optional)</Typography>
          </Grid>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <CsvConfiguration />
          </Collapse>

          {columnNames && columnNames.length > 0 && <CsvConfigFieldMapper />}
        </Grid>

        <FormTextField error={formState.errors.secret} type="hidden" name="secret" inputRef={register} value={secret} />

        <DialogActions>
          <ButtonConfirm>Upload</ButtonConfirm>
        </DialogActions>
      </form>
    </CsvUploadFormMethodContext.Provider>
  );
}

export const DELIMITER_ID = "invoice.config.delimiter";
export const DATE_FORMAT_ID = "invoice.config.dateFormat";
export const NUMBER_FORMAT_ID = "invoice.config.numberFormat";
export const HAS_HEADER_ID = "invoice.config.hasHeader";

export const CsvUploadFormMethodContext = createContext<
  UseFormReturn<InvoicesCSVCollectorRequest & { [key: string]: any }> | undefined
>(undefined);
export const useCsvUploadFormMethods = () => useContextOrThrow(CsvUploadFormMethodContext);
