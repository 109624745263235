// TODO: Move away from date-fns in favor of luxon
import { formatDistanceToNow, parseISO } from "date-fns";
import { enUS } from "@material-ui/core/locale";
import { asDate, asDateTime, asDisplayYearMonth, parseDate } from "./functional/date.utils";

export const numberFormatLocales = "nl-nl";
export const materialUiLocales = enUS;

export const LOCALE = {
  NL: "nl-NL",
  US: "en-US",
};

export const NL_NUMBER_FORMAT_REGEX = "^\\d{1,3}(?:\\.\\d{3})*(?:,\\d+)?$|^\\d+(?:,)?\\d+$";
export const US_NUMBER_FORMAT_REGEX = "^\\d{1,3}(?:\\,\\d{3})*(?:.\\d+)?$|^\\d+(?:.)?\\d+$";

// TODO: i18n key/value mapping
export const MESSAGE = {
  ERROR: {
    DELETE_ORGANIZATIONAL_UNIT:
      "Could not delete organizational unit. Make sure the unit does not have child units, a shared C-View or associated budgets.",
  },
};

/**
 * @Deprecated
 */
export const formattedDate = (date?: string) => {
  if (date) {
    return asDate(parseDate(date));
  }

  return null;
};

export const getCurrencySymbol = (currency: string) => {
  const symbol = new Intl.NumberFormat("en", { style: "currency", currency })
    .formatToParts(0)
    .find((x) => x.type === "currency");
  return symbol && symbol.value;
};

export function formattedCurrency(value: number = 0, currency: string = "", digits: number = 2) {
  if (currency === "") {
    return new Intl.NumberFormat(numberFormatLocales, {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(value);
  }

  try {
    return new Intl.NumberFormat(numberFormatLocales, {
      style: "currency",
      currency: currency || "EUR",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(value);
  } catch (err) {
    console.warn(err);
    return (
      new Intl.NumberFormat(numberFormatLocales, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(value) + ` ${currency}`
    );
  }
}

export const roundToTwoDecimals = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const roundToFourDecimals = (number: number) => {
  return Math.round((number + Number.EPSILON) * 10000) / 10000;
};

export const formattedDisplayNumber = (
  value: number,
  minFraction: number = 1,
  maxFraction: number = 2,
  locale?: string
) =>
  Intl.NumberFormat(locale || numberFormatLocales, {
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  }).format(value);

export const formattedPercentage = (value: number, minFraction: number = 1, maxFraction: number = 2) =>
  formattedDisplayNumber(value, minFraction, maxFraction) + `%`;

export function formattedDateTimeUntilNow(isoDateString: string | undefined, defaultString: string = "-") {
  if (!isoDateString) {
    return defaultString;
  }
  const date = parseISO(isoDateString);

  return formatDistanceToNow(date, { addSuffix: true });
}

export function formattedDateTime(isoDateString: string | undefined, defaultString: string = "-") {
  if (!isoDateString) {
    return defaultString;
  }
  const date = parseDate(isoDateString);

  return asDateTime(date);
}

export function formattedMonth(isoDateString?: string, defaultString = "-") {
  if (!isoDateString) {
    return defaultString;
  }
  const date = parseDate(isoDateString);

  return asDisplayYearMonth(date);
}

export function ucFirst(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}
