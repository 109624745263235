import { SubscriptionType, useSubscriptionType, useWorkspaceRole, WorkspaceRole } from "../hooks/auth.hooks";
import { ReactNode } from "react";

interface AuthorizationProps {
  hasRole?: Array<WorkspaceRole>;
  hasType?: SubscriptionType;
  children?: ReactNode;
}

export const ConditionalRender = ({ hasRole, hasType, children }: AuthorizationProps) => {
  const role = useWorkspaceRole();
  const type = useSubscriptionType();

  return hasRole?.includes(role) || hasType === type ? <>{children}</> : <></>;
};
