import { UserList } from "./UserList";
import { Grid } from "@material-ui/core";
import { TabsStyled } from "../../components/tabs/TabStyled";
import { Route, useLocation } from "react-router-dom";
import { CViewList } from "./CViewList";
import { useRedirect } from "../../hooks/router.hooks";
import { ForceMFA } from "./ForceMFA";
import { AzureSSOConfig } from "./AzureSSOConfig";

enum tabKeys {
  USERS,
  UNMANAGED_CVIEWS,
  MANAGED_CVIEWS,
  MFA,
  AZURE_SSO,
}

const tabs = [
  {
    key: tabKeys.USERS,
    label: "Users",
    url: "/user-management/users",
  },
  {
    key: tabKeys.UNMANAGED_CVIEWS,
    label: "Unmanaged C-Views",
    url: "/user-management/unmanaged-c-views",
  },
  {
    key: tabKeys.MANAGED_CVIEWS,
    label: "Managed C-Views",
    url: "/user-management/managed-c-views",
  },
  {
    key: tabKeys.MFA,
    label: "2FA",
    url: "/user-management/mfa",
  },
  {
    key: tabKeys.AZURE_SSO,
    label: "Azure SSO",
    url: "/user-management/azure-sso",
  },
];

export function UserManagementPage() {
  const redirect = useRedirect();
  const location = useLocation();

  if (location.pathname === "/user-management") {
    redirect(tabs[0].url);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TabsStyled tabs={tabs} />
        <Route path={tabs[0].url}>
          <UserList />
        </Route>
        <Route path={tabs[1].url}>
          <CViewList managed={false} />
        </Route>
        <Route path={tabs[2].url}>
          <CViewList managed={true} />
        </Route>
        <Route path={tabs[3].url}>
          <ForceMFA />
        </Route>
        <Route path={tabs[4].url}>
          <AzureSSOConfig />
        </Route>
      </Grid>
    </Grid>
  );
}
