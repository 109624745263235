import { CSSProperties } from "react";
import { ReactComponent as ErrorImg } from "../assets/img/5xx-error.svg";

const colors = {
  CF_GREEN: "#cddc39",
  CF_GREY: "#303030",
  CF_LIGHT_GREY: "#424242",
  CF_WHITE: "#FFFFFF",
};

const containerStyle: CSSProperties = {
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  backgroundColor: colors.CF_GREY,
  color: colors.CF_WHITE,
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  width: "100%",
};

const paperStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "24px",
  marginTop: "48px",
  marginBottom: "auto",
  justifyContent: "center",
  backgroundColor: colors.CF_LIGHT_GREY,
  borderRadius: "4px",
  boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
};

export function ErrorPage({ errorId }: { errorId: string }) {
  return (
    <div style={containerStyle}>
      <div style={paperStyle}>
        <ErrorImg title={"Error page"} style={{ maxWidth: 200, maxHeight: 200, marginTop: 20 }} />
        <h1>Something went wrong!</h1>
        <p>Our developers have been notified, and are working to make sure this does not happen again.</p>

        <a href={"/dashboard"} style={{ color: colors.CF_GREEN }}>
          Take me back
        </a>
      </div>
    </div>
  );
}
