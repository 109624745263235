import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { resetPassword } from "../../../clients/user.client";
import { messageError } from "../../../services/message.service";
import { useRedirect } from "../../../hooks/router.hooks";
import { ReactNode } from "react";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { ControlledTextField } from "../../../components/form/FormComponents";

interface ResetComponentProps {
  resetCode: string;
  buttonText?: string;
  children?: ReactNode;
}

export function ResetComponent({ resetCode, buttonText = "reset", children }: ResetComponentProps) {
  const redirect = useRedirect();

  const {
    control,
    handleSubmit,
  } = useCFForm({
    schema: z.object({
      password: z
        .string()
        .regex(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,255})/, "Password requirements: At least 8 characters, a capital and a number."),
      repeatPassword: z.string(),
    }).refine((data) => data.password === data.repeatPassword, {
      message: "Passwords don't match",
      path: ["repeatPassword"],
    }),
  });

  const handleReset = (password: string) => {
    resetPassword(resetCode, password)
      .then(() => redirect("/login", { state: "welcome" }))
      .catch((msg) => messageError(`Failed to reset: ${msg}`));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(({ password }) => {
          handleReset(password);
        })}
      >
        <Grid container>
          {children}
          <Grid item xs={12}>
            <ControlledTextField type="password" control={control} name={"password"} label={"Password"} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField type="password" control={control} name={"repeatPassword"} label={"Confirm password"} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" autoFocus>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
