import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { DataSourcesList } from "./DataSourcesList";
import { DataSourceAdd } from "./DataSourceAdd";
import { DataSourceResponseDTO, useCollectors } from "../../clients/collector.client";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { useRedirect } from "../../hooks/router.hooks";
import { Invitations } from "../dashboard/invitations/Invitations";
import { useServices } from "../../services/context.service";
import { useBootstrap } from "../../clients/bootstrap.client";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
}));

export function DataSourcesPage() {
  const classes = useStyles();
  const [dataSources] = useCollectors();
  const redirect = useRedirect();

  const portalService = useServices().portals;
  const [bootstrap] = useBootstrap();
  const [canAddDataSource, setCanAddDataSource] = useState(false);

  useEffect(() => {
    const getCanAddDataSource = async () => {
      if (bootstrap?.workspace?.workspaceId) {
        const res = await portalService.canWorkspaceAddDataSource(bootstrap?.workspace?.workspaceId);
        //open-api boolean limitation
        setCanAddDataSource(res === "true");
      }
    };
    getCanAddDataSource();
  }, [bootstrap, portalService]);

  if (!dataSources) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={5} />
      </OneCardGrid>
    );
  }

  const navigateToDataSource = (ds?: DataSourceResponseDTO) => ds && redirect(`/datasources/${ds.id}`);

  return (
    <>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <Invitations />
          <DataSourcesList dataSources={dataSources} onClick={navigateToDataSource} />
        </Grid>
      </Grid>
      {canAddDataSource && <DataSourceAdd onDataSourceAdded={navigateToDataSource} />}
    </>
  );
}
