import { CFactsIcon, CFactsIconColor, Icon } from "../../theme/Icon";
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from "@material-ui/core";
import { CFactsAction } from "../buttons/CFactsAction";
import { ReactNode } from "react";

export interface DenseListItemProps {
  icon?: CFactsIcon;
  iconTooltip?: string;
  iconColor?: CFactsIconColor;
  primary: ReactNode;
  secondary?: ReactNode;
  action?: CFactsAction | false;
}

export const DenseListItem = ({ item }: { item: DenseListItemProps }) => (
  <ListItem>
    {item.icon && (
      <Tooltip title={item.iconTooltip ?? ""}>
        <ListItemIcon>
          <Icon icon={item.icon} color={item.iconColor} size={"small"} />
        </ListItemIcon>
      </Tooltip>
    )}
    <ListItemText primary={item.primary} secondary={item.secondary} />
    {item.action && (
      <ListItemSecondaryAction>
        <Tooltip title={item.action.tooltip}>
          <IconButton
            disabled={item.action.disabled}
            edge="end"
            aria-label={item.action.tooltip}
            onClick={item.action.onClick}
          >
            <Icon icon={item.action.icon} />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    )}
  </ListItem>
);
