import { useState } from "react";
import MaterialTable, { Column } from "@material-table/core";
import { tableIcons } from "../../theme/TableIcons";
import { OneCardGrid } from "../../components/grid/OneCardGrid";
import { DataTableSkeleton } from "../../components/skeletons/DataTableChartSkeleton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import UpdateIcon from "@material-ui/icons/Update";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import { messageError, messageSuccess } from "../../services/message.service";
import { PortalResponseDTO } from "../../open-api";
import { formattedDateTimeUntilNow, ucFirst } from "../../utils/localization.utils";
import { useServices } from "../../services/context.service";
import { useApiCall, useDataClientPromise } from "../../hooks/client.hooks";
import { useRedirect } from "../../hooks/router.hooks";
import { GenericIcon, Icon } from "../../theme/Icon";
import { Link, Tooltip } from "@material-ui/core";
import { AddCView } from "./AddCView";
import { useCanEdit } from "../../hooks/auth.hooks";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { CloudOff } from "@material-ui/icons";

interface CViewList {
  managed: boolean;
}

export function CViewList({ managed }: CViewList) {
  const services = useServices();
  const redirect = useRedirect();
  const canEdit = useCanEdit();

  const [portals, refreshPortals, isLoading] = useDataClientPromise(
    managed ? services.managedPortals.listManaged : services.portals.list,
    []
  );
  const [deletePortal] = useApiCall(managed ? services.managedPortals.delete : services.portals.delete);
  const [updateCanAddDataSource] = useApiCall(services.portals.updateCanAddDataSource);

  const [selectedForDelete, setSelectedForDelete] = useState(null as PortalResponseDTO | null);
  const [selectedForReset, setSelectedForReset] = useState(null as PortalResponseDTO | null);
  const [selectedForCanadddatasource, setSelectedForCanadddatasource] = useState(null as PortalResponseDTO | null);

  const handleClickDelete = (ds: PortalResponseDTO) => setSelectedForDelete(ds);
  const handleClickReset = (ds: PortalResponseDTO) => setSelectedForReset(ds);
  const handleClickCanadddatasource = (ds: PortalResponseDTO) => setSelectedForCanadddatasource(ds);

  const onCloseDelete = () => {
    setSelectedForDelete(null);
  };

  const onCloseReset = () => {
    setSelectedForReset(null);
  };

  const onCloseCanadddatasource = () => {
    setSelectedForCanadddatasource(null);
  };

  const handleReset = (ds: PortalResponseDTO) =>
    services.mfa
      .resetMFA(ds.email)
      .then((_) => {
        messageSuccess("Password and TOTP token reset");
      })
      .catch((e) => messageError(e.message));

  const handleDelete = (ds: PortalResponseDTO) =>
    deletePortal(ds.id)
      .then((_) => {
        messageSuccess("C-View access disabled");
        refreshPortals();
      })
      .catch((e) => messageError(e.message));

  const handleCanadddatasource = (ds: PortalResponseDTO) =>
    updateCanAddDataSource(ds.id, !ds.canAddDataSource)
      .then((_) => {
        messageSuccess("Successfully updated the possibility of adding data sources");
        refreshPortals();
      })
      .catch((e) => messageError(e.message));

  if (!portals || isLoading) {
    return (
      <OneCardGrid>
        <DataTableSkeleton rowCount={10} />
      </OneCardGrid>
    );
  }

  const columns: Column<PortalResponseDTO>[] = [
    {
      title: "Status",
      render: (row) => (
        <Tooltip
          title={
            row.status === "PENDING"
              ? `${row.email} is invited, but has not yet accepted`
              : `${row.email} has access to this data`
          }
        >
          <Icon
            icon={row.status === "PENDING" ? GenericIcon.PENDING : GenericIcon.ACCEPTED}
            color={row.status === "PENDING" ? "disabled" : "primary"}
          />
        </Tooltip>
      ),
      width: 40,
    },
    {
      title: "Organization",
      field: "organizationName",
    },
    {
      title: "Name",
      field: "name",
      width: 200,
    },
    {
      title: "Email",
      field: "email",
      render: (row) => <Link href={`mailto:${row.email}`}>{row.email}</Link>,
    },
    {
      title: "Last login",
      field: "lastActive",
      render: (row) => ucFirst(formattedDateTimeUntilNow(row.lastActive, "Never")),
    },
  ];

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        title={"C-Views"}
        columns={columns}
        data={portals}
        options={{ draggable: false, pageSize: 10, actionsColumnIndex: -1 }}
        onRowClick={(event, row) => {
          if (row) {
            redirect(`/organizational-unit/unit/${row.organizationId}`);
          }
        }}
        actions={[
          (rowData) => ({
            icon: () => <DeleteOutlineIcon />,
            tooltip: "Remove access to this c-view.",
            onClick: (event, rowData) => handleClickDelete(Array.isArray(rowData) ? rowData[0] : rowData || null),
          }),
          (rowData) => ({
            icon: () => <UpdateIcon />,
            tooltip: rowData.mfaEnabled ? "Reset the password and TOTP." : "TOTP is not set for this user.",
            disabled: !rowData.mfaEnabled,
            onClick: (event, rowData) => handleClickReset(Array.isArray(rowData) ? rowData[0] : rowData || null),
          }),
          (rowData) => ({
            icon: () => (rowData.canAddDataSource ? <CloudDownloadIcon /> : <CloudOff />),
            tooltip: rowData.canAddDataSource
              ? "This C-View can add data sources"
              : !rowData.lastActive && !managed
              ? "This C-view is not yet activated"
              : "This C-View can not add data sources",
            disabled: managed || !rowData.lastActive,
            onClick: (event, rowData) =>
              handleClickCanadddatasource(Array.isArray(rowData) ? rowData[0] : rowData || null),
          }),
        ]}
      />
      {selectedForDelete && (
        <ConfirmDialog
          open={!!selectedForDelete}
          title={"Delete C-View"}
          content={`Are you sure you want to permanently remove access to this C-View for user: ${selectedForDelete.name} and all users within the associated workspace?`}
          onClose={() => onCloseDelete()}
          onConfirm={() => handleDelete(selectedForDelete)}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
      {selectedForReset && (
        <ConfirmDialog
          open={!!selectedForReset}
          title={"Reset the password and the TOTP"}
          content={`You are about to reset the password and the TOTP token for user: ${selectedForReset.name}
                    The user will be notified with an email to reset the password and token. Do you wish to proceed?`}
          onClose={() => onCloseReset()}
          onConfirm={() => handleReset(selectedForReset)}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
      {selectedForCanadddatasource && (
        <ConfirmDialog
          open={!!selectedForCanadddatasource}
          title={"The C-View can add data sources"}
          content={`You are about to change the possibility to add data sources for user ${selectedForCanadddatasource.name}
                        Do you wish to proceed?`}
          onClose={() => onCloseCanadddatasource()}
          onConfirm={() => handleCanadddatasource(selectedForCanadddatasource)}
        >
          <DeleteOutlineIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
      {canEdit && <AddCView onSuccess={() => refreshPortals()} />}
    </>
  );
}
