import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Money } from "../Money";
import { ConvertedMoneyDTO } from "graphql-simple-bindings/money";

interface TooltipTemplateProps {
  argumentText: ReactNode;
  value: ConvertedMoneyDTO;
  title?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "5px",
    fontSize: "16px",
    fontWeight: "bold",
    paddingBottom: "5px",
    borderBottom: "1px solid #c5c5c5",
  },
  value: {
    fontWeight: "normal",
    opacity: 0.6,
    display: "inline-block",
    lineHeight: 1.5,
    paddingRight: "10px",
  },
}));

export function ValueTooltip({ argumentText, value, title = "Value", ...props }: TooltipTemplateProps) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.title}>{argumentText}</div>
      <div className={classes.value}>{title}</div>
      <div>
        <Money money={value} />
      </div>
      {/*<div>{formattedCurrency(value, props.point.data.costMoney.requested.currency, 0)}</div>*/}
    </div>
  );
}
