import { range } from "./range.utils";

export const distinct = <T>(array: T[]) => Array.from(new Set(array));

export const distinctJson = <T>(array: T[]) =>
  Array.from(new Set(array.map((it) => JSON.stringify(it)))).map((it) => JSON.parse(it) as T);

export const distinctBy = <T>(array: T[], equality: (a: T, b: T) => boolean) => {
  const newArray: T[] = [];
  for (const item of array) {
    if (newArray.find((it) => equality(it, item)) == null) {
      newArray.push(item);
    }
  }
  return newArray;
};

export const equals = (a: unknown[], b: unknown[]) =>
  a.length === b.length && range(a.length).every((i) => a[i] === b[i]);

export const contains = <T>(array: ReadonlyArray<T>, search: unknown) => array.includes(search as T);

export * as Arr from "./array.utils";

export const groupBy = <T>(array: T[], keySelector: (it: T) => string): Map<string, T[]> => {
  const map = new Map<string, T[]>();

  for (const it of array) {
    const key = keySelector(it);
    let arr = map.get(key);
    if (arr == null) {
      arr = [it];
      map.set(key, arr);
    } else {
      map.set(key, [...arr, it]);
    }
  }
  return map;
};

export const filterUndefined = <T>(item: T | undefined): item is T => !!item;
