import { useLocation } from "react-router-dom";
import { useEnabledFeatures } from "./auth.hooks";
import { FEATURES } from "../app.config";

export const useFeatureToggle = (feature: keyof typeof FEATURES): boolean => {
  const location = useLocation();
  const serverFeatures = useEnabledFeatures();
  const enabledFeatures = new URLSearchParams(location.search).get("ft")?.split(",") ?? [];
  const disabledFeatures = new URLSearchParams(location.search).get("ft-off")?.split(",") ?? [];
  return (serverFeatures.includes(feature) || enabledFeatures.includes(feature)) && !disabledFeatures.includes(feature);
};
