export * as GrossTypeText from "./gross-type.text";

export const profit = {
  title: "Profit",
  description: "calculating the sales price by adding a % to the selected cost/base price",
};
export const margin = {
  title: "Margin",
  description: "calculating the sales price by determining the required % of margin",
};
export const markup = {
  title: "Markup",
  description: "calculating the cross charging price by adding a % to the selected cost/base price",
};
export const retailPrice = {
  title: "Retail price",
  description: "the list price used by the applicable cloud provider",
};
export const invoicePrice = {
  title: "Cost",
  description:
    "the price stated on the invoice of the cloud provider. For indirect connections this is estimated using the retail price",
};
export const incentives = {
  title: "Incentives",
  description:
    "a discount given by the cloud provider as an incentive for sales. Incentives are often not applied to the invoice",
};
export const savings = {
  title: "Saving",
  description:
    "calculated as the difference between the retail consumption price minus the discounted consumption commitment price",
};
export const priceAfterDiscount = {
  title: "Cost after discount",
  description: "the invoice price minus incentives.",
};

export const sales = {
  title: "Sales",
  description: "price for customers based on configured price adjustments",
};

export const crossCharging = {
  title: "Cross-charging",
  description: "costs after price adjustments are applied",
};
