import { Grid, Tooltip, Typography } from "@material-ui/core";
import { deleteCustomerPortal, deleteManagedCustomerPortal } from "../../../../clients/organizationalunit.client";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useDialog } from "../../../../hooks/dialog.hooks";
import { CustomerPortalDialogCreate } from "../../dialogs/CustomerPortalDialogCreate";
import { ConfirmDialog } from "../../../../components/dialogs/ConfirmDialog";
import { DenseList } from "../../../../components/list/DenseList";
import { AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { CFactsIcon, GenericIcon } from "../../../../theme/Icon";
import { useCanEdit } from "../../../../hooks/auth.hooks";
import { OrganizationalUnitResponseDTO, PortalResponseDTO } from "../../../../open-api";
import { OrganizationUnitType } from "../../../../mappers/organization.mapper";

interface OrganizationPortalsProps {
  organization: OrganizationalUnitResponseDTO;
  onChange: () => void;
}

export const AccordionPortals = ({ organization, onChange }: OrganizationPortalsProps) => {
  const [createPortal, setCreatePortal, createPortalDialogProps] = useDialog<OrganizationalUnitResponseDTO>();
  const [deletePortal, setDeletePortal, deletePortalDialogProps] = useDialog<PortalResponseDTO>();

  const canAdd =
    useCanEdit() &&
    // TODO: CCC-713: what are we going to do about this?
    // organization.unitType === OrganizationUnitType.ROOT &&
    organization.portals?.filter((it) => !it.associatedThrough).length === 0;

  const content = (
    <>
      <Grid item>
        <DenseList
          action={
            canAdd && {
              icon: GenericIcon.ADD,
              tooltip: "Share the data that belongs to this unit and its children with someone else",
              onClick: () => setCreatePortal(organization),
            }
          }
          empty={"No shared C-View"}
          items={
            organization.portals || organization.managedPortals?.length
              ? [...(organization.portals || []), ...(organization.managedPortals || [])].map((portal) => ({
                  icon: portal.status as GenericIcon.ACCEPTED | GenericIcon.PENDING,
                  iconTooltip:
                    portal.status === GenericIcon.ACCEPTED
                      ? `${portal.email} has access to this data`
                      : `${portal.email} is invited, but has not yet accepted`,
                  iconColor: portal.status === GenericIcon.ACCEPTED ? "primary" : "disabled",
                  primary: (
                    <Tooltip title={portal.associatedThrough ? `Associated through: ${portal.associatedThrough}` : ""}>
                      <Typography color={portal.associatedThrough ? "textSecondary" : "textPrimary"}>
                        {portal.email}
                      </Typography>
                    </Tooltip>
                  ),
                  action: !portal.associatedThrough && {
                    icon: GenericIcon.DELETE,
                    tooltip: "Remove access for customer",
                    onClick: () => setDeletePortal(portal),
                  },
                }))
              : []
          }
        />
      </Grid>
      {createPortal && (
        <CustomerPortalDialogCreate
          {...createPortalDialogProps}
          customer={createPortal}
          onSuccess={() => {
            onChange();
          }}
        />
      )}
      {deletePortal && (
        <ConfirmDialog
          {...deletePortalDialogProps}
          title={"Disable share data"}
          content={`Are you sure you want to disable sharing data for: ${deletePortal.email}?`}
          onConfirm={() => {
            if (deletePortal.type === "DATA") {
              return deleteCustomerPortal(organization.id, deletePortal.id).then(onChange);
            }
            return deleteManagedCustomerPortal(organization.id, deletePortal.id).then(onChange);
          }}
        >
          <AccountBoxIcon style={{ fontSize: 80 }} />
        </ConfirmDialog>
      )}
    </>
  );

  const item = {
    id: "organization-portal",
    summaryMain: "Share C-View",
    summarySub: `${
        (organization?.portals?.length || organization?.managedPortals?.length) ? "C-View shared with: " : "Share a C-View"
    }`,
    action: canAdd
      ? {
          icon: "ADD" as CFactsIcon,
          tooltip: "Share the data that belongs to this unit and its children with someone else",
          onClick: () => setCreatePortal(organization),
        }
      : undefined,
    details: content,
  };

  if (organization.unitType !== OrganizationUnitType.ROOT) return null;

  return <AccordionItemList item={item} />;
};
