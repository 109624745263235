import dxChart from "devextreme/viz/chart";
import { exportFromMarkup } from "devextreme/viz/export";

const prepareMarkup = (chartSvg: dxChart) => {
  // language=HTML
  return `
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" 
             height="${chartSvg.getSize().height}" 
             width="${chartSvg.getSize().width}">${chartSvg.svg()}"
        </svg>`;
};

export function exportImageFromRef(chartSvg: dxChart, type: "png" | "svg") {
  exportFromMarkup(prepareMarkup(chartSvg), {
    width: chartSvg.getSize().width,
    height: chartSvg.getSize().height,
    margin: 0,
    format: type,
  });
}
