import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { usePipelineRunEvents } from "../../../clients/collector.client";
import { useRefresh } from "../../../hooks/refresh.hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RunEvent } from "./components/RunEvent";
import { Box, Grid, Paper, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { groupBy } from "../../../utils/functional/reduce.utils";
import { css } from "@emotion/css";
import { PipelineRunEvent } from "../../../open-api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list: {
    height: 230,
    overflow: "auto",
  },
  text: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

interface PipelineDetailProps {
  collectorId: string;
  pipelineId: string;
  refresh?: boolean;
  includeDebug?: boolean;
}

export function PipelineDetail({ collectorId, pipelineId, refresh = true, includeDebug = false }: PipelineDetailProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [events, refreshEvents, isLoading] = usePipelineRunEvents(collectorId, pipelineId);
  const [groupedEvents, setGroupedEvents] = useState<PipelineRunEvent[][]>([]);

  const [refreshTimer] = useRefresh(refresh, refreshEvents);

  useEffect(() => {
    const safeEvents = events ?? [];
    const grouped = groupBy(safeEvents, (it) => it.stepId);

    setGroupedEvents(
      Object.keys(grouped)
        .map((key) => grouped[key])
        .sort((a, b) => b[b.length - 1].timestamp.localeCompare(a[a.length - 1].timestamp))
    );
  }, [events, includeDebug]);
  const errors = (events ?? []).filter((it) => it.type === "INFO" && it.messageType === "ERROR");

  return (
    <div>
      {errors.length > 0 && (
        <Grid>
          <Box margin={theme.spacing(2, 0)}>
            <Alert variant="outlined" severity="warning">
              {errors[0].name}
            </Alert>
          </Box>
        </Grid>
      )}
      <div className={classes.text}>
        {!refresh ? (
          <Tooltip title={"Refresh"}>
            <IconButton aria-label="refresh run events" size="small" onClick={refreshEvents}>
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        ) : (
          <div>Refreshing {isLoading ? <CircularProgress size={"16px"} /> : `in ${refreshTimer} seconds`}</div>
        )}
      </div>
      {groupedEvents.map((step) => (
        <PipelineStep events={step} includeDebug={includeDebug} />
      ))}
    </div>
  );
}

interface PipelineStepProps {
  events: PipelineRunEvent[];
  includeDebug: boolean;
}

function PipelineStep({ events, includeDebug }: PipelineStepProps) {
  const theme = useTheme();
  const getSeverity = () => {
    if (events.some((it) => it.type === "INFO" && it.messageType === "ERROR")) return "error";
    if (events.some((it) => it.type === "END")) return "success";
    if (events.some((it) => it.type === "START")) return "info";
    return undefined;
  };
  const severity = getSeverity();

  const state =
    severity === "error"
      ? "ERROR"
      : severity === "success"
      ? "FINISHED"
      : severity === "info"
      ? "IN_PROGRESS"
      : undefined;

  if (includeDebug) {
    if (!severity) {
      return (
        <Paper variant="outlined">
          <Box className={css({ display: "flex", padding: theme.spacing(1) })}>
            <CircularProgress />
            {events.map((it) => (
              <RunEvent event={it} key={it.id} includeDebug={includeDebug} />
            ))}
          </Box>
        </Paper>
      );
    }
    return (
      <Alert variant="outlined" severity={severity}>
        {events
          // .filter(it => it.type === "INFO")
          .map((it) => (
            <RunEvent event={it} key={it.id} includeDebug={includeDebug} />
          ))}
      </Alert>
    );
  }
  const toShow =
    events.find((it) => it.type === "INFO" && it.messageType === "ERROR") ??
    events.find((it) => it.type === "INFO") ??
    events.find((it) => it.type === "END");

  const lastActive = events[0].timestamp;
  return <RunEvent event={toShow} state={state} includeDebug={false} timestamp={lastActive} />;
}
