import { Box, Button } from "@material-ui/core";
import { ButtonConfirm } from "../../../../components/form/ButtonConfirm";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { GenericIcon } from "../../../../theme/Icon";
import { SubmitErrorHandler, SubmitHandler } from "react-hook-form/dist/types/form";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useCanEditAdminAndCView } from "../../../../hooks/auth.hooks";
import { BaseSyntheticEvent, ReactElement, ReactNode, useState } from "react";

interface BudgetAlertInlineEditFormProps<T> {
  setEditFn: (id: string, editing: boolean) => void;
  isEditFn: (id: string) => boolean;
  content: ReactElement<any, any>;
  initValue?: string;
  onSave: (form: T) => void;
  onDelete?: () => void;
  children: ReactNode;
  handleSubmit: (
    onValid: SubmitHandler<any>,
    onInvalid?: SubmitErrorHandler<any>
  ) => (e?: BaseSyntheticEvent) => Promise<void>;
  formKey: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    budgetInputField: {
      marginLeft: theme.spacing(2),
    },
    cancelButton: {
      marginLeft: theme.spacing(1),
    },
    form: {
      display: "flex",
      flexGrow: 1,
    },
  })
);

export function BudgetAlertInlineEditForm<T>({
  setEditFn,
  isEditFn,
  formKey,
  content,
  onSave,
  initValue,
  onDelete,
  children,
  handleSubmit,
}: BudgetAlertInlineEditFormProps<T>) {
  const [editState, setEditState] = useState<boolean>(false);
  const classes = useStyles();

  const canEdit = useCanEditAdminAndCView();

  const setEdit = (state: boolean) => {
    if (!canEdit) {
      return;
    }
    if (!!setEditFn) {
      return setEditFn(formKey, state);
    }
    setEditState(state);
  };

  const isEdit = (key: string): boolean => {
    return !!isEditFn ? isEditFn(key) : editState;
  };

  return isEdit(formKey) ? (
    <Box flexGrow={1}>
      <form
        key={formKey}
        className={classes.form}
        onSubmit={handleSubmit((form) => {
          setEdit(false);
          onSave(form);
        })}
      >
        <Box flexGrow={1}>{children}</Box>
        <Box margin={"auto"}>
          <ButtonConfirm classes={{ root: classes.budgetInputField }}>Save</ButtonConfirm>
          <Button classes={{ root: classes.cancelButton }} onClick={() => setEdit(false)}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  ) : (
    <>
      <Box flexGrow={1} onClick={() => setEdit(true)}>
        {content}
      </Box>

      {canEdit && (
        <ActionIconButton
          buttonSize={"medium"}
          size={22}
          action={{
            icon: initValue ? GenericIcon.EDIT : GenericIcon.ADD,
            tooltip: "Add",
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
              setEdit(true);
            },
          }}
        />
      )}

      {canEdit && !!onDelete && (
        <ActionIconButton
          buttonSize={"medium"}
          size={22}
          action={{
            icon: GenericIcon.DELETE,
            tooltip: "Delete",
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            },
          }}
        />
      )}
    </>
  );
}
