import { useState } from "react";

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}

export const useDialog = <T>(): [T | null, (t: T) => void, DialogProps] => {
  const [selected, setSelected] = useState<T | null>(null);

  const dialogProps = {
    open: !!selected,
    onClose: () => setSelected(null),
  };
  return [selected, setSelected, dialogProps];
};

export const useShowDialog = (): [boolean, (t: boolean) => void, DialogProps] => {
  const [show, setShow] = useState(false);
  const dialogProps = {
    open: show,
    onClose: () => setShow(false),
  };
  return [show, setShow, dialogProps];
};
