import "react-sortable-tree/style.css";
import { Card, CardHeader } from "@material-ui/core";
import { mapOrganizationToIconType } from "../../../mappers/organization.mapper";
import { ChangeEvent, ReactNode } from "react";
import { Icon } from "../../../theme/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TreeItem, TreeView } from "@material-ui/lab";
import { FilterChips } from "./FilterChips";
import { flattenAll } from "../../../utils/organization.utils";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  hiddenIconContainer: {
    display: "none",
  },
  group: { marginLeft: 17 },
  iconContainer: {
    width: 0,
  },
  label: { paddingLeft: 0 },
  card: {
    margin: theme.spacing(0.5),
    backgroundColor: "rgb(48,48,48)",
  },
  organizationLogo: {
    fontSize: 30,
  },
}));

export function OrganizationTreeStyled({
  organization,
  onNodeSelect,
  itemAction,
  selected,
}: {
  organization: OrganizationalUnitResponseDTO;
  onNodeSelect?: (nodeId: string) => void;
  itemAction?: (organization: OrganizationalUnitResponseDTO) => ReactNode;
  selected?: string;
}) {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={flattenAll([organization]).map((item) => item.id)}
      defaultEndIcon={<div style={{ width: 24 }} />}
      selected={selected}
      onNodeSelect={(event: ChangeEvent<{}>, nodeIds: string) => onNodeSelect && onNodeSelect(nodeIds)}
    >
      <TreeItemStyled key={organization.id} organization={organization} rootId={organization.id} action={itemAction} />
    </TreeView>
  );
}

function TreeItemStyled({
  organization,
  action,
  rootId,
}: {
  organization: OrganizationalUnitResponseDTO;
  action?: (organization: OrganizationalUnitResponseDTO) => ReactNode;
  rootId: string;
}) {
  const classes = useStyles();

  return (
    <TreeItem
      key={`tree-item-${rootId}`}
      classes={{
        group: classes.group,
        label: classes.label,
        iconContainer: classes.iconContainer,
      }}
      onIconClick={(event) => {
        event.preventDefault();
      }}
      onLabelClick={(event) => {
        event.preventDefault();
      }}
      nodeId={organization.id}
      label={
        <>
          <Card className={classes.card}>
            <CardHeader
              avatar={<Icon icon={mapOrganizationToIconType(organization)} className={classes.organizationLogo} />}
              title={`${organization.name}`}
              subheader={<FilterChips organizationId={organization.id} limit={2} />}
              action={action && action(organization)}
            />
          </Card>
        </>
      }
    >
      {organization.children
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((child) => (
          <TreeItemStyled key={child.id} action={action} organization={child} rootId={rootId} />
        ))}
    </TreeItem>
  );
}
