import { UseFormReturn } from "react-hook-form";
import { Control } from "react-hook-form/dist/types";

// FormPartialUtils.tsx
export interface FormPartial<T> {
  formMethods?: UseFormReturn<T>;
  control: Control<T>;
}

export const transformMFA = (val: string) => {
  const numberStr = val.replaceAll(/\D/g, "").substring(0, 6);
  const cursorPos = numberStr?.length ?? 0;
  const appendLength = 6 - cursorPos;
  const appended = [...Array(appendLength)].map((it) => "_").join("");
  return {
    newVal: numberStr + appended,
    cursorPos: cursorPos,
  };
};
