import { Grid } from "@material-ui/core";
import { LandingPageBarChart } from "./types/LandingPageBarChart";
import { DISPLAY_SEGMENT } from "../../newdash/model/ChartConfig";
import { DIMENSIONS } from "../../../clients/lineitems.client";
import { ConditionalRender } from "../../../components/ConditionalRender";
import { WorkspaceRole } from "../../../hooks/auth.hooks";

export const TopLists = () => {
  return (
    <Grid container spacing={1}>
      <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
        <LandingPageBarChart
          dimension={DIMENSIONS.ORGANIZATION}
          filter={{ dimension: "TYPE", filters: ["CUSTOMER"] }}
          plural="Customers"
          segments={[DISPLAY_SEGMENT.COST]}
        />
        <LandingPageBarChart
          dimension={DIMENSIONS.ORGANIZATION}
          filter={{ dimension: "TYPE", filters: ["RESELLER"] }}
          plural="Resellers"
          segments={[DISPLAY_SEGMENT.COST]}
        />
        <LandingPageBarChart
          dimension={DIMENSIONS.ORGANIZATION}
          filter={{ dimension: "TYPE", filters: ["INTERNAL"] }}
          plural="Internal organizations"
          segments={[DISPLAY_SEGMENT.COST]}
        />
      </ConditionalRender>
      <ConditionalRender hasRole={[WorkspaceRole.MANAGED_C_VIEW]}>
        <LandingPageBarChart
          dimension={DIMENSIONS.ORGANIZATION}
          filter={{ dimension: "TYPE", filters: ["INTERNAL", "RESELLER", "CUSTOMER"] }}
          plural="Internal organizations"
          segments={[DISPLAY_SEGMENT.COST]}
        />
      </ConditionalRender>
      <LandingPageBarChart
        dimension={DIMENSIONS.SERVICE}
        filter={{ dimension: "COST_TYPE", filters: ["LICENSE"] }}
        plural="M365 Licenses"
        segments={[DISPLAY_SEGMENT.COST]}
      />
      <LandingPageBarChart
        dimension={DIMENSIONS.SERVICE}
        filter={{ dimension: "COST_TYPE", filters: ["CLOUD_USAGE"] }}
        plural="Cloud services"
        segments={[DISPLAY_SEGMENT.COST]}
      />
    </Grid>
  );
};
