import { DateTime, Interval } from "luxon";
import { range } from "./functional/range.utils";
import { yearMonth } from "./functional/date.utils";
import { AbsoluteRange } from "../pages/newdash/model/DateRange";

export function yearMonthRange(dateRange: AbsoluteRange) {
  const [from, to] = [dateRange.startDate, dateRange.endDate].map((it) =>
    DateTime.local(...it.split("-").map((it) => parseInt(it)))
  );
  return range(Interval.fromDateTimes(from, to).length("months") + 1).map((i) => yearMonth(from.plus({ month: i })));
}
