import { Redirect, Route } from "react-router-dom";
import { useAuthParams, WorkspaceRole } from "../hooks/auth.hooks";

// @ts-ignore
const NotManagedPortalGuardedRoute = ({ renderComponent, ...rest }) => {
  const isNotManagedPortal = useAuthParams().role !== WorkspaceRole.MANAGED_C_VIEW;
  return <Route {...rest} render={() => (isNotManagedPortal ? renderComponent(rest) : <Redirect to="/" />)} />;
};

export default NotManagedPortalGuardedRoute;
