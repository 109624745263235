import { BatchProjectionCollectorAttributesDTO } from "../../../../open-api";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Button, FormControlLabel, Switch, Typography, useTheme } from "@material-ui/core";
import { css } from "@emotion/css";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useCollectorConfig } from "../../../../clients/collector.client";
import { useDataClientPromise } from "../../../../hooks/client.hooks";
import { useServices } from "../../../../services/context.service";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useBatchCreationType } from "../AutomaticCustomerCreation.provider";
import { mapOrgTypeToDisplay } from "../AutomaticCustomerCreation.utils";

interface EditDatasourceConfigFormProps {
  batchAttributes: BatchProjectionCollectorAttributesDTO;
  onClose: () => void;
  onSave: (collectorId: string, rootTag?: string) => void;
}

export const EditDatasourceConfigForm = ({ batchAttributes, onClose, onSave }: EditDatasourceConfigFormProps) => {
  const theme = useTheme();
  const queryService = useServices().aggregate;
  const [config] = useCollectorConfig(batchAttributes.collector.id);
  const [value, setValue] = useState<{ key: string; label: string } | null>(null);
  const [useFilter, setUseFilter] = useState<boolean>(false);
  const type = useBatchCreationType();

  const [filterOptions, , loading] = useDataClientPromise(
    queryService.options,
    [
      batchAttributes.rootAttribute.id ?? "",
      [
        {
          dimension: "DATASOURCE",
          filters: [batchAttributes.collector.id],
        },
      ],
    ],
    {
      useCacheKey: "options",
      shouldLoad: !!batchAttributes.rootAttribute.uuid,
      onSuccess: (res) =>
        res.options
          .map((it) => ({ key: it.id, label: it.display || it.id }))
          .sort((a, b) => a.label.localeCompare(b.label)),
    }
  );

  useEffect(() => {
    if (batchAttributes.rootFilter?.id && batchAttributes.rootFilter?.display) {
      setValue({
        key: batchAttributes.rootFilter.id,
        label: batchAttributes.rootFilter.display,
      });
      setUseFilter(true);
    }
  }, [batchAttributes.rootFilter]);

  const typeLabel = mapOrgTypeToDisplay(type);
  const rootLabel = config?.hierarchy?.values?.rootHierarchy.displayValue ?? "";
  const detailLabel = config?.hierarchy?.values?.detailHierarchy[0].displayValue ?? "";
  return (
    <>
      <Paper
        variant={"outlined"}
        className={css({
          padding: theme.spacing(2),
        })}
      >
        <Grid container spacing={2} className={css({ maxWidth: "1000px" })}>
          <Grid item xs={12}>
            <Typography variant={"h5"}>
              Edit {typeLabel} configuration for {batchAttributes.collector.name}{" "}
            </Typography>
            <Typography paragraph>
              Edit how the cost center attributes are used to determine how your cloud costs relate to your {typeLabel}s
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography align={"right"}>Cost center attribute 1</Typography>
              <Typography align={"right"}>
                <strong>{rootLabel}</strong>
              </Typography>
            </Grid>
            <Grid item xs={1} container justifyContent={"center"}>
              {!useFilter && <CheckCircleIcon color={"primary"} />}
            </Grid>
            <Grid item xs={8}>
              <Autocomplete<{ key: string; label: string }>
                value={value}
                disabled={!useFilter}
                onChange={(e, val) => setValue(val)}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={useFilter && !value}
                      helperText={
                        useFilter && !value ? `Select for which ${rootLabel} you want to use ${detailLabel}` : undefined
                      }
                      label={useFilter ? `${rootLabel} value filter` : `Use ${rootLabel}`}
                      placeholder={"Search"}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  );
                }}
                getOptionLabel={(it) => it.label}
                loading={loading}
                options={filterOptions ?? []}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography align={"right"}>Cost center attribute 2</Typography>
              <Typography align={"right"}>
                <strong>{detailLabel}</strong>
              </Typography>
            </Grid>
            <Grid item xs={1} container justifyContent={"center"}>
              {useFilter && <CheckCircleIcon color={"primary"} />}
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                label={
                  <>
                    Use <strong>{detailLabel}</strong> instead of <strong>{rootLabel}</strong> to create {typeLabel}s
                  </>
                }
                control={
                  <Switch
                    checked={useFilter}
                    onChange={() => {
                      if (useFilter) {
                        setValue(null);
                      }
                      setUseFilter((it) => !it);
                    }}
                    name={"useFilter"}
                    id={"useFilter"}
                    color={"primary"}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-end"}>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                variant="contained"
                color={"primary"}
                disabled={useFilter && !value}
                onClick={() => onSave(batchAttributes.collector.id, value?.key)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
