import { FastFact } from "../FastFact";
import { Money } from "../../../../components/Money";
import { CFactsIcon } from "../../../../theme/Icon";
import { CFPerArgument } from "../../../newdash/model/Query";
import { useIsManagedCView } from "../../../../hooks/auth.hooks";

interface FastFactCloudRevenueProps {
  items: CFPerArgument[];
}

export function FastFactCloudRevenue({ items }: FastFactCloudRevenueProps) {
  const isManagedCview = useIsManagedCView();
  const infoTextCloudRevenue = isManagedCview
    ? "The amount of costs this month that have been allocated to an organization"
    : "Internal: The amount of costs this month that have been allocated to an internal department\n" +
      "Customer: The amount of costs this month that have been allocated to a customer\n" +
      "Unassigned services: The amount of costs this month that have not been allocated to a cost centre";

  return (
    <>
      {items.map((it) => (
        <FastFact
          key={it.id}
          icon={it.id as CFactsIcon}
          info={infoTextCloudRevenue}
          title={it.display}
          lines={it.values.map((typeItem) => ({
            key: typeItem.id,
            description: typeItem.display,
            value: <Money money={typeItem.value} digits={0} />,
          }))}
        />
      ))}
    </>
  );
}
