import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ReactNode } from "react";

interface StepCompleteProps {
  children?: ReactNode;
}

export function StepComplete({ children }: StepCompleteProps) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>You have completed this step</Typography>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <CheckCircleIcon style={{ fontSize: 120 }} />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}
