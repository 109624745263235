import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import { Theme } from "@material-ui/core";
import { GenericIcon, Icon } from "../../../../theme/Icon";
import { TreeItemStyled, TreeListItemProps } from "./TreeItemStyled";
import { TreeSkeleton } from "../../../../components/skeletons/TreeSkeleton";
import { TAG_DIALOG_FULLSCREEN_HEIGHT_IN_PERCENTAGE } from "../../../../components/dialogs/FullScreenDialog";
import { ChangeEvent } from "react";

const useStyles = (headerHeight: number): (() => Record<string, string>) => {
  return makeStyles((theme: Theme) => ({
    root: {
      maxWidth: 1200,
      flexGrow: 1,
      transition: theme.transitions.create(["opacity", "transform"], {
        duration: theme.transitions.duration.standard,
      }),
    },
    scrollable: {
      maxHeight: `calc(${TAG_DIALOG_FULLSCREEN_HEIGHT_IN_PERCENTAGE}vh - ${theme.spacing(20) + headerHeight}px)`,
      overflowY: "auto",
      overflowX: "hidden",
    },
    disabled: {
      opacity: 0.2,
    },
  }));
};

interface TreeListProps<T> {
  items: TreeListItemProps<T>[];
  expanded?: string[];
  handleToggle?: (event: ChangeEvent<{}>, nodeIds: string[]) => void;
  isLoading: boolean;
  className?: string;
  headerHeight?: number;
}

export default function TreeViewStyled<T>({
  items,
  expanded,
  handleToggle,
  isLoading,
  className,
  headerHeight = 0,
}: TreeListProps<T>) {
  const classes = useStyles(headerHeight)();

  if (items.length === 0 && isLoading) return <TreeSkeleton />;

  return (
    <TreeView
      className={[classes.root, classes.scrollable, isLoading ? classes.disabled : ""].join(" ")}
      defaultCollapseIcon={<Icon icon={GenericIcon.LIST_COLLAPSE} size={22} />}
      defaultExpandIcon={<Icon icon={GenericIcon.LIST_EXPAND} size={22} />}
      defaultEndIcon={null}
      expanded={expanded}
      onNodeToggle={handleToggle}
    >
      {items.map((item) => {
        return <TreeItemStyled overrideRootClass={className} key={item.nodeId} node={item} />;
      })}
    </TreeView>
  );
}
