import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AppStateContext } from "../providers/state.provider";

export type UseState<T> = [T, Dispatch<SetStateAction<T>>];

// TODO: is deprecated, phase out
export const useMergedState = <T>(initialState: T): [T, (p: Partial<T>) => void] => {
  const [state, setState] = useState(initialState);
  const setMergedState = (partialState: Partial<T>) =>
    setState((prevState) => ({
      ...prevState,
      ...partialState,
    }));
  return [state, setMergedState];
};

export const useAppStateContext = () => useContext(AppStateContext);
