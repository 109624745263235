import { useDataClient } from "../hooks/client.hooks";
import { deleteRequest, postRequest, putRequest } from "../utils/api.utils";
import {
  CreateCustomerPortalDTO,
  CreateOrganizationalUnitRequestDTO,
  CreateOrUpdateOrganizationalUnitContactRequestDTO,
  OrganizationalUnitContactDTO,
  OrganizationalUnitResponseDTO,
  UpdateOrganizationalUnitRequestDTO,
} from "../open-api";

export const OrganizationalUnitType = ["CUSTOMER", "INTERNAL", "RESELLER"] as const;
export type OrganizationalUnitType = typeof OrganizationalUnitType[number];
export type OrganizationalUnitUnitType = "ROOT" | "DEPARTMENT" | "PROJECT" | "APPLICATION";

const organizationalUnitBaseUrl = "/api/organizational-unit";

export const useRootOrganizations = () => useDataClient<OrganizationalUnitResponseDTO[]>(organizationalUnitBaseUrl);

export const useOrganization = (id: string) =>
  useDataClient<OrganizationalUnitResponseDTO>(`${organizationalUnitBaseUrl}/${id}`);

export const createOrganizationalUnit = (customer: CreateOrganizationalUnitRequestDTO) =>
  postRequest(organizationalUnitBaseUrl, customer);

export const createCustomerPortal = (customerIds: string, customerPortal: CreateCustomerPortalDTO) =>
  postRequest(`${organizationalUnitBaseUrl}/${customerIds}/portal`, customerPortal);

export const createManagedCustomerPortal = (customerIds: string, customerPortal: CreateCustomerPortalDTO) =>
  postRequest(`${organizationalUnitBaseUrl}/${customerIds}/managed-portal`, customerPortal);

export const deleteCustomerPortal = (customerId: string, portalId: string) =>
  deleteRequest(`${organizationalUnitBaseUrl}/${customerId}/portal/${portalId}`);

export const deleteManagedCustomerPortal = (customerId: string, portalId: string) =>
  deleteRequest(`${organizationalUnitBaseUrl}/${customerId}/managed-portal/${portalId}`);

export const removeCustomerFromAccessibleWorkspace = (customerId: string) =>
  deleteRequest(`${organizationalUnitBaseUrl}/${customerId}/portal`);

export const updateOrganizationalUnit = (customerId: string, customer: UpdateOrganizationalUnitRequestDTO) =>
  putRequest(`${organizationalUnitBaseUrl}/${customerId}`, customer);

export const deleteOrganizationalUnit = (customerId: string) =>
  deleteRequest(`${organizationalUnitBaseUrl}/${customerId}`);

export function useOrganizationContact(id: string) {
  return useDataClient<OrganizationalUnitContactDTO>(`${organizationalUnitBaseUrl}/${id}/contact`);
}

export const updateOrganizationalUnitContact = (
  organizationalUnitId: string,
  organizationalUnitContact: CreateOrUpdateOrganizationalUnitContactRequestDTO
) => putRequest(`${organizationalUnitBaseUrl}/${organizationalUnitId}/contact`, organizationalUnitContact);
