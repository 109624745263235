import { Avatar, Chip, Tooltip, Typography } from "@material-ui/core";
import { useState } from "react";
import { GenericIcon, Icon } from "../../../theme/Icon";
import { useProjectionsForOrganization } from "../../../clients/projections.client";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TAG } from "../../../utils/functional/tag.utils";
import { ucFirst } from "../../../utils/localization.utils";
import { ProjectionResponseDTO } from "../../../open-api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    noMaxWidth: {
      maxWidth: 500,
    },
  })
);

export function FilterChips({ organizationId, limit }: { organizationId: string; limit?: number }) {
  const [projections, ,] = useProjectionsForOrganization(organizationId);

  const indirect = projections ? projections.filter((it) => it.mappedTo[0].id !== organizationId) : [];
  const direct = projections ? projections.filter((it) => it.mappedTo[0].id === organizationId) : [];

  const [showIndirect, setShowIndirect] = useState(false);

  const classes = useStyles();

  const FilterChip = ({
    projection,
    organizationId,
    indirect = false,
  }: {
    projection: ProjectionResponseDTO;
    organizationId: string;
    indirect?: boolean;
  }) => {
    const text = [
      projection.filter.account.displayValue,
      projection.filter.customerTag.value !== TAG.ANY && projection.filter.customerTag.displayValue,
      projection.filter.applicationTag.value !== TAG.ANY && projection.filter.applicationTag.displayValue,
    ]
      .filter((it) => !!it)
      .join(" / ");

    const avatar = (
      <Avatar>
        <Icon icon={GenericIcon.CUSTOMER} />
      </Avatar>
    );

    const direct = projection.mappedTo[0].id === organizationId;
    return (
      <Tooltip title={direct ? "" : `Mapped to: ${projection.mappedTo[0].name}`}>
        <Chip
          avatar={avatar}
          size="small"
          key={`${text}`}
          label={text}
          className={classes.chip}
          variant={indirect ? "outlined" : "default"}
        />
      </Tooltip>
    );
  };

  const listItems = direct.map((projection, idx) => (
    <FilterChip key={`${idx}-${projection.key}`} projection={projection} organizationId={organizationId} />
  ));

  if (!showIndirect && indirect.length > 0) {
    listItems.push(
      <Tooltip key={"direct-tooltip-more"} title={`Show ${indirect.length} indirect`}>
        <Chip
          key={"direct-chip-more"}
          variant={"outlined"}
          onClick={() => setShowIndirect(true)}
          size="small"
          label={`${indirect.length} indirect filter${indirect.length > 1 ? "s" : ""}`}
          className={classes.chip}
        />
      </Tooltip>
    );
  }
  if (showIndirect) {
    indirect.forEach((projection) =>
      listItems.push(<FilterChip projection={projection} organizationId={organizationId} indirect={true} />)
    );

    const label = `Hide ${indirect.length} indirect filter${indirect.length > 1 ? "s" : ""}`;
    listItems.push(
      <Tooltip key={"indirect-tooltip-more"} title={ucFirst(label)}>
        <Chip
          variant={"outlined"}
          onClick={() => setShowIndirect(false)}
          size="small"
          key={"indirect-chip-more"}
          label={label}
          className={classes.chip}
        />
      </Tooltip>
    );
  }

  const pillsLimit = limit ? limit : listItems.length;

  return listItems.length > 0 ? (
    <>
      {listItems?.slice(0, limit)}

      {limit && listItems.length > limit ? (
        <Tooltip classes={{ tooltip: classes.noMaxWidth }} title={listItems?.slice(pillsLimit)}>
          <Chip size="small" key={"chip-more"} label={"..."} className={classes.chip} />
        </Tooltip>
      ) : (
        ""
      )}
    </>
  ) : (
    <Typography>No data assigned</Typography>
  );
}
