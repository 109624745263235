import { Chip, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

interface ChipsProps<T> {
  chips: T[];
  getAvatar: (option: T) => ReactElement;
  getKey: (option: T) => string;
  getValue: (option: T) => string;
  limit?: number;
}

export function Chips<T>({ chips, getKey, getValue, getAvatar, limit }: ChipsProps<T>) {
  const classes = useStyles();

  const pills = chips.map((filter: T) => (
    <Chip
      avatar={getAvatar(filter)}
      className={classes.chip}
      size="small"
      key={`${getKey(filter)}-${getValue(filter)}`}
      label={getValue(filter)}
    />
  ));

  const pillsLimit = limit ? limit : pills.length;

  return (
    <>
      {pills?.slice(0, pillsLimit)}

      {limit && pills.length > limit ? (
        <Tooltip title={pills?.slice(pillsLimit)}>
          <Chip className={classes.chip} size="small" key={"more"} label={"..."} />
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
}
