import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { GeneralPageSkeleton } from "../../components/skeletons/GeneralPageSkeleton";
import {
  LINE_ITEM_DEFAULTS,
  useLineItemDownloadUrl,
  useLineItemsForBillingPeriod,
  useLineItemsForBillingPeriodURL,
} from "../../clients/lineitems.client";
import { MenuOptionsGraph } from "../../components/menu/MenuOptionsGraph";
import { BillingPeriod } from "../../components/BillingPeriod";
import LineItemTable, { DIMENSIONS_DEFAULT, LINE_ITEM_TABLE_DIMENSIONS } from "../../components/billing/LineItemTable";
import { useEffect, useState } from "react";
import { FilterOption, OrganizationSelectFilter } from "./OrganizationSelectFilter";
import { useIsManagedCView, WorkspaceRole } from "../../hooks/auth.hooks";
import { ConditionalRender } from "../../components/ConditionalRender";
import { csvRequest, xlsRequest } from "../../utils/api.utils";
import { useSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";

interface BillingMonthSpecificationProps {
  selectedYearMonth: string;
}

export function BillingMonthSpecification({ selectedYearMonth }: BillingMonthSpecificationProps) {
  const selected = useSelectedBillingPeriods();
  const [filterOrg, setFilterOrg] = useState<FilterOption | null>(null);
  const [lineItems, refreshLineItems] = useLineItemsForBillingPeriod(
    selectedYearMonth,
    filterOrg?.key ?? "",
    selected?.collectorId
  );
  const isManagedCViewUser = useIsManagedCView();

  const exportUrl = useLineItemDownloadUrl(selectedYearMonth, filterOrg?.key ?? "", selected?.collectorId);
  const summaryUrl = useLineItemsForBillingPeriodURL(selectedYearMonth, filterOrg?.key ?? null, selected?.collectorId);

  const csvLabel = `C-Facts line items${filterOrg?.label ? "- " + filterOrg?.label : ""} - Export CSV file`;
  const xlsLabel = `C-Facts line items${filterOrg?.label ? "- " + filterOrg?.label : ""} - Export XLS file`;

  useEffect(() => {
    refreshLineItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOrg]);

  if (!lineItems) return <GeneralPageSkeleton />;

  const unassigned = lineItems.find((it) => it.label === LINE_ITEM_DEFAULTS.TYPE.UNMAPPED);
  const rest = lineItems.filter((it) => it.label !== LINE_ITEM_DEFAULTS.TYPE.UNMAPPED);
  rest.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={8}>
              <Typography variant={"h4"}>
                {`Pro forma information: `}
                <BillingPeriod full billingPeriod={selectedYearMonth} />
              </Typography>
            </Grid>
            <Grid item lg={4} justify={"flex-end"} style={{ display: "flex" }}>
              <OrganizationSelectFilter
                billingPeriod={selectedYearMonth}
                setFilter={setFilterOrg}
                selectedFilter={filterOrg}
              />
              <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
                <MenuOptionsGraph
                  csv={exportUrl}
                  csvLabel={csvLabel}
                  xls={exportUrl}
                  xlsLabel={xlsLabel}
                  additionalItems={[
                    {
                      text: `Line item summary${filterOrg ? ` - ${filterOrg.label}` : ""} - Export CSV file`,
                      onClick: () => csvRequest(summaryUrl),
                    },
                    {
                      text: `Line item summary${filterOrg ? ` - ${filterOrg.label}` : ""} - Export XLS file`,
                      onClick: () => xlsRequest(summaryUrl),
                    },
                  ]}
                />
              </ConditionalRender>
            </Grid>
            {unassigned && (
              <Grid item xs={12}>
                <LineItemTable
                  titleItem={unassigned}
                  lineItems={unassigned.children[0].children}
                  dimensions={LINE_ITEM_TABLE_DIMENSIONS}
                  valueKeys={isManagedCViewUser ? ["cost"] : ["cost", "discount"]}
                />
              </Grid>
            )}
            {rest.map((item) => (
              <Grid item xs={12}>
                <LineItemTable
                  lineItems={[item]}
                  dimensions={DIMENSIONS_DEFAULT}
                  valueKeys={isManagedCViewUser ? ["cost"] : ["cost", "discount", "sales", "crossCharging"]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
