import { DataSourceType } from "../../clients/collector.client";
import { DataSourceIcon, Icon } from "../../theme/Icon";

interface CloudTypeLogoProps {
  type: DataSourceType;
  className: string;
}

export const CloudTypeLogo = ({ type, className }: CloudTypeLogoProps) => {
  return <Icon icon={type as DataSourceIcon} className={className} />;
};
