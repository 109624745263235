import { Card, CardContent, Grid } from "@material-ui/core";
import { ReactNode } from "react";

interface OneCardGridProps {
  children: ReactNode;
}

export const OneCardGrid = ({ children }: OneCardGridProps) => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12} item>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
