export const CURRENCIES = [
  {
    id: "EUR",
  },
  {
    id: "USD",
  },
  {
    id: "GBP",
  },
  {
    id: "CHF",
  },
];

export const ConvertedMoney = {
  ZERO: {
    requested: {
      amount: 0,
      currency: "",
    },
    original: [
      {
        amount: 0,
        currency: "",
      },
    ],
  },
};
