import { AttributeFilterDTO, UpdateAttributeFilterStateRequest } from "../open-api";
import { extendable, Extendable } from "./test.service";
import { API } from "./context.service";

export interface AttributesFilterService {
  getForCollector(collectorId: string): Promise<Array<AttributeFilterDTO>>;
  updateDisplayState(params: UpdateAttributeFilterStateRequest): Promise<void>;
}

export class LiveAttributesFilterService implements AttributesFilterService {
  constructor(private api: API) {}

  getForCollector = (collectorId: string): Promise<Array<AttributeFilterDTO>> => {
    return this.api.attributesFilter.listAttributeFilterState({ collectorId });
  };

  updateDisplayState = (params: UpdateAttributeFilterStateRequest) => {
    return this.api.attributesFilter.updateAttributeFilterState(params);
  };
}

export class TestAttributesFilterService implements AttributesFilterService {
  constructor(props?: Extendable<AttributesFilterService>) {
    extendable(this, props);
  }

  getForCollector = async (): Promise<Array<AttributeFilterDTO>> => {
    // TODO add mock;
    return [];
  };

  updateDisplayState = async () => undefined;
}
