import { createContext, useEffect, useMemo, useState } from "react";
import { AutomaticCustomerCreation, AutomaticCustomerCreationProps } from "./AutomaticCustomerCreation";
import { StateContext, useContextOrThrow } from "../../../hooks/context.hooks";
import { OrganizationalUnitType } from "../../../clients/organizationalunit.client";
import { useServices } from "../../../services/context.service";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { externalizeBatchConfig, internalizeBatchConfig } from "./model/StoredBatchConfig";

export interface BatchCreationConfig {
  collectorId: string;
  rootFilter?: string;
}

export const AutomaticCustomerCreationProvider = ({ ...props }: AutomaticCustomerCreationProps) => {
  const service = useServices().projections;
  const [type, setType] = useState<OrganizationalUnitType>("CUSTOMER");
  const [customerCreationConfig, setCustomerCreationConfig] = useState<BatchCreationConfig[]>([]);
  const [storedConfig, reload] = useDataClientPromise(service.getConfig, [type]);

  useEffect(() => {
    if (storedConfig) {
      setCustomerCreationConfig(internalizeBatchConfig(storedConfig));
    }
  }, [storedConfig, setCustomerCreationConfig]);

  const memoizedType = useMemo(() => [type, setType] as const, [type, setType]);
  const memoizedConfig = useMemo(() => {
    return [customerCreationConfig, setCustomerCreationConfig] as const;
  }, [customerCreationConfig, setCustomerCreationConfig]);
  const memoizedSave = useMemo(() => {
    return () => {
      return service.saveConfig(type, externalizeBatchConfig(customerCreationConfig)).then(() => {
        reload();
      });
    };
  }, [type, service, customerCreationConfig, reload]);

  const memoizedCancel = useMemo(() => {
    return () => {
      if (storedConfig) {
        setCustomerCreationConfig(internalizeBatchConfig(storedConfig));
      } else {
        setCustomerCreationConfig([]);
      }
    };
  }, [storedConfig, setCustomerCreationConfig]);

  return (
    <BatchCreationTypeContext.Provider value={memoizedType}>
      <BatchCreationConfigContext.Provider value={memoizedConfig}>
        <BatchCreationSaveContext.Provider value={{ save: memoizedSave, cancel: memoizedCancel }}>
          <AutomaticCustomerCreation {...props} />
        </BatchCreationSaveContext.Provider>
      </BatchCreationConfigContext.Provider>
    </BatchCreationTypeContext.Provider>
  );
};

export const BatchCreationConfigContext = createContext<StateContext<BatchCreationConfig[]>>(undefined);
export const useBatchCreationConfig = () => useContextOrThrow(BatchCreationConfigContext)[0];
export const useSetBatchCreationConfig = () => useContextOrThrow(BatchCreationConfigContext)[1];

export const BatchCreationTypeContext = createContext<StateContext<OrganizationalUnitType>>(undefined);
export const useBatchCreationType = () => useContextOrThrow(BatchCreationTypeContext)[0];
export const useSetBatchCreationType = () => useContextOrThrow(BatchCreationTypeContext)[1];

export const BatchCreationSaveContext = createContext<
  | {
      save: () => Promise<void>;
      cancel: () => void;
    }
  | undefined
>(undefined);

export const useSaveBatchCreationConfig = () => useContextOrThrow(BatchCreationSaveContext).save;
export const useCancelBatchCreationConfig = () => useContextOrThrow(BatchCreationSaveContext).cancel;
