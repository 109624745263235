import { Grid } from "@material-ui/core";
import { TabsStyled } from "../../components/tabs/TabStyled";
import { Route, useLocation } from "react-router-dom";
import { AccountSettingsPage } from "./AccountSettingsPage";
import { NotificationsPage } from "./NotificationPage";
import { useRedirect } from "../../hooks/router.hooks";
import { CFContainer } from "../../components/layout/CFContainer";
import Container from "@material-ui/core/Container";
import { useFeatureToggle } from "../../hooks/ft.hooks";
import { FEATURES } from "../../app.config";
import { ApiDocs } from "./ApiDocs";

enum tabKeys {
  ACCOUNT_SETTINGS,
  NOTIFICATIONS,
  API_DOCS,
}

export function AccountPage() {
  const redirect = useRedirect();
  const location = useLocation();
  const swaggerFeatureToggleEnabled = useFeatureToggle(FEATURES.swagger);

  const tabs = [
    {
      key: tabKeys.ACCOUNT_SETTINGS,
      label: "Account Settings",
      url: "/account/settings",
    },
    {
      key: tabKeys.NOTIFICATIONS,
      label: "Notifications",
      url: "/account/notifications",
    },
    ...(swaggerFeatureToggleEnabled
      ? [
          {
            key: tabKeys.API_DOCS,
            label: "API Docs",
            url: "/account/api-docs",
          },
        ]
      : []),
  ];

  if (location.pathname === "/account") {
    redirect(tabs[0].url);
  }

  if (location.pathname === "/account/api-docs" && !swaggerFeatureToggleEnabled) {
    redirect(tabs[0].url);
  }

  return (
    <Container>
      <CFContainer>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TabsStyled tabs={tabs} />
            <Route path={tabs[0].url}>
              <AccountSettingsPage />
            </Route>
            <Route path={tabs[1].url}>
              <NotificationsPage />
            </Route>
            {swaggerFeatureToggleEnabled ? (
              <Route path={tabs[2].url}>
                <ApiDocs />
              </Route>
            ) : null}
          </Grid>
        </Grid>
      </CFContainer>
    </Container>
  );
}
