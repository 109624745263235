import { FormSelectSingle } from "../../components/form/FormSelectSingle";
import { BillingPeriodFilter, useInvoicePeriods } from "../../clients/billing-periods.client";
import { useSelectedBillingPeriods, useSetSelectedBillingPeriods } from "../../hooks/billing-periods.hooks";
import { FC, ReactNode } from "react";

type RenderFilterValue = (cycle: BillingPeriodFilter | null) => ReactNode | null;

interface BillingPeriodSelectProps {
  variant?: "outlined";
  fullWidth?: boolean;
  renderFilterValue?: RenderFilterValue;
}

const defaultRender: RenderFilterValue = (cycle) =>
  !!cycle ? cycle.anchorDateDisplayElement : "Aggregate over months";

export const BillingPeriodSelect: FC<BillingPeriodSelectProps> = ({ ...props }) => {
  const billingPeriods = useInvoicePeriods();
  const selected = useSelectedBillingPeriods();
  const setSelected = useSetSelectedBillingPeriods();

  const render = props.renderFilterValue ?? defaultRender;

  const options = [
    {
      id: "monthly",
      label: render(null) ?? defaultRender(null),
    },
    ...billingPeriods.map((cycle) => {
      return {
        id: cycle.id,
        label: render(cycle) ?? defaultRender(cycle),
      };
    }),
  ];

  return (
    <FormSelectSingle
      noGrid
      value={selected?.id ?? "monthly"}
      handleChange={(e: any) => setSelected(billingPeriods.find((it) => it.id === e.target.value) ?? null)}
      options={options}
      optionId={"id"}
      optionValue={"label"}
      id="billing_periods"
      label={"Billing cycle"}
      variant={props.variant ?? "standard"}
      fullWidth={props.fullWidth ?? false}
    />
  );
};
