import { ChangeEvent, useEffect, useState } from "react";
import { FormSelectSingle } from "../../../components/form/FormSelectSingle";
import { makeStyles } from "@material-ui/core/styles";
import { DATE_ATTRIBUTE, GroupSegment, queryKeysToSegments, segmentsToOptions } from "../model/GroupSegment";
import { Box, Button, Grid, IconButton, Paper } from "@material-ui/core";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import {
  ChartConfig,
  DISPLAY_SEGMENT,
  LineItemDisplay,
  pieChartTypes,
  rangeChartTypes,
  rawChartType,
} from "../model/ChartConfig";
import { GenericIcon, Icon } from "../../../theme/Icon";
import { attributeEquals, attributeId, parseAttributeId } from "../model/Attribute";
import { useDashCharts, useDashFilters } from "../selectors/dashboard.selector";
import { v4 } from "uuid";
import { assertNever } from "../../../utils/assert";
import { useServices } from "../../../services/context.service";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useRangeAsFilter } from "../model/Query";

const useStyles = makeStyles((theme) => ({
  filterFormControl: {
    width: 226,
    marginBottom: theme.spacing(2),
  },
  container: {
    margin: theme.spacing(2),
    width: 226 + theme.spacing(6),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addForm: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
  },
}));

export function DashAddGraph() {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const queryService = useServices().aggregate;
  const [filters] = useDashFilters();
  const rangeFilter = useRangeAsFilter();

  const [chartType, setChartType] = useState<ChartConfig["type"] | undefined>("line");
  const [segments, setSegments] = useState<GroupSegment[]>([]);
  const [segment, setSegment] = useState<GroupSegment | undefined>();
  const [argument, setArgument] = useState<GroupSegment | undefined>();
  const [value, setValue] = useState<LineItemDisplay | undefined>(DISPLAY_SEGMENT.QUANTITY);
  const [, setCharts] = useDashCharts();

  const noEmptyFilters = filters
    .filter((it) => it.filters.length > 0)
    .map((it) => ({ dimension: it.segment.id, filters: it.filters.map((it) => it.id) }));

  const [loadedOptions, , loading] = useDataClientPromise(queryService.keys, [[rangeFilter, ...noEmptyFilters]], {
    useCacheKey: "keys",
    shouldLoad: showForm,
  });

  const [segmentSelectOptions, setSegmentSelectOptions] = useState<
    { label: string; key: string; group: { id: string; label: string } }[]
  >([]);
  const [valueSelectOptions, setValueSelectOptions] = useState<{ label: string; key: string }[]>([]);

  const graphTypeSelectOptions = (): { key: string; label: string }[] => {
    return [rawChartType, ...rangeChartTypes, ...pieChartTypes].map((chartType) => {
      return {
        key: chartType,
        label: chartType === "spline" ? "smooth line" : chartType === "raw" ? "single-value" : chartType,
      };
    });
  };

  useEffect(() => {
    if (!loadedOptions) return;

    setValueSelectOptions(
      Object.values(DISPLAY_SEGMENT).map((displaySegment) => ({
        key: displaySegment.value,
        label: displaySegment.label,
      }))
    );
    const segments = queryKeysToSegments(loadedOptions);
    setSegments(segments);
    setSegmentSelectOptions(segmentsToOptions(segments));
    if (!segment) {
      setSegment(segments[0]);
    }
    if (!argument) {
      setArgument(segments[0]);
    }
  }, [loadedOptions, setValueSelectOptions, setSegmentSelectOptions, setSegments, argument, segment]);

  return (
    <Box className={classes.container}>
      {!showForm ? (
        <IconButton onClick={handleAddGraph} size={"medium"}>
          <Icon icon={GenericIcon.ADD_CHART} size={90} style={{ margin: 16 }} />
        </IconButton>
      ) : (
        <Grid container>
          <Paper classes={{ root: classes.addForm }}>
            <FormSelectSingle
              fullWidth={false}
              value={chartType}
              classesFormControl={{ root: classes.filterFormControl }}
              label={"Graph type"}
              handleChange={handleGraphTypeSelected}
              options={graphTypeSelectOptions()}
              optionId={"key"}
              id={`add-graph-type`}
            />

            {chartType === "bar" && (
              <FormSelectSingle
                fullWidth={false}
                value={attributeId(argument)}
                classesFormControl={{ root: classes.filterFormControl }}
                label={"Argument"}
                handleChange={handleArgumentSelected}
                options={segmentSelectOptions}
                isLoading={loading}
                optionId={"key"}
                group={{ id: (it) => it.group.id, label: (it) => it.group.label }}
                id={`add-graph-argument`}
              />
            )}

            <FormSelectSingle
              fullWidth={false}
              value={value?.value}
              classesFormControl={{ root: classes.filterFormControl }}
              label={"Value"}
              handleChange={handleValueSelected}
              options={valueSelectOptions}
              optionId={"key"}
              id={`add-graph-value`}
            />

            {chartType !== "raw" && (
              <FormSelectSingle
                fullWidth={false}
                value={attributeId(segment)}
                classesFormControl={{ root: classes.filterFormControl }}
                label={"Segment"}
                handleChange={handleSegmentSelected}
                options={segmentSelectOptions}
                optionId={"key"}
                isLoading={loading}
                group={{ id: (it) => it.group.id, label: (it) => it.group.label }}
                id={`add-graph-segment`}
              />
            )}

            <Box display={"flex"} justifyContent={"flex-end"}>
              <ButtonConfirm onClick={handleSaveAddingGraph}>Add</ButtonConfirm>
              <Button className={classes.cancelButton} onClick={() => setShowForm(false)}>
                Cancel
              </Button>
            </Box>
          </Paper>
        </Grid>
      )}
    </Box>
  );

  function handleSegmentSelected(event: ChangeEvent<{ value?: string }>) {
    if (event.target.value == null) return;
    const value = parseAttributeId(event.target.value);
    const segment = segments.find((it) => attributeEquals(it, value));
    if (segment != null) setSegment(segment);
  }

  function handleArgumentSelected(event: ChangeEvent<{ value?: string }>) {
    if (event.target.value == null) return;
    const value = parseAttributeId(event.target.value);
    const argument = segments.find((it) => attributeEquals(it, value));
    if (argument != null) setArgument(argument);
  }

  function handleValueSelected(event: ChangeEvent<{ value: string }>) {
    setValue(Object.values(DISPLAY_SEGMENT).find((it) => it.value === event.target.value));
  }

  function handleGraphTypeSelected(event: ChangeEvent<{ value: ChartConfig["type"] }>) {
    setChartType(event.target.value);
  }

  function handleAddGraph() {
    setShowForm(true);
  }

  function handleSaveAddingGraph() {
    setShowForm(false);
    if (chartType == null || value == null || !segment) return;
    setCharts((state) => [
      ...state,
      (() => {
        switch (chartType) {
          case "raw":
            return { id: v4(), type: chartType, displaySegment: value };
          case "line":
          case "spline":
          case "bar":
            return {
              id: v4(),
              type: chartType,
              displaySegment: value,
              groupSegment: segment,
              argument: chartType === "bar" && argument ? argument : DATE_ATTRIBUTE,
            };
          case "pie":
          case "donut":
            return { id: v4(), type: chartType, displaySegment: value, groupSegment: segment };
          default:
            return assertNever(chartType);
        }
      })(),
    ]);
  }
}
