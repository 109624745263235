import { Box, Tooltip, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import { GenericIcon, Icon } from "../theme/Icon";

interface InformationProps {
  title: string;
  align?: "right" | "left";
  size?: "inherit" | "default" | "small" | "large";
  color?: "inherit" | "primary" | "secondary" | "action" | "error" | "disabled";
  className?: string;
}

export const Information: FunctionComponent<InformationProps> = ({
  title,
  align = "right",
  size = "small",
  color,
  className,
}) => {
  return (
    <Box className={className} display={"flex"} flexDirection={"column"} alignContent={"start"}>
      <Box display={"flex"} flexDirection={align === "right" ? "row" : "row-reverse"} alignContent={"end"}>
        <Box flexGrow={1} />
        <Tooltip style={{ zIndex: 1 }} title={<Typography style={{ whiteSpace: "pre-line" }}>{title}</Typography>}>
          <Box>
            <Icon icon={GenericIcon.INFO} size={size} color={color} />
          </Box>
        </Tooltip>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );
};

interface InformationLinkProps {
  url: string;
  size?: "inherit" | "default" | "small" | "large";
  color?: "inherit" | "primary" | "secondary" | "action" | "error" | "disabled";
  className?: string;
}

export const InformationLink: FunctionComponent<InformationLinkProps> = ({
  url,
  size = "small",
  color = "action",
  className,
}) => {
  return (
    <a target={"_blank"} href={url} rel="noreferrer">
      <Icon className={className} icon={GenericIcon.INFO} size={size} color={color} />
    </a>
  );
};
