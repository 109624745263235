import { API } from "./context.service";
import { ForceMFADTOTypeEnum, GetMFASecretDTO } from "../open-api";

export interface MfaService {
  requestSecret(): Promise<GetMFASecretDTO>;

  enableMFA(secret: string, code: string): Promise<any>;

  disableMFA(): Promise<any>;

  resetMFA(email: string): Promise<any>;

  verifyMFA(code: string): Promise<any>;

  isMFAForced(): Promise<string>;

  enableForcedMFA(type: ForceMFADTOTypeEnum): Promise<void>;

  disableForcedMFA(type: ForceMFADTOTypeEnum): Promise<void>;

  isWorkspaceMFAForced(type: ForceMFADTOTypeEnum): Promise<string>;
}

export class LiveMfaService implements MfaService {
  constructor(private api: API) {}

  requestSecret = (): Promise<GetMFASecretDTO> => {
    return this.api.mfa.getNewMFASecret();
  };

  enableMFA = (secret: string, code: string): Promise<any> => {
    return this.api.mfa.enableMFA({
      enableMFADTO: {
        secret: secret,
        code: code,
      },
    });
  };

  disableMFA = (): Promise<void> => {
    return this.api.mfa.disableMFA();
  };

  resetMFA = (email: string): Promise<void> => {
    return this.api.mfa.resetMFA({ email: email });
  };

  verifyMFA = (code: string) =>
    this.api.mfa
      .completeLogin(
        {
          code: code,
        },
        {
          redirect: "manual",
        }
      )
      .catch(() => {
        window.location.href = "/login";
      });

  isMFAForced = (): Promise<string> => {
    return this.api.mfa.isMFAForced();
  };

  enableForcedMFA = (type: ForceMFADTOTypeEnum): Promise<void> => {
    return this.api.mfa.enableForcedMFA({
      forceMFADTO: {
        type: type,
      },
    });
  };

  disableForcedMFA = (type: ForceMFADTOTypeEnum): Promise<void> => {
    return this.api.mfa.disableForcedMFA({
      forceMFADTO: {
        type: type,
      },
    });
  };

  isWorkspaceMFAForced = (type: ForceMFADTOTypeEnum): Promise<string> => {
    return this.api.mfa.isWorkspaceMFAForced({
      forceMFADTO: {
        type: type,
      },
    });
  };
}
