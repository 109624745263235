import { useDataClient, useDataClientPromise } from "../hooks/client.hooks";
import { useServices } from "../services/context.service";

export function useGetAllDashboards() {
  return useDataClientPromise(useServices().dashboard.getAll, []);
}

export interface MonthlyCostDTO {
  cost: number;
  billingMonth: string;
  collectorId: string;
}
export function useMonthlyOverview() {
  return useDataClient<MonthlyCostDTO[]>("/api/dashboard/monthly-overview");
}
