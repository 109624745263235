import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Link, useLocation } from "react-router-dom";
import { LinkProps } from "@material-ui/core";
import { ConditionalRender } from "../components/ConditionalRender";
import { useIsManagedCView, useSubscriptionType, WorkspaceRole } from "../hooks/auth.hooks";
import { useCollectors } from "../clients/collector.client";
import { allStepsComplete } from "../pages/information/stepper/OnboardingStepper";
import { getBillingPageTitle } from "../pages/billing/BillingOverviewPage";
import { getInvoicesPageTitle } from "../pages/invoices/InvoicesPage";
import { GenericIcon, Icon } from "../theme/Icon";
import TimelineIcon from "@material-ui/icons/Timeline";
import { forwardRef } from "react";

export default function AppMenu() {
  const subscriptionType = useSubscriptionType();
  const [dataSources] = useCollectors();
  const isManagedCViewUser = useIsManagedCView();

  return (
    <List component="nav">
      <ListItemLink to="/dashboard" primary="Dashboard" icon={<Icon icon={GenericIcon.DASHBOARD} />} />
      <ListItemLink to="/analytics" primary="Analytics" icon={<TimelineIcon />} />

      <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
        <ListItemLink to="/invoices" primary={getInvoicesPageTitle()} icon={<Icon icon={GenericIcon.INVOICES} />} />
      </ConditionalRender>
      <ListItemLink to="/billing" primary={getBillingPageTitle()} icon={<Icon icon={GenericIcon.BILLING} />} />
      <ListItemLink
        to="/organizational-unit"
        primary="Organizations"
        icon={<Icon icon={GenericIcon.ORGANIZATIONAL_UNIT} />}
      />
      <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
        <ListItemLink to="/datasources" primary="Data Sources" icon={<Icon icon={GenericIcon.DATASOURCE} />} />
      </ConditionalRender>
      <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
        <ListItemLink
          to="/user-management"
          primary="User Management"
          icon={<Icon icon={GenericIcon.USER_MANAGEMENT} />}
        />
      </ConditionalRender>
      {subscriptionType !== "ACTIVE" || isManagedCViewUser || (dataSources && allStepsComplete(dataSources)) ? (
        ""
      ) : (
        <ListItemLink
          to="/information"
          primary="Information"
          icon={<Icon icon={GenericIcon.INFO} color={"primary"} />}
        />
      )}
    </List>
  );
}

interface ListItemLinkProps {
  icon: any;
  primary: string;
  to: string;
}

function ListItemLink({ icon, primary, to }: ListItemLinkProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  Object.keys(params).forEach((it) => {
    if (it !== "ft") {
      params.delete(it);
    }
  });

  const renderLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link to={{ pathname: to, search: params.toString() }} ref={ref} {...props} />
  ));

  return (
    <>
      <ListItem button component={renderLink} selected={location && location.pathname.startsWith(to)} title={primary}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
      <Divider />
    </>
  );
}
