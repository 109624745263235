import { css } from "@emotion/css";

import React, { useEffect, useRef, useState } from "react";
import { GroupSegment } from "../model/GroupSegment";
import { Checkbox, IconButton, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteGroupSegmentFilter,
  GroupSegmentFilter,
  onMultipleFiltersSelected,
  SegmentFilterOption,
} from "../model/GroupSegmentFilter";
import { attributeEquals } from "../model/Attribute";
import { useDashFilters, useDateRange } from "../selectors/dashboard.selector";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TextField from "@material-ui/core/TextField";
import { SearchRounded } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { useServices } from "../../../services/context.service";
import { toBillingMonths } from "../model/DateRange";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import WarningIcon from "@material-ui/icons/Warning";

interface DashSelectFilterProps {
  filter: GroupSegmentFilter;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const LISTBOX_PADDING = 8;
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;
  const rowHeights = useRef({});

  const getRowHeight = (index: number) => {
    // @ts-ignore
    return rowHeights?.current?.[index] || 60;
  };

  const setRowHeight = (index: number, size: number) => {
    // @ts-ignore
    if (gridRef?.current != null) {
      // @ts-ignore
      gridRef.current.resetAfterIndex(0);
    }
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  function Row(props: ListChildComponentProps) {
    const { data, index, style } = props;

    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current != null) {
        // @ts-ignore
        setRowHeight(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

    return React.cloneElement(data[index], {
      style: {
        ...style,
        height: "auto",
      },
      ref: rowRef,
    });
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map((item, index) => getRowHeight(index)).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getRowHeight}
          overscanCount={5}
          itemCount={itemCount}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

type FilterOption = {
  key: string;
  label?: string;
  inactive?: boolean;
};

export const DashSelectFilter = ({ filter, open, onOpen, onClose }: DashSelectFilterProps) => {
  const theme = useTheme();
  const [dateRange] = useDateRange();
  const [filters, setFilters] = useDashFilters();
  const [inputValue, setInputValue] = useState("");
  const queryService = useServices().aggregate;
  const ref = useRef<HTMLDivElement>();

  const billingMonths = toBillingMonths(dateRange);
  const filtersWithRange = [
    ...filters
      .filter((it) => !attributeEquals(it.segment, filter.segment))
      .filter((it) => it.filters.length > 0)
      .map((it) => ({ dimension: it.segment.id, filters: it.filters.map((it) => it.id) })),
    {
      dimension: "BILLING_MONTH",
      filters: billingMonths,
    },
  ];
  const [filterOptions, , loading] = useDataClientPromise(queryService.options, [filter.segment.id, filtersWithRange], {
    useCacheKey: "options",
    shouldLoad: open,
    onSuccess: (res) =>
      res.options
        .map((it) => ({ key: it.id, label: it.display || it.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
  });

  useEffect(() => {
    // autocomplete doesn't set focus well when controlling the open state
    if (open) window.setTimeout(() => ref.current?.querySelector("input")?.focus(), 0);
  }, [open]);

  const selectedFilters = filter.filters;

  const options = filterOptions ?? [];
  // Finding the filters in the options to make sure it is referential equal
  const inactiveFilterOptions: FilterOption[] = [];

  const value = selectedFilters.map((activeFilter) => {
    const found = options.find((option) => option.key === activeFilter.id);
    const inactive = !loading && !!filterOptions && !found;
    if (inactive) {
      inactiveFilterOptions.push({
        key: activeFilter.id,
        label: activeFilter.label ?? activeFilter.id,
        inactive: true,
      });
    }
    return { key: activeFilter.id, label: activeFilter.label, inactive: inactive };
  });

  // This is independent of the options, as the options are expensive and only updated when open.
  // This is always updated, to show the selected filters.
  const allOptions = [...inactiveFilterOptions, ...options];

  return (
    <Autocomplete<{ key: string; label?: string; inactive?: boolean }, true>
      ref={ref}
      multiple
      className={css({
        width: 312,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
      })}
      open={open}
      value={value}
      getOptionSelected={(a, b) => a.key === b.key}
      loading={loading}
      onChange={(event, value) => handleChanged(value.map((it) => ({ id: it.key, label: it.label })))}
      disableCloseOnSelect
      inputValue={inputValue}
      onInputChange={(e, value, reason) => {
        if (e && reason !== "reset") {
          setInputValue(value);
        }
      }}
      onOpen={onOpen}
      onClose={onClose}
      options={allOptions}
      disableListWrap
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            size={"small"}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon color={"primary"} fontSize="small" />}
            style={{ marginRight: theme.spacing(0.5) }}
            checked={selected}
          />
          {option.inactive && (
            <Tooltip
              title={
                "This filter does not appear in the data anymore. We recommend removing or replacing this filter, since it can lead to errors and invalid data."
              }
            >
              <WarningIcon color="action" className={css({ paddingRight: "8px" })} />
            </Tooltip>
          )}
          {option.label}
        </>
      )}
      getOptionLabel={(option) => option.label ?? option.key}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            // error={inactiveFilterOptions.length > 0}
            // helperText={inactiveFilterOptions.length > 0
            //   ? "This filter contains items that do not exist anymore in the available data. Please review the filters, and save the updated dashboard."
            //   : undefined}
            label={
              <>
                {filter.segment.label}
                {inactiveFilterOptions.length > 0 && (
                  <WarningIcon fontSize="inherit" className={css({ marginLeft: "8px", marginBottom: "-2px" })} />
                )}
              </>
            }
            placeholder={"Search"}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              ...(open || selectedFilters.length === 0
                ? { flex: 1 }
                : { style: { minWidth: "0px", padding: 0, flex: 0 } }),
              value: inputValue,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <div
                    className={css({
                      position: "absolute",
                      right: 33,
                      top: "calc(50% - 14px)",
                      visibility: selectedFilters.length === 0 ? "visible" : "hidden",
                    })}
                  >
                    <DeleteFilter segment={filter.segment} />
                  </div>
                </>
              ),
              startAdornment:
                open || selectedFilters.length === 0 ? (
                  <SearchRounded className={css({ margin: "0 4px" })}> </SearchRounded>
                ) : (
                  <div
                    onClick={() => onOpen()}
                    className={css({
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      // matching the padding of the input
                      padding: "9.5px 4px",
                      flex: 1,
                      textOverflow: "ellipsis",
                    })}
                  >
                    {value.map((it, idx) => (
                      <>
                        {it.label ?? it.key}
                        {it.inactive && (
                          <WarningIcon
                            fontSize="inherit"
                            className={css({ marginLeft: "8px", marginBottom: "-2px" })}
                          />
                        )}
                        {idx < value.length - 1 && ", "}
                      </>
                    ))}
                  </div>
                ),
            }}
          />
        );
      }}
    />
  );

  function handleChanged(newFilters: SegmentFilterOption[]) {
    setFilters(onMultipleFiltersSelected(filters, filter, newFilters));
  }
};

function DeleteFilter({ segment }: { segment: GroupSegment }) {
  const [filters, setFilters] = useDashFilters();

  return (
    <IconButton
      edge={false}
      size={"small"}
      aria-label="delete"
      onClick={() => setFilters(deleteGroupSegmentFilter(filters, segment))}
    >
      <DeleteIcon />
    </IconButton>
  );
}
