import Dialog from "@material-ui/core/Dialog";
import { useState } from "react";
import { StepperDialog, StepperDialogObj } from "../../../components/dialogs/StepperDialog";
import { OrganizationalUnitType, OrganizationalUnitUnitType } from "../../../clients/organizationalunit.client";
import { messageError, messageSuccess } from "../../../services/message.service";
import { StepDialogError } from "../../../components/tutorial/StepDialogError";
import { OrganizationalUnitFormCreate } from "../forms/OrganizationalUnitFormCreate";
import { OrganizationalUnitCreateStepSelect } from "./steps/OrganizationalUnitCreateStepSelect";
import { OrganizationalUnitCreateStepCreated } from "./steps/OrganizationalUnitCreateStepCreated";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

export type SetTypeAndUnitType = (type: OrganizationalUnitType, unitType: OrganizationalUnitUnitType) => void;

const useTypeAndUnitType = (): [
  OrganizationalUnitType | null,
  OrganizationalUnitUnitType | null,
  SetTypeAndUnitType
] => {
  const [type, setType] = useState(null as OrganizationalUnitType | null);
  const [unitType, setUnitType] = useState(null as OrganizationalUnitUnitType | null);

  return [
    type,
    unitType,
    (type: OrganizationalUnitType, unitType: OrganizationalUnitUnitType) => {
      setType(type);
      setUnitType(unitType);
    },
  ];
};

interface OrganizationalUnitDialogProps {
  toggle?: () => void;
  open?: boolean;
  onSuccess?: (res: OrganizationalUnitResponseDTO) => void;
  title?: string;
  parent?: OrganizationalUnitResponseDTO;
  buttons?: { close: boolean; cancel: boolean };
}

export function OrganizationalUnitDialogCreate({
  toggle,
  open = true,
  onSuccess,
  title = "Add organization",
  buttons = { close: true, cancel: true },
  parent,
}: OrganizationalUnitDialogProps) {
  const [type, unitType, setTypeAndUnitType] = useTypeAndUnitType();
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    toggle && toggle();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSuccess = (res: OrganizationalUnitResponseDTO) => {
    messageSuccess(`Organization created`);
    handleNext();
    onSuccess && onSuccess(res);
  };

  const handleError = (e: any) => {
    messageError("Could not create organizational unit.");
  };

  const step1: StepperDialogObj = {
    stepTitle: "Select type of organizational unit",
    step: (
      <OrganizationalUnitCreateStepSelect
        setTypeAndUnitType={(type, unitType) => {
          setTypeAndUnitType(type, unitType);
          handleNext();
        }}
        onClose={handleClose}
        buttonCancel={buttons.cancel}
        parent={parent}
      />
    ),
  };

  const step2: StepperDialogObj = {
    stepTitle: "Fill in details",
    step:
      type && unitType ? (
        <OrganizationalUnitFormCreate
          onClose={handleClose}
          onSuccess={handleSuccess}
          onError={handleError}
          type={type}
          unitType={unitType}
          parentId={parent?.id}
        />
      ) : (
        <StepDialogError text={"No organizational type selected"} onBack={handleBack} onCancel={handleClose} />
      ),
  };

  const step3: StepperDialogObj = {
    stepTitle: "Done",
    step: <OrganizationalUnitCreateStepCreated type={type} onClose={handleClose} />,
  };

  const steps = [step1, step2, step3];

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose} disableBackdropClick>
      <StepperDialog steps={steps} dialogTitle={title} onClose={handleClose} activeStep={activeStep} />
    </Dialog>
  );
}
