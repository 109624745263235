import { ReactNode, useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@material-ui/core";
import { FormSelectSingle } from "../../components/form/FormSelectSingle";
import { formattedCurrency, formattedDate } from "../../utils/localization.utils";
import { DataSourceIcon, Icon } from "../../theme/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { BillingPeriodSelect } from "../../app/config/BillingPeriodSelect";
import { InvoiceResponseDTO } from "../../open-api";
import { css } from "@emotion/css";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 70,
    height: 18,
    paddingRight: theme.spacing(0.5),
  },
  root: {
    height: theme.spacing(3),
  },
}));

interface InvoiceFilterProps {
  selected: InvoiceResponseDTO | null;
  setSelected: (invoice: InvoiceResponseDTO | null) => void;
  loading: boolean;
  allInvoices: InvoiceResponseDTO[];
}

interface DataSource {
  id: string;
  label: ReactNode;
}

const ALL_DATA_SOURCE_ID: string = "all-data-sources";

export function InvoiceFilter({ selected, setSelected, loading, allInvoices }: InvoiceFilterProps) {
  const [invoices, setInvoices] = useState<InvoiceResponseDTO[]>([]);

  const onSelect = (invoiceId: string) => {
    invoices && setSelected(invoices.find((it) => it.id === invoiceId) ?? null);
  };

  useEffect(() => {
    setInvoices(allInvoices);
  }, [allInvoices]);

  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <BillingPeriodSelect
          variant={"outlined"}
          fullWidth
          renderFilterValue={(filter) => (
            <DataSourceSelectValue
              dataSource={{
                id: filter ? filter.datasourceName : ALL_DATA_SOURCE_ID,
                label: filter ? filter.anchorDateDisplayElement : "All billing cycles",
              }}
            />
          )}
        />
      </Grid>
      <Grid container item xs={6}>
        <FormSelectSingle
          isLoading={!invoices || loading}
          value={(selected && selected.id) || ""}
          handleChange={(e: any) => onSelect(e.target.value)}
          options={invoices || []}
          optionId={"id"}
          optionValue={(e) => <InvoiceSelectValue invoice={e} />}
          id="invoices"
          label="Invoices"
          emptyOption
          classesSelect={{ root: classes.root }}
        />
      </Grid>
      <Grid item xs={2}>
        <PdfDownload invoice={selected} />
      </Grid>
    </Grid>
  );
}

const InvoiceSelectValue = ({ invoice }: { invoice: InvoiceResponseDTO }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={1}>
        <Icon icon={invoice.origin as DataSourceIcon} className={classes.logo} />
      </Grid>
      <Grid item xs={7} className={css({ paddingLeft: theme.spacing(2) })}>
        <strong>{invoice.name}</strong>
      </Grid>
      <Grid item xs={2}>
        <small>{formattedDate(invoice.date)}</small>
      </Grid>
      <Grid item xs={2} container justify={"flex-end"}>
        {invoice.invoicedCost ? (
          formattedCurrency(invoice.invoicedCost, invoice.currency)
        ) : invoice.cost === 0 ? (
          <>-</>
        ) : (
          <>&#177; {formattedCurrency(invoice.cost, invoice.currency)}</>
        ) // +/- sign
        }
      </Grid>
    </Grid>
  );
};

const DataSourceSelectValue = ({ dataSource }: { dataSource: DataSource }) => {
  const classes = useStyles();
  const icon =
    dataSource.id !== ALL_DATA_SOURCE_ID ? (
      <Icon icon={dataSource.id as DataSourceIcon} className={classes.logo} />
    ) : (
      ""
    );
  return (
    <Box display={"flex"}>
      {icon}
      <Typography>{dataSource.label}</Typography>
    </Box>
  );
};

function PdfDownload({ invoice }: { invoice: InvoiceResponseDTO | null | undefined }) {
  if (!invoice) {
    return null;
  }

  return invoice.pdfAvailable ? (
    <Tooltip title="Download original invoice">
      <IconButton component={Link} to={`/api/invoices/${invoice.id}/pdf`} target="_blank">
        <PictureAsPdfIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="No pdf invoice available">
      <span>
        <IconButton disabled>
          <PictureAsPdfIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
