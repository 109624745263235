import { Grid, Typography } from "@material-ui/core";
import { CFactsIcon, Icon } from "../../theme/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 18,
    width: 18,
    marginRight: 8,
  },
}));

interface FormTitleFieldProps {
  icon?: CFactsIcon;
  text: string;
}

export function FormTitleField({ icon, text }: FormTitleFieldProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant="h6">
        {icon && <Icon icon={icon} className={classes.button} />} {text}
      </Typography>
    </Grid>
  );
}
