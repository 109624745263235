import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, capitalize, createStyles, Grid, TableFooter, Theme } from "@material-ui/core";
import { DIMENSIONS, displayValueKey, displayValueKeyDescription, ValueKey } from "../../clients/lineitems.client";
import { mapDimension } from "../../mappers/dimension.mapper";
import { Money } from "../Money";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { mapType, mapTypeIcon } from "../../mappers/lineitem.mapper";
import { useAttributesHierarchy } from "../../clients/attributes.client";
import { associateBy } from "../../utils/functional/reduce.utils";
import Tooltip from "@material-ui/core/Tooltip";
import { AttributeHierarchyResponseDTO, NestedLineItemDTOV3 } from "../../open-api";
import { pipe } from "../../utils/fp";
import { FC, ReactNode, useState } from "react";
import { BillingLineItem, SHOW_MORE_STEP, ShowMore } from "./BillingLineItem";

export const LINE_ITEM_TABLE_CF_ORGANIZATION_DIMENSIONS = [DIMENSIONS.ORGANIZATION, DIMENSIONS.DEPARTMENT];

export const LINE_ITEM_TABLE_DIMENSIONS = [
  DIMENSIONS.DATASOURCE,
  DIMENSIONS.CUSTOMER_TAG,
  DIMENSIONS.APPLICATION_TAG,
  DIMENSIONS.SERVICE,
  DIMENSIONS.DETAIL,
];

export const DIMENSIONS_DEFAULT = [...LINE_ITEM_TABLE_CF_ORGANIZATION_DIMENSIONS, ...LINE_ITEM_TABLE_DIMENSIONS];

const useStyles = makeStyles((theme) => ({
  text: {
    justifyContent: "space-between",
  },
  highlightText: {
    fontWeight: "bold",
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.grey[700],
  },
  tableBottom: {
    "&> th": {
      backgroundColor: theme.palette.grey[700],
      position: "sticky",
      bottom: 0,
      left: 0,
      zIndex: 2,
    },
  },
  cellTextHighLight: {
    color: theme.palette.grey[100],
    fontWeight: "bold",
  },
  container: {
    maxHeight: "calc(100vh - 365px)",
  },
  tooltip: {
    maxWidth: 600,
  },
  tooltipBody: {
    fontSize: 15,
    whiteSpace: "pre-line",
  },
  toolTippedHead: {
    fontStyle: "italic",
  },
}));

interface LineItemTableProps {
  dataSourceId?: string;
  titleItem?: NestedLineItemDTOV3 | string;
  displayTitle?: boolean;
  lineItems: NestedLineItemDTOV3[];
  dimensions?: string[];
  stretchToParentHeight?: boolean;
  valueKeys?: ValueKey[];
}

const LineItemTable: FC<LineItemTableProps> = ({
  dataSourceId,
  dimensions = DIMENSIONS_DEFAULT,
  lineItems,
  titleItem,
  displayTitle = true,
  stretchToParentHeight = false,
  valueKeys = ["cost", "discount", "sales", "crossCharging"],
}) => {
  const classes = useStyles();

  const [attributes] = useAttributesHierarchy();
  const [showChildCount, setShowChildCount] = useState(SHOW_MORE_STEP);

  const hierarchyAttributes: Record<string, AttributeHierarchyResponseDTO> = associateBy<AttributeHierarchyResponseDTO>(
    attributes ?? [],
    (attr) => attr.collectorId
  );
  const hierarchyAttribute: AttributeHierarchyResponseDTO | undefined = dataSourceId
    ? hierarchyAttributes[dataSourceId]
    : undefined;

  if (lineItems.length !== 1) {
    return null;
  }
  const root = lineItems[0];
  const items = root.children
    .filter((it) => it.total.cost || it.total.list || it.total.sales || it.total.crossCharging)
    .sort((a, b) => a.label.localeCompare(b.label));

  const actualValueKeys = valueKeys.filter((it) => !!root.total[it]);

  const renderToolTip = (label: string, tooltipContent: string): ReactNode => {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        placement={"top-start"}
        title={
          <>
            <Typography classes={{ root: classes.tooltipBody }}>{tooltipContent}</Typography>
          </>
        }
      >
        <Box className={classes.toolTippedHead}>{label}</Box>
      </Tooltip>
    );
  };

  const renderTableHeadCell = (dimension: keyof typeof DIMENSIONS): ReactNode => {
    const dimensionDisplayValue = mapDimension(dimension);

    switch (dimension) {
      // organization
      case DIMENSIONS.ORGANIZATION_ATTRIBUTE:
        const organizationAttribute: string | undefined = hierarchyAttribute?.root?.displayValue;
        if (!!organizationAttribute) {
          return renderToolTip(organizationAttribute, dimensionDisplayValue);
        }

        const displayOrganizationAttributes = (attributes ? attributes : [])
          .filter((attribute) => !!attribute.root)
          .map((attribute) => `${attribute.root?.collectorName}: ${attribute.root?.displayValue}`)
          .join("\n");
        if (!!displayOrganizationAttributes) {
          return renderToolTip(dimensionDisplayValue, displayOrganizationAttributes);
        }

        return dimensionDisplayValue;

      // resource group
      case DIMENSIONS.COST_CENTER_ATTRIBUTE:
        const resourceGroupAttribute: string | undefined =
          hierarchyAttribute?.detail && hierarchyAttribute?.detail[0]
            ? hierarchyAttribute?.detail[0].displayValue
            : undefined;
        if (!!resourceGroupAttribute) {
          return renderToolTip(resourceGroupAttribute, dimensionDisplayValue);
        }

        const displayResourceGroupAttributes = (attributes ? attributes : [])
          .filter((attribute) => !!attribute.detail && attribute.detail[0])
          .map(
            (attribute) =>
              `${attribute.detail && attribute.detail[0].collectorName}: ${
                attribute.detail && attribute.detail[0].displayValue
              }`
          )
          .join("\n");
        if (!!displayResourceGroupAttributes) {
          return renderToolTip(dimensionDisplayValue, displayResourceGroupAttributes);
        }

        return dimensionDisplayValue;

      default:
        return dimensionDisplayValue;
    }
  };

  return (
    <Grid container>
      {displayTitle && (
        <Grid item xs={12}>
          <EnhancedTableToolbar item={titleItem || root} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer className={stretchToParentHeight ? "" : classes.container} component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {dimensions.map((it) => (
                  <TableCell key={it}>{renderTableHeadCell(it as keyof typeof DIMENSIONS)}</TableCell>
                ))}
                <TableCell align="right">Qty</TableCell>
                {actualValueKeys.map((valueKey) => (
                  <TableCell align="right">
                    {pipe(displayValueKeyDescription(valueKey as ValueKey), (description) =>
                      description == null ? (
                        <>{displayValueKey(valueKey as ValueKey)}</>
                      ) : (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          placement={"top-start"}
                          title={
                            <Typography classes={{ root: classes.tooltipBody }}>{capitalize(description)}</Typography>
                          }
                        >
                          <Box className={classes.toolTippedHead}>{displayValueKey(valueKey as ValueKey)}</Box>
                        </Tooltip>
                      )
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(0, showChildCount).map((item) => (
                <BillingLineItem
                  key={item.id}
                  lineItem={item}
                  expandOnFirstRender={false}
                  maxDepth={dimensions.length - 1}
                  valueColumns={actualValueKeys}
                />
              ))}
              {items.length > showChildCount && (
                <ShowMore
                  onClick={() => setShowChildCount((count) => count + SHOW_MORE_STEP)}
                  depth={dimensions.length - 2}
                  maxDepth={dimensions.length - 1}
                  valueColumns={actualValueKeys}
                  remainingItems={items.slice(showChildCount)}
                />
              )}
            </TableBody>
            <TableFooter>
              <TableRow key={root.id} className={classes.tableBottom}>
                <TableCell component="th" colSpan={dimensions.length} scope="row" />
                <TableCell component="th" scope="row" align="right" className={classes.cellTextHighLight}>
                  Total:
                </TableCell>
                {actualValueKeys.map((valueKey) => (
                  <TableCell component="th" align="right" className={classes.cellTextHighLight}>
                    <Money money={root.total[valueKey]} />
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    title: {
      paddingLeft: theme.spacing(1),
      flex: "1 1 100%",
    },
  })
);
const EnhancedTableToolbar = ({ item }: { item: NestedLineItemDTOV3 | string }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      {typeof item !== "string" && mapTypeIcon(item.label)}
      <Typography className={classes.title} variant="h6" component="div">
        {typeof item === "string" ? item : mapType(item.label)}
      </Typography>
    </Toolbar>
  );
};

export default LineItemTable;
