import { Dialog, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitleCloseable } from "../DialogTitleCloseable";
import { DialogProps } from "../../hooks/dialog.hooks";
import { ReactNode } from "react";

interface FullScreenDialogProps extends DialogProps {
  title: string;
  children: ReactNode;
  maxWidth?: string;
}

export const TAG_DIALOG_FULLSCREEN_HEIGHT_IN_PERCENTAGE = "92";
export const TAG_DIALOG_FULLSCREEN_Z_INDEX = 1600;

const useStyles = (maxWidth: string): (() => Record<"fullScreen" | "container", string>) => {
  return makeStyles((theme: Theme) => ({
    fullScreen: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      height: `${TAG_DIALOG_FULLSCREEN_HEIGHT_IN_PERCENTAGE}%`,
      zIndex: TAG_DIALOG_FULLSCREEN_Z_INDEX,
    },
    container: {
      padding: theme.spacing(2),
      overflowX: "hidden",
      overflowY: "auto",
      margin: "auto",
      maxWidth: maxWidth,
    },
  }));
};

export const FullScreenDialog = ({ open, onClose, children, title, maxWidth = "100%" }: FullScreenDialogProps) => {
  const classes: Record<"fullScreen" | "container", string> = useStyles(maxWidth)();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      disableBackdropClick
      classes={{
        paperFullScreen: classes.fullScreen,
        container: classes.container,
      }}
    >
      <DialogTitleCloseable onClose={onClose}>{title}</DialogTitleCloseable>
      {children}
    </Dialog>
  );
};
