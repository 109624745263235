export const debounce = <T extends any[]>(fn: (...args: T) => void, time: number) => {
  let timeout: number;

  return (...args: T) => {
    const functionCall = () => fn(...args);

    window.clearTimeout(timeout);
    timeout = window.setTimeout(functionCall, time);
  };
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
