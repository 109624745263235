import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useMergedState } from "../../../hooks/state.hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { RouterLink } from "../../../components/navigation/RouterLink";
import { appConfig } from "../../../app.config";
import { ChangeEvent } from "react";
import { css } from "@emotion/css";
import { useTheme } from "@material-ui/core";

interface SignInState {
  username: string;
  password: string;
  loading: boolean;
}

export function SignInComponent() {
  const theme = useTheme();

  // TODO: This should be replaced by react-form-hooks, however I have been unable to figure out to get that to work
  const [{ username, password, loading }, setState] = useMergedState<SignInState>({
    username: "",
    password: "",
    loading: false,
  });

  const handleChange = (name: keyof SignInState) => (event: ChangeEvent<any>) => {
    setState({
      [name]: event.target.value,
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        container
        className={css({
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </Grid>
      <form method="post" action={`${appConfig.apiBaseUri}/api/login/handle`}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              className={css({ marginBottom: theme.spacing(2) })}
              fullWidth
              variant="outlined"
              id="username"
              name="username"
              label="Email Address"
              autoComplete="username"
              autoFocus
              value={username || ""}
              onChange={handleChange("username")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              value={password || ""}
              onChange={handleChange("password")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={css({ margin: theme.spacing(2, 0) })}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}>
            <RouterLink to={"/login/forgot"}>Forgot password?</RouterLink>
            <RouterLink to={"/login/sso"}>SSO Login</RouterLink>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
