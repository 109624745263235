import { putProjection, usePossibleProjections } from "../../../clients/projections.client";
import { useApiCall } from "../../../hooks/client.hooks";
import { Grid, Theme } from "@material-ui/core";
import { SearchableTabTree } from "../components/tags/SearchableTabTree";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  filterAvailableAndNotAssociatedWithOrganizationId,
  filterProjectionsAssociatedWithOrganizationId,
  filterUnavailableProjectionsWithOrganizationId,
  mappedToWeightForOrganization,
  mapProjectionsToTreeListItems,
  projectionAssociated,
  projectionAvailable,
  TAG,
  weightAvailableForOrganization,
} from "../../../utils/functional/tag.utils";
import { ActionButtonType } from "../components/tags/buttons/TagActionButton";
import { GenericIcon } from "../../../theme/Icon";
import { roundToFourDecimals } from "../../../utils/localization.utils";
import {
  OrganizationalUnitResponseDTO,
  ProjectionOrganizationRequestDTO,
  ProjectionRequestDTO,
  ProjectionResponseDTO,
} from "../../../open-api";

const useStyles = makeStyles((theme: Theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
    opacity: 1,
  },
  inactive: {
    textDecoration: "line-through",
    color: theme.palette.text.disabled,
    opacity: 1,
  },
  selected: {
    color: theme.palette.primary.light,
    opacity: 1,
  },
  available: {
    color: theme.palette.text.primary,
    opacity: 1,
  },
  container: {
    height: "100%",
  },
  associatedTree: {
    background: theme.palette.background.default,
  },
}));

export const OrganizationalUnitTagsFormEdit = ({
  organizationalUnit,
}: {
  organizationalUnit: OrganizationalUnitResponseDTO;
}) => {
  const [possibleProjections, refreshProjections, isLoading] = usePossibleProjections();
  const [updateProjection] = useApiCall(putProjection);
  const [loading, setLoading] = useState(false);
  const [projections, setProjections] = useState(possibleProjections);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setProjections(possibleProjections);
  }, [possibleProjections]);

  const classes = useStyles();

  const selectedProjections = filterProjectionsAssociatedWithOrganizationId(
    possibleProjections ?? [],
    organizationalUnit.id
  );

  const addMappedToForOrganization = (
    projection: ProjectionRequestDTO,
    value: number,
    organizationalUnitId: string
  ): ProjectionRequestDTO => {
    const mappedToForOrganization: ProjectionOrganizationRequestDTO = {
      id: organizationalUnitId,
      weight: roundToFourDecimals(value / 100),
    };

    return {
      ...projection,
      mappedTo: [mappedToForOrganization].concat(projection.mappedTo),
    };
  };

  const updateMappedToWeightForOrganization = (
    projection: ProjectionRequestDTO,
    value: number,
    organizationalUnitId: string
  ): ProjectionRequestDTO => {
    const mappedToOrganization: ProjectionOrganizationRequestDTO | undefined = projection.mappedTo.find(
      (it) => it.id === organizationalUnitId
    );
    const mappedToOthers: ProjectionOrganizationRequestDTO[] = projection.mappedTo.filter(
      (it) => it.id !== organizationalUnitId
    );

    return mappedToOrganization
      ? {
          ...projection,
          mappedTo: [
            {
              ...mappedToOrganization,
              weight: roundToFourDecimals(value / 100),
            },
          ].concat(mappedToOthers),
        }
      : projection;
  };

  const removeMappedToOrganizationFromProjection = (
    projection: ProjectionRequestDTO,
    organizationalUnitId: string
  ): ProjectionRequestDTO => {
    return {
      ...projection,
      mappedTo: projection.mappedTo.filter((it) => it.id !== organizationalUnitId),
    };
  };

  const searchFn = (row: ProjectionResponseDTO, searchedVal: string) => {
    return (
      row.filter.applicationTag.displayValue.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.filter.account.displayValue.toLowerCase().includes(searchedVal.toLowerCase()) ||
      row.filter.customerTag.displayValue.toLowerCase().includes(searchedVal.toLowerCase())
    );
  };

  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item sm={7}>
        <SearchableTabTree<ProjectionResponseDTO>
          mapDataItemsToTreeListItems={mapProjectionsToTreeListItems}
          searchFn={searchFn}
          tabs={[
            {
              label: "All",
              onSelected: (treeProjections) => treeProjections,
              tooltip:
                "All Tags. The tags available to be applied to this cost center, " +
                "the tags already assigned to this cost center and the tags that are not available.",
            },
            {
              label: "Available",
              onSelected: (treeProjections) =>
                filterAvailableAndNotAssociatedWithOrganizationId(treeProjections, organizationalUnit.id),
              className: classes.available,
              tooltip:
                "Available Tags. The tags available to be applied to this cost center. " +
                "These tags are either not assigned yet, or have weight available to be assigned to this cost center.",
            },
            {
              label: "Assigned",
              onSelected: (treeProjections) =>
                filterProjectionsAssociatedWithOrganizationId(treeProjections, organizationalUnit.id),
              className: classes.selected,
              tooltip: "Assigned Tags. The tags that are fully, or partially assigned to this cost center.",
            },
            {
              label: "Unavailable",
              onSelected: (treeProjections) =>
                filterUnavailableProjectionsWithOrganizationId(treeProjections, organizationalUnit.id),
              className: classes.disabled,
              tooltip:
                "Unavailable Tags. These tags can't be assigned to this cost center. " +
                "Their full weight is assigned to other cost centres or customers.",
            },
            {
              label: "Inactive",
              onSelected: (projections) => projections.filter((it) => !it.active),
              className: classes.inactive,
              tooltip: "Inactive Tags. These tags were once set, but are not available in the line items anymore.",
            },
          ]}
          dataItems={projections || []}
          organizationalUnit={organizationalUnit}
          isLoading={loading}
          actions={[
            {
              actionButtonType: ActionButtonType.ICON_AND_CHIP,
              name: TAG.WEIGHT,
              icon: GenericIcon.ADD_BOX,
              tooltip: TAG.ADD_TAG,
              organizationalUnitId: organizationalUnit.id,
              inputControls: {
                max: (value: ProjectionResponseDTO): number => {
                  return weightAvailableForOrganization(value, organizationalUnit.id);
                },
                init: (value: ProjectionResponseDTO): number => {
                  return weightAvailableForOrganization(value, organizationalUnit.id);
                },
              },
              shouldShow: (projection: ProjectionResponseDTO): boolean => {
                return projectionAvailable(projection) && !projectionAssociated(projection, organizationalUnit.id);
              },

              onClick: (projection, value) => {
                value = Number(value);
                if (!loading && value > 0) {
                  setLoading(true);
                  updateProjection(addMappedToForOrganization(projection, value, organizationalUnit.id))
                    .then(refreshProjections)
                    .finally(() => setLoading(false));
                }
              },
            },
          ]}
        />
      </Grid>
      <Grid item sm={5} classes={{ item: classes.associatedTree }}>
        <SearchableTabTree<ProjectionResponseDTO>
          mapDataItemsToTreeListItems={mapProjectionsToTreeListItems}
          searchFn={searchFn}
          tabs={[
            {
              label: `Tags assigned to "${organizationalUnit.name}"`,
              onSelected: (treeProjections) => treeProjections,
              className: classes.available,
              tooltip: `Assigned Tags. The tags that are fully, or partially assigned to the cost center "${organizationalUnit.name}"`,
            },
          ]}
          dataItems={selectedProjections}
          organizationalUnit={organizationalUnit}
          isLoading={loading}
          actions={[
            {
              name: TAG.WEIGHT,
              tooltip: TAG.EDIT_WEIGHT,
              actionButtonType: ActionButtonType.CHIP,
              organizationalUnitId: organizationalUnit.id,
              selected: (value: ProjectionResponseDTO): boolean => projectionAssociated(value, organizationalUnit.id),
              inputControls: {
                max: (value: ProjectionResponseDTO): number => {
                  return weightAvailableForOrganization(value, organizationalUnit.id);
                },
                init: (value: ProjectionResponseDTO): number => {
                  return mappedToWeightForOrganization(value, organizationalUnit.id);
                },
              },
              shouldShow: (projection: ProjectionResponseDTO): boolean => {
                return projectionAssociated(projection, organizationalUnit.id);
              },
              onClick: (projection, value) => {
                value = Number(value);
                if (!loading && value > 0) {
                  setLoading(true);
                  updateProjection(updateMappedToWeightForOrganization(projection, value, organizationalUnit.id))
                    .then(refreshProjections)
                    .finally(() => setLoading(false));
                }
              },
            },
            {
              name: TAG.REMOVE_TAG,
              tooltip: TAG.REMOVE_TAG,
              actionButtonType: ActionButtonType.ICON,
              organizationalUnitId: organizationalUnit.id,
              icon: GenericIcon.DELETE,
              shouldShow: (projection: ProjectionResponseDTO): boolean => {
                return projectionAssociated(projection, organizationalUnit.id);
              },
              onClick: (projection) => {
                if (!loading) {
                  setLoading(true);
                  updateProjection(removeMappedToOrganizationFromProjection(projection, organizationalUnit.id))
                    .then(refreshProjections)
                    .finally(() => setLoading(false));
                }
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
