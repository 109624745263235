import { DialogProps } from "../../../hooks/dialog.hooks";
import { Dialog } from "@material-ui/core";
import { OrganizationalUnitContactFormCreate } from "../forms/OrganizationalUnitContactFormCreate";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

interface OrganizationalUnitContactDialogCreateProps extends DialogProps {
  organizationalUnit: OrganizationalUnitResponseDTO;
  onSuccess?: () => void;
  onError?: () => void;
}

export const OrganizationalUnitContactDialogCreate = ({
  organizationalUnit,
  open,
  onClose,
  onSuccess,
  onError,
}: OrganizationalUnitContactDialogCreateProps) => {
  return (
    <>
      <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
        <OrganizationalUnitContactFormCreate
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          organizationalUnit={organizationalUnit}
        />
      </Dialog>
    </>
  );
};
