import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { BudgetAlertInlineEditForm } from "./BudgetAlertInlineEditForm";
import { formattedCurrency } from "../../../../utils/localization.utils";
import { GenericIcon } from "../../../../theme/Icon";
import { ActionIconButton } from "../../../../components/buttons/ActionIconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import { BudgetAlertResponseDTO, BudgetResponseDTO, CreateBudgetAlertRequestDTO } from "../../../../open-api";

interface BudgetAlertInlineEditBudgetAlertProps {
  budget: BudgetResponseDTO;
  alert?: BudgetAlertResponseDTO;
  setEditFn: (id: string, editing: boolean) => void;
  isEditFn: (id: string) => boolean;
  onSave: (form: CreateBudgetAlertRequestDTO) => void;
  onDelete?: () => void;
  onRowClick?: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertBox: {
      marginRight: theme.spacing(2),
    },
    displayAlert: {
      fontSize: 17,
      fontWeight: "bold",
    },
    displayAlertSecondary: {
      marginLeft: 12,
      flexGrow: 1,
      fontSize: 16,
    },
    alertReachedIcon: {
      marginLeft: theme.spacing(2),
    },
    inputThreshold: {
      marginBottom: 0,
      marginTop: 6,
      width: 160,
    },
    thresholdPreviewBox: {
      width: 154,
      paddingLeft: 12,
    },
    thresholdPreviewBudget: {
      fontSize: 16,
    },
    thresholdPreviewValue: {
      fontSize: 14,
    },
  })
);

export function BudgetAlertInlineEditBudgetAlert({
  budget,
  alert,
  setEditFn,
  isEditFn,
  onSave,
  onDelete,
  onRowClick,
}: BudgetAlertInlineEditBudgetAlertProps) {
  const {
    handleSubmit,
    control,
    watch,

    formState: { errors },
  } = useForm<CreateBudgetAlertRequestDTO>({
    mode: "onTouched",
    defaultValues: { thresholdValue: alert?.thresholdValue || 0 },
  });

  const watchThresholdValue = watch("thresholdValue");
  const classes = useStyles();

  return (
    <BudgetAlertInlineEditForm<CreateBudgetAlertRequestDTO>
      isEditFn={isEditFn}
      setEditFn={setEditFn}
      content={
        !!alert ? (
          <Box display={"flex"} flexDirection={"row"} className={classes.alertBox}>
            <Typography classes={{ root: classes.displayAlert }}>
              {`${alert?.thresholdValue}%`} (
              {formattedCurrency((watchThresholdValue * budget.value) / 100, budget.currency, 0)})
            </Typography>

            <Typography onClick={onRowClick} classes={{ root: classes.displayAlertSecondary }} color={"textSecondary"}>
              {`${
                !!alert?.notifications?.email && alert.notifications.email.length > 0
                  ? "Configured e-mails addresses will be notified"
                  : "No e-mail addresses configured"
              }`}
            </Typography>
            {alert.recentStatus?.triggeredOn != null && (
              <ActionIconButton
                buttonSize={"small"}
                color={"error"}
                size={22}
                className={classes.alertReachedIcon}
                action={{
                  icon: GenericIcon.ERROR_OUTLINE,
                  tooltip: `Alert was triggered on ${DateTime.fromISO(alert.recentStatus.triggeredOn).toLocaleString(
                    DateTime.DATETIME_MED
                  )}`,
                  onClick: (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                  },
                }}
              />
            )}
          </Box>
        ) : (
          <Typography color={"textSecondary"}>Add an alert</Typography>
        )
      }
      onSave={onSave}
      onDelete={onDelete}
      handleSubmit={handleSubmit}
      initValue={alert?.thresholdValue?.toString()}
      formKey={`budget-alert-inline-budget-alert-form-${alert?.id}`}
    >
      <>
        <Controller
          render={({ field: { onChange, onBlur, value, name } }) => (
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              name={name}
              label={"Threshold value"}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.thresholdValue}
              helperText={errors.thresholdValue?.message}
              variant={"outlined"}
              margin={"dense"}
              classes={{ root: classes.inputThreshold }}
            />
          )}
          name={"thresholdValue"}
          control={control}
          rules={{
            required: "A value is required",
            max: {
              value: 10 * 1000,
              message: "Value is too large",
            },
            pattern: {
              value: /^[1-9][0-9]*$/,
              message: "Invalid value",
            },
          }}
        />
        <Box display={"inline-flex"} flexDirection={"column"} className={classes.thresholdPreviewBox}>
          <Typography variant={"h6"} classes={{ root: classes.thresholdPreviewBudget }}>
            of {formattedCurrency(budget.value, budget.currency, 0)}
          </Typography>
          <Typography color={"textSecondary"} classes={{ root: classes.thresholdPreviewValue }}>
            ({formattedCurrency((watchThresholdValue * budget.value) / 100, budget.currency, 0)})
          </Typography>
        </Box>
      </>
    </BudgetAlertInlineEditForm>
  );
}
