import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ControlledSelectSingleField, ControlledTextField } from "../../../components/form/FormComponents";
import { Control } from "react-hook-form/dist/types";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

interface UserEditFormProps {
  control: Control<{ name: string; role: "ADMIN" | "READ" }>;
  canEditRole: boolean;
}

export function UserEditForm({ control, canEditRole = true }: UserEditFormProps) {
  const classes = useStyles();
  const options = [
    {
      value: "ADMIN",
      label: "Admin",
    },
    {
      value: "READ",
      label: "Read",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} className={classes.container}>
        <ControlledTextField control={control} name="name" label={"Name"} />
      </Grid>
      {canEditRole && (
        <Grid item xs={12} className={classes.container}>
          <ControlledSelectSingleField options={options} control={control} name="role" label={"Role"} />
        </Grid>
      )}
    </Grid>
  );
}
