import { DialogProps } from "../../../../hooks/dialog.hooks";
import { UnitLineItemTable } from "../../components/UnitLineItemTable";
import { FullScreenDialog } from "../../../../components/dialogs/FullScreenDialog";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNMostRecentPeriods } from "../../../../clients/lineitems.client";
import { mapBillingPeriod } from "../../../../mappers/lineitem.mapper";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";

interface DialogCViewProps extends DialogProps {
  organization: OrganizationalUnitResponseDTO;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    flexGrow: 0,
    maxWidth: "100%",
    flexBasis: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

export const DialogCView = ({ organization, open, onClose }: DialogCViewProps) => {
  const classes = useStyles();

  const period = useNMostRecentPeriods()[0];

  return (
    <FullScreenDialog
      title={`C-View for ${organization.name} (${mapBillingPeriod(period)})`}
      open={open}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <UnitLineItemTable selected={organization} stretchToParentHeight={true} />
      </Box>
    </FullScreenDialog>
  );
};
