import {useDataClientPromise} from "../../hooks/client.hooks";
import {FormControlLabel, Grid, Switch} from "@material-ui/core";
import {FormTitleField} from "../../components/form/FormTitleField";
import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../services/context.service";
import {ForceMFADTOTypeEnum} from "../../open-api";
import {messageError} from "../../services/message.service";

interface ForceMFAProps {}

export function ForceMFA(props: ForceMFAProps) {

  const mfaService = useServices().mfa;
  const [forcedMFAInternal, refreshForcedMFAInternal] = useDataClientPromise(mfaService.isWorkspaceMFAForced, [ForceMFADTOTypeEnum.USER]);
  const [forcedInternal, setForcedInternal] = useState(false);
  const [forcedMFAManaged, refreshForcedMFAManaged] = useDataClientPromise(mfaService.isWorkspaceMFAForced, [ForceMFADTOTypeEnum.MANAGED]);
  const [forcedManaged, setForcedManaged] = useState(false);

  const handleInternalChange = (e: any) => {
    forcedInternal ?
        mfaService.disableForcedMFA(ForceMFADTOTypeEnum.USER)
        .then(() => {refreshForcedMFAInternal()})
        .catch((e) => messageError(`Failed to disable forced MFA for internal users: ${e}`))
        : mfaService.enableForcedMFA(ForceMFADTOTypeEnum.USER)
        .then(() => {refreshForcedMFAInternal()})
        .catch((e) => messageError(`Failed to enable forced MFA for internal users: ${e}`))

  };

  const handleManagedChange = (e: any) => {
    forcedManaged ?
        mfaService.disableForcedMFA(ForceMFADTOTypeEnum.MANAGED)
        .then(() => {refreshForcedMFAManaged()})
        .catch((e) => messageError(`Failed to disable forced MFA for managed users: ${e}`))
        : mfaService.enableForcedMFA(ForceMFADTOTypeEnum.MANAGED)
        .then(() => {refreshForcedMFAManaged()})
        .catch((e) => messageError(`Failed to enable forced MFA for managed users: ${e}`))
  }

  useEffect(() => {
    setForcedInternal(forcedMFAInternal === "true");
  }, [forcedMFAInternal]);

  useEffect(() => {
    setForcedManaged(forcedMFAManaged === "true");
  }, [forcedMFAManaged]);

  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTitleField text="Force Two Factor Authentication" />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
                control={<Switch color="primary" checked={forcedInternal} onChange={handleInternalChange} />}
                label={"For internal users"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
                control={<Switch color="primary" checked={forcedManaged} onChange={handleManagedChange} />}
                label={"For managed C-Views"}
            />
          </Grid>
        </Grid>
      </>
  );
}
