import { OrganizationalUnitResponseDTO } from "../open-api";
import { API } from "./context.service";

export interface OrganizationalUnitsService {
  getAll(): Promise<OrganizationalUnitResponseDTO[]>;
}

export class LiveOrganizationalUnitsService implements OrganizationalUnitsService {
  constructor(private api: API) {}
  getAll = () => this.api.organizationalUnits.listRootOrganizations();
}

export class TestOrganizationalUnitsService implements OrganizationalUnitsService {
  getAll = async () => {
    const mock: OrganizationalUnitResponseDTO[] = [
      {
        id: "0",
        name: "Puma",
        type: "INTERNAL",
        unitType: "ROOT",
        reference: "Puma",
        projections: [],
        portals: [],
        basePrice: "INVOICED",
      },
      {
        id: "1",
        name: "Converse",
        type: "CUSTOMER",
        unitType: "ROOT",
        reference: "Converse",
        projections: [],
        portals: [],
        grossType: "MARGIN",
        basePrice: "INVOICED",
      },
    ];

    return mock;
  };
}
