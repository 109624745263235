import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { CFactsPivotGridDataSourceOptions, previous12Months } from "../../consumption/PivotChart";
import { useCurrency } from "../../../hooks/config.hooks";
import PivotGrid from "devextreme-react/pivot-grid";
import { Arr } from "../../../utils/functional/array.utils";
import { attributeId } from "../model/Attribute";
import { CircularProgress } from "@material-ui/core";
import { absoluteCenter } from "../../../utils/styles";
import { css } from "@emotion/css";
import { useDashCharts } from "../selectors/dashboard.selector";
import { PieChartConfig, pieChartTypes, RangeChartConfig, rangeChartTypes } from "../model/ChartConfig";
import { useLineItemsForPivot } from "../model/Query";

export const DashPivotGrid = () => {
  const currency = useCurrency();
  const [charts] = useDashCharts();

  const segments = Arr.distinctBy(
    charts.filter((it): it is PieChartConfig | RangeChartConfig =>
      [...pieChartTypes, ...rangeChartTypes].some((type) => type === it.type)
    ),
    (a, b) => attributeId(a.groupSegment) === attributeId(b.groupSegment)
  ).map((it) => it.groupSegment.id);

  const [filteredData, , loading] = useLineItemsForPivot(segments, ["COST", "SALES"]);

  const options: CFactsPivotGridDataSourceOptions = {
    fields: [
      ...(charts.length > 0
        ? Arr.distinctBy(
            charts.filter((it): it is PieChartConfig | RangeChartConfig =>
              [...pieChartTypes, ...rangeChartTypes].some((type) => type === it.type)
            ),
            (a, b) => attributeId(a.groupSegment) === attributeId(b.groupSegment)
          ).map(
            (it) =>
              ({
                caption: it.groupSegment.label,
                dataField: it.groupSegment.id,
                area: "row",
                sortBySummaryField: "Total",
              } as const)
          )
        : []),
      {
        dataField: "date",
        dataType: "date",
        area: "column",
        expanded: true,
        filterValues: previous12Months,
      },
      {
        caption: "Cost",
        dataField: "COST",
        dataType: "number",
        summaryType: "sum",
        format: {
          type: "currency",
          currency,
          precision: 2,
        },
        area: "data",
        displayFolder: "Spending",
      },
      {
        caption: "Sales",
        dataField: "sales",
        dataType: "number",
        summaryType: "sum",
        format: {
          type: "currency",
          currency,
          precision: 2,
        },
        displayFolder: "Spending",
      },
      {
        caption: "Profit",
        dataField: "profit",
        dataType: "number",
        summaryType: "sum",
        format: {
          type: "currency",
          currency,
          precision: 2,
        },
        displayFolder: "Spending",
      },
      {
        caption: "Quantity",
        dataField: "quantity",
        dataType: "string",
        visible: false,
      },
    ],
    store: filteredData ?? [],
  };

  const dataSource = new PivotGridDataSource(options);

  return (
    <div className={css({ position: "relative" })}>
      {loading && (
        <div className={css(absoluteCenter)}>
          <CircularProgress size={45} />
        </div>
      )}
      <PivotGrid
        id="pivotgrid"
        disabled={loading}
        dataSource={dataSource}
        allowSortingBySummary={true}
        allowSorting={true}
        allowFiltering={false}
        showBorders={true}
        showColumnTotals={false}
        showColumnGrandTotals={true}
        showRowTotals={false}
        showRowGrandTotals={true}
        showTotalsPrior={"rows"}
        // stateStoring={{ enabled: true, type: "sessionStorage", storageKey }}
        fieldChooser={{ enabled: false }}
        fieldPanel={{ showDataFields: false, allowFieldDragging: true, visible: true }}
        export={{ enabled: true }}
      />
    </div>
  );
};
