import { useEffect, useState } from "react";

export const useRefresh = (shouldRefresh: boolean, refreshFunction: () => any, timeBetweenRefresh = 5) => {
  const refreshTimeMilliseconds = 1000;
  const refreshInit = timeBetweenRefresh * refreshTimeMilliseconds;
  const [refreshTimer, setRefreshTimer] = useState(refreshInit);

  useEffect(() => {
    if (!shouldRefresh) {
      return;
    }
    const interval = setInterval(() => {
      setRefreshTimer((refreshTimer) => {
        if (refreshTimer <= 0) {
          if (isVisible()) {
            refreshFunction();
          }
          setRefreshTimer(refreshInit);
        }
        return refreshTimer - refreshTimeMilliseconds;
      });
    }, refreshTimeMilliseconds);
    return () => {
      clearInterval(interval);
    };
  }, [shouldRefresh, refreshInit, refreshFunction]);

  return [refreshTimer / refreshTimeMilliseconds];
};

const isVisible = () => typeof document !== "undefined" && document.visibilityState === "visible";
