import { z } from "zod";
import { FormPartial } from "../../../../utils/form.utils";
import { ControlledTextField } from "../../../../components/form/FormComponents";
import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";

interface OCICredentialsFormPartialProps extends Required<FormPartial<z.infer<typeof ociSchema>>> {}

export const ociSchema = z.object({
  user: z.string().min(1),
  tenancy: z.string(),
  region: z.string(),
  apiKey: z.string().min(1),
  fingerprint: z.string().min(1),
});

export const OCICredentialsFormPartial = ({ control, formMethods }: OCICredentialsFormPartialProps) => {
  const { setValue } = formMethods;
  useEffect(() => {
    console.log("setValue is called");
    setValue("apiKey", "");
    setValue("fingerprint", "");
  }, [setValue]);
  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name={"user"}
          label={"User ID"}
          placeholder="ocid1.user.oc1..aa**a"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name={"tenancy"}
          label={"Tenancy ID"}
          placeholder="ocid1.tenancy.oc1..aa***q"
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name={"region"}
          label={"region"}
          placeholder={"eu-frankfurt-1"}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name={"apiKey"}
          label={"Private key"}
          placeholder="-----BEGIN RSA PRIVATE KEY-----
MIIE***
*******
***Sgo=
-----END RSA PRIVATE KEY-----
    "
          fullWidth
          multiline
          rows={5}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name={"fingerprint"}
          label={"Fingerprint"}
          placeholder="00:***:ee:ff"
          fullWidth
        />
      </Grid>
    </>
  );
};
