import { fade, makeStyles } from "@material-ui/core/styles";
import { Box, Collapse, Theme, Tooltip, Typography } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import { TransitionProps } from "@material-ui/core/transitions";
import { animated, useSpring } from "react-spring";
import { CFactsIcon, Icon } from "../../../../theme/Icon";
import { SearchableTagTreeAction } from "./SearchableTabTree";
import { TagActionButton } from "./buttons/TagActionButton";
import { useState } from "react";
import { css } from "@emotion/css";

const useStylesTreeItem = makeStyles((theme: Theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
    marginRight: 6,
  },
  group: {
    marginLeft: theme.spacing(1),
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  content: {
    paddingLeft: theme.spacing(0),
  },
  action: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    display: "flex",
  },
  container: {
    width: "unset",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  inactive: {
    textDecoration: "line-through",
  },
  selected: {
    color: theme.palette.primary.light,
  },
  available: {
    color: theme.palette.text.primary,
  },
  treeItemLabel: {
    width: `calc(100% - 15px)`,
    height: theme.spacing(4) - 2,
    display: "flex",
    alignItems: "center",
  },
  label: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
  },
  rootBox: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  tooltip: {
    maxWidth: 600,
  },
  tooltipBody: {
    fontSize: 15,
    whiteSpace: "pre-line",
  },
}));

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

export interface TreeListItemProps<T> {
  nodeId: string;
  label: string;
  data: {
    item: T;
    filterKey?: string;
  };
  actions: SearchableTagTreeAction<T>[];
  tooltip?: string;
  icon?: CFactsIcon;
  selected?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  children?: TreeListItemProps<T>[];
}

interface TreeItemStyledProps<T> {
  node: TreeListItemProps<T>;
  overrideRootClass?: string;
}

const SHOW_MORE_STEP = 30;

export function TreeItemStyled<T>({ node, overrideRootClass }: TreeItemStyledProps<T>) {
  const classes = useStylesTreeItem();
  const [showChildCount, setShowChildCount] = useState(SHOW_MORE_STEP);

  let rootClass = node.selected ? classes.selected : node.disabled ? classes.disabled : classes.available;

  rootClass = node.actions.find((it) => {
    return it.selected && it.selected(node.data.item);
  })
    ? classes.selected
    : rootClass;

  return (
    <TreeItem
      nodeId={node.nodeId}
      classes={{
        group: classes.group,
        content: classes.content,
        iconContainer: classes.iconContainer,
        root: (overrideRootClass ? rootClass : "") + " " + (node.inactive ? classes.inactive : ""),
        label: classes.treeItemLabel,
      }}
      style={{}}
      label={
        <Box className={classes.rootBox}>
          {node.icon ? <Icon icon={node.icon} className={classes.icon} size={"small"} /> : ""}
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            arrow
            title={
              node.tooltip ? (
                <>
                  <Typography classes={{ root: classes.tooltipBody }}>{node.tooltip}</Typography>
                </>
              ) : (
                ""
              )
            }
          >
            <Box className={classes.label}>{node.label}</Box>
          </Tooltip>

          <Box className={classes.action}>
            {node.actions &&
              node.actions.map((action) => {
                return action.shouldShow(node.data.item) ? (
                  <TagActionButton
                    key={`action-button-${node.nodeId}-${action.tooltip}`}
                    action={action}
                    data={node.data}
                    label={node.label}
                    color={action.selected && action.selected(node.data.item) ? "primary" : "default"}
                    chipStyle={action.chipStyle}
                  />
                ) : (
                  ""
                );
              })}
          </Box>
        </Box>
      }
      TransitionComponent={TransitionComponent}
    >
      {node.children && (
        <>
          {node.children &&
            node.children
              .sort((a, b) => a.label.localeCompare(b.label))
              .slice(0, showChildCount)
              .map((item) => <TreeItemStyled overrideRootClass={overrideRootClass} key={item.nodeId} node={item} />)}
          {node.children && node.children.length > showChildCount && (
            <span
              className={css({
                cursor: "pointer",
                padding: "16px",
                "&:hover": {
                  fontWeight: "bold",
                },
              })}
              onClick={() => setShowChildCount((it) => it + SHOW_MORE_STEP)}
            >
              + Show {Math.min(SHOW_MORE_STEP, node.children.length - showChildCount)} more (of{" "}
              {node.children.length - showChildCount}) (or filter by searching)
            </span>
          )}
        </>
      )}
    </TreeItem>
  );
}
