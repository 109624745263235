/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    AttributeHierarchyResponseDTO,
    AttributeResponseDTO,
    UpdateAttributeDisplayStateRequestDTO,
} from '../models';

export interface GetAttributesForCollectorRequest {
    collectorId: string;
}

export interface UpdateStateRequest {
    updateAttributeDisplayStateRequestDTO: UpdateAttributeDisplayStateRequestDTO;
}

/**
 * 
 */
export class AttributesControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAttributesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AttributeResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getAttributes(initOverrides?: RequestInit): Promise<Array<AttributeResponseDTO>> {
        const response = await this.getAttributesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAttributesAsStreamRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/attributes/stream`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getAttributesAsStream(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getAttributesAsStreamRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAttributesForCollectorRaw(requestParameters: GetAttributesForCollectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AttributeResponseDTO>>> {
        if (requestParameters.collectorId === null || requestParameters.collectorId === undefined) {
            throw new runtime.RequiredError('collectorId','Required parameter requestParameters.collectorId was null or undefined when calling getAttributesForCollector.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/attributes/collector/{collectorId}`.replace(`{${"collectorId"}}`, encodeURIComponent(String(requestParameters.collectorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getAttributesForCollector(requestParameters: GetAttributesForCollectorRequest, initOverrides?: RequestInit): Promise<Array<AttributeResponseDTO>> {
        const response = await this.getAttributesForCollectorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAttributesHierarchyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AttributeHierarchyResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/attributes/hierarchy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getAttributesHierarchy(initOverrides?: RequestInit): Promise<Array<AttributeHierarchyResponseDTO>> {
        const response = await this.getAttributesHierarchyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStateRaw(requestParameters: UpdateStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateAttributeDisplayStateRequestDTO === null || requestParameters.updateAttributeDisplayStateRequestDTO === undefined) {
            throw new runtime.RequiredError('updateAttributeDisplayStateRequestDTO','Required parameter requestParameters.updateAttributeDisplayStateRequestDTO was null or undefined when calling updateState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/attributes/update-state`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateAttributeDisplayStateRequestDTO,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateState(requestParameters: UpdateStateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateStateRaw(requestParameters, initOverrides);
    }

}
