import { Tooltip } from "@material-ui/core";
import { css } from "@emotion/css";
import { DateTime } from "luxon";

interface BillingPeriodProps {
  billingPeriod: string;
  full?: boolean;
}

const DATEFORMAT = {
  FULL_WITH_YEAR: "dd MMM, yyyy",
  FULL_WITHOUT_YEAR: "dd MMM",
  SHORT_WITH_YEAR: "MM/dd/yy",
  SHORT_WITHOUT_YEAR: "MM/dd",
  YEARMONTH: "yyyy-MM",
  YEARMONTH_FULL: "MMMM yyyy",
};

export const BillingPeriod = ({ billingPeriod, full = false, ...props }: BillingPeriodProps) => {
  if (/^\d{4}-\d{2}$/g.test(billingPeriod)) {
    // yearmonth
    return (
      <span className={css({ whiteSpace: "nowrap" })}>
        {DateTime.fromFormat(billingPeriod, DATEFORMAT.YEARMONTH).toFormat(DATEFORMAT.YEARMONTH_FULL)}
      </span>
    );
  }

  const split = billingPeriod.split("|");
  if (split.length === 2) {
    // billing period
    const [from, to] = split.map((it) => DateTime.fromISO(it));
    const showFromYear = from.year !== to.year;

    const fullTitle = (
      <span>
        {from.toFormat(showFromYear ? DATEFORMAT.FULL_WITH_YEAR : DATEFORMAT.FULL_WITHOUT_YEAR)}
        &nbsp;&mdash;&nbsp;
        {to.toFormat(DATEFORMAT.FULL_WITH_YEAR)}
      </span>
    );
    if (full) {
      return fullTitle;
    }

    return (
      <Tooltip style={{ cursor: "pointer" }} title={fullTitle}>
        <span className={css({ whiteSpace: "nowrap", fontStyle: "italic" })}>
          {from.toFormat(DATEFORMAT.FULL_WITH_YEAR)}
        </span>
      </Tooltip>
    );
  }

  return null;
};
