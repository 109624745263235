import { Skeleton } from "@material-ui/lab";
import { FC } from "react";

export const GeneralPageSkeleton: FC = () => {
  return (
    <>
      <Skeleton width={500} height={80} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
};
