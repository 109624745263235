import Dialog from "@material-ui/core/Dialog";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import { InvoiceResponseDTO } from "../../../open-api";
import { useApiCall, useDataClientPromise } from "../../../hooks/client.hooks";
import { DIMENSIONS } from "../../../clients/lineitems.client";
import { useServices } from "../../../services/context.service";
import { FormAutocompleteFilter, useFilterState } from "../../../components/form/FormAutocompleteFilter";
import { Button, FormControlLabel, FormHelperText, Grid, Switch } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { prepareDownload } from "../../../clients/invoices.client";
import { messageError, messageSuccess } from "../../../services/message.service";
import { csvRequest } from "../../../utils/api.utils";

interface DownloadReconDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: InvoiceResponseDTO;
}

export function DownloadReconDialog({ open, onClose, invoice }: DownloadReconDialogProps) {
  const queryService = useServices().aggregate;
  const filterState = useFilterState();
  const [useFilter, setUseFilter] = useState(false);
  const [useRates, setUseRates] = useState(false);

  const [filterOptions] = useDataClientPromise(
    queryService.options,
    [
      DIMENSIONS.ORGANIZATION,
      [
        {
          dimension: "INVOICE",
          filters: [invoice.id],
        },
      ],
    ],
    {
      useCacheKey: "options",
      shouldLoad: open,
      onSuccess: (res) => {
        // should not be able to filter by Unassigned
        let options = res.options.filter((it) => it.id !== "<null>");
        return options
          .map((it) => ({ key: it.id, label: it.display || it.id }))
          .sort((a, b) => a.label.localeCompare(b.label));
      },
    }
  );

  const [handlePrepare, loading] = useApiCall(prepareDownload);

  const canSubmit = !useFilter || filterState[0] !== null;
  const directDownload = !useFilter && !useRates;
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (directDownload) {
      // Since file already exists, it can be downloaded directly
      csvRequest(`/api/invoices/${invoice.id}/recon-csv`).then(() => {
        handleClose();
      });
    } else {
      handlePrepare(invoice.id, useRates, filterState[0]?.key)
        .then(() => {
          messageSuccess("We are preparing your download. You will receive an email shortly!");
          handleClose();
        })
        .catch((err) => {
          messageError("Something went wrong while trying to prepare your download. Please try again.");
        });
    }
  };
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitleCloseable onClose={handleClose}>Download recon file for invoice {invoice.name}</DialogTitleCloseable>
      <DialogContent dividers={true}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormTitleField text={"Filter settings"} />
            <FormControlLabel
              label={"Only include line items for a specific organization/customer/reseller"}
              control={
                <Switch
                  checked={useFilter}
                  onChange={() => {
                    if (useFilter) {
                      filterState[1](null);
                    }
                    setUseFilter((it) => !it);
                  }}
                  name={"useFilter"}
                  id={"useFilter"}
                  color={"primary"}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormAutocompleteFilter filterState={filterState} filterOptions={filterOptions} disabled={!useFilter} />
          </Grid>
          <Grid item xs={12}>
            <FormTitleField text={"Recon file price settings"} />
            <FormControlLabel
              label={"Create a recon file based on sales/cross-charging"}
              control={
                <Switch
                  checked={useRates}
                  onChange={() => {
                    setUseRates((it) => !it);
                  }}
                  name={"useRates"}
                  id={"useRates"}
                  color={"primary"}
                />
              }
            />
            {!directDownload && (
              <FormHelperText>
                After clicking 'prepare download', you will receive an email with the URL to download your file when it
                is ready.
              </FormHelperText>
            )}
          </Grid>
          <Grid item container xs={12} justifyContent={"flex-end"}>
            <Button onClick={handleSubmit} disabled={loading || !canSubmit} color={"primary"} variant={"contained"}>
              {canSubmit ? `${directDownload ? "Download" : "Prepare download"}` : "Configure filter"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
