import { InvoiceResponseDTO } from "../open-api";
import { useInvoices } from "../clients/invoices.client";
import { createContext, FC } from "react";

interface AppState {
  invoices: InvoiceResponseDTO[] | null;
}

export const AppStateContext = createContext<AppState>(null!);

/**
 * State that does not change during a single session in application (such as invoices)
 * @param children
 * @constructor
 */
export const StateProvider: FC = ({ children }) => {
  // This can be used to determine correct billing periods when using invoices.
  const [invoices] = useInvoices();

  return (
    <AppStateContext.Provider
      value={{
        invoices,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
