import { AccordionItem, AccordionItemList } from "../../../../components/accordion/AccordionItemList";
import { useProjectionsForOrganization } from "../../../../clients/projections.client";
import { Grid } from "@material-ui/core";
import { useShowDialog } from "../../../../hooks/dialog.hooks";
import { GenericIcon } from "../../../../theme/Icon";
import { FilterChips } from "../../components/FilterChips";
import { useCanEdit } from "../../../../hooks/auth.hooks";
import { OrganizationalUnitTagsFormEdit } from "../../forms/OrganizationalUnitTagsFormEdit";
import { FullScreenDialog } from "../../../../components/dialogs/FullScreenDialog";
import { OrganizationalUnitResponseDTO } from "../../../../open-api";

interface AccordionTagsProps {
  organization: OrganizationalUnitResponseDTO;
  onChange: () => void;
}

export const AccordionTags = ({ organization, onChange }: AccordionTagsProps) => {
  const [showEdit, setShowEdit] = useShowDialog();

  const [projections, refreshProjections] = useProjectionsForOrganization(organization.id);

  const indirect = projections ? projections.filter((it) => it.mappedTo[0].id !== organization.id) : [];
  const direct = projections ? projections.filter((it) => it.mappedTo[0].id === organization.id) : [];

  const card: AccordionItem = {
    id: "data",
    summaryMain: "Assigned tags",
    summarySub:
      projections && projections.length > 0
        ? `${direct.length} tags assigned ${indirect.length > 0 ? `(${indirect.length} indirect)` : ""}`
        : "No data assigned",
    action: useCanEdit() && {
      icon: GenericIcon.EDIT,
      tooltip: "Edit assigned data",
      onClick: () => setShowEdit(true),
    },
    details: (
      <Grid container>
        <FilterChips organizationId={organization.id} />
      </Grid>
    ),
  };

  return (
    <>
      <AccordionItemList item={card} />
      {organization && showEdit && (
        <FullScreenDialog
          title={`View and edit tags for the "${organization.name}" cost center`}
          open={showEdit}
          onClose={() => {
            onChange();
            refreshProjections();
            setShowEdit(false);
          }}
        >
          <OrganizationalUnitTagsFormEdit organizationalUnit={organization} />
        </FullScreenDialog>
      )}
    </>
  );
};
