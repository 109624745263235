import { useServices } from "../../../services/context.service";
import { useDataClientPromise } from "../../../hooks/client.hooks";
import { useState } from "react";
import {
  ADJUSTMENT,
  adjustmentFromPriceAdjustment,
  AdjustmentType,
  labelForType,
  mapAllPriceAdjustmentsToTreeListItems,
} from "../../../utils/functional/adjustments.utils";
import { CFCard } from "../../../components/layout/CFContainer";
import { TreeView } from "../../organizationalunit/organization/components/TreeView";
import { css } from "@emotion/css";
import { FullScreenDialog } from "../../../components/dialogs/FullScreenDialog";
import { Box, Typography } from "@material-ui/core";
import { PriceAdjustmentsFormEdit } from "../../organizationalunit/forms/PriceAdjustmentsFormEdit";
import { SearchableTagTreeAction } from "../../organizationalunit/components/tags/SearchableTabTree";
import { PriceAdjustments } from "../../../model/PriceAdjustments";
import { ucFirst } from "../../../utils/localization.utils";
import { mapGrossType } from "../../../mappers/organization.mapper";
import { ActionButtonType } from "../../organizationalunit/components/tags/buttons/TagActionButton";
import { costPriceInfo } from "../../../texts/price-adjustment.text";

export function CollectorPriceAdjustments({ collectorId }: { collectorId: string }) {
  const services = useServices();
  const [priceAdjustments, refreshPriceAdjustments, isLoading] = useDataClientPromise(
    services.collectorPriceAdjustments.getAll,
    [{ collectorId, includeAvailable: false }]
  );

  const [isEditing, setIsEditing] = useState(false);

  if (priceAdjustments == null) return null;

  const [treeItems, nodeIds] = mapAllPriceAdjustmentsToTreeListItems(
    priceAdjustments,
    [AdjustmentType.MARKUP, AdjustmentType.DISCOUNT].map((it) => adjustmentAction(collectorId, it))
  );

  return (
    <CFCard header={"Cost price calculation"} actions={{ edit: !isEditing ? () => setIsEditing(true) : undefined }}>
      {priceAdjustments.length > 0 ? (
        <>
          <Box marginBottom={2}>{costPriceInfo}</Box>
          <TreeView nodeIds={nodeIds} isLoading={isLoading} items={treeItems} className={css({ width: 800 })} />
        </>
      ) : (
        `No cost calculation configured`
      )}

      <FullScreenDialog
        title={`Configure cost price calculation`}
        open={isEditing}
        onClose={() => {
          refreshPriceAdjustments();
          setIsEditing(false);
        }}
        maxWidth={"1200px"}
      >
        <Box margin={2} marginBottom={0}>
          <Typography color={"textSecondary"}>{costPriceInfo}</Typography>
        </Box>
        <PriceAdjustmentsFormEdit config={{ type: "collector", id: collectorId }} />
      </FullScreenDialog>
    </CFCard>
  );
}

const adjustmentAction = (id: string, adjustmentType: AdjustmentType): SearchableTagTreeAction<PriceAdjustments> => {
  return {
    name: adjustmentType,
    icon: ADJUSTMENT.ICON[adjustmentType],
    labelFn: labelForType,
    tooltip: ucFirst(mapGrossType(adjustmentType)),
    actionButtonType: ActionButtonType.CHIP_NO_CLICK,
    organizationalUnitId: id,
    inputControls: {
      init: (adjustment: PriceAdjustments): number => {
        return adjustmentFromPriceAdjustment(adjustment, adjustmentType) ?? 0;
      },
      max: (value: PriceAdjustments) => ADJUSTMENT.MAX_VALUE,
    },
    shouldShow: () => true,
    selected: (value: PriceAdjustments): boolean =>
      !!value.adjustments[adjustmentType] && !value.adjustments[adjustmentType]?.applied.inherited,
    chipStyle: { minWidth: "120px", margin: "0px 8px" },
  };
};
