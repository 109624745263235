import { AttributeResponseDTO, UpdateStateRequest } from "../open-api";
import { extendable, Extendable } from "./test.service";
import { attributesMock } from "../pages/newdash/model/Attribute.mock";
import { API } from "./context.service";

export interface AttributeService {
  getAll(): Promise<AttributeResponseDTO[]>;
  getForCollector(collectorId: string): Promise<AttributeResponseDTO[]>;
  updateDisplayState(params: UpdateStateRequest): Promise<void>;
}
// TODO: Remove RequestParams from class/interface
export class LiveAttributeService implements AttributeService {
  constructor(private api: API) {}

  getAll = (): Promise<AttributeResponseDTO[]> => {
    return Promise.resolve([]) //this.api.attributes.getAttributes();
  };

  getForCollector = (collectorId: string): Promise<AttributeResponseDTO[]> => {
    return this.api.attributes.getAttributesForCollector({ collectorId });
  };

  updateDisplayState = (params: UpdateStateRequest) => {
    return this.api.attributes.updateState(params);
  };
}

export class TestAttributeService implements AttributeService {
  constructor(props?: Extendable<AttributeService>) {
    extendable(this, props);
  }

  getAll = async (): Promise<AttributeResponseDTO[]> => {
    return attributesMock;
  };

  getForCollector = async (): Promise<AttributeResponseDTO[]> => {
    return attributesMock;
  };

  updateDisplayState = async () => undefined;
}
