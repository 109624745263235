export function findParent(element: Element, selector: (el: Element) => boolean) {
  for (let parent: Element | null = element.parentElement; parent != null; parent = parent.parentElement)
    if (selector(parent)) return parent;
}

export function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}
