import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, ReactNode } from "react";
import { filterUndefined } from "../../utils/functional/array.utils";

const useStyles = makeStyles((theme) => ({
  description: {},
  value: {
    fontWeight: "bold",
  },
}));

interface HorizontalDLProps {
  title: string | ReactNode;
  className?: string;
  items: Array<
    | {
        key: ReactNode;
        value: ReactNode;
        align?: "left" | "right" | "center";
      }
    | undefined
  >;
  colSize?: 1 | 2 | 3 | 4 | 6 | 12;
}

const HorizontalDL: FC<HorizontalDLProps> = ({ items, colSize = 6, title, className }) => {
  const classes = useStyles();
  return (
    <Grid container className={className}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {items.filter(filterUndefined).map((item, index) => (
        <Grid key={index} item xs={colSize}>
          <Typography className={classes.description} align={item.align || "left"} color="textSecondary">
            {item.key}
          </Typography>
          <Typography className={classes.value} align={item.align || "left"} color="textPrimary">
            {item.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export const HorizontalDLSkeleton = ({
  colSize,
  expectedItems,
}: {
  expectedItems: number;
  colSize: 1 | 2 | 3 | 4 | 6 | 12;
}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h6">
        <Skeleton />
      </Typography>
    </Grid>
    {[...Array(expectedItems)].map((_, i) => (
      <Grid item xs={colSize} key={i}>
        <Skeleton width={60} />
        <Skeleton width={40} />
      </Grid>
    ))}
  </Grid>
);

export default HorizontalDL;
