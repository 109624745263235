import { AccountDetails } from "./AccountDetails";
import { ResetPassword } from "./ResetPassword";
import { CFCard, CFContainer, CFPageTitle } from "../../components/layout/CFContainer";
import { SupportDetails } from "./SupportDetails";
import Container from "@material-ui/core/Container";
import { MFASettings } from "./MFASettings";

export function AccountSettingsPage() {
  return (
    <Container>
      <CFContainer>
        <CFPageTitle header={"My account"} />
        <CFCard>
          <AccountDetails />
        </CFCard>
        <CFPageTitle header={"Security"} />
        <CFCard>
          <ResetPassword />
        </CFCard>
        <CFCard>
          <MFASettings />
        </CFCard>
        <CFPageTitle header={"Support"} />
        <CFCard>
          <SupportDetails />
        </CFCard>
      </CFContainer>
    </Container>
  );
}
