import { toast } from "react-toastify";
import { getTheme } from "./theme/theme";

export const appInit = function () {
  toast.configure({
    autoClose: false,
    draggable: false,
    position: "bottom-center",
  });
};

export const appConfig = {
  customerLimitSearch: 20,
  waitForChart: 5000,
  dateApiCall: "yyyy-MM",
  theme: getTheme("main"),
  apiBaseUri: "",
};

export const FEATURES = {
  admin: "admin",
  batch: "batch",
  upload: "upload",
  awsMultipleTags: "awsMultipleTags",
  swagger: "swagger",
  maintenance: "maintenance",
  activeReload: "activeReload",
  forceActiveReload: "forceActiveReload",
  filterAttributes: "filterAttributes",
  recon: "recon",
  ingramConnect: "ingramConnect",
} as const;
