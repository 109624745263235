import { BatchProcessingRow, SetBatchRows } from "../AutomaticCustomerCreation.interface";
import { lighten, useTheme } from "@material-ui/core";
import { useDialog } from "../../../../hooks/dialog.hooks";
import { css } from "@emotion/css";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import MergeIcon from "@material-ui/icons/CallMerge";
import { BatchMergeVerificationDialog } from "../BatchMergeVerificationDialog";
import React from "react";
import { BatchProjectionCollectorAttributesDTO } from "../../../../open-api";

interface MergeCustomerVerificationProps {
  selectedForMerging: BatchProcessingRow[];
  typeLabel: string;
  setRows: SetBatchRows;
  collectors: BatchProjectionCollectorAttributesDTO[];
}

export const MergeCustomerVerification = ({
  selectedForMerging,
  typeLabel,
  setRows,
  collectors,
}: MergeCustomerVerificationProps) => {
  const theme = useTheme();
  const [mergeDialog, setMergeDialog, mergeDialogProps] = useDialog<BatchProcessingRow[]>();

  if (selectedForMerging.length === 0) {
    return null;
  }

  const onMergeBatch = () => {
    setRows((rows) => {
      const merge = rows.filter((it) => it.state === "selected-for-join");
      const other = rows.filter((it) => it.state !== "selected-for-join");

      return other.map((it) => {
        if (it.state === "selected-for-merge") {
          const newDatasources = { ...it.datasources };
          merge.forEach((toMerge) => {
            Object.keys(toMerge.datasources).forEach((dsKey) => {
              newDatasources[dsKey].newValues.push(...toMerge.datasources[dsKey].newValues);
            });
          });
          return {
            ...it,
            datasources: newDatasources,
            state: "default",
            tableData: {
              ...it.tableData,
              checked: true,
            },
          };
        }
        return { ...it, state: "default" };
      });
    });
    return Promise.resolve();
  };

  return (
    <>
      <div
        className={css({
          paddingRight: theme.spacing(2),
          position: "absolute",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        })}
      >
        <Fab
          aria-label={`Cancel merge ${typeLabel}s`}
          className={css({
            marginRight: theme.spacing(2),
          })}
          variant={"extended"}
          color={"default"}
          onClick={() => setRows((rows) => rows.map((it) => ({ ...it, state: "default" })))}
        >
          <Tooltip title={"Add organizational unit"} placement="left">
            <>Cancel</>
          </Tooltip>
        </Fab>
        <Fab
          aria-label={`Merge ${typeLabel}s`}
          classes={{
            disabled: css({
              backgroundColor: `${lighten(theme.palette.secondary.main, 0.2)} !important`,
            }),
          }}
          variant={"extended"}
          color={"secondary"}
          disabled={selectedForMerging.length === 1}
          onClick={() => setMergeDialog(selectedForMerging)}
        >
          <Tooltip title={"Add organizational unit"} placement="left">
            <>
              <MergeIcon />
              Merge {selectedForMerging.length} {typeLabel}s
            </>
          </Tooltip>
        </Fab>
      </div>
      <BatchMergeVerificationDialog
        typeToMerge={typeLabel}
        selected={mergeDialog}
        datasources={collectors ?? []}
        onVerify={onMergeBatch}
        {...mergeDialogProps}
      />
    </>
  );
};
