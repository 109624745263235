import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { useApiCall } from "../../../hooks/client.hooks";
import {
  CREATE_AWS_CONFIG,
  createAwsCollector,
  DataSourceResponseDTO,
  useAwsCollectorParameters,
} from "../../../clients/collector.client";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-hook-form";
import { FormTitleField } from "../../../components/form/FormTitleField";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import { Alert } from "@material-ui/lab";
import { FeatureToggle } from "../../../components/ft/FeatureToggle";
import { FEATURES } from "../../../app.config";
import { CollectorAwsInput } from "../../../open-api";
import { ControlledSelectSingleField, ControlledTextField } from "../../../components/form/FormComponents";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(1),
  },
}));

interface AwsDataSourceFormProps {
  onSuccess: (ds: DataSourceResponseDTO) => void;
  onError: (msg: string) => void;
  onBack: () => void;
  onClose: () => void;
  buttonCancel: boolean;
}

export function AwsDataSourceForm({
  onSuccess,
  onError,
  onBack,
  onClose,
  buttonCancel = true,
}: AwsDataSourceFormProps) {
  const classes = useStyles();
  const [submit, isSubmitting] = useApiCall(createAwsCollector);

  const [parameters] = useAwsCollectorParameters();

  const { register, handleSubmit, control, watch, setValue } = useForm<
    CollectorAwsInput & { credentials: { enableCostReport: boolean; cfactsaccountId: string } }
  >({
    defaultValues: {
      credentials: {
        accessType: "IAM_ROLE",
        cfactsaccountId: parameters?.accountId,
        roleToAssumeExternalId: parameters?.roleId,
      },
    },
  });

  const handleSave = (form: CollectorAwsInput) => {
    console.log(form);
    submit(form).then(onSuccess).catch(onError);
  };

  useEffect(() => {
    if (parameters) {
      setValue("credentials.cfactsaccountId", parameters.accountId);
      setValue("credentials.roleToAssumeExternalId", parameters.roleId);
    }
  }, [parameters, setValue]);

  const selectedType = watch("credentials.accessType");
  const enableCostReport = watch("credentials.enableCostReport");

  return (
    <form
      onSubmit={handleSubmit((form) => {
        handleSave(form);
      })}
    >
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <FormTitleField text="Data Source" />
          <ControlledTextField label="Name" name="name" control={control} fullWidth />
          <FormTitleField text="Choose your access type" />
          <ControlledSelectSingleField
            options={CREATE_AWS_CONFIG.TYPE_OPTIONS}
            control={control}
            name={"credentials.accessType"}
            label={"Access type"}
          />
          <FeatureToggle feature={FEATURES.awsMultipleTags}>
            <FormTitleField text="AWS Cost & Usage Report" />
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch {...register("credentials.enableCostReport")} color="primary" />}
                label="Enable AWS Cost & Usage Report processing"
              />
            </Grid>
            {enableCostReport ? (
              <>
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name={"credentials.bucketName"}
                    label={"Bucket name"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledTextField
                    control={control}
                    name={"credentials.bucketRegion"}
                    label={"Bucket region"}
                    fullWidth
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Alert variant="outlined" severity="warning">
                  Without access to AWS Cost & Usage Reporting, C-facts is limited in the amount of data it can collect
                  and process, such as being unable to associate more than 1 tag to each resource.
                </Alert>
              </Grid>
            )}
          </FeatureToggle>
          {selectedType === CREATE_AWS_CONFIG.TYPE_KEYS.IAM_ROLE && parameters && (
            <>
              <FormTitleField text="Create an AWS role using the following information" />
              <ControlledTextField
                control={control}
                name={"credentials.cfactsaccountId"}
                label={"Account ID"}
                fullWidth
                inputProps={{ readOnly: true }}
              />
              <ControlledTextField
                control={control}
                name={"credentials.roleToAssumeExternalId"}
                label={"Role ID"}
                fullWidth
                inputProps={{ readOnly: true }}
              />
              <FormTitleField text="Created role ARN" />
              <ControlledTextField
                control={control}
                name={"credentials.roleToAssumeArn"}
                label={"Role ARN"}
                fullWidth
              />
            </>
          )}
          {selectedType === CREATE_AWS_CONFIG.TYPE_KEYS.IAM_USER && (
            <>
              <FormTitleField text="Credentials" />
              <Grid item xs={12} md={6}>
                <ControlledTextField fullWidth control={control} name={"credentials.accessKey"} label={"Access key"} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextField fullWidth control={control} name={"credentials.secretKey"} label={"Secret key"} />
              </Grid>
            </>
          )}
          <FormTitleField text="Collector configuration" />
          <ControlledTextField
            control={control}
            label="The tag key that you use to split your resources"
            id="credentials.groupByTagKey"
            name={"credentials.groupByTagKey"}
            fullWidth
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Previous</Button>
        {buttonCancel ? <Button onClick={onClose}>Cancel</Button> : ""}
        <ButtonConfirm isSubmitting={isSubmitting}>Save</ButtonConfirm>
      </DialogActions>
    </form>
  );
}
