import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { ControlledTextField } from "../../../components/form/FormComponents";
import { useCFForm } from "../../../components/form/FormComponents.hooks";
import { z } from "zod";
import { appConfig } from "../../../app.config";
import { transformMFA } from "../../../utils/form.utils";

interface ResetComponentProps {}

export function MFAComponent({ ...props }: ResetComponentProps) {
  const {
    control,
    watch,
    formState: { isValid },
  } = useCFForm({
    schema: z.object({
      code: z.string().regex(/[0-9]{6}/, "Must be a string containing 6 digits"),
    }),
    mode: "onChange",
  });

  const code = watch("code");

  return (
    <>
      <form method="post" action={`${appConfig.apiBaseUri}/api/mfa/verify/${code}`}>
        <Grid container spacing={1}>
          <Grid item xs={12} justifyContent={"center"} style={{ display: "flex" }}>
            <LockOpenIcon style={{ fontSize: 80 }} />
          </Grid>
          <Grid item xs={12} justifyContent={"center"} style={{ display: "flex" }}>
            <Typography variant={"h6"}>Enter MFA code</Typography>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} style={{ display: "flex" }}>
            <Typography>Enter the 6-digit code provided by your authenticator provider</Typography>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name={"code"}
              transform={transformMFA}
              label={"One time token"}
              placeholder={"______"}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" fullWidth disabled={!isValid} variant="contained" color="primary">
              Complete sign in
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
