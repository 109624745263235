import { CSVType } from "../../../clients/collector.client";
import { FormDateField } from "../../../components/form/FormDateField";
import { Controller, FieldError } from "react-hook-form";
import { FormCurrencySelect } from "../../../components/form/FormCurrencySelect";
import { FormTextField } from "../../../components/form/FormTextField";
import { useCsvUploadFormMethods } from "./CsvUploadForm";

interface CsvDefaultFieldProps {
  type: string;
  id: string;
  key: string;
  required: boolean;
  hasValue: boolean;
}

export const CsvDefaultField = ({ type, id, key, required, hasValue }: CsvDefaultFieldProps) => {
  const formMethods = useCsvUploadFormMethods();

  const error: FieldError | undefined =
    formMethods.formState.errors.invoice?.config?.fields &&
    // @ts-ignore - because the type of fields does not correspond with what useForm gives us and so we can't do key as keyof
    formMethods.formState.errors.invoice?.config?.fields[key] &&
    // @ts-ignore - because the type of fields does not correspond with what useForm gives us and so we can't do key as keyof
    formMethods.formState.errors.invoice?.config?.fields[key].default;

  switch (type) {
    case CSVType.DATE:
      return (
        <FormDateField
          error={error}
          silent={true}
          name={id}
          label={"Default date for line items where date is missing"}
          formMethods={formMethods}
        />
      );
    case CSVType.CURRENCY:
      return (
        <Controller
          render={({ field: { onChange, value, name } }) => (
            <FormCurrencySelect
              error={error}
              silent={true}
              initValue={value}
              label="Default currency for line items where currency is missing"
              id={name}
              name={name}
              onChange={onChange}
            />
          )}
          name={id}
          control={formMethods.control}
          rules={
            required && !hasValue
              ? {
                  required: "A default currency is required if no column is selected",
                }
              : {}
          }
        />
      );
    case CSVType.NUMBER:
      return (
        <Controller
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormTextField
              error={error}
              silent={true}
              fullWidth={true}
              label={"Default number for line items without a number"}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={"outlined"}
            />
          )}
          name={id}
          control={formMethods.control}
          rules={
            required && !hasValue
              ? {
                  required: "A valid amount is required",
                  pattern: {
                    value: /^[1-9][0-9]*$/,
                    message: "Invalid amount",
                  },
                }
              : {}
          }
        />
      );
    case CSVType.STRING:
    default:
      return (
        <Controller
          render={({ field: { onChange, onBlur, value, name } }) => (
            <FormTextField
              silent={true}
              error={error}
              fullWidth={true}
              label={"Default for line items without a value"}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              variant={"outlined"}
            />
          )}
          name={id}
          control={formMethods.control}
          rules={
            required && !hasValue
              ? {
                  required: "A default is required if no column is selected",
                }
              : {}
          }
        />
      );
  }
};
