/**
 * Pure pseudo random functions making use of "generator" functions to compose them.
 *
 * To evaluate a random generator use Random.evaluate, for example:
 *
 * ```ts
 * Random.evaluate(pick([1,2,3]), seed); // always gives back the same value when using the same seed
 * ```
 *
 * To compose multiple random generators, use yield*:
 *
 * ```ts
 * function* randomNumbers() {
 *   const max = yield* inRange(10, 20);
 *   return [yield* pick([1, 5, 8]), yield* inRange(1, max), yield* inRange(1, max), yield* inRange(1, max)];
 * }
 * ```
 *
 */
export * as Random from "./random.utils";

const a = 1839567234;
const m = 8239451023;
const c = 972348567;

/**
 * A generator of type RandomG can yield a (seed: number) => number
 * which calculates the next seed using the current stored seed
 * and returns some value A.
 */
export type RandomG<A> = Generator<(seed: number) => number, A, number>;

/**
 * Calculates the next seed using the currently stored seed.
 */
function* nextSeed(next: (seed: number) => number): RandomG<number> {
  return yield next;
}

/**
 * Some random integer.
 */
export function* integer(): RandomG<number> {
  return yield* nextSeed((seed) => (a * seed + c) % m);
}

/**
 * Random integer in a given a range.
 */
export function* inRange(min: number, max: number): RandomG<number> {
  return min + Math.floor(((yield* integer()) / m) * (max - min));
}

/**
 * Pick randomnly an item out of the array.
 */
export function* pick<T>(arr: T[]): RandomG<T> {
  return arr[yield* inRange(0, arr.length)];
}

/**
 * Get the value of a random generator given a seed.
 */
export function evaluate<A>(random: RandomG<A>, seed = 0): A {
  const [a] = fromGenerator(random)(seed);
  return a;
}

/**
 * Get the returning seed of a random generator given an initial seed.
 */
export function execute<A>(generator: RandomG<A>, seed = 0): number {
  const [, s] = fromGenerator(generator)(seed);
  return s;
}

/**
 * Compose an array of random generators in one random generator.
 */
export function* all<T>(array: RandomG<T>[]): RandomG<T[]> {
  const out: T[] = [];
  // We can not use yield* in the callback of map etc.
  for (const item of array) out.push(yield* item);
  return out;
}

/**
 * The monadic form of Random. Given a seed, calculates the new seed and a return value A.
 */
type RandomM<A> = (seed: number) => [A, number];

/**
 * Converts a generator to its monadic form.
 */
function fromGenerator<A>(generator: RandomG<A>): RandomM<A> {
  return (seed) => {
    let result = generator.next();
    while (!result.done) {
      seed = result.value(seed);
      result = generator.next(seed);
    }
    return [result.value, seed];
  };
}
