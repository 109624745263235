import { ConfirmDialog } from "../../../components/dialogs/ConfirmDialog";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { OrganizationalUnitResponseDTO } from "../../../open-api";

export interface OrganizationalUnitDialogDeleteProps {
  selectedForDelete: OrganizationalUnitResponseDTO;
  onCloseDelete: () => void;
  onDelete: (organizationalUnit: OrganizationalUnitResponseDTO) => Promise<any>;
}

export function OrganizationalUnitDialogDelete({
  selectedForDelete,
  onCloseDelete,
  onDelete,
}: OrganizationalUnitDialogDeleteProps) {
  return (
    <ConfirmDialog
      open={!!selectedForDelete}
      title={`Delete organization`}
      content={`Are you sure you want to delete organization: ${selectedForDelete.name}?`}
      onClose={() => onCloseDelete()}
      onConfirm={() => onDelete(selectedForDelete)}
    >
      <DeleteOutlineIcon style={{ fontSize: 80 }} />
    </ConfirmDialog>
  );
}
