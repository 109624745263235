import { DateTime } from "luxon";
import { z } from "zod";
import { assertNever } from "../../../utils/assert";
import { yearMonth } from "../../../utils/functional/date.utils";
import { yearMonthRange } from "../../../utils/YearMonth";

export const ZodDateTime = z
  .string()
  .refine((it) => DateTime.fromISO(it).isValid)
  .transform((it) => DateTime.fromISO(it));

export const AbsoluteRange = z.object({
  type: z.literal("AbsoluteRange"),
  startDate: z.string(),
  endDate: z.string(),
});

export type AbsoluteRange = z.output<typeof AbsoluteRange>;

export const RelativeRange = z.object({
  type: z.literal("RelativeRange"),
  months: z.number(),
});

export type RelativeRange = z.output<typeof RelativeRange>;

export const DateRange = AbsoluteRange.or(RelativeRange);
export type DateRange = z.output<typeof DateRange>;

export function toAbsoluteRange(dateRange: DateRange): AbsoluteRange {
  switch (dateRange.type) {
    case "AbsoluteRange":
      return dateRange;
    case "RelativeRange":
      const now = DateTime.local().startOf("months");
      return {
        type: "AbsoluteRange",
        startDate: yearMonth(now.minus({ months: dateRange.months })),
        endDate: yearMonth(now),
      };
    default:
      return assertNever(dateRange);
  }
}

export function toDateRange({ startDate, endDate }: { startDate: string; endDate: string }): DateRange {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  const actualStart = end.diff(start, "months").months > 12 ? yearMonth(end.minus({ months: 12 })) : startDate;

  if (yearMonth(DateTime.local()) === endDate) {
    return {
      type: "RelativeRange",
      months: DateTime.fromISO(endDate).diff(DateTime.fromISO(actualStart), "months").months,
    };
  }
  return { type: "AbsoluteRange", startDate: actualStart, endDate };
}

export function toBillingMonths(dateRange: DateRange) {
  return yearMonthRange(toAbsoluteRange(dateRange));
}
