import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { GenericIcon, Icon } from "../../../theme/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { EditIconButton } from "../../../components/buttons/EditIconButton";
import { getCountryName } from "../../../utils/functional/country.utils";
import { ConditionalRender } from "../../../components/ConditionalRender";
import { WorkspaceRole } from "../../../hooks/auth.hooks";
import { OrganizationalUnitContactDTO } from "../../../open-api";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    backgroundColor: "rgb(48,48,48)",
    width: "100%",
  },
  organizationLogo: {
    fontSize: 60,
  },
}));

interface OrganizationalUnitContactCardProps {
  organizationContact: OrganizationalUnitContactDTO | null;
  onEdit: (contact: OrganizationalUnitContactDTO) => void;
}

export const OrganizationalUnitContactCard = ({ organizationContact, onEdit }: OrganizationalUnitContactCardProps) => {
  const classes = useStyles();

  if (!organizationContact) return null;

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Icon icon={GenericIcon.CONTACT} className={classes.organizationLogo} />}
        title={`${organizationContact.firstName} ${organizationContact.lastName}`}
        subheader={
          <>
            {organizationContact.replyTo ? (
              <Typography>
                Reply to: {organizationContact.replyTo?.name ? `${organizationContact.replyTo?.name} ` : ""}{" "}
                {organizationContact.replyTo?.email ? `(${organizationContact.replyTo?.email})` : ""}
              </Typography>
            ) : (
              ""
            )}
          </>
        }
        action={
          <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
            <EditIconButton onClick={() => onEdit(organizationContact)} tooltip="Edit contact" />
          </ConditionalRender>
        }
      />
      <CardContent>
        <Typography>
          {organizationContact.thoroughfare} {organizationContact.premise}
        </Typography>
        <Typography>
          {organizationContact.postalCode} {organizationContact.locality}
        </Typography>
        <Typography>
          {organizationContact.administrativeArea}{" "}
          {organizationContact.country ? getCountryName(organizationContact.country) : ""}
        </Typography>

        {organizationContact.chamberOfCommerce}
      </CardContent>
    </Card>
  );
};
