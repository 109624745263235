import { CFBox, CFCard } from "../../../../components/layout/CFContainer";
import { FormControlLabel, Grid, Switch, TextField, Typography, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { AttributeFilterValueDTO, AttributeResponseDTO, AttributeTypeDTODisplayStateEnum } from "../../../../open-api";
import { AttributeList } from "./AttributeList";
import { groupByState, mapAttributes } from "../../../../utils/attributes.utils";
import { css } from "@emotion/css";

interface AttributeFilterSectionProps {
  attributes: Array<AttributeFilterValueDTO | AttributeResponseDTO>;
  onSave: (changedStates: Record<string, AttributeTypeDTODisplayStateEnum>) => Promise<void>;
  title?: string;
  SubtitleComponent?: React.FC;
  withCheckboxes?: boolean;
  handleUndefined?: {
    selected: boolean;
    onChange: (val: boolean) => void;
  };
  canDelete?: boolean;
}

export interface AttributeData {
  id: string;
  displayParam: string;
  state: AttributeTypeDTODisplayStateEnum;
  isSelected?: boolean;
}

export const AttributeFilterSection = ({
  attributes,
  onSave,
  title,
  SubtitleComponent,
  withCheckboxes = false,
  handleUndefined,
  canDelete = false,
}: AttributeFilterSectionProps) => {
  const theme = useTheme();
  const [changedStates, setChangedStates] = useState<Record<string, AttributeTypeDTODisplayStateEnum>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [search, setSearch] = useState<string>("");

  const handleCancel = () => {
    setIsEditing(false);
    setSearch("");
    setChangedStates({});
  };

  const handleSave = () => {
    onSave(changedStates).then(() => {
      setIsEditing(false);
      setSearch("");
    });
  };

  const setStatus = !isEditing
    ? undefined
    : (paramId: string, status: AttributeTypeDTODisplayStateEnum) => {
        setChangedStates((prev) => ({ ...prev, [paramId]: status }));
      };

  const attributeParams = mapAttributes(attributes, changedStates);
  const [undecided, selected, ignored] = groupByState(attributeParams);

  return (
    <>
      <CFCard
        header={title}
        actions={{
          edit: !isEditing ? () => setIsEditing(true) : undefined,
        }}
        formActions={{
          cancel: isEditing ? handleCancel : undefined,
          save: isEditing ? handleSave : undefined,
        }}
      >
        {SubtitleComponent && <SubtitleComponent />}
        <Grid container>
          {!isEditing && handleUndefined && (
            <Grid item xs={12}>
              <Typography>
                Data without association with a <strong>{title}</strong> is{" "}
                <strong>{handleUndefined.selected ? "included" : "NOT included"}</strong>
              </Typography>
            </Grid>
          )}
          {isEditing && (
            <CFBox xs={12} container alignItems="center" justifyContent="space-between">
              <Grid item>
                {handleUndefined && (
                  <FormControlLabel
                    className={css({
                      paddingTop: theme.spacing(2),
                      marginLeft: theme.spacing(0),
                    })}
                    labelPlacement={"start"}
                    label={
                      <>
                        Include data without association with a <strong>{title}</strong>
                      </>
                    }
                    control={
                      <Switch
                        color={"primary"}
                        checked={handleUndefined.selected}
                        onChange={(e) => handleUndefined.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item>
                <TextField
                  label={"Filter"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ right: 0 }}
                />
              </Grid>
            </CFBox>
          )}
          {(isEditing || undecided.length > 0) && (
            <AttributeList
              title={"New"}
              attributes={undecided}
              setStatus={setStatus}
              search={search}
              type={AttributeTypeDTODisplayStateEnum.NEW}
              canEdit={isEditing}
              withCheckboxes={withCheckboxes}
            />
          )}
          <AttributeList
            title={"Include"}
            attributes={selected}
            setStatus={setStatus}
            search={search}
            type={AttributeTypeDTODisplayStateEnum.INCLUDE}
            canEdit={isEditing}
            withCheckboxes={withCheckboxes}
          />
          {isEditing && (
            <AttributeList
              title={"Exclude"}
              attributes={ignored}
              setStatus={setStatus}
              search={search}
              type={AttributeTypeDTODisplayStateEnum.HIDE}
              canEdit={isEditing}
              canDelete={canDelete}
              withCheckboxes={withCheckboxes}
            />
          )}
        </Grid>
      </CFCard>
    </>
  );
};
