import { ListSubheader, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import * as React from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  // Render item if it is a group
  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet.props} style={inlineStyle}>
      {dataSet.props.children[0].label || dataSet.props.children}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = () =>
  React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
      if (child.hasOwnProperty("group")) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    const sizeMap = React.useRef({});
    const setSize = React.useCallback((index, size) => {
      sizeMap.current = { ...sizeMap.current, [index]: size };
    }, []);
    // @ts-ignore
    const getSize = React.useCallback((index) => sizeMap.current[index] || 50, []);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={{ setSize, ...other }}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={getSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

// // TODO finish this component and use it in OrganizationSelectFilter and DashSelectFilter
// export default function Virtualize() {
//   return (
//     <Autocomplete
//       classes={{
//         listbox: css({
//           boxSizing: "border-box",
//           "& ul": {
//             padding: 0,
//             margin: 0,
//           },
//         }),
//       }}
//       id="virtualize-demo"
//       style={{ width: 300 }}
//       disableListWrap
//       PopperComponent={Popper}
//       ListboxComponent={ListboxComponent(true)}
//       options={OPTIONS}
//       groupBy={(option) => option[0].toUpperCase()}
//       renderInput={(params) => <TextField {...params} label="10,000 options" />}
//       renderOption={(props, option) => [props, option]}
//       renderGroup={(params) => params}
//     />
//   );
// }
