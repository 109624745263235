import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { RouterLink } from "../../../components/navigation/RouterLink";
import { deleteOrganizationalUnit, useOrganization } from "../../../clients/organizationalunit.client";
import { GeneralPageSkeleton } from "../../../components/skeletons/GeneralPageSkeleton";
import { OrganizationTreeStyled } from "../components/OrganizationTreeStyled";
import { OrganizationalUnitResponseDTO } from "../../../open-api";
import { OrganizationCard } from "../components/OrganizationCard";
import { OrganizationalUnitDialogDelete } from "../dialogs/OrganizationalUnitDialogDelete";
import { OrganizationalUnitDialogCreate } from "../dialogs/OrganizationalUnitDialogCreate";
import { flattenAll, getOrganizationUnitName } from "../../../utils/organization.utils";
import { messageError, messageSuccess } from "../../../services/message.service";
import AddIcon from "@material-ui/icons/Add";
import { OrganizationalUnitDialogEdit } from "../dialogs/OrganizationalUnitDialogEdit";
import { AccordionTags } from "./components/AccordionTags";
import { AccordionPortals } from "./components/AccordionPortals";
import { AccordionContact } from "./components/AccordionContact";
import { AccordionCView } from "./components/AccordionCView";
import { MESSAGE } from "../../../utils/localization.utils";
import { ConditionalRender } from "../../../components/ConditionalRender";
import { AccordionGrossType } from "./components/AccordionGrossType";
import { WorkspaceRole } from "../../../hooks/auth.hooks";
import { AccordionBudget } from "./components/AccordionBudget";
import { BudgetType } from "../../../utils/functional/budgetalerts.util";
import { useRedirect } from "../../../hooks/router.hooks";

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: theme.spacing(1),
  },
  breadCrumb: {
    padding: theme.spacing(3),
  },
}));

interface OrganizationPageProps {
  organizationalUnitId: string;
}

export function OrganizationPage({ organizationalUnitId }: OrganizationPageProps) {
  const classes = useStyles();

  const redirect = useRedirect();
  const [organization, refreshOrganization] = useOrganization(organizationalUnitId);

  const [openOrganization, setOpenOrganization] = useState(null as string | null);
  const [editOrganization, setEditOrganization] = useState(null as string | null);
  const [deleteOrganization, setDeleteOrganization] = useState(null as string | null);
  const [addOrganizationToParent, setAddOrganizationToParent] = useState(null as string | null);

  if (!organization) {
    return <GeneralPageSkeleton />;
  }

  !openOrganization && setOpenOrganization(organization.id);

  const organizationMap: Record<string, OrganizationalUnitResponseDTO> = flattenAll([organization]).reduce(
    (agg, curr) => ({
      [curr.id]: curr,
      ...agg,
    }),
    {}
  );

  const [, plural] = getOrganizationUnitName(organization?.type);

  const handleEdit = (organization: OrganizationalUnitResponseDTO) => {
    setEditOrganization(organization.id);
  };

  const handleDeleteConfirm = (organizationalUnit: OrganizationalUnitResponseDTO) =>
    deleteOrganizationalUnit(organizationalUnit.id)
      .then(() => {
        messageSuccess(`${organizationalUnit.name} deleted`);
        if (organizationalUnit.unitType === "ROOT") {
          redirect(`/organizational-unit/${organizationalUnit.type}`);
        } else {
          setOpenOrganization(organization.id);
          setDeleteOrganization(null);
          refreshOrganization();
        }
      })
      .catch(() => messageError(MESSAGE.ERROR.DELETE_ORGANIZATIONAL_UNIT));
  const selectedOrganization = organizationMap[openOrganization ? openOrganization : organization.id];

  return (
    <>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
              <RouterLink to={`/organizational-unit/${organization.type}`}>{plural}</RouterLink>
              <Typography color="textPrimary">{organization.name}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader subheader={"Organization structure"} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <OrganizationTreeStyled
                      onNodeSelect={(nodeId) => {
                        setOpenOrganization(nodeId);
                      }}
                      organization={organization}
                      selected={openOrganization ? openOrganization : organization.id}
                    />
                    <ConditionalRender hasRole={[WorkspaceRole.ADMIN]}>
                      <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => setAddOrganizationToParent(organization.id)}
                      >
                        Add cost center
                      </Button>
                    </ConditionalRender>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={7}>
            <OrganizationCard
              organization={selectedOrganization}
              root={organization}
              handleEdit={handleEdit}
              handleDelete={(organization) => setDeleteOrganization(organization.id)}
            />
            <AccordionTags organization={selectedOrganization} onChange={refreshOrganization} />
            <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
              <AccordionGrossType organization={selectedOrganization} onChange={refreshOrganization} />
            </ConditionalRender>
            <AccordionBudget organization={selectedOrganization} budgetType={BudgetType.COST} />
            <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
              <AccordionBudget organization={selectedOrganization} budgetType={BudgetType.SALES} />
            </ConditionalRender>
            <AccordionCView organization={selectedOrganization} />
            <ConditionalRender hasRole={[WorkspaceRole.ADMIN, WorkspaceRole.READ]}>
              <AccordionPortals organization={selectedOrganization} onChange={refreshOrganization} />
            </ConditionalRender>
            <AccordionContact organization={selectedOrganization} />
          </Grid>
        </Grid>
      </Grid>

      {addOrganizationToParent && (
        <OrganizationalUnitDialogCreate
          toggle={() => {
            setAddOrganizationToParent(null);
          }}
          onSuccess={() => {
            setAddOrganizationToParent(null);
            refreshOrganization();
          }}
          open={!!addOrganizationToParent}
          parent={organizationMap[addOrganizationToParent]}
          title={"Add cost center"}
        />
      )}

      {deleteOrganization && (
        <OrganizationalUnitDialogDelete
          onCloseDelete={() => setDeleteOrganization(null)}
          onDelete={handleDeleteConfirm}
          selectedForDelete={organizationMap[deleteOrganization]}
        />
      )}

      {editOrganization ? (
        <OrganizationalUnitDialogEdit
          onClose={() => {
            setEditOrganization(null);
            refreshOrganization();
          }}
          onSuccess={() => {
            setEditOrganization(null);
            refreshOrganization();
          }}
          open={!!editOrganization}
          organizationalUnit={organizationMap[editOrganization]}
          display={"GENERIC"}
        />
      ) : (
        ""
      )}
    </>
  );
}
