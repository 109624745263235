import { useLineItemsForMonth, useNMostRecentPeriods } from "../../../clients/lineitems.client";
import { OrganizationalUnitResponseDTO } from "../../../open-api";
import LineItemTable, { DIMENSIONS_DEFAULT } from "../../../components/billing/LineItemTable";

interface UnitLineItemTableProps {
  selected: OrganizationalUnitResponseDTO;
  stretchToParentHeight?: boolean;
}

export const UnitLineItemTable = ({ selected, stretchToParentHeight }: UnitLineItemTableProps) => {
  const [currentMonth] = useNMostRecentPeriods(1, true);

  const [items, , loading] = useLineItemsForMonth(
    currentMonth,
    selected.unitType === "ROOT" ? selected.id : undefined,
    selected.unitType !== "ROOT" ? selected.id : undefined
  );

  if (!items || loading) {
    // TODO: Add skeleton
    return <p>Loading</p>;
  }

  return (
    <LineItemTable
      displayTitle={false}
      lineItems={items}
      dimensions={DIMENSIONS_DEFAULT}
      stretchToParentHeight={stretchToParentHeight}
    />
  );
};
