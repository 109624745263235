import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { OrganizationalUnitType } from "../../../../clients/organizationalunit.client";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});
export function OrganizationalUnitCreateStepCreated({
  onClose,
  type,
}: {
  onClose: () => void;
  type: OrganizationalUnitType | null;
}) {
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid alignItems="center" container spacing={1}>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <CheckCircleIcon style={{ fontSize: 80 }} />
          </Grid>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <Typography>{type && `Organizational unit created, close this window to view the status.`}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Done
        </Button>
      </DialogActions>
    </>
  );
}
