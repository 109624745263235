import { Button, Grid, Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ReactNode } from "react";

const useStyles = makeStyles({
  content: {
    display: "flex",
    justifyContent: "center",
  },
});

interface StepDialogErrorProps {
  text: string;
  onBack?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

export function StepDialogError({ text, onBack, onCancel, children }: StepDialogErrorProps) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <ErrorOutlineIcon style={{ fontSize: 120 }} />
      </Grid>
      <DialogContent className={classes.content}>
        <Typography>{text}</Typography>
      </DialogContent>
      <Grid item xs={12}>
        <DialogActions>
          {onBack ? <Button onClick={onBack}>back</Button> : ""}
          {onCancel ? <Button onClick={onCancel}>cancel</Button> : ""}
        </DialogActions>
      </Grid>
    </>
  );
}
