import { z } from "zod";
import { useForm, UseFormProps } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";

export const useCFForm = <T extends z.Schema<any, any>, TContext = any>(
  props: { schema: T } & Omit<UseFormProps<z.infer<T>, TContext>, "resolver">
) => {
  return useForm<z.infer<T>>({
    mode: "onBlur",
    resolver: zodResolver(props.schema),
    ...props,
  });
};
