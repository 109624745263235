import { createContext, useState } from "react";
import { AuthParams } from "../../hooks/auth.hooks";
import { Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import { GenericIcon, Icon } from "../../theme/Icon";
import { DashFilter } from "./components/DashFilter";
import { DashBoardPage } from "./DashBoardPage";
import { DashPivotGrid } from "./components/DashPivotGrid";
import { DashboardTabs } from "./Dashboards";
import { useSelectedDashboard } from "./selectors/dashboard.selector";
import { useContextOrThrow } from "../../hooks/context.hooks";
import { Dashboard } from "./model/Dashboard";

export const getTagsPageTitle = (params: AuthParams) => {
  return "Analytics";
};

export const NewDashPage = () => {
  const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true);
  const [showPivot, setShowPivot] = useState<boolean>(false);
  const selectedDashboard = useSelectedDashboard();

  return (
    <>
      <DashboardTabs />
      {selectedDashboard != null && (
        <DashboardContext.Provider value={selectedDashboard}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton style={{ display: "inline-flex" }} onClick={() => setFiltersExpanded(!filtersExpanded)}>
                <Icon icon={filtersExpanded ? GenericIcon.LIST_COLLAPSE : GenericIcon.LIST_EXPAND} />
              </IconButton>
              <Typography style={{ display: "inline-flex" }} variant="h6">
                Filters
              </Typography>
              <Collapse in={filtersExpanded} timeout="auto" style={{ width: "100%" }}>
                <DashFilter />
              </Collapse>
            </Grid>
            <Grid style={{ marginTop: "20px" }} item container xs={12}>
              <DashBoardPage />
            </Grid>

            <Grid item xs={12}>
              <IconButton style={{ display: "inline-flex" }} onClick={() => setShowPivot((_) => !_)}>
                <Icon icon={showPivot ? GenericIcon.LIST_COLLAPSE : GenericIcon.LIST_EXPAND} />
              </IconButton>
              <Typography style={{ display: "inline-flex" }} variant="h6">
                Pivot table
              </Typography>
              {showPivot && <DashPivotGrid />}
            </Grid>
          </Grid>
        </DashboardContext.Provider>
      )}
    </>
  );
};

export const DashboardContext = createContext<Dashboard | undefined>(undefined);

/** A non-nullable dashboard, but is not injected in the DashboardTabs, as there may be no tabs, or no selected dashboard. */
export const useDashboard = () => useContextOrThrow(DashboardContext);
