import { Button, Chip, Dialog, Typography, useTheme } from "@material-ui/core";
import { DialogTitleCloseable } from "../../../components/DialogTitleCloseable";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ButtonConfirm } from "../../../components/form/ButtonConfirm";
import React, { useMemo, useState } from "react";
import { DialogProps } from "../../../hooks/dialog.hooks";
import MaterialTable, { Column } from "@material-table/core";
import { css } from "@emotion/css";
import Tooltip from "@material-ui/core/Tooltip";
import { BatchProjectionCollectorAttributesDTO } from "../../../open-api";
import { tableIcons } from "../../../theme/TableIcons";
import { grey } from "@material-ui/core/colors";
import { BatchProcessingRow } from "./AutomaticCustomerCreation.interface";

interface BatchCreationVerificationDialogProps extends DialogProps {
  selected: BatchProcessingRow[] | null;
  onVerify: (selected: BatchProcessingRow[]) => Promise<void>;
  typeToCreate: string;
  datasources: BatchProjectionCollectorAttributesDTO[];
}

export function BatchCreationVerificationDialog({
  open,
  onClose,
  selected,
  onVerify,
  typeToCreate,
  datasources,
}: BatchCreationVerificationDialogProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSave = () => {
    if (selected) {
      setIsLoading(true);
      onVerify(selected).finally(() => {
        setIsLoading(false);
        onClose();
      });
    }
  };
  if (!selected) return null;

  const toCreate = selected.filter((it) => it.organization.type === "new");

  const toMerge = selected.filter((it) => it.organization.type === "existing");

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
      <DialogTitleCloseable onClose={onClose}>Verify batch creation of {typeToCreate}s</DialogTitleCloseable>
      <DialogContent>
        {toCreate.length > 0 && (
          <>
            <Typography variant={"h6"}>
              Create <strong>{toCreate.length}</strong> new {typeToCreate}s:
            </Typography>
            <BatchVerificationRow rows={toCreate} typeLabel={typeToCreate} datasources={datasources} />
          </>
        )}
        {toMerge.length > 0 && (
          <>
            <Typography variant={"h6"}>
              Associate new attributes to <strong>{toMerge.length}</strong> existing {typeToCreate}s:
            </Typography>
            <BatchVerificationRow rows={toMerge} typeLabel={typeToCreate} datasources={datasources} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonConfirm onClick={onSave} isSubmitting={isLoading}>
          Save
        </ButtonConfirm>
      </DialogActions>
    </Dialog>
  );
}

interface BatchVerificationRowProps {
  rows: BatchProcessingRow[];
  typeLabel: string;
  datasources: BatchProjectionCollectorAttributesDTO[];
}

export function BatchVerificationRow({ rows, typeLabel, datasources }: BatchVerificationRowProps) {
  const theme = useTheme();
  const columns: Column<BatchProcessingRow>[] = useMemo(() => {
    return [
      {
        title: `C-Facts ${typeLabel}`,
        field: "organization.name",
      },
      ...datasources.map((it, idx) => ({
        title: (
          <Typography>
            <span
              className={css({
                fontWeight: "bold",
                fontSize: "0.9rem",
              })}
            >
              {it.collector.type}: {it.collector.name}
            </span>
            <br />
            <span
              className={css({
                fontSize: "0.8rem",
              })}
            >
              {it.rootAttribute.displayValue}
            </span>
          </Typography>
        ),
        field: `datasources.${it.collector.id}.newValues[0].displayValue`,
        render: (rowData: BatchProcessingRow) => {
          return (
            <>
              {rowData.datasources[it.collector.id].newValues.map((value) => (
                <Tooltip title={value.externalId}>
                  <Chip
                    size="small"
                    color={rowData.tableData?.checked ? "primary" : undefined}
                    label={value.displayValue}
                  />
                </Tooltip>
              ))}
              {rowData.datasources[it.collector.id].existingValues.map((value) => (
                <Tooltip title={value.externalId}>
                  <Chip size="small" variant="outlined" label={value.displayValue} />
                </Tooltip>
              ))}
            </>
          );
        },
      })),
    ];
  }, [typeLabel, datasources]);
  return (
    <div>
      <MaterialTable
        style={{
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(-3),
          marginRight: theme.spacing(-3),
          marginBottom: theme.spacing(2),
        }}
        icons={tableIcons}
        data={rows}
        columns={columns}
        options={{
          toolbar: false,
          // paging: false,
          idSynonym: "key",
          draggable: false,
          selection: false,
          search: false,
          sorting: false,
          padding: "dense",
          overflowY: "scroll",
          maxBodyHeight: 250,
          showTitle: false,
          rowStyle: {
            marginRight: theme.spacing(-2),
          },
          headerStyle: {
            backgroundColor: grey["700"],
            position: "sticky",
            top: 0,
            width: "20%",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [10, 20, 50, 100],
        }}
      />
    </div>
  );
}
