import { DialogProps } from "../../../hooks/dialog.hooks";
import { Dialog } from "@material-ui/core";
import { OrganizationalUnitContactFormEdit } from "../forms/OrganizationalUnitContactFormEdit";
import { OrganizationalUnitContactDTO, OrganizationalUnitResponseDTO } from "../../../open-api";

interface OrganizationalUnitContactDialogEditProps extends DialogProps {
  organizationalUnitContact: OrganizationalUnitContactDTO;
  organizationalUnit: OrganizationalUnitResponseDTO;
  onSuccess?: () => void;
  onError?: () => void;
}

export const OrganizationalUnitContactDialogEdit = ({
  organizationalUnitContact,
  organizationalUnit,
  open,
  onClose,
  onSuccess,
  onError,
}: OrganizationalUnitContactDialogEditProps) => {
  return (
    <>
      <Dialog fullWidth maxWidth={"lg"} open={open} onClose={onClose} disableBackdropClick>
        <OrganizationalUnitContactFormEdit
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          organizationalUnitContact={organizationalUnitContact}
          organizationalUnit={organizationalUnit}
        />
      </Dialog>
    </>
  );
};
