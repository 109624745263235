import { TreeListItemProps } from "../../pages/organizationalunit/components/tags/TreeItemStyled";
import { GenericIcon } from "../../theme/Icon";
import { SearchableTagTreeAction } from "../../pages/organizationalunit/components/tags/SearchableTabTree";
import { formattedPercentage, roundToTwoDecimals } from "../localization.utils";
import {
  OrganizationalUnitResponseDTO,
  ProjectionResponseDTO,
  ProjectionResponseOrganizationDTO,
} from "../../open-api";

export const TAG = {
  ANY: "<ANY>",
  UNTAGGED: "<UNTAGGED>",
  ADD_TAG: "Add Tag",
  ADD_ANY_TAG: "Add all tags and all future tags",
  REMOVE_TAG: "Remove Tag",
  REMOVE_ANY_TAG: "Remove all tags and all future tags",
  EDIT_WEIGHT: "Edit weight",
  WEIGHT: "weight",
};

// projection is available when
// - mappedThrough is null
// - the combined total of all mappedTo weights is less than 1
export const filterAvailableProjections = (projections: ProjectionResponseDTO[]): ProjectionResponseDTO[] => {
  return projections.filter(projectionAvailable);
};

export const projectionAvailable = (projection: ProjectionResponseDTO): boolean => {
  return !projection.mappedThrough && projection.mappedTo.map((pjn) => pjn.weight).reduce((a, b) => a + b, 0) < 1;
};

export const filterAvailableAndNotAssociatedWithOrganizationId = (
  projections: ProjectionResponseDTO[],
  organizationalUnitId: string
): ProjectionResponseDTO[] => {
  return projections.filter((projection) =>
    projectionAvailableAndNotAssociatedWithOrganizationId(projection, organizationalUnitId)
  );
};

export const projectionAvailableAndNotAssociatedWithOrganizationId = (
  projection: ProjectionResponseDTO,
  organizationalUnitId: string
): boolean => {
  return (
    !projection.mappedThrough &&
    projection.mappedTo.map((pjn) => pjn.weight).reduce((a, b) => a + b, 0) < 1 &&
    !projectionAssociated(projection, organizationalUnitId)
  );
};

export const filterProjectionsAssociatedWithOrganizationId = (
  projections: ProjectionResponseDTO[],
  organizationalUnitId: string
): ProjectionResponseDTO[] => {
  return projections.filter((it) => {
    return projectionAssociated(it, organizationalUnitId);
  });
};

// projection is associated when
// - it's mapped to the organization id
export const projectionAssociated = (projection: ProjectionResponseDTO, organizationalUnitId: string): boolean => {
  return projection.mappedTo.length > 0 && !!projection.mappedTo.find((itt) => itt.id === organizationalUnitId);
};

export const filterUnavailableProjectionsWithOrganizationId = (
  projections: ProjectionResponseDTO[],
  organizationalUnitId: string
): ProjectionResponseDTO[] => {
  return projections.filter((it) => projectionUnavailable(it) && !projectionAssociated(it, organizationalUnitId));
};

// projection is unavailable when
// - it's mappedThrough
// - the combined total of all mappedTo weights is 1 (though should never be > 1)
const projectionUnavailable = (projection: ProjectionResponseDTO): boolean => {
  return !!projection.mappedThrough || projection.mappedTo.map((pjn) => pjn.weight).reduce((a, b) => a + b, 0) >= 1;
};

const addAnyActionToParent = (
  childItem: TreeListItemProps<ProjectionResponseDTO>,
  parentItem: TreeListItemProps<ProjectionResponseDTO>,
  actions: SearchableTagTreeAction<ProjectionResponseDTO>[]
): void => {
  parentItem.actions = actions.map((action) => {
    let tooltip = action.tooltip;

    switch (action.tooltip) {
      case TAG.REMOVE_TAG:
        tooltip = TAG.REMOVE_ANY_TAG;
        break;
      case TAG.ADD_TAG:
        tooltip = TAG.ADD_ANY_TAG;
        break;
    }

    let icon = action.icon;

    switch (action.icon) {
      case GenericIcon.DELETE:
        icon = GenericIcon.DELETE_SWEEP;
        break;
      case GenericIcon.ADD_BOX:
        icon = GenericIcon.QUEUE;
        break;
    }

    return {
      ...action,
      tooltip: tooltip,
      icon: icon,
    };
  });
};

export const mappedToWeightForOrganization = (
  projection: ProjectionResponseDTO,
  organizationalUnitId: string
): number => {
  const mappedTo = projection.mappedTo.find((it) => it.id === organizationalUnitId);
  return roundToTwoDecimals(mappedTo ? Number(mappedTo.weight * 100) : 100);
};

export const mappedToForOrganization = (
  projection: ProjectionResponseDTO,
  organizationalUnitId: string
): ProjectionResponseOrganizationDTO | undefined => {
  return projection.mappedTo.find((it) => it.id === organizationalUnitId);
};

export const weightAvailableForOrganization = (
  projection: ProjectionResponseDTO,
  organizationalUnitId: string
): number => {
  return roundToTwoDecimals(
    (1 -
      projection.mappedTo
        .filter((it) => it.id !== organizationalUnitId)
        .map((pjn) => pjn.weight)
        .reduce((a, b) => Number(a) + Number(b), 0)) *
      100
  );
};

const setToolTip = (tagDisabled: any, item: ProjectionResponseDTO): string => {
  let toolTip: string = "";

  const formatMappedTo = (mappedTo: ProjectionResponseOrganizationDTO[]): string => {
    return `${mappedTo.map((it) => `"${it.name}" with ${formattedPercentage(it.weight * 100, 0, 2)}`).join(" and ")}`;
  };

  if (item.mappedTo?.length > 0) {
    toolTip = `Mapped to ${formatMappedTo(item.mappedTo)}`;
  } else if (item.mappedThrough && item.mappedThrough.mappedTo?.length > 0) {
    toolTip = `Mapped through ${formatMappedTo(item.mappedThrough.mappedTo)}`;
  }

  return toolTip;
};

export const mapProjectionsToTreeListItems = (
  projections: ProjectionResponseDTO[] | null,
  actions: SearchableTagTreeAction<ProjectionResponseDTO>[],
  organizationalUnit: OrganizationalUnitResponseDTO
): [TreeListItemProps<ProjectionResponseDTO>[], string[]] => {
  if (!projections) return [[], []];

  const nodeIds: string[] = [];

  return [
    projections.reduce((resultTreeItems: TreeListItemProps<ProjectionResponseDTO>[], item: ProjectionResponseDTO) => {
      const tagDisabled = projectionUnavailable(item);
      const tagSelected = projectionAssociated(item, organizationalUnit.id);
      const tagInactive = !item.active;
      const tagAvailable = !tagDisabled && !tagSelected;

      // child of customer is application
      const grandChildTreeItem: TreeListItemProps<ProjectionResponseDTO> = {
        icon:
          item.filter.applicationTag.displayValue !== TAG.UNTAGGED ? GenericIcon.APPLICATION : GenericIcon.LABEL_OFF,
        nodeId:
          item.filter.account.value + "_" + item.filter.customerTag.value + "_" + item.filter.applicationTag.value,
        label: item.filter.applicationTag.displayValue,
        data: { item: item },
        selected: tagSelected,
        disabled: tagDisabled,
        inactive: tagInactive,
        actions: actions,
      };

      let parentItem: TreeListItemProps<ProjectionResponseDTO> | undefined = resultTreeItems.find(
        (treeListItem) => treeListItem.nodeId === item.filter.account.value
      );

      if (!parentItem) {
        // top level is account
        parentItem = {
          icon: GenericIcon.DATASOURCE,
          nodeId: item.filter.account.value,
          label: item.filter.account.displayValue,
          data: { item: item },
          selected: tagSelected,
          disabled: tagDisabled,
          inactive: tagInactive,
          children: [],
          actions: [],
        };
        resultTreeItems.push(parentItem);
        nodeIds.push(parentItem.nodeId);
      }

      let childItem: TreeListItemProps<ProjectionResponseDTO> | undefined = parentItem.children?.find(
        (child) => child.nodeId === item.filter.account.value + "_" + item.filter.customerTag.value
      );

      if (!childItem) {
        // child of account is customer
        childItem = {
          icon: GenericIcon.CUSTOMER,
          nodeId: item.filter.account.value + "_" + item.filter.customerTag.value,
          label: item.filter.customerTag.displayValue,
          data: { item: item },
          selected: tagSelected,
          disabled: tagDisabled,
          inactive: tagInactive,
          children: [],
          actions: [],
        };

        if (childItem.data.item.filter.customerTag.value === TAG.ANY) {
          addAnyActionToParent(childItem, parentItem, actions);
        } else {
          parentItem.children?.push(childItem);
          nodeIds.push(childItem.nodeId);
        }
      }

      if (grandChildTreeItem.data.item.filter.applicationTag.value === TAG.ANY) {
        childItem.tooltip = setToolTip(tagDisabled, childItem.data.item);
        addAnyActionToParent(grandChildTreeItem, childItem, actions);
      } else {
        childItem.children?.push(grandChildTreeItem);
        nodeIds.push(grandChildTreeItem.nodeId);
      }

      grandChildTreeItem.tooltip = setToolTip(tagDisabled, grandChildTreeItem.data.item);

      // if the tag is available, make sure the parent nodes reflect that
      if (tagAvailable) {
        childItem.selected = false;
        childItem.disabled = false;
        parentItem.selected = false;
        parentItem.disabled = false;
      }

      return resultTreeItems;
    }, []),
    nodeIds,
  ];
};
