import { DateTime } from "luxon";
import { GROUP_SEGMENT } from "./GroupSegment";
import { ChartConfig, DISPLAY_SEGMENT } from "./ChartConfig";
import { getDefaultGroupSegmentFilters, GroupSegmentFilter } from "./GroupSegmentFilter";
import * as uuid from "uuid";
import { DateRange } from "./DateRange";

export interface Dashboard {
  id: string;
  version: 1;
  title: string;
  editing: boolean;
  removing: boolean;
  saving: boolean;
  createdAt: DateTime;
  updatedAt: DateTime;
  savedAt?: DateTime;
  charts: ChartConfig[];
  filters: GroupSegmentFilter[];
  dateRange: DateRange;
  shared: boolean;
}

export function createDashboard(id: string = uuid.v4()): Dashboard {
  const now = DateTime.local();
  return {
    id,
    version: 1,
    title: "",
    editing: false,
    removing: false,
    saving: false,
    createdAt: now,
    updatedAt: now,
    dateRange: { type: "RelativeRange", months: 3 },
    filters: getDefaultGroupSegmentFilters(),
    charts: [
      {
        id: uuid.v4(),
        type: "pie",
        groupSegment: GROUP_SEGMENT.DATASOURCE,
        displaySegment: DISPLAY_SEGMENT.COST,
      },
      {
        id: uuid.v4(),
        type: "pie",
        groupSegment: GROUP_SEGMENT.CF_ORGANIZATION,
        displaySegment: DISPLAY_SEGMENT.COST,
      },
    ],
    shared: false,
  };
}

export const defaultDashboard: Dashboard = { ...createDashboard(), title: "Default" };
