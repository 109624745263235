import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "../theme/theme";
import { Card, CardContent } from "@material-ui/core";

const useStyles = makeStyles<LoadProgressProps>((theme) => ({
  loader: ({ baseHeight = 400 }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    minHeight: baseHeight + 32, // 2x 16px padding
  }),
}));

interface LoadProgressProps {
  baseHeight?: number;
}

export function LoadProgress({ baseHeight }: LoadProgressProps) {
  const classes = useStyles({ baseHeight });
  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
}

export function LoadProgressCard({ baseHeight }: LoadProgressProps) {
  return (
    <Card>
      <CardContent>
        <LoadProgress baseHeight={baseHeight} />
      </CardContent>
    </Card>
  );
}
