/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ConfigResponseDTO,
    CreateConfigRequestDTO,
} from '../models';

export interface Delete2Request {
    type: string;
    id: string;
    typeParam?: string;
}

export interface FindRequest {
    type: string;
    id: string;
    typeParam?: string;
}

export interface FindForParameterRequest {
    type: string;
    param: string;
}

export interface ListRequest {
    type: string;
    typeParam?: string;
}

export interface PutRequest {
    type: string;
    id: string;
    createConfigRequestDTO: CreateConfigRequestDTO;
    typeParam?: string;
}

export interface ShareConfigRequest {
    type: string;
    id: string;
    typeParam?: string;
}

export interface UnshareConfigRequest {
    type: string;
    id: string;
    typeParam?: string;
}

export interface UpdateForParameterRequest {
    type: string;
    param: string;
    createConfigRequestDTO: CreateConfigRequestDTO;
}

/**
 * 
 */
export class ConfigControllerApi extends runtime.BaseAPI {

    /**
     */
    async delete2Raw(requestParameters: Delete2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling delete2.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling delete2.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete2(requestParameters: Delete2Request, initOverrides?: RequestInit): Promise<void> {
        await this.delete2Raw(requestParameters, initOverrides);
    }

    /**
     */
    async findRaw(requestParameters: FindRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfigResponseDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling find.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling find.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async find(requestParameters: FindRequest, initOverrides?: RequestInit): Promise<ConfigResponseDTO> {
        const response = await this.findRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findForParameterRaw(requestParameters: FindForParameterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfigResponseDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling findForParameter.');
        }

        if (requestParameters.param === null || requestParameters.param === undefined) {
            throw new runtime.RequiredError('param','Required parameter requestParameters.param was null or undefined when calling findForParameter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}/param/{param}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"param"}}`, encodeURIComponent(String(requestParameters.param))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async findForParameter(requestParameters: FindForParameterRequest, initOverrides?: RequestInit): Promise<ConfigResponseDTO> {
        const response = await this.findForParameterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRaw(requestParameters: ListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConfigResponseDTO>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling list.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(requestParameters: ListRequest, initOverrides?: RequestInit): Promise<Array<ConfigResponseDTO>> {
        const response = await this.listRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putRaw(requestParameters: PutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfigResponseDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling put.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling put.');
        }

        if (requestParameters.createConfigRequestDTO === null || requestParameters.createConfigRequestDTO === undefined) {
            throw new runtime.RequiredError('createConfigRequestDTO','Required parameter requestParameters.createConfigRequestDTO was null or undefined when calling put.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/config/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createConfigRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async put(requestParameters: PutRequest, initOverrides?: RequestInit): Promise<ConfigResponseDTO> {
        const response = await this.putRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shareConfigRaw(requestParameters: ShareConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling shareConfig.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling shareConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}/{id}/share`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shareConfig(requestParameters: ShareConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shareConfigRaw(requestParameters, initOverrides);
    }

    /**
     */
    async unshareConfigRaw(requestParameters: UnshareConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling unshareConfig.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unshareConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.typeParam !== undefined) {
            queryParameters['typeParam'] = requestParameters.typeParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/{type}/{id}/share`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async unshareConfig(requestParameters: UnshareConfigRequest, initOverrides?: RequestInit): Promise<void> {
        await this.unshareConfigRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateForParameterRaw(requestParameters: UpdateForParameterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfigResponseDTO>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling updateForParameter.');
        }

        if (requestParameters.param === null || requestParameters.param === undefined) {
            throw new runtime.RequiredError('param','Required parameter requestParameters.param was null or undefined when calling updateForParameter.');
        }

        if (requestParameters.createConfigRequestDTO === null || requestParameters.createConfigRequestDTO === undefined) {
            throw new runtime.RequiredError('createConfigRequestDTO','Required parameter requestParameters.createConfigRequestDTO was null or undefined when calling updateForParameter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/config/{type}/param/{param}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"param"}}`, encodeURIComponent(String(requestParameters.param))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createConfigRequestDTO,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async updateForParameter(requestParameters: UpdateForParameterRequest, initOverrides?: RequestInit): Promise<ConfigResponseDTO> {
        const response = await this.updateForParameterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
